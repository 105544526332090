import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as FindUniversityActions from '../actions/find-university.actions';
import { FindUniversityService } from '../services/find-university.service';
import { FirebaseCallableFunctionError } from '../errors/firebase-callable-function-error';

@Injectable()
export class FindUniversityEffects {
  private LOG_SOURCE = this.constructor.name;

  findUniversity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FindUniversityActions.findUniversity),
      switchMap(action =>
        this.findUniversityService.findUniversity(action.request).pipe(
          map(response => FindUniversityActions.findUniversitySuccess({ response })),
          catchError(e => of(FindUniversityActions.findUniversityFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private findUniversityService: FindUniversityService) {}
}
