import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, commonIdAuthFeatureKey } from '../../reducers/common-id/common-id-auth.reducer';

const selectCommonIdAuthState = createFeatureSelector<State>(commonIdAuthFeatureKey);

export const getCommonIdSignedInUser = createSelector(selectCommonIdAuthState, state => state.signedInUser);
export const getCommonIdSignInResult = createSelector(selectCommonIdAuthState, state => state.signInResult);
export const getUpdateSignedInUserIsTermsAgreeResult = createSelector(
  selectCommonIdAuthState,
  state => state.updateSignedInUserIsTermAgreeResult
);
export const getCommonIdSavePurchaseResult = createSelector(selectCommonIdAuthState, state => state.savePurchaseResult);
