import * as moment from 'moment';

export type ISO8601 = string;
export const JST_OFFSET = '+09:00';

const iso8601Format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const stdFormat = 'YYYY/MM/DD HH:mm';
const simpleYmdFormat = 'YY/MM/DD';
const simple4YmdFormat = 'YYYY/MM/DD';
const serializedYmdFormat = 'YYYYMMDD';

export class Dates {
  static now(): ISO8601 {
    return moment()
      .utcOffset(JST_OFFSET)
      .format(iso8601Format);
  }

  static momentDateFromIso(isoString: string): moment.Moment {
    return moment(isoString, iso8601Format).utcOffset(JST_OFFSET);
  }

  static isoDateFromSimple4YmdFormat(simple4YmdString: string): string {
    return moment(simple4YmdString, simple4YmdFormat)
      .utcOffset(JST_OFFSET)
      .format(iso8601Format);
  }

  static stdDateStringFromIso(isoString: string): string {
    return moment(isoString, iso8601Format)
      .utcOffset(JST_OFFSET)
      .format(stdFormat);
  }

  static simpleYmdStringFromIso(isoString: string): string {
    return moment(isoString, iso8601Format)
      .utcOffset(JST_OFFSET)
      .format(simpleYmdFormat);
  }

  static simple4YmdStringFromIso(isoString: string): string {
    return moment(isoString, iso8601Format)
      .utcOffset(JST_OFFSET)
      .format(simple4YmdFormat);
  }

  static serializedYmdStringFromIso(isoString: string): string {
    return moment(isoString, iso8601Format)
      .utcOffset(JST_OFFSET)
      .format(serializedYmdFormat);
  }

  static isCachedDateExpired(cachedDate: ISO8601, cacheDays: number): boolean {
    const cacheExpireDate = Dates.momentDateFromIso(cachedDate).add(cacheDays, 'days');
    const now = Dates.momentDateFromIso(Dates.now());
    const expired = cacheExpireDate.isSameOrBefore(now);
    return expired;
  }

  static getDiffDay(start: ISO8601, end: ISO8601): number {
    return moment(end, iso8601Format).diff(moment(start, iso8601Format), 'days');
  }

  static get1YearsLaterDay(day: ISO8601): ISO8601 {
    return moment(day, iso8601Format)
      .add(364, 'days')
      .utcOffset(JST_OFFSET)
      .format(iso8601Format);
  }

  static isAfter(beforeDay: ISO8601, afterDay: ISO8601): boolean {
    return moment(afterDay).isAfter(beforeDay);
  }

  static get10DaysLaterDay(day: ISO8601): ISO8601 {
    return moment(day, iso8601Format)
      .add(10, 'days')
      .utcOffset(JST_OFFSET)
      .format(iso8601Format);
  }

  static getDaysLaterDay(day: ISO8601, days: number): ISO8601 {
    return moment(day, iso8601Format)
      .add(days, 'days')
      .utcOffset(JST_OFFSET)
      .format(iso8601Format);
  }

  static getDaysBeforeDay(day: ISO8601, days: number): ISO8601 {
    return moment(day, iso8601Format)
      .subtract(days, 'days')
      .utcOffset(JST_OFFSET)
      .format(iso8601Format);
  }

  static jstDateStringFromIso(isoString: string): ISO8601 {
    return moment(isoString)
      .utcOffset(JST_OFFSET)
      .format(iso8601Format);
  }
}
