<app-dialog-basic dialogTitle="プランの予約登録" [isLoading]="planCreating">
  <form [formGroup]="planForm">
    <!-- 学校名 -->
    <div class="mb-4">
      <div class="mb-1">
        <label class="text-sm" *ngIf="organization === schoolOrganizationId">学校名</label>
        <label class="text-sm" *ngIf="organization === jukuOrganizationId">塾・予備校名</label>
      </div>
      <div class="p-4 bg-gray-30 rounded-md">{{ this.schoolId }} {{ this.school }}</div>
    </div>

    <!-- 科目 -->
    <mat-form-field appearance="outline" class="w-full">
      <mat-label>科目</mat-label>
      <mat-select formControlName="subjectId" (selectionChange)="onChangeSubject()">
        <mat-option *ngFor="let subject of subjects" [value]="subject.id">{{ subject.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="planForm.get('subjectId').hasError('required')">選択してください</mat-error>
    </mat-form-field>

    <!-- 人数 -->
    <mat-form-field appearance="outline">
      <mat-label>人数</mat-label>
      <input matInput formControlName="memberCount" />
      <mat-error *ngIf="planForm.get('memberCount').hasError('required')">入力してください</mat-error>
      <mat-error *ngIf="planForm.get('memberCount').hasError('pattern')">半角数字を入力してください</mat-error>
    </mat-form-field>

    <!-- 期間 -->
    <div class="grid grid-cols-2 gap-x-2 gap-y-4">
      <mat-form-field appearance="outline">
        <mat-label>開始日</mat-label>
        <input
          matInput
          formControlName="startAt"
          [matDatepicker]="startAt"
          readonly
          (click)="startAt.open()"
          [min]="minDate"
          (dateChange)="onChangeStartAt()"
        />
        <mat-datepicker-toggle matSuffix [for]="startAt"></mat-datepicker-toggle>
        <mat-datepicker #startAt></mat-datepicker>
        <mat-error *ngIf="planForm.get('startAt').hasError('required')">入力してください</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>終了日</mat-label>
        <input matInput formControlName="endAt" [matDatepicker]="endAt" readonly (click)="endAt.open()" />
        <mat-datepicker-toggle matSuffix [for]="endAt"></mat-datepicker-toggle>
        <mat-datepicker #endAt></mat-datepicker>
        <mat-error *ngIf="planForm.get('endAt').hasError('required')">入力してください</mat-error>
        <mat-error *ngIf="planForm.get('endAt').hasError('endAtIsAfterStartAt')">開始日より後の日付を入力してください</mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="planCreating"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <div class="text-danger text-sm mr-auto" @enter *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
    <button mat-stroked-button (click)="cancel()" [disabled]="planCreating">キャンセル</button>
    <button mat-flat-button color="primary" (click)="addPlan()" [disabled]="planCreating">プランを登録</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
