import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, map, startWith, take } from 'rxjs/operators';

import { Authority, HighSchool, Organization, Prefecture } from 'src/app/models/common-data';
import { enter } from 'src/app/resources/animations';
import { Log } from 'src/app/utils/log';
import { RootState } from 'src/app/reducers';
import { initializeUpdateUserState, updateUser } from 'src/app/actions/user.actions';
import { User } from 'src/app/models/user';
import { getUpdateUserResult } from 'src/app/selectors/user.selectors';
import { dispatchInfoMessage } from 'src/app/actions/core.actions';
import {
  ACCOUNT_DIALOG_TYPE_ACCOUNTS,
  JUKU_ORGANIZATION_ID,
  OBUNSHA_NAME,
  OBUNSHA_ORGANIZATION_ID,
  ORGANIZATIONS,
  PREFECTURES,
  SCHOOL_ORGANIZATION_ID,
  TRY_ORGANIZATION_ID,
  TRY_ORGANIZATION_NAME,
  USER_AUTHORITIES,
  USER_AUTHORITY_OBUNSHA_ID,
  USER_AUTHORITY_ORGANIZATION_MASTER_ID,
  USER_AUTHORITY_ORGANIZATION_USER_ID
} from '../../../resources/config';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Observable } from 'rxjs';
import { CustomValidators } from '../../../utils/custom-validator';
import { Juku } from '../../../models/juku';

export interface EditAccountDialogData {
  signInUser: User;
  highSchools: HighSchool[];
  jukus: Juku[];
  user: User;
  type?: string;
}

@Component({
  selector: 'app-edit-account-dialog',
  templateUrl: './edit-account-dialog.component.html',
  styleUrls: ['./edit-account-dialog.component.scss'],
  animations: [enter]
})
export class EditAccountDialogComponent implements OnInit, OnDestroy {
  userAuthorityObunshaId: number;
  userAuthorityOrganizationMasterId: number;
  userAuthorityOrganizationUserId: number;
  accountForm: UntypedFormGroup;
  organizations: Organization[];
  highSchools: HighSchool[];
  jukus: Juku[];
  filteredSchools$: Observable<HighSchool[]>;
  prefectures: Prefecture[];
  authorities: Authority[];
  schoolId: string;
  accountEditing = false;
  errorMessage = '';
  signInUserIsAdmin: boolean;
  dialogTypeAccounts: string;
  dialogType: string;
  dialogTitle: string;
  /** two-way binding */
  adminDisabled = true;
  organizationMasterDisabled = false;

  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<EditAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: EditAccountDialogData
  ) {}

  ngOnInit() {
    this.userAuthorityObunshaId = USER_AUTHORITY_OBUNSHA_ID;
    this.userAuthorityOrganizationMasterId = USER_AUTHORITY_ORGANIZATION_MASTER_ID;
    this.userAuthorityOrganizationUserId = USER_AUTHORITY_ORGANIZATION_USER_ID;
    this.organizations = ORGANIZATIONS;
    this.highSchools = [...this.data.highSchools];
    this.jukus = [...this.data.jukus];
    this.prefectures = PREFECTURES;
    this.authorities = USER_AUTHORITIES;
    this.signInUserIsAdmin = this.data.signInUser.isAdmin;
    this.dialogTypeAccounts = ACCOUNT_DIALOG_TYPE_ACCOUNTS;
    this.dialogType = this.data.type;
    this.dialogTitle = this.dialogType === ACCOUNT_DIALOG_TYPE_ACCOUNTS ? 'ユーザーアカウントの編集' : 'メンバーの編集';

    this.setUpForms();
    this.setUpSchool();
    this.setUpValues();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeUpdateUserState());
  }

  editAccount() {
    if (this.accountForm.invalid) {
      this.accountForm.markAllAsTouched();
      return;
    }
    Log.debug(this.LOG_SOURCE, 'アカウントを変更します');
    this.disableForms();
    this.errorMessage = '';

    const familyName: string = this.accountForm.get('familyName').value;
    const firstName: string = this.accountForm.get('firstName').value;
    const familyNameKana: string = this.accountForm.get('familyNameKana').value;
    const firstNameKana: string = this.accountForm.get('firstNameKana').value;
    const organizationId: string = this.signInUserIsAdmin
      ? this.accountForm.get('organization').value.id
      : this.data.signInUser.organization;
    const school: string = this.signInUserIsAdmin ? this.accountForm.get('school').value : this.data.signInUser.school;
    const authority: number = this.accountForm.get('authority').value;

    if (!this.signInUserIsAdmin) {
      this.schoolId = this.data.signInUser.schoolId;
    } else if (this.accountForm.get('organization').value.id === SCHOOL_ORGANIZATION_ID) {
      if (this.highSchools.find(highSchool => highSchool.name === school) === undefined) {
        this.schoolId = SCHOOL_ORGANIZATION_ID;
      }
    } else if (this.accountForm.get('organization').value.id === JUKU_ORGANIZATION_ID) {
      if (this.jukus.find(juku => this.getJukuNameForFiltered(juku) === school) === undefined) {
        this.schoolId = JUKU_ORGANIZATION_ID;
      }
    }

    const user: User = {
      id: this.data.user.id,
      familyName,
      firstName,
      organization: organizationId,
      schoolId: this.schoolId,
      school,
      isAdmin: authority === USER_AUTHORITY_OBUNSHA_ID,
      isOrganizationAdmin: authority === USER_AUTHORITY_ORGANIZATION_MASTER_ID
    };

    if (familyNameKana !== '') user.familyNameKana = familyNameKana;
    if (firstNameKana !== '') user.firstNameKana = firstNameKana;

    Log.debug(this.LOG_SOURCE, 'Update User', user);
    this.store.dispatch(updateUser({ user }));

    this.store
      .select(getUpdateUserResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeUpdateUserState());
        Log.debug(this.LOG_SOURCE, `edit user result: `, result);
        if (result.success) {
          this.dialogRef.close(true);
          Log.debug(this.LOG_SOURCE, 'result.updatedUser', result.updatedUser);
          const message = `${result.updatedUser.email} のアカウント変更が完了しました`;
          this.store.dispatch(
            dispatchInfoMessage({
              message
            })
          );
          Log.debug(this.LOG_SOURCE, `アカウント変更完了: `, result.updatedUser);
          return;
        }

        Log.warn(this.LOG_SOURCE, `update user error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
        this.enableForms();
        // TODO: メッセージ内容を要検討
        this.errorMessage = result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました';
      });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  // components internal methods ---------------------------------------------------------------
  onChangeOrganization() {
    this.schoolId = null;
    this.accountForm.patchValue({ school: null });

    this.changeConditionIsAdmin();
    this.setOrganizationName();
    this.setUpSchool();
    this.setOrganizationMasterDisabled();

    if (
      this.accountForm.get('organization').value.id !== SCHOOL_ORGANIZATION_ID &&
      this.accountForm.get('organization').value.id !== JUKU_ORGANIZATION_ID
    ) {
      this.schoolId = this.accountForm.get('organization').value.id;
    }
  }

  onChangePrefecture() {
    this.changeConditionIsAdmin();
    this.setUpSchool();
  }

  onSelectSchool(event: MatAutocompleteSelectedEvent) {
    this.schoolId = event.option.value.id.trim();
    this.accountForm.patchValue({ school: event.option.value.name });
  }

  // set ups or private method ---------------------------------------------------------------

  private setUpForms() {
    this.accountForm = new UntypedFormGroup({
      familyName: new UntypedFormControl('', [Validators.required]),
      firstName: new UntypedFormControl('', [Validators.required]),
      familyNameKana: new UntypedFormControl('', []),
      firstNameKana: new UntypedFormControl('', []),
      organization: new UntypedFormControl('', [Validators.required]),
      prefecture: new UntypedFormControl('', []),
      school: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.isSchoolInMaster(this.highSchools.map(school => school.name)),
        CustomValidators.isJukuInMaster(this.jukus.map(juku => this.getJukuNameForFiltered(juku)))
      ]),
      authority: new UntypedFormControl('', [Validators.required])
    });
  }

  private setUpSchool() {
    this.filteredSchools$ = this.accountForm.get('school').valueChanges.pipe(
      startWith(null as string),
      map(value => this.changefilteredSchools(value))
    );
  }

  private setUpValues() {
    let userOrganization: string | undefined = this.data.user.organization;
    if (userOrganization === undefined && this.data.user.isAdmin) userOrganization = OBUNSHA_ORGANIZATION_ID;

    this.accountForm.patchValue({
      familyName: this.data.user.familyName,
      firstName: this.data.user.firstName,
      familyNameKana: this.data.user.familyNameKana,
      firstNameKana: this.data.user.firstNameKana,
      organization: this.organizations.find(organization => organization.id === userOrganization),
      school: this.data.user.school,
      authority: this.data.user.isAdmin
        ? USER_AUTHORITY_OBUNSHA_ID
        : this.data.user.isOrganizationAdmin
        ? USER_AUTHORITY_ORGANIZATION_MASTER_ID
        : USER_AUTHORITY_ORGANIZATION_USER_ID
    });

    this.schoolId = this.data.user.schoolId === undefined ? userOrganization : this.data.user.schoolId;
    this.adminDisabled = this.accountForm.get('organization').value.id !== OBUNSHA_ORGANIZATION_ID;
    this.organizationMasterDisabled = this.accountForm.get('organization').value.id === TRY_ORGANIZATION_ID;
  }

  private changefilteredSchools(value: string): HighSchool[] {
    let filteredSchools = [];

    if (this.accountForm.get('organization').value && this.accountForm.get('organization').value.id === SCHOOL_ORGANIZATION_ID) {
      filteredSchools = this.highSchools;
    } else if (this.accountForm.get('organization').value && this.accountForm.get('organization').value.id === JUKU_ORGANIZATION_ID) {
      filteredSchools = this.jukus.map(juku => {
        return {
          id: juku.jukuCode,
          name: this.getJukuNameForFiltered(juku),
          prefectureId: juku.prefectureId
        };
      });
    }

    if (this.accountForm.get('prefecture').value) {
      filteredSchools = filteredSchools.filter(highSchool => highSchool.prefectureId === this.accountForm.get('prefecture').value);
    }

    value = value ? value : this.accountForm.get('school').value;
    return value ? filteredSchools.filter(highSchool => highSchool.name.indexOf(value) !== -1) : filteredSchools;
  }

  private changeConditionIsAdmin() {
    this.adminDisabled = this.accountForm.get('organization').value.id !== OBUNSHA_ORGANIZATION_ID;

    if (this.adminDisabled) {
      if (this.accountForm.get('authority').value === USER_AUTHORITY_OBUNSHA_ID) {
        this.accountForm.get('authority').setValue(USER_AUTHORITY_ORGANIZATION_MASTER_ID);
      }
    }
  }

  private setOrganizationMasterDisabled() {
    this.organizationMasterDisabled = this.accountForm.get('organization').value.id === TRY_ORGANIZATION_ID;

    if (this.organizationMasterDisabled) {
      this.accountForm.get('authority').setValue(USER_AUTHORITY_ORGANIZATION_USER_ID);
    }
  }

  private setOrganizationName() {
    if (this.accountForm.get('organization').value.id === OBUNSHA_ORGANIZATION_ID) {
      this.accountForm.patchValue({
        school: OBUNSHA_NAME
      });
    }
    if (this.accountForm.get('organization').value.id === TRY_ORGANIZATION_ID) {
      this.accountForm.patchValue({
        school: TRY_ORGANIZATION_NAME
      });
    }
  }

  private disableForms() {
    Object.keys(this.accountForm.controls).forEach(ctrlName => {
      this.accountForm.get(ctrlName).disable();
    });
    this.adminDisabled = true;
    this.accountEditing = true;
  }

  private enableForms() {
    Object.keys(this.accountForm.controls).forEach(ctrlName => {
      this.accountForm.get(ctrlName).enable();
    });
    this.adminDisabled = this.accountForm.get('organization').value.id !== OBUNSHA_ORGANIZATION_ID;
    this.accountEditing = false;
  }

  private getJukuNameForFiltered(juku: Juku): string {
    return juku.jukuName + (juku.jukuSubName ? '（' + juku.jukuSubName + '）' : '');
  }
}
