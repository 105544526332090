import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { openWindow, redirectPage } from 'src/app/actions/core.actions';
import { BookmarkProblem } from 'src/app/models/bookmark';
import { ReadableNationalLanguagePlaylistProblem, ReadableNationalLanguageProblem } from 'src/app/models/problem';
import { RootState } from 'src/app/reducers';
import { Log } from 'src/app/utils/log';
import { HighlightPipe } from '../../pipes/highlight.pipe';
import { AppEvent } from '../../../models/event-log';
import { reportAppEvent } from '../../../actions/event-log.actions';
import { WordFileUtil } from '../../../utils/word-file-util';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

interface Description {
  field: string;
  problemOrder: string;
  genre: string;
  author: string;
  title: string;
  titleComplement: string;
  characterCount: string;
  overview: string;
}

interface ProblemData {
  sequentialId: string;
  id: string;
  university: string;
  departments: string;
  year: number;
  outline: {
    contents: string;
    descriptions: Description[];
    isCommonOverview: boolean;
  };
  level: string;
  book: string;
  page: number;
  problemNumber: string;

  comment?: string;
  pdfPath?: string;
  pdfDownloading?: boolean;

  hasExternalData: boolean;
  hasWordData: boolean;
  isBookmarked: boolean;
}

type ProblemId = string;

@Component({
  selector: 'app-national-language-problems',
  templateUrl: './national-language-problems.component.html',
  styleUrls: ['./national-language-problems.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NationalLanguageProblemsComponent implements OnChanges, OnDestroy {
  @Input() readableNationalLanguageProblems: ReadableNationalLanguageProblem[];
  @Input() readableNationalLanguagePlaylistProblems: ReadableNationalLanguagePlaylistProblem[];
  @Input() keywords: string[];
  @Input() problemButtonDisabled = false;
  @Input() wordButtonDisabled = false;
  @Input() hideLastBottomBorder = false;
  @Input() initializedBookmark$: Observable<boolean>;
  @Input() bookmarkProblems: BookmarkProblem[];
  @Input() bookmarkedAllFlag: boolean;
  @Input() canBookmarkSpiner = false;
  @Input() isTrial: boolean;
  @Input() playListId: string;
  @Input() themeId: string;

  @Output() addBookmarkClick = new EventEmitter<ProblemId>();
  @Output() deleteBookmarkClick = new EventEmitter<ProblemId>();
  @Output() problemClick = new EventEmitter<{ problemId: ProblemId; year: number }>();
  columns: string[];
  dataSource: ProblemData[] = [];
  private LOG_SOURCE = this.constructor.name;
  private objectUrls: string[] = [];
  private highlightPipe: HighlightPipe;

  constructor(private store: Store<RootState>, private detector: ChangeDetectorRef) {
    this.highlightPipe = new HighlightPipe();
  }

  ngOnChanges() {
    if (!this.readableNationalLanguageProblems && !this.readableNationalLanguagePlaylistProblems) return;
    const dataCount = this.readableNationalLanguageProblems
      ? this.readableNationalLanguageProblems.length
      : this.readableNationalLanguagePlaylistProblems
      ? this.readableNationalLanguagePlaylistProblems.length
      : 'none';
    Log.debug(this.LOG_SOURCE, `input data length: ${dataCount}`);

    this.columns = this.readableNationalLanguageProblems
      ? ['university', 'departments', 'year', 'outline', 'level', 'book', 'page', 'problemNumber', 'wordDownload']
      : ['sequentialId', 'university', 'departments', 'year', 'outline', 'level', 'book', 'page', 'problemNumber', 'wordDownload'];

    if (this.initializedBookmark$ !== undefined) {
      this.columns.splice(0, 0, 'bookmark');
    }

    const aggregatedProblems = this.readableNationalLanguageProblems
      ? this.readableNationalLanguageProblems.reduce<ReadableNationalLanguageProblem[][]>((acc, it) => {
          const element = acc.find(problems => problems[0].id === it.id);
          if (element) acc[acc.indexOf(element)].push(it);
          else acc.push([it]);
          return acc;
        }, [])
      : [];

    const aggregatedPlaylistProblems = this.readableNationalLanguagePlaylistProblems
      ? this.readableNationalLanguagePlaylistProblems.reduce<ReadableNationalLanguagePlaylistProblem[][]>((acc, it) => {
          const element = acc.find(problems => problems[0].id === it.id);
          if (element) acc[acc.indexOf(element)].push(it);
          else acc.push([it]);
          return acc;
        }, [])
      : [];

    this.dataSource = this.readableNationalLanguageProblems
      ? aggregatedProblems.map(readableProblem => this.mapData(readableProblem))
      : this.readableNationalLanguagePlaylistProblems
      ? aggregatedPlaylistProblems.map(readablePlaylistProblem => {
          const data = this.mapData(readablePlaylistProblem);
          data.sequentialId = readablePlaylistProblem[0].sequentialId;
          if (readablePlaylistProblem[0].comment) data.comment = readablePlaylistProblem[0].comment;
          if (readablePlaylistProblem[0].pdfPath) {
            data.pdfPath = readablePlaylistProblem[0].pdfPath;
            data.pdfDownloading = false;
          }
          return data;
        })
      : [];
  }

  ngOnDestroy() {
    this.objectUrls.forEach(url => URL.revokeObjectURL(url));
  }

  showPlayListProblemDetail(problem: ProblemData) {
    const url = RoutingPathResolver.resolvePlaylistProblemDetail(this.playListId, this.themeId, problem.id);
    this.store.dispatch(openWindow({ url }));
  }

  openProblemWord(problem: ProblemData) {
    const wordUrl = WordFileUtil.getPath(problem.id, this.isTrial);
    if (wordUrl === '') {
      Log.warn(this.LOG_SOURCE, `wordURL が設定されていません. problem id: `, problem.id);
      return;
    }
    Log.debug(this.LOG_SOURCE, `word をダウンロードして開きます. wordURL: `, wordUrl);

    this.store.dispatch(redirectPage({ url: wordUrl }));

    const subjectId = problem.id.slice(6, 8);
    const selectedProblemWord = `${problem.id}.docx`;
    const appEvent: AppEvent = {
      type: 'select-problem-word',
      value: JSON.stringify({
        subjectId,
        selectedProblemWord
      })
    };
    setTimeout(() => this.store.dispatch(reportAppEvent({ event: appEvent })));
  }

  keywordHighlight(text: string): string {
    if (!this.keywords) return text;
    return this.highlightPipe.transform(text, ...this.keywords);
  }

  private mapData(readableProblems: ReadableNationalLanguageProblem[]): ProblemData {
    const isThinking = readableProblems.reduce((acc, it) => (it.thinking !== '' ? it.thinking : acc), '');
    const categories = readableProblems.reduce((acc, it) => {
      if (acc.includes(it.categories)) return acc;
      if (acc !== '') {
        return acc + ', ' + it.categories;
      } else {
        return it.categories;
      }
    }, '');
    const unitContents = readableProblems
      .reduce((acc, it) => {
        if (it.unitContents) {
          it.unitContents.forEach(content => {
            if (acc.includes(content)) return;
            acc.push(content);
          });
        }
        return acc;
      }, [])
      .join(', ');
    const contents = unitContents ? isThinking + categories + ' / ' + unitContents : isThinking + categories;

    const descriptions = readableProblems
      .sort((a, b) => parseInt(a.problemOrder, 10) - parseInt(b.problemOrder, 10))
      .map<Description>(it => ({
        field: it.categories,
        problemOrder: it.displayProblemOrder,
        genre: it.unitGenreDescription ? `${it.unitGenre} / ${it.unitGenreDescription}` : it.unitGenre,
        author: it.author,
        title: it.title,
        titleComplement: it.titleComplement,
        characterCount: it.displayCharacterCount,
        overview: it.overview
      }));
    const isCommonOverview = readableProblems[0].isCommonOverview;
    const data: ProblemData = {
      /** PlaylistProblem の場合は map 後に適切な値を設定 */
      sequentialId: '',

      id: readableProblems[0].id,
      university: readableProblems[0].university,
      departments: readableProblems[0].departments,
      year: readableProblems[0].year,
      outline: { contents, descriptions, isCommonOverview },
      level: readableProblems[0].level,
      book: readableProblems[0].book,
      page: readableProblems.reduce<number>((acc, it, index) => (index === 0 || acc > it.page ? it.page : acc), 0),
      problemNumber: readableProblems[0].problemNumber,
      hasExternalData: readableProblems.reduce<boolean>((acc, it) => (it.hasExternalData === true ? true : acc), false),
      hasWordData: readableProblems.reduce<boolean>((acc, it) => (it.hasWordData === true ? true : acc), false),
      isBookmarked: this.bookmarkedAllFlag
        ? true
        : this.bookmarkProblems !== undefined
        ? this.bookmarkProblems.find(bookmarkProblem => bookmarkProblem.problemId === readableProblems[0].id) !== undefined
        : false
    };
    return data;
  }
}
