import { createFeatureSelector, createSelector } from '@ngrx/store';
import { commonIdPlaylistBookmarkFeatureKey, State } from '../../reducers/common-id/common-id-playlist-bookmark.reducer';

const selectCommonIdPlaylistBookmarkState = createFeatureSelector<State>(commonIdPlaylistBookmarkFeatureKey);

export const getCommonIdPlaylistBookmark = createSelector(selectCommonIdPlaylistBookmarkState, state => state.playlistBookmark);
export const getCommonIdPlaylistProblemIdsByPlaylistBookmarkIdsResult = createSelector(
  selectCommonIdPlaylistBookmarkState,
  state => state.findPlaylistProblemIdsByPlaylistBookmarkIdsResult
);
export const getCommonIdAddPlaylistBookmarkResult = createSelector(
  selectCommonIdPlaylistBookmarkState,
  state => state.addPlaylistBookmarkResult
);
export const getCommonIdDeletePlaylistBookmarkResult = createSelector(
  selectCommonIdPlaylistBookmarkState,
  staet => staet.deletePlaylistBookmarkResult
);
