import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../models/user';

import * as UserActions from '../actions/user.actions';
import { AppError } from '../errors/app-error';
import { CreateUsersResponse } from '../models/create-users-response';

export const userFeatureKey = 'user';

export interface CreateUserResult {
  success: boolean;
  createdUser: User | null;
  error: AppError | null;
}

export interface CreateUsersResult {
  success: boolean;
  response: CreateUsersResponse | null;
  error: AppError | null;
}

export interface UpdateUserResult {
  success: boolean;
  updatedUser: User | null;
  error: AppError | null;
}

export interface UpdateUserEmailResult {
  success: boolean;
  updatedUser: User | null;
  error: AppError | null;
}

export interface UpdateUserIsActiveResult {
  success: boolean;
  updatedUser: User | null;
  error: AppError | null;
}

export interface UpdateUserIsOrganizationAdminResult {
  success: boolean;
  updatedUser: User | null;
  error: AppError | null;
}

export interface UpdateUserIsTermsAgreeResult {
  success: boolean;
  updatedUser: User | null;
  error: AppError | null;
}

export interface DeleteUserResult {
  success: boolean;
  deletedUser: User | null;
  error: AppError | null;
}

export interface State {
  findUsers: User[] | null;
  findUsersByEmail: User[] | null;
  watchedUsers: User[] | null;
  createUserResult: CreateUserResult | null;
  createUsersResult: CreateUsersResult | null;
  updateUserResult: UpdateUserResult | null;
  updateUserEmailResult: UpdateUserEmailResult | null;
  updateUserIsActiveResult: UpdateUserIsActiveResult | null;
  updateUserIsOrganizationAdminResult: UpdateUserIsOrganizationAdminResult | null;
  updateUserIsTermsAgreeResult: UpdateUserIsTermsAgreeResult | null;
  deleteUserResult: DeleteUserResult | null;
}

export const initialState: State = {
  findUsers: null,
  findUsersByEmail: null,
  watchedUsers: null,
  createUserResult: null,
  createUsersResult: null,
  updateUserResult: null,
  updateUserEmailResult: null,
  updateUserIsActiveResult: null,
  updateUserIsOrganizationAdminResult: null,
  updateUserIsTermsAgreeResult: null,
  deleteUserResult: null
};

const userReducer = createReducer(
  initialState,

  // get Users  ----------------------------------------------------------------
  on(UserActions.findUsersSuccess, (state, { users }) => ({ ...state, findUsers: users })),
  on(UserActions.initializeFindUsers, state => {
    const next: State = { ...state, findUsers: null };
    return next;
  }),

  // find User By Email---------------------------------------------------------
  on(UserActions.findUsersByEmailSuccess, (state, { users }) => ({
    ...state,
    findUsersByEmail: users
  })),

  on(UserActions.initializeFindUsersByEmail, state => {
    const next: State = { ...state, findUsersByEmail: null };
    return next;
  }),

  // Watch Users ----------------------------------------------------------------
  on(UserActions.watchUsersSuccess, (state, { users }) => ({ ...state, watchedUsers: users })),
  on(UserActions.initializeWatchedUsers, state => {
    const next: State = { ...state, watchedUsers: null };
    return next;
  }),

  // Create User ----------------------------------------------------------------
  on(UserActions.createUserSuccess, (state, { user }) => {
    const next: State = {
      ...state,
      createUserResult: { success: true, createdUser: user, error: null }
    };
    return next;
  }),
  on(UserActions.createUserFailure, (state, { error }) => {
    const next: State = {
      ...state,
      createUserResult: { success: false, createdUser: null, error }
    };
    return next;
  }),
  on(UserActions.initializeCreateUserState, state => {
    const next: State = { ...state, createUserResult: null };
    return next;
  }),

  // Create Users ----------------------------------------------------------------
  on(UserActions.createUsersSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      createUsersResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(UserActions.createUsersFailure, (state, { error }) => {
    const next: State = {
      ...state,
      createUsersResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(UserActions.initializeCreateUsersState, state => {
    const next: State = { ...state, createUsersResult: null };
    return next;
  }),

  // Update User ----------------------------------------------------------------
  on(UserActions.updateUserSuccess, (state, { user }) => {
    const next: State = {
      ...state,
      updateUserResult: { success: true, updatedUser: user, error: null }
    };
    return next;
  }),
  on(UserActions.updateUserFailure, (state, { error }) => {
    const next: State = {
      ...state,
      updateUserResult: { success: false, updatedUser: null, error }
    };
    return next;
  }),
  on(UserActions.initializeUpdateUserState, state => {
    const next: State = { ...state, updateUserResult: null };
    return next;
  }),

  // Update User Email ----------------------------------------------------------------
  on(UserActions.updateUserEmailSuccess, (state, { user }) => {
    const next: State = {
      ...state,
      updateUserEmailResult: { success: true, updatedUser: user, error: null }
    };
    return next;
  }),
  on(UserActions.updateUserEmailFailure, (state, { error }) => {
    const next: State = {
      ...state,
      updateUserEmailResult: { success: false, updatedUser: null, error }
    };
    return next;
  }),
  on(UserActions.initializeUpdateUserEmailState, state => {
    const next: State = { ...state, updateUserEmailResult: null };
    return next;
  }),

  // Delete User ----------------------------------------------------------------
  on(UserActions.deleteUserSuccess, (state, { user }) => {
    const next: State = {
      ...state,
      deleteUserResult: { success: true, deletedUser: user, error: null }
    };
    return next;
  }),
  on(UserActions.deleteUserFailure, (state, { error }) => {
    const next: State = {
      ...state,
      deleteUserResult: { success: false, deletedUser: null, error }
    };
    return next;
  }),
  on(UserActions.initializeDeleteUserState, state => {
    const next: State = { ...state, deleteUserResult: null };
    return next;
  }),

  // Update User Is Active ----------------------------------------------------------------
  on(UserActions.updateUserIsActiveSuccess, (state, { user }) => {
    const next: State = {
      ...state,
      updateUserIsActiveResult: { success: true, updatedUser: user, error: null }
    };
    return next;
  }),
  on(UserActions.updateUserIsActiveFailure, (state, { error }) => {
    const next: State = {
      ...state,
      updateUserIsActiveResult: { success: false, updatedUser: null, error }
    };
    return next;
  }),
  on(UserActions.initializeUpdateUserIsActiveState, state => {
    const next: State = { ...state, updateUserIsActiveResult: null };
    return next;
  }),

  // Update User Is Organization Admin ----------------------------------------------------------------
  on(UserActions.updateUserIsOrganizationAdminSuccess, (state, { user }) => {
    const next: State = {
      ...state,
      updateUserIsOrganizationAdminResult: { success: true, updatedUser: user, error: null }
    };
    return next;
  }),
  on(UserActions.updateUserIsOrganizationAdminFailure, (state, { error }) => {
    const next: State = {
      ...state,
      updateUserIsOrganizationAdminResult: { success: false, updatedUser: null, error }
    };
    return next;
  }),
  on(UserActions.initializeUpdateUserIsOrganizationAdminState, state => {
    const next: State = { ...state, updateUserIsOrganizationAdminResult: null };
    return next;
  }),

  // Update User Is Terms Agree ----------------------------------------------------------------
  on(UserActions.updateUserIsTermsAgreeSuccess, (state, { user }) => {
    const next: State = {
      ...state,
      updateUserIsTermsAgreeResult: { success: true, updatedUser: user, error: null }
    };
    return next;
  }),
  on(UserActions.updateUserIsTermsAgreeFailure, (state, { error }) => {
    const next: State = {
      ...state,
      updateUserIsTermsAgreeResult: { success: false, updatedUser: null, error }
    };
    return next;
  }),
  on(UserActions.initializeUpdateUserIsTermsAgreeState, state => {
    const next: State = { ...state, updateUserIsTermsAgreeResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userReducer(state, action);
}
