import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { signOut } from 'src/app/actions/auth.actions';
import { dispatchAppError, navigate, unsubscribeWatchedProps } from 'src/app/actions/core.actions';
import * as InformationActions from 'src/app/actions/information.actions';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { findStaticData, initializeStaticDataState } from 'src/app/actions/static-data.actions';
import {
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  EDIT_EMAIL_ACCOUNT_DIALOG_WIDTH,
  META_VIEWPORT_OTHER,
  STATIC_DATA_CACHE_DAYS,
  UnsubscribeTarget
} from 'src/app/resources/config';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { getInformations } from 'src/app/selectors/information.selectors';
import { reportAppLaunchEvent } from 'src/app/actions/event-log.actions';
import { getFetchedDate } from 'src/app/selectors/static-data.selectors';
import { Dates } from 'src/app/utils/dates';
import { Log } from 'src/app/utils/log';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { EditEmailDialogComponent, EditEmailDialogData } from '../edit-email-dialog/edit-email-dialog.component';
import { AuthError } from '../../../errors/auth-error';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;

  constructor(private store: Store<RootState>, private dialog: MatDialog, private meta: Meta) {}

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.findStaticDataIfNeeded();
    this.setUpInformations();
    this.store.dispatch(reportAppLaunchEvent());
  }

  signOut() {
    this.store.dispatch(signOut());
    this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.ON_SIGN_OUT }));
    setTimeout(() => this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSignIn() })));
  }

  updateEmail() {
    this.store
      .select(getSignedInUser)
      .pipe(
        take(1),
        filter(it => it != null)
      )
      .subscribe(user => {
        if (user !== 'none') {
          const config: MatDialogConfig<EditEmailDialogData> = {
            width: EDIT_EMAIL_ACCOUNT_DIALOG_WIDTH,
            panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
            data: { id: user.id, email: user.email },
            disableClose: true
          };
          this.dialog.open(EditEmailDialogComponent, config);
        } else {
          this.store.dispatch(
            dispatchAppError({
              source: this.LOG_SOURCE,
              error: AuthError.alreadySignOut('already sign out')
            })
          );
        }
      });
  }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }

  private setUpInformations(): void {
    this.store.dispatch(InformationActions.getInformations());

    this.store
      .select(getInformations)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        const newInformation = result.response.some(info => this.compareDate(info));
        this.store.dispatch(InformationActions.updateNewInformation({ newInformation }));
      });
  }

  private compareDate(info) {
    const lastAccessedInformationToS = localStorage.getItem('lastAccessedInformationToS');
    return lastAccessedInformationToS !== null ? Dates.jstDateStringFromIso(info.publishedAt) > lastAccessedInformationToS : true;
  }
}
