<app-dialog-basic dialogTitle="送信内容の確認" [isLoading]="disabled">
  <div class="pb-4">
    <div class="mb-8">
      お知らせを送信します。<br />
      送信後は変更はできません。送信してよろしいですか？
    </div>

    <div class="border-2 border-gray-100 rounded-md divide-y divide-gray-100">
      <dl class="divide-y divide-gray-200">
        <div class="py-4 grid grid-cols-3">
          <dt class="px-4 self-center">お知らせの対象</dt>
          <dd class="col-span-2 font-bold self-center">
            <ng-container *ngFor="let mailReceiver of mailReceivers">
              <span class="inline-block">{{ mailReceiver.name }}</span>
              <span *ngIf="mailReceiver.name !== '入試正解デジタル担当'" class="comma-space">,</span>
            </ng-container>
          </dd>
        </div>
      </dl>
    </div>
  </div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="disabled"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="send()" [disabled]="disabled">送信</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
