import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { exhaustMap, catchError, map, switchMap, mapTo } from 'rxjs/operators';

import * as AuthActions from '../actions/auth.actions';
import { AuthService } from '../services/auth.service';
import { dispatchAppError } from '../actions/core.actions';
import { FirebaseAuthError } from '../errors/firebase-auth-error';
import { FirestoreError } from '../errors/firestore-error';
import { UserService } from '../services/user.service';
import { AuthError } from '../errors/auth-error';
import { UserUtil } from '../utils/user-util';

@Injectable()
export class AuthEffects {
  private LOG_SOURCE = this.constructor.name;

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signIn),
      exhaustMap(action =>
        this.authService.signIn(action.email, action.password).pipe(
          switchMap(user => {
            if (!user) {
              return of(
                AuthActions.signInFailure(),
                dispatchAppError({
                  source: this.LOG_SOURCE,
                  error: AuthError.userNotFound(`user data not found`)
                }),
                AuthActions.signOut()
              );
            }
            return of(AuthActions.signInSuccess({ user }));
          }),
          catchError(err => {
            const error = err instanceof AuthError ? err : FirebaseAuthError.from(err);
            return of(
              AuthActions.signInFailure(),
              dispatchAppError({
                source: this.LOG_SOURCE,
                error
              }),
              AuthActions.signOut()
            );
          })
        )
      )
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signOut),
      exhaustMap(() =>
        this.authService.signOut().pipe(
          mapTo(AuthActions.signOutSuccess()),
          catchError(err =>
            of(AuthActions.signOutFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseAuthError.from(err) }))
          )
        )
      )
    )
  );

  findSignedInUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.findSignedInUser),
      switchMap(() =>
        this.authService.signedInUserId$.pipe(
          switchMap(userId => {
            return userId === ''
              ? of(AuthActions.findSignedInUserFailure())
              : this.userService.findUser(userId).pipe(
                  map(user =>
                    user != null
                      ? AuthActions.findSignedInUserSuccess({ user: UserUtil.setDefaultValues(user) })
                      : AuthActions.findSignedInUserFailure()
                  ),
                  catchError(err =>
                    of(
                      AuthActions.findSignedInUserFailure(),
                      dispatchAppError({
                        source: this.LOG_SOURCE,
                        error: FirestoreError.from(err)
                      })
                    )
                  )
                );
          })
        )
      )
    )
  );

  sendPasswordResetEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.sendPasswordResetEmail),
      exhaustMap(action =>
        this.authService.sendPasswordResetEmail(action.email).pipe(
          mapTo(AuthActions.sendPasswordResetEmailSuccess()),
          catchError(e => {
            const error = e instanceof FirebaseAuthError ? e : FirebaseAuthError.from(e);
            return of(
              AuthActions.sendPasswordResetEmailFailure(),
              dispatchAppError({
                source: this.LOG_SOURCE,
                error
              })
            );
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private authService: AuthService, private userService: UserService) {}
}
