import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, shareReplay, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import {
  BOOKMARK_RESULTS_PER_PAGE,
  BookmarkSortType,
  BookmarkSortTypeDisplayName,
  DELETE_BOOKMARK_DIALOG_WIDTH,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  PAGINATION_WINDOW_SIZE,
  SEARCH_RESULTS_PER_PAGE,
  SortType,
  SubjectId
} from 'src/app/resources/config';
import { Log } from 'src/app/utils/log';
import { dispatchAppError, openWindow, setBrowserTitle, setTitle } from 'src/app/actions/core.actions';
import { getSubject } from 'src/app/selectors/static-data.selectors';
import {
  ReadableEnglishProblem,
  ReadableHistoryProblem,
  ReadableNationalLanguageProblem,
  ReadableScienceProblem
} from 'src/app/models/problem';
import { enter, fadeInOut } from 'src/app/resources/animations';
import { AppUtil } from 'src/app/utils/app-util';
import { Bookmark, DeleteBookmarkData } from 'src/app/models/bookmark';
import {
  getBookmarkCount,
  getBookmarkSearching,
  getReadableEnglishBookmarkProblems,
  getReadableMathBookmarkProblems,
  getReadableNationalLanguageBookmarkProblems,
  getReadablePhysicsBookmarkProblems,
  getReadableChemistryBookmarkProblems,
  getReadableBiologyBookmarkProblems,
  getReadableJapaneseHistoryBookmarkProblems,
  getReadableWorldHistoryBookmarkProblems,
  getReadableGeographyBookmarkProblems,
  getReadablePoliticalEconomyBookmarkProblems
} from 'src/app/selectors/bookmark.selectors';
import * as BookmarkActions from 'src/app/actions/bookmark.actions';
import {
  findBookmarkCount,
  findEnglishBookmark,
  findMathBookmark,
  findNationalLanguageBookmark,
  findPhysicsBookmark,
  findChemistryBookmark,
  findBiologyBookmark,
  findJapaneseHistoryBookmark,
  findWorldHistoryBookmark,
  findGeographyBookmark,
  findPoliticalEconomyBookmark,
  initializeFindBookmarkCountState,
  initializeFindEnglishBookmarkState,
  initializeFindMathBookmarkState,
  initializeFindNationalLanguageBookmarkState,
  initializeFindPhysicsBookmarkState,
  initializeFindChemistryBookmarkState,
  initializeFindBiologyBookmarkState,
  initializeFindJapaneseHistoryBookmarkState,
  initializeFindWorldHistoryBookmarkState,
  initializeFindGeographyBookmarkState,
  initializeFindPoliticalEconomyBookmarkState
} from 'src/app/actions/bookmark.actions';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { User } from 'src/app/models/user';
import { GeneralError } from '../../../errors/general-error';
import { Subject } from '../../../models/common-data';
import { SearchBookmarkCondition } from '../../../models/search-bookmark-condition';
import { DeleteBookmarkDialogComponent } from '../delete-bookmark-dialog/delete-bookmark-dialog.component';
import { SearchProblemsCondition } from '../../../models/search-condition';
import { QueryParamsMapper } from '../../../mappers/query-params-mapper';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';

interface SelectableSortType {
  type: BookmarkSortType;
  displayName: string;
}

@Component({
  selector: 'app-bookmark-problems',
  templateUrl: './bookmark-problems.component.html',
  styleUrls: ['./bookmark-problems.component.scss'],
  animations: [enter, fadeInOut]
})
export class BookmarkProblemsComponent implements OnInit, OnDestroy {
  @ViewChild('pageTop') pageTop: ElementRef;
  subjects$: Observable<Subject[]>;
  readableEnglishProblems$: Observable<ReadableEnglishProblem[]>;
  readableMathProblems$: Observable<ReadableScienceProblem[]>;
  readableNationalLanguageProblems$: Observable<ReadableNationalLanguageProblem[]>;
  readablePhysicsProblems$: Observable<ReadableScienceProblem[]>;
  readableChemistryProblems$: Observable<ReadableScienceProblem[]>;
  readableBiologyProblems$: Observable<ReadableScienceProblem[]>;
  readableJapaneseHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableWorldHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableGeographyProblems$: Observable<ReadableScienceProblem[]>;
  readablePoliticalEconomyProblems$: Observable<ReadableScienceProblem[]>;
  bookmarkSearching$: Observable<boolean>;
  ENGLISH_SUBJECT_ID: string;
  MATH_SUBJECT_ID: string;
  NATIONAL_LANGUAGE_SUBJECT_ID: string;
  PHYSICS_SUBJECT_ID: string;
  CHEMISTRY_SUBJECT_ID: string;
  BIOLOGY_SUBJECT_ID: string;
  JAPANESE_HISTORY_SUBJECT_ID: string;
  WORLD_HISTORY_SUBJECT_ID: string;
  GEOGRAPHY_SUBJECT_ID: string;
  POLITICAL_ECONOMY_SUBJECT_ID: string;
  user: User;
  totalBookmarkCount: number;
  selectedSubjectId: string;
  selectableSortTypes: SelectableSortType[];
  selectedSortType: SelectableSortType;
  // 表示用
  bookmark: Bookmark;
  initializedBookmark$ = new BehaviorSubject(true);
  bookmarkedAllFlag = true;
  // pagination ----------
  currentPage = 1;
  totalPage: number;
  paginationPageSize: number;
  paginationWindowSize: number;
  private LOG_SOURCE = this.constructor.name;
  private title = 'お気に入り管理';
  private firstPageInitializedSubject = new BehaviorSubject(false);
  firstPageInitialized$ = this.firstPageInitializedSubject.asObservable();

  constructor(
    private store: Store<RootState>,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.ENGLISH_SUBJECT_ID = SubjectId.ENGLISH;
    this.MATH_SUBJECT_ID = SubjectId.MATH;
    this.NATIONAL_LANGUAGE_SUBJECT_ID = SubjectId.NATIONAL_LANGUAGE;
    this.PHYSICS_SUBJECT_ID = SubjectId.PHYSICS;
    this.CHEMISTRY_SUBJECT_ID = SubjectId.CHEMISTRY;
    this.BIOLOGY_SUBJECT_ID = SubjectId.BIOLOGY;
    this.JAPANESE_HISTORY_SUBJECT_ID = SubjectId.JAPANESE_HISTORY;
    this.WORLD_HISTORY_SUBJECT_ID = SubjectId.WORLD_HISTORY;
    this.GEOGRAPHY_SUBJECT_ID = SubjectId.GEOGRAPHY;
    this.POLITICAL_ECONOMY_SUBJECT_ID = SubjectId.POLITICAL_ECONOMY;

    this.paginationPageSize = SEARCH_RESULTS_PER_PAGE;
    this.paginationWindowSize = PAGINATION_WINDOW_SIZE;
    this.setUpSelectableSortTypes();
  }

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUp();
  }

  ngOnDestroy() {
    this.store.dispatch(BookmarkActions.initializeFindBookmarkState());
  }

  selectPage(page: number) {
    Log.debug(this.LOG_SOURCE, `選択された page: ${page}`);
    this.scrollToTop();
    this.searchBookmarkProblems(page, this.selectedSortType.type);
    this.currentPage = page;
  }

  onChangeSubject() {
    this.currentPage = 1;
    this.findFirstPageProblems();
  }

  onChangeSortType() {
    this.currentPage = 1;
    this.findFirstPageProblems();
  }

  showEnglishProblemDetail(problemObj: { problemId: string; year: number }) {
    const condition: SearchProblemsCondition<any> = {
      subjectId: this.selectedSubjectId,
      startYear: problemObj.year,
      endYear: problemObj.year,
      sortType: SortType.D_CODE
    };

    const url = RoutingPathResolver.resolveProblemDetail(problemObj.problemId);
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, '大問詳細画面用の query params: ', queryParams);
    this.store.dispatch(openWindow({ url, extras: { queryParams: { ...queryParams, from: 'bookmark' } } }));
  }

  showScienceProblemDetail(problemObj: { problemId: string; year: number }) {
    const condition: SearchProblemsCondition<any> = {
      subjectId: this.selectedSubjectId,
      startYear: problemObj.year,
      endYear: problemObj.year,
      sortType: SortType.D_CODE
    };

    const url = RoutingPathResolver.resolveProblemDetail(problemObj.problemId);
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, '大問詳細画面用の query params: ', queryParams);
    this.store.dispatch(openWindow({ url, extras: { queryParams: { ...queryParams, from: 'bookmark' } } }));
  }

  showNationalLanguageProblemDetail(problemObj: { problemId: string; year: number }) {
    const condition: SearchProblemsCondition<any> = {
      subjectId: this.selectedSubjectId,
      startYear: problemObj.year,
      endYear: problemObj.year,
      sortType: SortType.D_CODE
    };

    const url = RoutingPathResolver.resolveProblemDetail(problemObj.problemId);
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, '大問詳細画面用の query params: ', queryParams);
    this.store.dispatch(openWindow({ url, extras: { queryParams: { ...queryParams, from: 'bookmark' } } }));
  }

  showJapaneseHistoryProblemDetail(problemObj: { problemId: string; year: number }) {
    const condition: SearchProblemsCondition<any> = {
      subjectId: this.selectedSubjectId,
      startYear: problemObj.year,
      endYear: problemObj.year,
      sortType: SortType.D_CODE
    };

    const url = RoutingPathResolver.resolveProblemDetail(problemObj.problemId);
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, '大問詳細画面用の query params: ', queryParams);
    this.store.dispatch(openWindow({ url, extras: { queryParams: { ...queryParams, from: 'bookmark' } } }));
  }

  showWorldHistoryProblemDetail(problemObj: { problemId: string; year: number }) {
    const condition: SearchProblemsCondition<any> = {
      subjectId: this.selectedSubjectId,
      startYear: problemObj.year,
      endYear: problemObj.year,
      sortType: SortType.D_CODE
    };

    const url = RoutingPathResolver.resolveProblemDetail(problemObj.problemId);
    const queryParams = QueryParamsMapper.encodeSearchProblemsCondition(condition);
    Log.debug(this.LOG_SOURCE, '大問詳細画面用の query params: ', queryParams);
    this.store.dispatch(openWindow({ url, extras: { queryParams: { ...queryParams, from: 'bookmark' } } }));
  }

  addBookmark(problemId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入り管理では、お気に入りを登録はできません');
  }

  deleteBookmarkDialog(problemId: string) {
    Log.debug(this.LOG_SOURCE, `次のお気に入りを解除します: `, problemId);
    const data: DeleteBookmarkData = {
      userId: this.user.id,
      subjectId: this.selectedSubjectId,
      problemId
    };
    const config: MatDialogConfig = {
      width: DELETE_BOOKMARK_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      data,
      disableClose: true
    };
    this.dialog
      .open(DeleteBookmarkDialogComponent, config)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.currentPage = 1;
          setTimeout(() => this.findFirstPageProblems(), 300);
        }
      });
  }

  // set ups ----------------------------------------------------------------------------------------------
  private setUp() {
    const conditionParams = this.activatedRoute.snapshot.queryParams;
    Log.debug(this.LOG_SOURCE, `query params: `, conditionParams);

    this.currentPage = conditionParams.page ? conditionParams.page : 1;
    this.selectedSortType = conditionParams.sortType
      ? conditionParams.sortType
      : { type: BookmarkSortType.CREATED_AT_DESC, displayName: BookmarkSortTypeDisplayName.CREATED_AT_DESC };

    this.subjects$ = this.store.select(getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([this.subjects$, this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none'))])
      .pipe(take(1))
      .subscribe(([subjects, signedInUser]) => {
        this.user = signedInUser;

        if (subjects.length > 0) {
          const _subjects = [];
          subjects.forEach(subject => {
            if (signedInUser.visibleSubjectIds.includes(subject.id)) {
              _subjects.push(subject);
            }
          });

          subjects = _subjects;
          this.subjects$ = of(subjects);
        }

        if (subjects.length === 0) {
          this.store.dispatch(
            dispatchAppError({
              source: this.LOG_SOURCE,
              error: GeneralError.unknown(`subject が 0 件です`)
            })
          );
          return;
        }

        this.selectedSubjectId = conditionParams.subjectId ? conditionParams.subjectId : subjects[0].id;

        this.setUps();
      });
  }

  private setUps() {
    this.bookmarkSearching$ = this.store.select(getBookmarkSearching);

    this.setUpEnglishBookmarkProblems();
    this.setUpMathBookmarkProblems();
    this.setUpNationalLanguageBookmarkProblems();
    this.setUpPhysicsBookmarkProblems();
    this.setUpChemistryBookmarkProblems();
    this.setUpBiologyBookmarkProblems();
    this.setUpJapaneseHistoryBookmarkProblems();
    this.setUpWorldHistoryBookmarkProblems();
    this.setUpGeographyBookmarkProblems();
    this.setUpPoliticalEconomyBookmarkProblems();

    this.findFirstPageProblems();
  }

  private setUpEnglishBookmarkProblems() {
    this.readableEnglishProblems$ = this.store.select(getReadableEnglishBookmarkProblems).pipe(shareReplay(1));
    this.readableEnglishProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problems => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpMathBookmarkProblems() {
    this.readableMathProblems$ = this.store.select(getReadableMathBookmarkProblems).pipe(shareReplay(1));
    this.readableMathProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problems => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpNationalLanguageBookmarkProblems() {
    this.readableNationalLanguageProblems$ = this.store.select(getReadableNationalLanguageBookmarkProblems).pipe(shareReplay(1));
    this.readableNationalLanguageProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problems => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpPhysicsBookmarkProblems() {
    this.readablePhysicsProblems$ = this.store.select(getReadablePhysicsBookmarkProblems).pipe(shareReplay(1));
    this.readablePhysicsProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problems => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpChemistryBookmarkProblems() {
    this.readableChemistryProblems$ = this.store.select(getReadableChemistryBookmarkProblems).pipe(shareReplay(1));
    this.readableChemistryProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problems => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpBiologyBookmarkProblems() {
    this.readableBiologyProblems$ = this.store.select(getReadableBiologyBookmarkProblems).pipe(shareReplay(1));
    this.readableBiologyProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problems => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpJapaneseHistoryBookmarkProblems() {
    this.readableJapaneseHistoryProblems$ = this.store.select(getReadableJapaneseHistoryBookmarkProblems).pipe(shareReplay(1));
    this.readableJapaneseHistoryProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problems => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpWorldHistoryBookmarkProblems() {
    this.readableWorldHistoryProblems$ = this.store.select(getReadableWorldHistoryBookmarkProblems).pipe(shareReplay(1));
    this.readableWorldHistoryProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problems => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpGeographyBookmarkProblems() {
    this.readableGeographyProblems$ = this.store.select(getReadableGeographyBookmarkProblems).pipe(shareReplay(1));
    this.readableGeographyProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problems => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpPoliticalEconomyBookmarkProblems() {
    this.readablePoliticalEconomyProblems$ = this.store.select(getReadablePoliticalEconomyBookmarkProblems).pipe(shareReplay(1));
    this.readablePoliticalEconomyProblems$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(problems => {
        this.firstPageInitializedSubject.next(true);
      });
  }

  private setUpTotalPage(totalBookmarkCount: number) {
    this.totalPage = Math.ceil(totalBookmarkCount / BOOKMARK_RESULTS_PER_PAGE);
  }

  private setUpSelectableSortTypes() {
    const sortTypeDefault: SelectableSortType = {
      type: BookmarkSortType.CREATED_AT_DESC,
      displayName: BookmarkSortTypeDisplayName.CREATED_AT_DESC
    };
    this.selectableSortTypes = [
      sortTypeDefault,
      { type: BookmarkSortType.CREATED_AT_ASC, displayName: BookmarkSortTypeDisplayName.CREATED_AT_ASC }
    ];
    this.selectedSortType = sortTypeDefault;
  }

  private findFirstPageProblems() {
    this.store.dispatch(initializeFindBookmarkCountState());

    this.store
      .select(getBookmarkCount)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(bookmarkCount => {
        Log.debug(this.LOG_SOURCE, 'bookmarkCount', bookmarkCount);
        this.totalBookmarkCount = bookmarkCount;

        if (bookmarkCount === 0) {
          Log.warn(this.LOG_SOURCE, `条件に該当する大問が 0 件です`);
          this.firstPageInitializedSubject.next(true);
          return;
        }

        this.setUpTotalPage(bookmarkCount);
        this.selectPage(this.currentPage);
      });

    this.store.dispatch(findBookmarkCount({ userId: this.user.id, subjectId: this.selectedSubjectId }));
  }

  private searchBookmarkProblems(page: number, sortType: BookmarkSortType) {
    this.store.dispatch(initializeFindEnglishBookmarkState());
    this.store.dispatch(initializeFindMathBookmarkState());
    this.store.dispatch(initializeFindNationalLanguageBookmarkState());
    this.store.dispatch(initializeFindPhysicsBookmarkState());
    this.store.dispatch(initializeFindChemistryBookmarkState());
    this.store.dispatch(initializeFindBiologyBookmarkState());
    this.store.dispatch(initializeFindJapaneseHistoryBookmarkState());
    this.store.dispatch(initializeFindWorldHistoryBookmarkState());
    this.store.dispatch(initializeFindGeographyBookmarkState());
    this.store.dispatch(initializeFindPoliticalEconomyBookmarkState());

    if (this.selectedSubjectId === SubjectId.ENGLISH) {
      this.findEnglishBookmarkProblems(page, sortType);
      return;
    }
    if (this.selectedSubjectId === SubjectId.MATH) {
      this.findScienceBookmarkProblems('MATH', page, sortType);
      return;
    }
    if (this.selectedSubjectId === SubjectId.NATIONAL_LANGUAGE) {
      this.findNationalLanguageBookmarkProblems(page, sortType);
      return;
    }
    if (this.selectedSubjectId === SubjectId.PHYSICS) {
      this.findScienceBookmarkProblems('PHYSICS', page, sortType);
      return;
    }
    if (this.selectedSubjectId === SubjectId.CHEMISTRY) {
      this.findScienceBookmarkProblems('CHEMISTRY', page, sortType);
      return;
    }
    if (this.selectedSubjectId === SubjectId.BIOLOGY) {
      this.findScienceBookmarkProblems('BIOLOGY', page, sortType);
      return;
    }
    if (this.selectedSubjectId === SubjectId.JAPANESE_HISTORY) {
      this.findHistoryBookmarkProblems('JAPANESE_HISTORY', page, sortType);
      return;
    }
    if (this.selectedSubjectId === SubjectId.WORLD_HISTORY) {
      this.findHistoryBookmarkProblems('WORLD_HISTORY', page, sortType);
      return;
    }
    if (this.selectedSubjectId === SubjectId.GEOGRAPHY) {
      this.findScienceBookmarkProblems('GEOGRAPHY', page, sortType);
      return;
    }
    if (this.selectedSubjectId === SubjectId.POLITICAL_ECONOMY) {
      this.findScienceBookmarkProblems('POLITICAL_ECONOMY', page, sortType);
      return;
    }

    Log.error(this.LOG_SOURCE, 'subjectId が想定されていないものです: ', this.selectedSubjectId);
  }

  private findEnglishBookmarkProblems(page: number, sortType: BookmarkSortType) {
    Log.debug(this.LOG_SOURCE, `${page} ページ目の english の大問を取得します`);

    const condition: SearchBookmarkCondition = {
      userId: this.user.id,
      subjectId: this.selectedSubjectId,
      sortType,
      page
    };
    this.store.dispatch(findEnglishBookmark({ condition }));
  }

  private findScienceBookmarkProblems(
    subject: 'MATH' | 'PHYSICS' | 'CHEMISTRY' | 'BIOLOGY' | 'GEOGRAPHY' | 'POLITICAL_ECONOMY',
    page: number,
    sortType: BookmarkSortType
  ) {
    Log.debug(this.LOG_SOURCE, `${page} ページ目の ${subject} の大問を取得します`);

    const condition: SearchBookmarkCondition = {
      userId: this.user.id,
      subjectId: this.selectedSubjectId,
      sortType,
      page
    };

    const action =
      subject === 'MATH'
        ? findMathBookmark({ condition })
        : subject === 'PHYSICS'
        ? findPhysicsBookmark({ condition })
        : subject === 'BIOLOGY'
        ? findBiologyBookmark({ condition })
        : subject === 'CHEMISTRY'
        ? findChemistryBookmark({ condition })
        : subject === 'GEOGRAPHY'
        ? findGeographyBookmark({ condition })
        : findPoliticalEconomyBookmark({ condition });
    this.store.dispatch(action);
  }

  private findNationalLanguageBookmarkProblems(page: number, sortType: BookmarkSortType) {
    Log.debug(this.LOG_SOURCE, `${page} ページ目の national language の大問を取得します`);

    const condition: SearchBookmarkCondition = {
      userId: this.user.id,
      subjectId: this.selectedSubjectId,
      sortType,
      page
    };
    this.store.dispatch(findNationalLanguageBookmark({ condition }));
  }

  private findHistoryBookmarkProblems(subject: 'JAPANESE_HISTORY' | 'WORLD_HISTORY', page: number, sortType: BookmarkSortType) {
    Log.debug(this.LOG_SOURCE, `${page} ページ目の ${subject} の問題を取得します`);

    const condition: SearchBookmarkCondition = {
      userId: this.user.id,
      subjectId: this.selectedSubjectId,
      sortType,
      page
    };
    const action = subject === 'JAPANESE_HISTORY' ? findJapaneseHistoryBookmark({ condition }) : findWorldHistoryBookmark({ condition });
    this.store.dispatch(action);
  }

  private scrollToTop() {
    this.pageTop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
