import { Action, createReducer, on } from '@ngrx/store';

import * as EventLogActions from '../actions/event-log.actions';
import { SearchLogFileMetadata } from '../models/search-log-file-metadata';

export const eventLogFeatureKey = 'eventLog';

export interface ResolveSearchLogFileObjectUrlResult {
  success: boolean;
  objectUrl: string | null;
  filePath: string;
}

export interface State {
  searchLogFileMetadataList: SearchLogFileMetadata[] | null;
  resolveSearchLogFileObjectUrlResults: ResolveSearchLogFileObjectUrlResult[];
}

export const initialState: State = {
  searchLogFileMetadataList: null,
  resolveSearchLogFileObjectUrlResults: []
};

const eventLogReducer = createReducer(
  initialState,

  // Find Search Log File Metadata List ------------------------------------------------
  on(EventLogActions.findSearchLogFileMetadataListSuccess, (state, { metadataList }) => ({
    ...state,
    searchLogFileMetadataList: metadataList
  })),
  on(EventLogActions.initializeSearchLogFileMetadataListState, state => {
    const next: State = { ...state, searchLogFileMetadataList: null };
    return next;
  }),

  // Resolve Search Log File Object Url ------------------------------------------------
  on(EventLogActions.resolveSearchLogFileObjectUrlSuccess, (state, { filePath, objectUrl }) => {
    const resolveSearchLogFileObjectUrlResults: ResolveSearchLogFileObjectUrlResult[] = [
      ...state.resolveSearchLogFileObjectUrlResults,
      { success: true, filePath, objectUrl }
    ];
    return { ...state, resolveSearchLogFileObjectUrlResults };
  }),
  on(EventLogActions.resolveSearchLogFileObjectUrlFailure, (state, { filePath }) => {
    const resolveSearchLogFileObjectUrlResults: ResolveSearchLogFileObjectUrlResult[] = [
      ...state.resolveSearchLogFileObjectUrlResults,
      { success: false, filePath, objectUrl: null }
    ];
    return { ...state, resolveSearchLogFileObjectUrlResults };
  }),
  on(EventLogActions.initializeSearchLogFileObjectUrl, (state, { filePath }) => {
    const resolveSearchLogFileObjectUrlResults = state.resolveSearchLogFileObjectUrlResults.filter(it => it.filePath !== filePath);
    return { ...state, resolveSearchLogFileObjectUrlResults };
  }),
  on(EventLogActions.initializeSearchLogFileObjectUrlState, state => {
    const next: State = { ...state, resolveSearchLogFileObjectUrlResults: [] };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return eventLogReducer(state, action);
}
