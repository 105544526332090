import { Action, createReducer, on } from '@ngrx/store';
import * as CommonIdFindPurchasesActions from '../../actions/common-id/common-id-purchases.actions';

import { CommonIdPurchases } from '../../models/common-id/common-id-purchases';

export const commonIdPurchasesFeatureKey = 'commonIdPurchases';

export interface State {
  purchases: CommonIdPurchases | null;
}

export const initialState: State = {
  purchases: null
};

const commonIdFindPurchasesReducer = createReducer(
  initialState,

  on(CommonIdFindPurchasesActions.commonIdFindPurchasesSuccess, (state, { purchases }) => ({
    ...state,
    purchases
  })),
  on(CommonIdFindPurchasesActions.initializeCommonIdFindPurchasesState, state => {
    const next: State = { ...state, purchases: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return commonIdFindPurchasesReducer(state, action);
}
