import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CallableFunction } from '../../resources/app-firebase';
import { CommonIdGetCustomTokenRequest } from '../../models/common-id/common-id-get-custom-token-request';
import { CommonIdGetCustomTokenResponse } from '../../models/common-id/common-id-get-custom-token-response';

@Injectable({
  providedIn: 'root'
})
export class CommonIdGetCustomTokenService {
  signedInUserId$: Observable<string>;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {
    this.signedInUserId$ = this.afAuth.authState.pipe(
      map(firebaseUser => (firebaseUser ? firebaseUser.uid : '')),
      shareReplay(1)
    );
  }

  commonIdGetCustomToken(request: CommonIdGetCustomTokenRequest): Observable<CommonIdGetCustomTokenResponse> {
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_GET_CUSTOM_TOKEN);
    return callable(request);
  }
}
