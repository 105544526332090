import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { UntypedFormControl } from '@angular/forms';

export interface MultiselectOption {
  id: string;
  name: string;
}

export interface OptionWithSelectedFlag extends MultiselectOption {
  selected: boolean;
}

@Component({
  selector: 'app-autocomplete-multiselect',
  templateUrl: './autocomplete-multiselect.component.html',
  styleUrls: ['./autocomplete-multiselect.component.scss']
})
export class AutocompleteMultiselectComponent implements OnInit, OnChanges {
  @ViewChild('memberInput', { read: MatAutocompleteTrigger }) memberInput: MatAutocompleteTrigger;

  @Input() placeholder: string;
  @Input() multiselectOptions: MultiselectOption[] = [];
  @Input() maxAssignableNum: number;
  @Input() isFormDisabled: boolean;
  @Output() emitSelectedOptions = new EventEmitter<MultiselectOption[]>();

  selectControl = new UntypedFormControl();
  selectableOptions: OptionWithSelectedFlag[] = [];
  selectedOptions: OptionWithSelectedFlag[] = [];

  isCheckboxDisabled = false;

  constructor() {}

  ngOnInit() {
    this.multiselectOptions.forEach(user => {
      this.selectableOptions.push({ id: user.id, name: user.name, selected: false });
    });
    this.selectControl.enable();
  }

  ngOnChanges() {
    if (this.isFormDisabled) {
      this.selectControl.disable();
    } else {
      this.selectControl.enable();
    }
  }

  optionClicked(event: Event, option: OptionWithSelectedFlag) {
    event.stopPropagation();

    // 選択されたメンバーが上限に達している場合は、選択処理を行わない
    if (!this.isCheckboxDisabled || option.selected) {
      this.toggleSelection(option);
    }
  }

  toggleSelection(option: OptionWithSelectedFlag) {
    option.selected = !option.selected;
    if (option.selected === true) {
      this.selectedOptions.push(option);
    } else {
      const indexOfSelectedOption = this.selectedOptions.findIndex(value => value.id === option.id);
      this.selectedOptions.splice(indexOfSelectedOption, 1);
    }
    this.selectControl.setValue(this.selectedOptions);
    this.emitSelectedItems();

    // 登録できるメンバーの上限はプランにより変動する
    this.isCheckboxDisabled = this.selectedOptions.filter(item => item.selected === true).length >= this.maxAssignableNum;
  }

  removeChip(option: OptionWithSelectedFlag) {
    this.toggleSelection(option);
  }

  openPanel() {
    this.memberInput.openPanel();
  }

  private emitSelectedItems() {
    const results: MultiselectOption[] = [];
    this.selectedOptions.forEach((data: MultiselectOption) => {
      results.push({ id: data.id, name: data.name });
    });
    this.emitSelectedOptions.emit(results);
  }
}
