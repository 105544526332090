import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NavigationExtras } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest, of } from 'rxjs';
import { map, filter, take, shareReplay } from 'rxjs/operators';

import { RootState } from '../../../../reducers';
import { navigate, setTitle } from 'src/app/actions/core.actions';
import * as CommonIdSearchActions from 'src/app/actions/common-id/common-id-search.actions';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import {
  getMatchedUniversityCount,
  getUniversities,
  getUniversityCountSearching,
  getUniversitySearching
} from 'src/app/selectors/common-id/common-id-search.selectors';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import * as CommonIdUniversityBookmarkSelectors from 'src/app/selectors/common-id/common-id-university-bookmark.selectors';
import { getCommonIdVisitedPapers } from 'src/app/selectors/common-id/common-id-visited-paper.selectors';

import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import {
  COMMON_ID_FREE_YEARS,
  COMMON_ID_PREFECTURES,
  COMMON_ID_SEARCH_YEARS,
  COMMON_ID_YEAR_LABEL_FOR_UNPAID_USERS,
  COMMON_TEST_PREFECTURE_NAME,
  COMMON_TEST_UNIVERSITY_ID,
  DEFAULT_DEPARTMENT_CATEGORY
} from '../../../../resources/common-id-config';
import { NO_DISPLAY_DEPARTMENT_CATEGORY_IDS } from 'src/app/resources/config';

import { UniversitySearchCondition, UniversitySearchType, PrefectureCondition } from 'src/app/models/university-search-condition';
import { DepartmentCategory } from 'src/app/models/common-data';
import { StaticCommonData } from '../../../../models/static-common-data';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { CommonIdFindUniversityPapersResponse } from 'src/app/models/common-id/common-id-find-university-papers-response';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import { PrefectureData } from 'src/app/models/university-search-prefecture-dialog-condition';
import { FindUniversityPapersResult } from 'src/app/models/find-university-papers-result';
import { UniversityBookmark } from 'src/app/models/common-id/common-id-bookmark';
import { PrefectureChangeValuesParams, Year } from 'src/app/models/search-univ-interfaces';

import { Log } from 'src/app/utils/log';
import { Diff } from 'src/app/utils/diff';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { CommonIdSubjectUtil } from 'src/app/utils/common-id/common-id-subject-util';
import { UniversitySearchQueryParamsMapper } from 'src/app/mappers/university-search-query-params-mapper';

import { CommonIdSearchUnivResultComponent } from '../search-univ-result/search-univ-result.component';
import { UniversitySearchRouterService } from 'src/app/services/university-search-router.service';
import { UniversitySearchResultSharedService } from 'src/app/services/university-search-result-shared.service';
import { DataUtil } from '../../../../utils/data-util';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';

@Component({
  selector: 'app-common-id-search-univ-pref',
  templateUrl: './search-univ-pref.component.html',
  styleUrls: ['./search-univ-pref.component.scss']
})
export class CommonIdSearchUnivPrefComponent implements OnInit, OnDestroy, AfterViewInit {
  factory: ComponentFactory<CommonIdSearchUnivResultComponent>;
  @ViewChild('searchUnivResult', { static: true, read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  searchResultComponent: ComponentRef<CommonIdSearchUnivResultComponent>;
  previousPageSearchResultComponents: ComponentRef<CommonIdSearchUnivResultComponent>[] = [];

  constructor(
    private store: Store<RootState>,
    private resolver: ComponentFactoryResolver,
    private universitySearchRouterService: UniversitySearchRouterService,
    private universitySearchResultSharedService: UniversitySearchResultSharedService
  ) {}

  private LOG_SOURCE = this.constructor.name;
  private title = '都道府県で探す';
  private lastNotifiedCondition: UniversitySearchCondition<PrefectureCondition> | undefined;

  private universityCountSubscription: Subscription;
  private bookmarkClickEmittedSubsription: Subscription;
  private errorUniversityIdSubsription: Subscription;

  isSearchSummaryDisplayed: boolean;

  isPremiumUser: boolean;
  matchedUniversityCount: number | undefined;

  selectedPrefectures: PrefectureData[] = [];
  selectedUniversityTypeIds: string[] = [];
  selectedYear: string | null;
  selectableYears: Year[];

  selectedDepartmentCategory: DepartmentCategory;
  defaultDepartmentCategory: DepartmentCategory = DEFAULT_DEPARTMENT_CATEGORY;
  selectableDepartmentCategories: DepartmentCategory[];

  matchedUniversityCount$: Observable<number>;
  universityCountSearching$: Observable<boolean>;
  searchButtonDisabled$: Observable<boolean>;

  staticCommonData$: Observable<StaticCommonData>;
  staticCommonData: StaticCommonData;
  signedInUser$: Observable<CommonIdUser>;
  currentDateTime$: Observable<CurrentDateTime>;

  pageToSearch: number;
  currentPage: number;
  universities$: Observable<CommonIdFindUniversityPapersResponse[]>;
  searchResults: FindUniversityPapersResult[] = [];
  searchResultsToDisplay: FindUniversityPapersResult[] = [];

  isUniversitySearching$: Observable<boolean>;
  isShowMoreButtonShown$: Observable<boolean>;
  pageButton: number;

  isPremiumIconShown: boolean;

  universityBookmark: UniversityBookmark;
  universityBookmark$: Observable<UniversityBookmark>;

  commonIdVisitedPapers$: Observable<string[]>;
  commonIdVisitedPapers: string[];

  previousCondition: UniversitySearchCondition<PrefectureCondition>;

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    // 動的に大学検索結果コンポーネントをコンパイルできる状態にする
    this.factory = this.resolver.resolveComponentFactory(CommonIdSearchUnivResultComponent);

    this.setUpCurrentDateTime();
    this.setUpUser();
    this.setUpYears();
    this.setUpStaticCommonData();
    this.setUpCommonIdVisitedPapers();
    this.setUpUniversityBookmarks();
    this.setUpMatchedUniversityCount();
    this.initializeSearchResults();
    this.setUpIsUniversitySearching();

    // 大学詳細画面からの戻りであれば元の検索条件をセットし検索する
    this.previousCondition = this.universitySearchRouterService.getPreviousSearchConditionPrefecture();
    if (this.universitySearchRouterService.isFromLocationBack && this.previousCondition) {
      this.findUniversityByPreviousCondition(this.previousCondition);
    }

    // お気に入り登録ボタンに関する処理
    this.passBookmarkDataToChildComponents();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.universityCountSubscription) this.universityCountSubscription.unsubscribe();
    this.universityCountSubscription = undefined;
    if (this.bookmarkClickEmittedSubsription) this.bookmarkClickEmittedSubsription.unsubscribe();
    if (this.errorUniversityIdSubsription) this.errorUniversityIdSubsription.unsubscribe();

    this.store.dispatch(CommonIdSearchActions.initializeUniversityCountState());
    this.store.dispatch(CommonIdSearchActions.initializeUniversityPapersState());

    if (this.searchResultComponent) {
      this.searchResultComponent.destroy();
    }
    if (this.previousPageSearchResultComponents.length > 0) {
      this.previousPageSearchResultComponents = [];
    }
  }

  menuClickHandler(key) {
    switch (key) {
      case 'school':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUniv() }));
        break;
      case 'map':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUnivPref() }));
        break;
    }
  }

  resetForms(event: PrefectureChangeValuesParams) {
    // 共通コンポーネントより、最新の検索条件を取得
    this.getCurrentSearchConditionFromBaseComponent(event);

    this.lastNotifiedCondition = undefined;
    this.store.dispatch(CommonIdSearchActions.initializeUniversityCountState());
    this.store.dispatch(CommonIdSearchActions.initializeUniversityPapersState());

    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUnivPref(), extras: {} }));
    this.initializeSearchResults();

    if (this.viewContainerRef) this.viewContainerRef.clear();
    if (this.searchResultComponent) this.searchResultComponent.destroy();
    if (this.previousPageSearchResultComponents.length > 0) {
      this.previousPageSearchResultComponents = [];
    }
  }

  showSearchResultView() {
    const condition = this.getCurrentSearchCondition();
    this.setFirstPageSearchResults(condition);

    const extras: NavigationExtras = {
      queryParams: UniversitySearchQueryParamsMapper.encodeUniversitySearchConditionWithPrefectureIds(condition)
    };
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUnivPref(), extras }));

    // 結果を表示ボタンを無効にする
    this.searchButtonDisabled$ = of(true);
  }

  onChangeValues(event: PrefectureChangeValuesParams) {
    // 共通コンポーネントより、最新の検索条件を取得
    this.getCurrentSearchConditionFromBaseComponent(event);

    // 年度の値を取得 + 王冠アイコンの表示有無フラグを設定
    this.isPremiumIconShown = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(this.selectedYear) ? false : true;

    // 現在の選択条件を取得して保存
    const currentCondition = this.getCurrentSearchCondition();
    Log.debug(this.LOG_SOURCE, '現在の選択状況: ', currentCondition);

    if (!Diff.isDifferentObject(this.lastNotifiedCondition, currentCondition)) {
      Log.debug(this.LOG_SOURCE, '前回検索した条件と同じため無視します');
      return;
    }

    this.lastNotifiedCondition = { ...currentCondition };
    Log.debug(this.LOG_SOURCE, '検索項目が変更されました');

    // 検索条件が変わる度に、検索結果欄を非表示にする
    this.initializeSearchResults();
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
      this.setUpSearchResultComponent();
    }
    this.isShowMoreButtonShown$ = of(false);

    // 検索条件が未入力の場合は、大学校数検索処理をせず、結果も表示しない
    if (this.selectedPrefectures.length === 0 || this.selectedYear === undefined) {
      Log.warn(this.LOG_SOURCE, '検索条件が未入力です');
      this.store.dispatch(CommonIdSearchActions.initializeUniversityCountState());
    } else {
      this.findUniversityCount(currentCondition);
    }

    // 検索条件が未入力の場合、または、検索結果が０件の場合は検索ボタンをクリック不可にする
    this.setSearchButtonDisabled();
  }

  showMoreResults() {
    this.isShowMoreButtonShown$ = of(true);
    const condition = this.getCurrentSearchCondition();
    this.pageToSearch = this.currentPage + 1;

    // 検索結果用の配列を初期化する
    this.initializeSearchResults();

    this.findUniversities(condition, this.pageToSearch);

    // 前ページの検索結果コンポーネントをストアする - 前ページのお気に入りボタンも無効にするため
    const prevIndex = this.currentPage - 2;
    this.previousPageSearchResultComponents[prevIndex] = this.searchResultComponent;

    // 検索結果コンポーネントを表示する
    this.isSearchSummaryDisplayed = true;
    this.setUpSearchResultComponent();
  }

  setFirstPageSearchResults(condition: UniversitySearchCondition<PrefectureCondition>) {
    // 検索結果用の配列を初期化する
    this.initializeSearchResults();

    // 大学検索用APIから検索結果を取得する
    this.pageToSearch = 1;
    this.findUniversities(condition, this.pageToSearch);

    // 検索結果コンポーネントを表示する
    this.isSearchSummaryDisplayed = true;
    this.viewContainerRef.clear();
    this.setUpSearchResultComponent();
  }

  private findUniversityCount(condition: UniversitySearchCondition<PrefectureCondition>) {
    setTimeout(() => {
      this.store.dispatch(CommonIdSearchActions.findUniversityCountByPrefectureIds({ condition, logging: true }));
    });
  }

  private setUpCurrentDateTime() {
    this.store.dispatch(getCurrentDateTime());
    this.currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(
      filter<CommonIdUser>(it => it != null && it !== 'none'),
      take(1)
    );

    combineLatest([this.currentDateTime$, this.signedInUser$])
      .pipe(take(1))
      .subscribe(([currentDateTime, signedInUser]) => {
        this.isPremiumUser = CommonIdUserUtil.getIsPremiumUser(currentDateTime, signedInUser);
      });
  }

  private setUpYears() {
    this.selectableYears = COMMON_ID_SEARCH_YEARS.map(year => {
      const label = this.isPremiumUser || COMMON_ID_FREE_YEARS.includes(year) ? year : year + COMMON_ID_YEAR_LABEL_FOR_UNPAID_USERS;
      return {
        label,
        value: year
      };
    });
  }

  private setUpStaticCommonData() {
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpCommonIdVisitedPapers() {
    this.commonIdVisitedPapers$ = this.store.select(getCommonIdVisitedPapers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpMatchedUniversityCount() {
    this.matchedUniversityCount$ = this.store.select(getMatchedUniversityCount).pipe(shareReplay(1));
    this.universityCountSubscription = this.matchedUniversityCount$.subscribe(matchedUniversityCount => {
      this.matchedUniversityCount = matchedUniversityCount;
    });

    this.setSearchButtonDisabled();

    this.universityCountSearching$ = this.store.select(getUniversityCountSearching);
  }

  private setSearchButtonDisabled() {
    this.searchButtonDisabled$ = combineLatest([this.matchedUniversityCount$]).pipe(
      map(
        ([matchedUniversityCount]) =>
          matchedUniversityCount == null ||
          matchedUniversityCount === 0 ||
          this.selectedPrefectures.length === 0 ||
          this.selectedYear === undefined
      )
    );
  }

  private initializeSearchResults() {
    this.searchResults = [];
    this.searchResultsToDisplay = [];
    this.isSearchSummaryDisplayed = false;
  }

  private setUpIsUniversitySearching() {
    this.isUniversitySearching$ = this.store.select(getUniversitySearching);
  }

  private setUpIsShowMoreButton() {
    const numOfUniversitiesShown = this.currentPage * 3;
    this.isShowMoreButtonShown$ = combineLatest([this.matchedUniversityCount$]).pipe(
      map(
        ([matchedUniversityCount]) =>
          matchedUniversityCount > numOfUniversitiesShown && this.searchResultsToDisplay && this.searchResultsToDisplay.length > 0
      )
    );
    // もっと見るボタンを表示する度に、ローディングアイコンを非表示にする
    this.pageButton = this.pageToSearch;
  }

  private setUpUniversityBookmarks() {
    this.universityBookmark$ = this.store.select(CommonIdUniversityBookmarkSelectors.getUniversityBookmark).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpSearchResultComponent() {
    // 検索結果コンポーネントの生成を実行
    this.searchResultComponent = this.viewContainerRef.createComponent(this.factory);

    // データバインディング
    this.searchResultComponent.instance.searchResults = this.searchResultsToDisplay;
    this.searchResultComponent.instance.signedInUser$ = this.signedInUser$;
    this.searchResultComponent.instance.universityBookmark$ = this.universityBookmark$;
    this.searchResultComponent.instance.isPremiumIconShown = this.isPremiumIconShown;
    this.searchResultComponent.instance.isBtoC = true;
  }

  private setPreviousSearchCondition(condition: UniversitySearchCondition<PrefectureCondition>) {
    // 都道府県名に前回の検索条件を設定
    condition.universityCondition.prefectureIds.map(prefectureId => {
      const name = COMMON_ID_PREFECTURES.find(commonIdPrefecture => commonIdPrefecture.id === prefectureId).name;
      const region = COMMON_ID_PREFECTURES.find(commonIdPrefecture => commonIdPrefecture.id === prefectureId).region;
      this.selectedPrefectures.push({ id: prefectureId, name, region });
    });

    // 年度に前回の検索条件を設定
    this.selectedYear = condition.year.toString();

    // 学部系統に前回の検索条件を設定
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        this.selectableDepartmentCategories = [
          ...staticCommonData.departmentCategories.filter(it => !NO_DISPLAY_DEPARTMENT_CATEGORY_IDS.includes(it.id)),
          this.defaultDepartmentCategory
        ].sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : Number(b.id) > Number(a.id) ? -1 : 0));

        if (condition.departmentCategoryId) {
          const departmentCategoryId = condition.departmentCategoryId.toString();
          const depCategory = this.selectableDepartmentCategories.find(it => it.id === departmentCategoryId);
          this.selectedDepartmentCategory = depCategory;
        } else {
          this.selectedDepartmentCategory = this.defaultDepartmentCategory;
        }
      });

    // 大学種別に前回の検索条件を設定
    const universityTypeIds = condition.universityCondition.universityTypeIds;
    if (universityTypeIds && universityTypeIds.length > 0) {
      this.selectedUniversityTypeIds = universityTypeIds;
    } else {
      this.selectedUniversityTypeIds = [];
    }
  }

  private getCurrentSearchCondition(): UniversitySearchCondition<PrefectureCondition> {
    const prefectureIds = this.selectedPrefectures.map(prefecture => prefecture.id);

    const universityCondition: PrefectureCondition = {
      prefectureIds
    };
    if (this.selectedUniversityTypeIds.length !== 0) universityCondition.universityTypeIds = this.selectedUniversityTypeIds;

    const condition: UniversitySearchCondition<PrefectureCondition> = {
      type: UniversitySearchType.PREF,
      universityCondition,
      year: Number(this.selectedYear)
    };

    if (this.selectedDepartmentCategory.id !== this.defaultDepartmentCategory.id) {
      condition.departmentCategoryId = Number(this.selectedDepartmentCategory.id);
    }

    return condition;
  }

  private findUniversities(condition: UniversitySearchCondition<PrefectureCondition>, page: number) {
    Log.debug(this.LOG_SOURCE, `選択された page: ${page}`);
    this.currentPage = page;

    this.store.dispatch(CommonIdSearchActions.initializeUniversityPapersState());
    this.store.dispatch(CommonIdSearchActions.findUniversityPapersByPrefectureIds({ condition, page }));

    this.universities$ = this.store.select(getUniversities).pipe(filter(it => it != null));
    this.universities$.pipe(take(1)).subscribe(universities => {
      // staticCommonDataとcommonIdVisitedPapersとuniversityBookmarkの取得
      this.staticCommonData$.subscribe(staticCommonData => {
        this.staticCommonData = staticCommonData;
      });

      this.commonIdVisitedPapers$.subscribe(commonIdVisitedPapers => {
        this.commonIdVisitedPapers = commonIdVisitedPapers;
      });

      this.universityBookmark$.subscribe(bookmark => {
        this.universityBookmark = bookmark;
      });

      // 大学検索結果に、教科名称、閲覧済みフラグ、アイコンの表示有無フラグを追加
      this.searchResults = universities.map(university => {
        const departmentResults = university.departments.map(department => {
          const isCommonTest: boolean = university.universityId === COMMON_TEST_UNIVERSITY_ID ? true : false;
          return {
            ...department,
            papers: department.papers.map(paper => {
              return {
                ...paper,
                paperIds: paper.paperIds.map(paperId => {
                  const subjectId = paperId.paperId.substring(6, 8);
                  const subjectName = isCommonTest
                    ? CommonIdSubjectUtil.getSubjectNameForCommonTest(paperId.paperId, this.staticCommonData.subjects)
                    : SubjectUtil.getName(this.staticCommonData.subjects, subjectId);

                  return {
                    id: paperId.paperId,
                    daimonIds: paperId.daimonIds,
                    subjectName,
                    isVisited:
                      this.commonIdVisitedPapers &&
                      this.commonIdVisitedPapers.find(commonIdVisitedPaper => commonIdVisitedPaper === paperId.paperId)
                        ? true
                        : false,
                    isPremiumIconShown: this.isPremiumIconShown
                  };
                })
              };
            })
          };
        });

        const prefecture = COMMON_ID_PREFECTURES.find(pref => pref.id === university.prefectureId);
        const prefectureName = prefecture ? prefecture.name : COMMON_TEST_PREFECTURE_NAME;
        // 大学検索結果に、大学種別名と都道府県名、お気に入り登録の有無を追加
        return {
          ...university,
          universityTypeName: this.staticCommonData.universityTypes.find(universityType => universityType.id === university.universityType)
            .name,
          prefectureName,
          departments: departmentResults,
          isBookmarked:
            this.universityBookmark &&
            this.universityBookmark.universities &&
            this.universityBookmark.universities.find(bookmarkUniversity => bookmarkUniversity.unvId === university.universityId)
              ? true
              : false
        };
      });

      // 検索結果表示用の配列に、新しいページの大学検索結果を追加
      this.searchResults.map(searchResult => {
        this.searchResultsToDisplay.push(searchResult);
      });
      this.searchResultsToDisplay = DataUtil.sortObjectArrayBySpecificKey(this.searchResultsToDisplay, 'universityId');

      // 検索結果が１ページに４件以上存在する場合は、もっと見るボタンを表示する
      this.setUpIsShowMoreButton();
    });
  }

  private getCurrentSearchConditionFromBaseComponent(event: PrefectureChangeValuesParams) {
    this.selectedPrefectures = event.prefectures;
    this.selectedYear = event.year;
    this.selectedDepartmentCategory = event.department;
    this.selectedUniversityTypeIds = event.universityTypeIds;
  }

  private findUniversityByPreviousCondition(previousCondition: UniversitySearchCondition<PrefectureCondition>) {
    Log.debug(this.LOG_SOURCE, `前回の検索条件: `, previousCondition);
    this.setPreviousSearchCondition(previousCondition);

    // 検索結果欄を非表示にする
    this.initializeSearchResults();
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    if (this.previousPageSearchResultComponents.length > 0) {
      this.previousPageSearchResultComponents = [];
    }
    this.setUpSearchResultComponent();
    this.isShowMoreButtonShown$ = of(false);

    // 前回の検索条件が未入力の場合は、大学校数検索処理をせず、結果も表示しない
    if (this.selectedPrefectures.length === 0 || this.selectedYear === undefined) {
      Log.warn(this.LOG_SOURCE, '検索条件が未入力です');
      this.store.dispatch(CommonIdSearchActions.initializeUniversityCountState());
    } else {
      this.findUniversityCount(previousCondition);
    }

    // 結果表示ボタンを無効にする
    this.searchButtonDisabled$ = of(true);
    this.setFirstPageSearchResults(previousCondition);
  }

  private passBookmarkDataToChildComponents() {
    // お気に入りの登録または解除がされた場合に、前ページのお気に入りボタンも無効にする
    this.bookmarkClickEmittedSubsription = this.universitySearchResultSharedService.bookmarkClickEmitted$
      .pipe(filter(bookmarkClickEmitted => bookmarkClickEmitted !== null))
      .subscribe(bookmarkClickEmitted => {
        this.passBookmarkClickEmittedToChildComponents(bookmarkClickEmitted);
      });

    // お気に入りの登録のエラー発生時のボタンの挙動の制御
    this.errorUniversityIdSubsription = this.universitySearchResultSharedService.errorUniversityId$.subscribe(errorUniversityId => {
      this.passErrorUniversityIdToChildComponents(errorUniversityId);
    });
  }

  private passBookmarkClickEmittedToChildComponents(bookmarkClickEmitted: boolean) {
    // 全ページの検索結果コンポーネントの子コンポーネントに、お気に入り登録または解除のイベントフラグを渡す
    if (this.previousPageSearchResultComponents.length > 0) {
      this.previousPageSearchResultComponents.forEach(prevPageComponent => {
        if (prevPageComponent) {
          prevPageComponent.instance.bookmarkClickEmitted = bookmarkClickEmitted;
        }
      });
    }

    if (this.searchResultComponent) {
      this.searchResultComponent.instance.bookmarkClickEmitted = bookmarkClickEmitted;
    }
  }

  private passErrorUniversityIdToChildComponents(universityId: string) {
    // 全ページの検索結果コンポーネントの子コンポーネントに、お気に入り登録または解除のイベントフラグを渡す
    if (this.previousPageSearchResultComponents.length > 0) {
      this.previousPageSearchResultComponents.forEach(prevPageComponent => {
        if (prevPageComponent) {
          prevPageComponent.instance.errorUnivId$ = of(universityId);
        }
      });
    }

    if (this.searchResultComponent) {
      this.searchResultComponent.instance.errorUnivId$ = of(universityId);
    }
  }
}
