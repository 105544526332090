<div class="card-content-nav" #navContainer [attr.data-is-scrolled]="isScrolled">
  <nav mat-tab-nav-bar class="card-content-nav-list" [disableRipple]="true">
    <button
      mat-tab-link
      class="card-content-nav-list-item"
      [active]="menu.isActive"
      [disabled]="menu.disabled"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleRadius]="30"
      *ngFor="let menu of menus"
      (click)="menuClick.emit(menu.key)"
    >
      <mat-icon class="icon" [fontSet]="menu.fontSet ? menu.fontSet : ''">{{ menu.icon }}</mat-icon>
      {{ menu.label }}
    </button>
  </nav>
</div>
