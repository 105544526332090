import { createAction, props } from '@ngrx/store';
import { AppError } from '../errors/app-error';
import { FindUniversityRequest } from '../models/find-university-request';
import { FindUniversityResponse } from '../models/find-university-response';

// 各大学の大学情報と年度情報を取得する -------------------------------------------------------------------
export const findUniversity = createAction('[Find University] Find University', props<{ request: FindUniversityRequest }>());
export const findUniversitySuccess = createAction(
  '[Find University] Find University Success',
  props<{ response: FindUniversityResponse }>()
);
export const findUniversityFailure = createAction('[Find University] Find University Failure', props<{ error: AppError }>());

// 各大学の大学情報と年度情報をリセットする場合 -------------------------------------------------------------------------------------
export const initializeFindUniversityState = createAction('[Find University] Initialize Find University State');
