<article class="problem-detail-frame-for-print">
  <!-- MEMO: もともとのheaderとfooterは、safari(mac/iPhone)でchromeと異なる仕様。fixedによる全ページ配置ができない。そのため各ページ毎に .section-header, .section-footer として再配置
  <div class="print-header" *ngIf="menuType === 'spellcheck'">
    {{ year }}年 {{ subjectName }} {{ readableProblem?.university }} 研究・解答
  </div>
  <div class="print-header" *ngIf="menuType === 'article'">
    {{ year }}年 {{ subjectName }} {{ readableProblem?.university }} 問題
  </div>
  <div class="print-footer">
    <div class="print-footer-inner">
      <p class="print-footer-copyright"><small>© Obunsha Co.,Ltd. Powered by 全国大学入試問題正解</small></p>
      <div class="print-footer-id">{{ readableProblem?.id }}</div>
    </div>
  </div>
  -->

  <section class="print-page">
    <ng-container *ngTemplateOutlet="sectionHeader"></ng-container>
    <div class="cover">
      <div class="cover-inner">
        <div class="cover-logo">
          <img src="assets/images/app-logo-no-lead-gray.svg" alt="入試正解デジタル" />
        </div>
        <div class="cover-meta">
          <h1 class="cover-meta-title" *ngIf="readableProblem?.isOriginalProblem === true">{{ readableProblem?.originalOverview }}</h1>
          <h1 class="cover-meta-title" *ngIf="readableProblem?.isOriginalProblem !== true">{{ readableProblem?.university }}</h1>
          <div class="cover-meta-title-sub">
            <p>{{ subjectName }}</p>
            <p *ngIf="!paperMode && readableProblem?.isOriginalProblem && readableProblem?.showProblemNumberFlg">大問 {{ daimonName }}</p>
            <p *ngIf="!paperMode && !readableProblem?.isOriginalProblem">{{ year }}年度入試 大問 {{ daimonName }}</p>
            <p *ngIf="paperMode">{{ year }}年度入試</p>
            <p *ngIf="menuType === 'spellcheck'">研究・解答</p>
            <p *ngIf="menuType === 'article'">問題</p>
          </div>
          <div class="cover-meta-title-meta" *ngIf="readableProblem?.examPaper">
            <div class="cover-meta-title-meta-inner">
              <div>【学部】 {{ readableProblem?.departments }}</div>
              <div *ngIf="paperMode">【試験日】 {{ examDate }}</div>
              <div *ngIf="paperMode">【入試名】 {{ nyushiName }}</div>
              <div *ngIf="paperMode">【試験時間】{{ examTime }}</div>
              <div class="special-info" style="white-space: pre-wrap" *ngIf="paperMode && specialInfoData.length > 0">
                <div>【その他特記事項】 {{ specialInfoData.join('　') }}</div>
              </div>
            </div>
          </div>
          <div class="cover-meta-title-meta" *ngIf="readableProblem?.isOriginalProblem">
            <div class="cover-meta-title-meta-inner">
              <div>【出典】 {{ originalSources.join(', ') }}</div>
            </div>
          </div>
          <div class="cover-meta-title-meta" *ngIf="playlistTheme">
            <div class="cover-meta-title-meta-inner">
              <div>【セット名】 {{ seriesName ? seriesName + ' | ' : '' }}{{ playlist.name }}</div>
              <div>【テーマ名】 {{ theme.name }}</div>
              <div *ngIf="menuType === 'article' && readablePlaylistProblem && readablePlaylistProblem.comment">
                【コメント】 {{ readablePlaylistProblem.comment }}
              </div>
              <!-- TODO リリースまでコメントアウトしておく 2023/09/04 -->
              <!--              <div *ngIf="menuType === 'spellcheck' && readablePlaylistProblem && readablePlaylistProblem.answerComment">-->
              <!--                【コメント】 {{ readablePlaylistProblem.answerComment }}-->
              <!--              </div>-->
            </div>
          </div>
        </div>
        <!-- g画像領域 -->
        <div class="cover-f-area">
          <div class="cover-f-area-inner" *ngIf="fImage">
            <img src="{{ fImage.url }}" width="{{ fImage.width }}" height="{{ fImage.height }}" />
          </div>
        </div>
        <div class="cover-footer">
          <div class="cover-footer-logo">
            <img src="./assets/images/logo-company-k@3x.jpg" alt="旺文社" width="435" height="150" />
          </div>
          <div class="cover-footer-license" *ngIf="isBToC === false">
            「入試正解デジタル」のサービスに関する知的財産権その他一切の権利は、（株）旺文社または各情報提供者に帰属します。<br />
            本サービスに掲載の全部または一部の無断複製、配布、転載、譲渡等を禁止します。<br />
            本サービスの機能を利用して作成した出力コンテンツについては、ユーザーが所属する学校等における授業・講義において生徒もしくは受講者に印刷し配布する方法、またはユーザー自らの研究活動のためにユーザーが使用する方法に限り利用することができます。
          </div>
          <div class="cover-footer-license" *ngIf="isBToC === true">
            「入試正解デジタル」のサービスに関する知的財産権その他一切の権利は、（株）旺文社または各情報提供者に帰属します。<br />
            本サービスに掲載の全部または一部の無断複製、配布、転載、譲渡等を禁止します。<br />
            本サービスの機能を利用して作成した出力コンテンツについては、ユーザーが個人として自ら使用する範囲に限り利用することができます。
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="sectionFooter"></ng-container>
  </section>

  <!-- ▼ type1 の場合 -->
  <ng-container *ngIf="layoutType === 1">
    <section class="print-page" *ngFor="let pages of calcImageLayout1(layoutType, problemImages)">
      <ng-container *ngTemplateOutlet="sectionHeader"></ng-container>
      <div class="pd-layout" data-layout-type="1">
        <div class="pd-layout-group" *ngFor="let containers of pages">
          <div class="pd-layout-group-item" *ngFor="let image of containers">
            <img src="{{ image.url }}" width="{{ image.width }}" height="{{ image.height }}" />
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="sectionFooter"></ng-container>
    </section>
  </ng-container>
  <!-- △ type1 の場合 -->

  <!-- ▼ type2 の場合 -->
  <ng-container *ngIf="layoutType === 2">
    <section class="print-page" *ngFor="let pages of calcImageLayout2(layoutType, problemImages)">
      <ng-container *ngTemplateOutlet="sectionHeader"></ng-container>
      <div class="pd-layout" data-layout-type="2">
        <div class="pd-layout-group" *ngFor="let containers of pages">
          <div class="pd-layout-group-item" *ngFor="let image of containers">
            <img src="{{ image.url }}" width="{{ image.width }}" height="{{ image.height }}" />
            <!-- ▼ デバッグ用画像 -->
            <!-- <img src="https://placehold.jp/16/b3b3b3/ffffff/894x640.png?text={{ image.url }}-w='{{ image.width }}'-h='{{ image.height }}'" width="894" height="640" /> -->
            <!-- △ デバッグ用画像 -->
          </div>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="sectionFooter"></ng-container>
    </section>
  </ng-container>
  <!-- △ type2 の場合 -->

  <!-- ▼ type3 の場合 -->
  <ng-container *ngIf="layoutType === 3">
    <section class="print-page" *ngFor="let pages of calcImageLayout3(layoutType, problemImages)">
      <ng-container *ngTemplateOutlet="sectionHeader"></ng-container>
      <div class="pd-layout" data-layout-type="3">
        <ng-container *ngFor="let containers of pages; let i = index; last as last">
          <div class="pd-layout-group">
            <ng-container *ngFor="let image of containers">
              <div class="pd-layout-group-item">
                <img src="{{ image.url }}" width="{{ image.width }}" height="{{ image.height }}" />
                <!-- ▼ デバッグ用画像 -->
                <!-- <img src="https://placehold.jp/16/b3b3b3/ffffff/416x675.png?text={{ image.url }}-w='{{ image.width }}'-h='{{ image.height }}'" width="416" height="675" /> -->
                <!-- △ デバッグ用画像 -->
              </div>
              <div class="pd-layout-group-item" *ngIf="containers?.length === 1"></div>
            </ng-container>
          </div>
          <app-problem-detail-separater *ngIf="!last"></app-problem-detail-separater>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="sectionFooter"></ng-container>
    </section>
  </ng-container>
  <!-- △ type3 の場合 -->
</article>

<ng-template #sectionHeader>
  <div class="section-header">
    <div class="section-header-inner">
      <div class="section-header-problem">{{ headerProblemLabel }}</div>
      <!-- BtoS のみ表示 -->
      <div class="section-header-user" *ngIf="signedInUser">{{ signedInUser?.school }} {{ outputDate }}</div>
    </div>
  </div>
</ng-template>

<ng-template #sectionFooter>
  <div class="section-footer">
    <div class="section-footer-inner">
      <p class="section-footer-copyright"><small>© Obunsha Co.,Ltd. Powered by 全国大学入試問題正解</small></p>
      <div class="section-footer-id">{{ readableProblem?.id }}</div>
    </div>
  </div>
</ng-template>
