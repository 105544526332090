import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import * as CommonIdSearchActions from '../../actions/common-id/common-id-search.actions';
import { CommonIdUniversityService } from '../../services/common-id/common-id-university.service';
import { dispatchAppError } from '../../actions/core.actions';
import { FirebaseCallableFunctionError } from '../../errors/firebase-callable-function-error';

@Injectable()
export class CommonIdSearchEffects {
  private LOG_SOURCE = this.constructor.name;

  findUniversityCountByUniversityIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdSearchActions.findUniversityCountByUniversityIds),
      switchMap(action =>
        this.commonIdUniversityService.findUniversityCountByUniversityIds(action.condition, action.logging).pipe(
          map(result => CommonIdSearchActions.findUniversityCountByUniversityIdsSuccess({ result })),
          catchError(e =>
            of(
              CommonIdSearchActions.findUniversityCountByUniversityIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findUniversityCountByPrefectureIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdSearchActions.findUniversityCountByPrefectureIds),
      switchMap(action =>
        this.commonIdUniversityService.findUniversityCountByPrefectureIds(action.condition, action.logging).pipe(
          map(result => CommonIdSearchActions.findUniversityCountByPrefectureIdsSuccess({ result })),
          catchError(e =>
            of(
              CommonIdSearchActions.findUniversityCountByPrefectureIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findUniversityPapersByUniversityIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdSearchActions.findUniversityPapersByUniversityIds),
      switchMap(action =>
        this.commonIdUniversityService.findUniversityPapersByUniversityIds(action.condition, action.page).pipe(
          map(universities => CommonIdSearchActions.findUniversityPapersByUniversityIdsSuccess({ universities })),
          catchError(e =>
            of(
              CommonIdSearchActions.findUniversityPapersByUniversityIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findUniversityPapersByPrefectureIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdSearchActions.findUniversityPapersByPrefectureIds),
      switchMap(action =>
        this.commonIdUniversityService.findUniversityPapersByPrefectureIds(action.condition, action.page).pipe(
          map(universities => CommonIdSearchActions.findUniversityPapersByPrefectureIdsSuccess({ universities })),
          catchError(e =>
            of(
              CommonIdSearchActions.findUniversityPapersByPrefectureIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdUniversityService: CommonIdUniversityService) {}
}
