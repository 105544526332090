import { Component, Input, OnInit, HostListener, Inject } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { WINDOW_OBJECT } from '../../../utils/injection-tokens';

@Component({
  selector: 'app-provider-accordion',
  templateUrl: './provider-accordion.component.html',
  styleUrls: ['./provider-accordion.component.scss'],
  animations: [
    trigger('accordion', [
      transition(':enter', [
        style({ height: '0', opacity: 0, overflow: 'hidden' }),
        animate('300ms ease-in-out', style({ height: '*', opacity: 1 }))
      ]),
      transition(':leave', [style({ height: '*', opacity: '1', overflow: 'hidden' }), animate('300ms ease-in-out', style({ height: '0' }))])
    ])
  ]
})
export class ProviderAccordionComponent implements OnInit {
  @Input() isExpanded: boolean;
  constructor(@Inject(DOCUMENT) private document: Document, @Inject(WINDOW_OBJECT) private window: Window) {}
  ngOnInit() {}

  @HostListener('click', ['$event'])
  onClick(event) {
    const element = event.target;
    const href = element.getAttribute('href');

    // href属性が「#〜」の形で振られているaタグをクリックした場合
    if (element.tagName === 'A' && /^#/.test(href)) {
      event.preventDefault();

      const id = href.slice(1);
      const targetElement = this.document.getElementById(`${id}`);
      const rectTop = targetElement.getBoundingClientRect().top;
      const offsetTop = this.window.pageYOffset;
      const buffer = 70; // headerの高さ
      const top = rectTop + offsetTop - buffer;
      this.window.scrollTo({
        top,
        behavior: 'smooth'
      });
    }
  }
}
