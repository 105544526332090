import { Pipe, PipeTransform } from '@angular/core';

const THEME_STRING = '<b>出題テーマ:</b> ';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    if (!value) return value;

    const regExps = args.map(arg => {
      const escapedArg = arg.replace(/\?/g, '\\?');
      return new RegExp(escapedArg, 'gi');
    });

    return regExps.reduce((currentValue, regExp) => {
      const strings = currentValue.split('\n');
      return strings
        .map(str => {
          if (!str.indexOf(THEME_STRING)) {
            return THEME_STRING + str.substr(THEME_STRING.length).replace(regExp, '<mark>$&</mark>');
          } else {
            return str.replace(regExp, '<mark>$&</mark>');
          }
        })
        .join('\n');
    }, value);
  }
}
