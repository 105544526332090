import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { filter, shareReplay, take } from 'rxjs/operators';

import { navigate } from '../../../actions/core.actions';
import { signOut } from 'src/app/actions/auth.actions';
import { openWindow, unsubscribeWatchedProps } from 'src/app/actions/core.actions';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import { findSignedInUserPlans } from 'src/app/actions/plan.actions';
import { RootState } from '../../../reducers';
import { getSignedInUserPlans } from 'src/app/selectors/plan.selectors';
import * as InformationSelectors from 'src/app/selectors/information.selectors';
import { getSignedInUser } from '../../../selectors/auth.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../selectors/current-date-time.selectors';
import { AuthService } from '../../../services/auth.service';

import {
  JUKU_ORGANIZATION_ID,
  OBUNSHA_ORGANIZATION_ID,
  OTHER_ORGANIZATION_ID,
  PlanStatuses,
  SCHOOL_ORGANIZATION_ID,
  SUBJECT_NAMES,
  UnsubscribeTarget
} from 'src/app/resources/config';
import { PlanUtil, UserAssignmentPlan } from 'src/app/utils/plan-util';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { User } from '../../../models/user';

@Component({
  selector: 'app-sign-in-header',
  templateUrl: './sign-in-header.component.html',
  styleUrls: ['./sign-in-header.component.scss']
})
export class SignInHeaderComponent implements OnInit, OnDestroy {
  private isAdmin: boolean;
  private organization: string;
  private schoolId: string;
  private currentUrl: string;
  private userInfoParentIsFocused = false;
  private userInfoItemsIsFocused: boolean[] = [];
  private subscriptions: Subscription[] = [];
  private visibleSubjectNames = new BehaviorSubject<string>('');
  visibleSubjectNames$ = this.visibleSubjectNames.asObservable();
  user$: Observable<User>;
  isSchoolOrJuku: boolean;
  newInformation$: Observable<boolean>;

  constructor(private store: Store<RootState>, private router: Router, private authService: AuthService) {}

  ngOnInit() {
    this.setUpUser();
    this.currentUrl = this.router.url;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
    });
    this.newInformation$ = this.store.select(InformationSelectors.getNewInformation).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.subscriptions.push(
      this.authService.signedInUserId$.subscribe(userId => {
        if (userId.includes('common-id')) {
          this.store.dispatch(openWindow({ url: RoutingPathResolver.resolveSignIn(), target: '_self' }));
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  touchendUserInfoDropdown() {
    if (this.userInfoParentIsFocused) {
      setTimeout(() => {
        // 遅延させないと遷移が発生しなくなる
        this.resetUserInfoDropdown();
      }, 10);
    } else {
      this.userInfoParentIsFocused = true;
    }
  }

  hoverUserInfoDropdown() {
    this.userInfoParentIsFocused = true;
  }

  focusUserInfoParent() {
    this.userInfoParentIsFocused = true;
  }

  blurUserInfoParent() {
    this.userInfoParentIsFocused = false;
  }

  focusUserInfoItem(index: number) {
    this.userInfoItemsIsFocused[index] = true;
  }

  blurUserInfoItem(index: number) {
    this.userInfoItemsIsFocused[index] = false;
  }

  showUserInfoDropdown(): boolean {
    function checkItem(val: boolean): boolean {
      return val;
    }
    return this.userInfoParentIsFocused || this.userInfoItemsIsFocused.some(b => checkItem(b));
  }

  resetUserInfoDropdown() {
    this.userInfoParentIsFocused = false;
    this.userInfoItemsIsFocused = [];
  }

  showSearch() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearch() }));
  }

  showUniversitySearch() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearchUniv() }));
  }

  showPlaylists() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlaylists() }));
  }

  showBookmarkProblems() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveBookmarkProblems() }));
  }

  showAccounts() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAccounts() }));
  }

  showPlans() {
    this.store.dispatch(
      navigate({ url: RoutingPathResolver.resolvePlans(this.isAdmin ? this.organization : null, this.isAdmin ? this.schoolId : null) })
    );
  }

  showAdmin() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
  }

  showHelp() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveHelp() }));
  }

  showInformation() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveInformation() }));
  }

  isCurrentSearch(): boolean {
    return (
      this.currentUrl === '/' + RoutingPathResolver.resolveSearch() ||
      this.currentUrl.includes('/' + RoutingPathResolver.resolveSearchResults())
    );
  }

  isCurrentUniversitySearch(): boolean {
    return this.currentUrl.includes(RoutingPathResolver.resolveSearchUniv());
  }

  isCurrentPlaylists(): boolean {
    return this.currentUrl.includes(RoutingPathResolver.resolvePlaylists());
  }

  isCurrentBookmarkProblems(): boolean {
    return this.currentUrl === '/' + RoutingPathResolver.resolveBookmarkProblems();
  }

  isCurrentPlans(): boolean {
    return (
      this.currentUrl ===
      '/' + RoutingPathResolver.resolvePlans(this.isAdmin ? this.organization : null, this.isAdmin ? this.schoolId : null)
    );
  }

  isCurrentAdmin(): boolean {
    return this.currentUrl.includes(RoutingPathResolver.resolveAdmin());
  }

  signOut() {
    this.resetUserInfoDropdown();
    this.store.dispatch(signOut());
    this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.ON_SIGN_OUT }));
    setTimeout(() => this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSignIn() })));
  }

  private setUpUser() {
    this.store.dispatch(getCurrentDateTime());
    this.user$ = this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none'));
    this.findSignedInUserPlansIfNeeded();

    const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const signedInUserPlans$ = this.store.select(getSignedInUserPlans).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.subscriptions.push(
      combineLatest([currentDateTime$, signedInUserPlans$, this.user$]).subscribe(([currentDateTime, plans, user]) => {
        this.isAdmin = user.isAdmin;
        this.organization = user.organization;
        this.schoolId = user.schoolId;
        this.isSchoolOrJuku = user.organization === SCHOOL_ORGANIZATION_ID || user.organization === JUKU_ORGANIZATION_ID ? true : false;
        if (user.organization === OBUNSHA_ORGANIZATION_ID || user.organization === OTHER_ORGANIZATION_ID || user.isTrial) {
          const visibleSubjectNames = user.visibleSubjectIds
            .map((SubjectId, index, array) => `<span>${SUBJECT_NAMES[SubjectId]}${index < array.length - 1 ? ',' : ''}</span>`)
            .join(' ');
          this.visibleSubjectNames.next(visibleSubjectNames);
        } else {
          const userAssignmentPlan: UserAssignmentPlan | null = PlanUtil.getUserAssignmentPlan(user.id, plans, currentDateTime);
          if (
            userAssignmentPlan &&
            userAssignmentPlan.status !== PlanStatuses.ENDED &&
            userAssignmentPlan.status !== PlanStatuses.RESERVE
          ) {
            const visibleSubjectNames = userAssignmentPlan.assignmentSubjectIds
              .map((SubjectId, index, array) => `<span>${SUBJECT_NAMES[SubjectId]}${index < array.length - 1 ? ',' : ''}</span>`)
              .join(' ');
            this.visibleSubjectNames.next(visibleSubjectNames);
          } else {
            this.visibleSubjectNames.next('未割当');
          }
        }
      })
    );
  }

  private findSignedInUserPlansIfNeeded() {
    const signedInUserPlans$ = this.store.select(getSignedInUserPlans);
    combineLatest([this.user$, signedInUserPlans$])
      .pipe(take(1))
      .subscribe(([user, signedInUserPlans]) => {
        if (signedInUserPlans === null) {
          this.store.dispatch(findSignedInUserPlans({ signedInUser: user }));
        }
      });
  }
}
