import { Action, createReducer, on } from '@ngrx/store';

import * as CommonIdPlaylistActions from '../../actions/common-id/common-id-playlist.actions';
import { CommonIdPlaylist } from '../../models/common-id/common-id-playlist';
import {
  SciencePlaylistProblem,
  EnglishPlaylistProblem,
  NationalLanguagePlaylistProblem,
  HistoryPlaylistProblem
} from '../../models/problem';

export const commonIdPlaylistFeatureKey = 'commonIdPlaylist';

export interface ResolvePdfObjectUrlResult {
  success: boolean;
  pdfPath: string;
  objectUrl: string | null;
}

export interface State {
  playlists: CommonIdPlaylist[] | null;

  /** playlist の problem id 達 */
  playlistProblemIds: string[] | null;

  /** 英語の playlist に紐づく問題達 */
  englishPlaylistProblems: EnglishPlaylistProblem[] | null;

  /** 数学の playlist に紐づく問題達 */
  mathPlaylistProblems: SciencePlaylistProblem[] | null;

  /** 国語の playlist に紐づく問題達 */
  nationalLanguagePlaylistProblems: NationalLanguagePlaylistProblem[] | null;

  /** 物理の playlist に紐づく問題達 */
  physicsPlaylistProblems: SciencePlaylistProblem[] | null;

  /** 化学の playlist に紐づく問題達 */
  chemistryPlaylistProblems: SciencePlaylistProblem[] | null;

  /** 生物の playlist に紐づく問題達 */
  biologyPlaylistProblems: SciencePlaylistProblem[] | null;

  /** 日本史の playlist に紐づく問題達 */
  japaneseHistoryPlaylistProblems: HistoryPlaylistProblem[] | null;

  /** 世界史の playlist に紐づく問題達 */
  worldHistoryPlaylistProblems: HistoryPlaylistProblem[] | null;

  /** 地理の playlist に紐づく問題達 */
  geographyPlaylistProblems: SciencePlaylistProblem[] | null;

  /** 政治経済の playlist に紐づく問題達 */
  politicalEconomyPlaylistProblems: SciencePlaylistProblem[] | null;

  /**
   * 各問題の PDF を ObjectURL に変換した結果保持用
   * 複数同時ダウンロードに対応するために配列になっています
   */
  resolvePdfObjectUrlResults: ResolvePdfObjectUrlResult[];
}

export const initialState: State = {
  playlists: null,
  playlistProblemIds: null,
  englishPlaylistProblems: null,
  mathPlaylistProblems: null,
  nationalLanguagePlaylistProblems: null,
  physicsPlaylistProblems: null,
  chemistryPlaylistProblems: null,
  biologyPlaylistProblems: null,
  japaneseHistoryPlaylistProblems: null,
  worldHistoryPlaylistProblems: null,
  geographyPlaylistProblems: null,
  politicalEconomyPlaylistProblems: null,
  resolvePdfObjectUrlResults: []
};

const playlistReducer = createReducer(
  initialState,

  // Find Playlists -------------------------------------------------------
  on(CommonIdPlaylistActions.findPlaylistsSuccess, (state, { playlists }) => ({ ...state, playlists })),

  // Find Playlist Problem ids -------------------------------------------------------
  on(CommonIdPlaylistActions.findPlaylistProblemIdsSuccess, (state, { playlistProblemIds }) => ({ ...state, playlistProblemIds })),
  on(CommonIdPlaylistActions.initializePlaylistProblemIdsState, state => {
    const next: State = { ...state, playlistProblemIds: null };
    return next;
  }),

  // Find English Playlist Problems -------------------------------------------------------
  on(CommonIdPlaylistActions.findEnglishPlaylistProblemsSuccess, (state, { englishPlaylistProblems }) => ({
    ...state,
    englishPlaylistProblems
  })),
  on(CommonIdPlaylistActions.initializeEnglishPlaylistProblemsState, state => {
    const next: State = { ...state, englishPlaylistProblems: null };
    return next;
  }),

  // Find Math Playlist Problems -------------------------------------------------------
  on(CommonIdPlaylistActions.findMathPlaylistProblemsSuccess, (state, { mathPlaylistProblems }) => ({
    ...state,
    mathPlaylistProblems
  })),
  on(CommonIdPlaylistActions.initializeMathPlaylistProblemsState, state => {
    const next: State = { ...state, mathPlaylistProblems: null };
    return next;
  }),

  // Find National Language Playlist Problems -------------------------------------------------------
  on(CommonIdPlaylistActions.findNationalLanguagePlaylistProblemsSuccess, (state, { nationalLanguagePlaylistProblems }) => ({
    ...state,
    nationalLanguagePlaylistProblems
  })),
  on(CommonIdPlaylistActions.initializeNationalLanguagePlaylistProblemsState, state => {
    const next: State = { ...state, nationalLanguagePlaylistProblems: null };
    return next;
  }),

  // Find Physics Playlist Problems -------------------------------------------------------
  on(CommonIdPlaylistActions.findPhysicsPlaylistProblemsSuccess, (state, { physicsPlaylistProblems }) => ({
    ...state,
    physicsPlaylistProblems
  })),
  on(CommonIdPlaylistActions.initializePhysicsPlaylistProblemsState, state => {
    const next: State = { ...state, physicsPlaylistProblems: null };
    return next;
  }),

  // Find Chemistry Playlist Problems -------------------------------------------------------
  on(CommonIdPlaylistActions.findChemistryPlaylistProblemsSuccess, (state, { chemistryPlaylistProblems }) => ({
    ...state,
    chemistryPlaylistProblems
  })),
  on(CommonIdPlaylistActions.initializeChemistryPlaylistProblemsState, state => {
    const next: State = { ...state, chemistryPlaylistProblems: null };
    return next;
  }),

  // Find Biology Playlist Problems -------------------------------------------------------
  on(CommonIdPlaylistActions.findBiologyPlaylistProblemsSuccess, (state, { biologyPlaylistProblems }) => ({
    ...state,
    biologyPlaylistProblems
  })),
  on(CommonIdPlaylistActions.initializeBiologyPlaylistProblemsState, state => {
    const next: State = { ...state, biologyPlaylistProblems: null };
    return next;
  }),

  // Find Japanese History Playlist Problems -------------------------------------------------------
  on(CommonIdPlaylistActions.findJapaneseHistoryPlaylistProblemsSuccess, (state, { japaneseHistoryPlaylistProblems }) => ({
    ...state,
    japaneseHistoryPlaylistProblems
  })),
  on(CommonIdPlaylistActions.initializeJapaneseHistoryPlaylistProblemsState, state => {
    const next: State = { ...state, japaneseHistoryPlaylistProblems: null };
    return next;
  }),

  // Find World History Playlist Problems -------------------------------------------------------
  on(CommonIdPlaylistActions.findWorldHistoryPlaylistProblemsSuccess, (state, { worldHistoryPlaylistProblems }) => ({
    ...state,
    worldHistoryPlaylistProblems
  })),
  on(CommonIdPlaylistActions.initializeWorldHistoryPlaylistProblemsState, state => {
    const next: State = { ...state, worldHistoryPlaylistProblems: null };
    return next;
  }),

  // Find Geography Playlist Problems -------------------------------------------------------
  on(CommonIdPlaylistActions.findGeographyPlaylistProblemsSuccess, (state, { geographyPlaylistProblems }) => ({
    ...state,
    geographyPlaylistProblems
  })),
  on(CommonIdPlaylistActions.initializeGeographyPlaylistProblemsState, state => {
    const next: State = { ...state, geographyPlaylistProblems: null };
    return next;
  }),

  // Find Political Economy Playlist Problems -------------------------------------------------------
  on(CommonIdPlaylistActions.findPoliticalEconomyPlaylistProblemsSuccess, (state, { politicalEconomyPlaylistProblems }) => ({
    ...state,
    politicalEconomyPlaylistProblems
  })),
  on(CommonIdPlaylistActions.initializePoliticalEconomyPlaylistProblemsState, state => {
    const next: State = { ...state, politicalEconomyPlaylistProblems: null };
    return next;
  }),

  // Resolve Pdf Object Url -------------------------------------------------------
  on(CommonIdPlaylistActions.resolvePdfObjectUrlSuccess, (state, { pdfPath, objectUrl }) => {
    const resolvePdfObjectUrlResults: ResolvePdfObjectUrlResult[] = [
      ...state.resolvePdfObjectUrlResults,
      { success: true, pdfPath, objectUrl }
    ];
    return { ...state, resolvePdfObjectUrlResults };
  }),
  on(CommonIdPlaylistActions.resolvePdfObjectUrlFailure, (state, { pdfPath }) => {
    const resolvePdfObjectUrlResults: ResolvePdfObjectUrlResult[] = [
      ...state.resolvePdfObjectUrlResults,
      { success: false, pdfPath, objectUrl: null }
    ];
    return { ...state, resolvePdfObjectUrlResults };
  }),
  on(CommonIdPlaylistActions.initializePdfObjectUrl, (state, { pdfPath }) => {
    const resolvePdfObjectUrlResults = state.resolvePdfObjectUrlResults.filter(it => it.pdfPath !== pdfPath);
    return { ...state, resolvePdfObjectUrlResults };
  }),
  on(CommonIdPlaylistActions.initializePdfObjectUrlState, state => {
    const next: State = { ...state, resolvePdfObjectUrlResults: [] };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return playlistReducer(state, action);
}
