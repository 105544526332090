import { createFeatureSelector, createSelector } from '@ngrx/store';
import { commonIdPaperBookmarkFeatureKey, State } from '../../reducers/common-id/common-id-paper-bookmark.reducer';

const selectCommonIdPaperBookmarkState = createFeatureSelector<State>(commonIdPaperBookmarkFeatureKey);

export const getCommonIdPaperBookmark = createSelector(selectCommonIdPaperBookmarkState, state => state.paperBookmark);
export const getCommonIdAddPaperBookmarkResult = createSelector(selectCommonIdPaperBookmarkState, state => state.addPaperBookmarkResult);
export const getCommonIdDeletePaperBookmarkResult = createSelector(
  selectCommonIdPaperBookmarkState,
  staet => staet.deletePaperBookmarkResult
);
