import { Component, OnInit, Input } from '@angular/core';

type FontSet = '' | 'material-icons-outlined' | 'material-icons-round' | 'material-icons-two-tone' | 'material-icons-sharp';

@Component({
  selector: 'app-label-with-icon',
  templateUrl: './label-with-icon.component.html',
  styleUrls: ['./label-with-icon.component.scss']
})
export class LabelWithIconComponent implements OnInit {
  @Input() iconName: string;
  @Input() iconFontSet: FontSet;

  constructor() {}
  ngOnInit() {}
}
