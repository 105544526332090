<ng-container *ngIf="shortMode">
  <ng-container *ngIf="subjectDataArray.length > 0">分野: 指定あり / </ng-container>
</ng-container>
<div class="root-container" *ngIf="!shortMode">
  <div class="not-selected" *ngIf="subjectDataArray.length === 0">選択されていません</div>

  <ng-container *ngFor="let subject of subjectDataArray">
    <div class="subjects-container">
      <div class="label subject-name">{{ subject.subjectName }}</div>
      <div class="fields-container" *ngIf="subject.allFieldSelected">
        <div class="label label-all">すべて</div>
      </div>

      <div class="fields-container" *ngIf="!subject.allFieldSelected">
        <div class="field" *ngFor="let field of subject.fields">
          <div class="label field-name" *ngIf="!isSubjectDataType()">{{ field.fieldName }}</div>
          <div class="label" *ngIf="field.allUnitSelected">すべて</div>

          <div class="units-container" *ngIf="!field.allUnitSelected">
            <div class="unit" *ngFor="let unit of field.units">
              <div class="label unit-name">{{ unit.unitName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <mat-divider></mat-divider> -->
  </ng-container>
</div>
