import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import { Dates } from 'src/app/utils/dates';

export class CommonIdUserUtil {
  static setDefaultValues(user: CommonIdUser): CommonIdUser {
    if (user === null) {
      return user;
    }

    return user;
  }

  static getIsPremiumUser(currentDateTime: CurrentDateTime, user: CommonIdUser): boolean {
    // ユーザー情報が取得できない場合
    if (user === undefined) {
      return false;
    }

    // プレミアム有効期限のデータを保持しない場合は無料ユーザー
    if (user.premiumStartAt === undefined || user.premiumEndAt === undefined) {
      return false;
    }

    // 有効期限は日付のみで判定する
    const currentDateTimeString = Dates.simple4YmdStringFromIso(currentDateTime?.dateTime);
    const startAtString = Dates.simple4YmdStringFromIso(user.premiumStartAt);
    const endAtString = Dates.simple4YmdStringFromIso(user.premiumEndAt);

    // プレミアム有効期限内のユーザーはプレミアム会員とする
    return currentDateTimeString >= startAtString && currentDateTimeString <= endAtString ? true : false;
  }

  static getPremiumUserLeftDay(currentDateTime: CurrentDateTime, user: CommonIdUser): number {
    // ユーザー情報が取得できない場合
    if (user === undefined) {
      return 0;
    }

    // プレミアム有効期限のデータを保持しない場合は無料ユーザー
    if (user.premiumStartAt === undefined || user.premiumEndAt === undefined) {
      return 0;
    }

    const currentDateTimeString = Dates.stdDateStringFromIso(currentDateTime?.dateTime);
    const endAtString = Dates.stdDateStringFromIso(user.premiumEndAt);

    return Dates.getDiffDay(currentDateTimeString, endAtString);
  }
}
