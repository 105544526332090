import { Action, createReducer, on } from '@ngrx/store';

import * as CurrentDateTimeActions from '../actions/current-date-time.actions';
import { CurrentDateTime } from '../models/current-date-time';

export const currentDateTimeFeatureKey = 'currentDateTime';

export interface State {
  currentDateTime: CurrentDateTime | null;
}

export const initialState: State = {
  currentDateTime: null
};

const currentDateTimeReducer = createReducer(
  initialState,

  // Get CurrentDateTime -------------------------------------------------------
  on(CurrentDateTimeActions.getCurrentDateTimeSuccess, (state, { currentDateTime }) => ({ ...state, currentDateTime })),
  on(CurrentDateTimeActions.initializeGetCurrentDateTime, state => {
    const next: State = { ...state, currentDateTime: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return currentDateTimeReducer(state, action);
}
