import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as CommonIdUniversityBookmarkActions from '../../actions/common-id/common-id-university-bookmark.actions';
import { CommonIdUniversityBookmarkService } from '../../services/common-id/common-id-university-bookmark.service';
import { dispatchAppError } from '../../actions/core.actions';
import { FirestoreError } from '../../errors/firestore-error';
import { FirebaseCallableFunctionError } from '../../errors/firebase-callable-function-error';
import { AppError } from '../../errors/app-error';

@Injectable()
export class CommonIdUniversityBookmarkEffects {
  private LOG_SOURCE = this.constructor.name;

  findUniversityBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdUniversityBookmarkActions.findUniversityBookmark),
      switchMap(action =>
        this.commonIdUniversityBookmarkService.findUniversityBookmark(action.userId).pipe(
          map(universityBookmark => CommonIdUniversityBookmarkActions.findUniversityBookmarkSuccess({ universityBookmark })),
          catchError(e => {
            const error = e instanceof AppError ? e : FirestoreError.from(e);
            return of(
              CommonIdUniversityBookmarkActions.findUniversityBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error })
            );
          })
        )
      )
    )
  );

  addUniversityBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdUniversityBookmarkActions.addUniversityBookmark),
      switchMap(action =>
        this.commonIdUniversityBookmarkService.addUniversityBookmark(action.condition).pipe(
          map(response => CommonIdUniversityBookmarkActions.addUniversityBookmarkSuccess({ response })),
          catchError(e =>
            of(CommonIdUniversityBookmarkActions.addUniversityBookmarkFailure({ error: FirebaseCallableFunctionError.from(e) }))
          )
        )
      )
    )
  );

  deleteUniversityBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdUniversityBookmarkActions.deleteUniversityBookmark),
      switchMap(action =>
        this.commonIdUniversityBookmarkService.deleteUniversityBookmark(action.condition).pipe(
          map(response => CommonIdUniversityBookmarkActions.deleteUniversityBookmarkSuccess({ response })),
          catchError(e =>
            of(CommonIdUniversityBookmarkActions.deleteUniversityBookmarkFailure({ error: FirebaseCallableFunctionError.from(e) }))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdUniversityBookmarkService: CommonIdUniversityBookmarkService) {}
}
