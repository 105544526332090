<div class="root-container">
  <div class="header">
    <div class="spacer"></div>
    <div class="close-mark" (click)="dismiss()">×</div>
  </div>

  <div class="content">
    <div class="mat-body">{{ appError.message }}</div>
    <div class="mat-body error-code error-code-color">{{ appError.code }}</div>
  </div>
</div>
