import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, map } from 'rxjs';

import * as CommonIdGetCustomTokenActions from '../../actions/common-id/common-id-get-custom-token.actions';
import { CommonIdGetCustomTokenService } from '../../services/common-id/common-id-get-custom-token.service';
import { FirebaseCallableFunctionError } from 'src/app/errors/firebase-callable-function-error';

@Injectable()
export class CommonIdGetCustomTokenEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdGetCustomToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdGetCustomTokenActions.commonIdGetCustomToken),
      switchMap(action =>
        this.commonIdGetCustomTokenService.commonIdGetCustomToken(action.request).pipe(
          map(response => CommonIdGetCustomTokenActions.commonIdGetCustomTokenSuccess({ response })),
          catchError(e => of(CommonIdGetCustomTokenActions.commonIdGetCustomTokenFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdGetCustomTokenService: CommonIdGetCustomTokenService) {}
}
