<section class="card-content">
  <!-- app-card-content-head はこの位置に出力 -->
  <ng-content select="app-card-content-head"></ng-content>
  <!-- app-card-content-nav はこの位置に出力 -->
  <ng-content select="app-card-content-nav"></ng-content>
  <!-- headとbodyの間のライン -->
  <hr class="card-content-separator" *ngIf="showSeparator" />
  <!-- slot -->
  <div class="card-content-body">
    <ng-content></ng-content>
  </div>
</section>
