import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-toggle-paper-bookmark-icon',
  templateUrl: './button-toggle-paper-bookmark-icon.component.html',
  styleUrls: ['./button-toggle-paper-bookmark-icon.component.scss']
})
export class ButtonTogglePaperBookmarkIconComponent implements OnInit {
  constructor() {}

  @Input() isPaperBookmarked = false;
  @Input() disabled = false;
  @Input() canSpinner = false;
  @Output() bookmarkClick = new EventEmitter();

  showSpinner = false;

  ngOnInit() {}

  toggle() {
    if (this.canSpinner) {
      this.showSpinner = true;
    }

    this.bookmarkClick.emit();
  }
}
