import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { UnsubscribeTarget } from '../resources/config';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private unsubscribedSubject = new Subject<UnsubscribeTarget>();

  unsubscribed$ = this.unsubscribedSubject.asObservable();

  constructor() {}

  unsubscribe(target: UnsubscribeTarget) {
    this.unsubscribedSubject.next(target);
  }
}
