import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { PlanStatuses } from './../../../resources/config';

import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';

import { navigate, setTitle } from '../../../actions/core.actions';
import * as UniversitySearchActions from '../../../actions/university-search.actions';
import { RootState } from '../../../reducers';
import { getCommonIdVisitedPapers } from '../../../selectors/common-id/common-id-visited-paper.selectors';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';
import {
  getMatchedUniversityCount,
  getUniversities,
  getUniversityCountSearching,
  getUniversitySearching
} from '../../../selectors/university-search.selectors';

import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import {
  COMMON_ID_PREFECTURES,
  COMMON_ID_SEARCH_YEARS,
  COMMON_TEST_PREFECTURE_NAME,
  COMMON_TEST_UNIVERSITY_ID,
  DEFAULT_DEPARTMENT_CATEGORY
} from '../../../resources/common-id-config';

import { DepartmentCategory, University } from '../../../models/common-data';
import { FindUniversityPapersResponse } from '../../../models/find-university-papers-response';
import { FindUniversityPapersResult } from '../../../models/find-university-papers-result';
import { StaticCommonData } from '../../../models/static-common-data';
import { UniversityCondition, UniversitySearchCondition, UniversitySearchType } from '../../../models/university-search-condition';

import { UniversitySearchRouterService } from '../../../services/university-search-router.service';
import { CommonIdSubjectUtil } from '../../../utils/common-id/common-id-subject-util';
import { Diff } from '../../../utils/diff';
import { Log } from '../../../utils/log';
import { SubjectUtil } from '../../../utils/subject-util';

import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import { UniversitySearchQueryParamsMapper } from 'src/app/mappers/university-search-query-params-mapper';
import { User } from 'src/app/models/user';
import {
  NO_DISPLAY_DEPARTMENT_CATEGORY_IDS,
  OBUNSHA_ORGANIZATION_ID,
  OTHER_ORGANIZATION_ID,
  SUBJECT_NAMES
} from 'src/app/resources/config';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { getSignedInUserPlans } from 'src/app/selectors/plan.selectors';
import { PlanUtil, UserAssignmentPlan } from 'src/app/utils/plan-util';
import { setBrowserTitle } from '../../../actions/core.actions';
import { ChangeValuesParams, Year } from '../../../models/search-univ-interfaces';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../selectors/current-date-time.selectors';
import { DataUtil } from '../../../utils/data-util';
import { SearchUnivResultComponent } from '../search-univ-result/search-univ-result.component';

@Component({
  selector: 'app-search-univ',
  templateUrl: './search-univ.component.html',
  styleUrls: ['./search-univ.component.scss']
})
export class SearchUnivComponent implements OnInit, OnDestroy, AfterViewInit {
  factory: ComponentFactory<SearchUnivResultComponent>;
  @ViewChild('searchUnivResult', { static: true, read: ViewContainerRef }) viewContainerRef: ViewContainerRef;
  searchResultComponent: ComponentRef<SearchUnivResultComponent>;

  constructor(
    private resolver: ComponentFactoryResolver,
    private store: Store<RootState>,
    private universitySearchRouterService: UniversitySearchRouterService
  ) {}

  private LOG_SOURCE = this.constructor.name;
  private title = '大学名で探す';
  private lastNotifiedCondition: UniversitySearchCondition<UniversityCondition> | undefined;
  private universityCountSubscription: Subscription;
  private visibleSubjectNames = new BehaviorSubject<string>('');

  isSearchSummaryDisplayed: boolean;

  matchedUniversityCount: number | undefined;

  selectedUniversities: University[] = [];
  defaultDepartmentCategory: DepartmentCategory = DEFAULT_DEPARTMENT_CATEGORY;

  selectableYears: Year[];
  selectedYear: string | null;
  selectedDepartmentCategory: DepartmentCategory;
  selectableDepartmentCategories: DepartmentCategory[];

  matchedUniversityCount$: Observable<number>;
  universityCountSearching$: Observable<boolean>;
  searchButtonDisabled$: Observable<boolean>;

  staticCommonData$: Observable<StaticCommonData>;
  staticCommonData: StaticCommonData;

  pageToSearch: number;
  currentPage: number;
  universities$: Observable<FindUniversityPapersResponse[]>;
  searchResults: FindUniversityPapersResult[] = [];
  searchResultsToDisplay: FindUniversityPapersResult[] = [];

  isUniversitySearching$: Observable<boolean>;
  isShowMoreButtonShown$: Observable<boolean>;
  pageButton: number;

  commonIdVisitedPapers$: Observable<string[]>;
  commonIdVisitedPapers: string[];

  previousCondition: UniversitySearchCondition<UniversityCondition>;

  visibleSubjectNames$ = this.visibleSubjectNames.asObservable();

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    // 動的に大学検索結果コンポーネントをコンパイルできる状態にする
    this.factory = this.resolver.resolveComponentFactory(SearchUnivResultComponent);

    this.setUpStaticCommonData();
    this.setUpYears();
    this.setUpCommonIdVisitedPapers();
    this.setUpMatchedUniversityCount();
    this.initializeSearchResults();
    this.setUpIsUniversitySearching();
    this.setVisibleSubjectNames();

    // 大学詳細画面からの戻りであれば元の検索条件をセットし検索する
    this.previousCondition = this.universitySearchRouterService.getPreviousSearchCondition();
    if (this.universitySearchRouterService.isFromLocationBack && this.previousCondition) {
      this.findUniversityByPreviousCondition(this.previousCondition);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.universityCountSubscription) this.universityCountSubscription.unsubscribe();
    this.universityCountSubscription = undefined;

    this.store.dispatch(UniversitySearchActions.initializeUniversityCountState());
    this.store.dispatch(UniversitySearchActions.initializeUniversityPapersState());

    if (this.searchResultComponent) {
      // 都道府県で探すタブを選択した際、searchResultComponentはundefinedになる
      this.searchResultComponent.destroy();
    }
  }

  menuClickHandler(key) {
    switch (key) {
      case 'school':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearchUniv() }));
        break;
      case 'map':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearchUnivPref() }));
        break;
    }
  }

  resetForms(event: ChangeValuesParams) {
    // 共通コンポーネントより、最新の検索条件を取得
    this.getCurrentSearchConditionFromBaseComponent(event);

    this.lastNotifiedCondition = undefined;

    this.store.dispatch(UniversitySearchActions.initializeUniversityCountState());
    this.store.dispatch(UniversitySearchActions.initializeUniversityPapersState());

    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearchUniv(), extras: {} }));
    this.initializeSearchResults();

    if (this.viewContainerRef) this.viewContainerRef.clear();
    if (this.searchResultComponent) this.searchResultComponent.destroy();
  }

  showSearchResultView() {
    const condition = this.getCurrentSearchCondition();
    this.setFirstPageSearchResults(condition);

    const extras: NavigationExtras = {
      queryParams: UniversitySearchQueryParamsMapper.encodeUniversitySearchConditionWithUniversityIds(condition)
    };
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearchUniv(), extras }));

    // 結果を表示ボタンを無効にする
    this.searchButtonDisabled$ = of(true);
  }

  onChangeValues(event: ChangeValuesParams) {
    // 共通コンポーネントより、最新の検索条件を取得
    this.getCurrentSearchConditionFromBaseComponent(event);

    // 現在の選択条件を取得して保存
    const currentCondition = this.getCurrentSearchCondition();
    Log.debug(this.LOG_SOURCE, '現在の選択状況: ', currentCondition);

    if (!Diff.isDifferentObject(this.lastNotifiedCondition, currentCondition)) {
      Log.debug(this.LOG_SOURCE, '前回検索した条件と同じため無視します');
      return;
    }

    this.lastNotifiedCondition = { ...currentCondition };
    Log.debug(this.LOG_SOURCE, '検索項目が変更されました');

    // 検索条件が変わる度に、検索結果欄を非表示にする
    this.initializeSearchResults();
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    this.setUpSearchResultComponent();
    this.isShowMoreButtonShown$ = of(false);

    // 検索条件が未入力の場合は、大学校数検索処理をせず、結果も表示しない
    if (this.selectedUniversities.length === 0 || this.selectedYear === undefined) {
      Log.warn(this.LOG_SOURCE, '検索条件が未入力です');
      this.store.dispatch(UniversitySearchActions.initializeUniversityCountState());
    } else {
      this.findUniversityCount(currentCondition);
    }

    // 検索条件が未入力の場合、または、検索結果が０件の場合は検索ボタンをクリック不可にする
    this.setSearchButtonDisabled();
  }

  showMoreResults() {
    this.isShowMoreButtonShown$ = of(true);
    const condition = this.getCurrentSearchCondition();
    this.pageToSearch = this.currentPage + 1;

    // 検索結果用の配列を初期化する
    this.initializeSearchResults();

    this.findUniversities(condition, this.pageToSearch);

    // 検索結果コンポーネントを表示する
    this.isSearchSummaryDisplayed = true;
    this.setUpSearchResultComponent();
  }

  setFirstPageSearchResults(condition: UniversitySearchCondition<UniversityCondition>) {
    // 検索結果用の配列を初期化する
    this.initializeSearchResults();

    // 大学検索用APIから検索結果を取得する
    this.pageToSearch = 1;
    this.findUniversities(condition, this.pageToSearch);

    // 検索結果コンポーネントを表示する
    this.isSearchSummaryDisplayed = true;

    this.viewContainerRef.clear();
    this.setUpSearchResultComponent();
  }

  private findUniversityCount(condition: UniversitySearchCondition<UniversityCondition>) {
    setTimeout(() => {
      this.store.dispatch(UniversitySearchActions.findUniversityCountByUniversityIds({ condition, logging: true }));
    });
  }

  private findUniversities(condition: UniversitySearchCondition<UniversityCondition>, page: number) {
    Log.debug(this.LOG_SOURCE, `選択された page: ${page}`);
    this.currentPage = page;

    this.store.dispatch(UniversitySearchActions.initializeUniversityPapersState());
    this.store.dispatch(UniversitySearchActions.findUniversityPapersByUniversityIds({ condition, page }));

    this.universities$ = this.store.select(getUniversities).pipe(filter(it => it != null));
    this.universities$.pipe(take(1)).subscribe(universities => {
      // staticCommonDataとcommonIdVisitedPapersの取得
      this.staticCommonData$.subscribe(staticCommonData => {
        this.staticCommonData = staticCommonData;
      });

      this.commonIdVisitedPapers$.subscribe(commonIdVisitedPapers => {
        this.commonIdVisitedPapers = commonIdVisitedPapers;
      });

      // 大学検索結果に、教科名称、閲覧済みフラグ、アイコンの表示有無フラグを追加
      this.searchResults = universities.map(university => {
        const isCommonTest: boolean = university.universityId === COMMON_TEST_UNIVERSITY_ID ? true : false;
        const departmentResults = university.departments.map(department => {
          return {
            ...department,
            papers: department.papers.map(paper => {
              return {
                ...paper,
                paperIds: paper.paperIds.map(paperId => {
                  const subjectId = paperId.paperId.substring(6, 8);
                  const subjectName = isCommonTest
                    ? CommonIdSubjectUtil.getSubjectNameForCommonTest(paperId.paperId, this.staticCommonData.subjects)
                    : SubjectUtil.getName(this.staticCommonData.subjects, subjectId);

                  return {
                    id: paperId.paperId,
                    daimonIds: paperId.daimonIds,
                    subjectName,
                    isVisited:
                      this.commonIdVisitedPapers &&
                      this.commonIdVisitedPapers.find(commonIdVisitedPaper => commonIdVisitedPaper === paperId.paperId)
                        ? true
                        : false
                  };
                })
              };
            })
          };
        });

        const prefecture = COMMON_ID_PREFECTURES.find(pref => pref.id === university.prefectureId);
        const prefectureName = prefecture ? prefecture.name : COMMON_TEST_PREFECTURE_NAME;
        // 大学検索結果に、大学種別名と都道府県名、お気に入り登録の有無を追加
        return {
          ...university,
          universityTypeName: this.staticCommonData.universityTypes.find(universityType => universityType.id === university.universityType)
            .name,
          prefectureName,
          departments: departmentResults
        };
      });

      // 検索結果表示用の配列に、新しいページの大学検索結果を追加
      this.searchResults.map(searchResult => {
        this.searchResultsToDisplay.push(searchResult);
      });
      this.searchResultsToDisplay = DataUtil.sortObjectArrayBySpecificKey(this.searchResultsToDisplay, 'universityId');

      // 検索結果が１ページに４件以上存在する場合は、もっと見るボタンを表示する
      this.setUpIsShowMoreButton();
    });
  }

  private getCurrentSearchCondition(): UniversitySearchCondition<UniversityCondition> {
    const universityIds = this.selectedUniversities.map(university => university.id);

    const condition: UniversitySearchCondition<UniversityCondition> = {
      type: UniversitySearchType.UNIV,
      universityCondition: { universityIds },
      year: Number(this.selectedYear)
    };

    if (this.selectedDepartmentCategory.id !== this.defaultDepartmentCategory.id) {
      condition.departmentCategoryId = Number(this.selectedDepartmentCategory.id);
    }

    return condition;
  }

  private setUpStaticCommonData() {
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpYears() {
    this.selectableYears = COMMON_ID_SEARCH_YEARS.map(year => {
      return {
        label: year,
        value: year
      };
    });
  }

  private setUpCommonIdVisitedPapers() {
    this.commonIdVisitedPapers$ = this.store.select(getCommonIdVisitedPapers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpMatchedUniversityCount() {
    this.matchedUniversityCount$ = this.store.select(getMatchedUniversityCount).pipe(shareReplay(1));
    this.universityCountSubscription = this.matchedUniversityCount$.subscribe(matchedUniversityCount => {
      this.matchedUniversityCount = matchedUniversityCount;
    });

    this.setSearchButtonDisabled();

    this.universityCountSearching$ = this.store.select(getUniversityCountSearching);
  }

  private setSearchButtonDisabled() {
    this.searchButtonDisabled$ = combineLatest([this.matchedUniversityCount$]).pipe(
      map(
        ([matchedUniversityCount]) =>
          matchedUniversityCount == null ||
          matchedUniversityCount === 0 ||
          this.selectedUniversities.length === 0 ||
          this.selectedYear === undefined
      )
    );
  }

  private initializeSearchResults() {
    this.searchResults = [];
    this.searchResultsToDisplay = [];
    this.isSearchSummaryDisplayed = false;
  }

  private setUpIsUniversitySearching() {
    this.isUniversitySearching$ = this.store.select(getUniversitySearching);
  }

  private setUpIsShowMoreButton() {
    const numOfUniversitiesShown = this.currentPage * 3;
    this.isShowMoreButtonShown$ = combineLatest([this.matchedUniversityCount$]).pipe(
      map(
        ([matchedUniversityCount]) =>
          matchedUniversityCount > numOfUniversitiesShown && this.searchResultsToDisplay && this.searchResultsToDisplay.length > 0
      )
    );
    // もっと見るボタンを表示する度に、ローディングアイコンを非表示にする
    this.pageButton = this.pageToSearch;
  }

  private setUpSearchResultComponent() {
    // 検索結果コンポーネントの生成を実行
    this.searchResultComponent = this.viewContainerRef.createComponent(this.factory);

    // データバインディング
    this.searchResultComponent.instance.searchResults = this.searchResultsToDisplay;
    this.searchResultComponent.instance.isBtoC = false;
  }

  private getCurrentSearchConditionFromBaseComponent(event: ChangeValuesParams) {
    this.selectedUniversities = event.universities;
    this.selectedYear = event.year;
    this.selectedDepartmentCategory = event.department;
  }

  private findUniversityByPreviousCondition(previousCondition: UniversitySearchCondition<UniversityCondition>) {
    Log.debug(this.LOG_SOURCE, `前回の検索条件: `, previousCondition);
    this.setPreviousSearchCondition(previousCondition);

    // 検索結果欄を非表示にする
    this.initializeSearchResults();
    if (this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
    this.setUpSearchResultComponent();
    this.isShowMoreButtonShown$ = of(false);

    // 前回の検索条件が未入力の場合は、大学校数検索処理をせず、結果も表示しない
    if (this.selectedUniversities.length === 0 || this.selectedYear === undefined) {
      Log.warn(this.LOG_SOURCE, '前回の検索条件が未入力です');
      this.store.dispatch(UniversitySearchActions.initializeUniversityCountState());
    } else {
      this.findUniversityCount(this.previousCondition);
    }

    // 結果表示ボタンを無効にする
    this.searchButtonDisabled$ = of(true);
    this.setFirstPageSearchResults(previousCondition);
  }

  private setPreviousSearchCondition(condition: UniversitySearchCondition<UniversityCondition>) {
    // 前回の年度を設定
    this.selectedYear = this.previousCondition.year.toString();

    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        // 前回の大学名を設定
        condition.universityCondition.universityIds.map(universityId => {
          const name = staticCommonData.universities.find(staticUniversity => staticUniversity.id === universityId).name;
          this.selectedUniversities.push({ id: universityId, name });
        });

        // 前回の学部系統を設定
        this.selectableDepartmentCategories = [
          ...staticCommonData.departmentCategories.filter(it => !NO_DISPLAY_DEPARTMENT_CATEGORY_IDS.includes(it.id)),
          this.defaultDepartmentCategory
        ].sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : Number(b.id) > Number(a.id) ? -1 : 0));

        if (condition.departmentCategoryId) {
          const departmentCategoryId = condition.departmentCategoryId.toString();
          const depCategory = this.selectableDepartmentCategories.find(it => it.id === departmentCategoryId);
          this.selectedDepartmentCategory = depCategory;
        } else {
          this.selectedDepartmentCategory = this.defaultDepartmentCategory;
        }
      });
  }

  private setVisibleSubjectNames() {
    this.store.dispatch(getCurrentDateTime());
    const user$ = this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none'));

    const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const signedInUserPlans$ = this.store.select(getSignedInUserPlans).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([currentDateTime$, signedInUserPlans$, user$])
      .pipe(take(1))
      .subscribe(([currentDateTime, plans, user]) => {
        if (user.organization === OBUNSHA_ORGANIZATION_ID || user.organization === OTHER_ORGANIZATION_ID || user.isTrial) {
          const visibleSubjectNames = user.visibleSubjectIds
            .map((SubjectId, index, array) => `<span>${SUBJECT_NAMES[SubjectId]}${index < array.length - 1 ? ',' : ''}</span>`)
            .join(' ');
          this.visibleSubjectNames.next(visibleSubjectNames);
        } else {
          const userAssignmentPlan: UserAssignmentPlan | null = PlanUtil.getUserAssignmentPlan(user.id, plans, currentDateTime);
          if (
            userAssignmentPlan &&
            userAssignmentPlan.status !== PlanStatuses.ENDED &&
            userAssignmentPlan.status !== PlanStatuses.RESERVE
          ) {
            const visibleSubjectNames = userAssignmentPlan.assignmentSubjectIds
              .map((SubjectId, index, array) => `<span>${SUBJECT_NAMES[SubjectId]}${index < array.length - 1 ? ',' : ''}</span>`)
              .join(' ');
            this.visibleSubjectNames.next(visibleSubjectNames);
          } else {
            this.visibleSubjectNames.next('未割当');
          }
        }
      });
  }
}
