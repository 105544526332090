import { Action, createReducer, on } from '@ngrx/store';

import * as BookmarkActions from '../actions/bookmark.actions';
import { Bookmark } from '../models/bookmark';
import { AppError } from '../errors/app-error';
import { AddBookmarkResponse } from '../models/add-bookmark-response';
import { DeleteBookmarkResponse } from '../models/delete-bookmark-response';
import { EnglishProblem, HistoryProblem, NationalLanguageProblem, ScienceProblem } from '../models/problem';

export const bookmarkFeatureKey = 'bookmark';

export interface AddBookmarkResult {
  success: boolean;
  response: AddBookmarkResponse | null;
  error: AppError | null;
}

export interface DeleteBookmarkResult {
  success: boolean;
  response: DeleteBookmarkResponse | null;
  error: AppError | null;
}

export interface State {
  bookmark: Bookmark | null;
  addBookmarkResult: AddBookmarkResult | null;
  deleteBookmarkResult: DeleteBookmarkResult | null;

  bookmarkCount: number | null;
  bookmarkSearching: boolean;

  englishBookmarkProblems: EnglishProblem[] | null;
  mathBookmarkProblems: ScienceProblem[] | null;
  nationalLanguageBookmarkProblems: NationalLanguageProblem[] | null;
  physicsBookmarkProblems: ScienceProblem[] | null;
  chemistryBookmarkProblems: ScienceProblem[] | null;
  biologyBookmarkProblems: ScienceProblem[] | null;
  japaneseHistoryBookmarkProblems: HistoryProblem[] | null;
  worldHistoryBookmarkProblems: HistoryProblem[] | null;
  geographyBookmarkProblems: ScienceProblem[] | null;
  politicalEconomyBookmarkProblems: ScienceProblem[] | null;
}

export const initialState: State = {
  bookmark: null,
  addBookmarkResult: null,
  deleteBookmarkResult: null,
  bookmarkCount: 0,
  bookmarkSearching: false,
  englishBookmarkProblems: null,
  mathBookmarkProblems: null,
  nationalLanguageBookmarkProblems: null,
  physicsBookmarkProblems: null,
  chemistryBookmarkProblems: null,
  biologyBookmarkProblems: null,
  japaneseHistoryBookmarkProblems: null,
  worldHistoryBookmarkProblems: null,
  geographyBookmarkProblems: null,
  politicalEconomyBookmarkProblems: null
};

const BookmarkReducer = createReducer(
  initialState,

  // Find Bookmarks -------------------------------------------------------
  on(BookmarkActions.findBookmarkSuccess, (state, { bookmark }) => ({ ...state, bookmark })),
  on(BookmarkActions.initializeFindBookmarkState, state => {
    const next: State = { ...state, bookmark: null };
    return next;
  }),

  // Add Bookmark ----------------------------------------------------------------
  on(BookmarkActions.addBookmarkSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      addBookmarkResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(BookmarkActions.addBookmarkFailure, (state, { error }) => {
    const next: State = {
      ...state,
      addBookmarkResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(BookmarkActions.initializeAddBookmarkState, state => {
    const next: State = { ...state, addBookmarkResult: null };
    return next;
  }),

  // Delete Bookmark ----------------------------------------------------------------
  on(BookmarkActions.deleteBookmarkSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      deleteBookmarkResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(BookmarkActions.deleteBookmarkFailure, (state, { error }) => {
    const next: State = {
      ...state,
      deleteBookmarkResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(BookmarkActions.initializeDeleteBookmarkState, state => {
    const next: State = { ...state, deleteBookmarkResult: null };
    return next;
  }),

  // Search Bookmark Count -------------------------------------------------------
  on(BookmarkActions.findBookmarkCount, state => ({ ...state })),
  on(BookmarkActions.findBookmarkCountSuccess, (state, { response }) => ({
    ...state,
    bookmarkCount: response.count
  })),
  on(BookmarkActions.findBookmarkCountFailure, state => ({ ...state, bookmarkCount: 0 })),
  on(BookmarkActions.initializeFindBookmarkCountState, state => {
    const next: State = { ...state, bookmarkCount: null };
    return next;
  }),

  // Search English Bookmark -------------------------------------------------------
  on(BookmarkActions.findEnglishBookmark, state => ({ ...state, bookmarkSearching: true })),
  on(BookmarkActions.findEnglishBookmarkSuccess, (state, { problems }) => ({
    ...state,
    englishBookmarkProblems: problems,
    bookmarkSearching: false
  })),
  on(BookmarkActions.findEnglishBookmarkFailure, state => ({ ...state, bookmarkSearching: false })),
  on(BookmarkActions.initializeFindEnglishBookmarkState, state => {
    const next: State = { ...state, englishBookmarkProblems: null };
    return next;
  }),

  // Search Math Bookmark -------------------------------------------------------
  on(BookmarkActions.findMathBookmark, state => ({ ...state, bookmarkSearching: true })),
  on(BookmarkActions.findMathBookmarkSuccess, (state, { problems }) => ({
    ...state,
    mathBookmarkProblems: problems,
    bookmarkSearching: false
  })),
  on(BookmarkActions.findMathBookmarkFailure, state => ({ ...state, bookmarkSearching: false })),
  on(BookmarkActions.initializeFindMathBookmarkState, state => {
    const next: State = { ...state, mathBookmarkProblems: null };
    return next;
  }),

  // Search NationalLanguage Bookmark -------------------------------------------------------
  on(BookmarkActions.findNationalLanguageBookmark, state => ({ ...state, bookmarkSearching: true })),
  on(BookmarkActions.findNationalLanguageBookmarkSuccess, (state, { problems }) => ({
    ...state,
    nationalLanguageBookmarkProblems: problems,
    bookmarkSearching: false
  })),
  on(BookmarkActions.findNationalLanguageBookmarkFailure, state => ({ ...state, bookmarkSearching: false })),
  on(BookmarkActions.initializeFindNationalLanguageBookmarkState, state => {
    const next: State = { ...state, nationalLanguageBookmarkProblems: null };
    return next;
  }),

  // Search Physics Bookmark -------------------------------------------------------
  on(BookmarkActions.findPhysicsBookmark, state => ({ ...state, bookmarkSearching: true })),
  on(BookmarkActions.findPhysicsBookmarkSuccess, (state, { problems }) => ({
    ...state,
    physicsBookmarkProblems: problems,
    bookmarkSearching: false
  })),
  on(BookmarkActions.findPhysicsBookmarkFailure, state => ({ ...state, bookmarkSearching: false })),
  on(BookmarkActions.initializeFindPhysicsBookmarkState, state => {
    const next: State = { ...state, physicsBookmarkProblems: null };
    return next;
  }),

  // Search Chemistry Bookmark -------------------------------------------------------
  on(BookmarkActions.findChemistryBookmark, state => ({ ...state, bookmarkSearching: true })),
  on(BookmarkActions.findChemistryBookmarkSuccess, (state, { problems }) => ({
    ...state,
    chemistryBookmarkProblems: problems,
    bookmarkSearching: false
  })),
  on(BookmarkActions.findChemistryBookmarkFailure, state => ({ ...state, bookmarkSearching: false })),
  on(BookmarkActions.initializeFindChemistryBookmarkState, state => {
    const next: State = { ...state, chemistryBookmarkProblems: null };
    return next;
  }),

  // Search Biology Bookmark -------------------------------------------------------
  on(BookmarkActions.findBiologyBookmark, state => ({ ...state, bookmarkSearching: true })),
  on(BookmarkActions.findBiologyBookmarkSuccess, (state, { problems }) => ({
    ...state,
    biologyBookmarkProblems: problems,
    bookmarkSearching: false
  })),
  on(BookmarkActions.findBiologyBookmarkFailure, state => ({ ...state, bookmarkSearching: false })),
  on(BookmarkActions.initializeFindBiologyBookmarkState, state => {
    const next: State = { ...state, biologyBookmarkProblems: null };
    return next;
  }),

  // Search JapaneseHistory Bookmark -------------------------------------------------------
  on(BookmarkActions.findJapaneseHistoryBookmark, state => ({ ...state, bookmarkSearching: true })),
  on(BookmarkActions.findJapaneseHistoryBookmarkSuccess, (state, { problems }) => ({
    ...state,
    japaneseHistoryBookmarkProblems: problems,
    bookmarkSearching: false
  })),
  on(BookmarkActions.findJapaneseHistoryBookmarkFailure, state => ({ ...state, bookmarkSearching: false })),
  on(BookmarkActions.initializeFindJapaneseHistoryBookmarkState, state => {
    const next: State = { ...state, japaneseHistoryBookmarkProblems: null };
    return next;
  }),

  // Search WorldHistory Bookmark -------------------------------------------------------
  on(BookmarkActions.findWorldHistoryBookmark, state => ({ ...state, bookmarkSearching: true })),
  on(BookmarkActions.findWorldHistoryBookmarkSuccess, (state, { problems }) => ({
    ...state,
    worldHistoryBookmarkProblems: problems,
    bookmarkSearching: false
  })),
  on(BookmarkActions.findWorldHistoryBookmarkFailure, state => ({ ...state, bookmarkSearching: false })),
  on(BookmarkActions.initializeFindWorldHistoryBookmarkState, state => {
    const next: State = { ...state, worldHistoryBookmarkProblems: null };
    return next;
  }),

  // Search Geography Bookmark -------------------------------------------------------
  on(BookmarkActions.findGeographyBookmark, state => ({ ...state, bookmarkSearching: true })),
  on(BookmarkActions.findGeographyBookmarkSuccess, (state, { problems }) => ({
    ...state,
    geographyBookmarkProblems: problems,
    bookmarkSearching: false
  })),
  on(BookmarkActions.findGeographyBookmarkFailure, state => ({ ...state, bookmarkSearching: false })),
  on(BookmarkActions.initializeFindGeographyBookmarkState, state => {
    const next: State = { ...state, geographyBookmarkProblems: null };
    return next;
  }),

  // Search Political Economy Bookmark -------------------------------------------------------
  on(BookmarkActions.findPoliticalEconomyBookmark, state => ({ ...state, bookmarkSearching: true })),
  on(BookmarkActions.findPoliticalEconomyBookmarkSuccess, (state, { problems }) => ({
    ...state,
    politicalEconomyBookmarkProblems: problems,
    bookmarkSearching: false
  })),
  on(BookmarkActions.findPoliticalEconomyBookmarkFailure, state => ({ ...state, bookmarkSearching: false })),
  on(BookmarkActions.initializeFindPoliticalEconomyBookmarkState, state => {
    const next: State = { ...state, politicalEconomyBookmarkProblems: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return BookmarkReducer(state, action);
}
