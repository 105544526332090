import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, filter, shareReplay, switchMap } from 'rxjs/operators';

import { RootState } from '../reducers';
import { getSignedInUser } from '../selectors/auth.selectors';
import { findSignedInUser } from '../actions/auth.actions';
import { Log } from '../utils/log';
import { navigate } from '../actions/core.actions';
import { RoutingPathResolver } from '../app-routing-path-resolver';
import { findSignedInUserPlans, initializeFindSignedInUserPlansState } from '../actions/plan.actions';
import { getCurrentDateTime, initializeGetCurrentDateTime } from '../actions/current-date-time.actions';
import { getSignedInUserPlans } from '../selectors/plan.selectors';
import { User } from '../models/user';
import { UserUtil } from '../utils/user-util';
import { CurrentDateTime } from '../models/current-date-time';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';
import { Plan } from '../models/plan';

@Injectable({
  providedIn: 'root'
})
export class CheckActivePlanGuard implements CanActivate {
  checkActivePlanError$: Observable<boolean>;
  user: User;
  plans: Plan[];
  currentDatetime: CurrentDateTime;

  constructor(private store: Store<RootState>) {}

  canActivate(): Observable<boolean> {
    this.findSignedInUserIfNeeded();
    this.store.dispatch(initializeFindSignedInUserPlansState());
    this.store.dispatch(initializeGetCurrentDateTime());

    return this.store
      .select(getSignedInUser)
      .pipe(
        filter<User | 'none'>(it => it != null),
        take(1),
        switchMap(signedInUser => {
          if (signedInUser !== 'none') {
            this.user = signedInUser;
            this.store.dispatch(findSignedInUserPlans({ signedInUser }));
            const plans$ = this.store.select(getSignedInUserPlans).pipe(
              filter(it => it != null),
              shareReplay(1)
            );

            return plans$;
          } else {
            // 認証情報が取得できなかった場合はリダイレクト
            this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSignIn() }));
            return of([]);
          }
        })
      )
      .pipe(
        take(1),
        switchMap(plans => {
          this.store.dispatch(getCurrentDateTime());
          const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
            filter(it => it != null),
            shareReplay(1)
          );
          this.plans = plans;

          return currentDateTime$;
        })
      )
      .pipe(
        take(1),
        switchMap(currentDatetime => {
          this.currentDatetime = currentDatetime;

          const result = this.user === undefined ? false : UserUtil.checkActivePlan(this.user, this.plans, this.currentDatetime);
          if (!result) {
            Log.warn(this.constructor.name, `アクティブプランチェックでエラーとなりました。`);
            const url = RoutingPathResolver.resolveCheckActivePlanError();
            this.store.dispatch(navigate({ url }));
          }

          return of(result);
        })
      );
  }

  private findSignedInUserIfNeeded() {
    this.store
      .select(getSignedInUser)
      .pipe(take(1))
      .subscribe(user => {
        if (user == null) this.store.dispatch(findSignedInUser());
      });
  }
}
