import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, throwError, of } from 'rxjs';
import { filter, take, map, shareReplay, switchMap, startWith } from 'rxjs/operators';

import * as SearchActions from 'src/app/actions/search.actions';
import * as SearchSelectors from 'src/app/selectors/search.selectors';
import { RootState } from 'src/app/reducers';
import { SearchCondition, SearchProblemsCondition } from 'src/app/models/search-condition';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import { SubjectId, STATIC_DATA_CACHE_DAYS, META_VIEWPORT_OTHER, SCIENCE_IDS } from 'src/app/resources/config';
import { Log } from 'src/app/utils/log';
import { dispatchAppError, openPrintDialog, setBrowserTitle } from 'src/app/actions/core.actions';
import {
  getStaticMathData,
  getFetchedDate,
  getStaticPhysicsData,
  getStaticChemistryData,
  getStaticBiologyData,
  getStaticJapaneseHistoryData,
  getStaticWorldHistoryData,
  getStaticGeographyData,
  getStaticPoliticalEconomyData
} from 'src/app/selectors/static-data.selectors';
import {
  ReadableScienceProblem,
  ReadableEnglishProblem,
  ReadableHistoryProblem,
  ReadableNationalLanguageProblem
} from 'src/app/models/problem';
import { ReadableCommonSearchCondition } from 'src/app/models/common-search-condition';
import { enter } from 'src/app/resources/animations';
import { EnglishSearchCondition, ReadableEnglishSearchCondition } from 'src/app/models/english-search-condition';
import { HistorySearchCondition, ReadableHistorySearchCondition } from 'src/app/models/history-search-condition';
import { QueryParamsMapper } from 'src/app/mappers/query-params-mapper';
import { GeneralError } from 'src/app/errors/general-error';
import { findStaticData, initializeStaticDataState } from 'src/app/actions/static-data.actions';
import { SearchConditionMapper } from 'src/app/mappers/search-condition-mapper';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { ReadableDataMapper } from 'src/app/mappers/readable-data-mapper';
import { CustomErrorMessage } from 'src/app/errors/error-info';
import { Dates } from 'src/app/utils/dates';
import {
  NationalLanguageSearchCondition,
  ReadableNationalLanguageSearchCondition
} from 'src/app/models/national-language-search-condition';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
  animations: [enter]
})
export class PrintComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private searchProblemsCondition$: Observable<SearchProblemsCondition<EnglishSearchCondition | ScienceSearchCondition>>;

  ENGLISH_SUBJECT_ID: string;
  MATH_SUBJECT_ID: string;
  NATIONAL_LANGUAGE_SUBJECT_ID: string;
  PHYSICS_SUBJECT_ID: string;
  CHEMISTRY_SUBJECT_ID: string;
  BIOLOGY_SUBJECT_ID: string;
  JAPANESE_HISTORY_SUBJECT_ID: string;
  WORLD_HISTORY_SUBJECT_ID: string;
  GEOGRAPHY_SUBJECT_ID: string;
  POLITICAL_ECONOMY_SUBJECT_ID: string;

  scienceIds = SCIENCE_IDS;

  staticMathData$: Observable<StaticScienceData>;
  staticPhysicsData$: Observable<StaticScienceData>;
  staticChemistryData$: Observable<StaticScienceData>;
  staticBiologyData$: Observable<StaticScienceData>;
  staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  staticWorldHistoryData$: Observable<StaticHistoryData>;
  staticGeographyData$: Observable<StaticScienceData>;
  staticPoliticalEconomyData$: Observable<StaticScienceData>;
  readableEnglishProblems$: Observable<ReadableEnglishProblem[]>;
  readableMathProblems$: Observable<ReadableScienceProblem[]>;
  readableNationalLanguageProblems$: Observable<ReadableNationalLanguageProblem[]>;
  readablePhysicsProblems$: Observable<ReadableScienceProblem[]>;
  readableChemistryProblems$: Observable<ReadableScienceProblem[]>;
  readableBiologyProblems$: Observable<ReadableScienceProblem[]>;
  readableJapaneseHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableWorldHistoryProblems$: Observable<ReadableHistoryProblem[]>;
  readableGeographyProblems$: Observable<ReadableScienceProblem[]>;
  readablePoliticalEconomyProblems$: Observable<ReadableScienceProblem[]>;
  totalProblemCount$: Observable<number>;
  initialized$: Observable<boolean>;

  currentSubjectId$: Observable<string>;
  readableSearchCondition$: Observable<ReadableCommonSearchCondition>;
  readableEnglishCondition$: Observable<ReadableEnglishSearchCondition>;
  readableNationalLanguageCondition$: Observable<ReadableNationalLanguageSearchCondition>;
  readableJapaneseHistoryCondition$: Observable<ReadableHistorySearchCondition>;
  readableWorldHistoryCondition$: Observable<ReadableHistorySearchCondition>;
  readableSortType$: Observable<string>;

  englishLongSentenceKeywords$: Observable<string[]>;
  mathSearchCondition$: Observable<ScienceSearchCondition>;
  nationalLanguageSearchCondition$: Observable<NationalLanguageSearchCondition>;
  physicsSearchCondition$: Observable<ScienceSearchCondition>;
  chemistrySearchCondition$: Observable<ScienceSearchCondition>;
  biologySearchCondition$: Observable<ScienceSearchCondition>;
  japaneseHistorySearchCondition$: Observable<HistorySearchCondition>;
  worldHistorySearchCondition$: Observable<HistorySearchCondition>;
  geographySearchCondition$: Observable<ScienceSearchCondition>;
  politicalEconomySearchCondition$: Observable<ScienceSearchCondition>;

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta
  ) {
    this.ENGLISH_SUBJECT_ID = SubjectId.ENGLISH;
    this.MATH_SUBJECT_ID = SubjectId.MATH;
    this.NATIONAL_LANGUAGE_SUBJECT_ID = SubjectId.NATIONAL_LANGUAGE;
    this.PHYSICS_SUBJECT_ID = SubjectId.PHYSICS;
    this.CHEMISTRY_SUBJECT_ID = SubjectId.CHEMISTRY;
    this.BIOLOGY_SUBJECT_ID = SubjectId.BIOLOGY;
    this.JAPANESE_HISTORY_SUBJECT_ID = SubjectId.JAPANESE_HISTORY;
    this.WORLD_HISTORY_SUBJECT_ID = SubjectId.WORLD_HISTORY;
    this.GEOGRAPHY_SUBJECT_ID = SubjectId.GEOGRAPHY;
    this.POLITICAL_ECONOMY_SUBJECT_ID = SubjectId.POLITICAL_ECONOMY;
  }

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.renderer.addClass(this.document.body, 'page-print-preview');

    this.store.dispatch(setBrowserTitle({ subTitle: '印刷' }));
    this.store.dispatch(SearchActions.initializeProblemsForPrintState());
    this.setUpStaticData();
    this.setUpSearchConditionAndCurrentSubjectId();
    this.setUpReadableSearchConditions();
    this.findProblemCountAndProblems();
    this.setUpReadableProblems();
    this.setUpInitializedState();
    this.openPrintDialogAfterInitialization();
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'page-print-preview');
    this.store.dispatch(SearchActions.initializeProblemsForPrintState());
  }

  // set ups ----------------------------------------------------------------------------------------------
  private setUpStaticData() {
    this.findStaticDataIfNeeded();

    this.staticMathData$ = this.store.select(getStaticMathData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPhysicsData$ = this.store.select(getStaticPhysicsData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticChemistryData$ = this.store.select(getStaticChemistryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticBiologyData$ = this.store.select(getStaticBiologyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticJapaneseHistoryData$ = this.store.select(getStaticJapaneseHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticWorldHistoryData$ = this.store.select(getStaticWorldHistoryData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticGeographyData$ = this.store.select(getStaticGeographyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    this.staticPoliticalEconomyData$ = this.store.select(getStaticPoliticalEconomyData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpSearchConditionAndCurrentSubjectId() {
    this.searchProblemsCondition$ = this.activatedRoute.queryParams.pipe(
      map(params => {
        Log.debug(this.LOG_SOURCE, `印刷用の query params: `, params);
        const condition = QueryParamsMapper.decodeSearchProblemsConditionQueryParams(params);
        if (!condition) {
          const error = GeneralError.customMessage(CustomErrorMessage.PRINT_INVALID_QUERY_PARAMETERS);
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
          throwError(() => error);
        }
        return condition;
      }),
      shareReplay(1)
    );

    this.currentSubjectId$ = this.searchProblemsCondition$.pipe(map(condition => condition.subjectId));
  }

  private setUpReadableSearchConditions() {
    this.totalProblemCount$ = this.store.select(SearchSelectors.getMatchedProblemCount).pipe(filter(it => it != null));

    this.readableSearchCondition$ = this.searchProblemsCondition$.pipe(
      switchMap(condition => this.store.pipe(select(SearchSelectors.getReadableSearchCondition(condition)))),
      filter(it => it != null)
    );

    this.readableSortType$ = this.searchProblemsCondition$.pipe(
      map(condition => ReadableDataMapper.getSortTypeDisplayName(condition.sortType)),
      filter(it => it != null)
    );

    this.readableEnglishCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.ENGLISH),
      switchMap(condition =>
        this.store.pipe(select(SearchSelectors.getReadableEnglishCondition((condition.subjectCondition as EnglishSearchCondition) || {})))
      ),
      filter(it => it != null)
    );

    this.englishLongSentenceKeywords$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.ENGLISH),
      map((condition: SearchProblemsCondition<EnglishSearchCondition>) =>
        !condition.subjectCondition
          ? []
          : condition.subjectCondition.longSentenceKeywords
          ? condition.subjectCondition.longSentenceKeywords
          : []
      )
    );

    this.mathSearchCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.MATH),
      map((condition: SearchProblemsCondition<ScienceSearchCondition>) => condition.subjectCondition || {})
    );

    this.readableNationalLanguageCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.NATIONAL_LANGUAGE),
      switchMap(condition =>
        this.store.pipe(
          select(
            SearchSelectors.getReadableNationalLanguageCondition((condition.subjectCondition as NationalLanguageSearchCondition) || {})
          )
        )
      ),
      filter(it => it != null)
    );

    this.physicsSearchCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.PHYSICS),
      map((condition: SearchProblemsCondition<ScienceSearchCondition>) => condition.subjectCondition || {})
    );

    this.chemistrySearchCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.CHEMISTRY),
      map((condition: SearchProblemsCondition<ScienceSearchCondition>) => condition.subjectCondition || {})
    );

    this.biologySearchCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.BIOLOGY),
      map((condition: SearchProblemsCondition<ScienceSearchCondition>) => condition.subjectCondition || {})
    );

    this.japaneseHistorySearchCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.JAPANESE_HISTORY),
      map((condition: SearchProblemsCondition<HistorySearchCondition>) => condition.subjectCondition || {})
    );

    this.worldHistorySearchCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.WORLD_HISTORY),
      map((condition: SearchProblemsCondition<HistorySearchCondition>) => condition.subjectCondition || {})
    );

    this.readableJapaneseHistoryCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.JAPANESE_HISTORY),
      switchMap(condition =>
        this.store.pipe(
          select(SearchSelectors.getReadableJapaneseHistoryCondition((condition.subjectCondition as HistorySearchCondition) || {}))
        )
      ),
      filter(it => it != null)
    );

    this.readableWorldHistoryCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.WORLD_HISTORY),
      switchMap(condition =>
        this.store.pipe(
          select(SearchSelectors.getReadableWorldHistoryCondition((condition.subjectCondition as HistorySearchCondition) || {}))
        )
      ),
      filter(it => it != null)
    );

    this.geographySearchCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.GEOGRAPHY),
      map((condition: SearchProblemsCondition<ScienceSearchCondition>) => condition.subjectCondition || {})
    );

    this.politicalEconomySearchCondition$ = this.searchProblemsCondition$.pipe(
      filter(condition => condition.subjectId === SubjectId.POLITICAL_ECONOMY),
      map((condition: SearchProblemsCondition<ScienceSearchCondition>) => condition.subjectCondition || {})
    );
  }

  private findProblemCountAndProblems() {
    this.searchProblemsCondition$.pipe(take(1)).subscribe(condition => {
      if (condition.subjectId === SubjectId.ENGLISH) {
        const c = SearchConditionMapper.mapSearchConditionFromProblemsCondition(condition) as SearchCondition<EnglishSearchCondition>;
        this.store.dispatch(SearchActions.findEnglishProblemCount({ condition: c, logging: false }));
        const problemsCondition = condition as SearchProblemsCondition<EnglishSearchCondition>;
        this.store.dispatch(SearchActions.findEnglishProblemsForPrint({ condition: problemsCondition }));
        return;
      }
      if (this.scienceIds.includes(condition.subjectId as SubjectId)) {
        const c = SearchConditionMapper.mapSearchConditionFromProblemsCondition(condition) as SearchCondition<ScienceSearchCondition>;
        const problemsCondition = condition as SearchProblemsCondition<ScienceSearchCondition>;
        if (condition.subjectId === SubjectId.MATH) {
          this.store.dispatch(SearchActions.findMathProblemCount({ condition: c, logging: false }));
          this.store.dispatch(SearchActions.findMathProblemsForPrint({ condition: problemsCondition }));
          return;
        }
        if (condition.subjectId === SubjectId.PHYSICS) {
          this.store.dispatch(SearchActions.findPhysicsProblemCount({ condition: c, logging: false }));
          this.store.dispatch(SearchActions.findPhysicsProblemsForPrint({ condition: problemsCondition }));
          return;
        }
        if (condition.subjectId === SubjectId.CHEMISTRY) {
          this.store.dispatch(SearchActions.findChemistryProblemCount({ condition: c, logging: false }));
          this.store.dispatch(SearchActions.findChemistryProblemsForPrint({ condition: problemsCondition }));
          return;
        }
        if (condition.subjectId === SubjectId.BIOLOGY) {
          this.store.dispatch(SearchActions.findBiologyProblemCount({ condition: c, logging: false }));
          this.store.dispatch(SearchActions.findBiologyProblemsForPrint({ condition: problemsCondition }));
          return;
        }
        if (condition.subjectId === SubjectId.GEOGRAPHY) {
          this.store.dispatch(SearchActions.findGeographyProblemCount({ condition: c, logging: false }));
          this.store.dispatch(SearchActions.findGeographyProblemsForPrint({ condition: problemsCondition }));
          return;
        }
        if (condition.subjectId === SubjectId.POLITICAL_ECONOMY) {
          this.store.dispatch(SearchActions.findPoliticalEconomyProblemCount({ condition: c, logging: false }));
          this.store.dispatch(SearchActions.findPoliticalEconomyProblemsForPrint({ condition: problemsCondition }));
          return;
        }
      }
      if (condition.subjectId === SubjectId.NATIONAL_LANGUAGE) {
        const c = SearchConditionMapper.mapSearchConditionFromProblemsCondition(condition) as SearchCondition<
          NationalLanguageSearchCondition
        >;
        this.store.dispatch(SearchActions.findNationalLanguageProblemCount({ condition: c, logging: false }));
        const problemsCondition = condition as SearchProblemsCondition<NationalLanguageSearchCondition>;
        this.store.dispatch(SearchActions.findNationalLanguageProblemsForPrint({ condition: problemsCondition }));
        return;
      }
      if (condition.subjectId === SubjectId.JAPANESE_HISTORY) {
        const c = SearchConditionMapper.mapSearchConditionFromProblemsCondition(condition) as SearchCondition<HistorySearchCondition>;
        this.store.dispatch(SearchActions.findJapaneseHistoryProblemCount({ condition: c, logging: false }));
        const problemsCondition = condition as SearchProblemsCondition<HistorySearchCondition>;
        this.store.dispatch(SearchActions.findJapaneseHistoryProblemsForPrint({ condition: problemsCondition }));
        return;
      }
      if (condition.subjectId === SubjectId.WORLD_HISTORY) {
        const c = SearchConditionMapper.mapSearchConditionFromProblemsCondition(condition) as SearchCondition<HistorySearchCondition>;
        this.store.dispatch(SearchActions.findWorldHistoryProblemCount({ condition: c, logging: false }));
        const problemsCondition = condition as SearchProblemsCondition<HistorySearchCondition>;
        this.store.dispatch(SearchActions.findWorldHistoryProblemsForPrint({ condition: problemsCondition }));
        return;
      }

      Log.error(this.LOG_SOURCE, `findProblemCountAndProblems: 不明な subjectId が指定されています: `, condition);
      this.store.dispatch(
        dispatchAppError({
          source: this.LOG_SOURCE,
          error: GeneralError.customMessage(CustomErrorMessage.PRINT_UNKNOWN_SUBJECT)
        })
      );
    });
  }

  private setUpReadableProblems() {
    this.readableEnglishProblems$ = this.store.select(SearchSelectors.getReadableEnglishProblemsForPrint).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.readableMathProblems$ = this.store.select(SearchSelectors.getReadableMathProblemsForPrint).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.readableNationalLanguageProblems$ = this.store.select(SearchSelectors.getReadableNationalLanguageProblemsForPrint).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.readablePhysicsProblems$ = this.store.select(SearchSelectors.getReadablePhysicsProblemsForPrint).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.readableChemistryProblems$ = this.store.select(SearchSelectors.getReadableChemistryProblemsForPrint).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.readableBiologyProblems$ = this.store.select(SearchSelectors.getReadableBiologyProblemsForPrint).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.readableJapaneseHistoryProblems$ = this.store.select(SearchSelectors.getReadableJapaneseHistoryProblemsForPrint).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.readableWorldHistoryProblems$ = this.store.select(SearchSelectors.getReadableWorldHistoryProblemsForPrint).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.readableGeographyProblems$ = this.store.select(SearchSelectors.getReadableGeographyProblemsForPrint).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.readablePoliticalEconomyProblems$ = this.store.select(SearchSelectors.getReadablePoliticalEconomyProblemsForPrint).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpInitializedState() {
    this.initialized$ = this.searchProblemsCondition$.pipe(
      switchMap(condition => {
        if (
          condition.subjectId !== SubjectId.ENGLISH &&
          condition.subjectId !== SubjectId.MATH &&
          condition.subjectId !== SubjectId.NATIONAL_LANGUAGE &&
          condition.subjectId !== SubjectId.PHYSICS &&
          condition.subjectId !== SubjectId.CHEMISTRY &&
          condition.subjectId !== SubjectId.BIOLOGY &&
          condition.subjectId !== SubjectId.JAPANESE_HISTORY &&
          condition.subjectId !== SubjectId.WORLD_HISTORY &&
          condition.subjectId !== SubjectId.GEOGRAPHY &&
          condition.subjectId !== SubjectId.POLITICAL_ECONOMY
        ) {
          throw GeneralError.invalidArguments(`不明な subjectId が指定されました: ${condition.subjectId}`);
        }
        const problemsTarget =
          condition.subjectId === SubjectId.ENGLISH
            ? this.store.select(SearchSelectors.getReadableEnglishProblemsForPrint)
            : condition.subjectId === SubjectId.MATH
            ? this.store.select(SearchSelectors.getReadableMathProblemsForPrint)
            : condition.subjectId === SubjectId.NATIONAL_LANGUAGE
            ? this.store.select(SearchSelectors.getReadableNationalLanguageProblemsForPrint)
            : condition.subjectId === SubjectId.PHYSICS
            ? this.store.select(SearchSelectors.getReadablePhysicsProblemsForPrint)
            : condition.subjectId === SubjectId.CHEMISTRY
            ? this.store.select(SearchSelectors.getReadableChemistryProblemsForPrint)
            : condition.subjectId === SubjectId.BIOLOGY
            ? this.store.select(SearchSelectors.getReadableBiologyProblemsForPrint)
            : condition.subjectId === SubjectId.JAPANESE_HISTORY
            ? this.store.select(SearchSelectors.getReadableJapaneseHistoryProblemsForPrint)
            : condition.subjectId === SubjectId.GEOGRAPHY
            ? this.store.select(SearchSelectors.getReadableGeographyProblemsForPrint)
            : condition.subjectId === SubjectId.POLITICAL_ECONOMY
            ? this.store.select(SearchSelectors.getReadablePoliticalEconomyProblemsForPrint)
            : this.store.select(SearchSelectors.getReadableWorldHistoryProblemsForPrint);
        // NOTE: 数学, 物理, 化学, 生物, 地理, 政治・経済は分野表示の際に可読処理するので of({}) を流しておく
        const conditionTarget =
          condition.subjectId === SubjectId.ENGLISH
            ? this.readableEnglishCondition$
            : this.scienceIds.includes(condition.subjectId as SubjectId)
            ? of({})
            : condition.subjectId === SubjectId.NATIONAL_LANGUAGE
            ? this.readableNationalLanguageCondition$
            : condition.subjectId === SubjectId.JAPANESE_HISTORY
            ? this.readableJapaneseHistoryCondition$
            : this.readableWorldHistoryCondition$;
        return combineLatest([
          this.store.select(SearchSelectors.getMatchedProblemCount),
          problemsTarget,
          this.readableSearchCondition$,
          conditionTarget
        ]);
      }),
      map(
        ([problemCount, problemsForPrint, commonSearchCondition, subjectCondition]) =>
          problemCount != null && problemsForPrint != null && commonSearchCondition != null && subjectCondition != null
      ),
      startWith(false)
    );
  }

  private openPrintDialogAfterInitialization() {
    this.initialized$
      .pipe(
        filter(initialized => initialized),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => this.store.dispatch(openPrintDialog()), 500);
      });
  }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }
}
