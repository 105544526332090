import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { FirebaseCallableFunctionError } from 'src/app/errors/firebase-callable-function-error';
import { CommonIdBookmarkAndAnsweredPaperService } from 'src/app/services/common-id/common-id-bookmark-and-answered-paper.service';

import * as CommonIdBookmarkAndAnsweredPaperActions from '../../actions/common-id/common-id-bookmark-and-answered-paper.actions';

@Injectable()
export class CommonIdBookmarkAndAnsweredPaperEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdFindBookmarkAndAnsweredPapers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdBookmarkAndAnsweredPaperActions.commonIdFindBookmarkAndAnsweredPapers),
      switchMap(action =>
        this.commonIdBookmarkAndAnsweredPaperService.commonIdFindBookmarkAndAnsweredPapers(action.paperIds).pipe(
          map(response => CommonIdBookmarkAndAnsweredPaperActions.commonIdFindBookmarkAndAnsweredPapersSuccess({ response })),
          catchError(e =>
            of(
              CommonIdBookmarkAndAnsweredPaperActions.commonIdFindBookmarkAndAnsweredPapersFailure({
                error: FirebaseCallableFunctionError.from(e)
              })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdBookmarkAndAnsweredPaperService: CommonIdBookmarkAndAnsweredPaperService) {}
}
