import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { inOut } from 'src/app/resources/animations';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { navigate } from '../../../../actions/core.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';

@Component({
  selector: 'app-common-id-goto-manabi-id-dialog',
  templateUrl: './goto-manabi-id-dialog.component.html',
  styleUrls: ['./goto-manabi-id-dialog.component.scss'],
  animations: [inOut]
})
export class CommonIdGotoManabiIdDialogComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;

  constructor(private store: Store<RootState>, private dialogRef: MatDialogRef<CommonIdGotoManabiIdDialogComponent>) {}

  ngOnInit() {}

  ngOnDestroy() {}

  close() {
    this.dialogRef.close(false);
  }

  gotoSearch() {
    this.dialogRef.close(false);
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUniv() }));
  }
}
