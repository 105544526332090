import { Action, createReducer, on } from '@ngrx/store';

import * as SearchActions from '../actions/search.actions';
import { ScienceProblem, EnglishProblem, HistoryProblem, NationalLanguageProblem } from '../models/problem';
import { SearchCondition } from '../models/search-condition';
import { ScienceSearchCondition } from '../models/science-search-condition';
import { EnglishSearchCondition } from '../models/english-search-condition';
import { HistorySearchCondition } from '../models/history-search-condition';
import { NationalLanguageSearchCondition } from '../models/national-language-search-condition';

export const searchFeatureKey = 'search';

// NOTE: 教科が増えたら Generics を追加
type SubjectConditionType = EnglishSearchCondition | ScienceSearchCondition | NationalLanguageSearchCondition | HistorySearchCondition;

export interface State {
  /** search-result -> search にブラウザバックした時の検索条件復元に使用 */
  searchCondition: SearchCondition<SubjectConditionType> | null;

  /** 大問を検索中かどうかを判定する flag (教科共通で使用) */
  problemsSearching: boolean;

  /** 検索結果 (最大 20 件) */
  englishProblems: EnglishProblem[] | null;
  mathProblems: ScienceProblem[] | null;
  nationalLanguageProblems: NationalLanguageProblem[] | null;
  nationalLanguageProblemsWithSameId: NationalLanguageProblem[] | null;
  physicsProblems: ScienceProblem[] | null;
  chemistryProblems: ScienceProblem[] | null;
  biologyProblems: ScienceProblem[] | null;
  japaneseHistoryProblems: HistoryProblem[] | null;
  worldHistoryProblems: HistoryProblem[] | null;
  geographyProblems: ScienceProblem[] | null;
  politicalEconomyProblems: ScienceProblem[] | null;

  /** 条件にヒットした大問の件数 (教科共通で使用) */
  matchedProblemCount: number | null;
  problemCountSearching: boolean;

  /** 印刷画面用の検索結果 (最大 300 件) */
  englishProblemsForPrint: EnglishProblem[] | null;
  mathProblemsForPrint: ScienceProblem[] | null;
  nationalLanguageProblemsForPrint: NationalLanguageProblem[] | null;
  physicsProblemsForPrint: ScienceProblem[] | null;
  chemistryProblemsForPrint: ScienceProblem[] | null;
  biologyProblemsForPrint: ScienceProblem[] | null;
  japaneseHistoryProblemsForPrint: HistoryProblem[] | null;
  worldHistoryProblemsForPrint: HistoryProblem[] | null;
  geographyProblemsForPrint: ScienceProblem[] | null;
  politicalEconomyProblemsForPrint: ScienceProblem[] | null;

  /** 条件に該当する大問の ID リスト */
  matchedProblemIds: string[] | null;
  scienceProblem: ScienceProblem | null;
  englishProblem: EnglishProblem | null;
  nationalLanguageProblem: NationalLanguageProblem | null;
  historyProblem: HistoryProblem | null;
  problemIdsSearching: boolean;
  problemSearching: boolean;
}

export const initialState: State = {
  searchCondition: null,
  problemsSearching: false,
  englishProblems: null,
  mathProblems: null,
  nationalLanguageProblems: null,
  nationalLanguageProblemsWithSameId: null,
  physicsProblems: null,
  chemistryProblems: null,
  biologyProblems: null,
  japaneseHistoryProblems: null,
  worldHistoryProblems: null,
  geographyProblems: null,
  politicalEconomyProblems: null,
  matchedProblemCount: null,
  problemCountSearching: false,
  englishProblemsForPrint: null,
  mathProblemsForPrint: null,
  nationalLanguageProblemsForPrint: null,
  physicsProblemsForPrint: null,
  chemistryProblemsForPrint: null,
  biologyProblemsForPrint: null,
  japaneseHistoryProblemsForPrint: null,
  worldHistoryProblemsForPrint: null,
  geographyProblemsForPrint: null,
  politicalEconomyProblemsForPrint: null,
  matchedProblemIds: null,
  scienceProblem: null,
  englishProblem: null,
  nationalLanguageProblem: null,
  historyProblem: null,
  problemIdsSearching: false,
  problemSearching: false
};

const searchReducer = createReducer(
  initialState,

  // Set Search Condition -------------------------------------------------------
  on(SearchActions.setSearchCondition, (state, { condition }) => ({ ...state, searchCondition: condition })),
  on(SearchActions.initializeSearchCondition, state => {
    const next: State = { ...state, searchCondition: null };
    return next;
  }),

  // Find English Problem Count -------------------------------------------------------
  on(SearchActions.findEnglishProblemCount, state => ({ ...state, problemCountSearching: true })),
  on(SearchActions.findEnglishProblemCountSuccess, (state, { result }) => ({
    ...state,
    matchedProblemCount: result.count,
    problemCountSearching: false
  })),
  on(SearchActions.findEnglishProblemCountFailure, state => ({ ...state, problemCountSearching: false })),

  // Find Math Problem Count -------------------------------------------------------
  on(SearchActions.findMathProblemCount, state => ({ ...state, problemCountSearching: true })),
  on(SearchActions.findMathProblemCountSuccess, (state, { result }) => ({
    ...state,
    matchedProblemCount: result.count,
    problemCountSearching: false
  })),
  on(SearchActions.findMathProblemCountFailure, state => ({ ...state, problemCountSearching: false })),

  // Find National Language Problem Count -------------------------------------------------------
  on(SearchActions.findNationalLanguageProblemCount, state => ({ ...state, problemCountSearching: true })),
  on(SearchActions.findNationalLanguageProblemCountSuccess, (state, { result }) => ({
    ...state,
    matchedProblemCount: result.count,
    problemCountSearching: false
  })),
  on(SearchActions.findNationalLanguageProblemCountFailure, state => ({ ...state, problemCountSearching: false })),

  // Find Physics Problem Count -------------------------------------------------------
  on(SearchActions.findPhysicsProblemCount, state => ({ ...state, problemCountSearching: true })),
  on(SearchActions.findPhysicsProblemCountSuccess, (state, { result }) => ({
    ...state,
    matchedProblemCount: result.count,
    problemCountSearching: false
  })),
  on(SearchActions.findPhysicsProblemCountFailure, state => ({ ...state, problemCountSearching: false })),

  // Find Chemistry Problem Count -------------------------------------------------------
  on(SearchActions.findChemistryProblemCount, state => ({ ...state, problemCountSearching: true })),
  on(SearchActions.findChemistryProblemCountSuccess, (state, { result }) => ({
    ...state,
    matchedProblemCount: result.count,
    problemCountSearching: false
  })),
  on(SearchActions.findChemistryProblemCountFailure, state => ({ ...state, problemCountSearching: false })),

  // Find Biology Problem Count -------------------------------------------------------
  on(SearchActions.findBiologyProblemCount, state => ({ ...state, problemCountSearching: true })),
  on(SearchActions.findBiologyProblemCountSuccess, (state, { result }) => ({
    ...state,
    matchedProblemCount: result.count,
    problemCountSearching: false
  })),
  on(SearchActions.findBiologyProblemCountFailure, state => ({ ...state, problemCountSearching: false })),

  // Find Japanese History Problem Count -------------------------------------------------------
  on(SearchActions.findJapaneseHistoryProblemCount, state => ({ ...state, problemCountSearching: true })),
  on(SearchActions.findJapaneseHistoryProblemCountSuccess, (state, { result }) => ({
    ...state,
    matchedProblemCount: result.count,
    problemCountSearching: false
  })),
  on(SearchActions.findJapaneseHistoryProblemCountFailure, state => ({ ...state, problemCountSearching: false })),

  // Find World History Problem Count -------------------------------------------------------
  on(SearchActions.findWorldHistoryProblemCount, state => ({ ...state, problemCountSearching: true })),
  on(SearchActions.findWorldHistoryProblemCountSuccess, (state, { result }) => ({
    ...state,
    matchedProblemCount: result.count,
    problemCountSearching: false
  })),
  on(SearchActions.findWorldHistoryProblemCountFailure, state => ({ ...state, problemCountSearching: false })),

  // Find Geography Problem Count -------------------------------------------------------
  on(SearchActions.findGeographyProblemCount, state => ({ ...state, problemCountSearching: true })),
  on(SearchActions.findGeographyProblemCountSuccess, (state, { result }) => ({
    ...state,
    matchedProblemCount: result.count,
    problemCountSearching: false
  })),
  on(SearchActions.findGeographyProblemCountFailure, state => ({ ...state, problemCountSearching: false })),

  // Find Political Economy Problem Count -------------------------------------------------------
  on(SearchActions.findPoliticalEconomyProblemCount, state => ({ ...state, problemCountSearching: true })),
  on(SearchActions.findPoliticalEconomyProblemCountSuccess, (state, { result }) => ({
    ...state,
    matchedProblemCount: result.count,
    problemCountSearching: false
  })),
  on(SearchActions.findPoliticalEconomyProblemCountFailure, state => ({ ...state, problemCountSearching: false })),

  /** 教科共通で使用 */
  on(SearchActions.initializeProblemCountState, state => {
    const next: State = { ...state, matchedProblemCount: null, problemCountSearching: false };
    return next;
  }),

  // Find English Problems -------------------------------------------------------
  on(SearchActions.findEnglishProblems, state => ({ ...state, problemsSearching: true })),
  on(SearchActions.findEnglishProblemsSuccess, (state, { problems }) => ({
    ...state,
    englishProblems: problems,
    problemsSearching: false
  })),
  on(SearchActions.findEnglishProblemsFailure, state => ({ ...state, problemsSearching: false })),

  // Find Math Problems -------------------------------------------------------
  on(SearchActions.findMathProblems, state => ({ ...state, problemsSearching: true })),
  on(SearchActions.findMathProblemsSuccess, (state, { problems }) => ({
    ...state,
    mathProblems: problems,
    problemsSearching: false
  })),
  on(SearchActions.findMathProblemsFailure, state => ({ ...state, problemsSearching: false })),

  // Find National Language Problems -------------------------------------------------------
  on(SearchActions.findNationalLanguageProblems, state => ({ ...state, problemsSearching: true })),
  on(SearchActions.findNationalLanguageProblemsSuccess, (state, { problems }) => ({
    ...state,
    nationalLanguageProblems: problems,
    problemsSearching: false
  })),
  on(SearchActions.findNationalLanguageProblemsFailure, state => ({ ...state, problemsSearching: false })),

  // Find Physics Problems -------------------------------------------------------
  on(SearchActions.findPhysicsProblems, state => ({ ...state, problemsSearching: true })),
  on(SearchActions.findPhysicsProblemsSuccess, (state, { problems }) => ({
    ...state,
    physicsProblems: problems,
    problemsSearching: false
  })),
  on(SearchActions.findPhysicsProblemsFailure, state => ({ ...state, problemsSearching: false })),

  // Find Chemistry Problems -------------------------------------------------------
  on(SearchActions.findChemistryProblems, state => ({ ...state, problemsSearching: true })),
  on(SearchActions.findChemistryProblemsSuccess, (state, { problems }) => ({
    ...state,
    chemistryProblems: problems,
    problemsSearching: false
  })),
  on(SearchActions.findChemistryProblemsFailure, state => ({ ...state, problemsSearching: false })),

  // Find Biology Problems -------------------------------------------------------
  on(SearchActions.findBiologyProblems, state => ({ ...state, problemsSearching: true })),
  on(SearchActions.findBiologyProblemsSuccess, (state, { problems }) => ({
    ...state,
    biologyProblems: problems,
    problemsSearching: false
  })),
  on(SearchActions.findBiologyProblemsFailure, state => ({ ...state, problemsSearching: false })),

  // Find Japanese History Problems -------------------------------------------------------
  on(SearchActions.findJapaneseHistoryProblems, state => ({ ...state, problemsSearching: true })),
  on(SearchActions.findJapaneseHistoryProblemsSuccess, (state, { problems }) => ({
    ...state,
    japaneseHistoryProblems: problems,
    problemsSearching: false
  })),
  on(SearchActions.findJapaneseHistoryProblemsFailure, state => ({ ...state, problemsSearching: false })),

  // Find World History Problems -------------------------------------------------------
  on(SearchActions.findWorldHistoryProblems, state => ({ ...state, problemsSearching: true })),
  on(SearchActions.findWorldHistoryProblemsSuccess, (state, { problems }) => ({
    ...state,
    worldHistoryProblems: problems,
    problemsSearching: false
  })),
  on(SearchActions.findWorldHistoryProblemsFailure, state => ({ ...state, problemsSearching: false })),

  // Find Geography Problems -------------------------------------------------------
  on(SearchActions.findGeographyProblems, state => ({ ...state, problemsSearching: true })),
  on(SearchActions.findGeographyProblemsSuccess, (state, { problems }) => ({
    ...state,
    geographyProblems: problems,
    problemsSearching: false
  })),
  on(SearchActions.findGeographyProblemsFailure, state => ({ ...state, problemsSearching: false })),

  // Find Political Economy Problems -------------------------------------------------------
  on(SearchActions.findPoliticalEconomyProblems, state => ({ ...state, problemsSearching: true })),
  on(SearchActions.findPoliticalEconomyProblemsSuccess, (state, { problems }) => ({
    ...state,
    politicalEconomyProblems: problems,
    problemsSearching: false
  })),
  on(SearchActions.findPoliticalEconomyProblemsFailure, state => ({ ...state, problemsSearching: false })),

  /** NOTE: 教科共通で使用. 教科が増えたら追記が必要 */
  on(SearchActions.initializeProblemsState, state => {
    const next: State = {
      ...state,
      problemsSearching: false,
      englishProblems: null,
      mathProblems: null,
      nationalLanguageProblems: null,
      nationalLanguageProblemsWithSameId: null,
      physicsProblems: null,
      chemistryProblems: null,
      biologyProblems: null,
      japaneseHistoryProblems: null,
      worldHistoryProblems: null,
      geographyProblems: null,
      politicalEconomyProblems: null
    };
    return next;
  }),

  on(SearchActions.initializeMatchedProblemIdsState, state => {
    const next: State = {
      ...state,
      matchedProblemIds: null
    };
    return next;
  }),

  // Find English Problems For Print -------------------------------------------------------
  on(SearchActions.findEnglishProblemsForPrintSuccess, (state, { problems }) => ({ ...state, englishProblemsForPrint: problems })),

  // Find Math Problems For Print -------------------------------------------------------
  on(SearchActions.findMathProblemsForPrintSuccess, (state, { problems }) => ({ ...state, mathProblemsForPrint: problems })),

  // Find National Language Problems For Print -------------------------------------------------------
  on(SearchActions.findNationalLanguageProblemsForPrintSuccess, (state, { problems }) => ({
    ...state,
    nationalLanguageProblemsForPrint: problems
  })),

  // Find Physics Problems For Print -------------------------------------------------------
  on(SearchActions.findPhysicsProblemsForPrintSuccess, (state, { problems }) => ({ ...state, physicsProblemsForPrint: problems })),

  // Find Chemistry Problems For Print -------------------------------------------------------
  on(SearchActions.findChemistryProblemsForPrintSuccess, (state, { problems }) => ({ ...state, chemistryProblemsForPrint: problems })),

  // Find Biology Problems For Print -------------------------------------------------------
  on(SearchActions.findBiologyProblemsForPrintSuccess, (state, { problems }) => ({ ...state, biologyProblemsForPrint: problems })),

  // Find Japanese History Problems For Print -------------------------------------------------------
  on(SearchActions.findJapaneseHistoryProblemsForPrintSuccess, (state, { problems }) => ({
    ...state,
    japaneseHistoryProblemsForPrint: problems
  })),

  // Find World History Problems For Print -------------------------------------------------------
  on(SearchActions.findWorldHistoryProblemsForPrintSuccess, (state, { problems }) => ({
    ...state,
    worldHistoryProblemsForPrint: problems
  })),

  // Find Geography Problems For Print -------------------------------------------------------
  on(SearchActions.findGeographyProblemsForPrintSuccess, (state, { problems }) => ({ ...state, geographyProblemsForPrint: problems })),

  // Find Political Economy Problems For Print -------------------------------------------------------
  on(SearchActions.findPoliticalEconomyProblemsForPrintSuccess, (state, { problems }) => ({
    ...state,
    politicalEconomyProblemsForPrint: problems
  })),

  /** 教科共通で使用 */
  on(SearchActions.initializeProblemsForPrintState, state => {
    const next: State = {
      ...state,
      englishProblemsForPrint: null,
      mathProblemsForPrint: null,
      nationalLanguageProblemsForPrint: null,
      physicsProblemsForPrint: null,
      chemistryProblemsForPrint: null,
      biologyProblemsForPrint: null,
      japaneseHistoryProblemsForPrint: null,
      worldHistoryProblemsForPrint: null,
      geographyProblemsForPrint: null,
      politicalEconomyProblemsForPrint: null
    };
    return next;
  }),

  // Find Science Problem Ids -------------------------------------------------------
  on(SearchActions.findScienceProblemIds, state => ({ ...state, problemIdsSearching: true })),
  on(SearchActions.findScienceProblemIdsSuccess, (state, { ids }) => ({ ...state, matchedProblemIds: ids, problemIdsSearching: false })),
  on(SearchActions.findScienceProblemIdsFailure, state => ({ ...state, problemIdsSearching: false })),

  // Find English Problem Ids -------------------------------------------------------
  on(SearchActions.findEnglishProblemIds, state => ({ ...state, problemIdsSearching: true })),
  on(SearchActions.findEnglishProblemIdsSuccess, (state, { ids }) => ({ ...state, matchedProblemIds: ids, problemIdsSearching: false })),
  on(SearchActions.findEnglishProblemIdsFailure, state => ({ ...state, problemIdsSearching: false })),

  // Find National Language Problem Ids -------------------------------------------------------
  on(SearchActions.findNationalLanguageProblemIds, state => ({ ...state, problemIdsSearching: true })),
  on(SearchActions.findNationalLanguageProblemIdsSuccess, (state, { ids }) => ({
    ...state,
    matchedProblemIds: ids,
    problemIdsSearching: false
  })),
  on(SearchActions.findNationalLanguageProblemIdsFailure, state => ({ ...state, problemIdsSearching: false })),

  // Find History Problem Ids -------------------------------------------------------
  on(SearchActions.findHistoryProblemIds, state => ({ ...state, problemIdsSearching: true })),
  on(SearchActions.findHistoryProblemIdsSuccess, (state, { ids }) => ({ ...state, matchedProblemIds: ids, problemIdsSearching: false })),
  on(SearchActions.findHistoryProblemIdsFailure, state => ({ ...state, problemIdsSearching: false })),

  // Find Science Problem -------------------------------------------------------
  on(SearchActions.findScienceProblem, state => ({ ...state, problemSearching: true })),
  on(SearchActions.findScienceProblemByPaperId, state => ({ ...state, problemSearching: true })),
  on(SearchActions.findScienceProblemSuccess, (state, { problem }) => ({ ...state, scienceProblem: problem, problemSearching: false })),
  on(SearchActions.findScienceProblemFailure, state => ({ ...state, problemSearching: false })),

  // Find English Problem -------------------------------------------------------
  on(SearchActions.findEnglishProblem, state => ({ ...state, problemSearching: true })),
  on(SearchActions.findEnglishProblemByPaperId, state => ({ ...state, problemSearching: true })),
  on(SearchActions.findEnglishProblemSuccess, (state, { problem }) => ({ ...state, englishProblem: problem, problemSearching: false })),
  on(SearchActions.findEnglishProblemFailure, state => ({ ...state, problemSearching: false })),

  // Find National Language Problem -------------------------------------------------------
  on(SearchActions.findNationalLanguageProblem, state => ({ ...state, problemSearching: true })),
  on(SearchActions.findNationalLanguageProblemByPaperId, state => ({ ...state, problemSearching: true })),
  on(SearchActions.findNationalLanguageProblemSuccess, (state, { problem }) => ({
    ...state,
    nationalLanguageProblem: problem,
    problemSearching: false
  })),
  on(SearchActions.findNationalLanguageProblemFailure, state => ({ ...state, problemSearching: false })),

  // Find National Language Problems By Id -------------------------------------------------------
  on(SearchActions.findNationalLanguageProblemsById, state => ({ ...state, problemSearching: true })),
  on(SearchActions.findNationalLanguageProblemsByIdSuccess, (state, { problems }) => ({
    ...state,
    nationalLanguageProblemsWithSameId: problems,
    problemSearching: false
  })),
  on(SearchActions.findNationalLanguageProblemsByIdFailure, state => ({ ...state, problemSearching: false })),

  // Find History Problem -------------------------------------------------------
  on(SearchActions.findHistoryProblem, state => ({ ...state, problemSearching: true })),
  on(SearchActions.findHistoryProblemByPaperId, state => ({ ...state, problemSearching: true })),
  on(SearchActions.findHistoryProblemSuccess, (state, { problem }) => ({ ...state, historyProblem: problem, problemSearching: false })),
  on(SearchActions.findHistoryProblemFailure, state => ({ ...state, problemSearching: false })),

  /** NOTE: 教科共通で使用. 教科が増えたら追記が必要 */
  on(SearchActions.initializeProblemState, state => {
    const next: State = {
      ...state,
      problemSearching: false,
      englishProblem: null,
      scienceProblem: null,
      nationalLanguageProblem: null,
      nationalLanguageProblemsWithSameId: null,
      historyProblem: null
    };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return searchReducer(state, action);
}
