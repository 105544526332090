import {
  MAX_ANSWERED_PROBLEMS_COUNT,
  MAX_BOOKMARK_COUNT,
  MAX_PAPER_BOOKMARK_COUNT,
  MAX_PLAYLIST_BOOKMARK_COUNT,
  MAX_UNIVERSITY_BOOKMARK_COUNT
} from '../resources/config';

export interface ErrorInfo {
  code: string;
  message: string;
}

export class CustomErrorMessage {
  static PLAYLIST_DETAIL_INVALID_PLAYLIST_ID = '指定されたおすすめ問題セットが存在しません';
  static PRINT_INVALID_QUERY_PARAMETERS =
    '検索条件の読み込みに失敗しました。この画面を閉じて、再度検索結果画面から印刷ボタンをクリックしてください。';
  static PRINT_UNKNOWN_SUBJECT = '想定していない科目が指定されています';
  static REPORT_INVALID_SUBJECT_ID = '想定していない科目のデータです';
  static PROBLEM_DETAIL_FAILED_PRECONDITION = '指定されている条件が不正です。この画面を閉じてもう一度基本検索結果画面から開いてください。';
  static SEARCH_BY_CATEGORY_PROBLEM_DETAIL_FAILED_PRECONDITION = 'リソースが見つかりませんでした';
}

const makeInfo = (code: string, message: string): ErrorInfo => ({ code, message });

export class AuthErrorInfo {
  static USER_NOT_FOUND = makeInfo('AU01', '登録されている情報と異なります');
  static USER_DISABLED = makeInfo('AU02', 'アカウントは無効です');
  static ALREADY_SIGN_OUT = makeInfo('AU03', 'ページを更新してサインインしてください');
}

export class CommonIdAuthErrorInfo {
  static USER_NOT_FOUND = makeInfo('CAU01', '登録されている情報と異なります');
  static USER_DISABLED = makeInfo('CAU02', 'アカウントは無効です');
  static ALREADY_SIGN_OUT = makeInfo('CAU03', 'ページを更新してサインインしてください');
}

export class GeneralErrorInfo {
  static NOT_FOUND = makeInfo('GE01', '不明なエラーが発生しました');
  static INVALID_ARGUMENTS = makeInfo('GE02', '不明なエラーが発生しました');
  static FAILED_PRECONDITION = makeInfo('GE03', '不明なエラーが発生しました');
  static CUSTOM_MESSAGE = makeInfo('GE98', '不明なエラーが発生しました');
  static UNKNOWN = makeInfo('GE99', '不明なエラーが発生しました');
}

// TODO: メッセージを検討
export class FirebaseCallableFunctionErrorInfo {
  static GENERAL_INVALID_ARGUMENT = makeInfo('FGE01', '不明なエラーが発生しました');
  static GENERAL_NOT_FOUND = makeInfo('FGE02', 'リソースが見つかりませんでした');
  static GENERAL_INTERNAL = makeInfo('FGE98', '不明なエラーが発生しました');
  static GENERAL_UNKNOWN = makeInfo('FGE99', '不明なエラーが発生しました');
  static AUTH_UNAUTHENTICATED = makeInfo('FAU01', 'ページを更新してサインインしてください');
  static AUTH_PERMISSION_DENIED = makeInfo('FAU02', '認証エラー: 権限がありません');
  static AUTH_EMAIL_ALREADY_EXISTS = makeInfo('FAU03', '認証エラー: 指定された Email が既に登録されています');
  static AUTH_INVALID_ARGUMENTS = makeInfo('FAU04', '認証エラー: 不明なエラーが発生しました');
  static AUTH_INVALID_USER = makeInfo('FAU05', 'ページを更新してサインインしてください');
  static AUTH_UNKNOWN = makeInfo('FAU99', '認証エラー: 不明なエラーが発生しました');
  static INVALID_START_AT = makeInfo('PE01', '開始日に過去の日付は指定できません');
  static INVALID_END_AT = makeInfo('PE02', '終了日に開始日より過去の日付を指定できません');
  static DUPLICATE_EXPIRATION_DATE = makeInfo('PE03', '有効期限が重複しているプランが存在しています');
  static ALREADY_EXISTS_RESERVE_PLAN = makeInfo('PE04', '予約プランが存在しています');
  static FEW_MEMBER_COUNT = makeInfo('PE05', '割り当て済み人数より少ない人数へは更新できません');
  static ALREADY_ASSIGNED = makeInfo('PE06', '既に割り当て済みです');
  static ALREADY_ASSIGNED_FOR_OTHER = makeInfo('PE07', '他のプランに既に割り当て済みです');
  static EXCEEDS_ASSIGNED_MEMBERS = makeInfo('PE08', '割り当て可能なユーザー数を超えています');
  static ALREADY_EXISTS_BOOKMARK = makeInfo('BE01', 'お気に入りに登録済みです');
  static NOT_FOUND_BOOKMARK = makeInfo('BE02', 'お気に入りに登録されていません');
  static EXCEEDS_BOOKMARKS = makeInfo('BE03', `登録上限数${MAX_BOOKMARK_COUNT}件に達したため、これ以上登録できません`);
  static PLAN_UNKNOWN = makeInfo('PE99', '不明なエラーが発生しました');
  static ALREADY_EXISTS_JUKU_CODE = makeInfo('JE01', '既に登録済みの塾・予備校コードです');
  static ALREADY_EXISTS_JUKU_NAME = makeInfo('JE02', '既に登録済みの塾・予備校名（教室名）です');
  static NOT_FOUND_JUKU = makeInfo('JE03', '登録されていません');
  static JUKU_CAN_NOT_DELETE = makeInfo('JE04', 'プランまたはメンバーが存在するため削除できません');

  static COMMON_ID_ALREADY_EXISTS_UNIVERSITY_BOOKMARK = makeInfo('CUBE01', 'お気に入り大学に登録済みです');
  static COMMON_ID_NOT_FOUND_UNIVERSITY_BOOKMARK = makeInfo('CUBE02', 'お気に入り大学に登録されていません');
  static COMMON_ID_UNIVERSITY_EXCEEDS_BOOKMARKS = makeInfo(
    'CUBE03',
    `登録上限数${MAX_UNIVERSITY_BOOKMARK_COUNT}件に達したため、これ以上登録できません`
  );
  static COMMON_ID_UNIVERSITY_BOOKMARK_UNKNOWN = makeInfo('CUBE99', '不明なエラーが発生しました');
  static COMMON_ID_AUTH_INCORRECT_ID_TOKEN = makeInfo('CAE01', '認証エラー：学びIDにログインできませんでした');
  static COMMON_ID_AUTH_API_NOT_FOUND_USER = makeInfo('CAE02', '認証エラー：学びIDのユーザ情報を取得できませんでした');
  static COMMON_ID_AUTH_API_INVALID_ARGUMENT = makeInfo('CAE03', '認証エラー：不明なエラーが発生しました');
  static COMMON_ID_ALREADY_EXISTS_PAPER_BOOKMARK = makeInfo('CPBE01', 'あとで解く（試験）に登録済みです');
  static COMMON_ID_NOT_FOUND_PAPER_BOOKMARK = makeInfo('CPBE02', 'あとで解く（試験）に登録されていません');
  static COMMON_ID_PAPER_EXCEEDS_BOOKMARKS = makeInfo(
    'CPBE03',
    `登録上限数${MAX_PAPER_BOOKMARK_COUNT}件に達したため、これ以上登録できません`
  );
  static COMMON_ID_UNIVERSITY_PAPER_BOOKMARK_UNKNOWN = makeInfo('CPBE99', '不明なエラーが発生しました');
  static COMMON_ID_ALREADY_EXISTS_PLAYLIST_BOOKMARK = makeInfo('CPLBE01', 'あとで解く（おすすめ問題セット）に登録済みです');
  static COMMON_ID_NOT_FOUND_PLAYLIST_BOOKMARK = makeInfo('CPLBE02', 'あとで解く（おすすめ問題セット）に登録されていません');
  static COMMON_ID_PLAYLIST_EXCEEDS_BOOKMARKS = makeInfo(
    'CPLBE03',
    `登録上限数${MAX_PLAYLIST_BOOKMARK_COUNT}件に達したため、これ以上登録できません`
  );
  static COMMON_ID_PLAYLIST_BOOKMARK_UNKNOWN = makeInfo('CPLBE99', '不明なエラーが発生しました');
  static COMMON_ID_ANSWERED_PROBLEM_EXCEEDS_BOOKMARKS = makeInfo(
    'CAPBE01',
    `登録上限数${MAX_ANSWERED_PROBLEMS_COUNT}件に達したため、これ以上登録できません`
  );
  static COMMON_ID_STRIPE_NOT_MATCH_PURCHASED_USER_ID = makeInfo('CSE01', '購入情報に紐づくユーザIDが一致しません');
  static COMMON_ID_ALREADY_PURCHASED = makeInfo('CSE02', '購入情報は登録済みです');

  static UNKNOWN = makeInfo('FN99', '不明なエラーが発生しました');
}

// https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signInWithEmailAndPassword
// https://firebase.google.com/docs/reference/js/firebase.auth.Auth#sendPasswordResetEmail
export class FirebaseAuthErrorInfo {
  static INVALID_EMAIL = makeInfo('FA01', '認証エラーが発生しました');
  static USER_DISABLED = makeInfo('FA02', '認証エラーが発生しました');
  static USER_NOT_FOUND = makeInfo('FA03', '登録されている情報と異なります');
  static WRONG_PASSWORD = makeInfo('FA04', '登録されている情報と異なります');
  static MISSING_ANDROID_PKG_NAME = makeInfo('FA05', '認証エラーが発生しました');
  static MISSING_CONTINUE_URI = makeInfo('FA06', '認証エラーが発生しました');
  static MISSING_IOS_BUNDLE_ID = makeInfo('FA07', '認証エラーが発生しました');
  static INVALID_CONTINUE_URI = makeInfo('FA08', '認証エラーが発生しました');
  static UNAUTHORIZED_CONTINUE_URI = makeInfo('FA09', '認証エラーが発生しました');
  static EMAIL_ALREADY_IN_USE = makeInfo('FA10', '指定されたメールアドレスは登録できません');
  static UNKNOWN = makeInfo('FA99', '認証エラーが発生しました');
}

// TODO: メッセージを検討
// https://firebase.google.com/docs/reference/js/firebase.firestore.FirestoreError
export class FirestoreErrorInfo {
  static CANCELLED = makeInfo('FS01', 'データ取得中にエラー発生しました');
  static INVALID_ARGUMENT = makeInfo('FS02', 'データ取得中にエラー発生しました');
  static DEADLINE_EXCEEDED = makeInfo('FS03', 'データ取得中にエラー発生しました');
  static NOT_FOUND = makeInfo('FS04', 'データ取得中にエラー発生しました');
  static ALREADY_EXISTS = makeInfo('FS05', 'データ取得中にエラー発生しました');
  static PERMISSION_DENIED = makeInfo('FS06', 'ページを更新してサインインしてください');
  static RESOURCE_EXHAUSTED = makeInfo('FS07', 'データ取得中にエラー発生しました');
  static FAILED_PRECONDITION = makeInfo('FS08', 'データ取得中にエラー発生しました');
  static ABORTED = makeInfo('FS09', 'データ取得中にエラー発生しました');
  static OUT_OF_RANGE = makeInfo('FS10', 'データ取得中にエラー発生しました');
  static UNIMPLEMENTED = makeInfo('FS11', 'データ取得中にエラー発生しました');
  static INTERNAL = makeInfo('FS12', 'データ取得中にエラー発生しました');
  static UNAVAILABLE = makeInfo('FS13', 'データ取得中にエラー発生しました');
  static DATA_LOSS = makeInfo('FS14', 'データ取得中にエラー発生しました');
  static UNAUTHENTICATED = makeInfo('FS15', 'データ取得中にエラー発生しました');
  static UNKNOWN = makeInfo('FS99', 'データ取得中にエラー発生しました');
}

// TODO: メッセージを検討
// https://firebase.google.com/docs/storage/web/handle-errors?hl=ja
export class FirebaseStorageErrorInfo {
  static OBJECT_NOT_FOUND = makeInfo('ST01', 'データが見つかりません');
  static BUCKET_NOT_FOUND = makeInfo('ST02', 'データが見つかりません');
  static PROJECT_NOT_FOUND = makeInfo('ST03', 'データが見つかりません');
  static QUOTA_EXCEEDED = makeInfo('ST04', '不明なエラーです');
  static UNAUTHENTICATED = makeInfo('ST05', 'ログインし直してください');
  static UNAUTHORIZED = makeInfo('ST06', '権限がありません');
  static RETRY_LIMIT_EXCEEDED = makeInfo('ST07', '再度アップロードをお試しください');
  static INVALID_CHECKSUM = makeInfo('ST08', '再度アップロードをお試しください');
  static CANCELED = makeInfo('ST09', 'キャンセルされました');
  static INVALID_EVENT_NAME = makeInfo('ST10', '不明なエラーです');
  static INVALID_URL = makeInfo('ST11', '不明なエラーです');
  static INVALID_ARGUMENT = makeInfo('ST12', '不明なエラーです');
  static NO_DEFAULT_BUCKET = makeInfo('ST13', '不明なエラーです');
  static CANNOT_SLICE_BLOB = makeInfo('ST14', '再度アップロードをお試しください');
  static SERVER_FILE_WRONG_SIZE = makeInfo('ST15', '再度アップロードをお試しください');
  static UNKNOWN = makeInfo('ST99', '不明なエラーです');
}
