import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { CommonIdPlaylist, CommonIdRawPlaylist } from '../../models/common-id/common-id-playlist';
import {
  SciencePlaylistProblem,
  EnglishPlaylistProblem,
  NationalLanguagePlaylistProblem,
  HistoryPlaylistProblem
} from '../../models/problem';
import { FindPlaylistProblemsRequest } from '../../models/find-playlist-problems-request';
import { CommonIdPlaylistMapper } from '../../mappers/common-id/common-id-playlist-mapper';
import { Collection, CallableFunction } from '../../resources/app-firebase';
import { SubjectId } from '../../resources/config';
import { HttpClient } from '@angular/common/http';
import { FirebaseStorageError } from '../../errors/firebase-storage-error';

@Injectable({
  providedIn: 'root'
})
export class CommonIdPlaylistService {
  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private storage: AngularFireStorage,
    private http: HttpClient
  ) {}

  findPlaylists(): Observable<CommonIdPlaylist[]> {
    return this.afs
      .collection<CommonIdRawPlaylist>(Collection.COMMON_ID_PLAYLIST)
      .get()
      .pipe(
        map(snapshot => (snapshot.empty ? [] : snapshot.docs.map(doc => doc.data() as CommonIdRawPlaylist))),
        map(rawPlaylists => rawPlaylists.map(rp => CommonIdPlaylistMapper.mapPlaylistFromRaw(rp)))
      );
  }

  findPlyaListProblemIds(subjectId: string, playlistId: string): Observable<string[]> {
    const req: FindPlaylistProblemsRequest = { subjectId, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEM_IDS);
    return callable(req);
  }

  findEnglishPlaylistProblems(playlistId: string): Observable<EnglishPlaylistProblem[]> {
    const req: FindPlaylistProblemsRequest = { subjectId: SubjectId.ENGLISH, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEMS);
    return callable(req);
  }

  findMathPlaylistProblems(playlistId: string): Observable<SciencePlaylistProblem[]> {
    const req: FindPlaylistProblemsRequest = { subjectId: SubjectId.MATH, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEMS);
    return callable(req);
  }

  findNationalLanguagePlaylistProblems(playlistId: string): Observable<NationalLanguagePlaylistProblem[]> {
    const req: FindPlaylistProblemsRequest = { subjectId: SubjectId.NATIONAL_LANGUAGE, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEMS);
    return callable(req);
  }

  findPhysicsPlaylistProblems(playlistId: string): Observable<SciencePlaylistProblem[]> {
    const req: FindPlaylistProblemsRequest = { subjectId: SubjectId.PHYSICS, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEMS);
    return callable(req);
  }

  findChemistryPlaylistProblems(playlistId: string): Observable<SciencePlaylistProblem[]> {
    const req: FindPlaylistProblemsRequest = { subjectId: SubjectId.CHEMISTRY, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEMS);
    return callable(req);
  }

  findBiologyPlaylistProblems(playlistId: string): Observable<SciencePlaylistProblem[]> {
    const req: FindPlaylistProblemsRequest = { subjectId: SubjectId.BIOLOGY, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEMS);
    return callable(req);
  }

  findJapaneseHistoryPlaylistProblems(playlistId: string): Observable<HistoryPlaylistProblem[]> {
    const req: FindPlaylistProblemsRequest = { subjectId: SubjectId.JAPANESE_HISTORY, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEMS);
    return callable(req);
  }

  findWorldHistoryPlaylistProblems(playlistId: string): Observable<HistoryPlaylistProblem[]> {
    const req: FindPlaylistProblemsRequest = { subjectId: SubjectId.WORLD_HISTORY, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEMS);
    return callable(req);
  }

  findGeographyPlaylistProblems(playlistId: string): Observable<SciencePlaylistProblem[]> {
    const req: FindPlaylistProblemsRequest = { subjectId: SubjectId.GEOGRAPHY, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEMS);
    return callable(req);
  }

  findPoliticalEconomyPlaylistProblems(playlistId: string): Observable<SciencePlaylistProblem[]> {
    const req: FindPlaylistProblemsRequest = { subjectId: SubjectId.POLITICAL_ECONOMY, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEMS);
    return callable(req);
  }

  getPdfObjectUrl(pdfPath: string): Observable<string> {
    return this.storage
      .ref(pdfPath)
      .getDownloadURL()
      .pipe(
        catchError(e => throwError(() => FirebaseStorageError.from(e))),
        switchMap(url => this.http.get(url, { responseType: 'blob' }).pipe(map(blob => URL.createObjectURL(blob))))
      );
  }
}
