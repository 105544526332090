import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';

import { EnglishLongSentence } from 'src/app/models/english-data';
import { HighlightPipe } from 'src/app/views/pipes/highlight.pipe';

/** 可読処理をしたもの */
export interface EnglishProblemOutlineData {
  isThinking: boolean;
  fields: string;
  subTypes: string;
  longSentences?: EnglishLongSentence[];
  keywords?: string[];
  comment?: string;
}

@Component({
  selector: 'app-english-problem-outline',
  templateUrl: './english-problem-outline.component.html',
  styleUrls: ['./english-problem-outline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnglishProblemOutlineComponent implements OnChanges {
  private highlightPipe: HighlightPipe;

  @Input() outlineData: EnglishProblemOutlineData;

  fieldsAndSubTypes: string;
  longSentences: EnglishLongSentence[];

  constructor() {
    this.highlightPipe = new HighlightPipe();
  }

  ngOnChanges() {
    if (!this.outlineData) return;

    this.fieldsAndSubTypes =
      this.outlineData.subTypes !== '' ? `${this.outlineData.fields} / ${this.outlineData.subTypes}` : this.outlineData.fields;
    this.longSentences = this.outlineData.longSentences ? [...this.outlineData.longSentences] : [];
  }

  keywordHighlight(text: string): string {
    if (!this.outlineData || !this.outlineData.keywords) return text;
    return this.highlightPipe.transform(text, ...this.outlineData.keywords);
  }
}
