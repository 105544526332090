import { Juku } from 'src/app/models/juku';

export class JukuUtil {
  static getDisplayJukuName(juku: Juku): string {
    return juku.jukuName + (juku.jukuSubName ? `（${juku.jukuSubName}）` : '');
  }

  static getJukuByJukuCode(jukus: Juku[], jukuCode: string): Juku {
    return jukus.find(juku => juku.jukuCode === jukuCode);
  }
}
