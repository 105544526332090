<app-dialog-basic dialogTitle="登録処理を開始します。" [isLoading]="disabled">
  <div class="pb-4">
    <div>これより、アップロードしたCSVファイルの一括登録を開始します。</div>
  </div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="disabled"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="registerMembers()" [disabled]="disabled">登録開始</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
