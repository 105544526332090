import { Action, createReducer, on } from '@ngrx/store';

import * as CommonIdFindUniversityActions from '../../actions/common-id/common-id-find-university.actions';
import { AppError } from '../../errors/app-error';
import { CommonIdFindUniversityResponse } from '../../models/common-id/common-id-find-university-response';

export const commonIdFindUniversityFeatureKey = 'commonIdFindUniversity';

export interface FindUniversityResult {
  success: boolean;
  response: CommonIdFindUniversityResponse | null;
  error: AppError | null;
}

export interface State {
  result: FindUniversityResult;
}

export const initialState: State = {
  result: null
};

const commonIdFindUniversityReducer = createReducer(
  initialState,

  on(CommonIdFindUniversityActions.findUniversitySuccess, (state, { response }) => {
    const next: State = {
      ...state,
      result: { success: true, response, error: null }
    };
    return next;
  }),
  on(CommonIdFindUniversityActions.findUniversityFailure, (state, { error }) => {
    const next: State = {
      ...state,
      result: { success: false, response: null, error }
    };
    return next;
  }),
  on(CommonIdFindUniversityActions.initializeFindUniversityState, state => {
    const next: State = { ...state, result: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return commonIdFindUniversityReducer(state, action);
}
