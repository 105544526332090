import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

import { ForceAggregateEventLogRequest } from '../models/force-aggregate-event-log-request';
import { CallableFunction } from '../resources/app-firebase';
import { User } from '../models/user';
import { ToolsDeleteMembersRequest } from '../models/tools-delete-members.request';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {
  constructor(private aff: AngularFireFunctions) {}

  transferStaticData(): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.TRANSFER_STATIC_DATA);
    return callable({});
  }

  transferPlaylists(): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.TRANSFER_PLAYLISTS);
    return callable({});
  }

  commonIdTransferPlaylists(): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_TRANSFER_PLAYLISTS);
    return callable({});
  }

  forceRotateRawSearchEventGroup(): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.FORCE_ROTATE_RAW_SEARCH_EVENT_GROUP);
    return callable({});
  }

  forceExportSearchEventLog(): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.FORCE_EXPORT_SEARCH_EVENT_LOG);
    return callable({});
  }

  forceAggregateDailySearchEventLog(ymd: string): Observable<void> {
    const req: ForceAggregateEventLogRequest = { ymd };
    const callable = this.aff.httpsCallable(CallableFunction.FORCE_AGGREGATE_DAILY_SEARCH_EVENT_LOG);
    return callable(req);
  }

  forceAggregateWeeklySearchEventLog(ymd: string): Observable<void> {
    const req: ForceAggregateEventLogRequest = { ymd };
    const callable = this.aff.httpsCallable(CallableFunction.FORCE_AGGREGATE_WEEKLY_SEARCH_EVENT_LOG);
    return callable(req);
  }

  cleanUpRawSearchEventGroup(): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.CLEAN_UP_RAW_SEARCH_EVENT_GROUP);
    return callable({});
  }

  forceRotateRawAdminOperationEventGroup(): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.FORCE_ROTATE_RAW_ADMIN_OPERATION_EVENT_GROUP);
    return callable({});
  }

  forceExportAdminOperationEventLog(): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.FORCE_EXPORT_ADMIN_OPERATION_EVENT_LOG);
    return callable({});
  }

  forceAggregateDailyAdminOperationEventLog(ymd: string): Observable<void> {
    const req: ForceAggregateEventLogRequest = { ymd };
    const callable = this.aff.httpsCallable(CallableFunction.FORCE_AGGREGATE_DAILY_ADMIN_OPERATION_EVENT_LOG);
    return callable(req);
  }

  forceAggregateMonthlyAdminOperationEventLog(ymd: string): Observable<void> {
    const req: ForceAggregateEventLogRequest = { ymd };
    const callable = this.aff.httpsCallable(CallableFunction.FORCE_AGGREGATE_MONTHLY_ADMIN_OPERATION_EVENT_LOG);
    return callable(req);
  }

  cleanUpRawAdminOperationEventGroup(): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.CLEAN_UP_RAW_ADMIN_OPERATION_EVENT_GROUP);
    return callable({});
  }

  forceBackupFirestore(): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.FORCE_BACKUP_FIRESTORE);
    return callable({});
  }

  commonIdAggregateNumberOfUsers(): Observable<any> {
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_AGGREGATE_NUMBER_OF_USERS);
    return callable({});
  }

  checkBookmarkMaxCount(): Observable<any> {
    const callable = this.aff.httpsCallable(CallableFunction.CHECK_BOOKMARK_MAX_COUNT);
    return callable({});
  }

  checkUniversityBookmarkMaxCount(): Observable<any> {
    const callable = this.aff.httpsCallable(CallableFunction.CHECK_UNIVERSITY_BOOKMARK_MAX_COUNT);
    return callable({});
  }

  checkPaperBookmarkMaxCount(): Observable<any> {
    const callable = this.aff.httpsCallable(CallableFunction.CHECK_PAPER_BOOKMARK_MAX_COUNT);
    return callable({});
  }

  checkAnsweredProblemsMaxCount(): Observable<any> {
    const callable = this.aff.httpsCallable(CallableFunction.CHECK_ANSWERED_PROBLEMS_MAX_COUNT);
    return callable({});
  }

  checkPlaylistBookmarkMaxCount(): Observable<any> {
    const callable = this.aff.httpsCallable(CallableFunction.CHECK_PLAYLIST_BOOKMARK_MAX_COUNT);
    return callable({});
  }

  deleteMembers(users: User[]): Observable<any> {
    const req: ToolsDeleteMembersRequest = { users };
    const callable = this.aff.httpsCallable(CallableFunction.PUBLISH_BULK_DELETE_USERS);
    return callable(req);
  }
}
