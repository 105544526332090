<div *ngIf="isVisible" @slideFade>
  <section class="alert-box" [attr.data-type]="type" [ngStyle]="{ 'align-items': alignItems }">
    <div class="alert-box-icon">
      <mat-icon class="icon">{{ getIconName() }}</mat-icon>
    </div>
    <div class="alert-box-body">
      <ng-content></ng-content>
    </div>
    <button class="alert-box-button-close" *ngIf="closeable" (click)="closeAlert()">
      <mat-icon class="icon">close</mat-icon>
    </button>
  </section>
</div>
