<div #pageTop>
  <app-wrapper size="xl">
    <app-content-row>
      <div class="flex">
        <app-button-location-back (backClick)="showPreviousView()">検索条件に戻る</app-button-location-back>
        <span class="mx-auto"></span>
        <button mat-flat-button (click)="showPrint()" [disabled]="(initialized$ | async) === false" class="w-24 bg-gray-30 hover:bg-white">
          <mat-icon aria-hidden="true" class="text-primary">print</mat-icon>
          印刷
        </button>
      </div>
    </app-content-row>
  </app-wrapper>
  <app-wrapper size="xl" isFitWithSmallScreen="true">
    <app-content-row>
      <div [attr.aria-expanded]="conditionOpenState" [@fadeInOut]="conditionInitialized$ | async">
        <app-card-content [showSeparator]="false">
          <app-card-content-head>
            <div class="whitespace-nowrap">
              検索結果
              <span class="text-primary font-bold">
                {{ totalProblemCount }}
              </span>
              <span class="text-sm">件</span>
            </div>
            <div class="card-content-head-util">
              <div class="inline-condition">
                <div class="inline-condition-content">
                  <span *ngIf="!conditionOpenState" [attr.aria-hidden]="!conditionOpenState">
                    <!-- 英語の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === ENGLISH_SUBJECT_ID">
                      科目: 英語 /
                      <ng-container *ngIf="(readableSearchCondition$ | async)?.hasData !== '-'"
                        >問題・解答データ: {{ (readableSearchCondition$ | async)?.hasData }} /
                      </ng-container>
                      <ng-container *ngIf="(readableEnglishCondition$ | async)?.fields !== '-'">大問分野: 指定あり / </ng-container>
                      <ng-container *ngIf="(readableEnglishCondition$ | async)?.subTypes !== '-'">小問形式: 指定あり / </ng-container>
                      <ng-container *ngIf="(readableEnglishCondition$ | async)?.longSentenceTypes !== '-'"
                        >長文-英文形態: 指定あり /
                      </ng-container>
                      <ng-container *ngIf="(readableEnglishCondition$ | async)?.longSentenceWordCountRange !== '-'"
                        >長文-英単語数: 指定あり /
                      </ng-container>
                      <ng-container *ngIf="(readableEnglishCondition$ | async)?.longSentenceKeywords !== '-'"
                        >長文-キーワード: {{ (readableEnglishCondition$ | async)?.longSentenceKeywords }} /
                      </ng-container>
                    </ng-container>

                    <!-- 数学の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === MATH_SUBJECT_ID">
                      科目: 数学 /
                      <ng-container *ngIf="(readableSearchCondition$ | async)?.hasData !== '-'"
                        >問題・解答データ: {{ (readableSearchCondition$ | async)?.hasData }} /
                      </ng-container>
                      <app-selected-categories
                        [staticScienceData]="staticMathData$ | async"
                        [scienceSearchCondition]="mathSearchCondition"
                        [shortMode]="true"
                      ></app-selected-categories>
                    </ng-container>

                    <!-- 国語の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === NATIONAL_LANGUAGE_SUBJECT_ID">
                      科目: 国語 /
                      <ng-container *ngIf="(readableSearchCondition$ | async)?.hasData !== '-'"
                        >問題・解答データ: {{ (readableSearchCondition$ | async)?.hasData }} /
                      </ng-container>
                      <ng-container *ngIf="(readableNationalLanguageCondition$ | async)?.keywords !== '-'"
                        >作者・作品名・要旨キーワード: {{ (readableNationalLanguageCondition$ | async)?.keywords }} /
                      </ng-container>
                      <ng-container
                        *ngIf="
                          (readableNationalLanguageCondition$ | async)?.categories?.contemporary?.genres.join(', ') !== '-' ||
                          (readableNationalLanguageCondition$ | async)?.categories?.contemporary?.contents.join(', ') !== '-'
                        "
                        >現代文: 指定あり /
                      </ng-container>
                      <ng-container
                        *ngIf="
                          (readableNationalLanguageCondition$ | async)?.categories?.japaneseClassic?.genres.join(', ') !== '-' ||
                          (readableNationalLanguageCondition$ | async)?.categories?.japaneseClassic?.contents.join(', ') !== '-'
                        "
                        >古文: 指定あり /
                      </ng-container>
                      <ng-container
                        *ngIf="
                          (readableNationalLanguageCondition$ | async)?.categories?.chineseClassic?.genres.join(', ') !== '-' ||
                          (readableNationalLanguageCondition$ | async)?.categories?.chineseClassic?.contents.join(', ') !== '-'
                        "
                        >漢文: 指定あり /
                      </ng-container>
                      <ng-container *ngIf="(readableNationalLanguageCondition$ | async)?.wordCountRange !== '-'"
                        >素材文の文字数: {{ (readableNationalLanguageCondition$ | async)?.wordCountRange }} /
                      </ng-container>
                    </ng-container>

                    <!-- 物理の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === PHYSICS_SUBJECT_ID">
                      科目: 物理 /
                      <ng-container *ngIf="(readableSearchCondition$ | async)?.hasData !== '-'"
                        >問題・解答データ: {{ (readableSearchCondition$ | async)?.hasData }} /
                      </ng-container>
                      <app-selected-categories
                        [staticScienceData]="staticPhysicsData$ | async"
                        [scienceSearchCondition]="physicsSearchCondition"
                        [shortMode]="true"
                      ></app-selected-categories>
                    </ng-container>

                    <!-- 化学の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === CHEMISTRY_SUBJECT_ID">
                      科目: 化学 /
                      <ng-container *ngIf="(readableSearchCondition$ | async)?.hasData !== '-'"
                        >問題・解答データ: {{ (readableSearchCondition$ | async)?.hasData }} /
                      </ng-container>
                      <app-selected-categories
                        [staticScienceData]="staticChemistryData$ | async"
                        [scienceSearchCondition]="chemistrySearchCondition"
                        [shortMode]="true"
                      ></app-selected-categories>
                    </ng-container>

                    <!-- 生物の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === BIOLOGY_SUBJECT_ID">
                      科目: 生物 /
                      <ng-container *ngIf="(readableSearchCondition$ | async)?.hasData !== '-'"
                        >問題・解答データ: {{ (readableSearchCondition$ | async)?.hasData }} /
                      </ng-container>
                      <app-selected-categories
                        [staticScienceData]="staticBiologyData$ | async"
                        [scienceSearchCondition]="biologySearchCondition"
                        [shortMode]="true"
                      ></app-selected-categories>
                    </ng-container>

                    <!-- 日本史の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === JAPANESE_HISTORY_SUBJECT_ID">
                      科目: 日本史 /
                      <ng-container *ngIf="(readableSearchCondition$ | async)?.hasData !== '-'"
                        >問題・解答データ: {{ (readableSearchCondition$ | async)?.hasData }} /
                      </ng-container>
                      <app-selected-categories
                        [staticHistoryData]="staticJapaneseHistoryData$ | async"
                        [historySearchCondition]="japaneseHistorySearchCondition"
                        [shortMode]="true"
                      ></app-selected-categories>
                      <ng-container *ngIf="(readableJapaneseHistoryCondition$ | async)?.keywords !== '-'"
                        >分野・単元・出題テーマ: {{ (readableJapaneseHistoryCondition$ | async)?.keywords }} /
                      </ng-container>
                    </ng-container>

                    <!-- 世界史の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === WORLD_HISTORY_SUBJECT_ID">
                      科目: 世界史 /
                      <ng-container *ngIf="(readableSearchCondition$ | async)?.hasData !== '-'"
                        >問題・解答データ: {{ (readableSearchCondition$ | async)?.hasData }} /
                      </ng-container>
                      <app-selected-categories
                        [staticHistoryData]="staticWorldHistoryData$ | async"
                        [historySearchCondition]="worldHistorySearchCondition"
                        [shortMode]="true"
                      ></app-selected-categories>
                      <ng-container *ngIf="(readableWorldHistoryCondition$ | async)?.keywords !== '-'"
                        >分野・単元・出題テーマ: {{ (readableWorldHistoryCondition$ | async)?.keywords }} /
                      </ng-container>
                    </ng-container>

                    <!-- 地理の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === GEOGRAPHY_SUBJECT_ID">
                      科目: 地理 /
                      <ng-container *ngIf="(readableSearchCondition$ | async)?.hasData !== '-'"
                        >問題・解答データ: {{ (readableSearchCondition$ | async)?.hasData }} /
                      </ng-container>
                      <app-selected-categories
                        [staticScienceData]="staticGeographyData$ | async"
                        [scienceSearchCondition]="geographySearchCondition"
                        [shortMode]="true"
                      ></app-selected-categories>
                    </ng-container>

                    <!-- 政治経済の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === POLITICAL_ECONOMY_SUBJECT_ID">
                      科目: 政治・経済 /
                      <ng-container *ngIf="(readableSearchCondition$ | async)?.hasData !== '-'"
                        >問題・解答データ: {{ (readableSearchCondition$ | async)?.hasData }} /
                      </ng-container>
                      <app-selected-categories
                        [staticScienceData]="staticPoliticalEconomyData$ | async"
                        [scienceSearchCondition]="politicalEconomySearchCondition"
                        [shortMode]="true"
                      ></app-selected-categories>
                    </ng-container>

                    <!-- 共通の検索条件 -->
                    <ng-container *ngIf="(readableSearchCondition$ | async)?.thinking !== '-'"
                      >思考力問題: {{ (readableSearchCondition$ | async)?.thinking }} /
                    </ng-container>
                    <ng-container *ngIf="(readableSearchCondition$ | async)?.levels !== '-'">難易度: 指定あり / </ng-container>
                    <ng-container *ngIf="(readableSearchCondition$ | async)?.yearRange"
                      >年度: {{ (readableSearchCondition$ | async)?.yearRange }} /
                    </ng-container>
                    <ng-container *ngIf="(readableSearchCondition$ | async)?.universityTypes !== '-'">大学種別: 指定あり / </ng-container>
                    <ng-container *ngIf="(readableSearchCondition$ | async)?.areas !== '-'">エリア: 指定あり / </ng-container>
                    <ng-container *ngIf="(readableSearchCondition$ | async)?.universities !== '-'"
                      >大学名: {{ (readableSearchCondition$ | async)?.universities }} /
                    </ng-container>
                    <ng-container *ngIf="(readableSearchCondition$ | async)?.departmentCategory !== '指定なし'"
                      >学部系統: {{ (readableSearchCondition$ | async)?.departmentCategory }} /
                    </ng-container>
                  </span>
                </div>
                <div class="inline-condition-button">
                  <button
                    mat-button
                    class="text-primary text-sm pl-2 pr-1"
                    *ngIf="!conditionOpenState"
                    (click)="toggleConditionOpenState()"
                  >
                    検索条件を表示
                    <mat-icon aria-hidden="true">keyboard_arrow_down</mat-icon>
                  </button>
                  <button mat-button class="text-primary text-sm pl-2 pr-1" *ngIf="conditionOpenState" (click)="toggleConditionOpenState()">
                    検索条件を隠す
                    <mat-icon aria-hidden="true">keyboard_arrow_up</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </app-card-content-head>
          <app-provider-accordion [isExpanded]="conditionOpenState">
            <app-wrapper size="fluid">
              <app-content-row>
                <div [@fadeInOut]="conditionInitialized$ | async">
                  <div class="-mt-6">
                    <div class="condition-container">
                      <div class="condition-label">科目</div>
                      <div>{{ (readableSearchCondition$ | async)?.subject }}</div>
                    </div>

                    <div class="condition-container">
                      <div class="condition-label">問題・解答データ</div>
                      <div>{{ (readableSearchCondition$ | async)?.hasData }}</div>
                    </div>

                    <!-- 英語の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === ENGLISH_SUBJECT_ID">
                      <div class="condition-container">
                        <div class="condition-label">大問分野</div>
                        <div>{{ (readableEnglishCondition$ | async)?.fields }}</div>
                      </div>

                      <div class="condition-container">
                        <div class="condition-label">小問形式</div>
                        <div>{{ (readableEnglishCondition$ | async)?.subTypes }}</div>
                      </div>

                      <div class="condition-container">
                        <div class="condition-label">長文-英文形態</div>
                        <div>{{ (readableEnglishCondition$ | async)?.longSentenceTypes }}</div>
                      </div>

                      <div class="condition-container">
                        <div class="condition-label">長文-英単語数</div>
                        <div>{{ (readableEnglishCondition$ | async)?.longSentenceWordCountRange }}</div>
                      </div>

                      <div class="condition-container">
                        <div class="condition-label">長文-キーワード</div>
                        <div>{{ (readableEnglishCondition$ | async)?.longSentenceKeywords }}</div>
                      </div>
                    </ng-container>

                    <!-- 数学の検索条件 -->
                    <div class="condition-container" *ngIf="mathSearchCondition != null">
                      <div class="condition-label">分野</div>
                      <div class="selected-categories">
                        <app-selected-categories
                          [staticScienceData]="staticMathData$ | async"
                          [scienceSearchCondition]="mathSearchCondition"
                        ></app-selected-categories>
                      </div>
                    </div>

                    <!-- 国語の検索条件 -->
                    <ng-container *ngIf="searchCondition != null && searchCondition.subjectId === NATIONAL_LANGUAGE_SUBJECT_ID">
                      <div class="condition-container">
                        <div class="condition-label">作者・作品名・要旨キーワード</div>
                        <div>{{ (readableNationalLanguageCondition$ | async)?.keywords }}</div>
                      </div>
                      <div class="condition-container">
                        <div class="condition-label">現代文</div>
                        <div>
                          <div class="condition-container pl-0">
                            <div class="national-language-category-condition-label">大問ジャンル</div>
                            <div>{{ (readableNationalLanguageCondition$ | async)?.categories?.contemporary?.genres.join(', ') }}</div>
                          </div>
                          <div class="condition-container pl-0">
                            <div class="national-language-category-condition-label">小問内容</div>
                            <div>{{ (readableNationalLanguageCondition$ | async)?.categories?.contemporary?.contents.join(', ') }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="condition-container">
                        <div class="condition-label">古文</div>
                        <div>
                          <div class="condition-container pl-0">
                            <div class="national-language-category-condition-label">大問ジャンル</div>
                            <div>{{ (readableNationalLanguageCondition$ | async)?.categories?.japaneseClassic?.genres.join(', ') }}</div>
                          </div>
                          <div class="condition-container pl-0">
                            <div class="national-language-category-condition-label">小問内容</div>
                            <div>
                              {{ (readableNationalLanguageCondition$ | async)?.categories?.japaneseClassic?.contents.join(', ') }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="condition-container">
                        <div class="condition-label">漢文</div>
                        <div>
                          <div class="condition-container pl-0">
                            <div class="national-language-category-condition-label">大問ジャンル</div>
                            <div>{{ (readableNationalLanguageCondition$ | async)?.categories?.chineseClassic?.genres.join(', ') }}</div>
                          </div>
                          <div class="condition-container pl-0">
                            <div class="national-language-category-condition-label">小問内容</div>
                            <div>{{ (readableNationalLanguageCondition$ | async)?.categories?.chineseClassic?.contents.join(', ') }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="condition-container">
                        <div class="condition-label">素材文の文字数</div>
                        <div>{{ (readableNationalLanguageCondition$ | async)?.wordCountRange }}</div>
                      </div>
                    </ng-container>

                    <!-- 物理の検索条件 -->
                    <div class="condition-container" *ngIf="physicsSearchCondition != null">
                      <div class="condition-label">分野</div>
                      <div class="selected-categories">
                        <app-selected-categories
                          [staticScienceData]="staticPhysicsData$ | async"
                          [scienceSearchCondition]="physicsSearchCondition"
                        ></app-selected-categories>
                      </div>
                    </div>

                    <!-- 化学の検索条件 -->
                    <div class="condition-container" *ngIf="chemistrySearchCondition != null">
                      <div class="condition-label">分野</div>
                      <div class="selected-categories">
                        <app-selected-categories
                          [staticScienceData]="staticChemistryData$ | async"
                          [scienceSearchCondition]="chemistrySearchCondition"
                        ></app-selected-categories>
                      </div>
                    </div>

                    <!-- 生物の検索条件 -->
                    <div class="condition-container" *ngIf="biologySearchCondition != null">
                      <div class="condition-label">分野</div>
                      <div class="selected-categories">
                        <app-selected-categories
                          [staticScienceData]="staticBiologyData$ | async"
                          [scienceSearchCondition]="biologySearchCondition"
                        ></app-selected-categories>
                      </div>
                    </div>

                    <!-- 日本史の検索条件 -->
                    <ng-container *ngIf="japaneseHistorySearchCondition != null">
                      <div class="condition-container">
                        <div class="condition-label">分野</div>
                        <div class="selected-categories">
                          <app-selected-categories
                            [staticHistoryData]="staticJapaneseHistoryData$ | async"
                            [historySearchCondition]="japaneseHistorySearchCondition"
                          ></app-selected-categories>
                        </div>
                      </div>

                      <div class="condition-container">
                        <div class="condition-label">分野・単元・出題テーマ</div>
                        <div>{{ (readableJapaneseHistoryCondition$ | async)?.keywords }}</div>
                      </div>
                    </ng-container>

                    <!-- 世界史の検索条件 -->
                    <ng-container *ngIf="worldHistorySearchCondition != null">
                      <div class="condition-container">
                        <div class="condition-label">分野</div>
                        <div class="selected-categories">
                          <app-selected-categories
                            [staticHistoryData]="staticWorldHistoryData$ | async"
                            [historySearchCondition]="worldHistorySearchCondition"
                          ></app-selected-categories>
                        </div>
                      </div>

                      <div class="condition-container">
                        <div class="condition-label">分野・単元・出題テーマ</div>
                        <div>{{ (readableWorldHistoryCondition$ | async)?.keywords }}</div>
                      </div>
                    </ng-container>

                    <!-- 地理の検索条件 -->
                    <div class="condition-container" *ngIf="geographySearchCondition != null">
                      <div class="condition-label">分野</div>
                      <div class="selected-categories">
                        <app-selected-categories
                          [staticScienceData]="staticGeographyData$ | async"
                          [scienceSearchCondition]="geographySearchCondition"
                        ></app-selected-categories>
                      </div>
                    </div>

                    <!-- 政治経済の検索条件 -->
                    <div class="condition-container" *ngIf="politicalEconomySearchCondition != null">
                      <div class="condition-label">分野</div>
                      <div class="selected-categories">
                        <app-selected-categories
                          [staticScienceData]="staticPoliticalEconomyData$ | async"
                          [scienceSearchCondition]="politicalEconomySearchCondition"
                        ></app-selected-categories>
                      </div>
                    </div>

                    <!-- 共通の検索条件 -->
                    <div class="condition-container">
                      <div class="condition-label">思考力問題</div>
                      <div>{{ (readableSearchCondition$ | async)?.thinking }}</div>
                    </div>

                    <div class="condition-container">
                      <div class="condition-label">難易度</div>
                      <div>{{ (readableSearchCondition$ | async)?.levels }}</div>
                    </div>

                    <div class="condition-container">
                      <div class="condition-label">年度</div>
                      <div>{{ (readableSearchCondition$ | async)?.yearRange }}</div>
                    </div>

                    <div class="condition-container">
                      <div class="condition-label">大学種別</div>
                      <div>{{ (readableSearchCondition$ | async)?.universityTypes }}</div>
                    </div>

                    <div class="condition-container">
                      <div class="condition-label">エリア</div>
                      <div>{{ (readableSearchCondition$ | async)?.areas }}</div>
                    </div>

                    <div class="condition-container">
                      <div class="condition-label">大学名</div>
                      <div>{{ (readableSearchCondition$ | async)?.universities }}</div>
                    </div>

                    <div class="condition-container">
                      <div class="condition-label">学部系統</div>
                      <div>{{ (readableSearchCondition$ | async)?.departmentCategory }}</div>
                    </div>
                  </div>
                </div>
              </app-content-row>
            </app-wrapper>
          </app-provider-accordion>
        </app-card-content>
      </div>
    </app-content-row>

    <app-content-row>
      <div class="" @enter *ngIf="firstPageInitialized$ | async">
        <app-card-content [showSeparator]="false">
          <app-wrapper size="fluid">
            <app-content-row>
              <div @enter *ngIf="firstPageInitialized$ | async">
                <ng-container *ngIf="totalProblemCount != null && totalProblemCount !== 0">
                  <div class="flex items-center">
                    <!-- ソート -->
                    <div class="-mb-6">
                      <mat-form-field appearance="outline">
                        <mat-label>並び替え</mat-label>
                        <mat-select [(value)]="selectedSortType" (selectionChange)="onChangeSortType()">
                          <mat-option *ngFor="let sortType of selectableSortTypes" [value]="sortType">{{
                            sortType.displayName
                          }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <span class="mx-auto"></span>
                    <div class="paginator hidden md:block">
                      <app-paginator
                        [currentPage]="currentPage"
                        [totalItemCount]="totalProblemCount"
                        [pageSize]="paginationPageSize"
                        [windowSize]="paginationWindowSize"
                        (pageChange)="selectPage($event)"
                      ></app-paginator>
                    </div>
                  </div>
                </ng-container>
              </div>
            </app-content-row>
          </app-wrapper>
          <app-wrapper size="fluid" isFitWithSmallScreen="true">
            <app-content-row>
              <!--
              <div *ngIf="(initialized$ | async) === false">
                <div class="loading-placeholder"></div>
              </div>
              -->

              <div>
                <ng-container *ngIf="totalProblemCount != null && totalProblemCount !== 0; else noProblemMessage">
                  <div>
                    <!-- 英語の検索結果 -->
                    <div class="" *ngIf="searchCondition?.subjectId === ENGLISH_SUBJECT_ID">
                      <div [@fadeInOut]="(problemsSearching$ | async) === false">
                        <app-english-problems
                          [readableEnglishProblems]="readableEnglishProblems$ | async"
                          [longSentenceKeywords]="englishSearchCondition?.longSentenceKeywords"
                          (problemClick)="showEnglishProblemDetail($event)"
                          (addBookmarkClick)="addBookmark($event)"
                          (deleteBookmarkClick)="deleteBookmark($event)"
                          [initializedBookmark$]="this.initializedBookmark$"
                          [bookmarkProblems]="this.bookmarkSubjectProblems"
                          [canBookmarkSpiner]="true"
                          [isTrial]="this.user.isTrial"
                        ></app-english-problems>
                      </div>
                    </div>
                    <!-- 数学の検索結果 -->
                    <div class="" *ngIf="searchCondition?.subjectId === MATH_SUBJECT_ID">
                      <div [@fadeInOut]="(problemsSearching$ | async) === false">
                        <app-science-problems
                          [readableScienceProblems]="readableMathProblems$ | async"
                          (problemClick)="showScienceProblemDetail($event)"
                          (addBookmarkClick)="addBookmark($event)"
                          (deleteBookmarkClick)="deleteBookmark($event)"
                          [initializedBookmark$]="this.initializedBookmark$"
                          [bookmarkProblems]="this.bookmarkSubjectProblems"
                          [canBookmarkSpiner]="true"
                          [isTrial]="this.user.isTrial"
                        ></app-science-problems>
                      </div>
                    </div>

                    <!-- 国語の検索結果 -->
                    <div class="" *ngIf="searchCondition?.subjectId === NATIONAL_LANGUAGE_SUBJECT_ID">
                      <div [@fadeInOut]="(problemsSearching$ | async) === false">
                        <app-national-language-problems
                          [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
                          [keywords]="nationalLanguageSearchCondition?.keywords"
                          (problemClick)="showNationalLanguageProblemDetail($event)"
                          (addBookmarkClick)="addBookmark($event)"
                          (deleteBookmarkClick)="deleteBookmark($event)"
                          [initializedBookmark$]="this.initializedBookmark$"
                          [bookmarkProblems]="this.bookmarkSubjectProblems"
                          [canBookmarkSpiner]="true"
                          [isTrial]="this.user.isTrial"
                        ></app-national-language-problems>
                      </div>
                    </div>

                    <!-- 物理の検索結果 -->
                    <div class="" *ngIf="searchCondition?.subjectId === PHYSICS_SUBJECT_ID">
                      <div [@fadeInOut]="(problemsSearching$ | async) === false">
                        <app-science-problems
                          [readableScienceProblems]="readablePhysicsProblems$ | async"
                          (problemClick)="showScienceProblemDetail($event)"
                          (addBookmarkClick)="addBookmark($event)"
                          (deleteBookmarkClick)="deleteBookmark($event)"
                          [initializedBookmark$]="this.initializedBookmark$"
                          [bookmarkProblems]="this.bookmarkSubjectProblems"
                          [canBookmarkSpiner]="true"
                          [isTrial]="this.user.isTrial"
                        ></app-science-problems>
                      </div>
                    </div>

                    <!-- 化学の検索結果 -->
                    <div class="" *ngIf="searchCondition?.subjectId === CHEMISTRY_SUBJECT_ID">
                      <div [@fadeInOut]="(problemsSearching$ | async) === false">
                        <app-science-problems
                          [readableScienceProblems]="readableChemistryProblems$ | async"
                          (problemClick)="showScienceProblemDetail($event)"
                          (addBookmarkClick)="addBookmark($event)"
                          (deleteBookmarkClick)="deleteBookmark($event)"
                          [initializedBookmark$]="this.initializedBookmark$"
                          [bookmarkProblems]="this.bookmarkSubjectProblems"
                          [canBookmarkSpiner]="true"
                          [isTrial]="this.user.isTrial"
                        ></app-science-problems>
                      </div>
                    </div>

                    <!-- 生物の検索結果 -->
                    <div class="" *ngIf="searchCondition?.subjectId === BIOLOGY_SUBJECT_ID">
                      <div [@fadeInOut]="(problemsSearching$ | async) === false">
                        <app-science-problems
                          [readableScienceProblems]="readableBiologyProblems$ | async"
                          (problemClick)="showScienceProblemDetail($event)"
                          (addBookmarkClick)="addBookmark($event)"
                          (deleteBookmarkClick)="deleteBookmark($event)"
                          [initializedBookmark$]="this.initializedBookmark$"
                          [bookmarkProblems]="this.bookmarkSubjectProblems"
                          [canBookmarkSpiner]="true"
                          [isTrial]="this.user.isTrial"
                        ></app-science-problems>
                      </div>
                    </div>

                    <!-- 日本史の検索結果 -->
                    <div class="" *ngIf="searchCondition?.subjectId === JAPANESE_HISTORY_SUBJECT_ID">
                      <div [@fadeInOut]="(problemsSearching$ | async) === false">
                        <app-history-problems
                          [readableHistoryProblems]="readableJapaneseHistoryProblems$ | async"
                          [keywords]="japaneseHistorySearchCondition?.keywords"
                          (problemClick)="showJapaneseHistoryProblemDetail($event)"
                          (addBookmarkClick)="addBookmark($event)"
                          (deleteBookmarkClick)="deleteBookmark($event)"
                          [initializedBookmark$]="this.initializedBookmark$"
                          [bookmarkProblems]="this.bookmarkSubjectProblems"
                          [canBookmarkSpiner]="true"
                          [isTrial]="this.user.isTrial"
                        ></app-history-problems>
                      </div>
                    </div>

                    <!-- 世界史の検索結果 -->
                    <div class="" *ngIf="searchCondition?.subjectId === WORLD_HISTORY_SUBJECT_ID">
                      <div [@fadeInOut]="(problemsSearching$ | async) === false">
                        <app-history-problems
                          [readableHistoryProblems]="readableWorldHistoryProblems$ | async"
                          [keywords]="worldHistorySearchCondition?.keywords"
                          (problemClick)="showWorldHistoryProblemDetail($event)"
                          (addBookmarkClick)="addBookmark($event)"
                          (deleteBookmarkClick)="deleteBookmark($event)"
                          [initializedBookmark$]="this.initializedBookmark$"
                          [bookmarkProblems]="this.bookmarkSubjectProblems"
                          [canBookmarkSpiner]="true"
                          [isTrial]="this.user.isTrial"
                        ></app-history-problems>
                      </div>
                    </div>

                    <!-- 地理の検索結果 -->
                    <div class="" *ngIf="searchCondition?.subjectId === GEOGRAPHY_SUBJECT_ID">
                      <div [@fadeInOut]="(problemsSearching$ | async) === false">
                        <app-science-problems
                          [readableScienceProblems]="readableGeographyProblems$ | async"
                          (problemClick)="showScienceProblemDetail($event)"
                          (addBookmarkClick)="addBookmark($event)"
                          (deleteBookmarkClick)="deleteBookmark($event)"
                          [initializedBookmark$]="this.initializedBookmark$"
                          [bookmarkProblems]="this.bookmarkSubjectProblems"
                          [canBookmarkSpiner]="true"
                          [isTrial]="this.user.isTrial"
                        ></app-science-problems>
                      </div>
                    </div>

                    <!-- 政治経済の検索結果 -->
                    <div class="" *ngIf="searchCondition?.subjectId === POLITICAL_ECONOMY_SUBJECT_ID">
                      <div [@fadeInOut]="(problemsSearching$ | async) === false">
                        <app-science-problems
                          [readableScienceProblems]="readablePoliticalEconomyProblems$ | async"
                          (problemClick)="showScienceProblemDetail($event)"
                          (addBookmarkClick)="addBookmark($event)"
                          (deleteBookmarkClick)="deleteBookmark($event)"
                          [initializedBookmark$]="this.initializedBookmark$"
                          [bookmarkProblems]="this.bookmarkSubjectProblems"
                          [canBookmarkSpiner]="true"
                          [isTrial]="this.user.isTrial"
                        ></app-science-problems>
                      </div>
                    </div>

                    <!-- 問題取得中のプログレス -->
                    <div class="progress-48" [@fadeInOut]="problemsSearching$ | async">
                      <mat-spinner [diameter]="48"></mat-spinner>
                    </div>

                    <div class="mt-4">
                      <app-paginator
                        [currentPage]="currentPage"
                        [totalItemCount]="totalProblemCount"
                        [pageSize]="paginationPageSize"
                        [windowSize]="paginationWindowSize"
                        (pageChange)="selectPage($event)"
                      ></app-paginator>
                    </div>
                  </div>
                </ng-container>
              </div>
            </app-content-row>
          </app-wrapper>
        </app-card-content>
      </div>

      <div *ngIf="(initialized$ | async) === false">
        <div class="progress-48">
          <mat-spinner [diameter]="48"></mat-spinner>
        </div>
      </div>
    </app-content-row>
  </app-wrapper>
</div>

<ng-template #noProblemMessage>
  <div class="mat-title no-problem-message">指定された条件に該当する問題が存在しません</div>
  <div class="mat-title no-problem-message">基本検索画面に戻り、条件を再設定してください</div>
</ng-template>
