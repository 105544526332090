import { createFeatureSelector, createSelector } from '@ngrx/store';
import { commonIdUniversityBookmarkFeatureKey, State } from '../../reducers/common-id/common-id-university-bookmark.reducer';

const selectCommonIdUniversityBookmarkState = createFeatureSelector<State>(commonIdUniversityBookmarkFeatureKey);

export const getUniversityBookmark = createSelector(selectCommonIdUniversityBookmarkState, state => state.universityBookmark);
export const getAddUniversityBookmarkResult = createSelector(
  selectCommonIdUniversityBookmarkState,
  state => state.addUniversityBookmarkResult
);
export const getDeleteUniversityBookmarkResult = createSelector(
  selectCommonIdUniversityBookmarkState,
  state => state.deleteUniversityBookmarkResult
);

export const getUniversityBookmarkInTheProcess = createSelector(
  selectCommonIdUniversityBookmarkState,
  state => state.isUniversityBookmarkInTheProcess
);
