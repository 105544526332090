import { createAction, props } from '@ngrx/store';

import { CreatePlanData, UpdatePlanData, DeletePlanData, Plan } from '../models/plan';
import { AppError } from '../errors/app-error';
import { DeletePlanResponse } from '../models/delete-plan-response';
import { User } from '../models/user';
import { UpdateDisableRenewPlanAlertRequest } from '../models/update-disable-renew-plan-alert-request';
import { UpdateDisableRenewPlanAlertResponse } from '../models/update-disable-renew-plan-alert-response';

// findPlan ------------------------------------------------------------
export const findPlan = createAction('[Plan] Find Plan', props<{ planId: string }>());
export const findPlanSuccess = createAction('[Plan] Find Plan Success', props<{ plan: Plan }>());
export const findPlanFailure = createAction('[Plan] Find Plan Failure');
export const initializeFindPlanState = createAction('[Plan] Initialize Find Plan State');

// findPlans ------------------------------------------------------------
export const findPlans = createAction('[Plan] Find Plans', props<{ organization?: string; schoolId?: string; subjectId?: string }>());
export const findPlansSuccess = createAction('[Plan] Find Plans Success', props<{ plans: Plan[] }>());
export const findPlansFailure = createAction('[Plan] Find Plans Failure');
export const initializeFindPlansState = createAction('[Plan] Initialize Find Plans State');

// findSignedInUserPlans ------------------------------------------------------------
export const findSignedInUserPlans = createAction('[Plan] Find SignedIn User Plans', props<{ signedInUser: User }>());
export const findSignedInUserPlansSuccess = createAction('[Plan] Find SignedIn User Plans Success', props<{ signedInUserPlans: Plan[] }>());
export const findSignedInUserPlansFailure = createAction('[Plan] Find SignedIn User Plans Failure');
export const initializeFindSignedInUserPlansState = createAction('[Plan] Initialize Find SignedIn User Plans State');

export const createPlan = createAction('[Plan] Create Plan', props<{ plan: CreatePlanData }>());
export const createPlanSuccess = createAction('[Plan] Create Plan Success', props<{ plan: Plan }>());
export const createPlanFailure = createAction('[Plan] Create Plan Failure', props<{ error: AppError }>());
export const initializeCreatePlanState = createAction('[Plan] Initialize Crate Plan State');

export const updatePlan = createAction('[Plan] Update Plan', props<{ plan: UpdatePlanData }>());
export const updatePlanSuccess = createAction('[Plan] Update Plan Success', props<{ plan: Plan }>());
export const updatePlanFailure = createAction('[Plan] Update Plan Failure', props<{ error: AppError }>());
export const initializeUpdatePlanState = createAction('[Plan] Initialize Update Plan State');

export const deletePlan = createAction('[Plan] Delete Plan', props<{ plan: DeletePlanData }>());
export const deletePlanSuccess = createAction('[Plan] Delete Plan Success', props<{ response: DeletePlanResponse }>());
export const deletePlanFailure = createAction('[Plan] Delete Plan Failure', props<{ error: AppError }>());
export const initializeDeletePlanState = createAction('[Plan] Initialize Delete Plan State');

export const assignPlan = createAction('[Plan] Assign Plan', props<{ userIds: string[]; planId: string; subjectId: string }>());
export const assignPlanSuccess = createAction('[Plan] Assign Plan Success', props<{ userIds: string[] }>());
export const assignPlanFailure = createAction('[Plan] Assign Plan Failure', props<{ error: AppError }>());
export const initializeAssignPlanState = createAction('[Plan] Initialize Assign Plan State');

export const unAssignPlan = createAction('[Plan] Unassign Plan', props<{ userIds: string[]; planId: string; subjectId: string }>());
export const unAssignPlanSuccess = createAction('[Plan] Un Assign Plan Success', props<{ userIds: string[] }>());
export const unAssignPlanFailure = createAction('[Plan] Un Assign Plan Failure', props<{ error: AppError }>());
export const initializeUnAssignPlanState = createAction('[Plan] Initialize Unassign Plan State');

export const updateDisableRenewPlanAlert = createAction(
  '[Plan] Update Disable Renew Plan Alert',
  props<{ updateDisableRenewPlanAlert: UpdateDisableRenewPlanAlertRequest }>()
);
export const updateDisableRenewPlanAlertSuccess = createAction(
  '[Plan] Update Disable Renew Plan Alert Success',
  props<{ response: UpdateDisableRenewPlanAlertResponse }>()
);
export const updateDisableRenewPlanAlertFailure = createAction(
  '[Plan] Update Disable Renew Plan Alert Failure',
  props<{ error: AppError }>()
);
export const initializeUpdateDisableRenewPlanAlertState = createAction('[Plan] Initialize Update Disable Renew Plan Alert State');
