import { createReducer, on } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { CommonIdGetStripeCheckoutUrlResponse } from '../../models/common-id/common-id-get-stripe-checkout-url-response';
import * as CommonIdGetStripeCheckoutUrlActions from '../../actions/common-id/common-id-get-stripe-checkout-url.actions';

export const commonIdGetStripeCheckoutUrlFeatureKey = 'commonIdGetStripeCheckoutUrl';

export interface CommonIdGetStripeCheckoutUrlResult {
  success: boolean;
  response: CommonIdGetStripeCheckoutUrlResponse | null;
  error: AppError | null;
}

export interface State {
  commonIdGetStripeCheckoutUrlResult: CommonIdGetStripeCheckoutUrlResult | null;
}

export const initialState: State = {
  commonIdGetStripeCheckoutUrlResult: null
};

export const reducer = createReducer(
  initialState,
  on(CommonIdGetStripeCheckoutUrlActions.commonIdGetStripeCheckoutUrlSuccess, (state, { response }) => ({
    ...state,
    commonIdGetStripeCheckoutUrlResult: { success: true, response, error: null }
  })),
  on(CommonIdGetStripeCheckoutUrlActions.commonIdGetStripeCheckoutUrlFailure, (state, { error }) => ({
    ...state,
    commonIdGetStripeCheckoutUrlResult: { success: false, response: null, error }
  })),
  on(CommonIdGetStripeCheckoutUrlActions.initializeCommonIdGetStripeCheckoutUrlState, state => {
    const next: State = { ...state, commonIdGetStripeCheckoutUrlResult: null };
    return next;
  })
);
