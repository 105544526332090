<app-dialog-basic dialogTitle="プランの編集" [isLoading]="planEditing">
  <form class="input-form" [formGroup]="planForm">
    <!-- 学校名 -->
    <div class="mb-4">
      <div class="mb-1">
        <label class="text-sm" *ngIf="organization === schoolOrganizationId">学校名</label>
        <label class="text-sm" *ngIf="organization === jukuOrganizationId">塾・予備校名</label>
      </div>
      <div class="p-4 bg-gray-30 rounded-md">{{ this.plan.schoolId }} {{ this.school }}</div>
    </div>

    <!-- 科目 -->
    <div class="mb-6">
      <div class="mb-1"><label class="text-sm">科目</label></div>
      <div class="p-4 bg-gray-30 rounded-md">{{ subjects[plan.subjectId] }}</div>
    </div>

    <!-- 人数 -->
    <mat-form-field appearance="outline">
      <mat-label>人数</mat-label>
      <input matInput formControlName="memberCount" />
      <mat-error *ngIf="planForm.get('memberCount').hasError('required')">入力してください</mat-error>
      <mat-error *ngIf="planForm.get('memberCount').hasError('pattern')">半角数字を入力してください</mat-error>
    </mat-form-field>

    <!-- 期間 -->
    <div class="grid grid-cols-2 gap-x-2 gap-y-4">
      <mat-form-field appearance="outline">
        <mat-label>開始日</mat-label>
        <input
          matInput
          formControlName="startAt"
          [matDatepicker]="startAt"
          readonly
          (click)="startAt.open()"
          [min]="startAtDisabled ? null : minDate"
          (dateChange)="onChangeStartAt()"
        />
        <mat-datepicker-toggle matSuffix [for]="startAt"></mat-datepicker-toggle>
        <mat-datepicker #startAt [disabled]="startAtDisabled"></mat-datepicker>
        <mat-error *ngIf="planForm.get('startAt').hasError('required')">入力してください</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>終了日</mat-label>
        <input matInput formControlName="endAt" [matDatepicker]="endAt" readonly (click)="endAt.open()" />
        <mat-datepicker-toggle matSuffix [for]="endAt"></mat-datepicker-toggle>
        <mat-datepicker #endAt></mat-datepicker>
        <mat-error *ngIf="planForm.get('endAt').hasError('required')">入力してください</mat-error>
        <mat-error *ngIf="planForm.get('endAt').hasError('endAtIsAfterStartAt')">開始日より後の日付を入力してください</mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="planEditing"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <div class="text-danger text-sm mr-auto" @enter *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
    <button mat-stroked-button (click)="cancel()" [disabled]="planEditing">キャンセル</button>
    <button mat-flat-button color="primary" (click)="editPlan()" [disabled]="planEditing">更新</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
