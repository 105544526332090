import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

import * as CommonIdFindPlaylistOriginalProblemIdsActions from '../../actions/common-id/common-id-find-playlist-original-problems.action';
import { CommonIdFindPlaylistOriginalProblemsService } from 'src/app/services/common-id/common-id-find-playlist-original-problems.service';
import { FirebaseCallableFunctionError } from 'src/app/errors/firebase-callable-function-error';

@Injectable()
export class CommonIdFindPlaylistOriginalProblemIdsEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdFindPlaylistOriginalProblemIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdFindPlaylistOriginalProblemIdsActions.commonIdFindPlaylistOriginalProblemIds),
      switchMap(() =>
        this.commonIdFindPlaylistOriginalProblemIdsService.commonIdFindPlaylistOriginalProblemIds().pipe(
          map(response => CommonIdFindPlaylistOriginalProblemIdsActions.commonIdFindPlaylistOriginalProblemIdsSuccess({ response })),
          catchError(e =>
            of(
              CommonIdFindPlaylistOriginalProblemIdsActions.commonIdFindPlaylistOriginalProblemIdsFailure({
                error: FirebaseCallableFunctionError.from(e)
              })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private commonIdFindPlaylistOriginalProblemIdsService: CommonIdFindPlaylistOriginalProblemsService
  ) {}
}
