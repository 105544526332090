import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { FirebaseCallableFunctionError } from 'src/app/errors/firebase-callable-function-error';
import { CommonIdInformationService } from 'src/app/services/common-id/common-id-information.service';

import * as CommonIdInformationActions from '../../actions/common-id/common-id-information.actions';

@Injectable()
export class CommonIdInformationEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdGetInformations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdInformationActions.commonIdGetInformations),
      switchMap(() =>
        this.commonIdInformationService.commonIdGetInformations().pipe(
          map(response => CommonIdInformationActions.commonIdGetInformationsSuccess({ response })),
          catchError(e => of(CommonIdInformationActions.commonIdGetInformationsFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdInformationService: CommonIdInformationService) {}
}
