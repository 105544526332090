import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tips-content',
  templateUrl: './tips-content.component.html',
  styleUrls: ['./tips-content.component.scss']
})
export class TipsContentComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
