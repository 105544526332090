import { trigger, style, transition, animate, state } from '@angular/animations';

const animateDuration = 150; // ms

export const enter = trigger('enter', [transition(':enter', [style({ opacity: 0 }), animate(animateDuration, style({ opacity: 1 }))])]);

export const fadeInOut = trigger('fadeInOut', [
  state('true', style({ opacity: '1' })),
  state('false', style({ opacity: '0' })),
  transition('true <=> false', animate(animateDuration))
]);

export const inOut = trigger('inOut', [state('true', style({ opacity: '1' })), state('false', style({ opacity: '0', display: 'none' }))]);
export const showHide = trigger('showHide', [state('true', style({ opacity: '1' })), state('false', style({ opacity: '0' }))]);
