import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, pairwise, share, throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScrollDirectionService {
  private scrollDirectionSubject = new BehaviorSubject(true);
  scrollDirectionIsUp = this.scrollDirectionSubject.asObservable();

  constructor() {
    this.listenToScrollEvents();
  }

  private listenToScrollEvents() {
    fromEvent(window, 'scroll')
      .pipe(
        throttleTime(100),
        map(() => window.pageYOffset),
        pairwise(),
        map(([y1, y2]) => y2 < y1),
        distinctUntilChanged(),
        share()
      )
      .subscribe(isScrollingUp => this.scrollDirectionSubject.next(isScrollingUp));
  }
}
