import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-icon-word',
  templateUrl: './icon-word.component.html',
  styleUrls: ['./icon-word.component.scss']
})
export class IconWordComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
