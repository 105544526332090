import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { navigate, setTitle } from '../../../../actions/core.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';

@Component({
  selector: 'app-common-id-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class CommonIdSignInComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private title = 'サインイン';

  private loginButtonDisabled = false;

  constructor(private store: Store<RootState>, @Inject(WINDOW_OBJECT) private window: Window) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.clearLocalStorage();
  }

  ngOnDestroy() {}

  gotoSearch() {
    if (!this.loginButtonDisabled) {
      this.loginButtonDisabled = true;
      this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUniv() }));
    }
  }

  private clearLocalStorage() {
    localStorage.removeItem('previousUrl');
    localStorage.removeItem('state');
    localStorage.removeItem('nonce');
  }
}
