<div class="root-container">
  <mat-tab-group [(selectedIndex)]="selectedTabIndex">
    <mat-tab *ngFor="let subject of subjectDataArray" label="{{ subject.name }}">
      <div class="mt-4 mx-4">
        <mat-checkbox
          color="primary"
          [(indeterminate)]="subject.indeterminate"
          [(ngModel)]="subject.checked"
          (change)="onChangeSubject(subject)"
          >すべてを選択する</mat-checkbox
        >
      </div>
      <div class="subject-container" *ngIf="!isSubjectDataType()">
        <div class="field-container" *ngFor="let field of subject.fields">
          <mat-checkbox
            color="primary"
            [(indeterminate)]="field.indeterminate"
            [(ngModel)]="field.checked"
            (change)="onChangeField(field, subject)"
            >{{ field.name }}</mat-checkbox
          >
          <div class="unit-container">
            <ng-container *ngFor="let unit of field.units">
              <mat-checkbox color="primary" [(ngModel)]="unit.checked" (change)="onChangeUnit(field, subject)">{{
                unit.name
              }}</mat-checkbox>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="subject-container" *ngIf="isSubjectDataType()">
        <div class="field-container">
          <ng-container *ngFor="let unit of subject.fields[0].units">
            <mat-checkbox color="primary" [(ngModel)]="unit.checked" (change)="onChangeUnit(subject.fields[0], subject)">{{
              unit.name
            }}</mat-checkbox>
          </ng-container>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="buttons-container">
    <div class="flex justify-start items-center text-lg md:text-xl">
      <div class="font-bold">検索結果</div>
      <div class="text-primary font-bold ml-2">
        {{ (matchedProblemCount$ | async) == null ? '-' : (matchedProblemCount$ | async) }}
      </div>
      <div class="ml-2 mr-2 text-md relative">件</div>
      <div [@inOut]="problemCountSearching$ | async">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>
    </div>
    <span class="spacer"></span>
    <button mat-stroked-button (click)="reset()">
      <mat-icon class="text-gray-300 -mt-[1px]">delete</mat-icon>
      リセット
    </button>
    <button mat-flat-button color="primary" (click)="close()">閉じる</button>
  </div>
</div>
