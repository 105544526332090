<app-main-layout-spacer>
  <app-sign-out-header></app-sign-out-header>

  <app-wrapper size="lg">
    <app-content-row>
      <app-article-content>
        <app-article-content-head>特定商取引法に基づく表示</app-article-content-head>
        <app-document-theme-default>
          <div>
            <table>
              <tr>
                <th>1. 事業者の名称、住所、電話番号</th>
                <td>
                  <ul class="mt-0">
                    <li>事業者名 株式会社旺文社</li>
                    <li>事業者の住所 〒162-8680　東京都新宿区横寺町５５</li>
                    <li>電話番号　03-3266-6448　（平日10時～16時／12時～13時を除く）</li>
                    <li>メールアドレス　nyushi-digi@obunsha.co.jp</li>
                    <li>責任者名　粂川　秀樹</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>2. 販売価格</th>
                <td>お問い合わせいただき次第、ご連絡いたします。</td>
              </tr>
              <tr>
                <th>3. 対価以外に必要となる費用</th>
                <td>お問い合わせいただき次第、ご連絡いたします。</td>
              </tr>
              <tr>
                <th>4. 商品代金の支払い方法・支払い時期</th>
                <td>
                  <p>
                    <strong>学校の場合：</strong><br />
                    銀行振込にてお支払いいただき、当社からのアカウント発行後の翌月末日までにお支払いください。
                  </p>
                </td>
              </tr>
              <tr>
                <th>5. 商品の引渡時期</th>
                <td>
                  申込内容を当社が確認・承諾し、所定の手続きが完了した後、原則7営業日以内に申込者に対し、
                  サービス開始に必要な情報を記載した電子メールを送付し、お申込者が利用規約に同意後、利用可能な状態になります。
                </td>
              </tr>
              <tr>
                <th>6. 返品、解約等に関する事項</th>
                <td>デジタルコンテンツの特性上、購入確定後の返品・交換には応じられません。</td>
              </tr>
              <tr>
                <th>7. 動作環境</th>
                <td>サービスをご利用いただける推奨動作環境は、ヘルプページをご確認ください。</td>
              </tr>
            </table>
          </div>
        </app-document-theme-default>
      </app-article-content>
    </app-content-row>
  </app-wrapper>

  <app-footer></app-footer>
</app-main-layout-spacer>
