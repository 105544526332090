import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-members',
  templateUrl: './plan-members.component.html',
  styleUrls: ['./plan-members.component.scss']
})
export class PlanMembersComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
