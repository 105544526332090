<div class="problem-detail-data-table">
  <table>
    <tr>
      <th>大学名</th>
      <td class="sep">:</td>
      <td>{{ universityName }}</td>
    </tr>
    <tr>
      <th>大学コード</th>
      <td class="sep">:</td>
      <td>
        <span class="code-g">{{ universityId }}</span>
      </td>
    </tr>
    <tr>
      <th>年度</th>
      <td class="sep">:</td>
      <td>
        <span>20</span>
        <span class="code-r">{{ year }}</span>
      </td>
    </tr>
    <tr>
      <th>入試名</th>
      <td class="sep">:</td>
      <td>{{ nyushiName }}</td>
    </tr>
    <tr>
      <th>科目</th>
      <td class="sep">:</td>
      <td>{{ subjectName }}</td>
    </tr>
    <tr>
      <th>科目コード</th>
      <td class="sep">:</td>
      <td>
        <span class="code-b">{{ subjectId }}</span>
      </td>
    </tr>
    <tr>
      <th>過去問ID</th>
      <td class="sep">:</td>
      <td>
        <span class="code-r">{{ year }}</span>
        <span class="code-g">{{ universityId }}</span>
        <span class="code-b">{{ subjectId }}</span>
        <span>{{ shikenNumber }}</span>
      </td>
    </tr>
    <tr>
      <th>大問ID</th>
      <td class="sep">:</td>
      <td>{{ daimonId }}</td>
    </tr>
    <tr>
      <th>対象学部</th>
      <td class="sep">:</td>
      <td>{{ departments }}</td>
    </tr>
  </table>
</div>
