import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

import { CallableFunction } from '../resources/app-firebase';
import { FindUniversityRequest } from '../models/find-university-request';
import { FindUniversityResponse } from '../models/find-university-response';

@Injectable({
  providedIn: 'root'
})
export class FindUniversityService {
  constructor(private aff: AngularFireFunctions) {}

  findUniversity(request: FindUniversityRequest): Observable<FindUniversityResponse> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_UNIVERSITY);
    return callable(request);
  }
}
