import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { Prefecture } from 'src/app/models/common-data';
import { enter } from 'src/app/resources/animations';
import { Log } from 'src/app/utils/log';
import { RootState } from 'src/app/reducers';
import { dispatchInfoMessage } from 'src/app/actions/core.actions';
import { PREFECTURES } from '../../../resources/config';
import { Juku, UpdateJukuData } from '../../../models/juku';
import { initializeUpdateJukuState, updateJuku } from 'src/app/actions/juku.actions';
import { getUpdateJukuResult } from 'src/app/selectors/juku.selectors';

export interface EditJukuDialogData {
  juku: Juku;
}

@Component({
  selector: 'app-edit-juku-dialog',
  templateUrl: './edit-juku-dialog.component.html',
  styleUrls: ['./edit-juku-dialog.component.scss'],
  animations: [enter]
})
export class EditJukuDialogComponent implements OnInit, OnDestroy {
  jukuForm: UntypedFormGroup;
  prefectures: Prefecture[];
  jukuId: string;
  jukuEditing = false;
  errorMessage = '';
  dialogTitle: string;

  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<EditJukuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: EditJukuDialogData
  ) {}

  ngOnInit() {
    this.prefectures = PREFECTURES;
    this.dialogTitle = '塾・予備校の編集';

    this.setUpForms();
    this.setUpValues();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeUpdateJukuState());
  }

  editJuku() {
    if (this.jukuForm.invalid) {
      this.jukuForm.markAllAsTouched();
      return;
    }
    Log.debug(this.LOG_SOURCE, '塾・予備校を変更します');
    this.disableForms();
    this.errorMessage = '';

    const jukuName: string = this.jukuForm.get('jukuName').value;
    const jukuSubName: string = this.jukuForm.get('jukuSubName').value;
    const jukuCode: string = this.jukuForm.get('jukuCode').value;
    const prefectureId: string = this.jukuForm.get('prefectureId').value;

    const juku: UpdateJukuData = {
      id: this.data.juku.id,
      jukuName,
      jukuSubName,
      jukuCode,
      prefectureId
    };
    Log.debug(this.LOG_SOURCE, 'Update Juku', juku);
    this.store.dispatch(updateJuku({ juku }));

    this.store
      .select(getUpdateJukuResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeUpdateJukuState());
        Log.debug(this.LOG_SOURCE, `edit juku result: `, result);
        if (result.success) {
          this.dialogRef.close(true);
          Log.debug(this.LOG_SOURCE, 'result.updatedJuku', result.updatedJuku);
          const message = `${result.updatedJuku.jukuName} の変更が完了しました`;
          this.store.dispatch(
            dispatchInfoMessage({
              message
            })
          );
          Log.debug(this.LOG_SOURCE, `塾・予備校変更完了: `, result.updatedJuku);
          return;
        }

        Log.warn(this.LOG_SOURCE, `update user error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
        this.enableForms();
        // TODO: メッセージ内容を要検討
        this.errorMessage = result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました';
      });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  // components internal methods ---------------------------------------------------------------
  onChangeOrganization() {
    this.jukuId = null;
    this.jukuForm.patchValue({ juku: null });
  }

  // set ups or private method ---------------------------------------------------------------

  private setUpForms() {
    this.jukuForm = new UntypedFormGroup({
      jukuName: new UntypedFormControl('', [Validators.required]),
      jukuSubName: new UntypedFormControl('', []),
      jukuCode: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
        Validators.pattern(/^-?(0|[1-9]\d*)?$/)
      ]),
      prefectureId: new UntypedFormControl('', [Validators.required])
    });
    this.jukuForm.get('jukuCode').disable();
  }

  private setUpValues() {
    this.jukuForm.patchValue({
      jukuName: this.data.juku.jukuName,
      jukuSubName: this.data.juku.jukuSubName,
      jukuCode: this.data.juku.jukuCode.slice(2),
      prefectureId: this.data.juku.prefectureId
    });
  }

  private disableForms() {
    Object.keys(this.jukuForm.controls).forEach(ctrlName => {
      this.jukuForm.get(ctrlName).disable();
    });
    this.jukuEditing = true;
  }

  private enableForms() {
    Object.keys(this.jukuForm.controls).forEach(ctrlName => {
      this.jukuForm.get(ctrlName).enable();
    });
    this.jukuEditing = false;
    this.jukuForm.get('jukuCode').disable();
  }
}
