import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, share, startWith, take } from 'rxjs/operators';
import { setTitle } from 'src/app/actions/core.actions';
import { findProblemImages } from 'src/app/actions/try-search.action';
import { ImageType } from 'src/app/models/try-search';
import { RootState } from 'src/app/reducers';
import { STATIC_DATA_CACHE_DAYS, SUBJECT_NAMES } from 'src/app/resources/config';
import { getKakomonImage } from 'src/app/selectors/try-search.selector';
import { ProblemImageUtil } from 'src/app/utils/problem-image-util';
import { environment } from 'src/environments/environment';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { getFetchedDate } from '../../../../selectors/static-data.selectors';
import { Log } from '../../../../utils/log';
import { findStaticData, initializeStaticDataState } from '../../../../actions/static-data.actions';
import { Dates } from '../../../../utils/dates';
import { getUniversities } from '../../../../selectors/static-data.selectors';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';

@Component({
  selector: 'app-try-kakomon-image',
  templateUrl: './kakomon-image.component.html',
  styleUrls: ['./kakomon-image.component.scss']
})
export class TryKakomonImageComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private title = '';

  loaded$: Observable<boolean>;
  paramType$: Observable<ImageType>;
  questionImages$: Observable<string[]>;
  answerImages$: Observable<string[]>;
  isError$: Observable<boolean>;

  constructor(private store: Store<RootState>, private route: ActivatedRoute, @Inject(WINDOW_OBJECT) private window: Window) {}

  ngOnInit() {
    this.setUp();

    const params$ = this.route.queryParams.pipe(filter(it => it != null));
    const universities$ = this.store.select(getUniversities).pipe(filter(it => it != null));
    combineLatest([universities$, params$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([universities, params]) => {
        const problemId = params.paperId + params.daimon_Id;
        this.store.dispatch(findProblemImages({ problemId }));

        const universityId = params.paperId.toString().substring(2, 6);
        const university = universities.find(item => item.id === `D${universityId}`);
        const subject = SUBJECT_NAMES[params.paperId.toString().substring(6, 8)];
        const imageType = params.type === 'a' ? '解答' : '問題';

        this.title = `${university.name} ${subject} ${imageType}`;
        this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
        setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
      });
  }

  ngOnDestroy() {}

  clickImage(src: string) {
    this.window.parent.postMessage(
      {
        type: 'click',
        target: this.getLambdaImagePath(src)
      },
      '*'
    );
  }

  private setUp() {
    this.findStaticDataIfNeeded();

    this.loaded$ = this.store.select(getKakomonImage).pipe(
      map(it => it != null),
      startWith(false)
    );

    this.paramType$ = this.route.queryParams.pipe(
      filter(it => it != null),
      take(1),
      map(params => (params.type === 'a' ? 'a' : 'q') as ImageType)
    );

    this.questionImages$ = this.store.select(getKakomonImage).pipe(
      filter(it => it != null),
      take(1),
      map(kakomonImages => kakomonImages.questionImages.map(image => this.getImagePath(image)))
    );

    this.answerImages$ = this.store.select(getKakomonImage).pipe(
      filter(it => it != null),
      take(1),
      map(kakomonImages => kakomonImages.answerImages.map(image => this.getImagePath(image)))
    );

    this.isError$ = combineLatest([this.paramType$, this.questionImages$, this.answerImages$]).pipe(
      filter(it => it != null),
      take(1),
      map(
        ([paramType, questionImages, answerImages]) =>
          (paramType === 'q' && questionImages.length === 0) || (paramType === 'a' && answerImages.length === 0)
      )
    );

    this.isError$.subscribe(isError => {
      if (isError) {
        this.window.parent.postMessage(
          {
            type: 'invalid_page'
          },
          '*'
        );
      }
    });

    const kakomonImages$ = this.store.select(getKakomonImage).pipe(
      filter(it => it != null),
      take(1)
    );
    combineLatest([this.paramType$, kakomonImages$])
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(([paramType, kakomonImages]) => {
        const targetImages = paramType === 'q' ? kakomonImages.questionImages : kakomonImages.answerImages;
        const images = targetImages.map(image => this.getLambdaImagePath(image));
        if (images.length > 0) {
          this.window.parent.postMessage(
            {
              type: 'init',
              images
            },
            '*'
          );
        }
      });
  }

  private getImagePath(image: string): string {
    const path = ProblemImageUtil.getPath(image, false);
    return `${environment.cloudfrontHost}${path}`;
  }

  private getLambdaImagePath(image: string): string {
    const splitedPath = image.split('/');
    const fileName = splitedPath[splitedPath.length - 1];
    const path = ProblemImageUtil.getPath(fileName, false);
    return `${environment.lambdaHost}?key=${path}`;
  }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }
}
