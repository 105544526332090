import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { FirebaseCallableFunctionError } from 'src/app/errors/firebase-callable-function-error';
import * as InformationActions from 'src/app/actions/information.actions';
import { InformationService } from 'src/app/services/information.service';

@Injectable()
export class InformationEffects {
  private LOG_SOURCE = this.constructor.name;

  getInformations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InformationActions.getInformations),
      switchMap(() =>
        this.informationService.getInformations().pipe(
          map(response => InformationActions.getInformationsSuccess({ response })),
          catchError(e => of(InformationActions.getInformationsFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private informationService: InformationService) {}
}
