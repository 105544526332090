<app-wrapper size="lg" @enter *ngIf="information$ | async as information; else loadingProgress">
  <app-content-row>
    <app-card-content [showSeparator]="false">
      <app-wrapper size="md">
        <app-content-row>
          <article class="information-article">
            <app-content-row>
              <header class="information-article-head">
                <div class="information-article-head-meta flex">
                  <time datetime="2021-08-13" class="text-base">{{ information.date }}</time>
                  <span class="information-article-head-meta-tag flex pl-4 gap-2">
                    <span class="bg-primary text-white font-bold" *ngIf="information.importantFlag">重要</span>
                    <span class="bg-gray-50 text-black font-bold">{{ information.categoryName }}</span>
                  </span>
                </div>
                <h1 class="information-article-head-title">{{ information.title }}</h1>
              </header>
            </app-content-row>
            <app-content-row>
              <div class="information-article-body">
                <app-document-theme-default>
                  <div [innerHTML]="sanitize(information.body)"></div>
                </app-document-theme-default>
              </div>
            </app-content-row>
          </article>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
  <app-content-row>
    <div class="text-center">
      <button mat-flat-button class="bg-white font-normal h-12 w-36" (click)="backToList()">
        <mat-icon class="">format_list_bulleted</mat-icon>
        一覧へ戻る
      </button>
    </div>
  </app-content-row>
</app-wrapper>

<ng-template #loadingProgress>
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>
