<div class="breadcrumbs">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index; let first = first; let last = last">
    <button
      class="label"
      tabindex="0"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleRadius]="60"
      (click)="breadcrumbsClick.emit(breadcrumb.key)"
      *ngIf="!last"
    >
      <mat-icon class="home" *ngIf="breadcrumb.icon">{{ breadcrumb.icon }}</mat-icon>
      <ng-container *ngIf="breadcrumb.label">{{ breadcrumb.label }}</ng-container>
    </button>

    <span class="label" *ngIf="last">{{ breadcrumb.label }}</span>

    <mat-icon class="icon" *ngIf="!last">chevron_right</mat-icon>
  </ng-container>
</div>
