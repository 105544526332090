import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

import { Collection } from '../resources/app-firebase';
import { MaintenanceMode } from '../models/maintenance-mode';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  constructor(private afs: AngularFirestore) {}

  watchMaintenanceMode(): Observable<MaintenanceMode> {
    return this.afs
      .collection<MaintenanceMode>(Collection.MAINTENANCE_MODE)
      .doc('maintenanceMode')
      .valueChanges();
  }
}
