import { createAction, props } from '@ngrx/store';
import { UniversitySearchCondition, UniversityCondition, PrefectureCondition } from '../models/university-search-condition';
import { UniversitySearchCountResponse } from '../models/university-search-count-response';
import { FindUniversityPapersResponse } from '../models/find-university-papers-response';

// 検索条件に合う大学の校数を取得する ------------------------------------------------------------
export const findUniversityCountByUniversityIds = createAction(
  '[University Search] Find University Count By University Ids',
  props<{ condition: UniversitySearchCondition<UniversityCondition>; logging: boolean }>()
);
export const findUniversityCountByUniversityIdsSuccess = createAction(
  '[University Search] Find University Count By University Ids Success',
  props<{ result: UniversitySearchCountResponse }>()
);
export const findUniversityCountByUniversityIdsFailure = createAction(
  '[University Search] Find University Count By University Ids Failure'
);

// 検索条件に合う大学の校数を取得する（都道府県別） ------------------------------------------------------------
export const findUniversityCountByPrefectureIds = createAction(
  '[University Search] Find University Count By Prefecture Ids',
  props<{ condition: UniversitySearchCondition<PrefectureCondition>; logging: boolean }>()
);
export const findUniversityCountByPrefectureIdsSuccess = createAction(
  '[University Search] Find University Count By Prefecture Ids Success',
  props<{ result: UniversitySearchCountResponse }>()
);
export const findUniversityCountByPrefectureIdsFailure = createAction(
  '[University Search] Find University Count By Prefecture Ids Failure'
);

// 検索条件に合う大学の詳細を取得する ------------------------------------------------------------
export const findUniversityPapersByUniversityIds = createAction(
  '[University Search] Find University Papers By University Ids',
  props<{ condition: UniversitySearchCondition<UniversityCondition>; page: number }>()
);
export const findUniversityPapersByUniversityIdsSuccess = createAction(
  '[University Search] Find University Papers By University Ids Success',
  props<{ universities: FindUniversityPapersResponse[] }>()
);
export const findUniversityPapersByUniversityIdsFailure = createAction(
  '[University Search] Find University Papers By University Ids Failure'
);

// 検索条件に合う大学の詳細を取得する（都道府県別）  ------------------------------------------------------------
export const findUniversityPapersByPrefectureIds = createAction(
  '[University Search] Find University Papers By Prefecture Ids',
  props<{ condition: UniversitySearchCondition<PrefectureCondition>; page: number }>()
);
export const findUniversityPapersByPrefectureIdsSuccess = createAction(
  '[University Search] Find University Papers By Prefecture Ids Success',
  props<{ universities: FindUniversityPapersResponse[] }>()
);
export const findUniversityPapersByPrefectureIdsFailure = createAction(
  '[University Search] Find University Papers By Prefecture Ids Failure'
);

// 検索結果の校数をリセットする場合 ----------------------------------------------------------------------------------
export const initializeUniversityCountState = createAction('[University Search] Initialize University Count State');

// 検索結果の大学詳細をリセットする場合 ----------------------------------------------------------------------------------
export const initializeUniversityPapersState = createAction('[University Search] Initialize University Paper State');
