<div class="button-toggle-saved-problem" [attr.data-showSpinner]="showSpinner">
  <div class="button-toggle-saved-problem-inner">
    <div class="progress-24">
      <mat-slide-toggle [color]="'primary'" [(ngModel)]="isAnswerd" (click)="toggle()" [disabled]="disabled"
        ><span>解答済み</span></mat-slide-toggle
      >
      <mat-spinner [diameter]="24" *ngIf="showSpinner"></mat-spinner>
    </div>
  </div>
</div>
