import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { dispatchAppError } from 'src/app/actions/core.actions';
import { AppError } from 'src/app/errors/app-error';
import { FirestoreError } from 'src/app/errors/firestore-error';

import * as CommonIdPlaylistBookmarkActions from '../../actions/common-id/common-id-playlist-bookmark.actions';
import { FirebaseCallableFunctionError } from '../../errors/firebase-callable-function-error';
import { CommonIdPlaylistBookmarkService } from '../../services/common-id/common-id-playlist-bookmark.service';

@Injectable()
export class CommonIdPlaylistBookmarkEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdAddPlaylistBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistBookmarkActions.commonIdAddPlaylistBookmark),
      switchMap(action =>
        this.commonIdPlaylistBookmarkService.commonIdAddPlaylistBookmark(action.userId, action.playlistId).pipe(
          map(response => CommonIdPlaylistBookmarkActions.commonIdAddPlaylistBookmarkSuccess({ response })),
          catchError(e =>
            of(CommonIdPlaylistBookmarkActions.commonIdAddPlaylistBookmarkFailure({ error: FirebaseCallableFunctionError.from(e) }))
          )
        )
      )
    )
  );

  commonIdDeletePlaylistBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistBookmarkActions.commonIdDeletePlaylistBookmark),
      switchMap(action =>
        this.commonIdPlaylistBookmarkService.commonIdDeletePlaylistBookmark(action.userId, action.playlistId).pipe(
          map(response => CommonIdPlaylistBookmarkActions.commonIdDeletePlaylistBookmarkSuccess({ response })),
          catchError(e =>
            of(CommonIdPlaylistBookmarkActions.commonIdDeletePlaylistBookmarkFailure({ error: FirebaseCallableFunctionError.from(e) }))
          )
        )
      )
    )
  );

  commonIdFindPlaylistBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistBookmarkActions.commonIdFindPlaylistBookmark),
      switchMap(action =>
        this.commonIdPlaylistBookmarkService.commonIdFindPlaylistBookmark(action.userId).pipe(
          map(playlistBookmark => CommonIdPlaylistBookmarkActions.commonIdFindPlaylistBookmarkSuccess({ playlistBookmark })),
          catchError(e => {
            const error = e instanceof AppError ? e : FirestoreError.from(e);
            return of(
              CommonIdPlaylistBookmarkActions.commonIdFindPlaylistBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error })
            );
          })
        )
      )
    )
  );

  commonIdFindPlaylistProblemIdsByPlaylistBookmarkIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistBookmarkActions.commonIdFindPlaylistProblemIdsByPlaylistBookmarkIds),
      switchMap(() =>
        this.commonIdPlaylistBookmarkService.commonIdFindPlaylistProblemIdsByPlaylistBookmarkIds().pipe(
          map(response => CommonIdPlaylistBookmarkActions.commonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsSuccess({ response })),
          catchError(e => {
            const error = e instanceof AppError ? e : FirestoreError.from(e);
            return of(
              CommonIdPlaylistBookmarkActions.commonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsFailure({ error }),
              dispatchAppError({ source: this.LOG_SOURCE, error })
            );
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdPlaylistBookmarkService: CommonIdPlaylistBookmarkService) {}
}
