import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import * as SearchActions from '../actions/search.actions';
import { ProblemService } from '../services/problem.service';
import { dispatchAppError } from '../actions/core.actions';
import { FirebaseCallableFunctionError } from '../errors/firebase-callable-function-error';

@Injectable()
export class SearchEffects {
  private LOG_SOURCE = this.constructor.name;

  findEnglishProblemCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findEnglishProblemCount),
      switchMap(action =>
        this.problemService.findProblemCount(action.condition, action.logging).pipe(
          map(result => SearchActions.findEnglishProblemCountSuccess({ result })),
          catchError(e =>
            of(
              SearchActions.findEnglishProblemCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findMathProblemCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findMathProblemCount),
      switchMap(action =>
        this.problemService.findProblemCount(action.condition, action.logging).pipe(
          map(result => SearchActions.findMathProblemCountSuccess({ result })),
          catchError(e =>
            of(
              SearchActions.findMathProblemCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findNationalLanguageProblemCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findNationalLanguageProblemCount),
      switchMap(action =>
        this.problemService.findProblemCount(action.condition, action.logging).pipe(
          map(result => SearchActions.findNationalLanguageProblemCountSuccess({ result })),
          catchError(e =>
            of(
              SearchActions.findNationalLanguageProblemCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findPhysicsProblemCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findPhysicsProblemCount),
      switchMap(action =>
        this.problemService.findProblemCount(action.condition, action.logging).pipe(
          map(result => SearchActions.findPhysicsProblemCountSuccess({ result })),
          catchError(e =>
            of(
              SearchActions.findPhysicsProblemCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findChemistryProblemCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findChemistryProblemCount),
      switchMap(action =>
        this.problemService.findProblemCount(action.condition, action.logging).pipe(
          map(result => SearchActions.findChemistryProblemCountSuccess({ result })),
          catchError(e =>
            of(
              SearchActions.findChemistryProblemCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findBiologyProblemCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findBiologyProblemCount),
      switchMap(action =>
        this.problemService.findProblemCount(action.condition, action.logging).pipe(
          map(result => SearchActions.findBiologyProblemCountSuccess({ result })),
          catchError(e =>
            of(
              SearchActions.findBiologyProblemCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findJapaneseHistoryProblemCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findJapaneseHistoryProblemCount),
      switchMap(action =>
        this.problemService.findProblemCount(action.condition, action.logging).pipe(
          map(result => SearchActions.findJapaneseHistoryProblemCountSuccess({ result })),
          catchError(e =>
            of(
              SearchActions.findJapaneseHistoryProblemCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findWorldHistoryProblemCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findWorldHistoryProblemCount),
      switchMap(action =>
        this.problemService.findProblemCount(action.condition, action.logging).pipe(
          map(result => SearchActions.findWorldHistoryProblemCountSuccess({ result })),
          catchError(e =>
            of(
              SearchActions.findWorldHistoryProblemCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findGeographyProblemCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findGeographyProblemCount),
      switchMap(action =>
        this.problemService.findProblemCount(action.condition, action.logging).pipe(
          map(result => SearchActions.findGeographyProblemCountSuccess({ result })),
          catchError(e =>
            of(
              SearchActions.findGeographyProblemCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findPoliticalEconomyProblemCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findPoliticalEconomyProblemCount),
      switchMap(action =>
        this.problemService.findProblemCount(action.condition, action.logging).pipe(
          map(result => SearchActions.findPoliticalEconomyProblemCountSuccess({ result })),
          catchError(e =>
            of(
              SearchActions.findPoliticalEconomyProblemCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findEnglishProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findEnglishProblems),
      switchMap(action =>
        this.problemService.findEnglishProblems(action.condition, 'search').pipe(
          map(problems => SearchActions.findEnglishProblemsSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findEnglishProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findMathProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findMathProblems),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'search').pipe(
          map(problems => SearchActions.findMathProblemsSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findMathProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findNationalLanguageProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findNationalLanguageProblems),
      switchMap(action =>
        this.problemService.findNationalLanguageProblems(action.condition, 'search').pipe(
          map(problems => SearchActions.findNationalLanguageProblemsSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findNationalLanguageProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findPhysicsProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findPhysicsProblems),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'search').pipe(
          map(problems => SearchActions.findPhysicsProblemsSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findPhysicsProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findChemistryProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findChemistryProblems),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'search').pipe(
          map(problems => SearchActions.findChemistryProblemsSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findChemistryProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findBiologyProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findBiologyProblems),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'search').pipe(
          map(problems => SearchActions.findBiologyProblemsSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findBiologyProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findJapaneseHistoryProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findJapaneseHistoryProblems),
      switchMap(action =>
        this.problemService.findHistoryProblems(action.condition, 'search').pipe(
          map(problems => SearchActions.findJapaneseHistoryProblemsSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findJapaneseHistoryProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findWorldHistoryProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findWorldHistoryProblems),
      switchMap(action =>
        this.problemService.findHistoryProblems(action.condition, 'search').pipe(
          map(problems => SearchActions.findWorldHistoryProblemsSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findWorldHistoryProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findGeographyProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findGeographyProblems),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'search').pipe(
          map(problems => SearchActions.findGeographyProblemsSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findGeographyProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findPoliticalEconomyProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findPoliticalEconomyProblems),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'search').pipe(
          map(problems => SearchActions.findPoliticalEconomyProblemsSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findPoliticalEconomyProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findEnglishProblemsForPrint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findEnglishProblemsForPrint),
      switchMap(action =>
        this.problemService.findEnglishProblems(action.condition, 'print').pipe(
          map(problems => SearchActions.findEnglishProblemsForPrintSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findEnglishProblemsForPrintFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findMathProblemsForPrint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findMathProblemsForPrint),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'print').pipe(
          map(problems => SearchActions.findMathProblemsForPrintSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findMathProblemsForPrintFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findNationalLanguageProblemsForPrint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findNationalLanguageProblemsForPrint),
      switchMap(action =>
        this.problemService.findNationalLanguageProblems(action.condition, 'print').pipe(
          map(problems => SearchActions.findNationalLanguageProblemsForPrintSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findNationalLanguageProblemsForPrintFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findPhysicsProblemsForPrint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findPhysicsProblemsForPrint),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'print').pipe(
          map(problems => SearchActions.findPhysicsProblemsForPrintSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findPhysicsProblemsForPrintFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findChemistryProblemsForPrint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findChemistryProblemsForPrint),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'print').pipe(
          map(problems => SearchActions.findChemistryProblemsForPrintSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findChemistryProblemsForPrintFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findBiologyProblemsForPrint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findBiologyProblemsForPrint),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'print').pipe(
          map(problems => SearchActions.findBiologyProblemsForPrintSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findBiologyProblemsForPrintFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findJapaneseHistoryProblemsForPrint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findJapaneseHistoryProblemsForPrint),
      switchMap(action =>
        this.problemService.findHistoryProblems(action.condition, 'print').pipe(
          map(problems => SearchActions.findJapaneseHistoryProblemsForPrintSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findJapaneseHistoryProblemsForPrintFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findWorldHistoryProblemsForPrint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findWorldHistoryProblemsForPrint),
      switchMap(action =>
        this.problemService.findHistoryProblems(action.condition, 'print').pipe(
          map(problems => SearchActions.findWorldHistoryProblemsForPrintSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findWorldHistoryProblemsForPrintFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findGeographyProblemsForPrint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findGeographyProblemsForPrint),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'print').pipe(
          map(problems => SearchActions.findGeographyProblemsForPrintSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findGeographyProblemsForPrintFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findPoliticalEconomyProblemsForPrint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findPoliticalEconomyProblemsForPrint),
      switchMap(action =>
        this.problemService.findScienceProblems(action.condition, 'print').pipe(
          map(problems => SearchActions.findPoliticalEconomyProblemsForPrintSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findPoliticalEconomyProblemsForPrintFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findScienceProblemIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findScienceProblemIds),
      switchMap(action =>
        this.problemService.findScienceProblemIds(action.condition).pipe(
          map(ids => SearchActions.findScienceProblemIdsSuccess({ ids })),
          catchError(e =>
            of(
              SearchActions.findScienceProblemIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findEnglishProblemIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findEnglishProblemIds),
      switchMap(action =>
        this.problemService.findEnglishProblemIds(action.condition).pipe(
          map(ids => SearchActions.findEnglishProblemIdsSuccess({ ids })),
          catchError(e =>
            of(
              SearchActions.findEnglishProblemIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findNationalLanguageProblemIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findNationalLanguageProblemIds),
      switchMap(action =>
        this.problemService.findNationalLanguageProblemIds(action.condition).pipe(
          map(ids => SearchActions.findNationalLanguageProblemIdsSuccess({ ids })),
          catchError(e =>
            of(
              SearchActions.findNationalLanguageProblemIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findHistoryProblemIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findHistoryProblemIds),
      switchMap(action =>
        this.problemService.findHistoryProblemIds(action.condition).pipe(
          map(ids => SearchActions.findHistoryProblemIdsSuccess({ ids })),
          catchError(e =>
            of(
              SearchActions.findHistoryProblemIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findScienceProblem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findScienceProblem),
      switchMap(action =>
        this.problemService.findScienceProblem(action.subjectId, action.problemId).pipe(
          map(problem => SearchActions.findScienceProblemSuccess({ problem })),
          catchError(e =>
            of(
              SearchActions.findScienceProblemFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findScienceProblemByPaperId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findScienceProblemByPaperId),
      switchMap(action =>
        this.problemService.findScienceProblemByPaperId(action.subjectId, action.paperId).pipe(
          map(problem => SearchActions.findScienceProblemSuccess({ problem })),
          catchError(e =>
            of(
              SearchActions.findScienceProblemFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findEnglishProblem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findEnglishProblem),
      switchMap(action =>
        this.problemService.findEnglishProblem(action.subjectId, action.problemId).pipe(
          map(problem => SearchActions.findEnglishProblemSuccess({ problem })),
          catchError(e =>
            of(
              SearchActions.findEnglishProblemFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findEnglishProblemPaperId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findEnglishProblemByPaperId),
      switchMap(action =>
        this.problemService.findEnglishProblemByPaperId(action.subjectId, action.paperId).pipe(
          map(problem => SearchActions.findEnglishProblemSuccess({ problem })),
          catchError(e =>
            of(
              SearchActions.findEnglishProblemFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findNationalLanguageProblem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findNationalLanguageProblem),
      switchMap(action =>
        this.problemService.findNationalLanguageProblem(action.subjectId, action.problemId).pipe(
          map(problems => {
            if (problems !== undefined && problems !== null && problems.length > 0) {
              return SearchActions.findNationalLanguageProblemSuccess({ problem: problems.pop() });
            } else {
              return SearchActions.findNationalLanguageProblemSuccess({ problem: null });
            }
          }),
          catchError(e =>
            of(
              SearchActions.findNationalLanguageProblemFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findNationalLanguageProblemByPaperId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findNationalLanguageProblemByPaperId),
      switchMap(action =>
        this.problemService.findNationalLanguageProblemByPaperId(action.subjectId, action.paperId).pipe(
          map(problem => SearchActions.findNationalLanguageProblemSuccess({ problem })),
          catchError(e =>
            of(
              SearchActions.findNationalLanguageProblemFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findNationalLanguageProblemsById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findNationalLanguageProblemsById),
      switchMap(action =>
        this.problemService.findNationalLanguageProblemsById(action.subjectId, action.problemId).pipe(
          map(problems => SearchActions.findNationalLanguageProblemsByIdSuccess({ problems })),
          catchError(e =>
            of(
              SearchActions.findNationalLanguageProblemsByIdFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findHistoryProblem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findHistoryProblem),
      switchMap(action =>
        this.problemService.findHistoryProblem(action.subjectId, action.problemId).pipe(
          map(problem => SearchActions.findHistoryProblemSuccess({ problem })),
          catchError(e =>
            of(
              SearchActions.findHistoryProblemFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findHistoryProblemByPaperId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.findHistoryProblemByPaperId),
      switchMap(action =>
        this.problemService.findHistoryProblemByPaperId(action.subjectId, action.paperId).pipe(
          map(problem => SearchActions.findHistoryProblemSuccess({ problem })),
          catchError(e =>
            of(
              SearchActions.findHistoryProblemFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private problemService: ProblemService) {}
}
