import { createAction, props } from '@ngrx/store';
import { SearchLogFileMetadata } from '../models/search-log-file-metadata';
import { AppEvent } from '../models/event-log';

// findSearchLogFileMetadataList -----------------------------------------------------------------
export const findSearchLogFileMetadataList = createAction('[EventLog] Find Search Log File Metadata List');
export const findSearchLogFileMetadataListSuccess = createAction(
  '[EventLog] Find Search Log File Metadata List Success',
  props<{ metadataList: SearchLogFileMetadata[] }>()
);
export const findSearchLogFileMetadataListFailure = createAction('[EventLog] Find Search Log File Metadata List Failure');
export const initializeSearchLogFileMetadataListState = createAction('[EventLog] Initialize Search Log File Metadata List State');

// resolveSearchLogFileObjectUrl -----------------------------------------------------------------
export const resolveSearchLogFileObjectUrl = createAction(
  '[EventLog] Resolve Search Log File Object Url',
  props<{ filePath: string; isCRLF: boolean }>()
);
export const resolveSearchLogFileObjectUrlSuccess = createAction(
  '[EventLog] Resolve Search Log File Object Url Success',
  props<{ filePath: string; objectUrl: string }>()
);
export const resolveSearchLogFileObjectUrlFailure = createAction(
  '[EventLog] Resolve Search Log File Object Url Failure',
  props<{ filePath: string }>()
);
export const initializeSearchLogFileObjectUrl = createAction(
  '[EventLog] Initialize Search Log File Object Url',
  props<{ filePath: string }>()
);
export const initializeSearchLogFileObjectUrlState = createAction('[EventLog] Initialize Search Log File Object Url State');

// reportAppEvent -----------------------------------------------------------------
export const reportAppEvent = createAction('[EventLog] Report App Event', props<{ event: AppEvent }>());
export const reportAppEventSuccess = createAction('[EventLog] Report App Event Success');
export const reportAppEventFailure = createAction('[EventLog] Report App Event Failure');
export const ignoreAppEvent = createAction('[EventLog] Ignore App Event');

// reportAppLaunchEvent -----------------------------------------------------------------
export const reportAppLaunchEvent = createAction('[EventLog] Report App Launch Event');
export const reportAppLaunchEventSuccess = createAction('[EventLog] Report App Launch Event Success');
export const reportAppLaunchEventFailure = createAction('[EventLog] Report App Launch Event Failure');
