<div class="root-container">
  <!-- 大問分野 -->
  <div class="item-container ignore-top-margin">
    <div class="mat-caption">大問分野</div>
    <div class="checkbox-list">
      <div *ngFor="let field of fields">
        <mat-checkbox color="primary" [(ngModel)]="field.checked" (change)="onChangeValues()">{{ field.name }}</mat-checkbox>
      </div>
    </div>
  </div>

  <!-- 小問形式 -->
  <div class="item-container">
    <div class="mat-caption">小問形式</div>
    <div class="checkbox-list">
      <div *ngFor="let subType of subTypes">
        <mat-checkbox color="primary" [(ngModel)]="subType.checked" (change)="onChangeValues()">{{ subType.name }}</mat-checkbox>
      </div>
    </div>
  </div>

  <div class="mat-caption">長文条件設定（下記を選択すると長文問題のみ検索されます）</div>
  <div class="long-sentence-container">
    <!-- 長文-英文形態 -->
    <div class="item-container ignore-top-margin">
      <div class="mat-caption">長文-英文形態</div>
      <div class="checkbox-list">
        <div *ngFor="let longSentenceType of longSentenceTypes">
          <mat-checkbox color="primary" [(ngModel)]="longSentenceType.checked" (change)="onChangeValues()">{{
            longSentenceType.name
          }}</mat-checkbox>
        </div>
      </div>
    </div>

    <!-- 長文-英単語数 -->
    <div class="word-count-container">
      <mat-form-field appearance="outline">
        <mat-label>長文-英単語数（最小）</mat-label>
        <mat-select (selectionChange)="onChangeMinWordCount()" [formControl]="minWordCountFormControl">
          <mat-option *ngFor="let lowWordCount of lowWordCounts" [value]="lowWordCount">{{ lowWordCount.displayName }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="mat-title">〜</div>

      <mat-form-field appearance="outline">
        <mat-label>長文-英単語数（最大）</mat-label>
        <mat-select (selectionChange)="onChangeMaxWordCount()" [formControl]="maxWordCountFormControl">
          <mat-option *ngFor="let highWordCount of highWordCounts" [value]="highWordCount">{{ highWordCount.displayName }}</mat-option>
        </mat-select>
        <mat-error *ngIf="maxWordCountFormControl.hasError('invalid-value')">最小より大きい値を選択してください</mat-error>
      </mat-form-field>
    </div>

    <!-- 長文-キーワード -->
    <div>
      <mat-form-field class="keyword-form-field" appearance="outline">
        <mat-label>長文-トピック別長文問題要約キーワード検索（複数可）</mat-label>
        <input matInput [formControl]="keywordFormControl" />
      </mat-form-field>
    </div>
  </div>
</div>
