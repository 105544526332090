<app-wrapper size="xl" @enter *ngIf="schoolsData$ | async; else loadingProgress" isFitWithSmallScreen="true">
  <app-content-row>
    <app-breadcrumbs
      (breadcrumbsClick)="breadcrumbsClickHandler($event)"
      [breadcrumbs]="[
        { key: 'admin', label: 'Admin機能' },
        { key: 'admin/schools', label: 'アカウント管理 - 学校' }
      ]"
    ></app-breadcrumbs>
  </app-content-row>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">group</mat-icon>
        <strong class="font-bold">アカウント管理</strong>
      </app-card-content-head>
      <app-card-content-nav
        (menuClick)="menuClickHandler($event)"
        [menus]="[
          {
            key: 'admin/accounts',
            icon: 'group',
            label: 'ユーザー',
            isActive: false
          },
          {
            key: 'admin/schools',
            icon: 'school',
            label: '学校',
            isActive: true
          },
          {
            key: 'admin/jukus',
            icon: 'domain',
            label: '塾・予備校',
            isActive: false
          }
        ]"
      ></app-card-content-nav>

      <app-content-row>
        <app-wrapper size="fluid">
          <app-content-no-data *ngIf="(schoolsData$ | async)?.length === 0">
            <span>ユーザーが存在する学校がありません</span>
          </app-content-no-data>
        </app-wrapper>
        <app-wrapper size="fluid" isFitWithSmallScreen="true">
          <app-table-overflow>
            <table mat-table [dataSource]="schoolsData$ | async" class="w-full">
              <ng-container matColumnDef="schoolId">
                <th mat-header-cell *matHeaderCellDef style="min-width: 60px;">学校ID</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  {{ element.schoolId }}
                </td>
              </ng-container>
              <ng-container matColumnDef="schoolName">
                <th mat-header-cell *matHeaderCellDef style="min-width: 200px;">学校名</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  <button class="text-primary underline hover:no-underline" (click)="plans(element.schoolId)">
                    {{ element.schoolName }}
                  </button>
                </td>
              </ng-container>
              <ng-container matColumnDef="planStatus">
                <th mat-header-cell *matHeaderCellDef style="min-width: 100px;">契約状況</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  <div class="text-primary" *ngIf="element.planStatus === planActiveStatus">
                    <app-label-with-icon iconName="check_circle">契約中</app-label-with-icon>
                  </div>
                  <div class="text-black" *ngIf="element.planStatus === planUnassignedStatus">契約なし</div>
                  <div class="text-gray-300" *ngIf="element.planStatus === planEndedStatus">契約終了</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>管理・編集</th>
                <td mat-cell *matCellDef="let element">
                  <button mat-flat-button color="primary" (click)="plans(element.schoolId)">管理・編集</button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </app-table-overflow>
        </app-wrapper>
      </app-content-row>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<ng-template #loadingProgress>
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>
