<app-dialog-basic dialogTitle="アカウント削除" [isLoading]="disabled">
  <div class="pb-4">
    <div>
      <strong>{{ data.email }}</strong> のアカウントを削除します。
    </div>
    <div *ngIf="data.isSignedInUserAdmin">この操作は取り消せません。削除を実行しますか？</div>
    <div *ngIf="!data.isSignedInUserAdmin">
      アカウントを削除すると、会員情報はすべて削除され、復元できませんのでご注意ください。<br />本当に削除しますか？
    </div>
  </div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="disabled"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="deleteAccount()" [disabled]="disabled">削除</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
