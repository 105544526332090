import { Action, createReducer, on } from '@ngrx/store';
import * as MaintenanceActions from '../actions/maintenance.actions';

export const maintenanceFeatureKey = 'maintenance';

export interface State {
  watchedMaintenanceModeFlag: boolean;
}

export const initialState: State = {
  watchedMaintenanceModeFlag: null
};

const maintenanceReducer = createReducer(
  initialState,

  on(MaintenanceActions.watchMaintenanceModeSuccess, (state, { maintenanceMode }) => ({
    ...state,
    watchedMaintenanceModeFlag: maintenanceMode.flag
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return maintenanceReducer(state, action);
}
