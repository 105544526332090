<app-dialog-basic dialogTitle="継続訴求メールを有効化する" [isLoading]="disabled" *ngIf="data.isAlertDisabled === true">
  <div class="text-md">
    <p>
      有効にすると、契約終了の一定期間前に継続訴求メールが自動的に送信されます。
    </p>
    <br />
    <p>
      メール送信を再開しますか？<br />
      （必要に応じて、後から再度無効にすることもできます）
    </p>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="changeRenewPlanAlert()" [disabled]="disabled">有効にする</button>
  </app-dialog-basic-footer>
</app-dialog-basic>

<app-dialog-basic dialogTitle="継続訴求メールを無効化する" [isLoading]="disabled" *ngIf="!data.isAlertDisabled">
  <div class="text-md">
    <p>無効にすると、契約終了の一定期間前に送信予定の継続訴求メールが送信されなくなります。</p>
    <br />
    <p>
      メール送信を停止しますか？<br />
      （必要に応じて、後から再度有効にすることもできます）
    </p>
  </div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="disabled"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="changeRenewPlanAlert()" [disabled]="disabled">無効にする</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
