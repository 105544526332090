import { Component, ChangeDetectionStrategy, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, map, shareReplay } from 'rxjs/operators';

// Redux
import {
  initializeFindCommonIdUser,
  initializeWatchedCommonIdUsers,
  watchCommonIdUsers
} from 'src/app/actions/common-id/common-id-user.actions';
import { navigate, setBrowserTitle, setTitle, unsubscribeWatchedProps } from 'src/app/actions/core.actions';
import { getCommonIdWatchedUsers, getCommonIdWatchedUsersLoaded } from 'src/app/selectors/common-id/common-id-user.selectors';
import { RootState } from 'src/app/reducers';

// models
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import {
  AccountStatusFilterType,
  PurchaseStatusFilterType,
  PurchasePlanFilterType
} from 'src/app/models/common-id/common-id-account-filter';
import { AccountTableData } from 'src/app/models/account-table-data';

// utils
import { Log } from 'src/app/utils/log';
import { Dates } from 'src/app/utils/dates';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';

// config
import { UnsubscribeTarget, KEYBOARD_INPUT_DEBOUNCE_TIME } from 'src/app/resources/config';
import {
  ACCOUNT_FILTER_ACCOUNT_STATUS,
  ACCOUNT_FILTER_PURCHASE_STATUS,
  ACCOUNT_FILTER_PURCHASE_PLAN,
  COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR,
  COMMON_ID_DEFAULT_EMAIL_PLACEHOLDER,
  ACCOUNT_STATUS_ACTIVE,
  COMMON_ID_ACCOUNTS_PAGE_SIZE_OPTIONS,
  COMMON_ID_DEFAULT_USER_PLACEHOLDER,
  PURCHASE_PLAN_NAMES,
  PURCHASE_STATUS_NAMES
} from 'src/app/resources/common-id-config';

// others
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { enter } from 'src/app/resources/animations';

@Component({
  selector: 'app-common-id-account-list',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
  animations: [enter],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonIdAccountsComponent implements OnInit, OnDestroy {
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  pageIndex = 0;
  pageSize = COMMON_ID_ACCOUNTS_PAGE_SIZE_OPTIONS[0];
  pageSizeOptions = COMMON_ID_ACCOUNTS_PAGE_SIZE_OPTIONS;
  totalAccounts = 0;
  totalFilteredAccounts = 0;
  pageEvent: PageEvent;

  accounts: AccountTableData[];
  usersLoaded$: Observable<boolean>;
  watchedCommonIdUsers$: Observable<CommonIdUser[]>;
  commonIdUser$: Observable<CommonIdUser>;
  defaultDisplayedColumns: string[] = [
    'isActive',
    'commonId',
    'purchaseStatus',
    'lastPurchasedAt',
    'premiumEndAt',
    'purchasePlan',
    'latestAppLaunchedDate',
    'updatePurchaseStatus',
    'viewPurchaseHistory'
  ];
  displayedColumns: string[] = [...this.defaultDisplayedColumns];
  private LOG_SOURCE = this.constructor.name;
  private title = 'BtoCアカウント一覧';

  filterForm: UntypedFormGroup;
  accountStatusFilterTypes: AccountStatusFilterType[] = ACCOUNT_FILTER_ACCOUNT_STATUS;
  selectedAccountStatusId: number;
  purchaseStatusFilterTypes: PurchaseStatusFilterType[] = ACCOUNT_FILTER_PURCHASE_STATUS;
  selectedPurchaseStatusId: number;
  purchasePlanFilterTypes: PurchasePlanFilterType[] = ACCOUNT_FILTER_PURCHASE_PLAN;
  selectedPurchasePlanId: number;

  emailPlaceholder: string = COMMON_ID_DEFAULT_EMAIL_PLACEHOLDER;
  private emailSubscription: Subscription;
  enteredEmail: string;

  userIdPlaceholder: string = COMMON_ID_DEFAULT_USER_PLACEHOLDER;
  private userIdSubscription: Subscription;
  enteredUserId: string;

  constructor(
    private store: Store<RootState>,
    private changeDetectorRefs: ChangeDetectorRef,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUpForms();
    this.resetForms();
    this.setUpEmailChange();
    this.setUpUserIdChange();
    this.setUpCommonIdUsersData();
    this.usersLoaded$ = this.store.select(getCommonIdWatchedUsersLoaded);
  }

  ngOnDestroy() {
    this.store.dispatch(initializeWatchedCommonIdUsers());
    this.store.dispatch(initializeFindCommonIdUser());
    this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.WATCH_USERS }));
    if (this.emailSubscription) this.emailSubscription.unsubscribe();
    if (this.userIdSubscription) this.userIdSubscription.unsubscribe();
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'admin':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        break;
    }
  }

  accountStatusChange() {
    this.selectedAccountStatusId = this.filterForm.get('accountStatus').value;
    const selectedAccuntStatus = ACCOUNT_FILTER_ACCOUNT_STATUS.find(accountStatus => accountStatus.id === this.selectedAccountStatusId);
    Log.debug(this.LOG_SOURCE, `選択されたアカウント種別: ${selectedAccuntStatus.name}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  purchaseStatusChange() {
    this.selectedPurchaseStatusId = this.filterForm.get('purchaseStatus').value;
    const selectedStatus = ACCOUNT_FILTER_PURCHASE_STATUS.find(status => status.id === this.selectedPurchaseStatusId);
    Log.debug(this.LOG_SOURCE, `選択された課金ステータス: ${selectedStatus.name}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  purchasePlanChange() {
    this.selectedPurchasePlanId = this.filterForm.get('purchasePlan').value;
    const selectedPlan = ACCOUNT_FILTER_PURCHASE_PLAN.find(plan => plan.id === this.selectedPurchasePlanId);
    Log.debug(this.LOG_SOURCE, `選択された料金プラン: ${selectedPlan.name}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  resetFilter() {
    this.resetForms();
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  pageChangeEvent(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.pageEvent = event;
    this.totalFilteredAccounts = event.length;
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  toggleDisplayEmail(isDisplayEmail: boolean) {
    if (isDisplayEmail) {
      this.displayedColumns.splice(1, 0, 'email');
    } else {
      this.displayedColumns = [...this.defaultDisplayedColumns];
    }
  }

  private pageAccounts(filteredAccounts: CommonIdUser[], pageIndex: number, pageSize: number): CommonIdUser[] {
    this.totalFilteredAccounts = filteredAccounts.length;
    const startIndex = (pageIndex = 0 ? pageIndex : pageIndex * pageSize);
    const endIndex = startIndex + pageSize;

    // フィルタをかけた際は、最初のページに戻るようにする
    if (this.pageEvent && this.pageEvent.length !== this.totalFilteredAccounts) {
      this.pageIndex = 0;
      return filteredAccounts.slice(0, pageSize);
    } else {
      return filteredAccounts.slice(startIndex, endIndex);
    }
  }

  private filterAccounts(users: CommonIdUser[]): CommonIdUser[] {
    let filteredAccounts: CommonIdUser[] = users;
    this.totalAccounts = users.length;

    if (this.selectedAccountStatusId !== 0) {
      const selectedAccountStatus = ACCOUNT_FILTER_ACCOUNT_STATUS.find(accountStatus => accountStatus.id === this.selectedAccountStatusId);
      filteredAccounts = filteredAccounts.filter(
        user =>
          user.isActive === selectedAccountStatus.isActive ||
          (user.isActive === undefined && this.selectedAccountStatusId === ACCOUNT_STATUS_ACTIVE.ACTIVE)
      );
    }

    if (this.selectedPurchaseStatusId !== 0) {
      const selectedStatus = ACCOUNT_FILTER_PURCHASE_STATUS.find(status => status.id === this.selectedPurchaseStatusId);
      filteredAccounts = filteredAccounts.filter(user => user.purchaseStatus === selectedStatus.id);
    }

    if (this.selectedPurchasePlanId !== 0) {
      const selectedPlan = ACCOUNT_FILTER_PURCHASE_PLAN.find(plan => plan.id === this.selectedPurchasePlanId);
      filteredAccounts = filteredAccounts.filter(user => user.purchasePlanId === selectedPlan.id);
    }

    if (this.enteredEmail) {
      filteredAccounts = filteredAccounts.filter(account => account.email.includes(this.enteredEmail));
    }

    if (this.enteredUserId) {
      filteredAccounts = filteredAccounts.filter(account => account.id.includes(this.enteredUserId));
    }

    Log.debug(this.LOG_SOURCE, `絞り込み件数: ${filteredAccounts.length}`);
    return filteredAccounts;
  }

  private setUpAccountData(user: CommonIdUser): AccountTableData {
    const isActive = user.isActive === undefined ? true : user.isActive;
    const purchaseStatus = user.purchaseStatus
      ? PURCHASE_STATUS_NAMES.find(status => status.id === user.purchaseStatus).name
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;
    const purchasePlan = user.purchasePlanId
      ? PURCHASE_PLAN_NAMES.find(status => status.id === user.purchasePlanId).name
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;

    const lastPurchasedAt = user.lastPurchasedAt
      ? Dates.stdDateStringFromIso(user.lastPurchasedAt)
          .split(' ')
          .join('\n')
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;

    const premiumEndAt = user.premiumEndAt
      ? Dates.simple4YmdStringFromIso(user.premiumEndAt)
          .split(' ')
          .join('\n')
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;

    const latestAppLaunchedDate = user.latestAppLaunchedDate
      ? Dates.stdDateStringFromIso(user.latestAppLaunchedDate)
          .split(' ')
          .join('\n')
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;

    return {
      id: user.id,
      email: user.email,
      commonId: user.commonId,
      isActive,
      purchaseStatus,
      purchasePlan,
      lastPurchasedAt,
      premiumEndAt,
      latestAppLaunchedDate
    };
  }

  private setUpForms() {
    this.filterForm = new UntypedFormGroup({
      accountStatus: new UntypedFormControl(),
      purchaseStatus: new UntypedFormControl(),
      purchasePlan: new UntypedFormControl(),
      email: new UntypedFormControl(),
      userId: new UntypedFormControl()
    });
  }

  private resetForms() {
    this.filterForm.get('accountStatus').setValue(0);
    this.selectedAccountStatusId = 0;
    this.filterForm.get('purchaseStatus').setValue(0);
    this.selectedPurchaseStatusId = 0;
    this.filterForm.get('purchasePlan').setValue(0);
    this.selectedPurchasePlanId = 0;

    this.filterForm.get('email').setValue('');
    this.enteredEmail = '';
    this.filterForm.get('userId').setValue('');
    this.enteredUserId = '';
  }

  private setUpCommonIdUsersData() {
    this.store.dispatch(watchCommonIdUsers());
    this.watchedCommonIdUsers$ = this.store.select(getCommonIdWatchedUsers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  private setUpFilteredAndPagedAccountData(pageIndex: number, pageSize: number) {
    this.watchedCommonIdUsers$
      .pipe(
        filter(it => it != null),
        map(users => {
          const filteredAccounts = this.filterAccounts(users);
          return this.pageAccounts(filteredAccounts, pageIndex, pageSize).map<AccountTableData>(user => this.setUpAccountData(user));
        })
      )
      .subscribe(accounts => {
        this.accounts = accounts;
        // テーブルにデータの変更を反映する
        this.changeDetectorRefs.detectChanges();
      });
  }

  private setUpEmailChange() {
    this.emailSubscription = this.filterForm
      .get('email')
      .valueChanges.pipe(debounceTime(KEYBOARD_INPUT_DEBOUNCE_TIME))
      .subscribe(() => this.emailInputChange());
  }

  private emailInputChange() {
    this.enteredEmail = this.filterForm.get('email').value;
    Log.debug(this.LOG_SOURCE, `入力されたメールアドレス: ${this.enteredEmail}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }

  private setUpUserIdChange() {
    this.userIdSubscription = this.filterForm
      .get('userId')
      .valueChanges.pipe(debounceTime(KEYBOARD_INPUT_DEBOUNCE_TIME))
      .subscribe(() => this.userIdInputChange());
  }

  private userIdInputChange() {
    this.enteredUserId = this.filterForm.get('userId').value;
    Log.debug(this.LOG_SOURCE, `入力されたユーザーID: ${this.enteredUserId}`);
    this.setUpFilteredAndPagedAccountData(this.pageIndex, this.pageSize);
  }
}
