import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';

import * as CommonIdPlaylistActions from '../../actions/common-id/common-id-playlist.actions';
import { CommonIdPlaylistService } from '../../services/common-id/common-id-playlist.service';
import { FirebaseStorageError } from '../../errors/firebase-storage-error';
import { GeneralError } from '../../errors/general-error';
import { dispatchAppError } from '../../actions/core.actions';
import { FirestoreError } from '../../errors/firestore-error';

@Injectable()
export class CommonIdPlaylistEffects {
  private LOG_SOURCE = this.constructor.name;

  findPlaylists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findPlaylists),
      switchMap(() =>
        this.playlistService.findPlaylists().pipe(
          map(playlists => CommonIdPlaylistActions.findPlaylistsSuccess({ playlists })),
          catchError(e =>
            of(CommonIdPlaylistActions.findPlaylistsFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) }))
          )
        )
      )
    )
  );

  findEnglishPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findEnglishPlaylistProblems),
      switchMap(action =>
        this.playlistService.findEnglishPlaylistProblems(action.playlistId).pipe(
          map(englishPlaylistProblems => CommonIdPlaylistActions.findEnglishPlaylistProblemsSuccess({ englishPlaylistProblems })),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findEnglishPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findPlaylistProblemIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findPlaylistProblemIds),
      switchMap(action =>
        this.playlistService.findPlyaListProblemIds(action.subjectId, action.playlistId).pipe(
          map(playlistProblemIds => CommonIdPlaylistActions.findPlaylistProblemIdsSuccess({ playlistProblemIds })),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findPlaylistProblemIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findMathPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findMathPlaylistProblems),
      switchMap(action =>
        this.playlistService.findMathPlaylistProblems(action.playlistId).pipe(
          map(mathPlaylistProblems => CommonIdPlaylistActions.findMathPlaylistProblemsSuccess({ mathPlaylistProblems })),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findMathPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findNationalLanguagePlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findNationalLanguagePlaylistProblems),
      switchMap(action =>
        this.playlistService.findNationalLanguagePlaylistProblems(action.playlistId).pipe(
          map(nationalLanguagePlaylistProblems =>
            CommonIdPlaylistActions.findNationalLanguagePlaylistProblemsSuccess({ nationalLanguagePlaylistProblems })
          ),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findNationalLanguagePlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findPhysicsPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findPhysicsPlaylistProblems),
      switchMap(action =>
        this.playlistService.findPhysicsPlaylistProblems(action.playlistId).pipe(
          map(physicsPlaylistProblems => CommonIdPlaylistActions.findPhysicsPlaylistProblemsSuccess({ physicsPlaylistProblems })),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findPhysicsPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findChemistryPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findChemistryPlaylistProblems),
      switchMap(action =>
        this.playlistService.findChemistryPlaylistProblems(action.playlistId).pipe(
          map(chemistryPlaylistProblems => CommonIdPlaylistActions.findChemistryPlaylistProblemsSuccess({ chemistryPlaylistProblems })),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findChemistryPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findBiologyPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findBiologyPlaylistProblems),
      switchMap(action =>
        this.playlistService.findBiologyPlaylistProblems(action.playlistId).pipe(
          map(biologyPlaylistProblems => CommonIdPlaylistActions.findBiologyPlaylistProblemsSuccess({ biologyPlaylistProblems })),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findBiologyPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findJapaneseHistoryPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findJapaneseHistoryPlaylistProblems),
      switchMap(action =>
        this.playlistService.findJapaneseHistoryPlaylistProblems(action.playlistId).pipe(
          map(japaneseHistoryPlaylistProblems =>
            CommonIdPlaylistActions.findJapaneseHistoryPlaylistProblemsSuccess({ japaneseHistoryPlaylistProblems })
          ),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findJapaneseHistoryPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findWorldHistoryPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findWorldHistoryPlaylistProblems),
      switchMap(action =>
        this.playlistService.findWorldHistoryPlaylistProblems(action.playlistId).pipe(
          map(worldHistoryPlaylistProblems =>
            CommonIdPlaylistActions.findWorldHistoryPlaylistProblemsSuccess({ worldHistoryPlaylistProblems })
          ),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findWorldHistoryPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findGeographyPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findGeographyPlaylistProblems),
      switchMap(action =>
        this.playlistService.findGeographyPlaylistProblems(action.playlistId).pipe(
          map(geographyPlaylistProblems => CommonIdPlaylistActions.findGeographyPlaylistProblemsSuccess({ geographyPlaylistProblems })),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findGeographyPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findPoliticalEconomyPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.findPoliticalEconomyPlaylistProblems),
      switchMap(action =>
        this.playlistService.findPoliticalEconomyPlaylistProblems(action.playlistId).pipe(
          map(politicalEconomyPlaylistProblems =>
            CommonIdPlaylistActions.findPoliticalEconomyPlaylistProblemsSuccess({ politicalEconomyPlaylistProblems })
          ),
          catchError(e =>
            of(
              CommonIdPlaylistActions.findPoliticalEconomyPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  resolvePdfObjectUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPlaylistActions.resolvePdfObjectUrl),
      mergeMap(action =>
        this.playlistService.getPdfObjectUrl(action.pdfPath).pipe(
          map(objectUrl => CommonIdPlaylistActions.resolvePdfObjectUrlSuccess({ pdfPath: action.pdfPath, objectUrl })),
          catchError(e => {
            const error = e instanceof FirebaseStorageError ? e : GeneralError.unknown(e);
            return of(
              CommonIdPlaylistActions.resolvePdfObjectUrlFailure({ pdfPath: action.pdfPath }),
              dispatchAppError({ source: this.LOG_SOURCE, error })
            );
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private playlistService: CommonIdPlaylistService) {}
}
