import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-overflow',
  templateUrl: './table-overflow.component.html',
  styleUrls: ['./table-overflow.component.scss']
})
export class TableOverflowComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
