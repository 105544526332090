import { Injectable } from '@angular/core';

import { SearchCondition, SearchConditionTypeForAllSubjects } from 'src/app/models/search-condition';
import { EnglishSearchCondition } from 'src/app/models/english-search-condition';
import { NationalLanguageSearchCondition } from 'src/app/models/national-language-search-condition';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';
import { CommonSearchCondition } from 'src/app/models/common-search-condition';

import { SCIENCE_IDS, SortType, SubjectId } from 'src/app/resources/config';

@Injectable({
  providedIn: 'root'
})
export class CommonIdSearchByCategoriesRouterService {
  private scienceIds: string[] = SCIENCE_IDS;

  constructor() {}

  getConditionFromQueryParams(url: string): SearchConditionTypeForAllSubjects {
    const currentQueryParamsString = url.split('?')[1];
    return this.getSearchCondition(currentQueryParamsString);
  }

  getSortTypeFromQueryParams(url: string): string {
    const queryParamsString = url.split('?')[1];
    const queryParams = new URLSearchParams(queryParamsString);
    const sortType = queryParams.get('sortType');
    return this.getSortCondition(sortType);
  }

  private getSearchCondition(queryParamsString: string): SearchConditionTypeForAllSubjects {
    const queryParams = new URLSearchParams(queryParamsString);
    // 教科IDと分野
    const subjectId = queryParams.get('subjectId');
    const subjectIds = queryParams.getAll('subjectIds');
    const subjectAndFieldIds = queryParams.getAll('subjectAndFieldIds');
    const categories = queryParams.getAll('categories');

    // 教科をまたいで共通の検索条件
    const commonSearchCondition = this.getCommonSearchCondition(queryParams);

    if (this.scienceIds.includes(subjectId)) {
      return this.getScienceCondition(subjectIds, subjectAndFieldIds, categories, commonSearchCondition);
    } else if (subjectId === SubjectId.ENGLISH) {
      return this.getEnglishCondition(subjectIds, categories, commonSearchCondition);
    } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
      return this.getNationalLanguageCondition(subjectIds, subjectAndFieldIds, categories, commonSearchCondition);
    } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
      return this.getHistoryCondition(subjectIds, subjectAndFieldIds, categories, commonSearchCondition);
    }
  }

  private getPreviousSearchProblemsCondition(previousQueryParams: URLSearchParams): SearchConditionTypeForAllSubjects {
    // 教科IDと分野
    const subjectId = previousQueryParams.get('subjectId');
    const subjectIds = previousQueryParams.getAll('subjectIds');
    const subjectAndFieldIds = previousQueryParams.getAll('subjectAndFieldIds');
    const categories = previousQueryParams.getAll('categories');

    // 教科をまたいで共通の検索条件
    const commonSearchCondition = this.getCommonSearchCondition(previousQueryParams);

    if (this.scienceIds.includes(subjectId)) {
      return this.getScienceCondition(subjectIds, subjectAndFieldIds, categories, commonSearchCondition);
    } else if (subjectId === SubjectId.ENGLISH) {
      return this.getEnglishCondition(subjectIds, categories, commonSearchCondition);
    } else if (subjectId === SubjectId.NATIONAL_LANGUAGE) {
      return this.getNationalLanguageCondition(subjectIds, subjectAndFieldIds, categories, commonSearchCondition);
    } else if (subjectId === SubjectId.JAPANESE_HISTORY || subjectId === SubjectId.WORLD_HISTORY) {
      return this.getHistoryCondition(subjectIds, subjectAndFieldIds, categories, commonSearchCondition);
    }
  }

  private getScienceCondition(
    subjectIds: string[],
    subjectAndFieldIds: string[],
    categories: string[],
    commonSearchCondition: CommonSearchCondition
  ): SearchCondition<ScienceSearchCondition> {
    const condition: SearchCondition<ScienceSearchCondition> = {
      ...commonSearchCondition,
      subjectCondition: {}
    };

    if (subjectIds) condition.subjectCondition.subjectIds = subjectIds;
    if (subjectAndFieldIds) condition.subjectCondition.subjectAndFieldIds = subjectAndFieldIds;
    if (categories) condition.subjectCondition.categories = categories;

    return condition;
  }

  private getEnglishCondition(
    subjectIds: string[],
    categories: string[],
    commonSearchCondition: CommonSearchCondition
  ): SearchCondition<EnglishSearchCondition> {
    const condition: SearchCondition<EnglishSearchCondition> = {
      ...commonSearchCondition,
      subjectCondition: {}
    };

    if (subjectIds) condition.subjectCondition.subjectIds = subjectIds;
    if (categories) condition.subjectCondition.categories = categories;

    return condition;
  }

  private getNationalLanguageCondition(
    subjectIds: string[],
    subjectAndFieldIds: string[],
    categories: string[],
    commonSearchCondition: CommonSearchCondition
  ): SearchCondition<NationalLanguageSearchCondition> {
    const condition: SearchCondition<NationalLanguageSearchCondition> = {
      ...commonSearchCondition,
      subjectCondition: {}
    };

    if (subjectIds) condition.subjectCondition.subjectIds = subjectIds;
    if (subjectIds) condition.subjectCondition.subjectAndFieldIds = subjectAndFieldIds;
    if (categories) condition.subjectCondition.categories = categories;

    return condition;
  }

  private getHistoryCondition(
    subjectIds: string[],
    subjectAndFieldIds: string[],
    categories: string[],
    commonSearchCondition: CommonSearchCondition
  ): SearchCondition<HistorySearchCondition> {
    const condition: SearchCondition<HistorySearchCondition> = {
      ...commonSearchCondition,
      subjectCondition: {}
    };

    if (subjectIds) condition.subjectCondition.subjectIds = subjectIds;
    if (subjectAndFieldIds) condition.subjectCondition.subjectAndFieldIds = subjectAndFieldIds;
    if (categories) condition.subjectCondition.categories = categories;

    return condition;
  }

  private getCommonSearchCondition(queryParams: URLSearchParams): CommonSearchCondition {
    const condition: CommonSearchCondition = {
      subjectId: queryParams.get('subjectId')
    };

    if (queryParams.get('isThinking')) condition.isThinking = true;

    if (queryParams.get('startYear')) condition.startYear = Number(queryParams.get('startYear'));
    if (queryParams.get('endYear')) condition.endYear = Number(queryParams.get('endYear'));

    const levels = queryParams.getAll('levels');
    const levelsNum = levels.map(level => Number(level));
    if (levels.length > 0) condition.levels = levelsNum;

    const excludeAnswered = queryParams.get('excludeAnswered');
    if (excludeAnswered === 'true') condition.excludeAnswered = true;

    const universityTypeIds = queryParams.getAll('universityTypeIds');
    if (universityTypeIds.length > 0) condition.universityTypeIds = universityTypeIds;

    const areaIds = queryParams.getAll('areaIds');
    if (areaIds.length > 0) condition.areaIds = areaIds;

    const universityIds = queryParams.getAll('universityIds');
    if (universityIds.length > 0) condition.universityIds = universityIds;

    const departmentCategoryId = queryParams.get('departmentCategoryId');
    if (departmentCategoryId) condition.departmentCategoryId = departmentCategoryId;

    const hasExternalData = queryParams.get('hasExternalData');
    if (hasExternalData === 'true') condition.hasExternalData = true;

    return condition;
  }

  private getSortCondition(sortType: string): string {
    if (Object.values(SortType).indexOf(SortType[sortType]) >= 0) {
      return sortType;
    }
  }
}
