import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CommonIdUser } from '../../models/common-id/common-id-user';
import { Collection } from '../../resources/app-firebase';
import * as Sentry from '@sentry/angular';
import { CommonIdAuthError } from '../../errors/common-id-auth-error';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';
import { Log } from 'src/app/utils/log';

@Injectable({
  providedIn: 'root'
})
export class CommonIdAuthService {
  signedInUserId$: Observable<string>;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions, private http: HttpClient) {
    this.signedInUserId$ = this.afAuth.authState.pipe(
      map(firebaseUser => (firebaseUser ? firebaseUser.uid : '')),
      shareReplay(1)
    );
  }

  commonIdSignIn(customToken: string): Observable<CommonIdUser> {
    const commonIdSignInPromise = this.afAuth.signInWithCustomToken(customToken).then(userCredential => userCredential.user.uid);
    return from(commonIdSignInPromise).pipe(
      switchMap(userId => {
        return this.afs
          .collection(Collection.COMMON_ID_USERS)
          .doc(userId)
          .get()
          .pipe(
            map(snapshot => {
              if (!snapshot.exists) throw CommonIdAuthError.userNotFound('指定されたユーザーが見つかりませんでした。');
              const user = snapshot.data() as CommonIdUser;
              if (user.isActive === false) {
                throw CommonIdAuthError.userDisabled('指定されたユーザーは無効です。');
              } else {
                Sentry.setUser({ email: user.email, id: user.id, username: user.nickname });
                return CommonIdUserUtil.setDefaultValues(user);
              }
            })
          );
      })
    );
  }

  commonIdSignOut(): Observable<void> {
    localStorage.removeItem('state');
    localStorage.removeItem('nonce');
    localStorage.removeItem('tokenInfo');

    const promise = this.afAuth.signOut();
    return from(promise).pipe(tap(() => Log.debug(this.constructor.name, 'sign out exec')));
  }
}
