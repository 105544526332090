import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { map, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { navigate, setTitle, setBrowserTitle } from 'src/app/actions/core.actions';
import { RootState } from 'src/app/reducers';
import * as InformationSelectors from 'src/app/selectors/information.selectors';

import { Information, InformationResponse } from 'src/app/models/information';
import { Dates } from 'src/app/utils/dates';
import { enter } from 'src/app/resources/animations';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

@Component({
  selector: 'app-information-detail',
  templateUrl: './information-detail.component.html',
  styleUrls: ['./information-detail.component.scss'],
  animations: [enter]
})
export class InformationDetailComponent implements OnInit, AfterViewInit {
  information$: Observable<Information>;

  private LOG_SOURCE = this.constructor.name;
  private title = 'お知らせ詳細';

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUp();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  backToList() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveInformation() }));
  }

  sanitize(text) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  private setUp() {
    const informationId: string = this.activatedRoute.snapshot.paramMap.get('informationId');
    this.information$ = this.getInformationDetail(informationId).pipe(
      filter(it => it != null),
      take(1),
      map(result => this.getInformation(result))
    );
  }

  private getInformationDetail(informationId: string): Observable<InformationResponse> {
    return this.store.select(InformationSelectors.getInformations).pipe(
      filter(it => it != null),
      take(1),
      map(result => result.response.filter(info => info.id === informationId)[0])
    );
  }

  private getInformation(information: InformationResponse): Information {
    const date = Dates.simple4YmdStringFromIso(information.publishedAt);
    return {
      id: information.id,
      isNew: null,
      categoryName: information.categoryName,
      date,
      title: information.title,
      body: information.content,
      importantFlag: information.importantFlag
    } as Information;
  }
}
