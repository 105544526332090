<app-dialog-basic [dialogTitle]="dialogTitle" dialogIcon="check_circle">
  <p class="select-answered-notice">
    解き終わった大問を解答済みにしましょう。<br />
    解答済みにすると、<a (click)="goToMylistAnsweredProblems()">マイリスト > 解答済み</a>で振り返ることができます。
  </p>
  <div class="flex gap-4 bar">
    <b>進捗</b>
    <div class="grow"><app-progress-bar progress="{{ progress }}"></app-progress-bar></div>
    <b class="text-primary">{{ totalAnsweredCount }} / {{ totalProblemCount }}問</b>
  </div>
  <div class="wrap-select-answerd">
    <div class="mb-4" *ngIf="(loading$ | async) === false">
      <div class="flex flex-col gap-1">
        <div class="select-answered-list" *ngFor="let theme of themes; index as i">
          <ng-container *ngTemplateOutlet="problems; context: { $implicit: theme, index: i }"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled$ | async">キャンセル</button>
    <button mat-flat-button color="primary" (click)="save()" [disabled]="disabled$ | async">保存</button>
  </app-dialog-basic-footer>
</app-dialog-basic>

<div class="progress-48">
  <mat-spinner [diameter]="48" @enter *ngIf="disabled$ | async"></mat-spinner>
</div>

<ng-template #problems let-theme let-index="index">
  <div>
    <div class="select-answered-list-title">
      <button class="wrap-text" (click)="toggleIsExpanded(index)">
        <div class="grow">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }}問)</div>
        <div class="flex gap-3 select-answered-toggle text-md">
          <b class="text-primary">{{ theme.answeredProblemCount }} / {{ theme.problemCount }}</b>
        </div>
      </button>
      <div class="slide-toggle-wrap">
        <app-slide-toggle
          [checked]="theme.toggleCheck"
          [disabled]="disabled$ | async"
          (slideClick)="toggle(theme, $event)"
        ></app-slide-toggle>
      </div>
      <button class="wrap-arrow" (click)="toggleIsExpanded(index)">
        <div class="arrow">
          <mat-icon *ngIf="!themeExpands[index]" class="icon" aria-hidden="true">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="themeExpands[index]" class="icon" aria-hidden="true">keyboard_arrow_up</mat-icon>
        </div>
      </button>
    </div>
    <div>
      <app-provider-accordion [isExpanded]="themeExpands[index]">
        <div class="select-answered-list-content flex items-center" *ngFor="let problem of theme.problems">
          <div class="select-answered-list-detail">
            <b>{{ theme.order }}-{{ problem.orderInTheme }}</b>
            <!-- TODO: 以下を修正 -->
            <p class="playlist-detail-content-issue">
              <ng-container *ngIf="problem.isOriginalProblem">
                {{ problem.originalOverview }}
                <ng-container *ngIf="problem.showProblemNumberFlg">
                  {{ problem.problemNumber }}
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!problem.isOriginalProblem">
                {{ problem.university }} {{ problem.year }}年度 {{ problem.problemNumber }}
              </ng-container>
            </p>
          </div>
          <div class="select-answered-list-toggle">
            <app-slide-toggle
              [checked]="isAddedProblem(problem.id)"
              [disabled]="disabled$ | async"
              [problemId]="problem.id"
              [particles]="true"
              (slideClick)="toggle(theme, $event)"
            ></app-slide-toggle>
          </div>
        </div>
      </app-provider-accordion>
    </div>
  </div>
</ng-template>
