import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/reducers';
import { setBrowserTitle } from 'src/app/actions/core.actions';

@Component({
  selector: 'app-problem-detail-no-external-data',
  templateUrl: './problem-detail-no-external-data.component.html',
  styleUrls: ['./problem-detail-no-external-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProblemDetailNoExternalDataComponent implements OnInit {
  toolbarTitle = '問題詳細画像掲載なし';

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.toolbarTitle }));
  }
}
