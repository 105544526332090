import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonIdAddPaperBookmarkRequest } from '../../models/common-id/common-id-add-paper-bookmark-request';
import { CommonIdAddPaperBookmarkResponse } from '../../models/common-id/common-id-add-paper-bookmark-response';
import { CommonIdDeletePaperBookmarkRequest } from '../../models/common-id/common-id-delete-paper-bookmark-request';
import { CommonIdDeletePaperBookmarkResponse } from '../../models/common-id/common-id-delete-paper-bookmark-response';
import { CommonIdPaperBookmark } from '../../models/common-id/common-id-paper-bookmark';
import { CallableFunction, Collection } from '../../resources/app-firebase';

@Injectable({
  providedIn: 'root'
})
export class CommonIdPaperBookmarkService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  commonIdFindPaperBookmark(userId: string): Observable<CommonIdPaperBookmark> {
    return this.afs
      .collection<CommonIdPaperBookmark>(Collection.COMMON_ID_PAPER_BOOKMARK, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('userId', '==', userId);
        return query;
      })
      .get()
      .pipe(
        map(snapshot =>
          snapshot.empty
            ? ({} as CommonIdPaperBookmark)
            : snapshot.docs.map(doc => {
                const paperBookmarkId: string = doc.id;
                const data = doc.data();
                data.id = paperBookmarkId;
                return data as CommonIdPaperBookmark;
              })[0]
        )
      );
  }

  commonIdAddPaperBookmark(userId: string, paperId: string): Observable<CommonIdAddPaperBookmarkResponse> {
    const req: CommonIdAddPaperBookmarkRequest = { userId, paperId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_ADD_PAPER_BOOKMARK);
    return callable(req);
  }

  commonIdDeletePaperBookmark(userId: string, paperId: string): Observable<CommonIdDeletePaperBookmarkResponse> {
    const req: CommonIdDeletePaperBookmarkRequest = { userId, paperId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_DELETE_PAPER_BOOKMARK);
    return callable(req);
  }
}
