import { Component, OnInit, HostListener } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { DIALOG_ZERO_PADDING_PANEL_CLASS } from '../../../../resources/config';
import { CommonIdGotoManabiIdDialogComponent } from '../../../components/common-id/goto-manabi-id-dialog/goto-manabi-id-dialog.component';

@Component({
  selector: 'app-common-id-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class CommonIdTopHeaderComponent implements OnInit {
  private threshold = 300; // スクロール量がこの値を超えたらヘッダーを表示する
  headerIsShow = false;

  constructor(private dialog: MatDialog) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(e: any) {
    const currentPos: number = e.target.scrollingElement.scrollTop;
    if (currentPos > this.threshold) {
      this.headerIsShow = true;
    } else {
      this.headerIsShow = false;
    }
  }

  ngOnInit() {}

  openManabiModal() {
    const config: MatDialogConfig = {
      width: '500px',
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      disableClose: true
    };
    this.dialog.open(CommonIdGotoManabiIdDialogComponent, config);
  }
}
