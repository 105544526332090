// import * as StackdriverReporter from 'stackdriver-errors-js';
import { environment } from 'src/environments/environment';

// interface ErrorReporter {
//   start: (config: { key: string; projectId: string }) => void;
//   report: (error: Error | string) => void;
// }

// const needToErrorReport = environment.envName !== 'dev';
// let errorReporter: ErrorReporter;

// if (needToErrorReport) {
//   errorReporter = new StackdriverReporter();
//   errorReporter.start({
//     key: environment.firebase.apiKey,
//     projectId: environment.firebase.projectId
//   });
// }

// tslint:disable: no-console
export class Log {
  private static getMessage(source: string, message: string | (() => string)): string {
    const logMsg = typeof message === 'function' ? message() : message;
    return `[${source}] ${logMsg}`;
  }

  static info(source: string, message: string | (() => string), ...optionalParams: any[]) {
    const m = this.getMessage(source, message);
    console.log(m, ...optionalParams);
  }

  static debug(source: string, message: string | (() => string), ...optionalParams: any[]) {
    if (environment.production) return;

    const m = this.getMessage(source, message);
    console.log(m, ...optionalParams);
  }

  static warn(source: string, message: string | (() => string), ...optionalParams: any[]) {
    if (environment.production) return;

    const m = this.getMessage(source, message);
    console.warn(m, ...optionalParams);
  }

  static error(source: string, message: string | (() => string), ...optionalParams: any[]) {
    const m = this.getMessage(source, message);
    console.error(m, ...optionalParams);
    // if (needToErrorReport) errorReporter.report(m);
  }
}
// tslint:enable: no-console
