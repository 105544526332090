export class Diff {
  static isDifferentArray<T>(before: T[] | undefined, after: T[] | undefined): boolean {
    if (!before && !after) return false;
    if (before != null && after == null) return true;
    if (before == null && after != null) return true;
    if (before.length !== after.length) return true;
    const hasDiff = before.map((beforeValue, index) => beforeValue !== after[index]).includes(true);
    return hasDiff;
  }

  static isDifferentObject<T extends object>(before: T | undefined, after: T | undefined): boolean {
    if (!before && !after) return false;
    if (before != null && after == null) return true;
    if (before == null && after != null) return true;

    const beforeKeys = Object.keys(before);
    const afterKeys = Object.keys(after);
    if (beforeKeys.length !== afterKeys.length) return true;

    const hasDifferentKey = beforeKeys.map(beforeKey => !afterKeys.includes(beforeKey)).includes(true);
    if (hasDifferentKey) return true;

    const hasDifferentValue = beforeKeys
      .map(beforeKey => {
        const beforeValue = before[beforeKey];
        const afterValue = after[beforeKey];
        if (Array.isArray(beforeValue)) {
          return Diff.isDifferentArray(beforeValue, afterValue);
        } else if (typeof beforeValue === 'object' && typeof afterValue === 'object') {
          return Diff.isDifferentObject(beforeValue, afterValue);
        }
        return beforeValue !== afterValue;
      })
      .includes(true);
    if (hasDifferentValue) return true;

    return false;
  }
}
