import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, map, shareReplay } from 'rxjs/operators';

import * as EventLogActions from 'src/app/actions/event-log.actions';
import * as EventLogSelectors from 'src/app/selectors/event-log.selectors';
import { RootState } from 'src/app/reducers';
import { setTitle, dispatchAppError, setBrowserTitle } from 'src/app/actions/core.actions';
import { SearchLogFileMetadata } from 'src/app/models/search-log-file-metadata';
import {
  SubjectId,
  ENGLISH_SEARCH_LOG_FILE_PREFIX,
  MATH_SEARCH_LOG_FILE_PREFIX,
  ACTIVE_LOG_FILE_SUBJECT_IDS,
  PHYSICS_SEARCH_LOG_FILE_PREFIX,
  CHEMISTRY_SEARCH_LOG_FILE_PREFIX,
  BIOLOGY_SEARCH_LOG_FILE_PREFIX,
  NATIONAL_LANGUAGE_SEARCH_LOG_FILE_PREFIX,
  JAPANESE_HISTORY_SEARCH_LOG_FILE_PREFIX,
  WORLD_HISTORY_SEARCH_LOG_FILE_PREFIX,
  GEOGRAPHY_SEARCH_LOG_FILE_PREFIX,
  POLITICAL_ECONOMY_SEARCH_LOG_FILE_PREFIX
} from 'src/app/resources/config';
import { Dates } from 'src/app/utils/dates';
import { GeneralError } from 'src/app/errors/general-error';
import { enter } from 'src/app/resources/animations';
import { CustomErrorMessage } from 'src/app/errors/error-info';
import { Subject } from '../../../models/common-data';
import { SubjectUtil } from '../../../utils/subject-util';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';
import { combineLatest } from 'rxjs';

interface SearchLogFileMetadataForDisplay extends SearchLogFileMetadata {
  buttonLabel: string;
  downloading: boolean;
}

interface MetadataList {
  subjectName: string;
  dataList: SearchLogFileMetadataForDisplay[];
}

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  animations: [enter]
})
export class ReportsComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private title = 'レポート';

  metadataLists: MetadataList[];

  // two-way binding
  isWindows = true;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.store.dispatch(EventLogActions.findSearchLogFileMetadataList());
    this.setUpMetadataLists();
  }

  ngOnDestroy() {
    this.store.dispatch(EventLogActions.initializeSearchLogFileMetadataListState());
    this.store.dispatch(EventLogActions.initializeSearchLogFileObjectUrlState());
  }

  downloadSearchLogFile(metadata: SearchLogFileMetadataForDisplay) {
    metadata.downloading = true;
    const filePath = metadata.filePath;
    const isCRLF = this.isWindows;
    this.store.dispatch(EventLogActions.resolveSearchLogFileObjectUrl({ filePath, isCRLF }));
    this.store
      .select(EventLogSelectors.getSpecificResolveSearchLogFileObjectUrlResult(filePath))
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        metadata.downloading = false;
        if (result.success) {
          const searchLogFileName = this.getSearchLogFileName(metadata);
          const link = document.createElement('a');
          link.href = result.objectUrl;
          link.download = searchLogFileName;
          link.click();
          link.remove();
          URL.revokeObjectURL(result.objectUrl);
          this.store.dispatch(EventLogActions.initializeSearchLogFileObjectUrl({ filePath }));
        }
      });
  }

  private setUpMetadataLists() {
    const metadataListForDisplay$ = this.store.select(EventLogSelectors.getSearchLogFileMetadataList).pipe(
      filter(it => it != null),
      map(metadataList =>
        metadataList.map<SearchLogFileMetadataForDisplay>(metadata => ({
          ...metadata,
          buttonLabel: this.getButtonLabel(metadata),
          downloading: false
        }))
      )
    );

    const subjects$ = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([subjects$, metadataListForDisplay$])
      .pipe(take(1))
      .subscribe(([subjects, listForDisplay]) => {
        this.metadataLists = ACTIVE_LOG_FILE_SUBJECT_IDS.map(subjectId => this.getMetadataList(subjects, subjectId, listForDisplay));
      });
  }

  private sortMetadataList(metadataList: SearchLogFileMetadataForDisplay[]): SearchLogFileMetadataForDisplay[] {
    return [...metadataList].sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
  }

  private getButtonLabel(metadata: SearchLogFileMetadata): string {
    const startDateYmd = Dates.simpleYmdStringFromIso(metadata.startDate);
    const endDateYmd = Dates.simpleYmdStringFromIso(metadata.endDate);
    return `${startDateYmd} - ${endDateYmd}`;
  }

  private getSearchLogFileName(metadata: SearchLogFileMetadata): string {
    const prefix =
      metadata.subjectId === SubjectId.ENGLISH
        ? ENGLISH_SEARCH_LOG_FILE_PREFIX
        : metadata.subjectId === SubjectId.MATH
        ? MATH_SEARCH_LOG_FILE_PREFIX
        : metadata.subjectId === SubjectId.NATIONAL_LANGUAGE
        ? NATIONAL_LANGUAGE_SEARCH_LOG_FILE_PREFIX
        : metadata.subjectId === SubjectId.PHYSICS
        ? PHYSICS_SEARCH_LOG_FILE_PREFIX
        : metadata.subjectId === SubjectId.CHEMISTRY
        ? CHEMISTRY_SEARCH_LOG_FILE_PREFIX
        : metadata.subjectId === SubjectId.BIOLOGY
        ? BIOLOGY_SEARCH_LOG_FILE_PREFIX
        : metadata.subjectId === SubjectId.JAPANESE_HISTORY
        ? JAPANESE_HISTORY_SEARCH_LOG_FILE_PREFIX
        : metadata.subjectId === SubjectId.WORLD_HISTORY
        ? WORLD_HISTORY_SEARCH_LOG_FILE_PREFIX
        : metadata.subjectId === SubjectId.GEOGRAPHY
        ? GEOGRAPHY_SEARCH_LOG_FILE_PREFIX
        : metadata.subjectId === SubjectId.POLITICAL_ECONOMY
        ? POLITICAL_ECONOMY_SEARCH_LOG_FILE_PREFIX
        : '';
    if (!prefix) {
      const error = GeneralError.customMessage(CustomErrorMessage.REPORT_INVALID_SUBJECT_ID);
      this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
      throw GeneralError.unknown(`ログファイルの prefix が空です`);
    }

    const startDate = Dates.serializedYmdStringFromIso(metadata.startDate);
    const endDate = Dates.serializedYmdStringFromIso(metadata.endDate);
    return `${prefix}${startDate}_${endDate}.csv`;
  }

  private getMetadataList(subjects: Subject[], subjectId: string, metadataListForDisplay: SearchLogFileMetadataForDisplay[]): MetadataList {
    let subjectName = SubjectUtil.getName(subjects, subjectId);
    subjectName = subjectName === '' ? `unknown subject id: ${subjectId}` : subjectName;
    const dataList = this.sortMetadataList(metadataListForDisplay.filter(it => it.subjectId === subjectId));
    return { subjectName, dataList };
  }
}
