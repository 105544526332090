import {
  CommonIdPlaylistTheme,
  CommonIdRawPlaylist,
  CommonIdPlaylist,
  CommonIdPlaylistSearchUrl
} from '../../models/common-id/common-id-playlist';
import { AppError } from '../../errors/app-error';
import { GeneralError } from '../../errors/general-error';

export class CommonIdPlaylistMapper {
  static mapThemesFromJsonString(str: string): CommonIdPlaylistTheme[] {
    try {
      const themes: CommonIdPlaylistTheme[] = JSON.parse(str);
      return themes;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`playlist.themes の map に失敗しました: ${e}`);
    }
  }

  static mapSearchUrlsFromJsonString(str: string): CommonIdPlaylistSearchUrl[] {
    try {
      const searchUrlsArray: string[] = JSON.parse(str);
      const commonIdPlaylistSearchUrls: CommonIdPlaylistSearchUrl[] = searchUrlsArray.map(searchUrl => {
        const titleAndQueryParams = searchUrl.split(':');
        const title = titleAndQueryParams[0];
        const queryParams = titleAndQueryParams[1];

        const commonIdPlaylistSearchUrl: CommonIdPlaylistSearchUrl = { title, queryParams };
        return commonIdPlaylistSearchUrl;
      });

      return commonIdPlaylistSearchUrls;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`playlist.searchUrls の map に失敗しました: ${e}`);
    }
  }

  static mapPlaylistFromRaw(raw: CommonIdRawPlaylist): CommonIdPlaylist {
    try {
      const themes = CommonIdPlaylistMapper.mapThemesFromJsonString(raw.themes);
      const tags: string[] = raw.tags !== null ? JSON.parse(raw.tags) : null;
      const searchUrls = raw.searchUrls === '' ? null : CommonIdPlaylistMapper.mapSearchUrlsFromJsonString(raw.searchUrls);
      const playlist: CommonIdPlaylist = {
        playlistId: raw.playlistId,
        subjectId: raw.subjectId,
        seriesName: raw.seriesName,
        order: raw.order,
        name: raw.name,
        description: raw.description,
        problemCount: raw.problemCount,
        publishedDate: raw.publishedDate,
        contentUpdatedDate: raw.contentUpdatedDate,
        yearRange: raw.yearRange,
        creator: raw.creator,
        pending: raw.pending,
        duration: raw.duration,
        priorityFlg: raw.priorityFlg,
        freeFlg: raw.freeFlg,
        tags,
        enablePdfDownload: raw.enablePdfDownload,
        themes,
        searchUrls
      };
      return playlist;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`playlist の map に失敗しました: ${e}`);
    }
  }
}
