<app-content-row>
  <app-common-id-mylist-banner (bannerClick)="bannerClick()"></app-common-id-mylist-banner>

  <app-content-row>
    <app-wrapper size="lg" isFitWithSmallScreen="true">
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon aria-hidden="true" class="card-content-head-icon" fontSet="material-icons-outlined">fact_check</mat-icon>
          マイリスト
        </app-card-content-head>
        <app-card-content-nav
          (menuClick)="menuClickHandler($event)"
          [menus]="[
            {
              key: 'mylist-answer-problem',
              icon: 'check_circle',
              fontSet: 'material-icons-outlined',
              label: '解答済み',
              isActive: false
            },
            {
              key: 'mylist-univ',
              icon: 'star',
              label: '大学',
              isActive: true
            },
            {
              key: 'mylist-problem',
              icon: 'attach_file',
              fontSet: 'material-icons-outlined',
              label: '過去問',
              isActive: false
            },
            {
              key: 'mylist-playlist',
              icon: 'attach_file',
              fontSet: 'material-icons-outlined',
              label: 'おすすめ問題セット',
              isActive: false
            },
          ]"
        ></app-card-content-nav>

        <div @enter *ngIf="initializedUniversityBookmark$ | async; else loadingProgress">
          <!-- １件も登録していない場合に表示するエリア -->
          <app-wrapper size="fluid" *ngIf="unvIds.length == 0">
            <app-content-row>
              <app-content-no-data>
                <div class="flex flex-col-reverse md:flex-row items-center justify-center w-9/12 mx-auto my-4">
                  <div class="md:w-1/2 text-center leading-relaxed">
                    <p class="text-md md:text-lg font-bold">お気に入り大学の登録はありません</p>
                    <p class="text-sm md:text-md my-6">
                      お気に入り大学を登録すると、<br />
                      その大学の過去問をすぐに見ることができます。
                    </p>
                    <button mat-flat-button class="mr-2 bg-gray-50 text-primary font-normal" (click)="goSearchUniv()">
                      <mat-icon aria-hidden="true" class="card-content-head-icon">search</mat-icon>
                      大学から探す
                    </button>
                  </div>
                  <div class="md:w-1/2 -mt-4 md:mt-0">
                    <picture>
                      <source media="(min-width: 900px)" srcset="assets/images/mylist-example-univ@2x.png" />
                      <img class="pointer-events-none" src="assets/images/mylist-example-univ-sp@2x.png" width="940" height="840" alt="" />
                    </picture>
                  </div>
                </div>
              </app-content-no-data>
            </app-content-row>
          </app-wrapper>

          <app-wrapper size="fluid" isFitWithSmallScreen="true" *ngIf="unvIds.length > 0">
            <app-content-row>
              <app-table-overflow>
                <table mat-table [dataSource]="unvIds" class="w-full">
                  <ng-container matColumnDef="fav">
                    <th mat-header-cell *matHeaderCellDef class="w-16"></th>
                    <td mat-cell *matCellDef="let element" class="font-bold">
                      <app-button-toggle-bookmark
                        [isBookmarked]="true"
                        (backClick)="deleteUniversityBookmarkDialog(element.unvId)"
                      ></app-button-toggle-bookmark>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 200px;">大学名</th>
                    <td mat-cell *matCellDef="let element" class="font-bold">
                      {{ getUnivName(element.unvId) }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="goToUniv">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" class="text-right">
                      <button
                        mat-button
                        color="primary"
                        class="text-xs md:text-sm underline hover:no-underline"
                        (click)="goDetail(element.unvId)"
                      >
                        大学のページへ
                      </button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="['fav', 'name', 'goToUniv']"></tr>
                  <tr mat-row *matRowDef="let row; columns: ['fav', 'name', 'goToUniv']"></tr>
                </table>
              </app-table-overflow>
            </app-content-row>
          </app-wrapper>
          <!-- <app-wrapper size="fluid">
            <app-content-row>
              <div class="flex justify-end">
                <app-paginator [currentPage]="2" [totalItemCount]="10" [pageSize]="20" [windowSize]="5"></app-paginator>
              </div>
            </app-content-row>
          </app-wrapper> -->
        </div>

        <ng-template #loadingProgress>
          <app-content-row>
            <div class="spinner-spacer">
              <div class="progress-48">
                <mat-spinner [diameter]="48"></mat-spinner>
              </div>
            </div>
          </app-content-row>
        </ng-template>
      </app-card-content>
    </app-wrapper>
  </app-content-row>
</app-content-row>
