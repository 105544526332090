import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ContentChildren, QueryList, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-osusume-carousel',
  templateUrl: './osusume-carousel.component.html',
  styleUrls: ['./osusume-carousel.component.scss']
})
export class OsusumeCarouselComponent implements OnInit, AfterViewInit {
  @ViewChild('carousel') carouselElement: ElementRef;
  @ContentChildren('card') cards: QueryList<ElementRef>;

  @Input() speedFactor = 1;

  private totalWidth = 0;
  private cardWidths: number[] = [];
  private currentPosition = 0;
  private animationId: number;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.initializeCarousel();
    this.startAnimation();
  }

  @HostListener('window:resize')
  onResize() {
    this.updateCarouselDimensions();
  }

  private initializeCarousel() {
    this.updateCarouselDimensions();
    this.duplicateCards();
  }

  private updateCarouselDimensions() {
    this.cardWidths = this.cards.map(card => card.nativeElement.offsetWidth);
    this.totalWidth = this.cardWidths.reduce((sum, width) => sum + width, 0);
    this.carouselElement.nativeElement.style.width = `${this.totalWidth * 2}px`;
    this.adjustScrollPosition();
  }

  private duplicateCards() {
    const originalCards = this.cards.toArray();
    originalCards.forEach(card => {
      const clone = card.nativeElement.cloneNode(true);
      this.carouselElement.nativeElement.appendChild(clone);
    });
  }

  private adjustScrollPosition() {
    while (this.currentPosition <= -this.totalWidth) {
      this.currentPosition += this.totalWidth;
    }
    while (this.currentPosition > 0) {
      this.currentPosition -= this.totalWidth;
    }
    this.carouselElement.nativeElement.style.transform = `translateX(${this.currentPosition}px)`;
  }

  private startAnimation() {
    const animate = () => {
      this.currentPosition -= 1 * this.speedFactor;
      this.adjustScrollPosition();
      this.animationId = requestAnimationFrame(animate);
    };

    this.animationId = requestAnimationFrame(animate);
  }

  ngOnDestroy() {
    if (this.animationId) {
      cancelAnimationFrame(this.animationId);
    }
  }
}
