import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-basic-head',
  templateUrl: './section-basic-head.component.html',
  styleUrls: ['./section-basic-head.component.scss']
})
export class SectionBasicHeadComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
