import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-content-head',
  templateUrl: './card-content-head.component.html',
  styleUrls: ['./card-content-head.component.scss']
})
export class CardContentHeadComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
