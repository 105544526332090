<div class="terms-content">
  <app-document-theme-default>
    <div class="text-md">
      <p>
        この規約（以下「本規約」といいます。）は、株式会社旺文社（以下「当社」といいます。）が提供する大学入試過去問題の検索・閲覧Webサービス「入試正解デジタル」（以下「本サービス」といいます。）の利用に関する条件を定めるものであり、本サービスを利用するすべてのユーザー（以下「ユーザー」といいます。）に適用されます。ユーザーは、本サービスを利用する前に、本規約をよくお読みください。
      </p>
      <p>
        また、本サービスの利用には、当社が運営する共通IDサービス「旺文社まなびID」（以下「まなびID」といいます。）の登録・ログインが必要となります。「まなびID」を通じた本サービスの利用および本規約に定めのない事項については、「旺文社まなびID利用規約」および「旺文社まなびID等プライバシーポリシー」が適用されます。
      </p>
      <section>
        <h2>第 1 条 （定義）</h2>
        <p>本規約において、次の各号に掲げる用語の意義は、当該各号に定めるところによるものとします。</p>
        <p>
          （1）「コンテンツ」<br />
          ユーザーが本サービスを通じてアクセスすることができる情報（文章、画像、動画、音声、音楽その他のサウンド、イメージ、ソフトウェア、プログラム、コードその他のデータを含みますが、これらに限られません。）をいいます。<br />
          （2）「有料コンテンツ」<br />
          本条第1号のコンテンツおよび本サービスの機能のうち、本サービスの有料会員となったユーザーに対して提供するコンテンツおよび機能をいいます。<br />
          （3）「アカウント」<br />
          ユーザーが、まなびIDへの登録およびログインを通じた本サービスの使用権をいいます。<br />
          （4）「代理購入者」<br />
          ユーザーの親権者等の法定代理人として、ユーザーに代わって有料コンテンツを購入し、ユーザーに対して有料コンテンツを利用させる者をいいます。
        </p>
      </section>
      <section>
        <h2>第 2 条 （本規約への同意）</h2>
        <p>
          1 ユーザーは、本規約の定めに従って本サービスを利用するものとし、本規約に同意しなければ本サービスを利用することができません。<br />
          2
          ユーザーが、本サービスの利用のために「まなびID」の新規登録またはログインを行った時点で、ユーザーと当社との間で、本規約の諸規定に従った利用契約（以下「本利用契約」といいます。）が成立します。<br />
          3
          未成年者のユーザーは、有料コンテンツの購入を含む本サービスの利用の一切につき、親権者等の法定代理人の同意を得た上で本サービスをご利用ください。未成年者のユーザーが本サービスを利用した場合、親権者等の法定代理人の同意を得たものとみなします。
        </p>
      </section>
      <section>
        <h2>第 3条（本規約の改定・変更）</h2>
        <p>
          1
          当社は、当社の判断において、いつでも本規約の内容を変更または追加できるものとします。変更後の利用規約は、当社が別途定める場合を除いて、本サービス上または当社の運営するウェブサイトに掲示します。<br />
          2
          前項の規定により本規約を変更する場合、当社は、変更後の本規約の施行時期および内容を当社ウェブサイトでの掲示その他の適切な方法により周知し、またはユーザーに通知するものとし、当該施行時期が到来する前にユーザーが当社の指定する手続に従って退会手続を行わない限り、ユーザーによって承諾されたものとみなします。本規約の変更後、ユーザーが本サービスを利用した場合にも、当該ユーザーは本規約の変更を承諾したものとみなします。<br />
          3
          本規約外における本サービスのサービス内容等の記載（「旺文社まなびID利用規約」および「旺文社まなびID等プライバシーポリシー」を除きます）が、本規約の内容と異なる場合は、本規約が優先して適用されるものとします。
        </p>
      </section>
      <section>
        <h2>第 4 条（本サービスの利用）</h2>
        <p>
          1
          ユーザーは、当社の定める方法に従い、ユーザーが個人として自ら使用する範囲に限り、本サービスを利用できるものとします。ユーザーは、アカウントの共有、コンテンツの印刷による第三者への配布または授業・講座等における利用を行うことはできません。<br />
          2
          本サービスを利用するために必要な情報端末、ソフトウェア、通信回線その他の通信環境等の準備および維持は、ユーザーの費用と責任において行うものとします。<br />
          3
          当社は、ユーザーに対しコンテンツ毎に利用可能な期間等の利用条件を定めるものとします。ユーザーは、当該利用条件の範囲内においてのみ、本サービスにおいて提供コンテンツを利用することができます。
        </p>
      </section>
      <section>
        <h2>第 5 条 （有料会員）</h2>
        <p>
          1
          ユーザーは、当社所定の利用料金を支払うことで、本サービスの有料コンテンツを利用できます。有料コンテンツの金額、決済方法その他の事項は、別途当社が定め、本サービスまたは当社ウェブサイトに表示します。<br />
          2 有料コンテンツは、ユーザー本人に対してのみ利用が許諾されます。<br />
          3
          有料コンテンツを購入した場合、当該有料コンテンツの利用契約が成立し、本サービスの一部となって、本規約の定めに従うことになります。<br />
          4 ユーザーは、有料コンテンツの利用契約を利用期間の途中で終了することはできません。<br />
          5
          有料コンテンツについての支払いは、利用期間を問わず一括支払いとなります。また、利用期間の途中で理由を問わず有料コンテンツの利用契約が終了した場合であっても、日割りによる精算は行いません。<br />
          6
          当社は、ユーザーに対して事前に通知の上で、有料コンテンツの利用料金を随時変更することができます。なお、変更後の利用料金は、変更後における有料コンテンツの購入時から適用されます。<br />
          7
          有料コンテンツの購入においては、ユーザーの親権者等の法定代理人がユーザーに代わって代理購入者として購入することができます。この場合、代理購入者は購入した有料コンテンツをユーザーに対して利用させることができます。<br />
          8
          前項に基づき代理購入者が有料コンテンツを購入した場合、代理購入者は本サービスにおいてユーザーが負う義務と同様の義務を負います。また、代理購入者は、本サービスに関するユーザーの一切の行為について連帯して責任を負います。<br />
          9
          当社は、ユーザーまたは代理購入者が次の各号のいずれかに該当した場合、本規約に違反したものとして、本サービスの利用停止、有料コンテンツの利用停止または第9条第1項に定める措置を講じることができるものとします。また、当社は、当該措置によりユーザーまたは代理購入者に生じた損害について、一切の責任を負いません。この場合、ユーザーまたは代理購入者は、利用料金およびその他当社に対する債務の全てについて、期限の利益を失い、当社が申し出た場合には、当該債務を一括で支払うものとします。<br />
          （1）利用料金およびその他当社が提供する本サービスにかかる費用を滞納した場合<br />
          （2）利用料金のお支払い方法に指定したクレジットカードの利用がクレジットカード会社により停止された場合
        </p>
      </section>
      <section>
        <h2>第 6 条 （ユーザーに関する情報の取扱い）</h2>
        <p>
          1
          本サービスにおけるユーザーの個人情報の取り扱いについては、「旺文社まなびID等プライバシーポリシー」の定めによるものとし、ユーザーはこのプライバシーポリシーに従って当社がユーザーの個人情報を取扱うことについて同意するものとします。<br />
          2
          代理購入者に関して取得する個人情報は、購入履歴に関する情報等（なお、クレジットカード情報については、非保持化を行っております。）となります。また、代理購入者の個人情報は、本サービスの対価のご請求及びお支払いのため、ならびに購入情報の確認のために利用します。その他代理購入者の個人情報の取り扱いについては「旺文社まなびID等プライバシーポリシー」の定めによるものとし、代理購入者はこのプライバシーポリシーに従った当社が代理購入者の個人情報を取扱うことについて同意するものとします。
        </p>
      </section>
      <section>
        <h2>第 7 条 （権利の帰属）</h2>
        <p>
          本サービス、本サービス内のコンテンツに関する一切の知的財産権は、当社または当社にライセンスを許諾している者に帰属します。
        </p>
      </section>
      <section>
        <h2>第 8 条 （禁止行為）</h2>
        <p>
          1
          ユーザーは、本サービスの利用にあたり、自らまたは第三者をして以下の各号のいずれかに該当する行為または該当するおそれのある行為をしてはなりません。<br />
          （1）法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為またはこれらを助長する行為<br />
          （2）犯罪に結びつくまたはそのおそれのある行為（予告、関与、誘引、勧誘または助長などを含みます。）<br />
          （3）当社または他のユーザーその他の第三者に対する詐欺または脅迫行為<br />
          （4）公序良俗に反しまたは善良な風俗を害する行為<br />
          （5）当社または他のユーザーその他の第三者の知的財産権、肖像権、プライバシー、名誉、その他の権利または利益を侵害する行為<br />
          （6）本サービス上のコンテンツを、当社の承諾なく本規約または別途当社が定める範囲を超えて複製、公衆送信または送信可能化等をする行為<br />
          （7）本サービスの誤作動を誘引する行為または誤作動もしくは不具合を利用する行為<br />
          （8）本サービスに関連して当社が秘密である旨指定した情報を、当社の承諾なく開示する行為<br />
          （9）本サービス上での勧誘、宣伝または広告に関する行為<br />
          （10）本サービスの内外を問わず、本サービス上のアカウントまたはコンテンツを第三者に有償で貸与、譲渡、売買等をする行為（いわゆるリアルマネートレード行為）およびその申込みの誘引、申込み、承諾を含む一切の準備行為<br />
          （11）以下に該当しまたは該当すると当社が判断した情報を本サービス上に送信・投稿する行為<br />
          ア 差別を助長する表現を含む情報<br />
          イ 異性、同性を問わず、面識のない第三者との出会いまたはわいせつな行為等を目的とする情報<br />
          ウ スパムメール等に該当する情報またはコンピュータウィルス等の有害なプログラムを流布させる情報<br />
          エ その他当社が不適切と判断する情報<br />
          オ ユーザー本人または第三者の個人が特定できる情報<br />
          （12）本サービスの内外を問わず、本サービスに関連するイベントの開催またはグッズの販売等、本サービスが意図しない方法で利益を得ることを目的とする行為<br />
          （13）本サービスを逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本サービスのソースコードを解析する行為<br />
          （14）本サービスに接続しているシステムに権限なく不正にアクセスしまたは当社設備に蓄積された情報を不正に書き換えもしくは消去するもしくは本サービスまたは当社サーバー等に過度の負担をかける行為<br />
          （15）故意に虚偽の情報、データ等を当社へ送信、公開または投稿する行為<br />
          （16）当社または他のユーザーの情報の収集を目的とする行為<br />
          （17）当社または他のユーザーその他の第三者に成りすます行為<br />
          （18）他のユーザーのアカウントを利用、または自己のアカウントその他の本サービスに関する権利および義務を貸与もしくは譲渡等する行為<br />
          （19）その他、本規約および本サービスの趣旨・目的に反する等当社が不適切と判断する行為<br />
          2
          前項に定める禁止行為を行ったことによりユーザーに生じた損害の責任またはユーザーが他のユーザーもしくは第三者に生じさせた損害は、ユーザーが負うものとし、当社は一切の責任を負いません。
        </p>
      </section>
      <section>
        <h2>第 9 条 （規約違反に対する措置）</h2>
        <p>
          1
          当社は、ユーザーが本規約または「旺文社まなびID利用規約」第6条各号に該当しもしくは該当するおそれがあると当社が判断した場合には、当社の裁量により、何らの通知も行うことなく、当該ユーザーに対し、アカウントの利用の一時停止もしくは制限、まなびIDの削除を含む本利用契約の解除等の措置を講じることができるものとします。<br />
          2 前項に定める措置を行ったことによりユーザーに損害が生じても、当社は当該ユーザーの損害に対して、いかなる責任も負いません。
        </p>
      </section>
      <section>
        <h2>第 10 条 （損害賠償）</h2>
        <p>
          1
          ユーザーによる本規約違反行為その他故意または過失により、直接的または間接的に、当社に損害が生じた場合、ユーザーは、当社に対し、その全ての損害（弁護士等専門家費用および当社において対応に要した人件費相当額を含みます。）を賠償するものとします。<br />
          2 本サービスに関する当社の損害賠償責任については、「まなびID利用規約」第11条第1項の規定が適用されます。
        </p>
      </section>
      <section>
        <h2>第 11 条 （本サービスの利用終了）</h2>
        <p>
          1 ユーザーは、まなびIDの退会その他当社所定の方法により、いつでも本サービスの利用を終了することができます。<br />
          2
          ユーザーは、いかなる理由であっても、ユーザーが本サービスの利用を終了または利用する権利を失った場合、当該アカウントにおけるコンテンツその他本サービスに蓄積した情報を利用することができなくなること、および保有するコンテンツを削除することを、あらかじめ承諾するものとします。<br />
          3
          ユーザーは、本サービス利用終了後も、当社および第三者に対する本利用契約上の一切の義務および債務（損害賠償を含みますが、これに限りません。）を免れるものではありません。<br />
          4
          ユーザーは、本サービスの本利用契約の有効期間中であっても「まなびID」を退会した場合は、本サービスを利用することができなくなります。
        </p>
      </section>
      <section>
        <h2>第 12 条 （保証の否認および免責）</h2>
        <p>
          1
          当社は、本サービスに関する動作保証、端末OS等のバージョンアップへの対応またはその他安全な本サービスの提供について、如何なる保証も行うものではありません。<br />
          2
          当社は、ユーザーに事前に通知することなく、本サービスの内容の全部もしくは一部を変更、追加または廃止することができ、または本サービスの提供の全部もしくは一部を中断、中止、または終了することができます。当社は、本項の措置を行ったことにより、ユーザーに生じた損害に対して一切の責任を負わないものとします。<br />
          3
          本サービスに関し、ユーザー間またはユーザーと第三者との間で紛争が生じた場合、ユーザーは、当社にこれを通知の上、自己の責任と費用において解決するものとします。当社は、当該紛争に関与する義務を負わず、何ら責任を負わないものとします。<br />
          4 当社は、本サービスの利用にあたり支払った料金の返金の要請には、一切応じないものとします。
        </p>
      </section>
      <section>
        <h2>第13条　外部サービスの利用</h2>
        <p>
          1
          ユーザーは、本サービスの利用にあたり、外部サービス等を利用する場合には、本規約のほか、当該外部サービスの定める利用規約その他の条件（当該利用規約等が変更された場合には変更後の条件を含みます。）に従うものとします。<br />
          2
          外部サービスについては、当該外部サービスを提供する企業または個人が責任を負うものとし、当社は、当該外部サービスにつき、一切の動作保証、正確性、有用性、不具合等が存しないこと、第三者の権利を侵害しないこと、端末OS等のバージョンアップへの対応またはその他安全な本サービスの提供について、如何なる保証も行うものではありません。
        </p>
      </section>
      <section>
        <h2>第 14 条 （事業譲渡等の場合の取扱い）</h2>
        <p>
          当社が、本サービスもしくは本サービスにかかる事業を第三者に譲渡し、または合併もしくは会社分割等により本サービスにかかる事業を承継させたときは、当社は、当該譲渡等に伴い、本利用契約上の地位、権利および義務ならびに登録情報その他のユーザー情報を当該譲渡等の譲受人等に承継させることができるものとします。ユーザーは、かかる譲渡等につき本条において予めこれに同意したものとみなします。
        </p>
      </section>
      <section>
        <h2>第 15 条 （分離可能性）</h2>
        <p>
          本規約のいずれかの条項または条項の一部が、消費者契約法その他の法令等によって無効と判断とされた場合であっても、他の条項または無効と判断された条項の残部は、引き続き有効に存続します。
        </p>
      </section>
      <section>
        <h2>第 16条 （準拠法および合意管轄）</h2>
        <p>
          1 本規約の正文は日本語版とし、準拠法は日本法とします。<br />
          2 本サービスまたは本規約に起因しもしくは関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
      </section>

      <p>
        <strong>【附則】</strong><br />
        ・本規約は、2023年 3 月 31 日から施行します。
      </p>

      <p class="text-right text-xs">
        株式会社　旺文社<br />
        「入試正解デジタル」公式サイト<br />
        Copyright Obunsha Co.,Ltd. All Rights Reserved.
      </p>
    </div>
  </app-document-theme-default>
</div>
