<app-dialog-basic [dialogTitle]="data.title">
  <div class="mb-4">{{ data.description }}</div>

  <mat-form-field appearance="outline" class="block">
    <mat-label>{{ data.inputFieldPlaceholder }}</mat-label>
    <input matInput [formControl]="control" />
    <mat-error *ngIf="control.hasError('required')">入力してください</mat-error>
    <mat-error *ngIf="control.hasError('pattern')">
      {{ data.patternErrorMessage || '規定されている形式で入力してください' }}
    </mat-error>
  </mat-form-field>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="onNegativeClick()">{{ data.negativeButtonLabel || 'キャンセル' }}</button>
    <button mat-flat-button color="primary" (click)="onPositiveClick()">{{ data.positiveButtonLabel || 'OK' }}</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
