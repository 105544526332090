import { Log } from './log';
import { Subject } from '../models/common-data';
import { PlanSubjectId, SubjectId } from '../resources/config';

export class SubjectUtil {
  static getSubjectIds(): string[] {
    return Object.entries(SubjectId).map(([_, value]) => value);
  }

  static getName(subjects: Subject[], subjectId: string): string {
    const subject = subjects.find(obj => obj.id === subjectId);
    if (subject) return subject.name;

    if (subjectId !== PlanSubjectId.TRIAL) {
      // 体験版プランの場合はエラーではない
      Log.error('SubjectUtil.getName', `不明な subjectId です: ${subjectId}`);
    }

    return '';
  }

  static getSubjectLabelStyle(subjectId: string): string {
    switch (subjectId) {
      case SubjectId.MATH:
        return 'sugaku';
      case SubjectId.NATIONAL_LANGUAGE:
        return 'kokugo';
      case SubjectId.PHYSICS:
        return 'butsuri';
      case SubjectId.CHEMISTRY:
        return 'kagaku';
      case SubjectId.BIOLOGY:
        return 'seibutsu';
      case SubjectId.JAPANESE_HISTORY:
        return 'nihonshi';
      case SubjectId.WORLD_HISTORY:
        return 'sekaishi';
      case SubjectId.GEOGRAPHY:
        return 'chiri';
      case SubjectId.POLITICAL_ECONOMY:
        return 'seikei';
      case SubjectId.ENGLISH:
      default:
        return 'eigo';
    }
  }
}
