import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-subject-name',
  templateUrl: './button-subject-name.component.html',
  styleUrls: ['./button-subject-name.component.scss']
})
export class ButtonSubjectNameComponent implements OnInit {
  constructor() {}
  @Input() subject: string;
  @Input() isVisited = false;
  @Input() isPremium = false;
  @Input() isArrow = false;

  @Input() isPaperBookmarked = false;
  @Input() disabled = false;
  @Input() canSpinner = false;
  @Output() backClick = new EventEmitter();

  showSpinner = false;

  ngOnInit() {}

  toggle() {
    if (this.canSpinner) {
      this.showSpinner = true;
    }

    this.backClick.emit();
  }
}
