import { createAction, props } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { InformationResponse } from 'src/app/models/information';

export const getInformations = createAction('[Information] Get Informations');
export const getInformationsSuccess = createAction('[Information] Get Informations Success', props<{ response: InformationResponse[] }>());
export const getInformationsFailure = createAction('[Information] Get Informations Failure', props<{ error: AppError }>());
export const initializeGetInformationsState = createAction('[Information] Initialize Get Informations State');

export const updateNewInformation = createAction('[Information] Update New Information', props<{ newInformation: boolean }>());
