import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';

import { RootState } from '../reducers';
import { getSignedInUser } from '../selectors/auth.selectors';
import { Log } from '../utils/log';
import { navigate } from '../actions/core.actions';
import { RoutingPathResolver } from '../app-routing-path-resolver';
import { User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class SubjectGuard implements CanActivate {
  constructor(private store: Store<RootState>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null && it !== 'none'),
      take(1),
      map(user => {
        let subjectId = '';
        if (route.routeConfig.path.includes(RoutingPathResolver.resolvePlaylists())) {
          // おすすめ問題リストIDの先頭２文字が教科ID
          subjectId = route.params.playlistId.substr(0, 2);
        } else {
          subjectId = route.queryParams.subjectId;
        }

        if (!user.visibleSubjectIds.includes(subjectId)) {
          Log.warn(this.constructor.name, `許可されていない科目です: ${subjectId}`);
          if (
            route.routeConfig.path.includes(RoutingPathResolver.resolveSearchResults()) ||
            route.routeConfig.path.includes(RoutingPathResolver.resolvePrint())
          ) {
            this.store.dispatch(navigate({ url: RoutingPathResolver.resolveDefault() }));
          } else if (route.routeConfig.path.includes(RoutingPathResolver.resolvePlaylists())) {
            this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlaylists() }));
          }
        }

        return true;
      })
    );
  }
}
