import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WidgetsBreadcrumbsData } from '../../../models/widgets-breadcrumbs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumbs: WidgetsBreadcrumbsData[];

  @Output() breadcrumbsClick = new EventEmitter<string>();

  constructor() {}
  ngOnInit() {}
}
