import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-list-item',
  templateUrl: './faq-list-item.component.html',
  styleUrls: ['./faq-list-item.component.scss']
})
export class FaqListItemComponent implements OnInit {
  constructor() {}
  @Input() questionTitle: string;
  @Input() isExpanded: boolean;
  // @Input() isResponsive = false; // 参: #427 #1216

  toggleIsExpanded() {
    this.isExpanded = !this.isExpanded;
  }
  ngOnInit() {}
}
