import { Component, Inject, OnDestroy, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { inOut } from 'src/app/resources/animations';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { combineLatest, filter, Observable, of, take } from 'rxjs';
import { CommonIdPlaylist } from 'src/app/models/common-id/common-id-playlist';
import { ThemeForDisplay } from 'src/app/models/playlist';
import {
  ReadableEnglishPlaylistProblem,
  ReadableHistoryPlaylistProblem,
  ReadableNationalLanguagePlaylistProblem,
  ReadablePlaylistProblem,
  ReadableSciencePlaylistProblem
} from 'src/app/models/problem';
import {
  CommonIdAnsweredProblems,
  CommonIdSaveAnsweredProblem,
  CommonIdSaveAnsweredProblemsRequest
} from 'src/app//models/common-id/common-id-answered-problem';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import * as CommonIdAnsweredProblemActions from 'src/app/actions/common-id/common-id-answered-problem.actions';
import * as CommonIdAnsweredProblemSelectors from 'src/app/selectors/common-id/common-id-answered-problem.selectors';
import { SubjectId } from 'src/app/resources/config';
import { Log } from 'src/app/utils/log';
import { dispatchInfoMessage, navigate } from 'src/app/actions/core.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { enter } from '../../../../resources/animations';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES } from 'src/app/resources/common-id/ga';
import { GAUtil } from 'src/app/utils/ga-util';

export interface CommonIdSelectAnsweredDialogData {
  plyalistId: string;
  playlist$: Observable<CommonIdPlaylist>;
  englishPlaylistThemes$: Observable<ThemeForDisplay<ReadableEnglishPlaylistProblem>[]>;
  mathPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  nationalLanguagePlaylistThemes$: Observable<ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>[]>;
  physicsPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  chemistryPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  biologyPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  japaneseHistoryPlaylistThemes$: Observable<ThemeForDisplay<ReadableHistoryPlaylistProblem>[]>;
  worldHistoryPlaylistThemes$: Observable<ThemeForDisplay<ReadableHistoryPlaylistProblem>[]>;
  geographyPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  politicalEconomyPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
}

export interface ThemeForDialog<T extends ReadablePlaylistProblem> extends ThemeForDisplay<T> {
  answeredProblemCount?: number;
  toggleCheck?: boolean;
}

@Component({
  selector: 'app-common-id-select-answered-dialog',
  templateUrl: './select-answered-dialog.component.html',
  styleUrls: ['./select-answered-dialog.component.scss'],
  animations: [inOut, enter]
})
export class CommonIdSelectAnsweredDialogComponent implements OnInit, OnDestroy {
  dialogTitle: string;
  private LOG_SOURCE = this.constructor.name;

  signedInUser$: Observable<CommonIdUser>;
  answeredProblems$: Observable<CommonIdAnsweredProblems>;
  loading$: Observable<boolean>;
  disabled$: Observable<boolean>;
  saveAnsweredProblems: CommonIdSaveAnsweredProblem[];
  initialSaveAnsweredProblemIds: string[];

  // for UI
  totalProblemCount: number;
  totalAnsweredCount = 0;
  progress: number;
  themes: ThemeForDialog<ReadablePlaylistProblem>[] = [];
  themeExpands: boolean[] = [];

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<CommonIdSelectAnsweredDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonIdSelectAnsweredDialogData
  ) {}

  @Input() questionTitle: string;
  @Input() isExpanded: boolean;
  // @Input() isResponsive = false; // 参: #427 #1216

  toggleIsExpanded(index: number) {
    this.themeExpands[index] = !this.themeExpands[index];
  }

  ngOnInit() {
    this.dialogTitle = '解答済み';
    this.loading$ = of(true);
    this.disabled$ = of(true);
    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(filter<CommonIdUser>(it => it != null && it !== 'none'));
    this.initialSaveAnsweredProblemIds = [];

    combineLatest([this.signedInUser$, this.data.playlist$])
      .pipe(take(1))
      .subscribe(([user, playlist]) => {
        this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems());
        this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdSaveAnsweredProblemsState());

        this.store.dispatch(CommonIdAnsweredProblemActions.commonIdFindAnsweredProblems({ userId: user.id }));
        this.answeredProblems$ = this.store
          .select(CommonIdAnsweredProblemSelectors.getCommonIdAnsweredProblem)
          .pipe(filter(it => it != null));

        this.answeredProblems$.pipe(take(1)).subscribe(answeredProblems => {
          this.initialSaveAnsweredProblemIds = [];
          this.saveAnsweredProblems = [];
          if (playlist.subjectId === SubjectId.ENGLISH) {
            this.data.englishPlaylistThemes$.pipe(take(1)).subscribe(playlistThemes => {
              this.setUpAnsweredProblems(playlistThemes, answeredProblems);
            });
          } else if (playlist.subjectId === SubjectId.MATH) {
            this.data.mathPlaylistThemes$.pipe(take(1)).subscribe(playlistThemes => {
              this.setUpAnsweredProblems(playlistThemes, answeredProblems);
            });
          } else if (playlist.subjectId === SubjectId.NATIONAL_LANGUAGE) {
            this.data.nationalLanguagePlaylistThemes$.pipe(take(1)).subscribe(playlistThemes => {
              this.setUpAnsweredProblems(playlistThemes, answeredProblems);
            });
          } else if (playlist.subjectId === SubjectId.PHYSICS) {
            this.data.physicsPlaylistThemes$.pipe(take(1)).subscribe(playlistThemes => {
              this.setUpAnsweredProblems(playlistThemes, answeredProblems);
            });
          } else if (playlist.subjectId === SubjectId.CHEMISTRY) {
            this.data.chemistryPlaylistThemes$.pipe(take(1)).subscribe(playlistThemes => {
              this.setUpAnsweredProblems(playlistThemes, answeredProblems);
            });
          } else if (playlist.subjectId === SubjectId.BIOLOGY) {
            this.data.biologyPlaylistThemes$.pipe(take(1)).subscribe(playlistThemes => {
              this.setUpAnsweredProblems(playlistThemes, answeredProblems);
            });
          } else if (playlist.subjectId === SubjectId.JAPANESE_HISTORY) {
            this.data.japaneseHistoryPlaylistThemes$.pipe(take(1)).subscribe(playlistThemes => {
              this.setUpAnsweredProblems(playlistThemes, answeredProblems);
            });
          } else if (playlist.subjectId === SubjectId.WORLD_HISTORY) {
            this.data.worldHistoryPlaylistThemes$.pipe(take(1)).subscribe(playlistThemes => {
              this.setUpAnsweredProblems(playlistThemes, answeredProblems);
            });
          } else if (playlist.subjectId === SubjectId.GEOGRAPHY) {
            this.data.geographyPlaylistThemes$.pipe(take(1)).subscribe(playlistThemes => {
              this.setUpAnsweredProblems(playlistThemes, answeredProblems);
            });
          } else if (playlist.subjectId === SubjectId.POLITICAL_ECONOMY) {
            this.data.politicalEconomyPlaylistThemes$.pipe(take(1)).subscribe(playlistThemes => {
              this.setUpAnsweredProblems(playlistThemes, answeredProblems);
            });
          }
        });
      });
  }

  ngOnDestroy() {}

  goToMylistAnsweredProblems() {
    this.dialogRef.close(false);

    const url = RoutingPathResolver.resolveCommonIdMylistAnswerProblems();
    this.store.dispatch(navigate({ url }));
  }

  changeAnsweredStatus(target: { problemId: string; isAdd: boolean }) {
    // 解答済み問題を保存するための配列の保存状態フラグを更新
    const index = this.saveAnsweredProblems.findIndex(it => it.problemId === target.problemId);
    const eventSend = this.saveAnsweredProblems[index].isAdd === false;
    const saveAnsweredProblem: CommonIdSaveAnsweredProblem = { problemId: target.problemId, isAdd: target.isAdd };
    this.saveAnsweredProblems[index] = saveAnsweredProblem;

    // 解答済みトグルボタンがオンになっている問題IDを格納している配列を更新
    if (eventSend && target.isAdd) {
      const eventParams = {
        'event_category': GA_EVENT_CATEGORIES.SET_SOLVED_ON,
        'event_label': saveAnsweredProblem.problemId,
        'value': 1
      };
      GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);
      this.initialSaveAnsweredProblemIds.push(saveAnsweredProblem.problemId);
    } else {
      const ind = this.initialSaveAnsweredProblemIds.findIndex(it => it === target.problemId);
      this.initialSaveAnsweredProblemIds.splice(ind, 1);
    }
  }

  cancel() {
    this.disabled$ = of(true);
    this.dialogRef.close(false);
  }

  save() {
    this.disabled$ = of(true);
    this.signedInUser$.pipe(take(1)).subscribe(user => {
      const request: CommonIdSaveAnsweredProblemsRequest = {
        userId: user.id,
        problems: this.saveAnsweredProblems
      };
      Log.debug(this.LOG_SOURCE, 'save Answered Problems', request);
      this.store.dispatch(CommonIdAnsweredProblemActions.commonIdSaveAnsweredProblems({ request }));
      this.store
        .select(CommonIdAnsweredProblemSelectors.getCommonIdSaveAnsweredProblemsResult)
        .pipe(
          filter(it => it != null),
          take(1)
        )
        .subscribe(result => {
          Log.debug(this.LOG_SOURCE, `save Answered Problems result: `, result);
          if (result.success) {
            this.store.dispatch(
              dispatchInfoMessage({
                message: `解答済み問題の登録が完了しました`
              })
            );
            const allAddedFlag = !this.saveAnsweredProblems.map(problem => problem.isAdd).includes(false);
            this.dialogRef.close(allAddedFlag);
          } else {
            Log.warn(this.LOG_SOURCE, `save Answered Problems error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
            this.store.dispatch(
              dispatchInfoMessage({
                message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
              })
            );
            this.dialogRef.close();
          }

          this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdFindAnsweredProblems());
          this.store.dispatch(CommonIdAnsweredProblemActions.initializeCommonIdSaveAnsweredProblemsState());
        });
    });
  }

  private setUpAnsweredProblems(
    playlistThemes:
      | ThemeForDisplay<ReadableEnglishPlaylistProblem>[]
      | ThemeForDisplay<ReadableSciencePlaylistProblem>[]
      | ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>[]
      | ThemeForDisplay<ReadableHistoryPlaylistProblem>[],
    answeredProblems: CommonIdAnsweredProblems
  ) {
    playlistThemes.map(playlistTheme => {
      let answeredProblemCount = 0;
      playlistTheme.problems.map(problem => {
        const saveAnsweredProblem: CommonIdSaveAnsweredProblem = {
          problemId: problem.id,
          isAdd:
            answeredProblems.problems !== undefined &&
            answeredProblems.problems.find(answeredProblem => answeredProblem.pId === problem.id) !== undefined
        };

        if (saveAnsweredProblem.isAdd) {
          answeredProblemCount++;
          this.initialSaveAnsweredProblemIds.push(saveAnsweredProblem.problemId);
        }

        this.saveAnsweredProblems.push(saveAnsweredProblem);
      });
      const themeForDialog = playlistTheme as ThemeForDialog<ReadablePlaylistProblem>;
      themeForDialog.answeredProblemCount = answeredProblemCount;
      themeForDialog.toggleCheck = themeForDialog.answeredProblemCount === themeForDialog.problemCount;
      this.themes.push(themeForDialog);
      this.themeExpands.push(false);
    });

    this.totalAnsweredCount = this.initialSaveAnsweredProblemIds.length;
    this.totalProblemCount = this.saveAnsweredProblems.length;
    this.updateToggleStatus();

    this.loading$ = of(false);
    this.disabled$ = of(false);
  }

  toggle(theme: ThemeForDialog<ReadablePlaylistProblem>, event: { problemId: string; isAdd: boolean }) {
    if (event.problemId) {
      this.changeAnsweredStatus(event);
      theme.answeredProblemCount += event.isAdd ? 1 : -1;
    } else {
      theme.problems.map(problem => this.changeAnsweredStatus({ problemId: problem.id, isAdd: event.isAdd }));
      theme.answeredProblemCount = event.isAdd ? theme.problemCount : 0;
    }
    theme.toggleCheck =
      theme.answeredProblemCount === theme.problemCount ? true : theme.answeredProblemCount === 0 ? false : theme.toggleCheck;
    this.updateToggleStatus();
  }

  updateToggleStatus() {
    const answered = this.saveAnsweredProblems.filter(problem => problem.isAdd);
    // totalAnsweredCount を更新する
    this.totalAnsweredCount = answered.length;
    this.progress = this.totalAnsweredCount / this.totalProblemCount;
  }

  isAddedProblem(problemId) {
    return this.saveAnsweredProblems.find(it => it.problemId === problemId).isAdd;
  }
}
