import { AppError } from './app-error';
import { FirestoreErrorInfo, ErrorInfo } from './error-info';
import Firebase from 'firebase/compat';

const errorCodeMapping: { [key: string]: ErrorInfo } = {
  'cancelled': FirestoreErrorInfo.CANCELLED,
  'unknown': FirestoreErrorInfo.UNKNOWN,
  'invalid-argument': FirestoreErrorInfo.INVALID_ARGUMENT,
  'deadline-exceeded': FirestoreErrorInfo.DEADLINE_EXCEEDED,
  'not-found': FirestoreErrorInfo.NOT_FOUND,
  'already-exists': FirestoreErrorInfo.ALREADY_EXISTS,
  'permission-denied': FirestoreErrorInfo.PERMISSION_DENIED,
  'resource-exhausted': FirestoreErrorInfo.RESOURCE_EXHAUSTED,
  'failed-precondition': FirestoreErrorInfo.FAILED_PRECONDITION,
  'aborted': FirestoreErrorInfo.ABORTED,
  'out-of-range': FirestoreErrorInfo.OUT_OF_RANGE,
  'unimplemented': FirestoreErrorInfo.UNIMPLEMENTED,
  'internal': FirestoreErrorInfo.INTERNAL,
  'unavailable': FirestoreErrorInfo.UNAVAILABLE,
  'data-loss': FirestoreErrorInfo.DATA_LOSS,
  'unauthenticated': FirestoreErrorInfo.UNAUTHENTICATED
};

export class FirestoreError extends AppError {
  constructor(info: ErrorInfo, cause?: string | Error) {
    super(info.code, info.message, cause);
    Object.setPrototypeOf(this, FirestoreError.prototype);
  }

  static from(err: Firebase.FirebaseError): FirestoreError {
    const info = errorCodeMapping[err.code];
    if (info) return new FirestoreError(info, err);
    return FirestoreError.unknown(err);
  }

  static unknown(cause: string | Error): FirestoreError {
    return new FirestoreError(FirestoreErrorInfo.UNKNOWN, cause);
  }
}
