import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { Authority, HighSchool, Prefecture } from 'src/app/models/common-data';
import { enter } from 'src/app/resources/animations';
import { Log } from 'src/app/utils/log';
import { RootState } from 'src/app/reducers';
import { initializeCreateUserState } from 'src/app/actions/user.actions';
import { dispatchInfoMessage } from 'src/app/actions/core.actions';
import { PREFECTURES } from '../../../resources/config';
import { CreateJukuData } from '../../../models/juku';
import { createJuku, initializeCreateJukuState } from 'src/app/actions/juku.actions';
import { getCreateJukuResult } from 'src/app/selectors/juku.selectors';

@Component({
  selector: 'app-add-juku-dialog',
  templateUrl: './add-juku-dialog.component.html',
  styleUrls: ['./add-juku-dialog.component.scss'],
  animations: [enter]
})
export class AddJukuDialogComponent implements OnInit, OnDestroy {
  userAuthorityObunshaId: number;
  jukuForm: UntypedFormGroup;
  highSchools: HighSchool[];
  prefectures: Prefecture[];
  authorities: Authority[];
  defaultOrganization: string;
  defaultSchool: string;
  defaultSchoolId: string;
  schoolVisibleFlag: boolean;
  dialogTypeAccounts: string;
  dialogType: string;
  dialogTitle: string;
  jukuCreating = false;
  errorMessage = '';
  private LOG_SOURCE = this.constructor.name;

  /** two-way binding */
  adminDisabled = true;

  constructor(private store: Store<RootState>, private dialogRef: MatDialogRef<AddJukuDialogComponent>) {}

  ngOnInit() {
    this.prefectures = PREFECTURES;
    this.dialogTitle = '塾・予備校を登録';

    this.setUpForms();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeCreateUserState());
  }

  createJuku() {
    if (this.jukuForm.invalid) {
      this.jukuForm.markAllAsTouched();
      return;
    }
    Log.debug(this.LOG_SOURCE, '塾・予備校を作成します');
    this.disableForms();
    this.errorMessage = '';

    const jukuName: string = this.jukuForm.get('jukuName').value;
    const jukuSubName: string = this.jukuForm.get('jukuSubName').value;
    const jukuCode: string = this.jukuForm.get('jukuCode').value;
    const prefectureId: string = this.jukuForm.get('prefectureId').value;

    const juku: CreateJukuData = {
      jukuName,
      jukuSubName,
      jukuCode,
      prefectureId
    };

    this.store.dispatch(createJuku({ juku }));

    this.store
      .select(getCreateJukuResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeCreateJukuState());
        Log.debug(this.LOG_SOURCE, `create juku result: `, result);
        if (result.success) {
          const message = `${jukuName} を登録しました`;
          this.dialogRef.close(true);
          this.store.dispatch(
            dispatchInfoMessage({
              message
            })
          );
          return;
        }

        Log.warn(this.LOG_SOURCE, `create juku error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
        this.enableForms();
        this.errorMessage = result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました';
      });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  // set ups or private method ---------------------------------------------------------------
  private setUpForms() {
    this.jukuForm = new UntypedFormGroup({
      jukuName: new UntypedFormControl('', [Validators.required]),
      jukuSubName: new UntypedFormControl('', []),
      jukuCode: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-9]{5}$/)]),
      prefectureId: new UntypedFormControl('', [Validators.required])
    });
  }

  private disableForms() {
    Object.keys(this.jukuForm.controls).forEach(ctrlName => {
      this.jukuForm.get(ctrlName).disable();
    });
    this.jukuCreating = true;
  }

  private enableForms() {
    Object.keys(this.jukuForm.controls).forEach(ctrlName => {
      this.jukuForm.get(ctrlName).enable();
    });
    this.jukuCreating = false;
  }
}
