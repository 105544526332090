import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dialog-basic',
  templateUrl: './dialog-basic.component.html',
  styleUrls: ['./dialog-basic.component.scss']
})
export class DialogBasicComponent implements OnInit {
  /**
   * ダイアログのタイトル
   */
  @Input() dialogTitle: string;
  @Input() dialogIcon: string;
  @Input() dialogIconOutline: false;

  /**
   * 現在処理中の場合にtrue。 bodyにある要素のアクセスを禁止する見た目に制御するためのフラグです
   */
  @Input() isLoading = false;

  constructor() {}
  ngOnInit() {}
}
