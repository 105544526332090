import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-location-back',
  templateUrl: './button-location-back.component.html',
  styleUrls: ['./button-location-back.component.scss']
})
export class ButtonLocationBackComponent implements OnInit {
  @Output() backClick = new EventEmitter();
  constructor() {}
  ngOnInit() {}
}
