import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-basic',
  templateUrl: './section-basic.component.html',
  styleUrls: ['./section-basic.component.scss']
})
export class SectionBasicComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
