import { Action, createReducer, on } from '@ngrx/store';
import * as TrySearchActions from '../actions/try-search.action';
import { KakomonImages } from '../models/try-search';

export const trySearchFeatureKey = 'trySearch';

export interface State {
  kakomonImages: KakomonImages;
}

export const initialState: State = {
  kakomonImages: null
};

export const trySearchReducer = createReducer(
  initialState,
  on(TrySearchActions.findProblemImagesSuccess, (state, { kakomonImages }) => ({
    ...state,
    kakomonImages
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return trySearchReducer(state, action);
}
