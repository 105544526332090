import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';

import { RootState } from '../reducers';
import { getSignedInUser } from '../selectors/auth.selectors';
import { findSignedInUser } from '../actions/auth.actions';
import { Log } from '../utils/log';
import { navigate } from '../actions/core.actions';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(protected store: Store<RootState>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.findSignedInUserIfNeeded();

    return this.store.select(getSignedInUser).pipe(
      filter(it => it != null),
      take(1),
      map(user => {
        const authenticated = user !== 'none';

        if (!authenticated) {
          Log.warn(this.constructor.name, `認証されていません`);
          const url = RoutingPathResolver.resolveSignIn();
          this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));
        }

        return authenticated;
      })
    );
  }

  protected findSignedInUserIfNeeded() {
    this.store
      .select(getSignedInUser)
      .pipe(take(1))
      .subscribe(user => {
        if (user == null) this.store.dispatch(findSignedInUser());
      });
  }
}
