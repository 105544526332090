import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss'],
  animations: [trigger('slideFade', [transition(':leave', [animate('0.2s linear', style({ height: 0, opacity: 0 }))])])]
})
export class AlertBoxComponent implements OnInit {
  @Input() type: 'success' | 'info' | 'warning' | 'danger' | 'tips' | 'premium' = 'success';
  @Input() closeable = true;
  @Input() alignItems: 'center' | 'flex-start' | 'flex-end' = 'center';
  isVisible = true;

  constructor() {}
  ngOnInit() {}

  closeAlert() {
    // Xボタンをクリックしたときに呼び出されるメソッド
    this.isVisible = false;
  }

  getIconName(): string {
    switch (this.type) {
      case 'success':
        return 'check_circle';
      // case 'info': return 'info';
      case 'danger':
        return 'warning';
      case 'warning':
        return 'warning';
      default:
        return 'error';
    }
  }
}
