<app-wrapper size="lg" isFitWithSmallScreen="true">
  <div @enter *ngIf="(loaded$ | async) === true">
    <app-content-row>
      <app-wrapper size="fluid" isFitWithSmallScreen="true">
        <app-card-content [showSeparator]="true">
          <app-wrapper size="fluid">
            <app-content-row>
              <section class="section-basic section-premium">
                <h1>入試正解デジタル<br class="md:hidden" />プレミアム</h1>
                <h2>プラン選択</h2>
                <div class="flex flex-col md:flex-row gap-6">
                  <!-- <div class="col is-highlighted">
                    <i
                      ><span>期間<br />限定</span></i
                    >
                    <div class="col-content">
                      <div class="min-h-[4rem] flex items-center justify-center">
                        <p class="plan-text">
                          ＼短期集中／<br />
                          <b class="en">3</b>ヶ月プラン
                        </p>
                      </div>
                      <p class="plan-price"><span class="en en-black">¥3,500</span><small>(税込)</small></p>
                      <div class="plan-bar justify-center">
                        <p><b class="text-md">24年3月末までの限定販売!</b></p>
                      </div>
                      <div class="plan-date">
                        <p>サービス利用開始から</p>
                        <p>92日間</p>
                      </div>
                    </div>
                    <button mat-flat-button (click)="getStripeCheckoutUrl(shortTerm3MonthsPlan.id)" [disabled]="isPremiumUser$ | async">
                      購入画面へ
                    </button>
                  </div> -->
                  <div class="col is-highlighted">
                    <div class="col-content">
                      <div class="min-h-[4rem] flex items-center justify-center">
                        <p class="plan-text"><b class="en">6</b>ヶ月プラン</p>
                      </div>
                      <p class="plan-price"><span class="en en-black">¥5,500</span><small>(税込)</small></p>
                      <div class="plan-bar">
                        <p>ひと月あたり</p>
                        <p><b class="en en-black text-lg">￥917</b> (税込)</p>
                      </div>
                      <div class="plan-date">
                        <p>サービス利用開始から</p>
                        <p>183日間</p>
                      </div>
                    </div>
                    <button mat-flat-button (click)="getStripeCheckoutUrl(biannualPlan.id)" [disabled]="isPremiumUser$ | async">
                      購入画面へ
                    </button>
                  </div>
                  <div class="col is-highlighted">
                    <i><span>おトク!</span></i>
                    <div class="col-content">
                      <div class="min-h-[4rem] flex items-center justify-center">
                        <p class="plan-text"><b class="en">12</b>ヶ月プラン</p>
                      </div>
                      <p class="plan-price"><span class="en en-black">¥7,700</span><small>(税込)</small></p>
                      <div class="plan-bar">
                        <p>ひと月あたり</p>
                        <p><b class="en en-black text-lg">￥642</b> (税込)</p>
                      </div>
                      <div class="plan-date">
                        <p>サービス利用開始から</p>
                        <p>365日間</p>
                      </div>
                    </div>
                    <button mat-flat-button (click)="getStripeCheckoutUrl(annualPlan.id)" [disabled]="isPremiumUser$ | async">
                      購入画面へ
                    </button>
                  </div>
                </div>
                <p class="section-price-notion">
                  <strong>【購入時のご注意】</strong><br />
                  ※ 購入いただく前に、ご希望の入試問題が掲載されているか「<a
                    class="text-primary underline hover:no-underline"
                    (click)="gotoSearchUniv()"
                    >大学から探す</a
                  >」より該当の大学を検索して、掲載有無をご確認ください。 詳細は、<a
                    class="text-primary underline hover:no-underline"
                    (click)="gotoFaq('faq-11')"
                    >こちらのFAQ</a
                  >をご確認ください。<br />
                  ※ 購入いただいたにもかかわらず、プレミアム機能が使えない場合はお手数ですが
                  <a class="text-primary underline hover:no-underline" (click)="gotoFaq('faq-12')">こちらのFAQ</a>
                  をご確認ください。
                </p>
              </section>
              <section class="section-basic section-price">
                <h2>機能比較</h2>
                <table class="table-price">
                  <thead>
                    <tr>
                      <th class="w-4/12 transparent"></th>
                      <th class="w-4/12" data-cell-type="free">無料会員</th>
                      <th class="w-4/12" data-cell-type="premium">入試正解デジタル<br />プレミアム</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        過去問閲覧<br />
                        <small class="text-xs md:text-sm inline-block leading-snug">（大学から探す・分野から探す）</small>
                      </th>
                      <td data-cell-type="free">2023年度<wbr />入試</td>
                      <td data-cell-type="premium">無制限<sup>※1</sup></td>
                    </tr>
                    <tr>
                      <th>おすすめ問題<br class="md:hidden" />セット</th>
                      <td class="" data-cell-type="free">
                        お試しのみ
                      </td>
                      <td class="" data-cell-type="premium">
                        無制限
                      </td>
                    </tr>
                    <tr>
                      <th>
                        共通テスト対策<br />
                        予想問題セット<sup class="text-xs" style="top: -0.2em;">※2</sup>
                      </th>
                      <td class="" data-cell-type="free">
                        -
                      </td>
                      <td class="" data-cell-type="premium">
                        無制限
                      </td>
                    </tr>
                    <tr>
                      <th>
                        マイリスト
                      </th>
                      <td data-cell-type="free">
                        <img srcset="/assets/images/lp/student/check-silver.svg" alt="✓" />
                      </td>
                      <td data-cell-type="premium">
                        <img srcset="/assets/images/lp/student/check-gold.svg" alt="✓" />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p class="section-price-notion">
                  ※1【対応科目と掲載年度】<br />
                  数学、物理、化学：2015年度～2023年度<br />
                  生物：2018年度～2023年度<br />
                  日本史、世界史：2019年度～2023年度<br />
                  英語、国語、地理、政治・経済：2020年度～2023年度<br />
                  年度についての詳細は<button class="text-primary underline hover:no-underline" (click)="gotoFaq('faq-10')">
                    こちらのFAQ</button
                  >をご覧ください。<br />
                  著作権等の関係で、一部閲覧できない問題もございます。<br />
                  （2024年度入試は、2024年12月頃に搭載予定です。）<br />
                  ※2 共通テスト対策予想問題セットはおすすめ問題セットの中で掲載しています。
                </p>
              </section>
              <section class="section-basic section-flow">
                <h2>ご購入の流れ</h2>
                <div class="section-basic-inner">
                  <div class="flow-list">
                    <section class="flow-item">
                      <div class="flow-image">
                        <i class="en en-black">01</i>
                        <img srcset="/assets/images/lp/student/step01.png" alt="" />
                      </div>
                      <h3 class="flow-item-title">
                        <strong>プランを選択<br class="hidden md:block" /><small class="hidden md:block">&nbsp;</small> </strong>
                      </h3>
                      <div class="flow-item-body">
                        <p class="mb-1">
                          ログイン後ご希望のプランを選択します。 （外部の<strong>Stripeサイト</strong>に移動します。）
                        </p>
                        <p class="text-sm">※ログインには「旺文社まなびID」へのご登録（無料）が必要です。</p>
                      </div>
                    </section>
                    <section class="flow-item">
                      <div class="flow-image">
                        <i class="en en-black">02</i>
                        <img srcset="/assets/images/lp/student/step02.png" alt="" />
                      </div>
                      <h3 class="flow-item-title">
                        <strong>購入情報入力<br /><small class="font-normal">（保護者様ご入力箇所）</small> </strong>
                      </h3>
                      <div class="flow-item-body">
                        <p>
                          <strong class="font-bold">＜クレジットカード支払い＞</strong><br />
                          <span class="text-sm">メールアドレス（領収書送信先）とカード番号を入力してください。</span>
                        </p>
                        <p>
                          <strong class="font-bold">＜コンビニ支払い＞</strong><br />
                          <span class="text-sm"
                            >支払いコードと確認番号を受け取ります。 その後、コンビニエンスストアでお支払いください。</span
                          >
                        </p>
                      </div>
                    </section>
                    <section class="flow-item">
                      <div class="flow-image">
                        <i class="en en-black">03</i>
                        <img srcset="/assets/images/lp/student/step03.png" alt="" />
                      </div>
                      <h3 class="flow-item-title">
                        <strong>ご利用を開始<br class="hidden md:block" /><small class="hidden md:block">&nbsp;</small></strong>
                      </h3>
                      <div class="flow-item-body">
                        <p class="">
                          購入完了後から<strong class="font-bold">「入試正解デジタルプレミアム」</strong>をお使いいただけます。
                        </p>
                      </div>
                    </section>
                  </div>
                  <div class="flow-notion">
                    <h4>お支払いについて</h4>
                    <ul>
                      <li>お支払い方法は「クレジットカード」または「コンビニ」決済に対応しています。</li>
                      <li>ご利用できるクレジットカードの種類は、Visa / Mastercard / JCB / American Express です。</li>
                      <li>ご利用できるコンビニエンスストアは、ファミリーマート / ローソン / ミニストップ / セイコーマートです。</li>
                      <li>
                        未成年者によるクレジットカードのご購入の場合は、保護者が保護者名義のカードにてお申し込みください。
                      </li>
                      <li>当サイトではストライプジャパン株式会社 が提供する決済システム「Stripe」を利用しています。</li>
                      <li>
                        ご入力いただいたカード情報等は当サイトのサーバーを経由せず、SSL暗号化通信で Stripe
                        の決済システムに直接登録され、ストライプジャパン株式会社にて厳重に管理され安全性を確保しております。
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </app-content-row>
          </app-wrapper>
        </app-card-content>
      </app-wrapper>
    </app-content-row>

    <app-content-row>
      <app-wrapper size="fluid" isFitWithSmallScreen="true">
        <app-card-content [showSeparator]="true">
          <app-wrapper size="fluid">
            <app-content-row>
              <section class="section-basic section-faq">
                <h1>よくあるご質問</h1>
                <div class="section-basic-inner">
                  <h3>サービス全般について</h3>
                  <div class="-mx-2 mx-0">
                    <div class="logs" *ngFor="let faq of category1Faqs">
                      <div [id]="faq.id" class="mb-4 md:mb-6">
                        <app-faq-list-item [isExpanded]="faq.isExpanded" [questionTitle]="faq.title">
                          <div class="mt-0" [innerHTML]="faq.body"></div>
                        </app-faq-list-item>
                      </div>
                    </div>
                  </div>
                  <h3>掲載内容について</h3>
                  <div class="-mx-2 mx-0">
                    <div class="logs" *ngFor="let faq of category2Faqs">
                      <div [id]="faq.id" class="mb-4 md:mb-6">
                        <app-faq-list-item [isExpanded]="faq.isExpanded" [questionTitle]="faq.title">
                          <div class="mt-0" [innerHTML]="faq.body"></div>
                        </app-faq-list-item>
                      </div>
                    </div>
                  </div>
                  <h3>プレミアムについて</h3>
                  <div class="-mx-2 mx-0">
                    <div class="logs" *ngFor="let faq of category3Faqs">
                      <div [id]="faq.id" class="mb-4 md:mb-6">
                        <app-faq-list-item [isExpanded]="faq.isExpanded" [questionTitle]="faq.title">
                          <div class="mt-0" [innerHTML]="faq.body"></div>
                        </app-faq-list-item>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </app-content-row>
          </app-wrapper>
        </app-card-content>
      </app-wrapper>
    </app-content-row>
  </div>
</app-wrapper>

<div class="progress-48" *ngIf="(loaded$ | async) === false">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
