import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { navigate, openWindow, setTitle } from '../../../../actions/core.actions';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { RootState } from '../../../../reducers';
import * as CommonIdInformationSelectors from 'src/app/selectors/common-id/common-id-information.selectors';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';

import { CommonIdInformation, CommonIdInformationResponse } from 'src/app/models/common-id/common-id-information';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { Dates } from '../../../../utils/dates';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { CommonIdContactUtil } from 'src/app/utils/common-id/common-id-contact-util';

import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { enter } from '../../../../resources/animations';

@Component({
  selector: 'app-common-id-information-detail',
  templateUrl: './information-detail.component.html',
  styleUrls: ['./information-detail.component.scss'],
  animations: [enter]
})
export class CommonIdInformationDetailComponent implements OnInit, AfterViewInit {
  information$: Observable<CommonIdInformation>;
  signedInUser$: Observable<CommonIdUser>;

  private LOG_SOURCE = this.constructor.name;
  private title = 'お知らせ詳細';

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUp();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  backToList() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdInformation() }));
  }

  sanitize(text) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  gotoContact(linkUrl: string) {
    this.signedInUser$.subscribe(user => {
      const url = CommonIdContactUtil.getGoogleFormUrl(linkUrl, user.commonId, this.window.navigator.userAgent);
      this.store.dispatch(openWindow({ url }));
    });
  }

  private setUp() {
    const informationId: string = this.activatedRoute.snapshot.paramMap.get('informationId');
    this.information$ = this.getInformationDetail(informationId).pipe(
      filter(it => it != null),
      take(1),
      map(result => this.getInformation(result))
    );

    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(
      filter<CommonIdUser>(it => it != null && it !== 'none'),
      take(1)
    );
  }

  private getInformationDetail(informationId: string): Observable<CommonIdInformationResponse> {
    return this.store.select(CommonIdInformationSelectors.getCommonIdInformations).pipe(
      filter(it => it != null),
      take(1),
      map(result => result.response.filter(info => info.id === informationId)[0])
    );
  }

  private getInformation(information: CommonIdInformationResponse): CommonIdInformation {
    const date = Dates.simple4YmdStringFromIso(information.publishedAt);
    return {
      id: information.id,
      isNew: null,
      categoryName: information.categoryName,
      date,
      title: information.title,
      body: information.content,
      importantFlag: information.importantFlag,
      linkLabel: information.linkLabel,
      linkUrl: information.linkUrl
    } as CommonIdInformation;
  }
}
