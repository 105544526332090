import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, concatMap, mapTo, exhaustMap, withLatestFrom, mergeMap } from 'rxjs/operators';

import * as EventLogActions from '../actions/event-log.actions';
import { EventLogService } from '../services/event-log.service';
import { FirebaseStorageError } from '../errors/firebase-storage-error';
import { GeneralError } from '../errors/general-error';
import { dispatchAppError } from '../actions/core.actions';
import { Log } from '../utils/log';
import { getSignedInUser } from '../selectors/auth.selectors';
import { RootState } from '../reducers';
import { FirestoreError } from '../errors/firestore-error';

@Injectable()
export class EventLogEffects {
  private LOG_SOURCE = this.constructor.name;

  findSearchLogFileMetadataList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventLogActions.findSearchLogFileMetadataList),
      switchMap(() =>
        this.eventLogService.findSearchLogFileMetadataList().pipe(
          map(metadataList => EventLogActions.findSearchLogFileMetadataListSuccess({ metadataList })),
          catchError(e =>
            of(
              EventLogActions.findSearchLogFileMetadataListFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  resolveSearchLogFileObjectUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventLogActions.resolveSearchLogFileObjectUrl),
      mergeMap(action =>
        this.eventLogService.getSearchLogFileObjectUrl(action.filePath, action.isCRLF).pipe(
          map(objectUrl => EventLogActions.resolveSearchLogFileObjectUrlSuccess({ filePath: action.filePath, objectUrl })),
          catchError(e => {
            const error = e instanceof FirebaseStorageError ? e : GeneralError.unknown(e);
            return of(
              EventLogActions.resolveSearchLogFileObjectUrlFailure({ filePath: action.filePath }),
              dispatchAppError({ source: this.LOG_SOURCE, error })
            );
          })
        )
      )
    )
  );

  reportAppEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventLogActions.reportAppEvent),
      concatMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(getSignedInUser))))),
      concatMap(([action, signedInUser]) => {
        if (signedInUser === 'none') {
          Log.warn(this.LOG_SOURCE, `sign in していません`);
          return of(EventLogActions.ignoreAppEvent());
        }
        if (signedInUser.isAdmin) {
          Log.debug(this.LOG_SOURCE, 'admin ユーザーのため event を送信しません');
          return of(EventLogActions.ignoreAppEvent());
        }
        return this.eventLogService.reportAppEvent(action.event).pipe(
          mapTo(EventLogActions.reportAppEventSuccess()),
          catchError(e => {
            Log.error(this.LOG_SOURCE, `AppEvent の送信に失敗しました. event, error: `, action.event, e);
            return of(EventLogActions.reportAppEventFailure());
          })
        );
      })
    )
  );

  reportAppLaunchEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventLogActions.reportAppLaunchEvent),
      exhaustMap(() =>
        this.eventLogService.reportAppEvent({ type: 'launch-app' }).pipe(
          mapTo(EventLogActions.reportAppLaunchEventSuccess()),
          catchError(e => {
            Log.error(this.LOG_SOURCE, `LaunchAppEvent の送信に失敗しました. error: `, e);
            return of(EventLogActions.reportAppLaunchEventFailure());
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<RootState>, private eventLogService: EventLogService) {}
}
