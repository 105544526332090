<div @enter *ngIf="(isDataLoading$ | async) === false; else loadingProgress">
  <app-wrapper size="xl">
    <app-content-row>
      <app-breadcrumbs *ngIf="isAdmin" (breadcrumbsClick)="breadcrumbsClickHandler($event)" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
      <app-button-location-back *ngIf="!isAdmin" (backClick)="goOrganizationAdminPlans()"
        >プラン・アカウント管理 - プラン一覧へ戻る</app-button-location-back
      >
    </app-content-row>
  </app-wrapper>

  <app-wrapper size="xl" isFitWithSmallScreen="true">
    <app-content-row>
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon class="card-content-head-icon">outlined_flag</mat-icon>
          {{ planName }}
          <div class="card-content-head-util">
            <div class="text-primary font-bold">{{ memberNum }} / {{ memberCount }}人</div>
          </div>
        </app-card-content-head>

        <app-card-content-nav
          (menuClick)="menuClickHandler($event)"
          [menus]="[
            {
              key: 'fact_check_black',
              icon: 'fact_check_black',
              fontSet: 'material-icons-outlined',
              label: 'プラン契約状況・履歴',
              isActive: false
            },
            {
              key: 'person_add',
              icon: 'person_add',
              label: 'メンバー割当管理',
              isActive: true
            }
          ]"
        ></app-card-content-nav>

        <app-wrapper size="fluid">
          <app-content-row>
            <ng-container *ngIf="!isEndPlan; else endedPlanMessageBlock">
              <div *ngIf="!isMaxMember; else maxMemberMessageBlock">
                残り
                <span class="font-bold text-primary">{{ memberCount - memberNum }}メンバー</span>
                の割当が可能です。割り当てを行うと自動で招待メールが送信されます。
              </div>
              <ng-template #maxMemberMessageBlock>
                メンバーが上限に達しました。これ以上はメンバーを割当することはできません。上限を増やす場合は
                <a [href]="memberContactUrl" target="_blank" class="text-primary underline hover:no-underline">お問い合せフォーム</a>
                よりご連絡ください。
              </ng-template>
            </ng-container>
            <ng-template #endedPlanMessageBlock>
              ご契約のプランは終了しています。 このプランの再開をご希望の場合は
              <a [href]="memberContactUrl" target="_blank" class="text-primary underline hover:no-underline">お問い合せフォーム</a>
              よりご連絡ください。
            </ng-template>
          </app-content-row>
          <ng-container *ngIf="!isAllPlan; else allPlanBlock">
            <app-content-row *ngFor="let assignment of assignments">
              <app-content-no-data *ngIf="memberNum < 1">
                <span>メンバーは未割当です</span>
                <button
                  mat-flat-button
                  color="primary"
                  class="mt-4"
                  *ngIf="!isMaxMember && !isNoAssignableMember && !isEndPlan"
                  (click)="assignMember(assignment.subjectId)"
                >
                  <mat-icon class="">add_circle_outline</mat-icon>
                  メンバーを割当
                </button>
              </app-content-no-data>

              <!-- TODO: 0人の場合にこのエリアごとhidden -->
              <app-content-row *ngIf="memberNum > 0">
                <app-section-basic>
                  <app-section-basic-head>
                    メンバー割当
                    <div class="section-basic-head-util">
                      <div class="text-primary font-bold">{{ memberNum }}人</div>
                    </div>
                  </app-section-basic-head>
                  <app-wrapper size="fluid">
                    <app-content-row>
                      <div class="mt-4 -mb-4">
                        <app-plan-members *ngIf="memberNum > 0">
                          <ng-container *ngFor="let member of assignment.members">
                            <app-plan-members-item *ngIf="isEndPlan" [isDeletable]="false">
                              {{ member.name }}
                            </app-plan-members-item>
                            <app-plan-members-item
                              *ngIf="!isEndPlan"
                              [isDeletable]="true"
                              (click)="unAssignMember(assignment.subjectId, member.id)"
                            >
                              {{ member.name }}
                            </app-plan-members-item>
                          </ng-container>
                          <app-plan-members-add
                            *ngIf="!isMaxMember && !isNoAssignableMember && !isEndPlan"
                            (click)="assignMember(assignment.subjectId)"
                          ></app-plan-members-add>
                        </app-plan-members>
                      </div>
                    </app-content-row>
                  </app-wrapper>
                </app-section-basic>
              </app-content-row>

              <div class="mt-4">
                <app-tips-content>
                  <div>
                    割当可能メンバーが表示されない場合は、
                    <button (click)="goMembers()" class="text-primary underline hover:no-underline">
                      プラン・アカウント管理-所属メンバー一覧
                    </button>
                    よりメンバーの登録を行ってください。
                  </div>
                  <div>
                    メンバー割当は1ユーザー毎に1つのプランまでです。割当したいメンバーが表示されない場合は、そのメンバーに割り当てられているプランを解除してください。
                  </div>
                  <div>
                    プラン登録に関する 「<button (click)="goHelp()" class="text-primary underline hover:no-underline">よくある質問</button
                    >」 または、 「<button (click)="goHelp()" class="text-primary underline hover:no-underline">
                      手順のマニュアル</button
                    >」 もご参考ください。
                  </div>
                </app-tips-content>
              </div>
            </app-content-row>
          </ng-container>
          <ng-template #allPlanBlock>
            <app-content-row *ngFor="let assignment of assignments">
              <app-section-basic>
                <app-section-basic-head>
                  <mat-icon class="section-basic-head-icon">outlined_flag</mat-icon>
                  {{ assignment.subjectName }} のメンバー割当
                  <div class="section-basic-head-util">
                    <div class="text-primary font-bold">{{ assignment.members.length }}人</div>
                  </div>
                </app-section-basic-head>
                <app-wrapper size="fluid">
                  <app-content-row>
                    <app-plan-members>
                      <ng-container *ngFor="let member of assignment.members">
                        <app-plan-members-item *ngIf="isEndPlan" [isDeletable]="false">
                          {{ member.name }}
                        </app-plan-members-item>
                        <app-plan-members-item
                          *ngIf="!isEndPlan"
                          [isDeletable]="true"
                          (click)="unAssignMember(assignment.subjectId, member.id)"
                        >
                          {{ member.name }}
                        </app-plan-members-item>
                      </ng-container>
                      <app-plan-members-add
                        *ngIf="!isMaxMember && !isNoAssignableMember && !isEndPlan"
                        (click)="assignMember(assignment.subjectId)"
                      ></app-plan-members-add>

                      <!-- TODO: メンバー登録がある場合は hidden -->
                      <div
                        *ngIf="assignment.members.length === 0 && (isMaxMember || isNoAssignableMember || isEndPlan)"
                        class="pb-4 pr-4 text-sub"
                      >
                        割り当てられたメンバーは居ません
                      </div>
                    </app-plan-members>
                  </app-content-row>
                </app-wrapper>
              </app-section-basic>
            </app-content-row>
            <app-content-row>
              <div class="mt-4">
                <app-tips-content>
                  <div>
                    割当可能メンバーが表示されない場合は、
                    <button (click)="goMembers()" class="text-primary underline hover:no-underline">
                      プラン・アカウント管理-所属メンバー一覧
                    </button>
                    よりメンバーの登録を行ってください。
                  </div>
                  <div>
                    メンバー割当は1ユーザー毎に1つのプランまでです。割当したいメンバーが表示されない場合は、そのメンバーに割り当てられているプランを解除してください。
                  </div>
                  <div>
                    プラン登録に関する 「<button (click)="goHelp()" class="text-primary underline hover:no-underline">よくある質問</button
                    >」 または、 「<button (click)="goHelp()" class="text-primary underline hover:no-underline">
                      手順のマニュアル</button
                    >」 もご参考ください。
                  </div>
                </app-tips-content>
              </div>
            </app-content-row>
          </ng-template>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
  </app-wrapper>
</div>

<ng-template #loadingProgress>
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>
