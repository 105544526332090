<app-wrapper size="xl" @enter *ngIf="membersData$ | async; else loadingProgress" isFitWithSmallScreen="true">
  <app-content-row>
    <app-breadcrumbs *ngIf="isAdmin" (breadcrumbsClick)="breadcrumbsClickHandler($event)" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
  </app-content-row>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon" *ngIf="isAdmin">settings</mat-icon>
        <mat-icon class="card-content-head-icon" *ngIf="isOrganizationAdmin">manage_accounts</mat-icon>
        <strong class="font-bold">{{ schoolName }}</strong>
      </app-card-content-head>
      <app-card-content-nav
        (menuClick)="menuClickHandler($event)"
        [menus]="[
          {
            key: 'admin/plans',
            icon: 'outlined_flag',
            fontSet: 'material-icons-outlined',
            label: 'プラン一覧',
            isActive: false
          },
          {
            key: 'admin/members',
            icon: 'group',
            label: '所属メンバー一覧',
            isActive: true
          }
        ]"
      ></app-card-content-nav>
      <app-wrapper size="fluid">
        <!--        <app-content-row>-->
        <!--          <app-alert-box type="success">CSVのインポートが完了しました</app-alert-box>-->
        <!--        </app-content-row>-->
        <app-content-row>
          <div class="flex flex-wrap">
            <div>
              <p class="my-2 text-md pr-2">所属するメンバーの管理を行います</p>
            </div>
            <span class="mx-auto"></span>
            <div class="flex gap-2">
              <button mat-flat-button color="primary" *ngIf="(membersData$ | async)?.length > 0" (click)="addAccount()">
                <mat-icon class="">add_circle_outline</mat-icon>
                メンバーの登録
              </button>
              <div>
                <button mat-flat-button color="primary" (click)="goToMembersImport()">
                  <mat-icon class="">add_circle_outline</mat-icon>
                  CSVによる一括登録
                </button>
              </div>
            </div>
          </div>
        </app-content-row>
      </app-wrapper>

      <app-content-row>
        <app-wrapper size="fluid">
          <app-content-no-data *ngIf="(membersData$ | async)?.length === 0">
            <span>所属メンバーの登録がありません</span>
            <button mat-flat-button color="primary" class="mt-4" (click)="addAccount()">
              <mat-icon class="">add_circle_outline</mat-icon>
              メンバーの登録
            </button>
          </app-content-no-data>
        </app-wrapper>
        <app-wrapper size="fluid" isFitWithSmallScreen="true">
          <app-table-overflow>
            <table mat-table [dataSource]="membersData$ | async" class="w-full" *ngIf="(membersData$ | async)?.length > 0">
              <ng-container matColumnDef="memberName">
                <th mat-header-cell *matHeaderCellDef style="min-width: 150px;">氏名</th>
                <td mat-cell *matCellDef="let element" class="font-bold">{{ element.user.familyName }} {{ element.user.firstName }}</td>
              </ng-container>
              <ng-container matColumnDef="memberNameKana">
                <th mat-header-cell *matHeaderCellDef style="min-width: 150px;">ふりがな</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  {{ element.user.familyNameKana }} {{ element.user.firstNameKana }}
                </td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef style="min-width: 150px;">メールアドレス</th>
                <td mat-cell *matCellDef="let element" class="text-xs">
                  {{ element.user.email }}
                </td>
              </ng-container>
              <ng-container matColumnDef="memberType">
                <th mat-header-cell *matHeaderCellDef style="min-width: 200px;">権限</th>
                <td mat-cell *matCellDef="let element">
                  <div class="flex items-center">
                    <div class="text-sm">
                      <div *ngIf="element.user.isOrganizationAdmin">
                        <app-label-with-icon iconName="check_circle">団体管理ユーザー</app-label-with-icon>
                      </div>
                      <div *ngIf="!element.user.isOrganizationAdmin">一般ユーザー</div>
                    </div>
                    <div class="ml-2">
                      <button mat-flat-button color="primary" class="mat-button-mini" (click)="changeOrganizationAuthority(element.user)">
                        変更
                      </button>
                    </div>
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="assignedPlan">
                <th mat-header-cell *matHeaderCellDef style="min-width: 150px;">割当中のプラン</th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.planId">
                    <button
                      *ngIf="isAdmin || !element.user.isTrial"
                      (click)="planAssign(element.planId)"
                      class="text-primary underline hover:no-underline"
                    >
                      {{ element.planName }}
                    </button>
                    <div *ngIf="!isAdmin && element.user.isTrial">{{ element.planName }}</div>
                  </ng-container>
                  <ng-container *ngIf="element.planId === null">
                    <app-label-with-icon
                      iconName="info"
                      matTooltip="プラン一覧より、契約プランにメンバーを割り当ててください。"
                      (click)="plans()"
                      >未割当</app-label-with-icon
                    >
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="exclusion">
                <th mat-header-cell *matHeaderCellDef>除外</th>
                <td mat-cell *matCellDef="let element" class="text-sm">
                  <a href="#">このユーザーを団体から除外</a>
                </td>
              </ng-container>
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;">編集</th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <button
                    [disabled]="!isAdmin && element.user.isTrial"
                    mat-icon-button
                    class="icon-button-edit"
                    (click)="editAccount(element.user)"
                    *ngIf="!element.user.isTrial"
                  >
                    <mat-icon>create</mat-icon>
                  </button>
                </td>
              </ng-container>
              <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;">削除</th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <button mat-icon-button class="icon-button-delete" (click)="removeAccount(element.user)">
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </app-table-overflow>
        </app-wrapper>
      </app-content-row>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<ng-template #loadingProgress>
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>
