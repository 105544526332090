import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CallableFunction, Collection } from '../resources/app-firebase';
import { CreatePlanData, UpdatePlanData, DeletePlanData, Plan } from '../models/plan';
import { AssignPlanRequest } from '../models/assign-plan-request';
import { UnAssignPlanRequest } from '../models/un-assign-plan-request';
import { DeletePlanResponse } from '../models/delete-plan-response';
import { UpdateDisableRenewPlanAlertRequest } from '../models/update-disable-renew-plan-alert-request';
import { UpdateDisableRenewPlanAlertResponse } from '../models/update-disable-renew-plan-alert-response';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  findPlan(planId: string): Observable<Plan> {
    return this.afs
      .collection(Collection.PLAN)
      .doc<Plan>(planId)
      .get()
      .pipe(map(snapshot => (snapshot.exists ? snapshot.data() : {}) as Plan));
  }

  findPlans(organization: string, schoolId: string, subjectId: string): Observable<Plan[]> {
    return this.afs
      .collection<Plan>(Collection.PLAN, ref => {
        let query: CollectionReference | Query = ref;
        if (organization) query = query.where('organization', '==', organization);
        if (schoolId) query = query.where('schoolId', '==', schoolId);
        if (subjectId) query = query.where('subjectId', '==', subjectId);
        return query;
      })
      .get()
      .pipe(
        map(snapshot =>
          snapshot.empty
            ? []
            : snapshot.docs.map(doc => {
                const planId: string = doc.id;
                const data = doc.data();
                data.id = planId;
                return data as Plan;
              })
        ),
        map(plan => plan)
      );
  }

  createPlan(plan: CreatePlanData): Observable<Plan> {
    const callable = this.aff.httpsCallable<CreatePlanData, Plan>(CallableFunction.CREATE_PLAN);
    return callable(plan);
  }

  updatePlan(plan: UpdatePlanData): Observable<Plan> {
    const callable = this.aff.httpsCallable<UpdatePlanData, Plan>(CallableFunction.UPDATE_PLAN);
    return callable(plan);
  }

  deletePlan(plan: DeletePlanData): Observable<DeletePlanResponse> {
    const callable = this.aff.httpsCallable(CallableFunction.DELETE_PLAN);
    return callable(plan);
  }

  assignPlan(userIds: string[], planId: string, subjectId: string): Observable<string[]> {
    const req: AssignPlanRequest = { userIds, planId, subjectId };
    const callable = this.aff.httpsCallable(CallableFunction.ASSIGN_PLAN);
    return callable(req);
  }

  unAssignPlan(userIds: string[], planId: string, subjectId: string): Observable<string[]> {
    const req: UnAssignPlanRequest = { userIds, planId, subjectId };
    const callable = this.aff.httpsCallable(CallableFunction.UNASSIGN_USER);
    return callable(req);
  }

  updateDisableRenewPlanAlert(updateRenewPlanAlert: UpdateDisableRenewPlanAlertRequest): Observable<UpdateDisableRenewPlanAlertResponse> {
    const callable = this.aff.httpsCallable(CallableFunction.UPDATE_DISABLE_RENEW_PLAN_ALERT);
    return callable(updateRenewPlanAlert);
  }
}
