import { AppError } from './app-error';
import { GeneralErrorInfo, ErrorInfo } from './error-info';

export class GeneralError extends AppError {
  constructor(info: ErrorInfo, cause?: string | Error) {
    super(info.code, info.message, cause);
    Object.setPrototypeOf(this, GeneralError.prototype);
  }

  static notFound(cause?: string | Error): GeneralError {
    return new GeneralError(GeneralErrorInfo.NOT_FOUND, cause);
  }

  static invalidArguments(cause?: string | Error): GeneralError {
    return new GeneralError(GeneralErrorInfo.INVALID_ARGUMENTS, cause);
  }

  static failedPrecondition(cause?: string | Error): GeneralError {
    return new GeneralError(GeneralErrorInfo.FAILED_PRECONDITION, cause);
  }

  static customMessage(message: string, cause?: string | Error): GeneralError {
    const info = { ...GeneralErrorInfo.CUSTOM_MESSAGE, message };
    return new GeneralError(info, cause);
  }

  static unknown(cause?: string | Error): GeneralError {
    return new GeneralError(GeneralErrorInfo.UNKNOWN, cause);
  }
}
