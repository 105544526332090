import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  commonIdFindPlaylistOriginalProblemIdsFeatureKey,
  State
} from '../../reducers/common-id/common-id-find-playlist-original-problem.reducer';

const selectCommonIdFindPlaylistOriginalProblemState = createFeatureSelector<State>(commonIdFindPlaylistOriginalProblemIdsFeatureKey);

export const getFindCommonIdFindPlaylistOriginalProblemIdsResult = createSelector(
  selectCommonIdFindPlaylistOriginalProblemState,
  state => state.findPlaylistOriginalProblemIdsResult
);
