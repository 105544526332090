import { createAction, props } from '@ngrx/store';
import { AppError } from '../../errors/app-error';
import { CommonIdFindPlaylistOriginalProblemIdsResponse } from 'src/app/models/common-id/common-id-playlist-original-problem';

export const commonIdFindPlaylistOriginalProblemIds = createAction(
  '[CommonIdPlaylistBookmark] CommonId Find Playlist Original Problem Ids'
);
export const commonIdFindPlaylistOriginalProblemIdsSuccess = createAction(
  '[CommonIdPlaylistBookmark] CommonId Find Playlist Original Problem Ids Success',
  props<{ response: CommonIdFindPlaylistOriginalProblemIdsResponse[] }>()
);
export const commonIdFindPlaylistOriginalProblemIdsFailure = createAction(
  '[CommonIdPlaylistBookmark] CommonId Find Playlist Original Problem Ids Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdFindPlaylistOriginalProblemIdsState = createAction(
  '[CommonIdPlaylistBookmark] Initialize CommonId Find Playlist Original Problem Ids State'
);
