import { CurrentDateTime } from '../models/current-date-time';
import { Plan } from '../models/plan';
import { User, UserId } from '../models/user';
import { Dates } from 'src/app/utils/dates';
import { OBUNSHA_ORGANIZATION_ID, OTHER_ORGANIZATION_ID, SubjectId } from '../resources/config';
import { SubjectUtil } from './subject-util';

export class UserUtil {
  // 新規で追加された項目で初期値が必要な項目を設定
  static setDefaultValues(user: User): User {
    if (user === null) {
      return user;
    }

    // 団体種別種別が「その他」または「旺文社内」の場合は全教科使用可能（デフォルト教科は英語とする）
    if (user.organization === OTHER_ORGANIZATION_ID || user.organization === OBUNSHA_ORGANIZATION_ID) {
      user.subjectId = SubjectId.ENGLISH;
      user.visibleSubjectIds = SubjectUtil.getSubjectIds();
    }

    // 体験版プランに割当られたユーザの場合は全教科閲覧可能（デフォルト教科は英語とする）
    if (user.isTrial) {
      user.subjectId = SubjectId.ENGLISH;
      user.visibleSubjectIds = SubjectUtil.getSubjectIds();
    }

    // isActive が未設定の場合
    if (user.isActive === undefined) {
      user.isActive = true;
    }

    return user;
  }

  static getUserName(users: User[], userId: UserId): string {
    for (const user of users) if (user.id === userId) return user.familyName + user.firstName;
    return '';
  }

  // アクティブプラン取得
  static getActivePlan(user: User, plans: Plan[], currentDateTime: CurrentDateTime): Plan {
    if (user === null || plans === null || plans.length === 0) {
      return null;
    }

    const schoolPlans = plans.filter(
      schoolPlan =>
        user.organization !== undefined &&
        schoolPlan.organization === user.organization &&
        user.schoolId !== undefined &&
        schoolPlan.schoolId === user.schoolId &&
        schoolPlan.records !== undefined
    );

    const plan = schoolPlans.find(schoolPlan => {
      const record = schoolPlan.records.find(
        planRecord =>
          Dates.stdDateStringFromIso(planRecord.startAt + ' 00:00:00') <= Dates.stdDateStringFromIso(currentDateTime.dateTime) &&
          Dates.stdDateStringFromIso(planRecord.endAt + ' 23:59:59') >= Dates.stdDateStringFromIso(currentDateTime.dateTime)
      );

      if (record === null || record === undefined) {
        return false;
      } else {
        const assignments = record.assignment.filter(assignment => assignment.memberIds.includes(user.id));

        if (assignments !== null && assignments !== undefined && assignments.length > 0) {
          return true;
        }

        return false;
      }
    });

    return plan === undefined ? null : plan;
  }

  // アクティブプランチェック
  static checkActivePlan(user: User, plans: Plan[], currentDateTime: CurrentDateTime): boolean {
    if (user.organization === OBUNSHA_ORGANIZATION_ID || user.organization === OTHER_ORGANIZATION_ID) {
      return true;
    }

    const activePlan = this.getActivePlan(user, plans, currentDateTime);

    if (activePlan !== null) {
      return true;
    }

    return false;
  }
}
