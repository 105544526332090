import { createAction, props } from '@ngrx/store';
import { AppError } from '../../errors/app-error';
import {
  CommonIdAnsweredProblems,
  CommonIdSaveAnsweredProblemsResponse,
  CommonIdSaveAnsweredProblemsRequest
} from '../../models/common-id/common-id-answered-problem';

export const commonIdFindAnsweredProblems = createAction(
  '[CommonIdAnsweredProblem] CommonId Find Answered Problems',
  props<{ userId: string }>()
);
export const commonIdFindAnsweredProblemsSuccess = createAction(
  '[CommonIdAnsweredProblem] CommonId Find Answered Problems Success',
  props<{ answeredProblem: CommonIdAnsweredProblems }>()
);
export const commonIdFindAnsweredProblemsFailure = createAction('[CommonIdAnsweredProblem] CommonId Find Answered Problems Failure');
export const initializeCommonIdFindAnsweredProblems = createAction('[CommonIdAnsweredProblem] Initialize CommonId Find Answered Problems');

// CommonIdSaveAnswerdProblems ------------------------------------------------------------
export const commonIdSaveAnsweredProblems = createAction(
  '[CommonIdSaveAnswerdProblems] CommonId Save Answerd Problems',
  props<{ request: CommonIdSaveAnsweredProblemsRequest }>()
);
export const commonIdSaveAnsweredProblemsSuccess = createAction(
  '[CommonIdSaveAnwerdProblems] CommonId Save Answerd Problems Success',
  props<{ response: CommonIdSaveAnsweredProblemsResponse }>()
);
export const commonIdSaveAnsweredProblemsFailure = createAction(
  '[CommonIdSaveAnwerdProblems] CommonId Save Anwerd Problems Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdSaveAnsweredProblemsState = createAction(
  '[CommonIdSaveAnwerdProblems] Initialize CommonId Save Answerd Problems State'
);
