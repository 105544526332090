import { environment } from '../../environments/environment';
import { SubjectId } from '../resources/config';
import {
  TRIAL_ENGLISH_WORD_PATH,
  TRIAL_MATH_WORD_PATH,
  TRIAL_NATIONAL_LANGUAGE_WORD_PATH,
  TRIAL_PHYSICS_WORD_PATH,
  TRIAL_CHEMISTRY_WORD_PATH,
  TRIAL_BIOLOGY_WORD_PATH,
  TRIAL_JAPANESE_HISTORY_WORD_PATH,
  TRIAL_WORLD_HISTORY_WORD_PATH,
  TRIAL_GEOGRAPHY_WORD_PATH,
  TRIAL_POLITICAL_ECONOMY_WORD_PATH
} from '../resources/trial-word';

export class WordFileUtil {
  static getPath(problemId: string, isTrial: boolean): string {
    if (problemId === '' || problemId === undefined) return '';

    const subjectId = problemId.slice(6, 8);

    if (isTrial) {
      switch (subjectId) {
        case SubjectId.ENGLISH:
          return environment.cloudfrontHost + TRIAL_ENGLISH_WORD_PATH;
        case SubjectId.MATH:
          return environment.cloudfrontHost + TRIAL_MATH_WORD_PATH;
        case SubjectId.NATIONAL_LANGUAGE:
          return environment.cloudfrontHost + TRIAL_NATIONAL_LANGUAGE_WORD_PATH;
        case SubjectId.PHYSICS:
          return environment.cloudfrontHost + TRIAL_PHYSICS_WORD_PATH;
        case SubjectId.CHEMISTRY:
          return environment.cloudfrontHost + TRIAL_CHEMISTRY_WORD_PATH;
        case SubjectId.BIOLOGY:
          return environment.cloudfrontHost + TRIAL_BIOLOGY_WORD_PATH;
        case SubjectId.JAPANESE_HISTORY:
          return environment.cloudfrontHost + TRIAL_JAPANESE_HISTORY_WORD_PATH;
        case SubjectId.WORLD_HISTORY:
          return environment.cloudfrontHost + TRIAL_WORLD_HISTORY_WORD_PATH;
        case SubjectId.GEOGRAPHY:
          return environment.cloudfrontHost + TRIAL_GEOGRAPHY_WORD_PATH;
        case SubjectId.POLITICAL_ECONOMY:
          return environment.cloudfrontHost + TRIAL_POLITICAL_ECONOMY_WORD_PATH;
        default:
          return '';
      }
    } else {
      const year = problemId.slice(0, 2);
      const universityId = problemId.slice(2, 6);
      const problemIdWithoutProblemNumber = problemId.slice(0, 10);
      return `${environment.cloudfrontHost}word-files/${subjectId}/20${year}/D${universityId}/${problemIdWithoutProblemNumber}/${problemId}.docx`;
    }
  }
}
