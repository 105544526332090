<div @enter *ngIf="records$ | async; else loadingProgress">
  <app-content-row>
    <app-wrapper size="xl" isFitWithSmallScreen="true" *ngIf="isAdmin">
      <app-breadcrumbs (breadcrumbsClick)="breadcrumbsClickHandler($event)" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    </app-wrapper>
    <app-wrapper size="xl" *ngIf="!isAdmin">
      <app-button-location-back (backClick)="plans()">プラン・アカウント管理 - プラン一覧へ戻る</app-button-location-back>
    </app-wrapper>
  </app-content-row>
  <app-wrapper size="xl" isFitWithSmallScreen="true">
    <app-content-row>
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon class="card-content-head-icon">outlined_flag</mat-icon>
          {{ planName }}
          <div class="card-content-head-util">
            <div class="text-primary font-bold">{{ memberNum }} / {{ memberCount }}人</div>
          </div>
        </app-card-content-head>
        <app-card-content-nav (menuClick)="menuClickHandler($event)" [menus]="navMenu"></app-card-content-nav>
        <app-wrapper size="fluid">
          <app-content-row>
            <div class="flex">
              <div>
                <!-- テキストがあればここに -->
              </div>
              <span class="mx-auto"></span>
              <div>
                <button mat-flat-button color="primary" *ngIf="isAdmin" (click)="addPlan()">
                  <mat-icon class="">add_circle_outline</mat-icon>
                  プランを登録
                </button>
                <a
                  mat-flat-button
                  class="mr-2 bg-gray-50 text-primary font-normal"
                  *ngIf="!isAdmin"
                  [href]="memberContactUrl"
                  target="_blank"
                >
                  プラン更新・変更のご相談
                </a>
              </div>
            </div>
          </app-content-row>
        </app-wrapper>
        <app-content-row>
          <app-wrapper size="fluid">
            <app-content-no-data *ngIf="(records$ | async)?.length === 0">
              <span>プランがありません</span>
            </app-content-no-data>
          </app-wrapper>
          <app-wrapper size="fluid" isFitWithSmallScreen="true">
            <app-table-overflow>
              <table mat-table [dataSource]="records$ | async" class="w-full">
                <ng-container matColumnDef="planStatus">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 100px;">状況</th>
                  <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.planStatus === planReserveStatus">予約中</div>
                    <div class="font-bold text-primary" *ngIf="element.planStatus === planActiveStatus">アクティブ</div>
                    <div class="text-nega" *ngIf="element.planStatus === planEndedStatus">終了</div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="validityPeriod">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 350px;">有効期間</th>
                  <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.planStatus === planReserveStatus">
                      {{ element.planStartAt }} 〜 {{ element.planEndAt }} (開始まで{{ element.planToStartDay }}日)
                    </div>
                    <div class="font-bold text-primary" *ngIf="element.planStatus === planActiveStatus">
                      {{ element.planStartAt }} 〜 {{ element.planEndAt }} (終了まで{{ element.planLeftDay }}日)
                    </div>
                    <div class="text-nega" *ngIf="element.planStatus === planEndedStatus">
                      {{ element.planStartAt }} 〜 {{ element.planEndAt }}
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="memberCount">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 100px;">プラン人数</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.memberCount }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="memberList">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 350px;">終了時点での割当ユーザー</th>
                  <td mat-cell *matCellDef="let element" class="py-2 text-sm">
                    <!-- アクティブ時 -->
                    <div *ngIf="element.planStatus === planActiveStatus">
                      <span
                        class="inline-block mr-2"
                        *ngFor="let member of element.memberList; let i = index; let first = first; let last = last"
                      >
                        {{ member }}
                        <ng-container *ngIf="!last">,</ng-container>
                      </span>
                    </div>
                    <!-- 終了時 -->
                    <div class="text-nega" *ngIf="element.planStatus === planEndedStatus">
                      <span
                        class="inline-block mr-2"
                        *ngFor="let member of element.memberList; let i = index; let first = first; let last = last"
                      >
                        {{ member }}
                        <ng-container *ngIf="!last">,</ng-container>
                      </span>
                    </div>
                  </td>
                </ng-container>
                <!-- アドミンユーザーの場合のみ表示する欄 -->
                <ng-container matColumnDef="isAlertDisabled" *ngIf="isAdmin">
                  <th mat-header-cell *matHeaderCellDef style="min-width: 100px; text-align: center;">継続訴求メール配信機能</th>
                  <td mat-cell *matCellDef="let element">
                    <!-- アクティブ時の表示 -->
                    <div *ngIf="element.planStatus === planActiveStatus" class="alert-disable-fuction-column">
                      <!-- プランの終了日が30日より先の場合 -->
                      <div *ngIf="element.planLeftDay >= 30">
                        <button
                          mat-button
                          *ngIf="element.isRenewPlanAlertDisabled"
                          (click)="updateRenewAlert(element.planRecordId, element.isRenewPlanAlertDisabled)"
                          class="button-is-active disabled"
                        >
                          <mat-icon>remove_circle_outline</mat-icon>
                          <span class="label">無効</span>
                        </button>
                        <button
                          mat-button
                          *ngIf="!element.isRenewPlanAlertDisabled === true"
                          (click)="updateRenewAlert(element.planRecordId, element.isRenewPlanAlertDisabled)"
                          class="button-is-active enabled"
                        >
                          <mat-icon>check_circle_outline</mat-icon>
                          <span class="label">有効</span>
                        </button>
                      </div>

                      <!-- プランの終了日が30日より前の場合 -->
                      <div class="text-nega text-sm" *ngIf="element.planLeftDay < 30">配信対象<br />期間外</div>
                    </div>
                    <!-- 予約時または終了プランの表示 -->
                    <div
                      class="alert-disable-fuction-column"
                      *ngIf="element.planStatus === planReserveStatus || element.planStatus === planEndedStatus"
                    >
                      -
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="text-right"></th>
                  <td mat-cell *matCellDef="let element" class="text-right whitespace-nowrap">
                    <button
                      mat-flat-button
                      class="mr-2 bg-gray-50 text-primary font-normal"
                      (click)="removePlan(element.planRecordId)"
                      *ngIf="element.planStatus === planReserveStatus"
                    >
                      予約取消
                    </button>
                    <button
                      mat-flat-button
                      color="primary"
                      (click)="editPlan(element.planRecordId)"
                      *ngIf="element.planStatus !== planEndedStatus"
                    >
                      編集
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </app-table-overflow>
          </app-wrapper>
        </app-content-row>
      </app-card-content>
    </app-content-row>
  </app-wrapper>
</div>

<ng-template #loadingProgress>
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>
