<div class="pagination-container">
  <button class="prev-button" mat-button color="primary" @enter *ngIf="previousPageButtonVisible === true" (click)="onPreviousClick()">
    <mat-icon>arrow_back</mat-icon>
    前へ
  </button>
  <ng-container *ngFor="let page of leftPages">
    <ng-container
      [ngTemplateOutlet]="page === currentPage ? currentPageButton : standardPageButton"
      [ngTemplateOutletContext]="{ page: page }"
    ></ng-container>
  </ng-container>
  <span @enter *ngIf="leftReaderVisible === true">…</span>
  <ng-container *ngFor="let page of centerPages">
    <ng-container
      [ngTemplateOutlet]="page === currentPage ? currentPageButton : standardPageButton"
      [ngTemplateOutletContext]="{ page: page }"
    ></ng-container>
  </ng-container>
  <span @enter *ngIf="rightReaderVisible === true">…</span>
  <ng-container *ngFor="let page of rightPages">
    <ng-container
      [ngTemplateOutlet]="page === currentPage ? currentPageButton : standardPageButton"
      [ngTemplateOutletContext]="{ page: page }"
    ></ng-container>
  </ng-container>
  <button class="next-button" mat-button color="primary" @enter *ngIf="nextPageButtonVisible === true" (click)="onNextClick()">
    次へ
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>

<ng-template #standardPageButton let-page="page">
  <button mat-button color="primary" (click)="pageChange.emit(page)" class="number-button">{{ page }}</button>
</ng-template>

<ng-template #currentPageButton let-page="page">
  <button mat-flat-button color="primary" disabled="true" class="number-button">{{ page }}</button>
</ng-template>
