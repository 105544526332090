<app-search-univ-pref-base
  [isSearchSummaryDisplayed]="isSearchSummaryDisplayed"
  [matchedUniversityCount$]="matchedUniversityCount$"
  [defaultDepartmentCategory]="defaultDepartmentCategory"
  [previousCondition]="previousCondition"
  [searchButtonDisabled$]="searchButtonDisabled$"
  [universityCountSearching$]="universityCountSearching$"
  [isBToC]="true"
  [selectableYears]="selectableYears"
  (menuHandlerClick)="menuClickHandler($event)"
  (changeValues)="onChangeValues($event)"
  (resetFormsClick)="resetForms($event)"
  (showSearchResultViewClick)="showSearchResultView()"
></app-search-univ-pref-base>

<ng-template #searchUnivResult></ng-template>

<div *ngIf="isShowMoreButtonShown$ | async">
  <app-content-row>
    <app-wrapper size="lg" isFitWithSmallScreen="true">
      <div class="pb-8">
        <app-button-more canSpinner="true" [pageButton]="this.pageButton" (buttonClick)="showMoreResults()"></app-button-more>
      </div>
    </app-wrapper>
  </app-content-row>
</div>

<div class="progress-48-search-result-loading py-6 md:py-8" *ngIf="(isUniversitySearching$ | async) && this.currentPage === 1">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
