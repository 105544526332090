<div class="root-container">
  <div class="font-bold text-sm mb-2">
    <div *ngIf="outlineData?.isThinking">【思考力】</div>
    <div>{{ fieldsAndSubTypes }}</div>
  </div>

  <div class="long-sentence" *ngFor="let longSentence of longSentences">
    <div class="long-sentence-field-container">
      <div class="label">英文形態:</div>
      <div class="value">{{ longSentence.sentenceType }}</div>
    </div>

    <div class="long-sentence-field-container">
      <div class="label">ジャンル:</div>
      <div class="value" innerHtml="{{ keywordHighlight(longSentence.genre + ' / ' + longSentence.subGenre) }}"></div>
    </div>

    <div class="long-sentence-field-container">
      <div class="label">英単語数:</div>
      <div class="value">{{ longSentence.wordCount }}</div>
    </div>

    <div class="long-sentence-field-container">
      <div class="label">タイトル:</div>
      <div class="value" innerHtml="{{ keywordHighlight(longSentence.title) }}"></div>
    </div>

    <div class="long-sentence-field-container">
      <div class="label">要約:</div>
      <div class="value" innerHtml="{{ keywordHighlight(longSentence.digest) }}"></div>
    </div>
  </div>

  <div class="comment" *ngIf="outlineData?.comment != null && outlineData?.comment !== ''">
    <div class="label">コメント:</div>
    <div class="value">{{ outlineData?.comment }}</div>
  </div>
</div>
