<div class="root-container">
  <div class="sub-container">
    <div class="label">初期データ関連（Cloud SQL にデータが保存されている必要があります）</div>
    <div class="buttons">
      <app-button-with-progress
        label="Static Data を Firestore に転送"
        color="primary"
        matTooltip="Cloud SQL に保存されている情報などかから statics/common, statics/english, statics/math の Firestore Document を作成します。"
        [loading]="transferStaticDataProcessing$ | async"
        (click)="transferStaticData()"
      ></app-button-with-progress>
      <app-button-with-progress
        label="Playlist を Firestore に転送"
        color="primary"
        matTooltip="Cloud SQL に保存されている情報からおすすめ問題セットデータを Firestore 上に作成します"
        [loading]="transferPlaylistsProcessing$ | async"
        (click)="transferPlaylists()"
      ></app-button-with-progress>
      <app-button-with-progress
        label="Common Id Playlist を Firestore に転送"
        color="primary"
        matTooltip="Cloud SQL に保存されている情報からおすすめ問題セットデータを Firestore 上に作成します"
        [loading]="commonIdTransferPlaylistsProcessing$ | async"
        (click)="commonIdTransferPlaylists()"
      ></app-button-with-progress>
    </div>
  </div>

  <div class="sub-container">
    <div class="label">ログデータ関連</div>
    <div class="buttons">
      <app-button-with-progress
        label="RawSearchEventGroup をローテーションする"
        color="primary"
        [loading]="rotateRawSearchEventGroupProcessing$ | async"
        (click)="rotateRawSearchEventGroup()"
      ></app-button-with-progress>
      <app-button-with-progress
        label="検索ログを GCS にエクスポート"
        color="primary"
        matTooltip="ローテーション済みの RawSearchEventGroup を複数件取得し、GCS に書き出します。その後、該当の RawSearchEventGroup の削除リクエストを Pub/Sub 経由で投げます。"
        [loading]="exportSearchEventLogProcessing$ | async"
        (click)="exportSearchEventLog()"
      >
      </app-button-with-progress>
      <app-button-with-progress
        label="検索ログの daily 集計処理を起動"
        color="primary"
        matTooltip="指定された日付の日次ログを生成します。"
        [loading]="aggregateDailySearchEventLogProcessing$ | async"
        (click)="aggregateDailySearchEventLog()"
      >
      </app-button-with-progress>
      <app-button-with-progress
        label="検索ログの weekly 集計処理を起動"
        color="primary"
        matTooltip="指定された日付までの日次ログを結合して週次のログを生成します。Firestore の searchLogFileMetadata も更新します。"
        [loading]="aggregateWeeklySearchEventLogProcessing$ | async"
        (click)="aggregateWeeklySearchEventLog()"
      >
      </app-button-with-progress>
      <app-button-with-progress
        label="RawSearchEventGroup の掃除"
        color="primary"
        matTooltip="GCS に出力済みで削除されていない RawSearchEventGroup が存在していたら Pub/Sub 経由で削除するリクエストを発行します"
        [loading]="cleanUpRawSearchEventGroupProcessing$ | async"
        (click)="cleanUpRawSearchEventGroup()"
      >
      </app-button-with-progress>
    </div>
  </div>

  <div class="sub-container">
    <div class="label">Admin操作ログデータ関連</div>
    <div class="buttons">
      <app-button-with-progress
        label="RawAdminOperationEventGroup をローテーションする"
        color="primary"
        [loading]="rotateRawAdminOperationEventGroupProcessing$ | async"
        (click)="rotateRawAdminOperationEventGroup()"
      ></app-button-with-progress>
      <app-button-with-progress
        label="Admin操作ログを GCS にエクスポート"
        color="primary"
        matTooltip="ローテーション済みの RawAdminOperationEventGroup を複数件取得し、GCS に書き出します。その後、該当の RawAdminOperationEventGroup の削除リクエストを Pub/Sub 経由で投げます。"
        [loading]="exportAdminOperationEventLogProcessing$ | async"
        (click)="exportAdminOperationEventLog()"
      >
      </app-button-with-progress>
      <app-button-with-progress
        label="Admin操作ログの daily 集計処理を起動"
        color="primary"
        matTooltip="指定された日付の日次ログを生成します。"
        [loading]="aggregateDailyAdminOperationEventLogProcessing$ | async"
        (click)="aggregateDailyAdminOperationEventLog()"
      >
      </app-button-with-progress>
      <app-button-with-progress
        label="Admin操作ログの monthly 集計処理を起動"
        color="primary"
        matTooltip="指定された日付までの日次ログを結合して月次のログを生成します。Firestore の adminOperationLogFileMetadata も更新します。"
        [loading]="aggregateMonthlyAdminOperationEventLogProcessing$ | async"
        (click)="aggregateMonthlyAdminOperationEventLog()"
      >
      </app-button-with-progress>
      <app-button-with-progress
        label="RawAdminOperationEventGroup の掃除"
        color="primary"
        matTooltip="GCS に出力済みで削除されていない RawAdminOperationEventGroup が存在していたら Pub/Sub 経由で削除するリクエストを発行します"
        [loading]="cleanUpRawAdminOperationEventGroupProcessing$ | async"
        (click)="cleanUpRawAdminOperationEventGroup()"
      >
      </app-button-with-progress>
    </div>
  </div>

  <div class="sub-container">
    <div class="label">バックアップ関連</div>
    <div class="buttons">
      <app-button-with-progress
        label="Firestore をバックアップ"
        color="primary"
        [loading]="backupFirestoreProcessing$ | async"
        (click)="backupFirestore()"
      ></app-button-with-progress>
    </div>
  </div>

  <div class="sub-container">
    <div class="label">エクスポート関連</div>
    <div class="buttons">
      <app-button-with-progress
        label="高校マスタを出力"
        color="primary"
        [loading]="exportHighSchoolProcessing$ | async"
        (click)="exportHighSchool()"
      ></app-button-with-progress>
    </div>
  </div>

  <div class="sub-container">
    <div class="label">お気に入り数確認関連</div>
    <div class="buttons">
      <app-button-with-progress
        label="BtoSお気に入り最大数"
        color="primary"
        [loading]="checkMaxBookmarkCountProcessing$ | async"
        (click)="checkMaxBookmarkCount()"
      ></app-button-with-progress>

      <app-button-with-progress
        label="お気に入り大学最大数"
        color="primary"
        [loading]="checkMaxUniversityBookmarkCountProcessing$ | async"
        (click)="checkUniversityBookmarkCount()"
      ></app-button-with-progress>

      <app-button-with-progress
        label="あとで解く（試験単位）"
        color="primary"
        [loading]="checkPaperBookmarkMaxCountProcessing$ | async"
        (click)="checkPaperBookmarkMaxCount()"
      ></app-button-with-progress>

      <app-button-with-progress
        label="解答済み（大問単位）"
        color="primary"
        [loading]="checkAnsweredProblemsMaxCountProcessing$ | async"
        (click)="checkAnsweredProblemsMaxCount()"
      ></app-button-with-progress>

      <app-button-with-progress
        label="おすすめ問題セットお気に入り"
        color="primary"
        [loading]="checkPlaylistBookmarkMaxCountProcessing$ | async"
        (click)="checkPlaylistBookmarkMaxCount()"
      ></app-button-with-progress>
    </div>
  </div>

  <div class="sub-container">
    <div class="label">CSVメンバー一括登録関連</div>
    <div class="buttons">
      <app-button-with-progress label="メンバーの一括削除" color="primary" (click)="deleteMembers()"></app-button-with-progress>
    </div>
  </div>
</div>
