<app-content-row>
  <app-common-id-mylist-banner (bannerClick)="bannerClick()"></app-common-id-mylist-banner>

  <app-content-row>
    <app-wrapper size="lg" isFitWithSmallScreen="true">
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon aria-hidden="true" class="card-content-head-icon" fontSet="material-icons-outlined">fact_check</mat-icon>
          マイリスト
        </app-card-content-head>
        <app-card-content-nav
          (menuClick)="menuClickHandler($event)"
          [menus]="[
            {
              key: 'mylist-answer-problem',
              icon: 'check_circle',
              fontSet: 'material-icons-outlined',
              label: '解答済み',
              isActive: true
            },
            {
              key: 'mylist-univ',
              icon: 'star',
              label: '大学',
              isActive: false
            },
            {
              key: 'mylist-problem',
              icon: 'attach_file',
              fontSet: 'material-icons-outlined',
              label: '過去問',
              isActive: false
            },
            {
              key: 'mylist-playlist',
              icon: 'attach_file',
              fontSet: 'material-icons-outlined',
              label: 'おすすめ問題セット',
              isActive: false
            }
          ]"
        ></app-card-content-nav>

        <div @enter *ngIf="initializedMylistPaper$ | async; else loadingProgress">
          <app-wrapper size="fluid" *ngIf="targetProblems.length > 0">
            <form class="row-sort-area" action="">
              <app-content-row>
                <div class="flex gap-4 items-center sm:justify-between flex-wrap">
                  <div class="flex flex-col gap-2 sm:flex-row flex-wrap w-full sm:w-auto">
                    <div class="flex gap-2 w-full sm:w-auto">
                      <mat-form-field
                        floatLabel="always"
                        appearance="outline"
                        class="text-sm grow sm:grow-0 sm:w-32"
                        style="max-width: calc(50% - 0.25rem);"
                      >
                        <mat-label>科目</mat-label>
                        <mat-select [(value)]="selectedSubjectId" (selectionChange)="problemsFilter()">
                          <mat-option *ngFor="let subject of subjects$ | async" [value]="subject.id">
                            {{ subject.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field
                        floatLabel="always"
                        appearance="outline"
                        class="text-sm grow sm:grow-0 sm:w-32"
                        style="max-width: calc(50% - 0.25rem);"
                      >
                        <mat-label>年度</mat-label>
                        <mat-select [(value)]="selectedYear" (selectionChange)="problemsFilter()">
                          <mat-option *ngFor="let year of years" [value]="year">
                            {{ year }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="flex gap-2 w-full sm:w-auto">
                      <mat-form-field
                        class="text-sm grow"
                        style="max-width: calc(50% - 0.25rem);"
                        appearance="fill"
                        appearance="outline"
                        floatLabel="always"
                      >
                        <mat-label>大学名で絞り込む</mat-label>
                        <input
                          type="text"
                          [placeholder]="universityNamesPlaceholder"
                          aria-label="大学名"
                          matInput
                          [formControl]="universityNameFormControl"
                          [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                          autoActiveFirstOption
                          #auto="matAutocomplete"
                          (optionSelected)="universitySelected($event)"
                          [displayWith]="univForAutoComplete"
                        >
                          <mat-option [value]="allUniversities">
                            {{ allUniversities.name }}
                          </mat-option>
                          <mat-option *ngFor="let univ of filteredUniversities$ | async" [value]="univ">
                            {{ univ.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                      <mat-form-field
                        floatLabel="always"
                        appearance="outline"
                        class="text-sm grow sm:grow-0 sm:w-48"
                        style="max-width: calc(50% - 0.25rem);"
                      >
                        <mat-label>期間で絞り込む</mat-label>
                        <mat-select [(value)]="selectedMonth" (selectionChange)="problemsFilter()">
                          <mat-option *ngFor="let month of selectableMonths" [value]="month">
                            {{ month.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </app-content-row>
              <app-content-row>
                <div class="mt-8 flex gap-4 flex-col sm:flex-row sm:items-center sm:justify-between w-full">
                  <div class="grow">
                    <p class="font-semibold text-lg sm:text-xl mb-0">
                      <span>該当する解答済み問題数</span>
                      <span class="text-2xl sm:text-3xl mx-1 text-primary font-bold">{{ problemsCount }}</span>
                      <span>問</span>
                    </p>
                    <div class="text-sm flex flex-wrap gap-x-2 mt-2">
                      <span *ngIf="engilishProblemsCount"
                        >英語 : <span class="text-primary">{{ engilishProblemsCount }}問</span></span
                      >
                      <span *ngIf="mathProblemsCount"
                        >数学 : <span class="text-primary">{{ mathProblemsCount }}問</span></span
                      >
                      <span *ngIf="japaneseProblemsCount"
                        >国語 : <span class="text-primary">{{ japaneseProblemsCount }}問</span></span
                      >
                      <span *ngIf="physicsProblemsCount"
                        >物理 : <span class="text-primary">{{ physicsProblemsCount }}問</span></span
                      >
                      <span *ngIf="chemistryProblemsCount"
                        >化学 : <span class="text-primary">{{ chemistryProblemsCount }}問</span></span
                      >
                      <span *ngIf="biologyProblemsCount"
                        >生物 : <span class="text-primary">{{ biologyProblemsCount }}問</span></span
                      >
                      <span *ngIf="japaneseHistoryProblemsCount"
                        >日本史 : <span class="text-primary">{{ japaneseHistoryProblemsCount }}問</span></span
                      >
                      <span *ngIf="worldHistoryProblemsCount"
                        >世界史 : <span class="text-primary">{{ worldHistoryProblemsCount }}問</span></span
                      >
                      <span *ngIf="geographyProblemsCount"
                        >地理 : <span class="text-primary">{{ geographyProblemsCount }}問</span></span
                      >
                      <span *ngIf="politicalEconomyProblemsCount"
                        >政治・経済 : <span class="text-primary">{{ politicalEconomyProblemsCount }}問</span></span
                      >
                    </div>
                  </div>
                  <div>
                    <mat-form-field floatLabel="always" appearance="outline" class="text-sm grow sm:w-48">
                      <mat-label>並び替え</mat-label>
                      <mat-select [(value)]="selectedSort" (selectionChange)="problemsFilter()">
                        <mat-option [value]="'DESC'">解答日 新しい順</mat-option>
                        <mat-option [value]="'ASC'">解答日 古い順</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </app-content-row>
            </form>
          </app-wrapper>

          <!-- １件も登録していない場合に表示するエリア -->
          <app-wrapper size="fluid" *ngIf="showingProblems.length === 0">
            <app-content-row>
              <app-content-no-data>
                <div class="mx-auto my-4">
                  <div class="text-center leading-relaxed">
                    <p class="text-md md:text-lg font-bold">解答済みの問題がありません</p>
                    <button mat-flat-button class="mr-2 bg-gray-50 text-primary font-normal" (click)="goSearchUniv()">
                      <mat-icon aria-hidden="true" class="card-content-head-icon">search</mat-icon>
                      大学から探す
                    </button>
                  </div>
                </div>
              </app-content-no-data>
            </app-content-row>
          </app-wrapper>

          <app-wrapper size="fluid" isFitWithSmallScreen="false" *ngIf="showingProblems.length > 0">
            <app-content-row>
              <div #searchByCategoriesResult>
                <ng-container *ngFor="let problem of showingProblems">
                  <div class="py-4 font-bold border-t border-border-line">{{ problem.label }} {{ problem.date }}</div>
                  <ng-container *ngFor="let problem of problem.problems">
                    <!--
                      [isAnswered]="true"
                    -->
                    <app-common-id-problem-list-item-small
                      problemId="{{ problem.pId }}"
                      [isPremiumMemberOnly]="problem.isPremiumMemberOnly"
                      subjectLabelStyleName="{{ problem.subjectLabelStyleName }}"
                      subjectName="{{ problem.subjectName }}"
                      universityName="{{ problem.universityName }}"
                      year="{{ problem.year }}"
                      daimonNumber="{{ problem.daimonNumber }}"
                      (resultClick)="clickResult($event)"
                    ></app-common-id-problem-list-item-small>
                  </ng-container>
                </ng-container>
              </div>
            </app-content-row>
          </app-wrapper>
        </div>

        <ng-template #loadingProgress>
          <app-content-row>
            <div class="spinner-spacer">
              <div class="progress-48">
                <mat-spinner [diameter]="48"></mat-spinner>
              </div>
            </div>
          </app-content-row>
        </ng-template>
      </app-card-content>
    </app-wrapper>
  </app-content-row>
</app-content-row>
