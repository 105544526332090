import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonIdAddPlaylistBookmarkRequest, CommonIdPlaylistProblemIds } from '../../models/common-id/common-id-playlist-bookmark';
import { CommonIdAddPlaylistBookmarkResponse } from '../../models/common-id/common-id-playlist-bookmark';
import { CommonIdDeletePlaylistBookmarkRequest } from '../../models/common-id/common-id-playlist-bookmark';
import { CommonIdDeletePlaylistBookmarkResponse } from '../../models/common-id/common-id-playlist-bookmark';
import { CommonIdPlaylistBookmark } from '../../models/common-id/common-id-playlist-bookmark';
import { CallableFunction, Collection } from '../../resources/app-firebase';

@Injectable({
  providedIn: 'root'
})
export class CommonIdPlaylistBookmarkService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  commonIdFindPlaylistBookmark(userId: string): Observable<CommonIdPlaylistBookmark> {
    return this.afs
      .collection<CommonIdPlaylistBookmark>(Collection.COMMON_ID_PLAYLIST_BOOKMARK, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('userId', '==', userId);
        return query;
      })
      .get()
      .pipe(
        map(snapshot =>
          snapshot.empty
            ? ({} as CommonIdPlaylistBookmark)
            : snapshot.docs.map(doc => {
                const playlistBookmarkId: string = doc.id;
                const data = doc.data();
                data.id = playlistBookmarkId;
                return data as CommonIdPlaylistBookmark;
              })[0]
        )
      );
  }

  commonIdFindPlaylistProblemIdsByPlaylistBookmarkIds(): Observable<CommonIdPlaylistProblemIds[]> {
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_PLAYLIST_PROBLEM_IDS_BY_PLAYLIST_BOOKMARK_IDS);
    return callable(null);
  }

  commonIdAddPlaylistBookmark(userId: string, playlistId: string): Observable<CommonIdAddPlaylistBookmarkResponse> {
    const req: CommonIdAddPlaylistBookmarkRequest = { userId, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_ADD_PLAYLIST_BOOKMARK);
    return callable(req);
  }

  commonIdDeletePlaylistBookmark(userId: string, playlistId: string): Observable<CommonIdDeletePlaylistBookmarkResponse> {
    const req: CommonIdDeletePlaylistBookmarkRequest = { userId, playlistId };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_DELETE_PLAYLIST_BOOKMARK);
    return callable(req);
  }
}
