<div class="button-toggle">
  <!-- true -->
  <button mat-stroked-button color="primary" (click)="toggle()" *ngIf="isToggle">
    {{ labelIfTrue }}
  </button>
  <!-- false -->
  <button mat-stroked-button color="primary" (click)="toggle()" *ngIf="!isToggle">
    {{ labelIfFalse }}
  </button>
</div>
