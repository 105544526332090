import { CommonIdPlaylistSearchCondition } from 'src/app/models/common-id/common-id-playlist-search-condition';
import {
  CommonIdPlaylistSearchQueryParams,
  CommonIdPlaylistSearchSortType
} from 'src/app/models/common-id/common-id-playlist-search-query-params';
import { PlaylistSortType } from 'src/app/resources/config';

export class CommonIdPlaylistSearchQueryParamsMapper {
  static encodeCommonIdPlaylistSearchCondition(condition: CommonIdPlaylistSearchCondition): CommonIdPlaylistSearchQueryParams {
    let params: CommonIdPlaylistSearchQueryParams = {};
    if (condition.subjectId) params = { subjectId: condition.subjectId };

    if (condition.sortType === PlaylistSortType.PUBLISHED_DATE) {
      params = { ...params, sortType: CommonIdPlaylistSearchSortType.PUBLISHED_DATE };
    } else if (condition.sortType === PlaylistSortType.PLAYLIST_ID_ASC) {
      params = { ...params, sortType: CommonIdPlaylistSearchSortType.PLAYLIST_ID_ASC };
    } else if (condition.sortType === PlaylistSortType.PLAYLIST_ID_DESC) {
      params = { ...params, sortType: CommonIdPlaylistSearchSortType.PLAYLIST_ID_DESC };
    } else if (condition.sortType === PlaylistSortType.DURATION) {
      params = { ...params, sortType: CommonIdPlaylistSearchSortType.DURATION };
    }

    if (condition.free) {
      params = { ...params, free: condition.free };
    }

    if (condition.tag) params = { ...params, tag: condition.tag };

    return params;
  }
}
