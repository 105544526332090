import { Component, Input, OnChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';

import { ReadableScienceProblem, ReadableSciencePlaylistProblem } from 'src/app/models/problem';
import { Log } from 'src/app/utils/log';
import { RootState } from 'src/app/reducers';
import { openWindow, redirectPage } from 'src/app/actions/core.actions';
import { BookmarkProblem } from 'src/app/models/bookmark';
import { Observable } from 'rxjs';
import { AppEvent } from '../../../models/event-log';
import { reportAppEvent } from '../../../actions/event-log.actions';
import { WordFileUtil } from '../../../utils/word-file-util';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

interface ProblemData {
  sequentialId: string;
  id: string;
  university: string;
  departments: string;
  year: number;
  outline: string;
  level: string;
  book: string;
  page: number;
  problemNumber: string;

  comment?: string;
  pdfPath?: string;
  pdfDownloading?: boolean;

  hasExternalData: boolean;
  hasWordData: boolean;
  isBookmarked: boolean;
}

type ProblemId = string;

@Component({
  selector: 'app-science-problems',
  templateUrl: './science-problems.component.html',
  styleUrls: ['./science-problems.component.scss']
})
export class ScienceProblemsComponent implements OnChanges, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private objectUrls: string[] = [];

  @Input() readableScienceProblems: ReadableScienceProblem[];

  @Input() readableSciencePlaylistProblems: ReadableSciencePlaylistProblem[];

  @Input() problemButtonDisabled = false;
  @Input() wordButtonDisabled = false;
  @Input() hideLastBottomBorder = false;
  @Input() verticalAlignTop = false;

  @Input() initializedBookmark$: Observable<boolean>;
  @Input() bookmarkProblems: BookmarkProblem[];
  @Input() bookmarkedAllFlag: boolean;
  @Input() canBookmarkSpiner = false;
  @Input() isTrial: boolean;
  @Input() playListId: string;
  @Input() themeId: string;

  @Output() problemClick = new EventEmitter<{ problemId: ProblemId; year: number }>();
  @Output() addBookmarkClick = new EventEmitter<ProblemId>();
  @Output() deleteBookmarkClick = new EventEmitter<ProblemId>();

  columns: string[];
  dataSource: ProblemData[] = [];

  constructor(private store: Store<RootState>) {}

  ngOnChanges() {
    if (!this.readableScienceProblems && !this.readableSciencePlaylistProblems) return;
    const dataCount = this.readableScienceProblems
      ? this.readableScienceProblems.length
      : this.readableSciencePlaylistProblems
      ? this.readableSciencePlaylistProblems.length
      : 'none';
    Log.debug(this.LOG_SOURCE, `input data count: ${dataCount}`);

    this.columns = this.readableScienceProblems
      ? ['university', 'departments', 'year', 'outline', 'level', 'book', 'page', 'problemNumber', 'wordDownload']
      : ['sequentialId', 'university', 'departments', 'year', 'outline', 'level', 'book', 'page', 'problemNumber', 'wordDownload'];

    if (this.initializedBookmark$ !== undefined) {
      this.columns.splice(0, 0, 'bookmark');
    }

    this.dataSource = this.readableScienceProblems
      ? this.readableScienceProblems.map(readableProblem => this.mapData(readableProblem))
      : this.readableSciencePlaylistProblems
      ? this.readableSciencePlaylistProblems.map(readablePlaylistProblem => {
          const data = this.mapData(readablePlaylistProblem);
          data.sequentialId = readablePlaylistProblem.sequentialId;
          if (readablePlaylistProblem.comment) data.comment = readablePlaylistProblem.comment;
          if (readablePlaylistProblem.pdfPath) {
            data.pdfPath = readablePlaylistProblem.pdfPath;
            data.pdfDownloading = false;
          }
          return data;
        })
      : [];
  }

  ngOnDestroy() {
    this.objectUrls.forEach(url => URL.revokeObjectURL(url));
  }

  showPlayListProblemDetail(problem: ProblemData) {
    const url = RoutingPathResolver.resolvePlaylistProblemDetail(this.playListId, this.themeId, problem.id);
    this.store.dispatch(openWindow({ url }));
  }

  openProblemWord(problem: ProblemData) {
    const wordUrl = WordFileUtil.getPath(problem.id, this.isTrial);
    if (wordUrl === '') {
      Log.warn(this.LOG_SOURCE, `wordURL が設定されていません. problem id: `, problem.id);
      return;
    }
    Log.debug(this.LOG_SOURCE, `word をダウンロードして開きます. wordURL: `, wordUrl);

    this.store.dispatch(redirectPage({ url: wordUrl }));

    const subjectId = problem.id.slice(6, 8);
    const selectedProblemWord = `${problem.id}.docx`;
    const appEvent: AppEvent = {
      type: 'select-problem-word',
      value: JSON.stringify({
        subjectId,
        selectedProblemWord
      })
    };
    setTimeout(() => this.store.dispatch(reportAppEvent({ event: appEvent })));
  }

  private mapData(readableProblem: ReadableScienceProblem): ProblemData {
    const outline = readableProblem.thinking !== '' ? readableProblem.thinking + readableProblem.categories : readableProblem.categories;
    const data: ProblemData = {
      /** PlaylistProblem の場合は map 後に適切な値を設定 */
      sequentialId: '',

      id: readableProblem.id,
      university: readableProblem.university,
      departments: readableProblem.departments,
      year: readableProblem.year,
      outline,
      level: readableProblem.level,
      book: readableProblem.book,
      page: readableProblem.page,
      problemNumber: readableProblem.problemNumber,
      hasExternalData: readableProblem.hasExternalData,
      hasWordData: readableProblem.hasWordData,
      isBookmarked: this.bookmarkedAllFlag
        ? true
        : this.bookmarkProblems !== undefined
        ? this.bookmarkProblems.find(bookmarkProblem => bookmarkProblem.problemId === readableProblem.id) !== undefined
        : false
    };
    return data;
  }
}
