import { createReducer, on } from '@ngrx/store';

import * as CommonIdVisitedPaperActions from '../../actions/common-id/common-id-visited-paper.actions';

export const commonIdVisitedPaperFeatureKey = 'commonIdVisitedPaper';

export interface State {
  visitedPaperIds: string[];
}

export const initialState: State = {
  visitedPaperIds: null
};

export const reducer = createReducer(
  initialState,
  on(CommonIdVisitedPaperActions.commonIdAddVisitedPaper, (state, { paperIds }) => ({
    ...state,
    visitedPaperIds: paperIds
  }))
);
