import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RootState } from './reducers';
import { setBrowserTitle, navigate } from './actions/core.actions';
import { RoutingPathResolver } from './app-routing-path-resolver';
import { environment } from '../environments/environment';
import { GAUtil } from './utils/ga-util';
import { watchMaintenanceMode } from './actions/maintenance.actions';
import { getMaintenanceMode } from './selectors/maintenance.selectors';
import { WINDOW_OBJECT } from './utils/injection-tokens';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  maintenanceModeFlag$: Observable<boolean>;
  constructor(private store: Store<RootState>, private router: Router, @Inject(WINDOW_OBJECT) private window: Window) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({}));
    this.store.dispatch(watchMaintenanceMode());
    this.maintenanceModeFlag$ = this.store.select(getMaintenanceMode);

    if (environment.gaConfig.length > 0) {
      const gTm = document.createElement('script');
      gTm.async = true;
      gTm.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gaConfig}`;
      document.head.appendChild(gTm);

      const gaScript = document.createElement('script');
      gaScript.innerHTML = GAUtil.getGAScript(environment.gaConfig);
      document.head.appendChild(gaScript);
    }

    this.navigateToMaintenance();
  }

  private navigateToMaintenance(): void {
    const routerEvent$ = this.router.events.pipe(filter(it => it instanceof NavigationEnd));
    const accessibleDuringMaintenanceUrls = ['/', '/lp', '/terms', '/tokusyouhou', '/c', '/c/terms', '/c/tokusyouhou'];

    combineLatest([routerEvent$, this.maintenanceModeFlag$])
      .pipe(filter(it => it != null))
      .subscribe(([event, maintenanceModeFlag]) => {
        const url = (event as NavigationEnd).url;
        if (accessibleDuringMaintenanceUrls.indexOf(url) !== -1) {
          // メンテナンス中も静的ページは表示させる
          return;
        } else if (maintenanceModeFlag && url !== '/maintenance') {
          localStorage.setItem('backToUrlFromMaintenance', url);
          // メンテナンス画面へリダイレクト
          this.store.dispatch(navigate({ url: RoutingPathResolver.resolveMaintenance() }));
        } else if (!maintenanceModeFlag && url === '/maintenance') {
          // 検索画面へリダイレクト（openWindowだとポップアップブロックされるため location.href を使用）
          const backToUrl = localStorage.getItem('backToUrlFromMaintenance') ?? '/';
          localStorage.removeItem('backToUrlFromMaintenance');
          this.window.location.href = backToUrl;
        } else {
          return;
        }
      });
  }
}
