import firebase from 'firebase/compat/app';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { from, from as fromPromise, Observable } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, toArray } from 'rxjs/operators';

import { CallableFunction, Collection } from '../resources/app-firebase';
import { User, UserId } from '../models/user';
import { UserSearchCondition } from '../models/user-search-condition';
import { CreateUsersResponse } from '../models/create-users-response';
import { CreateUsersRequest } from '../models/create-users-request';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  findUser(id: UserId): Observable<User | undefined> {
    return this.afs
      .collection(Collection.USER)
      .doc<User>(id)
      .get()
      .pipe(map(snapshot => (snapshot.exists ? (snapshot.data() as User) : undefined)));
  }

  findUsers(searchCondition: UserSearchCondition): Observable<User[]> {
    return this.afs
      .collection<User>(Collection.USER, ref => {
        let query: CollectionReference | Query = ref;
        if (searchCondition.ids) query = query.where('id', 'in', searchCondition.ids);
        if (searchCondition.familyName) query = query.where('familyName', '==', searchCondition.familyName);
        if (searchCondition.firstName) query = query.where('firstName', '==', searchCondition.firstName);
        if (searchCondition.familyNameKana) query = query.where('familyNameKana', '==', searchCondition.familyNameKana);
        if (searchCondition.firstNameKana) query = query.where('firstNameKana', '==', searchCondition.firstNameKana);
        if (searchCondition.email) query = query.where('email', '==', searchCondition.email);
        if (searchCondition.organization) query = query.where('organization', '==', searchCondition.organization);
        if (searchCondition.prefectureId) query = query.where('prefectureId', '==', searchCondition.prefectureId);
        if (searchCondition.schoolId) query = query.where('schoolId', '==', searchCondition.schoolId);
        if (searchCondition.school) query = query.where('school', '==', searchCondition.school);
        return query;
      })
      .get()
      .pipe(
        map(snapshot =>
          snapshot.empty
            ? []
            : snapshot.docs.map(doc => {
                const data = doc.data();
                return data as User;
              })
        ),
        map(user => user)
      );
  }

  findUsersByEmail(emailGroups: string[][]): Observable<User[]> {
    return from(emailGroups).pipe(
      mergeMap(emailGroup =>
        this.afs
          .collection<User>(Collection.USER, ref => ref.where('email', 'in', emailGroup))
          .get()
          .pipe(
            map(snapshot =>
              snapshot.empty
                ? []
                : snapshot.docs.map(doc => {
                    const data = doc.data();
                    return data as User;
                  })
            ),
            map(user => user)
          )
      ),
      toArray(),
      map(results => {
        return results.reduce((acc, value) => acc.concat(value), []);
      })
    );
  }

  watchUsers(sortDirection: 'asc' | 'desc'): Observable<User[]> {
    return this.afs
      .collection<User>(Collection.USER, ref => ref.orderBy('createdAt', sortDirection))
      .valueChanges();
  }

  createUser(user: User): Observable<User> {
    const callable = this.aff.httpsCallable<User, User>(CallableFunction.CREATE_USER);
    return callable(user);
  }

  createUsers(organization: string, schoolId: string, users: User[]): Observable<CreateUsersResponse> {
    const req: CreateUsersRequest = { organization, schoolId, users };
    const callable = this.aff.httpsCallable(CallableFunction.PUBLISH_BULK_CREATE_USERS);
    return callable(req);
  }

  updateUser(user: User): Observable<User> {
    const callable = this.aff.httpsCallable<User, User>(CallableFunction.UPDATE_USER);
    return callable(user);
  }

  reauthenticate(user: User, password: string): Observable<void> {
    return this.afAuth.authState.pipe(
      map(firebaseUser => (firebaseUser ? firebaseUser : null)),
      filter(it => it != null),
      take(1),
      switchMap(currentUser => {
        const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, password);
        return fromPromise(currentUser.reauthenticateWithCredential(credential)).pipe(switchMap(() => currentUser.updateEmail(user.email)));
      })
    );
  }

  updateUserEmail(user: User): Observable<User> {
    const callable = this.aff.httpsCallable<User, User>(CallableFunction.UPDATE_USER_EMAIL);
    return callable(user);
  }

  deleteUser(user: User): Observable<User> {
    const callable = this.aff.httpsCallable<User, User>(CallableFunction.DELETE_USER);
    return callable(user);
  }

  updateUserIsActive(user: User): Observable<User> {
    const callable = this.aff.httpsCallable<User, User>(CallableFunction.UPDATE_USER_IS_ACTIVE);
    return callable(user);
  }

  updateUserIsOrganizationAdmin(user: User): Observable<User> {
    const callable = this.aff.httpsCallable<User, User>(CallableFunction.UPDATE_USER_IS_ORGANIZATION_ADMIN);
    return callable(user);
  }

  updateUserIsTermsAgree(user: User): Observable<User> {
    const callable = this.aff.httpsCallable<User, User>(CallableFunction.UPDATE_USER_IS_TERMS_AGREE);
    return callable(user);
  }
}
