import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-basic-footer',
  templateUrl: './dialog-basic-footer.component.html',
  styleUrls: ['./dialog-basic-footer.component.scss']
})
export class DialogBasicFooterComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
