import { createReducer, on } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import * as InformationActions from 'src/app/actions/information.actions';
import { InformationResponse } from 'src/app/models/information';

export const informationFeatureKey = 'information';

export interface GetInformationsResult {
  success: boolean;
  response: InformationResponse[] | null;
  error: AppError | null;
}

export interface State {
  getInformationsResult: GetInformationsResult | null;
  newInformation: boolean | null;
}

export const initialState: State = {
  getInformationsResult: null,
  newInformation: null
};

export const reducer = createReducer(
  initialState,
  on(InformationActions.getInformationsSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      getInformationsResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(InformationActions.getInformationsFailure, (state, { error }) => {
    const next: State = {
      ...state,
      getInformationsResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(InformationActions.initializeGetInformationsState, state => {
    const next: State = { ...state, getInformationsResult: null };
    return next;
  }),
  on(InformationActions.updateNewInformation, (state, { newInformation }) => ({ ...state, newInformation }))
);
