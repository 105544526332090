<app-main-layout-spacer *ngIf="reSignIn === null">
  <app-common-id-sign-out-header></app-common-id-sign-out-header>

  <app-wrapper size="lg" isFitWithSmallScreen="true">
    <div class="sign-out">
      <app-content-row>
        <app-article-content>
          <div class="sign-out-inner">
            <div class="icon-area">
              <mat-icon class="icon material-icons-outlined">check_circle</mat-icon>
            </div>
            <p class="text-2xl md:text-3xl text-center mb-10"><b>サインアウトしました</b></p>
            <div class="button-wrap grid gap-4">
              <button mat-flat-button class="bg-gray-50 text-base font-normal" (click)="gotoTop()">
                入試正解デジタル公式ページ
              </button>
              <button mat-flat-button color="primary" class="text-base font-normal" (click)="gotoSearch()">
                サインイン
              </button>
            </div>
          </div>
        </app-article-content>
      </app-content-row>
    </div>
  </app-wrapper>

  <app-common-id-footer></app-common-id-footer>
</app-main-layout-spacer>
