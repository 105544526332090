<app-content-row>
  <app-wrapper size="lg" isFitWithSmallScreen="true">
    <app-card-content [showSeparator]="true" @enter *ngIf="(loaded$ | async) === true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">credit_card</mat-icon>
        購入履歴と利用期限
      </app-card-content-head>
      <!-- ▼ 購入後の注意 -->
      <app-content-row *ngIf="(isPremiumUser$ | async) === false">
        <app-wrapper size="fluid">
          <app-alert-box title="購入後の注意" [closeable]="false" type="premium" alignItems="flex-start">
            <div class="attention flex gap-4 flex-col md:flex-row items-center">
              <div class="grow">
                <p class="font-bold mb-1">購入後の注意</p>
                <p class="mb-0">
                  プレミアムプランをご購入後、購入情報が反映されていない場合は「更新する」ボタンを押してください。
                  5分程時間をおいても反映されない場合は、
                  <a class="text-primary underline hover:no-underline" (click)="gotoContact()">お問い合わせフォーム</a>
                  からご連絡ください。
                </p>
              </div>
              <div>
                <button class="text-sm bg-yellow-gold-dark" mat-flat-button color="primary" (click)="getUser()">
                  <mat-icon>sync</mat-icon>
                  更新する
                </button>
              </div>
            </div>
          </app-alert-box>
        </app-wrapper>
      </app-content-row>
      <!-- △ 購入後の注意 -->
      <app-content-row>
        <div class="flex flex-col gap-3 mt-7">
          <app-wrapper size="fluid">
            <app-header-with-listmark><span class="text-base">現在のご契約プラン</span></app-header-with-listmark>
          </app-wrapper>
          <app-wrapper size="fluid" isFitWithSmallScreen="true">
            <app-content-row>
              <table class="plan-table">
                <tr *ngIf="(isPremiumUser$ | async) === false">
                  <th class="w-32 md:w-52">現在のプラン名</th>
                  <td>
                    <span class="text-sub">プレミアム未加入</span>
                    <button class="text-sm" mat-flat-button color="primary" (click)="gotoPurchase()">プラン選択画面へ</button>
                  </td>
                </tr>
                <tr *ngIf="(isPremiumUser$ | async) === true">
                  <th class="w-32 md:w-52">現在のプラン名</th>
                  <td>
                    <span class="">{{ userPlan.planName }}</span>
                    <button class="text-sm bg-gray-50 text-primary" mat-flat-button (click)="gotoPremium()">
                      プランについて詳しく<mat-icon class="">open_in_new</mat-icon>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="(isPremiumUser$ | async) === true">
                  <th class="w-32 md:w-52">有効期限</th>
                  <td>
                    <span class="">{{ userPlan.premiumEndAt }} （あと{{ userPlan.leftDay }}日）</span>
                  </td>
                </tr>
              </table>

              <div *ngIf="(isPremiumUser$ | async) === false" class="mt-4 text-right">
                <div class="flex items-center justify-end mr-3">
                  <button (click)="gotoPremium()" class="text-sm text-primary decoration-solid hover:underline">
                    サービスの概要について
                  </button>
                  <mat-icon class="icon text-primary text-md w-[0.75rem] h-[0.75rem]" aria-hidden="false" aria-label=""
                    >keyboard_arrow_right</mat-icon
                  >
                </div>
              </div>
            </app-content-row>
          </app-wrapper>
          <app-wrapper size="fluid">
            <app-header-with-listmark><span class="text-base">お支払い履歴</span></app-header-with-listmark>
          </app-wrapper>
          <app-wrapper size="fluid" *ngIf="purchases.length === 0">
            <app-content-row>
              <app-content-no-data>
                <span>お支払い履歴はありません</span>
              </app-content-no-data>
            </app-content-row>
          </app-wrapper>
          <app-wrapper size="fluid" isFitWithSmallScreen="true" *ngIf="purchases.length > 0">
            <app-content-row>
              <app-table-overflow>
                <table mat-table [dataSource]="purchases" class="table-payment-history w-full">
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>購入日</th>
                    <td mat-cell *matCellDef="let element" class="date">
                      {{ element.date }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="plan">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 200px;">プラン名</th>
                    <td mat-cell *matCellDef="let element" class="plan">
                      {{ element.plan }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 130px;">金額</th>
                    <td mat-cell *matCellDef="let element" class="price">{{ element.price }}<small>(税込)</small></td>
                  </ng-container>
                  <ng-container matColumnDef="settlement">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 150px;">決済方法</th>
                    <td mat-cell *matCellDef="let element" class="settlement">
                      {{ element.method }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="expiry">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 100px;">有効期限</th>
                    <td mat-cell *matCellDef="let element" class="expiry">
                      {{ element.expiry }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="statement">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 120px;">支払い状況</th>
                    <td mat-cell *matCellDef="let element" class="statement">
                      <span class="text-primary" *ngIf="element.status === 2"
                        ><mat-icon class="material-icons-outlined">warning</mat-icon>支払い待ち</span
                      >
                      <span class="text-primary" *ngIf="element.status === 1"
                        ><mat-icon class="material-icons-outlined">check_circle</mat-icon>購入済</span
                      >
                      <!-- <span class="text-primary"><mat-icon class="material-icons-outlined">do_disturb_on</mat-icon>キャンセル</span> -->
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="['date', 'plan', 'price', 'settlement', 'expiry', 'statement']"></tr>
                  <tr mat-row *matRowDef="let row; columns: ['date', 'plan', 'price', 'settlement', 'expiry', 'statement']"></tr>
                </table>
              </app-table-overflow>
            </app-content-row>
          </app-wrapper>
        </div>
      </app-content-row>
    </app-card-content>
    <div class="progress-48" *ngIf="(loaded$ | async) === false">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>
  </app-wrapper>
</app-content-row>
