import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { navigate } from '../../../../actions/core.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';

@Component({
  selector: 'app-common-id-sign-in-error',
  templateUrl: './sign-in-error.component.html',
  styleUrls: ['./sign-in-error.component.scss']
})
export class CommonIdSignInErrorComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {}

  gotoTop() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdTop() }));
  }
}
