import { createAction, props } from '@ngrx/store';
import { AppError } from '../../errors/app-error';
import { CommonIdAddPaperBookmarkResponse } from '../../models/common-id/common-id-add-paper-bookmark-response';
import { CommonIdDeletePaperBookmarkResponse } from '../../models/common-id/common-id-delete-paper-bookmark-response';
import { CommonIdPaperBookmark } from '../../models/common-id/common-id-paper-bookmark';

// findPaperBookmarks ------------------------------------------------------------
export const commonIdFindPaperBookmark = createAction('[CommonIdPaperBookmark] CommonId Find Paper Bookmark', props<{ userId: string }>());
export const commonIdFindPaperBookmarkSuccess = createAction(
  '[CommonIdPaperBookmark] CommonId Find Paper Bookmark Success',
  props<{ paperBookmark: CommonIdPaperBookmark }>()
);
export const commonIdFindPaperBookmarkFailure = createAction('[CommonIdPaperBookmark] FiCommonId Findnd Paper Bookmark Failure');
export const initializeCommonIdFindPaperBookmarkState = createAction(
  '[CommonIdPaperBookmark] Initialize CommonId Find Paper Bookmark State'
);

// addPaperBookmarks ------------------------------------------------------------
export const commonIdAddPaperBookmark = createAction(
  '[CommonIdPaperBookmark] CommonId Add Paper Bookmark',
  props<{ userId: string; paperId: string }>()
);
export const commonIdAddPaperBookmarkSuccess = createAction(
  '[CommonIdPaperBookmark] CommonId Add Paper Bookmark Success',
  props<{ response: CommonIdAddPaperBookmarkResponse }>()
);
export const commonIdAddPaperBookmarkFailure = createAction(
  '[CommonIdPaperBookmark] CommonId Add Paper Bookmark Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdAddPaperBookmarkState = createAction('[CommonIdPaperBookmark] Initialize CommonId Add Paper Bookmark State');

// deletePaperBookmarks ------------------------------------------------------------
export const commonIdDeletePaperBookmark = createAction(
  '[CommonIdPaperBookmark] CommonId Delete Paper Bookmark',
  props<{ userId: string; paperId: string }>()
);
export const commonIdDeletePaperBookmarkSuccess = createAction(
  '[CommonIdPaperBookmark] CommonId Delete Paper Bookmark Success',
  props<{ response: CommonIdDeletePaperBookmarkResponse }>()
);
export const commonIdDeletePaperBookmarkFailure = createAction(
  '[CommonIdPaperBookmark] CommonId Delete Paper Bookmark Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdDeletePaperBookmarkState = createAction(
  '[CommonIdPaperBookmark] Initialize CommonId Delete Paper Bookmark State'
);
