<app-dialog-basic [dialogTitle]="dialogTitle" [isLoading]="jukuCreating">
  <form [formGroup]="jukuForm">
    <!-- 塾・予備校名 -->
    <mat-form-field class="block mb-4" appearance="outline">
      <mat-label>塾・予備校名</mat-label>
      <input matInput formControlName="jukuName" />
      <mat-error *ngIf="jukuForm.get('jukuName').hasError('required')">入力してください</mat-error>
    </mat-form-field>

    <!-- 教室名 -->
    <mat-form-field class="block mb-4" appearance="outline">
      <mat-label>教室名(任意)</mat-label>
      <input matInput formControlName="jukuSubName" />
    </mat-form-field>

    <!-- 塾・予備校コード -->
    <mat-form-field class="block mb-4" appearance="outline">
      <mat-label>塾・予備校コード</mat-label>
      <input matInput formControlName="jukuCode" />
      <mat-error *ngIf="jukuForm.get('jukuCode').hasError('required')">入力してください</mat-error>
      <mat-error *ngIf="jukuForm.get('jukuCode').hasError('pattern')">5桁の半角数字を入力してください</mat-error>
    </mat-form-field>

    <!-- 都道府県 -->
    <mat-form-field class="block mb-4" appearance="outline">
      <mat-label>都道府県</mat-label>
      <mat-select formControlName="prefectureId">
        <mat-option *ngFor="let prefecture of prefectures" [value]="prefecture.id">{{ prefecture.name }}</mat-option>
      </mat-select>
      <mat-error *ngIf="jukuForm.get('prefectureId').hasError('required')">選択してください</mat-error>
    </mat-form-field>

    <div class="mt-4">
      ※ 塾・予備校コードは入力された5桁の先頭に「J-」が自動的に付与されます
    </div>
  </form>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="jukuCreating"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <div class="text-danger text-sm mr-auto" @enter *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
    <button mat-stroked-button (click)="cancel()" [disabled]="jukuCreating">キャンセル</button>
    <button mat-flat-button color="primary" (click)="createJuku()" [disabled]="jukuCreating">登録</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
