import { createAction, props } from '@ngrx/store';

import { User } from '../models/user';

export const signIn = createAction('[Auth] Sign In', props<{ email: string; password: string }>());
export const signInSuccess = createAction('[Auth] Sign In Success', props<{ user: User }>());
export const signInFailure = createAction('[Auth] Sign In Failure');
export const initializeSignInResult = createAction('[Auth] Initialize Sign In Result');

export const signOut = createAction('[Auth] Sign Out');
export const signOutSuccess = createAction('[Auth] Sign Out Success');
export const signOutFailure = createAction('[Auth] Sign Out Failure');

export const findSignedInUser = createAction('[Auth] Find Signed In User');
export const findSignedInUserSuccess = createAction('[Auth] Find Signed In User Success', props<{ user: User }>());
export const findSignedInUserFailure = createAction('[Auth] Find Signed In User Failure');

export const sendPasswordResetEmail = createAction('[Auth] Send Password Reset Email', props<{ email: string }>());
export const sendPasswordResetEmailSuccess = createAction('[Auth] Send Password Reset Email Success');
export const sendPasswordResetEmailFailure = createAction('[Auth] Send Password Reset Email Failure');

export const updateSignedInUserIsTermsAgreeSuccess = createAction(
  '[Auth] Update Signed In User Is Terms Agree Success',
  props<{ user: User }>()
);
export const initializeUpdateSignedInUserIsTermsAgreeResult = createAction('[Auth] Initialize Update Signed In User Is Terms Agree Result');

// state.signedInUser の情報を更新する際に使用
export const updateSignedInUser = createAction('[Auth] Update Signed In User', props<{ user: User }>());
