<app-dialog-basic dialogTitle="この試験の情報" dialogIcon="info" dialogIconOutline="true">
  <p class="source-information-title">出典：</p>
  <p class="source-information-content">
    <ng-container *ngFor="let description of data.descriptions"> {{ description }}<br /> </ng-container>
  </p>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="close()">閉じる</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
