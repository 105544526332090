import { Action, createReducer, on } from '@ngrx/store';

import * as ToolsActions from '../actions/tools.actions';

export const toolsFeatureKey = 'tools';

export interface State {
  transferStaticDataProcessing: boolean;
  transferPlaylistsProcessing: boolean;
  commonIdTransferPlaylistsProcessing: boolean;
  rotateRawSearchEventGroupProcessing: boolean;
  exportSearchEventLogProcessing: boolean;
  aggregateDailySearchEventLogProcessing: boolean;
  aggregateWeeklySearchEventLogProcessing: boolean;
  cleanUpRawSearchEventGroupProcessing: boolean;
  rotateRawAdminOperationEventGroupProcessing: boolean;
  exportAdminOperationEventLogProcessing: boolean;
  aggregateDailyAdminOperationEventLogProcessing: boolean;
  aggregateMonthlyAdminOperationEventLogProcessing: boolean;
  cleanUpRawAdminOperationEventGroupProcessing: boolean;
  backupFirestoreProcessing: boolean;
  checkBookmarkMaxCountProcessing: boolean;
  checkUniversityBookmarkMaxCountProcessing: boolean;
  checkPaperBookmarkMaxCountProcessing: boolean;
  checkAnsweredProblemsMaxCountProcessing: boolean;
  checkPlaylistBookmarkMaxCountProcessing: boolean;
  deleteMembersProcessing: boolean;
}

export const initialState: State = {
  transferStaticDataProcessing: false,
  transferPlaylistsProcessing: false,
  commonIdTransferPlaylistsProcessing: false,
  rotateRawSearchEventGroupProcessing: false,
  exportSearchEventLogProcessing: false,
  aggregateDailySearchEventLogProcessing: false,
  aggregateWeeklySearchEventLogProcessing: false,
  cleanUpRawSearchEventGroupProcessing: false,
  rotateRawAdminOperationEventGroupProcessing: false,
  exportAdminOperationEventLogProcessing: false,
  aggregateDailyAdminOperationEventLogProcessing: false,
  aggregateMonthlyAdminOperationEventLogProcessing: false,
  cleanUpRawAdminOperationEventGroupProcessing: false,
  backupFirestoreProcessing: false,
  checkBookmarkMaxCountProcessing: false,
  checkUniversityBookmarkMaxCountProcessing: false,
  checkPaperBookmarkMaxCountProcessing: false,
  checkAnsweredProblemsMaxCountProcessing: false,
  checkPlaylistBookmarkMaxCountProcessing: false,
  deleteMembersProcessing: false
};

const toolsReducer = createReducer(
  initialState,
  on(ToolsActions.transferStaticData, state => ({ ...state, transferStaticDataProcessing: true })),
  on(ToolsActions.transferStaticDataSuccess, state => ({ ...state, transferStaticDataProcessing: false })),
  on(ToolsActions.transferStaticDataFailure, state => ({ ...state, transferStaticDataProcessing: false })),

  on(ToolsActions.transferPlaylists, state => ({ ...state, transferPlaylistsProcessing: true })),
  on(ToolsActions.transferPlaylistsSuccess, state => ({ ...state, transferPlaylistsProcessing: false })),
  on(ToolsActions.transferPlaylistsFailure, state => ({ ...state, transferPlaylistsProcessing: false })),

  on(ToolsActions.commonIdTransferPlaylists, state => ({ ...state, commonIdTransferPlaylistsProcessing: true })),
  on(ToolsActions.commonIdTransferPlaylistsSuccess, state => ({ ...state, commonIdTransferPlaylistsProcessing: false })),
  on(ToolsActions.commonIdTransferPlaylistsFailure, state => ({ ...state, commonIdTransferPlaylistsProcessing: false })),

  on(ToolsActions.rotateRawSearchEventGroup, state => ({ ...state, rotateRawSearchEventGroupProcessing: true })),
  on(ToolsActions.rotateRawSearchEventGroupSuccess, state => ({ ...state, rotateRawSearchEventGroupProcessing: false })),
  on(ToolsActions.rotateRawSearchEventGroupFailure, state => ({ ...state, rotateRawSearchEventGroupProcessing: false })),

  on(ToolsActions.exportSearchEventLog, state => ({ ...state, exportSearchEventLogProcessing: true })),
  on(ToolsActions.exportSearchEventLogSuccess, state => ({ ...state, exportSearchEventLogProcessing: false })),
  on(ToolsActions.exportSearchEventLogFailure, state => ({ ...state, exportSearchEventLogProcessing: false })),

  on(ToolsActions.aggregateDailySearchEventLog, state => ({ ...state, aggregateDailySearchEventLogProcessing: true })),
  on(ToolsActions.aggregateDailySearchEventLogSuccess, state => ({ ...state, aggregateDailySearchEventLogProcessing: false })),
  on(ToolsActions.aggregateDailySearchEventLogFailure, state => ({ ...state, aggregateDailySearchEventLogProcessing: false })),

  on(ToolsActions.aggregateWeeklySearchEventLog, state => ({ ...state, aggregateWeeklySearchEventLogProcessing: true })),
  on(ToolsActions.aggregateWeeklySearchEventLogSuccess, state => ({ ...state, aggregateWeeklySearchEventLogProcessing: false })),
  on(ToolsActions.aggregateWeeklySearchEventLogFailure, state => ({ ...state, aggregateWeeklySearchEventLogProcessing: false })),

  on(ToolsActions.cleanUpRawSearchEventGroup, state => ({ ...state, cleanUpRawSearchEventGroupProcessing: true })),
  on(ToolsActions.cleanUpRawSearchEventGroupSuccess, state => ({ ...state, cleanUpRawSearchEventGroupProcessing: false })),
  on(ToolsActions.cleanUpRawSearchEventGroupFailure, state => ({ ...state, cleanUpRawSearchEventGroupProcessing: false })),

  on(ToolsActions.rotateRawAdminOperationEventGroup, state => ({ ...state, rotateRawAdminOperationEventGroupProcessing: true })),
  on(ToolsActions.rotateRawAdminOperationEventGroupSuccess, state => ({
    ...state,
    rotateRawAdminOperationEventGroupProcessing: false
  })),
  on(ToolsActions.rotateRawAdminOperationEventGroupFailure, state => ({
    ...state,
    rotateRawAdminOperationEventGroupProcessing: false
  })),

  on(ToolsActions.exportAdminOperationEventLog, state => ({ ...state, exportAdminOperationEventLogProcessing: true })),
  on(ToolsActions.exportAdminOperationEventLogSuccess, state => ({ ...state, exportAdminOperationEventLogProcessing: false })),
  on(ToolsActions.exportAdminOperationEventLogFailure, state => ({ ...state, exportAdminOperationEventLogProcessing: false })),

  on(ToolsActions.aggregateDailyAdminOperationEventLog, state => ({
    ...state,
    aggregateDailyAdminOperationEventLogProcessing: true
  })),
  on(ToolsActions.aggregateDailyAdminOperationEventLogSuccess, state => ({
    ...state,
    aggregateDailyAdminOperationEventLogProcessing: false
  })),
  on(ToolsActions.aggregateDailyAdminOperationEventLogFailure, state => ({
    ...state,
    aggregateDailyAdminOperationEventLogProcessing: false
  })),

  on(ToolsActions.aggregateMonthlyAdminOperationEventLog, state => ({
    ...state,
    aggregateMonthlyAdminOperationEventLogProcessing: true
  })),
  on(ToolsActions.aggregateMonthlyAdminOperationEventLogSuccess, state => ({
    ...state,
    aggregateMonthlyAdminOperationEventLogProcessing: false
  })),
  on(ToolsActions.aggregateMonthlyAdminOperationEventLogFailure, state => ({
    ...state,
    aggregateMonthlyAdminOperationEventLogProcessing: false
  })),

  on(ToolsActions.cleanUpRawAdminOperationEventGroup, state => ({ ...state, cleanUpRawAdminOperationEventGroupProcessing: true })),
  on(ToolsActions.cleanUpRawAdminOperationEventGroupSuccess, state => ({
    ...state,
    cleanUpRawAdminOperationEventGroupProcessing: false
  })),
  on(ToolsActions.cleanUpRawAdminOperationEventGroupFailure, state => ({
    ...state,
    cleanUpRawAdminOperationEventGroupProcessing: false
  })),

  on(ToolsActions.backupFirestore, state => ({ ...state, backupFirestoreProcessing: true })),
  on(ToolsActions.backupFirestoreSuccess, state => ({ ...state, backupFirestoreProcessing: false })),
  on(ToolsActions.backupFirestoreFailure, state => ({ ...state, backupFirestoreProcessing: false })),

  on(ToolsActions.checkBookmarkMaxCount, state => ({ ...state, checkBookmarkMaxCountProcessing: true })),
  on(ToolsActions.checkBookmarkMaxCountSuccess, state => ({ ...state, checkBookmarkMaxCountProcessing: false })),
  on(ToolsActions.checkBookmarkMaxCountFailure, state => ({ ...state, checkBookmarkMaxCountProcessing: false })),

  on(ToolsActions.checkUniversityBookmarkMaxCount, state => ({ ...state, checkUniversityBookmarkMaxCountProcessing: true })),
  on(ToolsActions.checkUniversityBookmarkMaxCountSuccess, state => ({ ...state, checkUniversityBookmarkMaxCountProcessing: false })),
  on(ToolsActions.checkUniversityBookmarkMaxCountFailure, state => ({ ...state, checkUniversityBookmarkMaxCountProcessing: false })),

  on(ToolsActions.checkPaperBookmarkMaxCount, state => ({ ...state, checkPaperBookmarkMaxCountProcessing: true })),
  on(ToolsActions.checkPaperBookmarkMaxCountSuccess, state => ({ ...state, checkPaperBookmarkMaxCountProcessing: false })),
  on(ToolsActions.checkPaperBookmarkMaxCountFailure, state => ({ ...state, checkPaperBookmarkMaxCountProcessing: false })),

  on(ToolsActions.checkAnsweredProblemsMaxCount, state => ({ ...state, checkAnsweredProblemsMaxCountProcessing: true })),
  on(ToolsActions.checkAnsweredProblemsMaxCountSuccess, state => ({ ...state, checkAnsweredProblemsMaxCountProcessing: false })),
  on(ToolsActions.checkAnsweredProblemsMaxCountFailure, state => ({ ...state, checkAnsweredProblemsMaxCountProcessing: false })),

  on(ToolsActions.checkPlaylistBookmarkMaxCount, state => ({ ...state, checkPlaylistBookmarkMaxCountProcessing: true })),
  on(ToolsActions.checkPlaylistBookmarkMaxCountSuccess, state => ({ ...state, checkPlaylistBookmarkMaxCountProcessing: false })),
  on(ToolsActions.checkPlaylistBookmarkMaxCountFailure, state => ({ ...state, checkPlaylistBookmarkMaxCountProcessing: false })),

  on(ToolsActions.deleteMembers, state => ({ ...state, deleteMembersProcessing: true })),
  on(ToolsActions.deleteMembersSuccess, state => ({ ...state, deleteMembersProcessing: false })),
  on(ToolsActions.deleteMembersFailure, state => ({ ...state, deleteMembersProcessing: false }))
);

export function reducer(state: State | undefined, action: Action) {
  return toolsReducer(state, action);
}
