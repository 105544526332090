import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, takeUntil, filter, exhaustMap } from 'rxjs/operators';

import * as CommonIdUserActions from '../../actions/common-id/common-id-user.actions';
import { CommonIdUserService } from '../../services/common-id/common-id-user.service';
import { dispatchAppError } from '../../actions/core.actions';
import { FirestoreError } from '../../errors/firestore-error';
import { SubscriptionService } from '../../services/subscription.service';
import { UnsubscribeTarget } from '../../resources/config';
import { FirebaseCallableFunctionError } from '../../errors/firebase-callable-function-error';

@Injectable()
export class CommonIdUserEffects {
  private LOG_SOURCE = this.constructor.name;

  findCommonIdUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdUserActions.findCommonIdUser),
      switchMap(action =>
        this.commonIdUserService.findCommonIdUser(action.userId).pipe(
          map(user => CommonIdUserActions.findCommonIdUserSuccess({ user })),
          catchError(e =>
            of(CommonIdUserActions.findCommonIdUserFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) }))
          )
        )
      )
    )
  );

  findCommonIdUserByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdUserActions.findCommonIdUserByEmail),
      switchMap(action =>
        this.commonIdUserService.findCommonIdUserByEmail(action.email).pipe(
          map(user =>
            user ? CommonIdUserActions.findCommonIdUserByEmailSuccess({ user }) : CommonIdUserActions.findCommonIdUserByEmailNotFound()
          ),
          catchError(e =>
            of(
              CommonIdUserActions.findCommonIdUserByEmailFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findCommonIdUserByCommonId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdUserActions.findCommonIdUserByCommonId),
      switchMap(action =>
        this.commonIdUserService.findCommonIdUserByCommonId(action.commonId).pipe(
          map(user =>
            user
              ? CommonIdUserActions.findCommonIdUserByCommonIdSuccess({ user })
              : CommonIdUserActions.findCommonIdUserByCommonIdNotFound()
          ),
          catchError(e =>
            of(
              CommonIdUserActions.findCommonIdUserByCommonIdFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  watchCommonIdUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdUserActions.watchCommonIdUsers),
      switchMap(action =>
        this.commonIdUserService.watchCommonIdUsers().pipe(
          takeUntil(this.subscriptionService.unsubscribed$.pipe(filter(it => it === UnsubscribeTarget.WATCH_USERS))),
          map(users => CommonIdUserActions.watchCommonIdUsersSuccess({ users })),
          catchError(e =>
            of(
              CommonIdUserActions.watchCommonIdUsersFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  updateCommonIdUserIsActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdUserActions.updateCommonIdUserIsActive),
      exhaustMap(action =>
        this.commonIdUserService.updateCommonIdUserIsActive(action.user).pipe(
          map(user => CommonIdUserActions.updateCommonIdUserIsActiveSuccess({ user })),
          catchError(e => of(CommonIdUserActions.updateCommonIdUserIsActiveFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  updateCommonIdUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdUserActions.updateCommonIdUser),
      exhaustMap(action =>
        this.commonIdUserService.updateCommonIdUser(action.request).pipe(
          map(request => CommonIdUserActions.updateCommonIdUserSuccess({ userId: request.id })),
          catchError(e => of(CommonIdUserActions.updateCommonIdUserFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private commonIdUserService: CommonIdUserService,
    private subscriptionService: SubscriptionService
  ) {}
}
