import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-layout-spacer',
  templateUrl: './main-layout-spacer.component.html',
  styleUrls: ['./main-layout-spacer.component.scss']
})
export class MainLayoutSpacerComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
