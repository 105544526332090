import { createAction, props } from '@ngrx/store';
import { KakomonImages } from '../models/try-search';

export const findProblemImages = createAction('[TrySearch] Find Problem Images', props<{ problemId: string }>());

export const findProblemImagesSuccess = createAction(
  '[TrySearch] Try Find Problem Images Success',
  props<{ kakomonImages: KakomonImages }>()
);

export const findProblemImagesFailure = createAction('[TrySearch] Try Find Problem Images Failure');
