<app-dialog-basic dialogTitle="プラン削除" [isLoading]="disabled">
  <div class="pb-4">
    <div>{{ planName }}のプランを削除します。</div>
    <div>この操作は取り消せません。削除を実行しますか？</div>
  </div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="disabled"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="deletePlan()" [disabled]="disabled">取消</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
