<app-table-overflow>
  <table mat-table [dataSource]="dataSource" class="w-full">
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef class="text-xs leading-3 text-center" style="min-width:84px;">アカウント<br />有効/無効</th>
      <td mat-cell *matCellDef="let account">
        <button mat-button *ngIf="!account.isActive" (click)="changeActiveAccount(account)" class="button-is-active disabled">
          <mat-icon>remove_circle_outline</mat-icon>
          <span class="label">無効</span>
        </button>
        <button mat-button *ngIf="account.isActive" (click)="changeActiveAccount(account)" class="button-is-active enabled">
          <mat-icon>check_circle_outline</mat-icon>
          <span class="label">有効</span>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">メールアドレス</th>
      <td mat-cell *matCellDef="let account" class="text-xs break-words">{{ account.email }}</td>
    </ng-container>

    <ng-container matColumnDef="commonId">
      <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">ユーザー ID</th>
      <td mat-cell *matCellDef="let account" class="text-xs break-words">{{ account.commonId }}</td>
    </ng-container>

    <ng-container matColumnDef="purchaseStatus">
      <th mat-header-cell *matHeaderCellDef class="">課金ステータス</th>
      <td mat-cell *matCellDef="let account" class="text-sm" style="min-width: 100px;">
        {{ account.purchaseStatus }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lastPurchasedAt">
      <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">購入日時</th>
      <td mat-cell *matCellDef="let account" class="latest-app-launched-date-value text-xs">
        {{ account.lastPurchasedAt }}
      </td>
    </ng-container>

    <ng-container matColumnDef="premiumEndAt">
      <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">有効期限</th>
      <td mat-cell *matCellDef="let account" class="latest-app-launched-date-value text-xs">
        {{ account.premiumEndAt }}
      </td>
    </ng-container>

    <ng-container matColumnDef="purchasePlan">
      <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">料金プラン</th>
      <td mat-cell *matCellDef="let account" class="text-sm">{{ account.purchasePlan }}</td>
    </ng-container>

    <ng-container matColumnDef="latestAppLaunchedDate">
      <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">最終ログイン</th>
      <td mat-cell *matCellDef="let account" class="latest-app-launched-date-value text-xs">
        {{ account.latestAppLaunchedDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="updatePurchaseStatus">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;">ステータス更新</th>
      <td mat-cell *matCellDef="let account" style="text-align: center;">
        <button mat-flat-button color="primary" (click)="updateAccount(account.id)">ステータス更新</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="viewPurchaseHistory">
      <th mat-header-cell *matHeaderCellDef style="text-align: center;">購入履歴閲覧</th>
      <td mat-cell *matCellDef="let account" style="text-align: center;">
        <button mat-flat-button class="mr-2 bg-gray-50 text-primary font-normal grow w-1/2 md:w-36" (click)="goToStripe(account.id)">
          購入履歴閲覧
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</app-table-overflow>
