import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { WidgetsCardContentNavMenuData } from '../../../models/widgets-card-content-nav';

@Component({
  selector: 'app-card-content-nav',
  templateUrl: './card-content-nav.component.html',
  styleUrls: ['./card-content-nav.component.scss']
})
export class CardContentNavComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() menus: WidgetsCardContentNavMenuData[];
  @Output() menuClick = new EventEmitter<string>();
  @ViewChild('navContainer', { static: false }) navContainer: ElementRef;

  isScrolled = false;

  private navElement;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.navElement = this.navContainer.nativeElement;
    this.navElement.addEventListener('scroll', this.checkScroll.bind(this));
    this.checkScroll(); // 初期状態の確認
    this.scrollToActiveTab(); // アクティブなタブにスクロール
  }

  ngOnDestroy() {
    this.navElement.removeEventListener('scroll');
  }

  scrollToActiveTab() {
    setTimeout(() => {
      const activeTab = this.navContainer.nativeElement.querySelector('.mat-tab-label-active');
      if (activeTab) {
        const navWidth = this.navContainer.nativeElement.offsetWidth;
        const tabPosition = activeTab.offsetLeft + activeTab.offsetWidth; // タブの右端の位置
        const offset = 16 * 3; // 1rem = 16px (位置を調整しつつ、左側に要素があることがわかる値)

        if (tabPosition > navWidth) {
          // アクティブなタブの位置が.navContainerの幅より大きい場合にのみスクロール
          this.navContainer.nativeElement.scrollLeft = activeTab.offsetLeft - offset;
        }
      }
    }, 1);
  }

  checkScroll() {
    this.isScrolled = this.navElement.scrollLeft > 0;
  }
}
