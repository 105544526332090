import { Action, createReducer, on } from '@ngrx/store';

import * as JukuActions from '../actions/juku.actions';
import { Juku } from '../models/juku';
import { AppError } from '../errors/app-error';
import { DeleteJukuResponse } from '../models/delete-juku-response';

export const jukuFeatureKey = 'juku';

export interface CreateJukuResult {
  success: boolean;
  createdJuku: Juku | null;
  error: AppError | null;
}

export interface UpdateJukuResult {
  success: boolean;
  updatedJuku: Juku | null;
  error: AppError | null;
}

export interface DeleteJukuResult {
  success: boolean;
  response: DeleteJukuResponse | null;
  error: AppError | null;
}

export interface State {
  juku: Juku | null;
  jukus: Juku[] | null;
  createJukuResult: CreateJukuResult | null;
  updateJukuResult: UpdateJukuResult | null;
  deleteJukuResult: DeleteJukuResult | null;
}

export const initialState: State = {
  juku: null,
  jukus: null,
  createJukuResult: null,
  updateJukuResult: null,
  deleteJukuResult: null
};

const jukuReducer = createReducer(
  initialState,

  // Find Juku -------------------------------------------------------
  on(JukuActions.findJukuSuccess, (state, { juku }) => ({ ...state, juku })),
  on(JukuActions.initializeFindJukuState, state => {
    const next: State = { ...state, juku: null };
    return next;
  }),

  // Find Jukus -------------------------------------------------------
  on(JukuActions.findJukusSuccess, (state, { jukus }) => ({ ...state, jukus })),
  on(JukuActions.initializeFindJukusState, state => {
    const next: State = { ...state, jukus: null };
    return next;
  }),

  // Create Juku ----------------------------------------------------------------
  on(JukuActions.createJukuSuccess, (state, { juku }) => {
    const next: State = {
      ...state,
      createJukuResult: { success: true, createdJuku: juku, error: null }
    };
    return next;
  }),
  on(JukuActions.createJukuFailure, (state, { error }) => {
    const next: State = {
      ...state,
      createJukuResult: { success: false, createdJuku: null, error }
    };
    return next;
  }),
  on(JukuActions.initializeCreateJukuState, state => {
    const next: State = { ...state, createJukuResult: null };
    return next;
  }),

  // Update Juku ----------------------------------------------------------------
  on(JukuActions.updateJukuSuccess, (state, { juku }) => {
    const next: State = {
      ...state,
      updateJukuResult: { success: true, updatedJuku: juku, error: null }
    };
    return next;
  }),
  on(JukuActions.updateJukuFailure, (state, { error }) => {
    const next: State = {
      ...state,
      updateJukuResult: { success: false, updatedJuku: null, error }
    };
    return next;
  }),
  on(JukuActions.initializeUpdateJukuState, state => {
    const next: State = { ...state, updateJukuResult: null };
    return next;
  }),

  // Delete Juku ----------------------------------------------------------------
  on(JukuActions.deleteJukuSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      deleteJukuResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(JukuActions.deleteJukuFailure, (state, { error }) => {
    const next: State = {
      ...state,
      deleteJukuResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(JukuActions.initializeDeleteJukuState, state => {
    const next: State = { ...state, deleteJukuResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return jukuReducer(state, action);
}
