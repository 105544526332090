import { Action, createReducer, on } from '@ngrx/store';

import * as CommonIdUniversityBookmarkActions from '../../actions/common-id/common-id-university-bookmark.actions';
import { AppError } from '../../errors/app-error';
import { AddUniversityBookmarkResponse } from '../../models/common-id/common-id-add-bookmark-response';
import { DeleteUniversityBookmarkResponse } from '../../models/common-id/common-id-delete-bookmark-response';
import { UniversityBookmark } from '../../models/common-id/common-id-bookmark';

export const commonIdUniversityBookmarkFeatureKey = 'commonIdUniversityBookmark';

export interface AddUniversiyBookmarkResult {
  success: boolean;
  response: AddUniversityBookmarkResponse | null;
  error: AppError | null;
}

export interface DeleteUniversityBookmarkResult {
  success: boolean;
  response: DeleteUniversityBookmarkResponse | null;
  error: AppError | null;
}

export interface State {
  universityBookmark: UniversityBookmark | null;
  addUniversityBookmarkResult: AddUniversiyBookmarkResult | null;
  deleteUniversityBookmarkResult: DeleteUniversityBookmarkResult | null;

  // universityBookmarkCount: number | null;
  // isUniversityBookmarkSearching: boolean;
  isUniversityBookmarkInTheProcess: boolean;

  // BookmarkUniversities: string | null;
}

export const initialState: State = {
  universityBookmark: null,
  addUniversityBookmarkResult: null,
  deleteUniversityBookmarkResult: null,
  isUniversityBookmarkInTheProcess: false
  // universityBookmarkCount: 0,
  // isUniversityBookmarkSearching: false,
  // BookmarkUniversities: null
};

const CommonIdUniversityBookmarkReducer = createReducer(
  initialState,

  // お気に入り大学の検索をする場合 -------------------------------------------------------
  on(CommonIdUniversityBookmarkActions.findUniversityBookmarkSuccess, (state, { universityBookmark }) => ({
    ...state,
    universityBookmark
  })),
  on(CommonIdUniversityBookmarkActions.initializeFindUniversityBookmarkState, state => {
    const next: State = { ...state, universityBookmark: null };
    return next;
  }),

  // お気に入り大学の追加をする場合 ------------------------------------------------------------
  on(CommonIdUniversityBookmarkActions.addUniversityBookmark, state => ({ ...state, isUniversityBookmarkInTheProcess: true })),
  on(CommonIdUniversityBookmarkActions.addUniversityBookmarkSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      addUniversityBookmarkResult: { success: true, response, error: null },
      isUniversityBookmarkInTheProcess: false
    };
    return next;
  }),
  on(CommonIdUniversityBookmarkActions.addUniversityBookmarkFailure, (state, { error }) => {
    const next: State = {
      ...state,
      addUniversityBookmarkResult: { success: false, response: null, error },
      isUniversityBookmarkInTheProcess: false
    };
    return next;
  }),
  on(CommonIdUniversityBookmarkActions.initializeAddUniversityBookmarkState, state => {
    const next: State = { ...state, addUniversityBookmarkResult: null };
    return next;
  }),

  // お気に入り大学の削除をする場合 ------------------------------------------------------------
  on(CommonIdUniversityBookmarkActions.deleteUniversityBookmark, state => ({ ...state, isUniversityBookmarkInTheProcess: true })),
  on(CommonIdUniversityBookmarkActions.deleteUniversityBookmarkSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      deleteUniversityBookmarkResult: { success: true, response, error: null },
      isUniversityBookmarkInTheProcess: false
    };
    return next;
  }),
  on(CommonIdUniversityBookmarkActions.deleteUniversityBookmarkFailure, (state, { error }) => {
    const next: State = {
      ...state,
      deleteUniversityBookmarkResult: { success: false, response: null, error },
      isUniversityBookmarkInTheProcess: false
    };
    return next;
  }),
  on(CommonIdUniversityBookmarkActions.initializeDeleteUniversityBookmarkState, state => {
    const next: State = { ...state, deleteUniversityBookmarkResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return CommonIdUniversityBookmarkReducer(state, action);
}
