import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, playlistFeatureKey, ResolvePdfObjectUrlResult } from '../reducers/playlist.reducer';
import {
  getSubject,
  getStaticCommonData,
  getStaticEnglishData,
  getStaticMathData,
  getStaticPhysicsData,
  getStaticChemistryData,
  getStaticBiologyData,
  getStaticNationalLanguageData,
  getStaticJapaneseHistoryData,
  getStaticWorldHistoryData,
  getStaticGeographyData,
  getStaticPoliticalEconomyData
} from './static-data.selectors';
import { SubjectWithPlaylist } from '../models/playlist';
import { StaticCommonData } from '../models/static-common-data';
import { StaticEnglishData } from '../models/static-english-data';
import {
  EnglishPlaylistProblem,
  ReadableEnglishPlaylistProblem,
  SciencePlaylistProblem,
  ReadableSciencePlaylistProblem,
  NationalLanguagePlaylistProblem,
  ReadableNationalLanguagePlaylistProblem,
  HistoryPlaylistProblem,
  ReadableHistoryPlaylistProblem
} from '../models/problem';
import { ReadableDataMapper } from '../mappers/readable-data-mapper';
import { StaticScienceData } from '../models/static-science-data';
import { StaticNationalLanguageData } from '../models/static-national-language-data';
import { StaticHistoryData } from '../models/static-history-data';

const selectPlaylistState = createFeatureSelector<State>(playlistFeatureKey);

export const getPlaylists = createSelector(selectPlaylistState, state => state.playlists);

const getResolvePdfObjectUrlResults = createSelector(selectPlaylistState, state => state.resolvePdfObjectUrlResults);

export const getSpecificResolvePdfObjectUrlResult = (pdfPath: string) =>
  createSelector(getResolvePdfObjectUrlResults, (resolvePdfObjectUrlResults): ResolvePdfObjectUrlResult | null => {
    if (resolvePdfObjectUrlResults.length === 0) return null;
    const result = resolvePdfObjectUrlResults.find(it => it.pdfPath === pdfPath);
    return result ? result : null;
  });

export const getSubjectsWithPlaylist = createSelector(getSubject, getPlaylists, (subjects, playlists): SubjectWithPlaylist[] => {
  if (!subjects || !playlists) return null;
  return subjects.map(subject => {
    const withPlaylist: SubjectWithPlaylist = {
      subjectId: subject.id,
      subjectName: subject.name,
      playlists: playlists.filter(playlist => playlist.subjectId === subject.id)
    };
    return withPlaylist;
  });
});

export const getPlaylistProblemIds = createSelector(selectPlaylistState, state => state.playlistProblemIds);
export const getEnglishPlaylistProblems = createSelector(selectPlaylistState, state => state.englishPlaylistProblems);
export const getMathPlaylistProblems = createSelector(selectPlaylistState, state => state.mathPlaylistProblems);
export const getNationalLanguagePlaylistProblems = createSelector(selectPlaylistState, state => state.nationalLanguagePlaylistProblems);
export const getPhysicsPlaylistProblems = createSelector(selectPlaylistState, state => state.physicsPlaylistProblems);
export const getChemistryPlaylistProblems = createSelector(selectPlaylistState, state => state.chemistryPlaylistProblems);
export const getBiologyPlaylistProblems = createSelector(selectPlaylistState, state => state.biologyPlaylistProblems);
export const getJapaneseHistoryPlaylistProblems = createSelector(selectPlaylistState, state => state.japaneseHistoryPlaylistProblems);
export const getWorldHistoryPlaylistProblems = createSelector(selectPlaylistState, state => state.worldHistoryPlaylistProblems);
export const getGeographyPlaylistProblems = createSelector(selectPlaylistState, state => state.geographyPlaylistProblems);
export const getPoliticalEconomyPlaylistProblems = createSelector(selectPlaylistState, state => state.politicalEconomyPlaylistProblems);

export const getReadableEnglishPlaylistProblems = (englishProblems: EnglishPlaylistProblem[]) =>
  createSelector(getStaticCommonData, getStaticEnglishData, (staticCommonData: StaticCommonData, staticEnglishData: StaticEnglishData):
    | ReadableEnglishPlaylistProblem[]
    | null => {
    if (!staticCommonData || !staticEnglishData || !englishProblems) return null;
    return englishProblems.map(problem => ReadableDataMapper.mapEnglishPlaylistProblem(problem, staticCommonData, staticEnglishData));
  });

export const getReadableMathPlaylistProblems = (mathProblems: SciencePlaylistProblem[]) =>
  createSelector(getStaticCommonData, getStaticMathData, (staticCommonData: StaticCommonData, staticMathData: StaticScienceData):
    | ReadableSciencePlaylistProblem[]
    | null => {
    if (!staticCommonData || !staticMathData || !mathProblems) return null;
    return mathProblems.map(problem => ReadableDataMapper.mapSciencePlaylistProblem(problem, staticCommonData, staticMathData));
  });

export const getReadableNationalLanguagePlaylistProblems = (nationalLanguageProblems: NationalLanguagePlaylistProblem[]) =>
  createSelector(
    getStaticCommonData,
    getStaticNationalLanguageData,
    (
      staticCommonData: StaticCommonData,
      staticNationalLanguageData: StaticNationalLanguageData
    ): ReadableNationalLanguagePlaylistProblem[] | null => {
      if (!staticCommonData || !staticNationalLanguageData || !nationalLanguageProblems) return null;
      return nationalLanguageProblems.map(problem =>
        ReadableDataMapper.mapNationalLanguagePlaylistProblem(problem, staticCommonData, staticNationalLanguageData)
      );
    }
  );

export const getReadablePhysicsPlaylistProblems = (physicsProblems: SciencePlaylistProblem[]) =>
  createSelector(getStaticCommonData, getStaticPhysicsData, (staticCommonData: StaticCommonData, staticPhysicsData: StaticScienceData):
    | ReadableSciencePlaylistProblem[]
    | null => {
    if (!staticCommonData || !staticPhysicsData || !physicsProblems) return null;
    return physicsProblems.map(problem => ReadableDataMapper.mapSciencePlaylistProblem(problem, staticCommonData, staticPhysicsData));
  });

export const getReadableChemistryPlaylistProblems = (chemistryProblems: SciencePlaylistProblem[]) =>
  createSelector(getStaticCommonData, getStaticChemistryData, (staticCommonData: StaticCommonData, staticChemistryData: StaticScienceData):
    | ReadableSciencePlaylistProblem[]
    | null => {
    if (!staticCommonData || !staticChemistryData || !chemistryProblems) return null;
    return chemistryProblems.map(problem => ReadableDataMapper.mapSciencePlaylistProblem(problem, staticCommonData, staticChemistryData));
  });

export const getReadableBiologyPlaylistProblems = (biologyProblems: SciencePlaylistProblem[]) =>
  createSelector(getStaticCommonData, getStaticBiologyData, (staticCommonData: StaticCommonData, staticBiologyData: StaticScienceData):
    | ReadableSciencePlaylistProblem[]
    | null => {
    if (!staticCommonData || !staticBiologyData || !biologyProblems) return null;
    return biologyProblems.map(problem => ReadableDataMapper.mapSciencePlaylistProblem(problem, staticCommonData, staticBiologyData));
  });

export const getReadableJapaneseHistoryPlaylistProblems = (japaneseHistoryProblems: HistoryPlaylistProblem[]) =>
  createSelector(
    getStaticCommonData,
    getStaticJapaneseHistoryData,
    (staticCommonData: StaticCommonData, staticJapaneseHistoryData: StaticHistoryData): ReadableHistoryPlaylistProblem[] | null => {
      if (!staticCommonData || !staticJapaneseHistoryData || !japaneseHistoryProblems) return null;
      return japaneseHistoryProblems.map(problem =>
        ReadableDataMapper.mapHistoryPlaylistProblem(problem, staticCommonData, staticJapaneseHistoryData)
      );
    }
  );

export const getReadableWorldHistoryPlaylistProblems = (worldHistoryProblems: HistoryPlaylistProblem[]) =>
  createSelector(
    getStaticCommonData,
    getStaticWorldHistoryData,
    (staticCommonData: StaticCommonData, staticWorldHistoryData: StaticHistoryData): ReadableHistoryPlaylistProblem[] | null => {
      if (!staticCommonData || !staticWorldHistoryData || !worldHistoryProblems) return null;
      return worldHistoryProblems.map(problem =>
        ReadableDataMapper.mapHistoryPlaylistProblem(problem, staticCommonData, staticWorldHistoryData)
      );
    }
  );

export const getReadableGeographyPlaylistProblems = (geographyProblems: SciencePlaylistProblem[]) =>
  createSelector(getStaticCommonData, getStaticGeographyData, (staticCommonData: StaticCommonData, staticGeographyData: StaticScienceData):
    | ReadableSciencePlaylistProblem[]
    | null => {
    if (!staticCommonData || !staticGeographyData || !geographyProblems) return null;
    return geographyProblems.map(problem => ReadableDataMapper.mapSciencePlaylistProblem(problem, staticCommonData, staticGeographyData));
  });

export const getReadablePoliticalEconomyPlaylistProblems = (politicalEconomyProblems: SciencePlaylistProblem[]) =>
  createSelector(
    getStaticCommonData,
    getStaticPoliticalEconomyData,
    (staticCommonData: StaticCommonData, staticPoliticalEconomyData: StaticScienceData): ReadableSciencePlaylistProblem[] | null => {
      if (!staticCommonData || !staticPoliticalEconomyData || !politicalEconomyProblems) return null;
      return politicalEconomyProblems.map(problem =>
        ReadableDataMapper.mapSciencePlaylistProblem(problem, staticCommonData, staticPoliticalEconomyData)
      );
    }
  );
