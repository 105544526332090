import { createAction, props } from '@ngrx/store';

import { Bookmark } from '../models/bookmark';
import { AddBookmarkResponse } from '../models/add-bookmark-response';
import { AppError } from '../errors/app-error';
import { DeleteBookmarkResponse } from '../models/delete-bookmark-response';
import { EnglishProblem, HistoryProblem, NationalLanguageProblem, ScienceProblem } from '../models/problem';
import { SearchBookmarkCondition } from '../models/search-bookmark-condition';
import { SearchBookmarkCountResponse } from '../models/search-bookmark-count-response';

// findBookmarks ------------------------------------------------------------
export const findBookmark = createAction('[Bookmark] Find Bookmark', props<{ userId: string }>());
export const findBookmarkSuccess = createAction('[Bookmark] Find Bookmark Success', props<{ bookmark: Bookmark }>());
export const findBookmarkFailure = createAction('[Bookmark] Find Bookmark Failure');
export const initializeFindBookmarkState = createAction('[Bookmark] Initialize Find Bookmark State');

// addBookmarks ------------------------------------------------------------
export const addBookmark = createAction('[Bookmark] Add Bookmark', props<{ userId: string; subjectId: string; problemId: string }>());
export const addBookmarkSuccess = createAction('[Bookmark] Add Bookmark Success', props<{ response: AddBookmarkResponse }>());
export const addBookmarkFailure = createAction('[Bookmark] Add Bookmark Failure', props<{ error: AppError }>());
export const initializeAddBookmarkState = createAction('[Bookmark] Initialize Add Bookmark State');

// deleteBookmarks ------------------------------------------------------------
export const deleteBookmark = createAction('[Bookmark] Delete Bookmark', props<{ userId: string; subjectId: string; problemId: string }>());
export const deleteBookmarkSuccess = createAction('[Bookmark] Delete Bookmark Success', props<{ response: DeleteBookmarkResponse }>());
export const deleteBookmarkFailure = createAction('[Bookmark] Delete Bookmark Failure', props<{ error: AppError }>());
export const initializeDeleteBookmarkState = createAction('[Bookmark] Initialize Delete Bookmark State');

// findBookmarkCount ------------------------------------------------------------
export const findBookmarkCount = createAction('[Bookmark] Search Bookmark Count', props<{ userId: string; subjectId: string }>());
export const findBookmarkCountSuccess = createAction(
  '[Bookmark] Search Bookmark Count Success',
  props<{ response: SearchBookmarkCountResponse }>()
);
export const findBookmarkCountFailure = createAction('[Bookmark] Search Bookmark Count Failure');
export const initializeFindBookmarkCountState = createAction('[Bookmark] Initialize Search Bookmark Count State');

// searchBookmark ------------------------------------------------------------
// English
export const findEnglishBookmark = createAction('[Bookmark] Search English Bookmark', props<{ condition: SearchBookmarkCondition }>());
export const findEnglishBookmarkSuccess = createAction(
  '[Bookmark] Search English Bookmark Success',
  props<{ problems: EnglishProblem[] }>()
);
export const findEnglishBookmarkFailure = createAction('[Bookmark] Search English Bookmark Failure');
export const initializeFindEnglishBookmarkState = createAction('[Bookmark] Initialize Search English Bookmark State');

// Math
export const findMathBookmark = createAction('[Bookmark] Search Math Bookmark', props<{ condition: SearchBookmarkCondition }>());
export const findMathBookmarkSuccess = createAction('[Bookmark] Search Math Bookmark Success', props<{ problems: ScienceProblem[] }>());
export const findMathBookmarkFailure = createAction('[Bookmark] Search Math Bookmark Failure');
export const initializeFindMathBookmarkState = createAction('[Bookmark] Initialize Search Math Bookmark State');

// NationalLanguage
export const findNationalLanguageBookmark = createAction(
  '[Bookmark] Search NationalLanguage Bookmark',
  props<{ condition: SearchBookmarkCondition }>()
);
export const findNationalLanguageBookmarkSuccess = createAction(
  '[Bookmark] Search NationalLanguage Bookmark Success',
  props<{ problems: NationalLanguageProblem[] }>()
);
export const findNationalLanguageBookmarkFailure = createAction('[Bookmark] Search NationalLanguage Bookmark Failure');
export const initializeFindNationalLanguageBookmarkState = createAction('[Bookmark] Initialize Search NationalLanguage Bookmark State');

// Physics
export const findPhysicsBookmark = createAction('[Bookmark] Search Physics Bookmark', props<{ condition: SearchBookmarkCondition }>());
export const findPhysicsBookmarkSuccess = createAction(
  '[Bookmark] Search Physics Bookmark Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findPhysicsBookmarkFailure = createAction('[Bookmark] Search Physics Bookmark Failure');
export const initializeFindPhysicsBookmarkState = createAction('[Bookmark] Initialize Search Physics Bookmark State');

// Chemistry
export const findChemistryBookmark = createAction('[Bookmark] Search Chemistry Bookmark', props<{ condition: SearchBookmarkCondition }>());
export const findChemistryBookmarkSuccess = createAction(
  '[Bookmark] Search Chemistry Bookmark Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findChemistryBookmarkFailure = createAction('[Bookmark] Search Chemistry Bookmark Failure');
export const initializeFindChemistryBookmarkState = createAction('[Bookmark] Initialize Search Chemistry Bookmark State');

// Biology
export const findBiologyBookmark = createAction('[Bookmark] Search Biology Bookmark', props<{ condition: SearchBookmarkCondition }>());
export const findBiologyBookmarkSuccess = createAction(
  '[Bookmark] Search Biology Bookmark Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findBiologyBookmarkFailure = createAction('[Bookmark] Search Biology Bookmark Failure');
export const initializeFindBiologyBookmarkState = createAction('[Bookmark] Initialize Search Biology Bookmark State');

// JapaneseHistory
export const findJapaneseHistoryBookmark = createAction(
  '[Bookmark] Search JapaneseHistory Bookmark',
  props<{ condition: SearchBookmarkCondition }>()
);
export const findJapaneseHistoryBookmarkSuccess = createAction(
  '[Bookmark] Search JapaneseHistory Bookmark Success',
  props<{ problems: HistoryProblem[] }>()
);
export const findJapaneseHistoryBookmarkFailure = createAction('[Bookmark] Search JapaneseHistory Bookmark Failure');
export const initializeFindJapaneseHistoryBookmarkState = createAction('[Bookmark] Initialize Search JapaneseHistory Bookmark State');

// WorldHistory
export const findWorldHistoryBookmark = createAction(
  '[Bookmark] Search WorldHistory Bookmark',
  props<{ condition: SearchBookmarkCondition }>()
);
export const findWorldHistoryBookmarkSuccess = createAction(
  '[Bookmark] Search WorldHistory Bookmark Success',
  props<{ problems: HistoryProblem[] }>()
);
export const findWorldHistoryBookmarkFailure = createAction('[Bookmark] Search WorldHistory Bookmark Failure');
export const initializeFindWorldHistoryBookmarkState = createAction('[Bookmark] Initialize Search WorldHistory Bookmark State');

// Geography
export const findGeographyBookmark = createAction('[Bookmark] Search Geography Bookmark', props<{ condition: SearchBookmarkCondition }>());
export const findGeographyBookmarkSuccess = createAction(
  '[Bookmark] Search Geography Bookmark Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findGeographyBookmarkFailure = createAction('[Bookmark] Search Geography Bookmark Failure');
export const initializeFindGeographyBookmarkState = createAction('[Bookmark] Initialize Search Geography Bookmark State');

// Political Economy
export const findPoliticalEconomyBookmark = createAction(
  '[Bookmark] Search Political Economy Bookmark',
  props<{ condition: SearchBookmarkCondition }>()
);
export const findPoliticalEconomyBookmarkSuccess = createAction(
  '[Bookmark] Search Political Economy Bookmark Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findPoliticalEconomyBookmarkFailure = createAction('[Bookmark] Search Political Economy Bookmark Failure');
export const initializeFindPoliticalEconomyBookmarkState = createAction('[Bookmark] Initialize Search Political Economy Bookmark State');
