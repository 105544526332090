import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-problem-detail-separater',
  templateUrl: './problem-detail-separater.component.html',
  styleUrls: ['./problem-detail-separater.component.scss']
})
export class ProblemDetailSeparaterComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
