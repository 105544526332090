import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, mapTo, of, switchMap, map } from 'rxjs';
import { FirestoreError } from 'src/app/errors/firestore-error';
import { CommonIdUserService } from 'src/app/services/common-id/common-id-user.service';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';

import * as CommonIdAuthActions from '../../actions/common-id/common-id-auth.actions';
import { dispatchAppError, openWindow } from '../../actions/core.actions';
import { CommonIdAuthError } from '../../errors/common-id-auth-error';
import { FirebaseAuthError } from '../../errors/firebase-auth-error';
import { CommonIdAuthService } from '../../services/common-id/common-id-auth.service';
import { FirebaseCallableFunctionError } from '../../errors/firebase-callable-function-error';
import { CommonIdSavePurchaseService } from '../../services/common-id/common-id-save-purchase.service';
import { FirebaseCallableFunctionErrorInfo } from '../../errors/error-info';
import { RoutingPathResolver } from '../../app-routing-path-resolver';

@Injectable()
export class CommonIdAuthEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdSignIn$ = createEffect(() =>
    this.actions$.pipe(
      // commonIdSignInアクションを処理
      ofType(CommonIdAuthActions.commonIdSignIn),
      exhaustMap(action =>
        // commonIdSignInでAPI接続
        this.commonIdAuthService.commonIdSignIn(action.customToken).pipe(
          switchMap(user => {
            // userがundefinedの場合
            if (!user) {
              return of(
                CommonIdAuthActions.commonIdSignInFailure(),
                dispatchAppError({
                  source: this.LOG_SOURCE,
                  error: CommonIdAuthError.userNotFound('user data not found')
                }),
                CommonIdAuthActions.commonIdSignOut()
              );
            }
            return of(CommonIdAuthActions.commonIdSignInSuccess({ user }));
          }),
          catchError(err => {
            const error = err instanceof CommonIdAuthError ? err : FirebaseAuthError.from(err);
            return of(
              CommonIdAuthActions.commonIdSignInFailure(),
              dispatchAppError({
                source: this.LOG_SOURCE,
                error
              }),
              CommonIdAuthActions.commonIdSignOut()
            );
          })
        )
      )
    )
  );

  commonIdSignOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdAuthActions.commonIdSignOut),
      exhaustMap(() =>
        this.commonIdAuthService.commonIdSignOut().pipe(
          mapTo(CommonIdAuthActions.commonIdSignOutSuccess()),
          catchError(err =>
            of(
              CommonIdAuthActions.commonIdSignOutFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseAuthError.from(err) })
            )
          )
        )
      )
    )
  );

  findCommonIdSignedInUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdAuthActions.findCommonIdSignedInUser),
      switchMap(() =>
        this.commonIdAuthService.signedInUserId$.pipe(
          switchMap(userId => {
            return userId === ''
              ? of(CommonIdAuthActions.findCommonIdSignedInUserFailure())
              : this.commonIdUserService.findCommonIdUser(userId).pipe(
                  map(user =>
                    user != null
                      ? CommonIdAuthActions.findCommonIdSignedInUserSuccess({ user: CommonIdUserUtil.setDefaultValues(user) })
                      : CommonIdAuthActions.findCommonIdSignedInUserFailure()
                  ),
                  catchError(err =>
                    of(
                      CommonIdAuthActions.findCommonIdSignedInUserFailure(),
                      dispatchAppError({
                        source: this.LOG_SOURCE,
                        error: FirestoreError.from(err)
                      })
                    )
                  )
                );
          })
        )
      )
    )
  );

  commonIdSavePurchase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdAuthActions.commonIdSavePurchase),
      switchMap(action =>
        this.commonIdSavePurchaseService.commonIdSavePurchase(action.condition).pipe(
          map(response => CommonIdAuthActions.commonIdSavePurchaseSuccess({ response, result: true })),
          catchError(e => {
            if (e.details && e.details.code === FirebaseCallableFunctionErrorInfo.COMMON_ID_ALREADY_PURCHASED.code) {
              // StripeのコールバックURLに複数回アクセスされてしまった場合
              return of(openWindow({ url: RoutingPathResolver.resolveCommonIdSearchUniv(), target: '_self' }));
            } else {
              return of(CommonIdAuthActions.commonIdSavePurchaseFailure({ error: FirebaseCallableFunctionError.from(e) }));
            }
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private commonIdAuthService: CommonIdAuthService,
    private commonIdUserService: CommonIdUserService,
    private commonIdSavePurchaseService: CommonIdSavePurchaseService
  ) {}
}
