<!-- ▼ 印刷時のみ表示 ここから -->
<div class="playlist-problem-detail-show-print-only-wrapper" data-print-only *ngIf="problem$ | async">
  <app-problem-detail-frame-for-print
    [menuType]="menuType$ | async"
    [universities]="universities$ | async"
    [readableScienceProblems]="readableScienceProblems$ | async"
    [readableEnglishProblems]="readableEnglishProblems$ | async"
    [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
    [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
    [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
    [sciencePlaylistTheme]="sciencePlaylistTheme$ | async"
    [englishPlaylistTheme]="englishPlaylistTheme$ | async"
    [nationalLanguagePlaylistTheme]="nationalLanguagePlaylistTheme$ | async"
    [japaneseHistoryPlaylistTheme]="japaneseHistoryPlaylistTheme$ | async"
    [worldHistoryPlaylistTheme]="worldHistoryPlaylistTheme$ | async"
    [playlist]="playlist$ | async"
    [theme]="theme$ | async"
    [paperMode]="paperMode$ | async"
    [trialMode]="trialMode$ | async"
    [subjectId]="subjectId$ | async"
    [isBToC]="true"
  ></app-problem-detail-frame-for-print>
</div>
<!-- △ 印刷時のみ表示 ここまで -->

<!-- ▼ 印刷時は消す ここから -->
<div *ngIf="(theme$ | async) !== null && (playListProblem$ | async) !== null" data-no-print>
  <app-content-row data-no-print>
    <app-wrapper size="fluid" isFitWithSmallScreen="true">
      <app-breadcrumbs
        (breadcrumbsClick)="breadcrumbsClickHandler($event)"
        [breadcrumbs]="[
        { key: 'playlist/detail', label: (playlist$ | async).seriesName + ' | ' + (playlist$ | async).name },
          { key: 'second', label: (theme$ | async).name },
        ]"
      ></app-breadcrumbs>
    </app-wrapper>
  </app-content-row>

  <div data-no-print>
    <div *ngIf="!isPreconditionError">
      <div [@fadeInOut]="(searching$ | async) === false && (loadingReadableProblems$ | async) === false">
        <app-wrapper size="fluid" isFitWithSmallScreen="true" class="playlist-problem-detail-meta">
          <app-card-content>
            <div class="playlist-problem-detail-heading">
              <div class="flex items-center">
                <button
                  mat-flat-button
                  color="primary"
                  [disabled]="isFirstProblem$ | async"
                  (click)="showPreviousProblem()"
                  *ngIf="(fromAnsweredProblems$ | async) === false"
                >
                  <mat-icon aria-hidden="true">arrow_back</mat-icon> 前の問題
                </button>
                <div class="playlist-problem-detail-title">
                  <b class="">{{ (theme$ | async).order }} - {{ (playListProblem$ | async).orderInTheme }}</b>
                  <div role="heading" class=" hidden md:block">
                    <ng-container *ngIf="(playListProblem$ | async).isOriginalProblem">
                      {{ (playListProblem$ | async).originalOverview }}
                      <ng-container *ngIf="(playListProblem$ | async).showProblemNumberFlg">
                        {{ (playListProblem$ | async).problemNumber }}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!(playListProblem$ | async).isOriginalProblem">
                      {{ (playListProblem$ | async).university }} {{ (playListProblem$ | async).year }}年度
                      {{ (playListProblem$ | async).problemNumber }}
                    </ng-container>
                    <ng-container *ngIf="playListProblem$ | async as playListProblem">
                      <button (click)="playListProblem.isOriginalProblem ? showPaperSourceInformation() : showPaperInformation()">
                        <mat-icon matTooltip="この問題の情報" matTooltipPosition="above" class="text-primary material-icons-outlined"
                          >info</mat-icon
                        >
                      </button>
                    </ng-container>
                  </div>
                </div>
                <button
                  mat-flat-button
                  color="primary"
                  [disabled]="isLastProblem$ | async"
                  (click)="showNextProblem()"
                  *ngIf="(fromAnsweredProblems$ | async) === false"
                >
                  次の問題 <mat-icon aria-hidden="true">arrow_forward</mat-icon>
                </button>
              </div>
            </div>
            <hr class="block md:hidden" />
            <app-wrapper size="fluid" class="block md:hidden">
              <app-content-row>
                <div class="flex items-center">
                  <div role="heading" class="text-base font-bold grow">
                    <ng-container *ngIf="(playListProblem$ | async).isOriginalProblem">
                      {{ (playListProblem$ | async).originalOverview }}
                      <ng-container *ngIf="(playListProblem$ | async).showProblemNumberFlg">
                        {{ (playListProblem$ | async).problemNumber }}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!(playListProblem$ | async).isOriginalProblem">
                      {{ (playListProblem$ | async).university }} {{ (playListProblem$ | async).year }}年度
                      {{ (playListProblem$ | async).problemNumber }}
                    </ng-container>
                  </div>
                  <ng-container *ngIf="playListProblem$ | async as playListProblem">
                    <button (click)="playListProblem.isOriginalProblem ? showPaperSourceInformation() : showPaperInformation()">
                      <mat-icon matTooltip="この問題の情報" matTooltipPosition="above" class="text-primary material-icons-outlined"
                        >info</mat-icon
                      >
                    </button>
                  </ng-container>
                </div>
              </app-content-row>
            </app-wrapper>
          </app-card-content>
        </app-wrapper>

        <app-content-row *ngIf="(problem$ | async)?.hasExternalData; else noExternalData">
          <app-wrapper size="fluid" isFitWithSmallScreen="true">
            <!-- ▼ テスト表示領域 -->
            <app-problem-detail-frame
              [readableScienceProblems]="readableScienceProblems$ | async"
              [readableEnglishProblems]="readableEnglishProblems$ | async"
              [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
              [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
              [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
              [sciencePlaylistTheme]="sciencePlaylistTheme$ | async"
              [englishPlaylistTheme]="englishPlaylistTheme$ | async"
              [nationalLanguagePlaylistTheme]="nationalLanguagePlaylistTheme$ | async"
              [japaneseHistoryPlaylistTheme]="japaneseHistoryPlaylistTheme$ | async"
              [worldHistoryPlaylistTheme]="worldHistoryPlaylistTheme$ | async"
              [paperMode]="paperMode$ | async"
              [trialMode]="trialMode$ | async"
              [hidePaperProblemBtn]="hidePaperProblemBtn"
              [isAnswered]="isAnswered$ | async"
              [showComment]="true"
              [showSaveAnsweredBtn]="true"
              [disabledSaveAnsweredBtn]="isPremiumUser === false"
              [subjectId]="subjectId$ | async"
              (menuTypeChangeHandler)="changeMenuType($event)"
              (paperClickHandler)="showPaper()"
              (printClickHandler)="showPrint()"
              (saveAnsweredClick)="saveAnswered()"
            ></app-problem-detail-frame>
            <!-- △ テスト表示領域 -->
          </app-wrapper>
        </app-content-row>
      </div>

      <div class="progress-48" [@fadeInOut]="(searching$ | async) || (loadingReadableProblems$ | async)">
        <mat-spinner [diameter]="48"></mat-spinner>
      </div>
    </div>

    <ng-template #noExternalData>
      <div class="text-center text-nega" *ngIf="(searching$ | async) === false && (loadingReadableProblems$ | async) === false">
        <app-problem-detail-no-external-data></app-problem-detail-no-external-data>
      </div>
    </ng-template>
  </div>
</div>
<!-- △ 印刷時は消す ここまで -->
