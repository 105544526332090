import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { CHEER_UP_MESSAGES } from 'src/app/resources/common-id/cheer-up-messages';
import { enter } from '../../../../resources/animations';

@Component({
  selector: 'app-common-id-complete-answered-dialog',
  templateUrl: './complete-answered-dialog.component.html',
  styleUrls: ['./complete-answered-dialog.component.scss'],
  animations: [enter]
})
export class CommonIdCompleteAnsweredDialogComponent implements OnInit {
  disabled = true;
  private LOG_SOURCE = this.constructor.name;
  randomMessage: string;

  constructor(private store: Store<RootState>, private dialogRef: MatDialogRef<CommonIdCompleteAnsweredDialogComponent>) {}

  ngOnInit() {
    // 応援メッセージをランダムに設定
    const randomIndex = Math.floor(Math.random() * CHEER_UP_MESSAGES.length);
    this.randomMessage = CHEER_UP_MESSAGES[randomIndex];
  }

  close() {
    this.dialogRef.close();
  }
}
