import { Component, Input, OnInit } from '@angular/core';
import { UNIVERSITY_TYPE } from '../../../resources/config';

@Component({
  selector: 'app-univ-cat-mark',
  templateUrl: './univ-cat-mark.component.html',
  styleUrls: ['./univ-cat-mark.component.scss']
})
export class UnivCatMarkComponent implements OnInit {
  constructor() {}

  @Input() isDeletable = true;
  @Input() univType: string;
  @Input() univCatType: string;
  @Input() univCatPref: string;

  univMarkType: string;

  ngOnInit() {
    switch (this.univType) {
      case UNIVERSITY_TYPE.KOKURITSU:
        this.univMarkType = 'kokuritsu';
        break;
      case UNIVERSITY_TYPE.KOURITSU:
        this.univMarkType = 'kouritsu';
        break;
      case UNIVERSITY_TYPE.SHIRITSU:
        this.univMarkType = 'shiritsu';
        break;
      default:
        this.univMarkType = 'other';
        break;
    }
  }
}
