<app-search-univ-detail-base
  *ngIf="isLoadingFinished$ | async"
  [isBToC]="false"
  [universityDetails$]="universityDetails$"
  [searchResults]="searchResults"
  [years]="years"
  [selectedYear]="selectedYear"
  [universityId]="universityId"
  (yearsChangeClick)="onYearsChange($event)"
  (goToPaperDetailClick)="goToPaperDetail($event)"
></app-search-univ-detail-base>

<div *ngIf="(isLoadingFinished$ | async) === false">
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</div>
