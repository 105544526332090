import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FindUniversityPapersResult } from 'src/app/models/find-university-papers-result';
import { GoToPaperDetailParams } from '../../../models/search-univ-interfaces';

@Component({
  selector: 'app-search-univ-result-base',
  templateUrl: './search-univ-result-base.component.html',
  styleUrls: ['./search-univ-result-base.component.scss']
})
export class SearchUnivResultBaseComponent implements OnInit {
  @Input() searchResults: FindUniversityPapersResult[];
  @Input() isPremiumIconShown: boolean;
  @Input() isBtoC: boolean;
  @Input() buttonAbled$: Observable<boolean>;
  @Input() bookmarkClickEmitted: boolean;
  @Input() errorUnivId$: Observable<string>;
  @Output() goToPaperDetailClick = new EventEmitter<GoToPaperDetailParams>();
  @Output() goToUnivDetailClick = new EventEmitter<string>();
  @Output() addUniversityBookmarkClick = new EventEmitter<string>();
  @Output() deleteUniversityBookmarkClick = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}
}
