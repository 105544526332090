import { createFeatureSelector, createSelector } from '@ngrx/store';
import { searchFeatureKey, State } from '../reducers/search.reducer';

import { CommonSearchCondition, ReadableCommonSearchCondition } from '../models/common-search-condition';
import { ReadableDataMapper } from '../mappers/readable-data-mapper';
import { EnglishSearchCondition, ReadableEnglishSearchCondition } from '../models/english-search-condition';
import { HistorySearchCondition, ReadableHistorySearchCondition } from '../models/history-search-condition';
import {
  EnglishProblem,
  HistoryProblem,
  NationalLanguageProblem,
  ReadableEnglishProblem,
  ReadableHistoryProblem,
  ReadableNationalLanguageProblem,
  ReadableScienceProblem,
  ScienceProblem
} from '../models/problem';
import { StaticCommonData } from '../models/static-common-data';
import { StaticScienceData } from '../models/static-science-data';
import { StaticHistoryData } from '../models/static-history-data';
import {
  getStaticCommonData,
  getStaticEnglishData,
  getStaticMathData,
  getStaticNationalLanguageData,
  getStaticPhysicsData,
  getStaticChemistryData,
  getStaticBiologyData,
  getStaticJapaneseHistoryData,
  getStaticWorldHistoryData,
  getStaticGeographyData,
  getStaticPoliticalEconomyData
} from './static-data.selectors';
import { StaticEnglishData } from '../models/static-english-data';
import { StaticNationalLanguageData } from '../models/static-national-language-data';
import { NationalLanguageSearchCondition, ReadableNationalLanguageSearchCondition } from '../models/national-language-search-condition';

const selectSearchState = createFeatureSelector<State>(searchFeatureKey);

export const getSearchCondition = createSelector(selectSearchState, state => state.searchCondition);

export const getMatchedProblemCount = createSelector(selectSearchState, state => state.matchedProblemCount);

export const getProblemCountSearching = createSelector(selectSearchState, state => state.problemCountSearching);

export const getProblemsSearching = createSelector(selectSearchState, state => state.problemsSearching);

export const getEnglishProblems = createSelector(selectSearchState, state => state.englishProblems);
export const getMathProblems = createSelector(selectSearchState, state => state.mathProblems);
export const getNationalLanguageProblems = createSelector(selectSearchState, state => state.nationalLanguageProblems);
export const getPhysicsProblems = createSelector(selectSearchState, state => state.physicsProblems);
export const getChemistryProblems = createSelector(selectSearchState, state => state.chemistryProblems);
export const getBiologyProblems = createSelector(selectSearchState, state => state.biologyProblems);
export const getJapaneseHistoryProblems = createSelector(selectSearchState, state => state.japaneseHistoryProblems);
export const getWorldHistoryProblems = createSelector(selectSearchState, state => state.worldHistoryProblems);
export const getGeographyProblems = createSelector(selectSearchState, state => state.geographyProblems);
export const getPoliticalEconomyProblems = createSelector(selectSearchState, state => state.politicalEconomyProblems);

export const getEnglishProblemsForPrint = createSelector(selectSearchState, state => state.englishProblemsForPrint);
export const getMathProblemsForPrint = createSelector(selectSearchState, state => state.mathProblemsForPrint);
export const getNationalLanguageProblemsForPrint = createSelector(selectSearchState, state => state.nationalLanguageProblemsForPrint);
export const getPhysicsProblemsForPrint = createSelector(selectSearchState, state => state.physicsProblemsForPrint);
export const getChemistryProblemsForPrint = createSelector(selectSearchState, state => state.chemistryProblemsForPrint);
export const getBiologyProblemsForPrint = createSelector(selectSearchState, state => state.biologyProblemsForPrint);
export const getJapaneseHistoryProblemsForPrint = createSelector(selectSearchState, state => state.japaneseHistoryProblemsForPrint);
export const getWorldHistoryProblemsForPrint = createSelector(selectSearchState, state => state.worldHistoryProblemsForPrint);
export const getGeographyProblemsForPrint = createSelector(selectSearchState, state => state.geographyProblemsForPrint);
export const getPoliticalEconomyProblemsForPrint = createSelector(selectSearchState, state => state.politicalEconomyProblemsForPrint);

export const getMatchedProblemIds = createSelector(selectSearchState, state => state.matchedProblemIds);
export const getScienceProblem = createSelector(selectSearchState, state => state.scienceProblem);
export const getEnglishProblem = createSelector(selectSearchState, state => state.englishProblem);
export const getNationalLanguageProblem = createSelector(selectSearchState, state => state.nationalLanguageProblem);
export const getNationalLanguageProblemsWithSameId = createSelector(selectSearchState, state => state.nationalLanguageProblemsWithSameId);
export const getHistoryProblem = createSelector(selectSearchState, state => state.historyProblem);
export const getProblemIdsSearching = createSelector(selectSearchState, state => state.problemIdsSearching);
export const getProblemSearching = createSelector(selectSearchState, state => state.problemSearching);

export const getFirstProblemIdFromMatchedProblemIds = createSelector(getMatchedProblemIds, (problemIds: string[] | null): string | null => {
  if (problemIds == null) return null;
  return [...problemIds].shift() || '';
});

export const getLastProblemIdFromMatchedProblemIds = createSelector(getMatchedProblemIds, (problemIds: string[] | null): string | null => {
  if (problemIds == null) return null;
  return [...problemIds].pop() || '';
});

export const getPreviousProblemId = (currentProblemId: string) =>
  createSelector(getMatchedProblemIds, (problemIds: string[] | null): string | null => {
    if (problemIds == null) return null;
    const prevIdIndex = problemIds.findIndex(it => it === currentProblemId) - 1;
    if (prevIdIndex < 0) return '';
    return problemIds[prevIdIndex];
  });

export const getNextProblemId = (currentProblemId: string) =>
  createSelector(getMatchedProblemIds, (problemIds: string[] | null): string | null => {
    if (problemIds == null) return null;
    const nextIdIndex = problemIds.findIndex(it => it === currentProblemId) + 1;
    if (nextIdIndex >= problemIds.length) return '';
    return problemIds[nextIdIndex];
  });

export const getReadableSearchCondition = (searchCondition: CommonSearchCondition) =>
  createSelector(getStaticCommonData, (staticCommonData: StaticCommonData): ReadableCommonSearchCondition | null => {
    if (!staticCommonData || !searchCondition) return null;

    return ReadableDataMapper.mapCommonSearchCondition(searchCondition, staticCommonData);
  });

export const getReadableEnglishCondition = (englishCondition: EnglishSearchCondition) =>
  createSelector(getStaticEnglishData, (staticEnglishData: StaticEnglishData): ReadableEnglishSearchCondition | null => {
    if (!staticEnglishData || !englishCondition) return null;

    return ReadableDataMapper.mapEnglishSearchCondition(englishCondition, staticEnglishData);
  });

export const getReadableNationalLanguageCondition = (nationalLanguageCondition: NationalLanguageSearchCondition) =>
  createSelector(
    getStaticNationalLanguageData,
    (staticNationalLanguageData: StaticNationalLanguageData): ReadableNationalLanguageSearchCondition | null => {
      if (!staticNationalLanguageData || !nationalLanguageCondition) return null;

      return ReadableDataMapper.mapNationalLanguageSearchCondition(nationalLanguageCondition, staticNationalLanguageData);
    }
  );

export const getReadableJapaneseHistoryCondition = (historyCondition: HistorySearchCondition) =>
  createSelector(getStaticJapaneseHistoryData, (staticHistoryData: StaticHistoryData): ReadableHistorySearchCondition | null => {
    if (!staticHistoryData || !historyCondition) return null;

    return ReadableDataMapper.mapHistorySearchCondition(historyCondition);
  });

export const getReadableWorldHistoryCondition = (historyCondition: HistorySearchCondition) =>
  createSelector(getStaticWorldHistoryData, (staticHistoryData: StaticHistoryData): ReadableHistorySearchCondition | null => {
    if (!staticHistoryData || !historyCondition) return null;

    return ReadableDataMapper.mapHistorySearchCondition(historyCondition);
  });

export const translateEnglishProblems = (
  staticCommonData: StaticCommonData,
  staticEnglishData: StaticEnglishData,
  englishProblems: EnglishProblem[]
): ReadableEnglishProblem[] | null => {
  if (!staticCommonData || !staticEnglishData || !englishProblems) return null;
  return englishProblems.map(problem => ReadableDataMapper.mapEnglishProblem(problem, staticCommonData, staticEnglishData));
};

/** math, physics, chemistry, biology */
export const translateScienceProblems = (
  staticCommonData: StaticCommonData,
  staticScienceData: StaticScienceData,
  scienceProblems: ScienceProblem[]
): ReadableScienceProblem[] | null => {
  if (!staticCommonData || !staticScienceData || !scienceProblems) return null;
  return scienceProblems.map(problem => ReadableDataMapper.mapScienceProblem(problem, staticCommonData, staticScienceData));
};

export const translateNationalLanguageProblems = (
  staticCommonData: StaticCommonData,
  staticNationalLanguageData: StaticNationalLanguageData,
  nationalLanguageProblems: NationalLanguageProblem[]
): ReadableNationalLanguageProblem[] | null => {
  if (!staticCommonData || !staticNationalLanguageData || !nationalLanguageProblems) return null;
  return nationalLanguageProblems.map(problem =>
    ReadableDataMapper.mapNationalLanguageProblem(problem, staticCommonData, staticNationalLanguageData)
  );
};

/** japanese history, world history */

export const translateHistoryProblems = (
  staticCommonData: StaticCommonData,
  staticHistoryData: StaticHistoryData,
  historyProblems: HistoryProblem[]
): ReadableHistoryProblem[] | null => {
  if (!staticCommonData || !staticHistoryData || !historyProblems) return null;
  return historyProblems.map(problem => ReadableDataMapper.mapHistoryProblem(problem, staticCommonData, staticHistoryData));
};

export const getReadableEnglishProblems = createSelector(
  getStaticCommonData,
  getStaticEnglishData,
  getEnglishProblems,
  (staticCommonData, staticEnglishData, englishProblems) => translateEnglishProblems(staticCommonData, staticEnglishData, englishProblems)
);

export const getReadableMathProblems = createSelector(
  getStaticCommonData,
  getStaticMathData,
  getMathProblems,
  (staticCommonData, staticMathData, mathProblems) => translateScienceProblems(staticCommonData, staticMathData, mathProblems)
);

export const getReadableNationalLanguageProblems = createSelector(
  getStaticCommonData,
  getStaticNationalLanguageData,
  getNationalLanguageProblems,
  (staticCommonData, staticNationalLanguageData, nationalLanguageProblems) =>
    translateNationalLanguageProblems(staticCommonData, staticNationalLanguageData, nationalLanguageProblems)
);

export const getReadablePhysicsProblems = createSelector(
  getStaticCommonData,
  getStaticPhysicsData,
  getPhysicsProblems,
  (staticCommonData, staticPhysicsData, physicsProblems) => translateScienceProblems(staticCommonData, staticPhysicsData, physicsProblems)
);

export const getReadableChemistryProblems = createSelector(
  getStaticCommonData,
  getStaticChemistryData,
  getChemistryProblems,
  (staticCommonData, staticChemistryData, chemistryProblems) =>
    translateScienceProblems(staticCommonData, staticChemistryData, chemistryProblems)
);

export const getReadableBiologyProblems = createSelector(
  getStaticCommonData,
  getStaticBiologyData,
  getBiologyProblems,
  (staticCommonData, staticBiologyData, biologyProblems) => translateScienceProblems(staticCommonData, staticBiologyData, biologyProblems)
);

export const getReadableJapaneseHistoryProblems = createSelector(
  getStaticCommonData,
  getStaticJapaneseHistoryData,
  getJapaneseHistoryProblems,
  (staticCommonData, staticJapaneseHistoryData, japaneseHistoryProblems) =>
    translateHistoryProblems(staticCommonData, staticJapaneseHistoryData, japaneseHistoryProblems)
);

export const getReadableWorldHistoryProblems = createSelector(
  getStaticCommonData,
  getStaticWorldHistoryData,
  getWorldHistoryProblems,
  (staticCommonData, staticWorldHistoryData, worldHistoryProblems) =>
    translateHistoryProblems(staticCommonData, staticWorldHistoryData, worldHistoryProblems)
);

export const getReadableGeographyProblems = createSelector(
  getStaticCommonData,
  getStaticGeographyData,
  getGeographyProblems,
  (staticCommonData, staticGeographyData, geographyProblems) =>
    translateScienceProblems(staticCommonData, staticGeographyData, geographyProblems)
);

export const getReadablePoliticalEconomyProblems = createSelector(
  getStaticCommonData,
  getStaticPoliticalEconomyData,
  getPoliticalEconomyProblems,
  (staticCommonData, staticPoliticalEconomyData, politicalEconomyProblems) =>
    translateScienceProblems(staticCommonData, staticPoliticalEconomyData, politicalEconomyProblems)
);

export const getReadableEnglishProblemsForPrint = createSelector(
  getStaticCommonData,
  getStaticEnglishData,
  getEnglishProblemsForPrint,
  (staticCommonData, staticEnglishData, englishProblems) => translateEnglishProblems(staticCommonData, staticEnglishData, englishProblems)
);

export const getReadableMathProblemsForPrint = createSelector(
  getStaticCommonData,
  getStaticMathData,
  getMathProblemsForPrint,
  (staticCommonData, staticMathData, mathProblems) => translateScienceProblems(staticCommonData, staticMathData, mathProblems)
);

export const getReadableNationalLanguageProblemsForPrint = createSelector(
  getStaticCommonData,
  getStaticNationalLanguageData,
  getNationalLanguageProblemsForPrint,
  (staticCommonData, staticNationalLanguageData, nationalLanguageProblems) =>
    translateNationalLanguageProblems(staticCommonData, staticNationalLanguageData, nationalLanguageProblems)
);

export const getReadablePhysicsProblemsForPrint = createSelector(
  getStaticCommonData,
  getStaticPhysicsData,
  getPhysicsProblemsForPrint,
  (staticCommonData, staticPhysicsData, physicsProblems) => translateScienceProblems(staticCommonData, staticPhysicsData, physicsProblems)
);

export const getReadableChemistryProblemsForPrint = createSelector(
  getStaticCommonData,
  getStaticChemistryData,
  getChemistryProblemsForPrint,
  (staticCommonData, staticChemistryData, chemistryProblems) =>
    translateScienceProblems(staticCommonData, staticChemistryData, chemistryProblems)
);

export const getReadableBiologyProblemsForPrint = createSelector(
  getStaticCommonData,
  getStaticBiologyData,
  getBiologyProblemsForPrint,
  (staticCommonData, staticBiologyData, biologyProblems) => translateScienceProblems(staticCommonData, staticBiologyData, biologyProblems)
);

export const getReadableJapaneseHistoryProblemsForPrint = createSelector(
  getStaticCommonData,
  getStaticJapaneseHistoryData,
  getJapaneseHistoryProblemsForPrint,
  (staticCommonData, staticJapaneseHistoryData, japaneseHistoryProblems) =>
    translateHistoryProblems(staticCommonData, staticJapaneseHistoryData, japaneseHistoryProblems)
);

export const getReadableWorldHistoryProblemsForPrint = createSelector(
  getStaticCommonData,
  getStaticWorldHistoryData,
  getWorldHistoryProblemsForPrint,
  (staticCommonData, staticWorldHistoryData, worldHistoryProblems) =>
    translateHistoryProblems(staticCommonData, staticWorldHistoryData, worldHistoryProblems)
);

export const getReadableGeographyProblemsForPrint = createSelector(
  getStaticCommonData,
  getStaticGeographyData,
  getGeographyProblemsForPrint,
  (staticCommonData, staticGeographyData, geographyProblems) =>
    translateScienceProblems(staticCommonData, staticGeographyData, geographyProblems)
);

export const getReadablePoliticalEconomyProblemsForPrint = createSelector(
  getStaticCommonData,
  getStaticPoliticalEconomyData,
  getPoliticalEconomyProblemsForPrint,
  (staticCommonData, staticPoliticalEconomyData, politicalEconomyProblems) =>
    translateScienceProblems(staticCommonData, staticPoliticalEconomyData, politicalEconomyProblems)
);

export const getReadableMathProblem = (problem: ScienceProblem) =>
  createSelector(getStaticCommonData, getStaticMathData, (staticCommonData, staticMathData) => {
    if (!staticCommonData || !staticMathData) return null;
    return translateScienceProblems(staticCommonData, staticMathData, [problem]).pop();
  });

export const getReadablePhysicsProblem = (problem: ScienceProblem) =>
  createSelector(getStaticCommonData, getStaticPhysicsData, (staticCommonData, staticPhysicsData) => {
    if (!staticCommonData || !staticPhysicsData) return null;
    return translateScienceProblems(staticCommonData, staticPhysicsData, [problem]).pop();
  });

export const getReadableChemistryProblem = (problem: ScienceProblem) =>
  createSelector(getStaticCommonData, getStaticChemistryData, (staticCommonData, staticChemistryData) => {
    if (!staticCommonData || !staticChemistryData) return null;
    return translateScienceProblems(staticCommonData, staticChemistryData, [problem]).pop();
  });

export const getReadableBiologyProblem = (problem: ScienceProblem) =>
  createSelector(getStaticCommonData, getStaticBiologyData, (staticCommonData, staticBiologyData) => {
    if (!staticCommonData || !staticBiologyData) return null;
    return translateScienceProblems(staticCommonData, staticBiologyData, [problem]).pop();
  });

export const getReadableEnglishProblem = (problem: EnglishProblem) =>
  createSelector(getStaticCommonData, getStaticEnglishData, (staticCommonData, staticEnglishData) => {
    if (!staticCommonData || !staticEnglishData) return null;
    return translateEnglishProblems(staticCommonData, staticEnglishData, [problem]).pop();
  });

export const getReadableNationalLanguageProblem = (problem: NationalLanguageProblem) =>
  createSelector(getStaticCommonData, getStaticNationalLanguageData, (staticCommonData, staticNationalLanguageData) => {
    if (!staticCommonData || !staticNationalLanguageData) return null;
    return translateNationalLanguageProblems(staticCommonData, staticNationalLanguageData, [problem]).pop();
  });

export const getReadableNationalLanguageProblemsFromProblems = (problems: NationalLanguageProblem[]) =>
  createSelector(getStaticCommonData, getStaticNationalLanguageData, (staticCommonData, staticNationalLanguageData) => {
    if (!staticCommonData || !staticNationalLanguageData) return null;
    return translateNationalLanguageProblems(staticCommonData, staticNationalLanguageData, problems);
  });

export const getReadableJapaneseHistoryProblem = (problem: HistoryProblem) =>
  createSelector(getStaticCommonData, getStaticJapaneseHistoryData, (staticCommonData, staticJapaneseHistoryData) => {
    if (!staticCommonData || !staticJapaneseHistoryData) return null;
    return translateHistoryProblems(staticCommonData, staticJapaneseHistoryData, [problem]).pop();
  });

export const getReadableWorldHistoryProblem = (problem: HistoryProblem) =>
  createSelector(getStaticCommonData, getStaticWorldHistoryData, (staticCommonData, staticWorldHistoryData) => {
    if (!staticCommonData || !staticWorldHistoryData) return null;
    return translateHistoryProblems(staticCommonData, staticWorldHistoryData, [problem]).pop();
  });

export const getReadableGeographyProblem = (problem: ScienceProblem) =>
  createSelector(getStaticCommonData, getStaticGeographyData, (staticCommonData, staticGeographyData) => {
    if (!staticCommonData || !staticGeographyData) return null;
    return translateScienceProblems(staticCommonData, staticGeographyData, [problem]).pop();
  });

export const getReadablePoliticalEconomyProblem = (problem: ScienceProblem) =>
  createSelector(getStaticCommonData, getStaticPoliticalEconomyData, (staticCommonData, staticPoliticalEconomyData) => {
    if (!staticCommonData || !staticPoliticalEconomyData) return null;
    return translateScienceProblems(staticCommonData, staticPoliticalEconomyData, [problem]).pop();
  });
