import { Action, createReducer, on } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { CommonIdGetCustomTokenResponse } from 'src/app/models/common-id/common-id-get-custom-token-response';

import * as CommonIdGetCustomTokenActions from '../../actions/common-id/common-id-get-custom-token.actions';

export const commonIdGetCustomTokenFeatureKey = 'commonIdGetCustomToken';

export interface CommonIdGetCustomTokenResult {
  success: boolean;
  response: CommonIdGetCustomTokenResponse | null;
  error: AppError | null;
}

export interface State {
  getCustomTokenResult: CommonIdGetCustomTokenResult | null;
}

export const initialState: State = {
  getCustomTokenResult: null
};

const commonIdGetCustomTokenReducer = createReducer(
  initialState,
  on(CommonIdGetCustomTokenActions.commonIdGetCustomTokenSuccess, (state, { response }) => ({
    ...state,
    getCustomTokenResult: { success: true, response, error: null }
  })),
  on(CommonIdGetCustomTokenActions.commonIdGetCustomTokenFailure, (state, { error }) => ({
    ...state,
    getCustomTokenResult: { success: false, response: null, error }
  })),
  on(CommonIdGetCustomTokenActions.initializeCommonIdGetCustomTokenResult, state => {
    const next: State = { ...state, getCustomTokenResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return commonIdGetCustomTokenReducer(state, action);
}
