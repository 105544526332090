import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, of, switchMap, map } from 'rxjs';

import * as CommonIdGetStripeCheckoutUrlActions from '../../actions/common-id/common-id-get-stripe-checkout-url.actions';
import { CommonIdGetStripeCheckoutUrlService } from '../../services/common-id/common-id-get-stripe-checkout-url.service';
import { FirebaseCallableFunctionError } from 'src/app/errors/firebase-callable-function-error';

@Injectable()
export class CommonIdGetStripeCheckoutUrlEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdGetStripeCheckoutUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdGetStripeCheckoutUrlActions.commonIdGetStripeCheckoutUrl),
      switchMap(action =>
        this.commonIdGetStripeCheckoutUrlService.commonIdGetStripeCheckoutUrl(action.condition).pipe(
          map(response => CommonIdGetStripeCheckoutUrlActions.commonIdGetStripeCheckoutUrlSuccess({ response })),
          catchError(e =>
            of(CommonIdGetStripeCheckoutUrlActions.commonIdGetStripeCheckoutUrlFailure({ error: FirebaseCallableFunctionError.from(e) }))
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdGetStripeCheckoutUrlService: CommonIdGetStripeCheckoutUrlService) {}
}
