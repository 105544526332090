import { Action, createReducer, on } from '@ngrx/store';

import * as UniversitySearchActions from '../actions/university-search.actions';
import { UniversitySearchCondition, UniversityCondition, PrefectureCondition } from '../models/university-search-condition';
import { FindUniversityPapersResponse } from '../models/find-university-papers-response';

export const universitySearchFeatureKey = 'universitySearch';

export interface State {
  searchCondition: UniversitySearchCondition<UniversityCondition | PrefectureCondition>;

  /** 検索条件に合う大学の件数 */
  isUniversityCountSearching: boolean;
  matchedUniversityCount: number | null;

  /** 大学の検索結果 */
  isUniversitySearching: boolean;
  matchedUniversities: FindUniversityPapersResponse[] | null;

  /** 検索結果のページ指定用 */
  page: number;
}

export const initialState: State = {
  searchCondition: null,
  isUniversityCountSearching: false,
  matchedUniversityCount: null,
  isUniversitySearching: false,
  matchedUniversities: null,
  page: null
};

const universitySearchReducer = createReducer(
  initialState,

  // 検索条件に合う大学の校数を取得する場合 -------------------------------------------------------
  on(UniversitySearchActions.findUniversityCountByUniversityIds, state => ({ ...state, isUniversityCountSearching: true })),
  on(UniversitySearchActions.findUniversityCountByUniversityIdsSuccess, (state, { result }) => ({
    ...state,
    matchedUniversityCount: result.count,
    isUniversityCountSearching: false
  })),
  on(UniversitySearchActions.findUniversityCountByUniversityIdsFailure, state => ({ ...state, isUniversityCountSearching: false })),

  // 検索条件に合う大学の校数を取得する場合（都道府県別） -------------------------------------------------------
  on(UniversitySearchActions.findUniversityCountByPrefectureIds, state => ({ ...state, isUniversityCountSearching: true })),
  on(UniversitySearchActions.findUniversityCountByPrefectureIdsSuccess, (state, { result }) => ({
    ...state,
    matchedUniversityCount: result.count,
    isUniversityCountSearching: false
  })),
  on(UniversitySearchActions.findUniversityCountByPrefectureIdsFailure, state => ({ ...state, isUniversityCountSearching: false })),

  // 検索条件に合う大学の詳細を取得する場合 -------------------------------------------------------
  on(UniversitySearchActions.findUniversityPapersByUniversityIds, state => ({ ...state, isUniversitySearching: true })),
  on(UniversitySearchActions.findUniversityPapersByUniversityIdsSuccess, (state, { universities }) => ({
    ...state,
    matchedUniversities: universities,
    isUniversitySearching: false
  })),
  on(UniversitySearchActions.findUniversityPapersByUniversityIdsFailure, state => ({ ...state, isUniversitySearching: false })),

  // 検索条件に合う大学の詳細を取得する場合（都道府県別） -------------------------------------------------------
  on(UniversitySearchActions.findUniversityPapersByPrefectureIds, state => ({ ...state, isUniversitySearching: true })),
  on(UniversitySearchActions.findUniversityPapersByPrefectureIdsSuccess, (state, { universities }) => ({
    ...state,
    matchedUniversities: universities,
    isUniversitySearching: false
  })),
  on(UniversitySearchActions.findUniversityPapersByPrefectureIdsFailure, state => ({ ...state, isUniversitySearching: false })),

  // 検索結果の校数をリセットする場合 ---------------------------------------------------------------------------
  on(UniversitySearchActions.initializeUniversityCountState, state => {
    const next: State = { ...state, matchedUniversityCount: null, isUniversityCountSearching: false };
    return next;
  }),

  // 検索結果の大学詳細をリセットする場合 ---------------------------------------------------------------------------
  on(UniversitySearchActions.initializeUniversityPapersState, state => {
    const next: State = { ...state, matchedUniversities: null, isUniversitySearching: false, page: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return universitySearchReducer(state, action);
}
