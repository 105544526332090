<app-sign-out-header></app-sign-out-header>
<main>
  <app-wrapper size="lg">
    <app-content-row>
      <app-article-content>
        <app-article-content-head>「入試正解デジタル for School」利用規約</app-article-content-head>
        <app-terms-content></app-terms-content>
        <div class="mx-auto max-w-md my-8">
          <div style="min-height: 114px;" class="relative">
            <ng-template #loadingProgress>
              <div class="progress-48">
                <mat-spinner [diameter]="48"></mat-spinner>
              </div>
            </ng-template>

            <div *ngIf="!loading; else loadingProgress">
              <button mat-flat-button class="bg-gray-50 mt-4 block w-full h-12" (click)="signOut()">同意せずにログアウトする</button>
              <button mat-flat-button color="primary" class="mt-4 block w-full h-12" (click)="agree()">同意して利用を開始する</button>
            </div>
          </div>
        </div>
      </app-article-content>
    </app-content-row>
  </app-wrapper>
</main>
<app-footer></app-footer>
