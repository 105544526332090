import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { dispatchAppError } from '../actions/core.actions';
import { findProblemImages, findProblemImagesFailure, findProblemImagesSuccess } from '../actions/try-search.action';
import { FirestoreError } from '../errors/firestore-error';
import { TrySearchService } from '../services/try-search.service';

@Injectable()
export class TrySearchEffects {
  private LOG_SOURCE = this.constructor.name;

  findProblemImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findProblemImages),
      switchMap(action =>
        this.trySearchService.findProblemImages(action.problemId).pipe(
          map(response => findProblemImagesSuccess({ kakomonImages: response })),
          catchError(e => of(findProblemImagesFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private trySearchService: TrySearchService) {}
}
