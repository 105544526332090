<div class="root-container">
  <app-table-overflow>
    <table mat-table [dataSource]="dataSource" style="min-width: 1200px;">
      <ng-container matColumnDef="sequentialId" *ngIf="readableSciencePlaylistProblems != null">
        <th mat-header-cell *matHeaderCellDef class="sequential-id">No.</th>
        <td mat-cell *matCellDef="let element">{{ element.sequentialId }}</td>
      </ng-container>

      <ng-container matColumnDef="bookmark" *ngIf="initializedBookmark$ !== undefined">
        <th mat-header-cell *matHeaderCellDef class="bookmark"></th>
        <td mat-cell *matCellDef="let element" class="font-bold">
          <app-button-toggle-bookmark
            *ngIf="!element.isBookmarked"
            [isBookmarked]="false"
            [disabled]="(initializedBookmark$ | async) === false"
            [canSpinner]="canBookmarkSpiner"
            (backClick)="addBookmarkClick.emit(element.id)"
          ></app-button-toggle-bookmark>
          <app-button-toggle-bookmark
            *ngIf="element.isBookmarked"
            [isBookmarked]="true"
            [disabled]="(initializedBookmark$ | async) === false"
            [canSpinner]="canBookmarkSpiner"
            (backClick)="deleteBookmarkClick.emit(element.id)"
          ></app-button-toggle-bookmark>
        </td>
      </ng-container>

      <ng-container matColumnDef="university">
        <th mat-header-cell *matHeaderCellDef class="university">大学名</th>
        <td mat-cell *matCellDef="let element" class="font-bold">{{ element.university }}</td>
      </ng-container>

      <ng-container matColumnDef="departments">
        <th mat-header-cell *matHeaderCellDef class="departments">学部</th>
        <td mat-cell *matCellDef="let element" class="text-sm">{{ element.departments }}</td>
      </ng-container>

      <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef class="year">入試年度</th>
        <td mat-cell *matCellDef="let element" class="text-sm">{{ element.year }}</td>
      </ng-container>

      <ng-container matColumnDef="outline">
        <th mat-header-cell *matHeaderCellDef>問題概要</th>
        <td mat-cell *matCellDef="let element" class="text-sm">
          <div class="outline-value">{{ element.outline }}</div>
          <div class="comment" *ngIf="element.comment != null && element.comment !== ''">
            <div class="label">コメント:</div>
            <div class="value">{{ element.comment }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef class="level">難易度</th>
        <td mat-cell *matCellDef="let element" class="text-sm">{{ element.level }}</td>
      </ng-container>

      <ng-container matColumnDef="book">
        <th mat-header-cell *matHeaderCellDef class="book">掲載書籍</th>
        <td mat-cell *matCellDef="let element" class="text-sm">{{ element.book ? element.book : '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="page">
        <th mat-header-cell *matHeaderCellDef class="page">ページ</th>
        <td mat-cell *matCellDef="let element" class="text-sm">{{ element.page === 0 ? '-' : element.page }}</td>
      </ng-container>

      <ng-container matColumnDef="problemNumber">
        <th mat-header-cell *matHeaderCellDef class="problem-number text-center">問題</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <!-- 印刷画面用の view -->
          <ng-container *ngIf="problemButtonDisabled">{{ element.problemNumber }}</ng-container>

          <!-- 検索結果画面用の view -->
          <ng-container *ngIf="readableScienceProblems != null && !problemButtonDisabled">
            <button
              mat-button
              color="primary"
              (click)="problemClick.emit({ problemId: element.id, year: element.year })"
              *ngIf="element.hasExternalData === true"
            >
              <span class="problem-button-label">{{ element.problemNumber }}</span>
            </button>
            <div *ngIf="element.hasExternalData !== true">{{ element.problemNumber }}</div>
          </ng-container>

          <!-- おすすめ問題セット用の view -->
          <div class="download-button" *ngIf="readableSciencePlaylistProblems != null && !problemButtonDisabled">
            <button
              mat-button
              color="primary"
              *ngIf="element.hasExternalData"
              (click)="showPlayListProblemDetail(element)"
              [disabled]="element.pdfDownloading === true"
            >
              <span class="pdf-button-label">{{ element.problemNumber }}</span>
            </button>
            <span *ngIf="!element.hasExternalData">{{ element.problemNumber }}</span>
            <div class="progress-24" *ngIf="element.pdfDownloading === true">
              <mat-spinner [diameter]="24"></mat-spinner>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="wordDownload">
        <th mat-header-cell *matHeaderCellDef class="word-download text-center">
          <!-- 検索結果画面用の view -->
          <div>Word</div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <!-- 印刷画面, おすすめ問題セット用の view -->
          <div class="download-button text-center" *ngIf="wordButtonDisabled">
            <app-button-download-word-file
              *ngIf="element.hasWordData === true"
              [disabled]="wordButtonDisabled"
            ></app-button-download-word-file>
            <div *ngIf="element.hasWordData !== true">-</div>
          </div>

          <!-- 検索結果画面用の view -->
          <div class="download-button text-center" *ngIf="!wordButtonDisabled">
            <app-button-download-word-file
              *ngIf="element.hasWordData === true"
              (buttonClick)="openProblemWord(element)"
            ></app-button-download-word-file>
            <div *ngIf="element.hasWordData !== true">-</div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columns"
        [ngClass]="{ 'no-bottom-border': hideLastBottomBorder, 'vertical-align-top': verticalAlignTop }"
      ></tr>
    </table>
  </app-table-overflow>
</div>
