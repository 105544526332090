import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-icon-premium',
  templateUrl: './icon-premium.component.html',
  styleUrls: ['./icon-premium.component.scss']
})
export class IconPremiumComponent implements OnInit {
  constructor() {}

  @Input() labelIfTrue = '';
  @Input() labelIfFalse = '';
  @Input() isToggle = false;
  @Output() toggleClick = new EventEmitter();

  ngOnInit() {}

  toggle() {
    this.isToggle = !this.isToggle;
    this.toggleClick.emit(this.isToggle);
  }
}
