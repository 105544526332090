import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

import { CallableFunction } from '../../resources/app-firebase';
import { CommonIdSearchCountResponse } from '../../models/common-id/common-id-search-count-response';
import {
  UniversitySearchCountRequest,
  UniversitySearchCondition,
  UniversityCondition,
  PrefectureCondition,
  UniversitySearchRequest
} from '../../models/university-search-condition';
import { CommonIdFindUniversityPapersResponse } from '../../models/common-id/common-id-find-university-papers-response';

@Injectable({
  providedIn: 'root'
})
export class CommonIdUniversityService {
  constructor(private aff: AngularFireFunctions) {}

  findUniversityCountByUniversityIds(
    condition: UniversitySearchCondition<UniversityCondition>,
    logging: boolean
  ): Observable<CommonIdSearchCountResponse> {
    const req: UniversitySearchCountRequest<UniversityCondition> = { ...condition, logging };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_UNIVERSITY_COUNT);
    return callable(req);
  }

  findUniversityCountByPrefectureIds(
    condition: UniversitySearchCondition<PrefectureCondition>,
    logging: boolean
  ): Observable<CommonIdSearchCountResponse> {
    const req: UniversitySearchCountRequest<PrefectureCondition> = { ...condition, logging };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_UNIVERSITY_COUNT);
    return callable(req);
  }

  findUniversityPapersByUniversityIds(
    condition: UniversitySearchCondition<UniversityCondition>,
    page: number
  ): Observable<CommonIdFindUniversityPapersResponse[]> {
    const req: UniversitySearchRequest<UniversityCondition> = { ...condition, page };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_UNIVERSITY_PAPERS);
    return callable(req);
  }

  findUniversityPapersByPrefectureIds(
    condition: UniversitySearchCondition<PrefectureCondition>,
    page: number
  ): Observable<CommonIdFindUniversityPapersResponse[]> {
    const req: UniversitySearchRequest<PrefectureCondition> = { ...condition, page };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_UNIVERSITY_PAPERS);
    return callable(req);
  }
}
