import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-toggle-playlist-bookmark',
  templateUrl: './button-toggle-playlist-bookmark.component.html',
  styleUrls: ['./button-toggle-playlist-bookmark.component.scss']
})
export class ButtonTogglePlaylistBookmarkComponent implements OnInit {
  constructor() {}

  @Input() isPlaylistBookmarked = false;
  @Input() disabled = false;
  @Input() canSpinner = false;
  @Input() errorFlag: boolean;
  @Output() bookmarkClick = new EventEmitter();

  showSpinner = false;

  ngOnInit() {}

  toggle() {
    if (this.canSpinner) {
      this.showSpinner = true;
    }
    this.bookmarkClick.emit();
  }

  reset() {
    this.isPlaylistBookmarked = false;
    this.showSpinner = false;
  }
}
