import { AppError } from './app-error';
import { FirebaseAuthErrorInfo, ErrorInfo } from './error-info';
import Firebase from 'firebase/compat';

const errorCodeMapping: { [key: string]: ErrorInfo } = {
  'auth/invalid-email': FirebaseAuthErrorInfo.INVALID_EMAIL,
  'auth/user-disabled': FirebaseAuthErrorInfo.USER_DISABLED,
  'auth/user-not-found': FirebaseAuthErrorInfo.USER_NOT_FOUND,
  'auth/wrong-password': FirebaseAuthErrorInfo.WRONG_PASSWORD,
  'auth/email-already-in-use': FirebaseAuthErrorInfo.EMAIL_ALREADY_IN_USE
};

export class FirebaseAuthError extends AppError {
  constructor(info: ErrorInfo, cause?: string | Error) {
    super(info.code, info.message, cause);
    Object.setPrototypeOf(this, FirebaseAuthError.prototype);
  }

  static from(err: Firebase.FirebaseError): FirebaseAuthError {
    const info = errorCodeMapping[err.code];
    if (info) return new FirebaseAuthError(info, err);
    return FirebaseAuthError.unknown(err);
  }

  static unknown(cause: string | Error): FirebaseAuthError {
    return new FirebaseAuthError(FirebaseAuthErrorInfo.UNKNOWN, cause);
  }
}
