import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-no-data',
  templateUrl: './content-no-data.component.html',
  styleUrls: ['./content-no-data.component.scss']
})
export class ContentNoDataComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
