import { getCookie } from 'typescript-cookie';
import { COOKIE_NAME_SSC_ID, PurchasePlan } from '../resources/common-id-config';
import { GA_EVENT_CATEGORIES } from '../resources/common-id/ga';

interface GAEventParams {
  event_category: string;
  event_label?: string;
  value?: number;
}
export class GAUtil {
  /** Visible for testing */
  static getGAScript(gaConfig: string[]): string {
    if (gaConfig.length > 0) {
      let gTag = '';
      gaConfig.forEach(code => {
        if (gTag !== '') gTag += '\n';
        gTag += `gtag('config', '${code}');`;
      });

      return `
window.dataLayer = window.dataLayer || [];
function gtag() { dataLayer.push(arguments); }
gtag('js', new Date());
${gTag}
`;
    }
    return '';
  }

  static sendEvent(eventAction: string, eventParams: GAEventParams) {
    const sscId = getCookie(COOKIE_NAME_SSC_ID) || '';
    if (sscId) {
      gtag('set', 'user_properties', { ssc_id: sscId });
    }
    gtag('event', eventAction, eventParams);
  }

  static getGAEventCategoryPlan(planId: number): string {
    switch (planId) {
      case PurchasePlan.BIANNUAL:
        return GA_EVENT_CATEGORIES.MONTH_6_BUY;
      case PurchasePlan.ANNUAL:
        return GA_EVENT_CATEGORIES.MONTH_12_BUY;
      case PurchasePlan.SHORT_TERM_3MONTHS:
        return GA_EVENT_CATEGORIES.MONTH_3_BUY;
      default:
        return GA_EVENT_CATEGORIES.MONTH_6_BUY;
    }
  }
}
