import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-label-level-with-stars',
  templateUrl: './label-level-with-stars.component.html',
  styleUrls: ['./label-level-with-stars.component.scss']
})
export class LabelLevelWithStarsComponent implements OnInit {
  @Input() level: number;
  @Input() levelLabelName: string;

  constructor() {}
  ngOnInit() {}

  displayStars() {
    switch (this.level) {
      case 1:
        return '★';
      case 2:
        return '★★';
      case 3:
        return '★★★';
      case 4:
        return '★★★★';
      case 5:
        return '';
    }
  }
}
