import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { CommonIdInformationResponse } from 'src/app/models/common-id/common-id-information';
import { CallableFunction } from 'src/app/resources/app-firebase';

@Injectable({
  providedIn: 'root'
})
export class CommonIdInformationService {
  constructor(private aff: AngularFireFunctions) {}

  commonIdGetInformations(): Observable<CommonIdInformationResponse[]> {
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_GET_INFORMATIONS);
    return callable({});
  }
}
