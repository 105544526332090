import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { getHighSchool } from '../../../selectors/static-data.selectors';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { HighSchool } from '../../../models/common-data';
import { getWatchedUsers } from '../../../selectors/user.selectors';
import { initializeWatchedUsers, watchUsers } from '../../../actions/user.actions';
import { User } from '../../../models/user';
import { Plan } from '../../../models/plan';
import { getPlans } from '../../../selectors/plan.selectors';
import { findPlans, initializeFindPlansState } from '../../../actions/plan.actions';
import { navigate, setBrowserTitle, setTitle, unsubscribeWatchedProps } from '../../../actions/core.actions';
import { enter } from '../../../resources/animations';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { PlanStatuses, SCHOOL_ORGANIZATION_ID, UnsubscribeTarget } from '../../../resources/config';
import { CurrentDateTime } from '../../../models/current-date-time';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../selectors/current-date-time.selectors';
import { getCurrentDateTime, initializeGetCurrentDateTime } from '../../../actions/current-date-time.actions';
import { PlanUtil, PriorityRecord } from '../../../utils/plan-util';

interface SchoolData {
  schoolId: string;
  schoolName: string;
  planStatus: string;
}

@Component({
  selector: 'app-schools',
  templateUrl: './schools.component.html',
  styleUrls: ['./schools.component.scss'],
  animations: [enter]
})
export class SchoolsComponent implements OnInit, OnDestroy {
  sortDirection: 'asc' | 'desc' = 'desc';
  schoolsData$: Observable<SchoolData[]>;
  planUnassignedStatus: string = PlanStatuses.UNASSIGNED;
  planActiveStatus: string = PlanStatuses.ACTIVE;
  planEndedStatus: string = PlanStatuses.ENDED;
  displayedColumns: string[] = ['schoolId', 'schoolName', 'planStatus', 'action'];

  private LOG_SOURCE = this.constructor.name;
  private title = '学校一覧';

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.setUp();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeWatchedUsers());
    this.store.dispatch(initializeFindPlansState());
    this.store.dispatch(initializeGetCurrentDateTime());
    this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.WATCH_USERS }));
  }

  plans(schoolId: string) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlans(SCHOOL_ORGANIZATION_ID, schoolId) }));
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'admin':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        break;
    }
  }

  menuClickHandler(key) {
    switch (key) {
      case 'admin/accounts':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAccounts() }));
        break;
      case 'admin/jukus':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveJukus() }));
        break;
    }
  }

  private setUp() {
    const highSchools$: Observable<HighSchool[]> = this.store.select(getHighSchool).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const users$: Observable<User[]> = this.store.select(getWatchedUsers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const plans$: Observable<Plan[]> = this.store.select(getPlans).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const currentDateTime$: Observable<CurrentDateTime> = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.schoolsData$ = combineLatest([highSchools$, users$, plans$, currentDateTime$])
      .pipe(take(1))
      .pipe(
        map(([highSchools, users, plans, currentDateTime]) => {
          const mapedSchools = [
            ...new Set(users.filter(user => user.organization === SCHOOL_ORGANIZATION_ID).map(user => user.schoolId))
          ].map(schoolId => {
            const school: HighSchool = highSchools.find(highSchool => highSchool.id.trim() === schoolId);
            const schoolPlans: Plan[] = plans.filter(plan => plan.schoolId === schoolId);
            return {
              schoolId,
              schoolName: school.name,
              planStatus: this.getSchoolPlanStatus(schoolPlans, currentDateTime)
            };
          });

          mapedSchools.sort((a, b) => {
            if (a.schoolId === b.schoolId) {
              return 0;
            }
            return a.schoolId > b.schoolId ? 1 : -1;
          });

          return mapedSchools;
        }),
        shareReplay(1)
      );

    this.store.dispatch(watchUsers({ sortDirection: this.sortDirection }));
    this.store.dispatch(findPlans({}));
    this.store.dispatch(getCurrentDateTime());
  }

  private getSchoolPlanStatus(plans: Plan[], currentDateTime: CurrentDateTime): string {
    if (plans.length === 0) return this.planUnassignedStatus;

    return plans.find(plan => {
      const priorityRecord: PriorityRecord = PlanUtil.getPriorityRecord(plan.records, currentDateTime);
      return priorityRecord.status === PlanStatuses.ACTIVE || priorityRecord.status === PlanStatuses.RESERVE;
    })
      ? this.planActiveStatus
      : this.planEndedStatus;
  }
}
