import { Component, OnInit } from '@angular/core';
import { META_VIEWPORT_OTHER } from '../../../resources/config';
import { Meta } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { setBrowserTitle } from '../../../actions/core.actions';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  private title = '利用規約';

  constructor(private store: Store<RootState>, private meta: Meta) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    this.meta.updateTag(META_VIEWPORT_OTHER);
  }
}
