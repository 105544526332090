<div class="button-subject-name" [attr.data-isVisited]="isVisited" [attr.data-isPremium]="isPremium" [attr.data-isArrow]="isArrow">
  <div class="button-subject-name-inner">
    <button class="button" mat-icon-button (click)="toggle()">
      <div class="flex gap-1 items-center">
        <app-icon-premium *ngIf="isPremium" class="icon-premium"></app-icon-premium>
        <span class="button-text">{{ subject }}</span>
      </div>
      <mat-icon class="icon" aria-hidden="false" aria-label="" *ngIf="!isArrow">keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
