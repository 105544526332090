import { createAction, props } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { CommonIdGetStripeCheckoutUrlRequest } from '../../models/common-id/common-id-get-stripe-checkout-url-request';
import { CommonIdGetStripeCheckoutUrlResponse } from '../../models/common-id/common-id-get-stripe-checkout-url-response';

export const commonIdGetStripeCheckoutUrl = createAction(
  '[CommonIdGetStripeCheckoutUrl] Common Id Get Stripe Checkout Url',
  props<{ condition: CommonIdGetStripeCheckoutUrlRequest }>()
);

export const commonIdGetStripeCheckoutUrlSuccess = createAction(
  '[CommonIdGetStripeCheckoutUrl] Common Id Get Stripe Checkout Url Success',
  props<{ response: CommonIdGetStripeCheckoutUrlResponse }>()
);

export const commonIdGetStripeCheckoutUrlFailure = createAction(
  '[CommonIdAGetStripeCheckoutUrl] Common Id Get Stripe Checkout Url Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdGetStripeCheckoutUrlState = createAction(
  '[CommonIdAGetStripeCheckoutUrl] Initialize Common Id Get Stripe Checkout Url State'
);
