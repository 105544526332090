import { Component, OnInit, Input } from '@angular/core';
import { navigate } from '../../../actions/core.actions';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Log } from '../../../utils/log';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  termsUrl: string;
  tokusyouhouUrl: string;
  view = true;
  invisibleList: string[] = [RoutingPathResolver.resolveSearch(), RoutingPathResolver.resolveSearchResults()];
  constructor(private store: Store<RootState>, private router: Router) {}
  // @Input() isResponsive = false; // 参: #427 #1216

  ngOnInit() {
    this.visibleCheck(this.router.url);
    this.router.events.pipe(filter(it => it instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      this.visibleCheck(e.url);
    });

    this.termsUrl = RoutingPathResolver.resolveTerms();
    this.tokusyouhouUrl = RoutingPathResolver.resolveTokusyouhou();
  }

  private visibleCheck(url) {
    this.view = this.invisibleList.findIndex(path => '/' + path === url.replace(/\?.*$/, '')) === -1;
  }
}
