import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { findStaticData, initializeStaticDataState } from 'src/app/actions/static-data.actions';
import { META_VIEWPORT_OTHER, STATIC_DATA_CACHE_DAYS } from 'src/app/resources/config';
import { getFetchedDate } from 'src/app/selectors/static-data.selectors';
import { Dates } from 'src/app/utils/dates';
import { Log } from 'src/app/utils/log';
import { Meta } from '@angular/platform-browser';

import * as CommonIdInformationActions from '../../../../actions/common-id/common-id-information.actions';
import { getCommonIdInformations } from 'src/app/selectors/common-id/common-id-information.selectors';
import { Observable } from 'rxjs';
import { commonIdReportAppLaunchEvent } from '../../../../actions/common-id/common-id-event-log.actions';
import { getCookie } from 'typescript-cookie';
import { COOKIE_NAME_SSC_ID } from '../../../../resources/common-id-config';

@Component({
  selector: 'app-common-id-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class CommonIdMainComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;

  hasNewInformations$: Observable<boolean>;

  constructor(private store: Store<RootState>, private meta: Meta) {}

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.findStaticDataIfNeeded();
    this.setUpInformations();
    this.store.dispatch(commonIdReportAppLaunchEvent());
    const sscId = getCookie(COOKIE_NAME_SSC_ID) || '';
    if (sscId) {
      gtag('set', 'user_properties', { ssc_id: sscId });
    }
  }

  private findStaticDataIfNeeded() {
    this.store
      .select(getFetchedDate)
      .pipe(take(1))
      .subscribe(fetchedDate => {
        if (!fetchedDate) {
          Log.debug(this.LOG_SOURCE, `static data が存在していないため取得します`);
          this.store.dispatch(findStaticData());
          return;
        }
        const cacheExpired = Dates.isCachedDateExpired(fetchedDate, STATIC_DATA_CACHE_DAYS);
        if (cacheExpired) {
          Log.debug(this.LOG_SOURCE, `cache 期間を超過したため再度 static data を取得します. fetchedDate: ${fetchedDate}`);
          this.store.dispatch(initializeStaticDataState());
          this.store.dispatch(findStaticData());
          return;
        }
        Log.debug(this.LOG_SOURCE, 'static data が取得済みのため何もしません');
      });
  }

  private setUpInformations(): void {
    this.store.dispatch(CommonIdInformationActions.commonIdGetInformations());

    this.store
      .select(getCommonIdInformations)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        const newInformation = result.response.some(info => this.compareDate(info));
        this.store.dispatch(CommonIdInformationActions.commonIdUpdateNewInformation({ newInformation }));
      });
  }

  private compareDate(info) {
    const lastAccessedInformation = localStorage.getItem('lastAccessedInformation');
    return lastAccessedInformation !== null ? Dates.jstDateStringFromIso(info.publishedAt) > lastAccessedInformation : true;
  }
}
