<app-wrapper size="lg">
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">settings</mat-icon>
        学校・塾向け管理
      </app-card-content-head>
      <app-wrapper size="fluid">
        <app-content-row>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 functions-menu">
            <app-card-content [showSeparator]="true" class="border-2 rounded-md border-inherit border-gray-50">
              <app-card-content-head>
                <mat-icon class="card-content-head-icon">group</mat-icon>
                アカウント管理
              </app-card-content-head>
              <div class="p-6 flex flex-col h-full">
                <div class="mb-auto">
                  「アカウント登録、編集、削除」「契約プランの登録、編集、削除」「塾登録」などを行うことができます。
                </div>
                <button mat-flat-button class="mt-4 w-full bg-gray-50 text-primary" (click)="accounts()">
                  アカウント管理
                </button>
              </div>
            </app-card-content>
            <app-card-content [showSeparator]="true" class="border-2 rounded-md border-inherit border-gray-50">
              <app-card-content-head>
                <mat-icon class="card-content-head-icon">mail</mat-icon>
                一斉メール送信
              </app-card-content-head>
              <div class="p-6 flex flex-col h-full">
                <div class="mb-auto">
                  団体種別ごとに一斉メールを送ることができます。
                </div>
                <button mat-flat-button class="mt-4 w-full bg-gray-50 text-primary" (click)="bulkMail()">
                  一斉メール送信
                </button>
              </div>
            </app-card-content>
            <app-card-content [showSeparator]="true" class="border-2 rounded-md border-inherit border-gray-50">
              <app-card-content-head>
                <mat-icon class="card-content-head-icon">poll</mat-icon>
                レポート
              </app-card-content-head>
              <div class="p-6 flex flex-col h-full">
                <div class="mb-auto">
                  各科目のログの出力ができます。
                </div>
                <button mat-flat-button class="mt-4 w-full bg-gray-50 text-primary" (click)="reports()">
                  レポート
                </button>
              </div>
            </app-card-content>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<app-wrapper size="lg">
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">settings</mat-icon>
        個人向け管理
      </app-card-content-head>
      <app-wrapper size="fluid">
        <app-content-row>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6 functions-menu">
            <app-card-content [showSeparator]="true" class="border-2 rounded-md border-inherit border-gray-50">
              <app-card-content-head>
                <mat-icon class="card-content-head-icon" fontSet="material-icons-outlined">person_search</mat-icon>
                個人アカウント検索
              </app-card-content-head>
              <div class="p-6 flex flex-col h-full">
                <div class="mb-auto">
                  メールアドレス、ユーザーIDで特定の個人ユーザーアカウントを検索します。
                </div>
                <button mat-flat-button class="mt-4 w-full bg-gray-50 text-primary" (click)="commonIdAccountSingleSearch()">
                  個人アカウント検索
                </button>
              </div>
            </app-card-content>
            <app-card-content [showSeparator]="true" class="border-2 rounded-md border-inherit border-gray-50">
              <app-card-content-head>
                <mat-icon class="card-content-head-icon">group</mat-icon>
                アカウント管理
              </app-card-content-head>
              <div class="p-6 flex flex-col h-full">
                <div class="mb-auto">
                  まなびIDの登録情報や購入履歴の管理ができます。
                </div>
                <button mat-flat-button class="mt-4 w-full bg-gray-50 text-primary" (click)="commonIdAccounts()">
                  アカウント管理
                </button>
              </div>
            </app-card-content>
            <app-card-content [showSeparator]="true" class="border-2 rounded-md border-inherit border-gray-50">
              <app-card-content-head>
                <mat-icon class="card-content-head-icon material-icons-outlined">notifications</mat-icon>
                お知らせ管理
              </app-card-content-head>
              <div class="p-6 flex flex-col h-full">
                <div class="mb-auto">
                  ユーザーの画面に表示するお知らせ機能の管理画面です。CMSに遷移します。
                </div>
                <button mat-flat-button class="mt-4 w-full bg-gray-50 text-primary" (click)="microCms()">
                  お知らせ管理
                </button>
              </div>
            </app-card-content>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>
