import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { enter } from '../../../../resources/animations';
import { filter, Observable, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { navigate, openWindow, setTitle } from '../../../../actions/core.actions';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { CommonIdContactUtil } from '../../../../utils/common-id/common-id-contact-util';
import { getCommonIdSignedInUser } from '../../../../selectors/common-id/common-id-auth.selectors';
import { CommonIdUser } from '../../../../models/common-id/common-id-user';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { CONTACT_GOOGLE_FORM_URL } from 'src/app/resources/common-id-config';

@Component({
  selector: 'app-common-id-purchase-complete',
  templateUrl: './purchase-complete.component.html',
  styleUrls: ['./purchase-complete.component.scss'],
  animations: [enter]
})
export class CommonIdPurchaseCompleteComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private title = 'プラン購入完了';
  loaded$: Observable<boolean>;

  constructor(protected store: Store<RootState>, @Inject(WINDOW_OBJECT) private window: Window) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
  }

  ngOnDestroy() {}

  gotoContact() {
    this.store
      .select(getCommonIdSignedInUser)
      .pipe(
        filter<CommonIdUser>(it => it != null && it !== 'none'),
        take(1)
      )
      .subscribe(user => {
        const url = CommonIdContactUtil.getGoogleFormUrl(CONTACT_GOOGLE_FORM_URL, user.commonId, this.window.navigator.userAgent);
        this.store.dispatch(openWindow({ url }));
      });
  }

  gotoSearch() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUniv() }));
  }

  gotoPayments() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPayments() }));
  }
}
