export class ProblemImageUtil {
  static getPath(fileName: string, trialMode: boolean): string {
    if (fileName === '' || fileName === undefined) return '';
    if (fileName.indexOf('no-picture') !== -1) return `problem-images/${fileName}`;

    const imageDir = trialMode === true ? 'trial-problem-images' : 'problem-images';
    const subjectId = fileName.slice(6, 8);
    const year = fileName.slice(0, 2);
    const universityId = fileName.slice(2, 6);
    const paperId = fileName.slice(0, 10);
    return `${imageDir}/${subjectId}/20${year}/D${universityId}/${paperId}/${fileName}`;
  }
}
