<div class="root-container">
  <!-- 思考力問題 -->
  <div class="item-container">
    <div class="mat-caption label">思考力問題</div>
    <mat-checkbox color="primary" [(ngModel)]="isThinking" (change)="onChangeValues()">思考力問題のみ</mat-checkbox>
  </div>

  <!-- 難易度 -->
  <div class="item-container">
    <div class="mat-caption label">難易度</div>
    <div class="checkbox-list">
      <div *ngFor="let level of levels">
        <mat-checkbox color="primary" [(ngModel)]="level.checked" (change)="onChangeValues()">{{ level.displayName }}</mat-checkbox>
      </div>
    </div>
  </div>

  <!-- 入試年度 -->
  <div class="years-container">
    <mat-form-field class="year-form-field" appearance="outline">
      <mat-label>開始年度</mat-label>
      <mat-select (selectionChange)="onChangeValues()" [formControl]="startYearFormControl">
        <mat-option *ngFor="let year of selectableYears" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="mat-title">〜</div>

    <mat-form-field class="year-form-field" appearance="outline">
      <mat-label>終了年度</mat-label>
      <mat-select (selectionChange)="onChangeValues()" [formControl]="endYearFormControl">
        <mat-option *ngFor="let year of selectableYears" [value]="year">{{ year }}</mat-option>
      </mat-select>
      <mat-error *ngIf="endYearFormControl.hasError('min')">開始年度以上の値を設定してください</mat-error>
    </mat-form-field>
  </div>

  <!-- 大学種別 -->
  <div class="item-container ignore-top-margin">
    <div class="mat-caption label">大学種別</div>
    <div class="checkbox-list">
      <div *ngFor="let universityType of universityTypes">
        <mat-checkbox color="primary" [(ngModel)]="universityType.checked" (change)="onChangeValues()">{{
          universityType.name
        }}</mat-checkbox>
      </div>
    </div>
  </div>

  <!-- エリア -->
  <div class="item-container">
    <div class="mat-caption label">エリア</div>
    <div class="checkbox-list">
      <div *ngFor="let area of areas">
        <mat-checkbox color="primary" [(ngModel)]="area.checked" (change)="onChangeValues()">{{ area.name }}</mat-checkbox>
      </div>
    </div>
  </div>

  <!-- 大学名 -->
  <div class="form-field-container">
    <app-univ-chip-list
      [matLabel]="univChipListLabelName"
      [appearance]="univChipListAppearance"
      [matFormFieldClass]="univChipListMatFormFieldClass"
      (changeSelectedUniversities)="changeSelectedUniversities($event)"
    >
    </app-univ-chip-list>
  </div>

  <!-- 学部系統 -->
  <div class="form-field-container">
    <mat-form-field appearance="outline">
      <mat-label>学部系統</mat-label>
      <mat-select [(value)]="selectedDepartmentCategory" (selectionChange)="onChangeValues()">
        <mat-option *ngFor="let category of selectableDepartmentCategories" [value]="category">{{ category.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
