<header class="sign-in-header">
  <div class="sign-in-header-top">
    <div class="sign-in-header-logo">
      <img src="assets/images/app-logo-with-company.svg" alt="旺文社 入試正解デジタル" />
    </div>
    <div
      class="sign-in-header-user-info"
      tabindex="1"
      (touchend)="touchendUserInfoDropdown()"
      (mouseenter)="hoverUserInfoDropdown()"
      (mouseleave)="blurUserInfoParent()"
      (focus)="focusUserInfoParent()"
      (blur)="blurUserInfoParent()"
      [ngClass]="{
        isShow: showUserInfoDropdown(),
        isHover: showUserInfoDropdown()
      }"
    >
      <!-- ▼ PCここから -->
      <div class="sign-in-header-user-info-label">
        <div class="user">
          <div class="school">{{ (user$ | async)?.school }}</div>
          <div class="name">
            <div class="family-name">{{ (user$ | async)?.familyName }}</div>
            <div class="first-name">{{ (user$ | async)?.firstName }}</div>
            <div class="honorifics">さん</div>
          </div>
        </div>
        <div class="dropdown-icon"><mat-icon aria-hidden="false">arrow_drop_down</mat-icon></div>
      </div>
      <!-- △ PCここから -->
      <!-- ▼ SPここから -->
      <div class="sign-in-header-user-info-label-sp">
        <div class="user-icon"><mat-icon aria-hidden="false">account_circle</mat-icon></div>
        <div class="dropdown-icon"><mat-icon aria-hidden="false">arrow_drop_down</mat-icon></div>
      </div>
      <!-- △ SPここから -->
      <div class="sign-in-header-user-info-dropdown">
        <div class="user px-4 py-2 mb-2 sm:hidden">
          <div class="school text-left ml-1">{{ (user$ | async)?.school }}</div>
          <div class="name">
            <div class="family-name">{{ (user$ | async)?.familyName }}</div>
            <div class="first-name">{{ (user$ | async)?.firstName }}</div>
            <div class="honorifics">さん</div>
          </div>
        </div>
        <button class="item" (click)="signOut()" matRipple tabindex="1" (focus)="focusUserInfoItem(0)" (blur)="blurUserInfoItem(0)">
          <mat-icon class="icon">logout</mat-icon>
          <span class="label">サインアウト</span>
        </button>
      </div>
    </div>
  </div>
</header>
