export class DataUtil {
  static sortStringArray(source: string[], sortDirection: 'asc' | 'desc' = 'asc'): string[] {
    return [...source].sort((a, b) => {
      if (a < b) return sortDirection === 'asc' ? -1 : 1;
      if (a > b) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }

  static sortStringArrayAsNumber(source: string[], sortDirection: 'asc' | 'desc' = 'asc'): string[] {
    return [...source].sort((a, b) => {
      if (Number(a) < Number(b)) return sortDirection === 'asc' ? -1 : 1;
      if (Number(a) > Number(b)) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }

  static sortObjectArrayBySpecificKey<T>(source: T[], key: keyof T, sortDirection: 'asc' | 'desc' = 'asc'): T[] {
    return [...source].sort((a, b) => {
      if (a[key] < b[key]) return sortDirection === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }

  static sortNumberIdentifiedData<T extends { id?: string }>(array: T[]): T[] {
    return array.sort((a, b) => {
      if (Number(a.id) < Number(b.id)) return -1;
      if (Number(a.id) > Number(b.id)) return 1;
      return 0;
    });
  }
}
