import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-overflow-wrapper-x',
  templateUrl: './overflow-wrapper-x.component.html',
  styleUrls: ['./overflow-wrapper-x.component.scss']
})
export class OverflowWrapperXComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() snap = false;
  @Input() snapPaddingLeft?: string;
  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  isScrolled = false;
  isScrolledEnd = false; // スクロールが終点に到達したかどうか
  isOverflowing = false; // スクロールが必要かどうか

  private wrapperElement;

  constructor() {}
  ngOnInit() {}

  ngAfterViewInit() {
    this.wrapperElement = this.wrapper.nativeElement;
    this.wrapperElement.addEventListener('scroll', this.checkScroll.bind(this));
    setTimeout(() => this.checkScroll(), 500); // 初期状態の確認
  }

  ngOnDestroy() {
    this.wrapperElement.removeEventListener('scroll');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScroll(); // ウィンドウサイズ変更時にスクロール状態をチェック
  }

  checkScroll() {
    const element = this.wrapper.nativeElement;
    this.isScrolled = element.scrollLeft > 0;
    this.checkOverflow(); // 右端のはみ出し状態をチェック
  }

  checkOverflow() {
    const element = this.wrapper.nativeElement;
    this.isOverflowing = element.scrollWidth > element.clientWidth;
    if (!this.isOverflowing || element.scrollWidth === element.clientWidth + element.scrollLeft) {
      this.isScrolledEnd = true;
    } else {
      this.isScrolledEnd = false;
    }
  }
}
