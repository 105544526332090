<div class="description-premium-dialog">
  <picture>
    <source srcset="./assets/images/info-premium-required@2x.png" media="(min-width: 780px)" />
    <img src="./assets/images/info-premium-required-sp@2x.png" alt="入試正解デジタルプレミアムにご登録が必要です" />
  </picture>
  <p class="text-center"><a class="underline text-primary" (click)="gotoTop()">プレミアムについて詳しく見る</a></p>
  <app-dialog-basic-footer class="description-premium-dialog-footer">
    <button mat-stroked-button (click)="close()">閉じる</button>
    <button mat-flat-button color="primary" (click)="gotoPlan()">プレミアムプランの選択</button>
  </app-dialog-basic-footer>
</div>
