import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { RootState } from 'src/app/reducers';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { navigate } from '../../../actions/core.actions';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';

@Component({
  selector: 'app-check-active-plan-error',
  templateUrl: './check-active-plan-error.component.html',
  styleUrls: ['./check-active-plan-error.component.scss']
})
export class CheckActivePlanErrorComponent implements OnInit {
  user$: Observable<User>;
  constructor(private store: Store<RootState>, private router: Router) {}

  ngOnInit() {
    this.setUp();
  }

  gotoPlans() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlans(null, null) }));
  }

  private setUp() {
    this.user$ = this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none'));
  }
}
