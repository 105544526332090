<!-- #1216 [attr.data-is-responsive]="isResponsive" -->
<div class="faq-list-item" [attr.aria-expanded]="isExpanded">
  <div class="faq-list-item-head">
    <button class="faq-list-item-head-hit" (click)="toggleIsExpanded()">
      <div class="faq-list-item-head-listmark">Q.</div>
      <div class="faq-list-item-head-label">{{ questionTitle }}</div>
      <div class="faq-list-item-head-arrow">
        <mat-icon *ngIf="!isExpanded" class="icon" aria-hidden="true">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="isExpanded" class="icon" aria-hidden="true">keyboard_arrow_up</mat-icon>
      </div>
    </button>
  </div>
  <div class="faq-list-item-body" [attr.aria-hidden]="!isExpanded">
    <app-provider-accordion [isExpanded]="isExpanded">
      <div class="faq-list-item-body-inner">
        <div class="faq-list-item-body-listmark">A.</div>
        <div class="faq-list-item-body-content">
          <app-document-theme-default>
            <ng-content></ng-content>
          </app-document-theme-default>
        </div>
      </div>
    </app-provider-accordion>
  </div>
</div>
