import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { InformationResponse } from 'src/app/models/information';
import { CallableFunction } from 'src/app/resources/app-firebase';

@Injectable({
  providedIn: 'root'
})
export class InformationService {
  constructor(private aff: AngularFireFunctions) {}

  getInformations(): Observable<InformationResponse[]> {
    const callable = this.aff.httpsCallable(CallableFunction.GET_INFORMATIONS);
    return callable({});
  }
}
