import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, staticDataFeatureKey } from '../reducers/static-data.reducer';

const selectStaticDataState = createFeatureSelector<State>(staticDataFeatureKey);

export const getStaticData = createSelector(selectStaticDataState, state => state);
export const getStaticCommonData = createSelector(selectStaticDataState, state => state.common);
export const getSubject = createSelector(getStaticCommonData, staticCommonData => (staticCommonData ? staticCommonData.subjects : null));
export const getHighSchool = createSelector(getStaticCommonData, staticCommonData =>
  staticCommonData ? staticCommonData.highSchools : null
);
export const getUniversities = createSelector(getStaticCommonData, staticCommonData =>
  staticCommonData ? staticCommonData.universities : null
);

export const getStaticEnglishData = createSelector(selectStaticDataState, state => state.english);
export const getStaticMathData = createSelector(selectStaticDataState, state => state.math);
export const getStaticNationalLanguageData = createSelector(selectStaticDataState, state => state.nationalLanguage);
export const getStaticPhysicsData = createSelector(selectStaticDataState, state => state.physics);
export const getStaticChemistryData = createSelector(selectStaticDataState, state => state.chemistry);
export const getStaticBiologyData = createSelector(selectStaticDataState, state => state.biology);
export const getStaticJapaneseHistoryData = createSelector(selectStaticDataState, state => state.japaneseHistory);
export const getStaticWorldHistoryData = createSelector(selectStaticDataState, state => state.worldHistory);
export const getStaticGeographyData = createSelector(selectStaticDataState, state => state.geography);
export const getStaticPoliticalEconomyData = createSelector(selectStaticDataState, state => state.politicalEconomy);

/** 初回ロードするデータ増えたら逐次追加 */
export const getStaticDataLoaded = createSelector(
  selectStaticDataState,
  state =>
    state.common != null &&
    state.english != null &&
    state.math != null &&
    state.nationalLanguage != null &&
    state.physics != null &&
    state.chemistry != null &&
    state.biology != null &&
    state.japaneseHistory != null &&
    state.worldHistory != null &&
    state.geography != null &&
    state.politicalEconomy != null
);

export const getFetchedDate = createSelector(selectStaticDataState, state => state.fetchedDate);
