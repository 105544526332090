import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { dispatchInfoMessage, dispatchAppError } from 'src/app/actions/core.actions';
import { initializeUpdateDisableRenewPlanAlertState, updateDisableRenewPlanAlert } from 'src/app/actions/plan.actions';
import { RootState } from 'src/app/reducers';
import { getUpdateDisableRenewPlanAlertResult } from 'src/app/selectors/plan.selectors';

import { Log } from '../../../utils/log';
import { enter } from '../../../resources/animations';
import { UpdateDisableRenewPlanAlertRequest } from 'src/app/models/update-disable-renew-plan-alert-request';

export interface ChangeRenewPlanAlertDialogData {
  planId: string;
  planName: string;
  recordId: number;
  isAlertDisabled: boolean;
}

@Component({
  selector: 'app-change-renew-plan-alert-dialog',
  templateUrl: './change-renew-plan-alert-dialog.component.html',
  styleUrls: ['./change-renew-plan-alert-dialog.component.scss'],
  animations: [enter]
})
export class ChangeRenewPlanAlertDialogComponent implements OnInit {
  disabled = false;
  private LOG_SOURCE = this.constructor.name;

  disableAlertMessage = '停止';
  enableAlertMessage = '停止解除';
  functionName: string;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<ChangeRenewPlanAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeRenewPlanAlertDialogData
  ) {}

  ngOnInit() {}

  changeRenewPlanAlert() {
    if (
      !this.data ||
      !this.data.planId ||
      !this.data.recordId ||
      this.data.isAlertDisabled === undefined ||
      this.data.isAlertDisabled === null
    ) {
      Log.warn(this.LOG_SOURCE, `データが正しく設定されていません: `, this.data);
      return;
    }

    this.functionName = this.data.isAlertDisabled === true ? this.enableAlertMessage : this.disableAlertMessage;

    const updateDisableRenewPlanAlertRequest: UpdateDisableRenewPlanAlertRequest = {
      planId: this.data.planId,
      recordId: this.data.recordId,
      isRenewPlanAlertDisabled: this.data.isAlertDisabled === true ? false : true
    };

    this.disableForms();

    Log.debug(this.LOG_SOURCE, `継続訴求メール${this.functionName}をします`);
    this.store
      .select(getUpdateDisableRenewPlanAlertResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeUpdateDisableRenewPlanAlertState());
        if (result.success) {
          const message = `${this.data.planName} の継続訴求メール${this.functionName}が完了しました`;
          this.store.dispatch(dispatchInfoMessage({ message }));
          Log.debug(this.LOG_SOURCE, `${this.data.planName} の継続訴求メール${this.functionName}が完了: `, result);
          this.enableForms();
        } else {
          Log.error(
            this.LOG_SOURCE,
            `${this.data.planName} の継続訴求メール${this.functionName}の変更でエラーが発生しました: `,
            JSON.stringify(result.error)
          );
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error: result.error }));
        }

        this.dialogRef.close();
      });

    this.store.dispatch(updateDisableRenewPlanAlert({ updateDisableRenewPlanAlert: updateDisableRenewPlanAlertRequest }));
    this.store.dispatch(
      dispatchInfoMessage({
        message: `${this.data.planName} の継続訴求メール${this.functionName}の変更をしています…`
      })
    );
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close();
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
