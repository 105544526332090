import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

import { CallableFunction } from '../resources/app-firebase';
import { SearchCountResponse } from '../models/search-count-response';
import {
  SearchCountRequest,
  SearchCondition,
  SearchProblemsCondition,
  SearchProblemsRequestType,
  SearchProblemsRequest
} from '../models/search-condition';
import { ScienceSearchCondition } from '../models/science-search-condition';
import { ScienceProblem, EnglishProblem, HistoryProblem, NationalLanguageProblem } from '../models/problem';
import { EnglishSearchCondition } from '../models/english-search-condition';
import { HistorySearchCondition } from '../models/history-search-condition';
import { FindProblemRequest } from '../models/find-problem-request';
import { NationalLanguageSearchCondition } from '../models/national-language-search-condition';
import { FindProblemByPaperIdRequest } from '../models/find-problem-by-paper-id-request';

type FindProblemCountType = SearchCondition<
  EnglishSearchCondition | ScienceSearchCondition | NationalLanguageSearchCondition | HistorySearchCondition
>;

@Injectable({
  providedIn: 'root'
})
export class ProblemService {
  constructor(private aff: AngularFireFunctions) {}

  findProblemCount(condition: FindProblemCountType, logging: boolean): Observable<SearchCountResponse> {
    const req: SearchCountRequest<
      EnglishSearchCondition | ScienceSearchCondition | NationalLanguageSearchCondition | HistorySearchCondition
    > = { ...condition, logging };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM_COUNT);
    return callable(req);
  }

  findEnglishProblemIds(condition: SearchProblemsCondition<EnglishSearchCondition>): Observable<string[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM_IDS);
    return callable(condition);
  }

  findEnglishProblems(
    condition: SearchProblemsCondition<EnglishSearchCondition>,
    reqType: SearchProblemsRequestType
  ): Observable<EnglishProblem[]> {
    const req: SearchProblemsRequest<EnglishSearchCondition> = { ...condition, type: reqType };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEMS);
    return callable(req);
  }

  findEnglishProblem(subjectId: string, problemId: string): Observable<EnglishProblem> {
    const req: FindProblemRequest = { subjectId, problemId };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM);
    return callable(req);
  }

  findEnglishProblemByPaperId(subjectId: string, paperId: string): Observable<EnglishProblem> {
    const req: FindProblemByPaperIdRequest = { subjectId, paperId };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM_BY_PAPER_ID);
    return callable(req);
  }

  /** math, physics, chemistry, biology, geography, political economy */
  findScienceProblems(
    condition: SearchProblemsCondition<ScienceSearchCondition>,
    reqType: SearchProblemsRequestType
  ): Observable<ScienceProblem[]> {
    const req: SearchProblemsRequest<ScienceSearchCondition> = { ...condition, type: reqType };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEMS);
    return callable(req);
  }

  findScienceProblemIds(condition: SearchProblemsCondition<ScienceSearchCondition>): Observable<string[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM_IDS);
    return callable(condition);
  }

  findScienceProblem(subjectId: string, problemId: string): Observable<ScienceProblem> {
    const req: FindProblemRequest = { subjectId, problemId };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM);
    return callable(req);
  }

  findScienceProblemByPaperId(subjectId: string, paperId: string): Observable<ScienceProblem> {
    const req: FindProblemByPaperIdRequest = { subjectId, paperId };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM_BY_PAPER_ID);
    return callable(req);
  }

  findNationalLanguageProblemIds(condition: SearchProblemsCondition<NationalLanguageSearchCondition>): Observable<string[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM_IDS);
    return callable(condition);
  }

  findNationalLanguageProblems(
    condition: SearchProblemsCondition<NationalLanguageSearchCondition>,
    reqType: SearchProblemsRequestType
  ): Observable<NationalLanguageProblem[]> {
    const req: SearchProblemsRequest<NationalLanguageSearchCondition> = { ...condition, type: reqType };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEMS);
    return callable(req);
  }

  findNationalLanguageProblem(subjectId: string, problemId: string): Observable<NationalLanguageProblem[]> {
    const req: FindProblemRequest = { subjectId, problemId };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEMS_BY_ID);
    return callable(req);
  }

  findNationalLanguageProblemByPaperId(subjectId: string, paperId: string): Observable<NationalLanguageProblem> {
    const req: FindProblemByPaperIdRequest = { subjectId, paperId };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM_BY_PAPER_ID);
    return callable(req);
  }

  findNationalLanguageProblemsById(subjectId: string, problemId: string): Observable<NationalLanguageProblem[]> {
    const req: FindProblemRequest = { subjectId, problemId };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEMS_BY_ID);
    return callable(req);
  }

  /** japanese history, world history */
  findHistoryProblemIds(condition: SearchProblemsCondition<HistorySearchCondition>): Observable<string[]> {
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM_IDS);
    return callable(condition);
  }

  findHistoryProblems(
    condition: SearchProblemsCondition<HistorySearchCondition>,
    reqType: SearchProblemsRequestType
  ): Observable<HistoryProblem[]> {
    const req: SearchProblemsRequest<HistorySearchCondition> = { ...condition, type: reqType };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEMS);
    return callable(req);
  }

  findHistoryProblem(subjectId: string, problemId: string): Observable<HistoryProblem> {
    const req: FindProblemRequest = { subjectId, problemId };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM);
    return callable(req);
  }

  findHistoryProblemByPaperId(subjectId: string, paperId: string): Observable<HistoryProblem> {
    const req: FindProblemByPaperIdRequest = { subjectId, paperId };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_PROBLEM_BY_PAPER_ID);
    return callable(req);
  }
}
