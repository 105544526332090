import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Collection } from '../resources/app-firebase';
import { RawStaticData } from '../models/static-data';
import { RawStaticScienceData, StaticScienceData } from '../models/static-science-data';
import { StaticCommonData, RawStaticCommonData } from '../models/static-common-data';
import { StaticEnglishData, RawStaticEnglishData } from '../models/static-english-data';
import { RawStaticHistoryData, StaticHistoryData } from '../models/static-history-data';
import { StaticDataMapper } from '../mappers/static-data-mapper';
import { GeneralError } from '../errors/general-error';
import { RawStaticNationalLanguageData, StaticNationalLanguageData } from '../models/static-national-language-data';

export interface AllStaticData {
  common: StaticCommonData;
  english: StaticEnglishData;
  math: StaticScienceData;
  nationalLanguage: StaticNationalLanguageData;
  physics: StaticScienceData;
  chemistry: StaticScienceData;
  biology: StaticScienceData;
  japaneseHistory: StaticHistoryData;
  worldHistory: StaticHistoryData;
  geography: StaticScienceData;
  politicalEconomy: StaticScienceData;
}

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {
  constructor(private afs: AngularFirestore) {}

  findAllStaticData(): Observable<AllStaticData> {
    return this.afs
      .collection<RawStaticData>(Collection.STATIC)
      .get()
      .pipe(
        map(snapshot => (snapshot.empty ? [] : snapshot.docs.map(doc => doc.data() as RawStaticData))),
        map(rawDataArray => {
          const allData = rawDataArray.reduce<Partial<AllStaticData>>(
            (acc, rawData) => this.mapRawDataToPartialAllStaticData(acc, rawData),
            {}
          );
          if (!allData.common) throw GeneralError.notFound('common data が存在しません');
          if (!allData.english) throw GeneralError.notFound('english data が存在しません');
          if (!allData.nationalLanguage) throw GeneralError.notFound('national language data が存在しません');
          if (!allData.math) throw GeneralError.notFound('math data が存在しません');
          if (!allData.physics) throw GeneralError.notFound('physics data が存在しません');
          if (!allData.chemistry) throw GeneralError.notFound('chemistry data が存在しません');
          if (!allData.biology) throw GeneralError.notFound('biology data が存在しません');
          if (!allData.japaneseHistory) throw GeneralError.notFound('japanese history data が存在しません');
          if (!allData.worldHistory) throw GeneralError.notFound('world history data が存在しません');
          if (!allData.geography) throw GeneralError.notFound('geography data が存在しません');
          if (!allData.politicalEconomy) throw GeneralError.notFound('political Economy data が存在しません');

          const [
            common,
            english,
            math,
            nationalLanguage,
            physics,
            chemistry,
            biology,
            japaneseHistory,
            worldHistory,
            geography,
            politicalEconomy
          ] = [
            allData.common,
            allData.english,
            allData.math,
            allData.nationalLanguage,
            allData.physics,
            allData.chemistry,
            allData.biology,
            allData.japaneseHistory,
            allData.worldHistory,
            allData.geography,
            allData.politicalEconomy
          ];
          const all: AllStaticData = {
            common,
            english,
            math,
            nationalLanguage,
            physics,
            chemistry,
            biology,
            japaneseHistory,
            worldHistory,
            geography,
            politicalEconomy
          };
          return all;
        })
      );
  }

  private mapRawDataToPartialAllStaticData(allData: Partial<AllStaticData>, rawData: RawStaticData): Partial<AllStaticData> {
    if (rawData.dataType === 'COMMON') {
      const common = StaticDataMapper.mapCommonDataFromRaw(rawData as RawStaticCommonData);
      return { ...allData, common };
    }
    if (rawData.dataType === 'ENGLISH') {
      const english = StaticDataMapper.mapEnglishDataFromRaw(rawData as RawStaticEnglishData);
      return { ...allData, english };
    }
    if (rawData.dataType === 'MATH') {
      const math = StaticDataMapper.mapScienceDataFromRaw(rawData as RawStaticScienceData);
      return { ...allData, math };
    }
    if (rawData.dataType === 'NATIONAL_LANGUAGE') {
      const nationalLanguage = StaticDataMapper.mapNationalLanguageDataFromRaw(rawData as RawStaticNationalLanguageData);
      return { ...allData, nationalLanguage };
    }
    if (rawData.dataType === 'PHYSICS') {
      const physics = StaticDataMapper.mapScienceDataFromRaw(rawData as RawStaticScienceData);
      return { ...allData, physics };
    }
    if (rawData.dataType === 'CHEMISTRY') {
      const chemistry = StaticDataMapper.mapScienceDataFromRaw(rawData as RawStaticScienceData);
      return { ...allData, chemistry };
    }
    if (rawData.dataType === 'BIOLOGY') {
      const biology = StaticDataMapper.mapScienceDataFromRaw(rawData as RawStaticScienceData);
      return { ...allData, biology };
    }
    if (rawData.dataType === 'JAPANESE_HISTORY') {
      const japaneseHistory = StaticDataMapper.mapHistoryDataFromRaw(rawData as RawStaticHistoryData);
      return { ...allData, japaneseHistory };
    }
    if (rawData.dataType === 'WORLD_HISTORY') {
      const worldHistory = StaticDataMapper.mapHistoryDataFromRaw(rawData as RawStaticHistoryData);
      return { ...allData, worldHistory };
    }
    if (rawData.dataType === 'GEOGRAPHY') {
      const geography = StaticDataMapper.mapScienceDataFromRaw(rawData as RawStaticScienceData);
      return { ...allData, geography };
    }
    if (rawData.dataType === 'POLITICAL_ECONOMY') {
      const politicalEconomy = StaticDataMapper.mapScienceDataFromRaw(rawData as RawStaticScienceData);
      return { ...allData, politicalEconomy };
    }
    return allData;
  }
}
