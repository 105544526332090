<app-wrapper size="fluid" isFitWithSmallScreen="true">
  <app-content-row>
    <app-breadcrumbs (breadcrumbsClick)="breadcrumbsClickHandler($event)" [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
  </app-content-row>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon" fontSet="material-icons-outlined">person_search</mat-icon>
        {{ title }}
      </app-card-content-head>

      <app-card-content-nav (menuClick)="menuClickHandler($event)" [menus]="menus"></app-card-content-nav>

      <app-wrapper size="fluid">
        <!-- 絞り込み機能 -->
        <app-content-row>
          <div class="">
            <form [formGroup]="filterForm" (keydown.enter)="$event.preventDefault()">
              <div class="col-auto flex flex-wrap gap-2 items-start">
                <div class="grow max-w-md">
                  <div class="w-full" *ngIf="selectedMenu === 'email'">
                    <mat-form-field
                      class="text-sm w-full [&_.mat-form-field-wrapper]:pb-0 [&_.mat-form-field-wrapper]:my-0"
                      floatLabel="always"
                      appearance="outline"
                    >
                      <mat-label>メールアドレス</mat-label>
                      <input
                        matInput
                        [placeholder]="emailPlaceholder"
                        formControlName="email"
                        (input)="onInputChanges($event.target.value)"
                      />
                    </mat-form-field>
                  </div>
                  <div class="w-full" *ngIf="selectedMenu === 'userId'">
                    <mat-form-field
                      class="text-sm w-full [&_.mat-form-field-wrapper]:pb-0 [&_.mat-form-field-wrapper]:my-0"
                      floatLabel="always"
                      appearance="outline"
                    >
                      <mat-label>User ID</mat-label>
                      <input
                        matInput
                        [placeholder]="userIdPlaceholder"
                        formControlName="userId"
                        (input)="onInputChanges($event.target.value)"
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="flex gap-2 h-[50px]">
                  <button
                    class="h-full w-24 md:w-32"
                    mat-flat-button
                    color="primary"
                    [disabled]="isSearchButtonDisabled"
                    (click)="search(selectedMenu)"
                  >
                    検索
                  </button>
                  <button class="h-full md:w-32" mat-stroked-button (click)="resetFilter(selectedMenu)">
                    <mat-icon class="text-gray-300 -mt-[1px]">delete</mat-icon>
                    リセット
                  </button>
                </div>
              </div>
            </form>
          </div>
        </app-content-row>

        <!-- 該当なし表示 -->
        <app-content-row>
          <app-content-no-data *ngIf="isCommonIdUserNotFound$ | async">
            該当する情報はありません
          </app-content-no-data>
        </app-content-row>
      </app-wrapper>

      <!-- 検索結果 -->
      <div *ngIf="(isCommonIdUserSearching$ | async) === false && (isCommonIdUserNotFound$ | async) === false && isResultDisplayed">
        <app-wrapper size="fluid" isFitWithSmallScreen="true">
          <app-content-row>
            <app-common-id-accounts-table
              [dataSource]="account"
              [displayedColumns]="displayedColumns"
              [isSingleSearch]="true"
              [commonIdUser]="commonIdUser$ | async"
              (accountUpdated)="search(selectedMenu)"
            >
            </app-common-id-accounts-table>
          </app-content-row>
        </app-wrapper>
      </div>

      <!-- 検索中表示 -->
      <div class="h-48 relative" *ngIf="isCommonIdUserSearching$ | async">
        <div class="progress-48">
          <mat-spinner [diameter]="48"></mat-spinner>
        </div>
      </div>
    </app-card-content>
  </app-content-row>
</app-wrapper>
