import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button-with-progress',
  templateUrl: './button-with-progress.component.html',
  styleUrls: ['./button-with-progress.component.scss']
})
export class ButtonWithProgressComponent implements OnInit {
  @Input() label: string;
  @Input() color: string;
  @Input() loading: boolean;

  constructor() {}

  ngOnInit() {}
}
