import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, toolsFeatureKey } from '../reducers/tools.reducer';

const selectToolsState = createFeatureSelector<State>(toolsFeatureKey);

export const getTransferStaticDataProcessing = createSelector(selectToolsState, state => state.transferStaticDataProcessing);
export const getTransferPlaylistsProcessing = createSelector(selectToolsState, state => state.transferPlaylistsProcessing);
export const getCommonIdTransferPlaylistsProcessing = createSelector(selectToolsState, state => state.commonIdTransferPlaylistsProcessing);
export const getRotateRawSearchEventGroupProcessing = createSelector(selectToolsState, state => state.rotateRawSearchEventGroupProcessing);
export const getExportSearchEventLogProcessing = createSelector(selectToolsState, state => state.exportSearchEventLogProcessing);
export const getAggregateDailySearchEventLogProcessing = createSelector(
  selectToolsState,
  state => state.aggregateDailySearchEventLogProcessing
);
export const getAggregateWeeklySearchEventLogProcessing = createSelector(
  selectToolsState,
  state => state.aggregateWeeklySearchEventLogProcessing
);
export const getCleanUpRawSearchEventGroupProcessing = createSelector(
  selectToolsState,
  state => state.cleanUpRawSearchEventGroupProcessing
);

export const getRotateRawAdminOperationEventGroupProcessing = createSelector(
  selectToolsState,
  state => state.rotateRawAdminOperationEventGroupProcessing
);
export const getExportAdminOperationEventLogProcessing = createSelector(
  selectToolsState,
  state => state.exportAdminOperationEventLogProcessing
);
export const getAggregateDailyAdminOperationEventLogProcessing = createSelector(
  selectToolsState,
  state => state.aggregateDailyAdminOperationEventLogProcessing
);
export const getAggregateMonthlyAdminOperationEventLogProcessing = createSelector(
  selectToolsState,
  state => state.aggregateMonthlyAdminOperationEventLogProcessing
);
export const getCleanUpRawAdminOperationEventGroupProcessing = createSelector(
  selectToolsState,
  state => state.cleanUpRawAdminOperationEventGroupProcessing
);

export const getBackupFirestoreProcessing = createSelector(selectToolsState, state => state.backupFirestoreProcessing);

export const getCheckBookmarkMaxCountProcessing = createSelector(selectToolsState, state => state.checkBookmarkMaxCountProcessing);

export const getCheckUniversityBookmarkMaxCountProcessing = createSelector(
  selectToolsState,
  state => state.checkUniversityBookmarkMaxCountProcessing
);

export const getCheckPaperBookmarkMaxCountProcessing = createSelector(
  selectToolsState,
  state => state.checkPaperBookmarkMaxCountProcessing
);

export const getCheckAnsweredProblemsMaxCountProcessing = createSelector(
  selectToolsState,
  state => state.checkAnsweredProblemsMaxCountProcessing
);

export const getCheckPlaylistBookmarkMaxCountProcessing = createSelector(
  selectToolsState,
  state => state.checkPlaylistBookmarkMaxCountProcessing
);

export const getDeleteMembersProcessing = createSelector(selectToolsState, state => state.deleteMembersProcessing);
