import { Action, createReducer, on } from '@ngrx/store';

import * as CommonIdPlaylistBookmarkActions from '../../actions/common-id/common-id-playlist-bookmark.actions';
import { AppError } from '../../errors/app-error';
import { CommonIdAddPlaylistBookmarkResponse, CommonIdPlaylistProblemIds } from '../../models/common-id/common-id-playlist-bookmark';
import { CommonIdDeletePlaylistBookmarkResponse } from '../../models/common-id/common-id-playlist-bookmark';
import { CommonIdPlaylistBookmark, CommonIdBookmarkPlaylist } from '../../models/common-id/common-id-playlist-bookmark';

export const commonIdPlaylistBookmarkFeatureKey = 'commonIdPlaylistBookmark';

export interface CommonIdAddPlaylistBookmarkResult {
  success: boolean;
  response: CommonIdAddPlaylistBookmarkResponse | null;
  error: AppError | null;
}

export interface CommonIdDeletePlaylistBookmarkResult {
  success: boolean;
  response: CommonIdDeletePlaylistBookmarkResponse | null;
  error: AppError | null;
}

export interface CommonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsResult {
  success: boolean;
  response: CommonIdPlaylistProblemIds[] | null;
  error: AppError | null;
}

export interface State {
  playlistBookmark: CommonIdPlaylistBookmark | null;
  addPlaylistBookmarkResult: CommonIdAddPlaylistBookmarkResult | null;
  deletePlaylistBookmarkResult: CommonIdDeletePlaylistBookmarkResult | null;
  playlistBookmarkProblems: CommonIdBookmarkPlaylist[] | null;
  findPlaylistProblemIdsByPlaylistBookmarkIdsResult: CommonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsResult | null;
}

export const initialState: State = {
  playlistBookmark: null,
  addPlaylistBookmarkResult: null,
  deletePlaylistBookmarkResult: null,
  playlistBookmarkProblems: null,
  findPlaylistProblemIdsByPlaylistBookmarkIdsResult: null
};

const CommonIdPlaylistBookmarkReducer = createReducer(
  initialState,

  // Find Bookmarks -------------------------------------------------------
  on(CommonIdPlaylistBookmarkActions.commonIdFindPlaylistBookmarkSuccess, (state, { playlistBookmark }) => ({
    ...state,
    playlistBookmark
  })),
  on(CommonIdPlaylistBookmarkActions.initializeCommonIdFindPlaylistBookmarkState, state => {
    const next: State = { ...state, playlistBookmark: null };
    return next;
  }),

  // Find Bookmark Playlist Problem Ids -------------------------------------------------------
  on(CommonIdPlaylistBookmarkActions.commonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsSuccess, (state, { response }) => ({
    ...state,
    findPlaylistProblemIdsByPlaylistBookmarkIdsResult: { success: true, response, error: null }
  })),
  on(CommonIdPlaylistBookmarkActions.commonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsFailure, (state, { error }) => ({
    ...state,
    findPlaylistProblemIdsByPlaylistBookmarkIdsResult: { success: false, response: null, error }
  })),
  on(CommonIdPlaylistBookmarkActions.initializeCommonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsState, state => {
    const next: State = { ...state, findPlaylistProblemIdsByPlaylistBookmarkIdsResult: null };
    return next;
  }),

  // Add Bookmark ----------------------------------------------------------------
  on(CommonIdPlaylistBookmarkActions.commonIdAddPlaylistBookmarkSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      addPlaylistBookmarkResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(CommonIdPlaylistBookmarkActions.commonIdAddPlaylistBookmarkFailure, (state, { error }) => {
    const next: State = {
      ...state,
      addPlaylistBookmarkResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(CommonIdPlaylistBookmarkActions.initializeCommonIdAddPlaylistBookmarkState, state => {
    const next: State = { ...state, addPlaylistBookmarkResult: null };
    return next;
  }),

  // Delete Bookmark ----------------------------------------------------------------
  on(CommonIdPlaylistBookmarkActions.commonIdDeletePlaylistBookmarkSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      deletePlaylistBookmarkResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(CommonIdPlaylistBookmarkActions.commonIdDeletePlaylistBookmarkFailure, (state, { error }) => {
    const next: State = {
      ...state,
      deletePlaylistBookmarkResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(CommonIdPlaylistBookmarkActions.initializeCommonIdDeletePlaylistBookmarkState, state => {
    const next: State = { ...state, deletePlaylistBookmarkResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return CommonIdPlaylistBookmarkReducer(state, action);
}
