import { Component, OnInit } from '@angular/core';
import { META_VIEWPORT_OTHER } from '../../../../resources/config';
import { Meta } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { setTitle } from '../../../../actions/core.actions';
import { getCookie } from 'typescript-cookie';
import { COOKIE_NAME_SSC_ID } from '../../../../resources/common-id-config';

@Component({
  selector: 'app-common-id-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class CommonIdTermsComponent implements OnInit {
  private title = '利用規約';

  constructor(private store: Store<RootState>, private meta: Meta) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.meta.updateTag(META_VIEWPORT_OTHER);

    const sscId = getCookie(COOKIE_NAME_SSC_ID) || '';
    if (sscId) {
      gtag('set', 'user_properties', { ssc_id: sscId });
    }
  }
}
