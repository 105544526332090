import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import * as MaintenanceActions from '../actions/maintenance.actions';
import { MaintenanceService } from '../services/maintenance.service';
import { dispatchAppError } from '../actions/core.actions';
import { FirestoreError } from '../errors/firestore-error';

@Injectable()
export class MaintenanceEffects {
  private LOG_SOURCE = this.constructor.name;

  watchMaintenanceMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaintenanceActions.watchMaintenanceMode),
      switchMap(() =>
        this.maintenanceService.watchMaintenanceMode().pipe(
          map(maintenanceMode => MaintenanceActions.watchMaintenanceModeSuccess({ maintenanceMode })),
          catchError(e =>
            of(
              MaintenanceActions.watchMaintenanceModeFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private maintenanceService: MaintenanceService) {}
}
