import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CallableFunction, Collection } from 'src/app/resources/app-firebase';
import { CommonIdUser, CommonIdUserId } from '../../models/common-id/common-id-user';
@Injectable({
  providedIn: 'root'
})
export class CommonIdUserService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  findCommonIdUser(id: CommonIdUserId): Observable<CommonIdUser | undefined> {
    return this.afs
      .collection(Collection.COMMON_ID_USERS)
      .doc<CommonIdUser>(id)
      .get()
      .pipe(map(snapshot => (snapshot.exists ? (snapshot.data() as CommonIdUser) : undefined)));
  }

  findCommonIdUserByEmail(email: string): Observable<CommonIdUser | null> {
    return this.afs
      .collection(Collection.COMMON_ID_USERS, ref => {
        return ref.where('email', '==', email);
      })
      .get()
      .pipe(map(snapshot => (snapshot.empty ? null : (snapshot.docs[0].data() as CommonIdUser))));
  }

  findCommonIdUserByCommonId(commonId: string): Observable<CommonIdUser | null> {
    return this.afs
      .collection(Collection.COMMON_ID_USERS, ref => {
        return ref.where('commonId', '==', commonId);
      })
      .get()
      .pipe(map(snapshot => (snapshot.empty ? null : (snapshot.docs[0].data() as CommonIdUser))));
  }

  watchCommonIdUsers(): Observable<CommonIdUser[]> {
    return this.afs
      .collection<CommonIdUser>(Collection.COMMON_ID_USERS, ref => ref.orderBy('createdAt'))
      .valueChanges();
  }

  updateCommonIdUserIsActive(user: CommonIdUser): Observable<CommonIdUser> {
    const callable = this.aff.httpsCallable<CommonIdUser, CommonIdUser>(CallableFunction.COMMON_ID_UPDATE_USER_IS_ACTIVE);
    return callable(user);
  }

  updateCommonIdUser(user: CommonIdUser): Observable<CommonIdUser> {
    const callable = this.aff.httpsCallable<CommonIdUser, CommonIdUser>(CallableFunction.COMMON_ID_UPDATE_USER);
    return callable(user);
  }
}
