import {
  UniversityBaseSearchCondition,
  UniversitySearchCondition,
  PrefectureCondition,
  UniversityCondition
} from 'src/app/models/university-search-condition';
import {
  UniversitySearchConditionWithPrefectureIdsQueryParams,
  UniversitySearchConditionWithUniversityIdsQueryParams,
  UniversitySearchSelectedYearParams,
  UniversitySearchDeptNameAndSpecialSubjectQueryParams
} from 'src/app/models/university-search-query-params';
import { SearchUnivProblemCondition } from '../models/search-univ-problem-condition';
import { SearchUnivProblemConditionQueryParams } from '../models/query-params';
import { SortType } from '../resources/config';

export class UniversitySearchQueryParamsMapper {
  static encodeUniversitySearchConditionWithUniversityIds(
    condition: UniversitySearchCondition<UniversityCondition>
  ): UniversitySearchConditionWithUniversityIdsQueryParams {
    const baseSearchCondition = UniversitySearchQueryParamsMapper.encodeBaseUniversitySearchCondition(condition);

    const universityCondition = condition.universityCondition;
    const universityIds = universityCondition.universityIds.length === 0 ? [] : [...universityCondition.universityIds];

    const params: UniversitySearchConditionWithUniversityIdsQueryParams = {
      ...baseSearchCondition,
      universityIds
    };

    return params;
  }

  static encodeUniversitySearchConditionWithPrefectureIds(
    condition: UniversitySearchCondition<PrefectureCondition>
  ): UniversitySearchConditionWithPrefectureIdsQueryParams {
    const baseSearchCondition = UniversitySearchQueryParamsMapper.encodeBaseUniversitySearchCondition(condition);

    const universityCondition = condition.universityCondition;
    const prefectureIds = universityCondition.prefectureIds.length === 0 ? [] : [...universityCondition.prefectureIds];

    const params: UniversitySearchConditionWithPrefectureIdsQueryParams = {
      ...baseSearchCondition,
      prefectureIds
    };

    if (universityCondition.universityTypeIds && universityCondition.universityTypeIds.length !== 0) {
      params.universityTypeIds = [...universityCondition.universityTypeIds];
    }

    return params;
  }

  static encodeBaseUniversitySearchCondition(
    source: UniversitySearchCondition<UniversityCondition | PrefectureCondition>
  ): UniversityBaseSearchCondition {
    const condition: UniversityBaseSearchCondition = {
      type: source.type,
      year: source.year,
      departmentCategoryId: source.departmentCategoryId
    };

    return condition;
  }

  // 問題詳細へ遷移する際のクエリパラメタを作成 - BtoCのみ
  static encodeDeptNameAndSpecialSubject(deptName: string, specialSubject: string): UniversitySearchDeptNameAndSpecialSubjectQueryParams {
    const params: UniversitySearchDeptNameAndSpecialSubjectQueryParams = {
      deptName,
      specialSubject: specialSubject ? specialSubject : ''
    };

    return params;
  }

  static encodeUniversitySearchSelectedYear(year: string): UniversitySearchSelectedYearParams {
    const params: UniversitySearchSelectedYearParams = {
      year
    };

    return params;
  }

  // 大学検索結果から問題詳細へ遷移する際のクエリパラメタを作成 - BtoSのみ
  static encodeSearchUnivProblemCondition(condition: SearchUnivProblemCondition): SearchUnivProblemConditionQueryParams {
    const params: SearchUnivProblemConditionQueryParams = {
      subjectId: condition.subjectId,
      startYear: condition.year,
      endYear: condition.year,
      universityId: condition.universityId,
      sortType: SortType.D_CODE
    };

    return params;
  }
}
