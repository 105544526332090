<div class="search-univ" [attr.data-is-search-summary-displayed]="isSearchSummaryDisplayed">
  <app-wrapper size="lg" isFitWithSmallScreen="true">
    <app-content-row>
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon class="card-content-head-icon">location_city</mat-icon>
          大学検索
        </app-card-content-head>
        <app-card-content-nav
          (menuClick)="menuHandlerClick.emit($event)"
          [menus]="[
            {
              key: 'school',
              icon: 'school',
              label: '大学名で探す',
              isActive: true
            },
            {
              key: 'map',
              icon: 'map',
              fontSet: 'material-icons-outlined',
              label: '都道府県で探す',
              isActive: false
            }
          ]"
        ></app-card-content-nav>
        <app-wrapper size="fluid">
          <app-content-row>
            <app-univ-chip-list
              [matLabel]="univChipListLabelName"
              [floatLabel]="univChipListfloatLabel"
              [appearance]="univChipListAppearance"
              [matFormFieldClass]="univChipListMatFormFiledClass"
              (changeSelectedUniversities)="changeSelectedUniversities($event)"
            >
            </app-univ-chip-list>
            <p><small class="text-gray-500 text-xs">※ 大学入学共通テスト（旧大学入試センター試験）も検索できます。</small></p>
          </app-content-row>
          <app-content-row>
            <div class="flex flex-wrap gap-4 items-center">
              <mat-form-field class="year-form-field" appearance="outline" floatLabel="always">
                <mat-label>年度</mat-label>
                <div class="year-form-field-select">
                  <mat-select (selectionChange)="onSelectionChange()" [formControl]="yearFormControl">
                    <mat-option *ngFor="let year of selectableYears" [value]="year.value">{{ year.label }}</mat-option>
                  </mat-select>
                </div>
              </mat-form-field>
              <mat-form-field class="department-form-field" appearance="outline" floatLabel="always">
                <mat-label>学部系統</mat-label>
                <div class="department-form-field-select">
                  <mat-select [(value)]="selectedDepartmentCategory" (selectionChange)="onSelectionChange()">
                    <mat-option *ngFor="let category of selectableDepartmentCategories" [value]="category">{{ category.name }}</mat-option>
                  </mat-select>
                </div>
              </mat-form-field>
            </div>
          </app-content-row>
        </app-wrapper>
        <app-wrapper size="xl">
          <div class="py-4 md:py-6 flex flex-col md:flex-row md:items-center md:justify-between" @enter>
            <div class="pr-4">
              <div class="flex items-center text-lg md:text-xl">
                <div class="font-bold">検索結果</div>
                <div class="text-primary font-bold ml-2">
                  {{ (matchedUniversityCount$ | async) == null ? '-' : (matchedUniversityCount$ | async) }}
                </div>
                <div class="ml-2 mr-2 text-md relative">校</div>
                <div [@inOut]="universityCountSearching$ | async">
                  <mat-spinner [diameter]="24"></mat-spinner>
                </div>
              </div>
              <div class="mt-2 text-sm text-sub leading-[1.5]" *ngIf="!isBToC">
                <div class="flex flex-wrap gap-x-[0.375rem]">
                  <div>検索科目:</div>
                  <div class="flex flex-wrap gap-x-[0.375rem]" [innerHTML]="visibleSubjectNames$ | async"></div>
                </div>
              </div>
            </div>
            <div class="flex mt-4 md:mt-0">
              <button mat-flat-button class="mr-4 bg-gray-50 md:w-36 w-1/2 md:h-12 md:text-base" (click)="resetForms()">
                条件をリセット
              </button>
              <button
                mat-flat-button
                class="mat-primary md:w-36 w-1/2 md:h-12 md:text-base"
                [disabled]="searchButtonDisabled$ | async"
                (click)="showSearchResultViewClick.emit()"
              >
                結果を表示
              </button>
            </div>
          </div>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
  </app-wrapper>
</div>

<div class="search-summary" [attr.data-is-search-summary-displayed]="isSearchSummaryDisplayed">
  <app-content-row>
    <app-card-content [showSeparator]="false">
      <app-content-row>
        <app-wrapper size="fluid">
          <div class="text-gray-500 mb-2">大学名: {{ selectedUniversityNamesForSummary }} / 年度: {{ yearFormControl.value }}</div>
          <div class="flex items-center">
            <div class="flex items-center">
              <div class="font-bold">検索結果</div>
              <div class="text-primary font-bold mx-1">
                {{ (matchedUniversityCount$ | async) == null ? '-' : (matchedUniversityCount$ | async) }}
              </div>
              <div>校</div>
            </div>
            <div class="ml-auto">
              <button mat-flat-button class="bg-gray-50 md:w-36" (click)="onChangeConditions()">
                条件を変更
              </button>
            </div>
          </div>
        </app-wrapper>
      </app-content-row>
    </app-card-content>
  </app-content-row>
</div>
