import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter, take } from 'rxjs';

import { navigate, setBrowserTitle, setTitle } from '../../../actions/core.actions';
import { deleteMembers } from 'src/app/actions/tools.actions';
import { findUsersByEmail, initializeFindUsersByEmail } from 'src/app/actions/user.actions';
import { getUsersByEmail } from 'src/app/selectors/user.selectors';
import { RootState } from '../../../reducers';

import { MailUtil } from 'src/app/utils/mail-util';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { User } from 'src/app/models/user';

interface UserFromCSV {
  recordIndex: number;
  familyName: string;
  firstName: string;
  familyNameKana: string;
  firstNameKana: string;
  email: string;
  isOrganizationAdmin: boolean;
}

interface CSVError {
  recordIndex: number;
  item: string;
  message: string;
  itemValue: string;
}

interface Email {
  index: number;
  email: string;
}

@Component({
  selector: 'app-tools-delete-members',
  templateUrl: './tools-delete-members.component.html',
  styleUrls: ['./tools-delete-members.component.scss']
})
export class ToolsDeleteMembersComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  private LOG_SOURCE = this.constructor.name;
  private title = '所属メンバー一括削除';

  // フラグ
  isCsvUploading = false;
  isReadyToRegister = false;

  // CSVファイル
  fileName: string;
  usersFromCsv: UserFromCSV[] = [];
  numOfCsvUsers = 0;
  numOfOrganizationAdminUsers = 0;
  numOfNonOrganizationAdminUsers = 0;
  emails: Email[] = [];

  // CSVエラーチェック
  csvErrors: CSVError[] = [];
  usersFoundByEmail$: Observable<User[]>;

  // メンバーテーブル
  dataSource: UserFromCSV[] = [];
  displayedColumns: string[] = ['lineNumber', 'familyName', 'firstName', 'familyNameKana', 'firstNameKana', 'mail', 'authority'];
  HEADER_ITEM_NAMES: string[] = ['行番号', '姓', '名', 'せい', 'めい', 'メールアドレス', '権限'];

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUpUsersFoundByEmail();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeFindUsersByEmail());
  }

  goToTools() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveTools() }));
  }

  resetFile() {
    if (this.fileInput) this.fileInput.nativeElement.value = '';
    this.initializeVariables();
  }

  deleteMembers() {
    const users: User[] = this.usersFromCsv.map(user => {
      return { email: user.email };
    });

    this.store.dispatch(deleteMembers({ users }));
  }

  onClick(event) {
    // 同一ファイルを選択しようとした際に onChange が発火しないため空にする
    event.target.value = '';
  }

  onFileUpload(event) {
    this.initializeVariables();
    this.isCsvUploading = true;

    // CSVの取り込み処理
    const file = event.target.files[0];
    this.fileName = file.name;
    const reader = new FileReader();
    // UTF-8として読み込む
    reader.readAsText(file, 'utf-8');
    // ファイルの読み込み処理
    reader.onload = (e: any) => {
      const text = e.target.result as string;
      if (text.includes('�')) {
        // Shift-JISとして読み込み
        this.readAsShiftJIS(file);
      } else {
        // UTF-8として読み込み成功
        this.csvOperation(text);
      }
    };
  }

  private readAsShiftJIS(file: File) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const buffer = event.target.result;
      const decoder = new TextDecoder('shift-jis');
      const text = decoder.decode(buffer);
      this.csvOperation(text);
    };

    reader.readAsArrayBuffer(file);
  }

  private csvOperation(csvData: string) {
    // carriage return または Line feedごとにデータを作成して、配列に格納 (1行ずつのデータに分ける)
    const csvRecords = csvData.split(/\r\n|\n/);

    // CSVの件数が100件以上の場合は、エラー表示、その他の場合は、メールアドレスの重複チェックに進む
    const isNumOfUsersOk = this.numberOfUsersCheck(csvRecords);
    if (isNumOfUsersOk) {
      this.usersFromCsv = this.getUsersFromCSV(csvRecords);
      // Firestore内のメールアドレス重複チェック - Firestore内にアドレスが存在するか検索
      this.emailExistenceCheck();

      // storeより重複しているアドレスの取得
      this.subscribeExistenceEmails();
    } else {
      this.isCsvUploading = false;
    }
  }

  private numberOfUsersCheck(csvRecords: string[]): boolean {
    // csvの行数をチェック - ヘッダ行のみ、または、ユーザーが100人以上の場合はエラーオブジェクトを追加
    this.numOfCsvUsers = csvRecords.length - 1;

    if (this.numOfCsvUsers < 1) {
      const message = 'ユーザーが登録されていません';
      const csvError: CSVError = { recordIndex: length, item: 'csv', message, itemValue: '' };
      this.csvErrors.push(csvError);
      return false;
    }

    if (this.numOfCsvUsers > 100) {
      const message = 'CSVファイルに設定できるユーザーの上限は１００人です';
      const csvError: CSVError = { recordIndex: length, item: 'csv', message, itemValue: '' };
      this.csvErrors.push(csvError);
      return false;
    }
    return true;
  }

  private getUsersFromCSV(csvRecords: string[]): UserFromCSV[] {
    const usersFromCsv: UserFromCSV[] = [];

    for (let index = 1; index < csvRecords.length; index++) {
      const currentRecord = csvRecords[index].split(',');

      const headerLength = this.HEADER_ITEM_NAMES.length - 1;
      if (currentRecord.length === headerLength) {
        // ユーザー1件ずつ、エラーチェックと項目の取得を行い、配列に追加
        const userFromCsv = this.getUserFromCSV(index, currentRecord);
        usersFromCsv.push(userFromCsv);
      } else {
        // 行内に空の項目が存在する場合はエラー
        const message = '項目数が不足しています';
        const recordIndex = index + 1;
        const csvError: CSVError = { recordIndex, item: '', message, itemValue: null };
        this.csvErrors.push(csvError);
      }
    }
    return usersFromCsv;
  }

  private getUserFromCSV(index: number, csvRecord: string[]): UserFromCSV {
    // 行番号の設定
    const recordIndex = index + 1;

    // 苗字のチェック
    const familyName = csvRecord[0];
    this.nameErrorCheck(this.HEADER_ITEM_NAMES[1], index, familyName);

    // 名前のチェック
    const firstName = csvRecord[1];
    this.nameErrorCheck(this.HEADER_ITEM_NAMES[2], index, firstName);

    // 苗字かなのチェック
    const familyNameKana = csvRecord[2];
    this.lengthCheck(this.HEADER_ITEM_NAMES[3], index, familyNameKana, 20);

    // 名前かなのチェック
    const firstNameKana = csvRecord[3];
    this.lengthCheck(this.HEADER_ITEM_NAMES[3], index, familyNameKana, 20);

    // メールアドレスのチェック
    const email = csvRecord[4].trim();
    this.emailErrorCheck(this.HEADER_ITEM_NAMES[5], index, email);

    // 権限のチェック
    const isOrganizationAdmin = csvRecord[5] === '1' ? true : false;
    this.isOrganizationAdminErrorCheck(this.HEADER_ITEM_NAMES[6], index, csvRecord[5]);

    const userFromCsv: UserFromCSV = {
      recordIndex,
      familyName,
      firstName,
      familyNameKana,
      firstNameKana,
      email,
      isOrganizationAdmin
    };

    return userFromCsv;
  }

  private nameErrorCheck(item: string, recordIndex: number, itemValue: string) {
    this.requireCheck(item, recordIndex, itemValue);
    this.lengthCheck(item, recordIndex, itemValue, 20);
  }

  private requireCheck(item: string, recordIndex: number, itemValue: string) {
    if (itemValue.length === 0) {
      const message = `${item}は必須項目です`;
      const csvError: CSVError = { recordIndex: recordIndex + 1, item, message, itemValue };
      this.csvErrors.push(csvError);
    }
  }

  private lengthCheck(item: string, recordIndex: number, itemValue: string, itemLength: number) {
    if (itemValue.length > itemLength) {
      const message = `${item}の文字数は${itemLength}文字以内にしてください`;
      const csvError: CSVError = { recordIndex: recordIndex + 1, item, message, itemValue };
      this.csvErrors.push(csvError);
    }
  }

  private emailErrorCheck(item: string, recordIndex: number, itemValue: string) {
    this.requireCheck(item, recordIndex, itemValue);
    if (itemValue) {
      const isEmailValidated = MailUtil.isValidEmail(itemValue);
      if (!isEmailValidated) {
        const message = 'メールアドレス形式にしてください';
        const csvError: CSVError = { recordIndex: recordIndex + 1, item, message, itemValue };
        this.csvErrors.push(csvError);
      }
      this.lengthCheck(item, recordIndex, itemValue, 256);
      // ファイル内の重複チェック
      this.emailDuplicateCheck(item, recordIndex, itemValue);
    }
  }

  private emailDuplicateCheck(item: string, recordIndex: number, itemValue: string) {
    const emails = this.emails.map(element => {
      return element.email;
    });

    if (emails.includes(itemValue)) {
      const message = 'ファイル内に重複したメールアドレスが存在します';
      const csvError: CSVError = { recordIndex: recordIndex + 1, item, message, itemValue };
      this.csvErrors.push(csvError);
    } else {
      this.emails.push({ index: recordIndex, email: itemValue });
    }
  }

  private emailExistenceCheck() {
    const emails = this.emails.map(element => {
      return element.email;
    });

    // 10件ごとのメールアドレスの配列を、最大10件作成する
    const emailGroups: string[][] = [];
    for (let i = 0; i < emails.length; i += 10) {
      emailGroups.push(emails.slice(i, i + 10));
    }

    this.store.dispatch(initializeFindUsersByEmail());
    this.store.dispatch(findUsersByEmail({ emailGroups }));
  }

  private isOrganizationAdminErrorCheck(item: string, recordIndex: number, itemValue: string) {
    this.requireCheck(item, recordIndex, itemValue);
    if (itemValue && itemValue !== '0' && itemValue !== '1') {
      const message = `${item}は０か１を指定して下さい`;
      const csvError: CSVError = { recordIndex: recordIndex + 1, item, message, itemValue };
      this.csvErrors.push(csvError);
    }
  }

  private subscribeExistenceEmails() {
    this.usersFoundByEmail$.pipe(take(1)).subscribe(users => {
      users.map(user => {
        if (!user) {
          const message = 'Firestore内にメールアドレスが存在しません';
          const recordIndex = this.emails.find(element => element.email === user.email).index;

          const csvError: CSVError = { recordIndex: recordIndex + 1, item: 'email', message, itemValue: user.email };
          this.csvErrors.push(csvError);
        }
      });

      // アップロードアイコンを非表示にする
      this.isCsvUploading = false;

      // エラーがある場合は、エラー表示をし、無い場合は削除メンバーの表示
      if (this.csvErrors.length > 0) {
        // エラーを行番号順に並び替え
        this.csvErrors.sort((a, b) => a.recordIndex - b.recordIndex);
        this.isReadyToRegister = false;
      } else {
        this.displayMembers();
      }
    });
  }

  private displayMembers() {
    // ユーザーを権限ごとにカウントアップ
    this.usersFromCsv.forEach(user => {
      if (user.isOrganizationAdmin === true) {
        this.numOfOrganizationAdminUsers = this.numOfOrganizationAdminUsers + 1;
      } else {
        this.numOfNonOrganizationAdminUsers = this.numOfNonOrganizationAdminUsers + 1;
      }
    });
    this.dataSource = this.usersFromCsv;
    this.isReadyToRegister = true;
  }

  private setUpUsersFoundByEmail() {
    this.usersFoundByEmail$ = this.store.select(getUsersByEmail).pipe(filter(it => it != null));
  }

  private initializeVariables() {
    this.dataSource = [];
    this.usersFromCsv = [];
    this.csvErrors = [];
    this.emails = [];
    this.fileName = '';

    this.isReadyToRegister = false;
    this.numOfCsvUsers = 0;
    this.numOfOrganizationAdminUsers = 0;
    this.numOfNonOrganizationAdminUsers = 0;
  }
}
