<button class="playlist-items-list" (click)="resultClick.emit({problemId, isPremiumMemberOnly})">
  <div class="playlist-detail-content">
    <div class="playlist-detail-content-labels">
      <app-label-subject subjectLabelStyle="{{ subjectLabelStyleName }}" subjectLabelName="{{ subjectName }}"></app-label-subject>
    </div>
    <div class="playlist-detail-content-issue">
      <p *ngIf="year">{{ universityName }} {{ year }}年度 大問 {{ daimonNumber }}</p>
      <p *ngIf="year === ''">{{ universityName }} 大問 {{ daimonNumber }}</p>
    </div>
    <mat-icon *ngIf="!isPremiumMemberOnly" class="icon icon-arrow">keyboard_arrow_right</mat-icon>
    <mat-icon *ngIf="isPremiumMemberOnly" class="icon icon-lock">lock</mat-icon>
  </div>
</button>
