import { createAction, props } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { CommonIdGetCustomTokenRequest } from '../../models/common-id/common-id-get-custom-token-request';
import { CommonIdGetCustomTokenResponse } from '../../models/common-id/common-id-get-custom-token-response';

export const commonIdGetCustomToken = createAction(
  '[CommonIdAuth] Common Id Get Custom Token',
  props<{ request: CommonIdGetCustomTokenRequest }>()
);

export const commonIdGetCustomTokenSuccess = createAction(
  '[CommonIdAuth] Common Id Get Custom Token Success',
  props<{ response: CommonIdGetCustomTokenResponse }>()
);

export const commonIdGetCustomTokenFailure = createAction(
  '[CommonIdAuth] Common Id Get Custom Token Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdGetCustomTokenResult = createAction('[CommonIdAuth] Initialize Get Custom Token Result');
