import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { RootState } from 'src/app/reducers';
import { setTitle } from 'src/app/actions/core.actions';
import { setCommonIdBrowserTitle } from '../../../actions/common-id/common-id-core.actions';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  // private LOG_SOURCE = this.constructor.name;
  private title = 'メンテナンス中';

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
  }
}
