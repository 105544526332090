import { Component, OnInit } from '@angular/core';
import { navigate } from '../../../../actions/core.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { NavigationEnd, Router } from '@angular/router';
import { filter, shareReplay } from 'rxjs/operators';
import * as CommonIdInformationSelectors from '../../../../selectors/common-id/common-id-information.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-bottom-tab-ui',
  templateUrl: './bottom-tab-ui.component.html',
  styleUrls: ['./bottom-tab-ui.component.scss']
})
export class CommonIdBottomTabUiComponent implements OnInit {
  newInformation$: Observable<boolean>;
  currentUrl: string;

  constructor(private store: Store<RootState>, private router: Router) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
    });
    this.newInformation$ = this.store.select(CommonIdInformationSelectors.getNewInformation).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  showSearch() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUniv() }));
  }

  showSearchByCategories() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchByCategories() }));
  }

  showPlaylists() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPlaylists() }));
  }

  showMylist() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistAnswerProblems() }));
  }

  showInformation() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdInformation() }));
  }

  isCurrentSearch(): boolean {
    return this.currentUrl.indexOf('/' + RoutingPathResolver.resolveCommonIdSearchUniv()) !== -1;
  }

  isCurrentSearchByCategories(): boolean {
    return this.currentUrl.indexOf('/' + RoutingPathResolver.resolveCommonIdSearchByCategories()) !== -1;
  }

  isCurrentPlaylists(): boolean {
    return this.currentUrl.indexOf('/' + RoutingPathResolver.resolveCommonIdPlaylists()) !== -1;
  }

  isCurrentMylist(): boolean {
    return this.currentUrl.indexOf('/' + RoutingPathResolver.resolveCommonIdMylist()) !== -1;
  }

  isInformation(): boolean {
    return this.currentUrl.indexOf('/' + RoutingPathResolver.resolveCommonIdInformation()) !== -1;
  }
}
