<app-dialog-basic dialogTitle="この問題の情報" dialogIcon="info" dialogIconOutline="true">
  <p class="information-title">{{ data.title }}</p>
  <table class="information-table">
    <!-- TODO: データ処理 -->
    <tr>
      <th>学部</th>
      <td>{{ data.description }}</td>
    </tr>
    <tr>
      <th>試験日</th>
      <td>{{ data.examDate }}</td>
    </tr>
    <tr>
      <th>入試名</th>
      <td>{{ data.nyushiName }}</td>
    </tr>
    <!--    <tr>-->
    <!--      <th>試験時間</th>-->
    <!--      <td>{{ data.examTime }}</td>-->
    <!--    </tr>-->
    <tr>
      <th>科目</th>
      <td>{{ data.subjectName }}</td>
    </tr>
    <tr>
      <th>難易度</th>
      <td>{{ displayLevel() }} {{ data.levelLabel }}</td>
    </tr>
    <tr>
      <th>分野</th>
      <td>
        <ul class="bunya-list">
          <li *ngFor="let category of data.categories" [innerHTML]="category"></li>
        </ul>
      </td>
    </tr>
  </table>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="close()">閉じる</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
