<div class="root-container">
  <div>
    <mat-form-field class="keyword-form-field" appearance="outline">
      <mat-label>作者・作品名・要旨キーワード検索（複数可）</mat-label>
      <input matInput [formControl]="keywordFormControl" />
    </mat-form-field>
  </div>

  <ng-container *ngFor="let field of fields">
    <mat-checkbox
      color="primary"
      [(indeterminate)]="field.indeterminate"
      [(ngModel)]="field.checked"
      (change)="onChangeField(field, $event.checked)"
      >{{ field.name }}</mat-checkbox
    >
    <div class="unit-container">
      <div class="mat-caption">大問ジャンル</div>
      <div class="checkbox-list unit-genre-list">
        <ng-container *ngFor="let unit of units">
          <div *ngIf="unit.parentFieldId === field.id && unit.id[0] === '1'">
            <mat-checkbox color="primary" [(ngModel)]="unit.checked" (change)="onChangeValues()">{{ unit.name }}</mat-checkbox>
          </div>
        </ng-container>
      </div>
      <div class="mat-caption">小問内容</div>
      <div class="checkbox-list unit-content-list">
        <ng-container *ngFor="let unit of units">
          <div *ngIf="unit.parentFieldId === field.id && unit.id[0] === '2'">
            <mat-checkbox color="primary" [(ngModel)]="unit.checked" (change)="onChangeValues()">{{ unit.name }}</mat-checkbox>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <div class="word-count-container">
    <mat-form-field class="no-padding-form-field" floatLabel="always" appearance="outline">
      <mat-label>素材文の文字数（最小）</mat-label>
      <input matInput placeholder="文字数を入力" [formControl]="minWordCountFormControl" />
      <mat-error *ngIf="minWordCountFormControl.hasError('invalid-value')">数字を半角で入力してください</mat-error>
    </mat-form-field>

    <div class="mat-title">〜</div>

    <mat-form-field class="no-padding-form-field" floatLabel="always" appearance="outline">
      <mat-label>素材文の文字数（最大）</mat-label>
      <input matInput placeholder="文字数を入力" [formControl]="maxWordCountFormControl" />
      <mat-error *ngIf="maxWordCountFormControl.hasError('invalid-value')">数字を半角で入力してください</mat-error>
      <mat-error *ngIf="maxWordCountFormControl.hasError('too-small-value')">最小より大きい値を入力してください</mat-error>
    </mat-form-field>
  </div>
</div>
