import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, planFeatureKey } from '../reducers/plan.reducer';

const selectPlanState = createFeatureSelector<State>(planFeatureKey);

export const getPlan = createSelector(selectPlanState, state => state.plan);
export const getPlans = createSelector(selectPlanState, state => state.plans);
export const getSignedInUserPlans = createSelector(selectPlanState, state => state.signedInUserPlans);
export const getCreatePlanResult = createSelector(selectPlanState, state => state.createPlanResult);
export const getUpdatePlanResult = createSelector(selectPlanState, state => state.updatePlanResult);
export const getDeletePlanResult = createSelector(selectPlanState, state => state.deletePlanResult);
export const getAssignPlanResult = createSelector(selectPlanState, state => state.assignPlanResult);
export const getUnAssignPlanResult = createSelector(selectPlanState, state => state.unAssignPlanResult);
export const getUpdateDisableRenewPlanAlertResult = createSelector(selectPlanState, state => state.updateDisableRenewPlanAlertResult);
