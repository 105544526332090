import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import * as UniversitySearchActions from '../actions/university-search.actions';
import { UniversitySearchService } from '../services/university-search.service';
import { dispatchAppError } from '../actions/core.actions';
import { FirebaseCallableFunctionError } from '../errors/firebase-callable-function-error';

@Injectable()
export class UniversitySearchEffects {
  private LOG_SOURCE = this.constructor.name;

  findUniversityCountByUniversityIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UniversitySearchActions.findUniversityCountByUniversityIds),
      switchMap(action =>
        this.universitySearchService.findUniversityCountByUniversityIds(action.condition, action.logging).pipe(
          map(result => UniversitySearchActions.findUniversityCountByUniversityIdsSuccess({ result })),
          catchError(e =>
            of(
              UniversitySearchActions.findUniversityCountByUniversityIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findUniversityCountByPrefectureIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UniversitySearchActions.findUniversityCountByPrefectureIds),
      switchMap(action =>
        this.universitySearchService.findUniversityCountByPrefectureIds(action.condition, action.logging).pipe(
          map(result => UniversitySearchActions.findUniversityCountByPrefectureIdsSuccess({ result })),
          catchError(e =>
            of(
              UniversitySearchActions.findUniversityCountByPrefectureIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findUniversityPapersByUniversityIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UniversitySearchActions.findUniversityPapersByUniversityIds),
      switchMap(action =>
        this.universitySearchService.findUniversityPapersByUniversityIds(action.condition, action.page).pipe(
          map(universities => UniversitySearchActions.findUniversityPapersByUniversityIdsSuccess({ universities })),
          catchError(e =>
            of(
              UniversitySearchActions.findUniversityPapersByUniversityIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  findUniversityPapersByPrefectureIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UniversitySearchActions.findUniversityPapersByPrefectureIds),
      switchMap(action =>
        this.universitySearchService.findUniversityPapersByPrefectureIds(action.condition, action.page).pipe(
          map(universities => UniversitySearchActions.findUniversityPapersByPrefectureIdsSuccess({ universities })),
          catchError(e =>
            of(
              UniversitySearchActions.findUniversityPapersByPrefectureIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private universitySearchService: UniversitySearchService) {}
}
