import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/reducers';
import { getPreviousProblemId, getNextProblemId } from 'src/app/selectors/search.selectors';
import { setBrowserTitle, navigate } from 'src/app/actions/core.actions';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { ReadablePlaylistProblem } from 'src/app/models/problem';
import { WINDOW_OBJECT } from '../../../utils/injection-tokens';

@Component({
  selector: 'app-problem-detail-back-forward-button',
  templateUrl: './problem-detail-back-forward-button.component.html',
  styleUrls: ['./problem-detail-back-forward-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProblemDetailBackForwardButtonComponent implements OnInit {
  @Input() isFirstProblem: boolean;
  @Input() isLastProblem: boolean;
  @Input() navigation: boolean;
  @Input() parentComponent: string;
  @Input() readableSortType: string;
  @Input() problemId: string;
  @Input() playlistProblems: ReadablePlaylistProblem[];
  @Input() themeId: string;
  @Input() playlistId: string;
  @Output() menuTypeChangeHandler = new EventEmitter();
  @Output() setLoadingReadableProblems = new EventEmitter();

  toolbarTitle = '問題詳細・前へ次へボタン';
  PROBLEM_DETAIL: string;
  PLAYLIST_PROBLEM_DETAIL: string;

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, @Inject(WINDOW_OBJECT) private window: Window) {
    this.PROBLEM_DETAIL = 'problemDetail';
    this.PLAYLIST_PROBLEM_DETAIL = 'playlistProblemDetail';
  }

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.toolbarTitle }));
  }

  showPreviousProblem(parentComponent: string) {
    this.changeMenuType('article');
    if (parentComponent === 'problemDetail') {
      this.store.select(getPreviousProblemId(this.problemId)).subscribe(prevProblemId => {
        const { queryParams } = this.activatedRoute.snapshot;
        const url = RoutingPathResolver.resolveProblemDetail(prevProblemId);
        this.store.dispatch(navigate({ url, extras: { queryParams } }));
      });
    } else {
      this.setLoadingReadableProblems.emit(true);
      const prevProblem = this.playlistProblems[
        this.playlistProblems.findIndex(problem => problem.themeId === this.themeId && problem.id === this.problemId) - 1
      ];
      const url = RoutingPathResolver.resolvePlaylistProblemDetail(this.playlistId, prevProblem.themeId, prevProblem.id);
      this.store.dispatch(navigate({ url }));
    }
  }

  showNextProblem(parentComponent: string) {
    this.changeMenuType('article');
    if (parentComponent === 'problemDetail') {
      this.store.select(getNextProblemId(this.problemId)).subscribe(nextProblemId => {
        const { queryParams } = this.activatedRoute.snapshot;
        const url = RoutingPathResolver.resolveProblemDetail(nextProblemId);
        this.store.dispatch(navigate({ url, extras: { queryParams } }));
      });
    } else {
      this.setLoadingReadableProblems.emit(true);
      // 国語の場合、problemsに同一problemIdの問題が存在するため逆順で検索を行う
      const nextProblemIndex = this.playlistProblems
        .slice()
        .reverse()
        .findIndex(problem => problem.themeId === this.themeId && problem.id === this.problemId);
      const nextProblem = this.playlistProblems[this.playlistProblems.length - nextProblemIndex];
      const url = RoutingPathResolver.resolvePlaylistProblemDetail(this.playlistId, nextProblem.themeId, nextProblem.id);
      this.store.dispatch(navigate({ url }));
    }
  }

  closeWindow() {
    this.window.close();
  }

  changeMenuType(type: string) {
    this.menuTypeChangeHandler.emit(type);
  }
}
