import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-with-listmark',
  templateUrl: './header-with-listmark.component.html',
  styleUrls: ['./header-with-listmark.component.scss']
})
export class HeaderWithListmarkComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
