<button class="flex playlist-items-list items-center" (click)="resultClick.emit({problemId, isPremiumMemberOnly})">
  <!-- ▼ サムネイル SP時のみ縮尺を少し大きく (ratio)-->
  <app-thumb-preview-problem
    class="w-fit self-baseline md:hidden"
    [problemId]="problemId"
    [ratio]="0.3"
    [isPremiumMemberOnly]="isPremiumMemberOnly"
  ></app-thumb-preview-problem>
  <app-thumb-preview-problem
    class="w-fit self-baseline hidden md:block"
    [problemId]="problemId"
    [ratio]="0.5"
    [isPremiumMemberOnly]="isPremiumMemberOnly"
  ></app-thumb-preview-problem>
  <!-- △ サムネイル -->
  <div class="playlist-detail-content ml-3 md:ml-8">
    <div class="playlist-detail-content-labels">
      <app-label-subject subjectLabelStyle="{{ subjectLabelStyleName }}" subjectLabelName="{{ subjectName }}"></app-label-subject>
      <app-label-level-with-stars *ngIf="levelName" [level]="level" levelLabelName="{{ levelName }}"></app-label-level-with-stars>
      <app-label-answered *ngIf="isAnswered"></app-label-answered>
    </div>
    <p class="playlist-detail-content-issue" *ngIf="year">{{ universityName }} {{ year }}年度 大問 {{ daimonNumber }}</p>
    <p class="playlist-detail-content-issue" *ngIf="year === ''">{{ universityName }} 大問 {{ daimonNumber }}</p>
    <p *ngIf="problemSummary" class="playlist-detail-content-subject">{{ problemSummary }}</p>
    <mat-icon *ngIf="!isPremiumMemberOnly" class="icon-arrow">keyboard_arrow_right</mat-icon>
    <mat-icon *ngIf="isPremiumMemberOnly" class="icon-lock">lock</mat-icon>
  </div>
</button>
