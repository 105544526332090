import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { RootState } from '../../../reducers';
import { openWindow, navigate } from 'src/app/actions/core.actions';
import * as CommonIdVisitedPaperActions from 'src/app/actions/common-id/common-id-visited-paper.actions';
import * as CommonIdVisitedPaperSelectors from 'src/app/selectors/common-id/common-id-visited-paper.selectors';

import { UniversitySearchRouterService } from 'src/app/services/university-search-router.service';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { Log } from 'src/app/utils/log';

import { FindUniversityPapersResult } from 'src/app/models/find-university-papers-result';
import { UniversitySearchQueryParamsMapper } from 'src/app/mappers/university-search-query-params-mapper';
import { SearchUnivProblemCondition } from 'src/app/models/search-univ-problem-condition';
import { GoToPaperDetailParams } from '../../../models/search-univ-interfaces';

@Component({
  selector: 'app-search-univ-result',
  templateUrl: './search-univ-result.component.html',
  styleUrls: ['./search-univ-result.component.scss']
})
export class SearchUnivResultComponent implements OnInit, OnDestroy {
  @Input() searchResults: FindUniversityPapersResult[];
  @Input() isBtoC: boolean;

  constructor(private store: Store<RootState>, private universitySearchRouterService: UniversitySearchRouterService) {}

  private LOG_SOURCE = this.constructor.name;

  searchResultsToDisplay: FindUniversityPapersResult[] = [];

  ngOnInit() {
    this.searchResultsToDisplay = this.searchResults;
  }

  ngOnDestroy() {}

  goToUnivDetail(universityId: string) {
    // Pathに大学IDを追加する
    Log.debug(this.LOG_SOURCE, '大学詳細を開きます: ', universityId);
    const url = RoutingPathResolver.resolveSearchUnivDetail(universityId);

    // クエリパラメタに選択された年度を追加する
    const year = this.searchResultsToDisplay[0].year;
    Log.debug(this.LOG_SOURCE, '選択された年度: ', year);
    const extras: NavigationExtras = {
      queryParams: UniversitySearchQueryParamsMapper.encodeUniversitySearchSelectedYear(year)
    };

    this.store.dispatch(navigate({ url, extras }));

    // 大学詳細画面からの遷移フラグを初期化
    this.universitySearchRouterService.initializeIsFromLocationBack();
  }

  goToPaperDetail(event: GoToPaperDetailParams) {
    Log.debug(this.LOG_SOURCE, '問題詳細を開きます: ', event.paperId);

    const condition: SearchUnivProblemCondition = {
      subjectId: event.paperId.slice(6, 8),
      year: Number('20' + event.paperId.slice(0, 2)),
      universityId: 'D' + event.paperId.slice(3, 6)
    };

    const problemId = event.paperId + event.daimonIds[0];
    const url = RoutingPathResolver.resolveProblemDetail(problemId);
    const queryParams = UniversitySearchQueryParamsMapper.encodeSearchUnivProblemCondition(condition);

    this.store.dispatch(openWindow({ url, extras: { queryParams: { ...queryParams, mode: 'paper' } } }));

    // 開いた問題詳細のIDを格納する
    this.addVisitedPaper(event.paperId);
  }

  private addVisitedPaper(paperId: string) {
    // 開いた問題詳細のIDを格納する
    this.store
      .select(CommonIdVisitedPaperSelectors.getCommonIdVisitedPapers)
      .pipe(take(1))
      .subscribe(paperIds => {
        const _paperIds: string[] = paperIds != null ? paperIds.concat() : [];
        if (!_paperIds.includes(paperId)) {
          _paperIds.push(paperId);
          this.store.dispatch(CommonIdVisitedPaperActions.commonIdAddVisitedPaper({ paperIds: _paperIds }));
        }

        // 開いた問題詳細のフラグを更新する
        this.searchResultsToDisplay = this.searchResults.map(university => {
          return {
            ...university,
            departments: university.departments.map(department => {
              return {
                ...department,
                papers: department.papers.map(paper => {
                  return {
                    ...paper,
                    paperIds: paper.paperIds.map(paperIdfromSearchResults => {
                      return {
                        ...paperIdfromSearchResults,
                        isVisited: _paperIds && _paperIds.find(_paperId => _paperId === paperIdfromSearchResults.id) ? true : false
                      };
                    })
                  };
                })
              };
            })
          };
        });
      });
  }
}
