import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CallableFunction } from '../../resources/app-firebase';
import { Observable } from 'rxjs';
import { CommonIdGetStripeCheckoutUrlRequest } from '../../models/common-id/common-id-get-stripe-checkout-url-request';
import { CommonIdGetStripeCheckoutUrlResponse } from '../../models/common-id/common-id-get-stripe-checkout-url-response';

@Injectable({
  providedIn: 'root'
})
export class CommonIdGetStripeCheckoutUrlService {
  constructor(private aff: AngularFireFunctions) {}

  commonIdGetStripeCheckoutUrl({ planId, isLocal }: CommonIdGetStripeCheckoutUrlRequest): Observable<CommonIdGetStripeCheckoutUrlResponse> {
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_GET_STRIPE_CHECKOUT_URL);
    return callable({ planId, isLocal });
  }
}
