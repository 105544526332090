<app-content-row>
  <app-wrapper size="lg">
    <app-content-row>
      <div class="flex items-center gap-x-2">
        <app-button-location-back (backClick)="goBack()">戻る</app-button-location-back>
      </div>
    </app-content-row>
  </app-wrapper>

  <app-wrapper size="lg" isFitWithSmallScreen="true" *ngIf="universityDetails$ | async">
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <div class="flex items-center gap-x-2 md:my-3">
          <app-univ-cat-mark
            univCatType="{{ (universityDetails$ | async).universityTypeName }}"
            univCatPref="{{ (universityDetails$ | async).prefectureName }}"
            univType="{{ (universityDetails$ | async).universityType }}"
          ></app-univ-cat-mark>
          <div role="heading" class="text-xl md:text-2xl ml-1">
            <!-- <a class="text-primary hover:underline">{{ (universityDetails$ | async).universityName }}</a> -->
            {{ (universityDetails$ | async).universityName }}
          </div>
        </div>
        <div class="card-content-head-util" *ngIf="isBToC">
          <div class="pl-2">
            <app-button-toggle-univ-bookmark
              *ngIf="!this.isBookmarked"
              [disabled]="(this.buttonAbled$ | async) === false"
              [isBookmarked]="false"
              [universityId]="universityId"
              [errorUnivId]="errorUnivId$ | async"
              (backClick)="addUniversityBookmarkClick.emit($event)"
            ></app-button-toggle-univ-bookmark>
            <app-button-toggle-univ-bookmark
              *ngIf="this.isBookmarked"
              [disabled]="(this.buttonAbled$ | async) === false"
              [isBookmarked]="true"
              [universityId]="universityId"
              [errorUnivId]="errorUnivId$ | async"
              (backClick)="deleteUniversityBookmarkClick.emit($event)"
            ></app-button-toggle-univ-bookmark>
          </div>
        </div>
      </app-card-content-head>

      <app-wrapper size="fluid">
        <app-content-row>
          <app-header-with-listmark>
            <span class="text-base md:text-xl">年度を選択</span>
          </app-header-with-listmark>
        </app-content-row>
        <app-content-row>
          <mat-form-field class="year-form-field" appearance="outline" floatLabel="always">
            <mat-label>過去問の年度</mat-label>
            <div class="year-form-field-select">
              <mat-select (selectionChange)="onYearsChange()" [formControl]="yearFormControl">
                <mat-option *ngFor="let year of years" [value]="year.value">{{ year.label }}</mat-option>
              </mat-select>
            </div>
          </mat-form-field>
        </app-content-row>
      </app-wrapper>

      <app-wrapper size="fluid">
        <app-content-row>
          <app-header-with-listmark>
            <span class="text-base md:text-xl">{{ this.selectedYear }}年度の過去問</span
            ><app-button-subject-name
              *ngIf="isPremiumIconShown"
              subject="プレミアム会員限定"
              isPremium="true"
              isArrow="true"
              class="text-xs ml-4 align-middle md:align-bottom"
            ></app-button-subject-name>
          </app-header-with-listmark>
        </app-content-row>
        <app-content-row>
          <div class="flex flex-col gap-4">
            <div *ngFor="let department of searchResults">
              <app-accordion-list-item [isExpanded]="true" [openableInPc]="false" size="sm" [questionTitle]="department.deptName">
                <div *ngFor="let paper of department.papers; let last = last">
                  <div class="md:flex items-center">
                    <div class="md:w-1/3 mb-4 md:mb-0">
                      <p class="mb-0 md:mr-4">{{ paper.specialSubject }} {{ paper.nyushiName }} 試験日：{{ paper.examDate }}</p>
                    </div>
                    <div class="md:w-2/3">
                      <div class="grid gap-2 grid-cols-2 md:flex md:flex-wrap md:grid-cols-none">
                        <div *ngFor="let paperId of paper.paperIds">
                          <app-button-subject-name
                            *ngIf="isBToC"
                            subject="{{ paperId.subjectName }}"
                            [isPremium]="paperId.isPremiumIconShown"
                            [isVisited]="paperId.isVisited"
                            (click)="
                              goToPaperDetailClick.emit({
                                paperId: paperId.id,
                                daimonIds: paperId.daimonIds,
                                isPremiumIconShown: paperId.isPremiumIconShown,
                                deptName: department.deptName,
                                specialSubject: paper.specialSubject
                              })
                            "
                          ></app-button-subject-name>
                          <app-button-subject-name
                            *ngIf="!isBToC"
                            subject="{{ paperId.subjectName }}"
                            [isVisited]="paperId.isVisited"
                            (click)="goToPaperDetailClick.emit({ paperId: paperId.id, daimonIds: paperId.daimonIds })"
                          ></app-button-subject-name>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr class="my-4 border-t-2 border-gray-50" *ngIf="!last" />
                </div>
              </app-accordion-list-item>
            </div>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-wrapper>
</app-content-row>
