import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { Log } from 'src/app/utils/log';
import { getDeleteUserResult } from 'src/app/selectors/user.selectors';
import { dispatchInfoMessage, dispatchAppError } from 'src/app/actions/core.actions';
import { deleteUser, initializeDeleteUserState } from 'src/app/actions/user.actions';
import { enter } from '../../../resources/animations';

export interface DeleteAccountDialogData {
  userId: string;
  email: string;
  isSignedInUserAdmin: boolean;
}

@Component({
  selector: 'app-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss'],
  animations: [enter]
})
export class DeleteAccountDialogComponent implements OnInit {
  disabled = false;
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<DeleteAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteAccountDialogData
  ) {}

  ngOnInit() {}

  deleteAccount() {
    if (!this.data || !this.data.userId) {
      Log.warn(this.LOG_SOURCE, `dialog.data または data.userId が設定されていません: `, this.data);
      return;
    }

    this.disableForms();

    Log.debug(this.LOG_SOURCE, `アカウントを削除します`);
    this.store
      .select(getDeleteUserResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeDeleteUserState());
        if (result.success) {
          const message = `${result.deletedUser.email} のアカウント削除が完了しました`;
          this.store.dispatch(dispatchInfoMessage({ message }));
          Log.debug(this.LOG_SOURCE, `アカウント削除完了: `, result.deletedUser);
          this.dialogRef.close(true);
        } else {
          Log.error(this.LOG_SOURCE, `アカウント削除でエラーが発生しました: `, JSON.stringify(result.error));
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error: result.error }));
          this.dialogRef.close(false);
        }

        this.enableForms();
      });
    this.store.dispatch(deleteUser({ user: { id: this.data.userId } }));
    this.store.dispatch(
      dispatchInfoMessage({
        message: `${this.data.email} のアカウントを削除しています…`
      })
    );
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close(false);
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
