import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { WINDOW_OBJECT } from '../../../utils/injection-tokens';
import { MEMBER_CONTACT_URL } from '../../../resources/config';
import { openWindow, setBrowserTitle } from '../../../actions/core.actions';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {
  memberContactUrl: string = MEMBER_CONTACT_URL;
  userManualUrl: string = environment.manualPdfFiles.userManual;
  adminManualUrl: string = environment.manualPdfFiles.adminManual;

  faqs = {
    'faq-1': false,
    'faq-2': false,
    'faq-3': false,
    'faq-4': false,
    'faq-5': false,
    'faq-6': false,
    'faq-7': false,
    'faq-8': false,
    'faq-9': false,
    'faq-10': false,
    'faq-11': false,
    'faq-12': false
  };

  private fragmentSubscription: Subscription;
  private LOG_SOURCE = this.constructor.name;
  private title = 'ヘルプ';

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    this.fragmentSubscription = this.activatedRoute.fragment.subscribe(fragment => {
      if (Object.keys(this.faqs).indexOf(fragment) !== -1) {
        this.faqs[fragment] = true;
        setTimeout(() => this.viewportScroller.scrollToAnchor(fragment), 300);
      }
    });
  }

  ngOnDestroy() {
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
  }

  contactClickHandler() {
    this.store.dispatch(openWindow({ url: this.memberContactUrl }));
  }
}
