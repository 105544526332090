export interface UniversityBaseSearchCondition {
  type: UniversitySearchType;
  year: number;
  departmentCategoryId?: number;
}

export enum UniversitySearchType {
  DETAIL = 'detail',
  UNIV = 'univ',
  PREF = 'pref'
}

export interface UniversitySearchCondition<T> extends UniversityBaseSearchCondition {
  universityCondition?: T;
}

export interface UniversitySearchCountRequest<T> extends UniversitySearchCondition<T> {
  logging: boolean;
}

export interface UniversitySearchRequest<T> extends UniversitySearchCondition<T> {
  page: number;
}

// university ids ------------------------------------------------------
export interface UniversityCondition {
  universityIds: string[];
}

// prefecture id ------------------------------------------------------
export interface PrefectureCondition {
  prefectureIds: string[];
  universityTypeIds?: string[];
}
