import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CommonIdPurchases } from '../../models/common-id/common-id-purchases';
import { Observable } from 'rxjs';
import { Collection } from '../../resources/app-firebase';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonIdPurchasesService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  commonIdFindPurchases(userId: string): Observable<CommonIdPurchases> {
    return this.afs
      .collection<CommonIdPurchases>(Collection.COMMON_ID_PURCHASES, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('userId', '==', userId);
        return query;
      })
      .get()
      .pipe(
        map(snapshot =>
          snapshot.empty
            ? ({} as CommonIdPurchases)
            : snapshot.docs.map(doc => {
                const data = doc.data();
                return data as CommonIdPurchases;
              })[0]
        )
      );
  }
}
