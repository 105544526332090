import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UniversitySearchResultSharedService {
  private bookmarkClick = new BehaviorSubject<boolean | null>(null);
  bookmarkClickEmitted$ = this.bookmarkClick.asObservable();

  private errorUniversityIdEmit = new BehaviorSubject<string | null>(null);
  errorUniversityId$ = this.errorUniversityIdEmit.asObservable();

  emitBookmarkClick(change: boolean) {
    this.bookmarkClick.next(change);
  }

  emitErrorUniversityId(univId: string) {
    this.errorUniversityIdEmit.next(univId);
  }
}
