import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CallableFunction, Collection } from '../../resources/app-firebase';
import { UniversityBookmark } from '../../models/common-id/common-id-bookmark';
import { AddUniversityBookmarkRequest } from '../../models/common-id/common-id-add-bookmark-request';
import { DeleteUniversityBookmarkRequest } from '../../models/common-id/common-id-delete-bookmark-request';
import { AddUniversityBookmarkResponse } from '../../models/common-id/common-id-add-bookmark-response';
import { DeleteUniversityBookmarkResponse } from '../../models/common-id/common-id-delete-bookmark-response';
import { UniversityBookmarkCondition } from 'src/app/models/common-id/common-id-bookmark-conditions';

@Injectable({
  providedIn: 'root'
})
export class CommonIdUniversityBookmarkService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  findUniversityBookmark(userId: string): Observable<UniversityBookmark> {
    return this.afs
      .collection<UniversityBookmark>(Collection.COMMON_ID_UNIVERSITY_BOOKMARK, ref => {
        let query: CollectionReference | Query = ref;
        query = query.where('userId', '==', userId);
        return query;
      })
      .get()
      .pipe(
        map(snapshot =>
          snapshot.empty
            ? ({} as UniversityBookmark)
            : snapshot.docs.map(doc => {
                const bookmarkId: string = doc.id;
                const data = doc.data();
                data.id = bookmarkId;
                return data as UniversityBookmark;
              })[0]
        )
      );
  }

  addUniversityBookmark(condition: UniversityBookmarkCondition): Observable<AddUniversityBookmarkResponse> {
    const req: AddUniversityBookmarkRequest = condition;
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_ADD_UNIVERSITY_BOOKMARK);
    return callable(req);
  }

  deleteUniversityBookmark(condition: UniversityBookmarkCondition): Observable<DeleteUniversityBookmarkResponse> {
    const req: DeleteUniversityBookmarkRequest = condition;
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_DELETE_UNIVERSITY_BOOKMARK);
    return callable(req);
  }
}
