<div class="complete-answered-dialog">
  <app-dialog-basic>
    <div class="text-center">
      <mat-icon class="complete-answered-dialog-icon" fontSet="material-icons-outlined">thumb_up</mat-icon>
      <p class="text-xl font-bold my-6">この問題をすべて解答しました</p>
      <p class="text-sm font-bold mb-4">ひとこと応援メッセージ</p>
    </div>
    <div class="complete-answered-dialog-speach-box">
      {{ randomMessage }}
    </div>
  </app-dialog-basic>
  <app-dialog-basic-footer class="description-premium-dialog-footer">
    <button mat-stroked-button (click)="close()">閉じる</button>
  </app-dialog-basic-footer>
</div>
