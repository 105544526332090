import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';

import { RootState } from '../reducers';
import { getSignedInUser } from '../selectors/auth.selectors';
import { Log } from '../utils/log';
import { navigate } from '../actions/core.actions';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { AuthGuard } from './auth.guard';
import { TRY_ORGANIZATION_ID } from '../resources/config';

@Injectable({
  providedIn: 'root'
})
export class UntryGuard extends AuthGuard {
  constructor(protected store: Store<RootState>) {
    super(store);
  }

  canActivate(): Observable<boolean> {
    this.findSignedInUserIfNeeded();

    return this.store.select(getSignedInUser).pipe(
      filter(it => it != null),
      take(1),
      map(user => {
        let isNotTry = false;
        const authenticated = user !== 'none';

        if (!authenticated) {
          Log.warn(this.constructor.name, `認証されていません`);
          const url = RoutingPathResolver.resolveSignIn();
          this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));
        } else {
          if (user !== 'none' && user.organization === TRY_ORGANIZATION_ID) {
            Log.warn(this.constructor.name, `アクセスできないユーザです`);
            const url = RoutingPathResolver.resolveTop();
            this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));
          } else {
            isNotTry = true;
          }
        }

        return authenticated && isNotTry;
      })
    );
  }
}
