import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import {
  deleteUniversityBookmark,
  initializeDeleteUniversityBookmarkState
} from 'src/app/actions/common-id/common-id-university-bookmark.actions';
import { RootState } from 'src/app/reducers';
import { Log } from 'src/app/utils/log';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { dispatchInfoMessage } from 'src/app/actions/core.actions';
import * as CommonIdUniversityBookmarkSelectors from 'src/app/selectors/common-id/common-id-university-bookmark.selectors';
import { filter, take } from 'rxjs/operators';
import { UniversityBookmarkCondition } from 'src/app/models/common-id/common-id-bookmark-conditions';
import { enter } from 'src/app/resources/animations';

@Component({
  selector: 'app-delete-university-bookmark-dialog',
  templateUrl: './delete-university-bookmark-dialog.component.html',
  styleUrls: ['./delete-university-bookmark-dialog.component.scss'],
  animations: [enter]
})
export class DeleteUniversityBookmarkDialogComponent implements OnInit {
  disabled = false;
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<DeleteUniversityBookmarkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UniversityBookmarkCondition
  ) {}

  ngOnInit(): void {}

  deleteUniversityBookmark() {
    if (!this.data) {
      Log.warn(this.LOG_SOURCE, `dialog.data が設定されていません。`, this.data);
      return;
    }

    this.disableForms();
    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入り大学を解除しています。`
      })
    );

    this.store
      .select(CommonIdUniversityBookmarkSelectors.getDeleteUniversityBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeDeleteUniversityBookmarkState());
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入り大学を解除しました`
            })
          );
          Log.debug(this.LOG_SOURCE, 'delete bookmark', this.data, result);
          this.dialogRef.close(true);
        } else {
          Log.warn(this.LOG_SOURCE, `delete bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
          this.dialogRef.close(false);
        }

        this.enableForms();
      });
    this.store.dispatch(deleteUniversityBookmark({ condition: this.data }));
  }
  cancel() {
    this.disableForms();
    this.dialogRef.close(false);
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
