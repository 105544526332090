import { createReducer, on } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { CommonIdInformationResponse } from 'src/app/models/common-id/common-id-information';

import * as CommonIdInformationActions from '../../actions/common-id/common-id-information.actions';

export const commonIdInformationFeatureKey = 'commonIdInformation';

export interface CommonIdGetInformationsResult {
  success: boolean;
  response: CommonIdInformationResponse[] | null;
  error: AppError | null;
}

export interface State {
  commonIdGetInformationsResult: CommonIdGetInformationsResult | null;
  newInformation: boolean | null;
}

export const initialState: State = {
  commonIdGetInformationsResult: null,
  newInformation: null
};

export const reducer = createReducer(
  initialState,
  on(CommonIdInformationActions.commonIdGetInformationsSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      commonIdGetInformationsResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(CommonIdInformationActions.commonIdGetInformationsFailure, (state, { error }) => {
    const next: State = {
      ...state,
      commonIdGetInformationsResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(CommonIdInformationActions.initializeCommonIdGetInformationsState, state => {
    const next: State = { ...state, commonIdGetInformationsResult: null };
    return next;
  }),
  on(CommonIdInformationActions.commonIdUpdateNewInformation, (state, { newInformation }) => ({ ...state, newInformation }))
);
