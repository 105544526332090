<div class="root-container">
  <app-table-overflow>
    <table mat-table [dataSource]="dataSource" style="min-width: 1200px;">
      <ng-container matColumnDef="sequentialId">
        <th mat-header-cell *matHeaderCellDef class="sequential-id">No.</th>
        <td mat-cell *matCellDef="let element">{{ element.sequentialId }}</td>
      </ng-container>

      <ng-container matColumnDef="bookmark" *ngIf="initializedBookmark$ !== undefined">
        <th mat-header-cell *matHeaderCellDef class="bookmark"></th>
        <td mat-cell *matCellDef="let element" class="font-bold">
          <app-button-toggle-bookmark
            *ngIf="!element.isBookmarked"
            [isBookmarked]="false"
            [disabled]="(initializedBookmark$ | async) === false"
            [canSpinner]="canBookmarkSpiner"
            (backClick)="addBookmarkClick.emit(element.id)"
          ></app-button-toggle-bookmark>
          <app-button-toggle-bookmark
            *ngIf="element.isBookmarked"
            [isBookmarked]="true"
            [disabled]="(initializedBookmark$ | async) === false"
            [canSpinner]="canBookmarkSpiner"
            (backClick)="deleteBookmarkClick.emit(element.id)"
          ></app-button-toggle-bookmark>
        </td>
      </ng-container>

      <ng-container matColumnDef="university">
        <th mat-header-cell *matHeaderCellDef class="university">大学名</th>
        <td mat-cell *matCellDef="let element" class="font-bold">{{ element.university }}</td>
      </ng-container>

      <ng-container matColumnDef="departments">
        <th mat-header-cell *matHeaderCellDef class="departments">学部</th>
        <td mat-cell *matCellDef="let element" class="text-sm">{{ element.departments }}</td>
      </ng-container>

      <ng-container matColumnDef="year">
        <th mat-header-cell *matHeaderCellDef class="year">入試年度</th>
        <td mat-cell *matCellDef="let element" class="text-sm">{{ element.year }}</td>
      </ng-container>

      <ng-container matColumnDef="outline">
        <th mat-header-cell *matHeaderCellDef>問題概要</th>
        <td mat-cell *matCellDef="let element" class="text-sm">
          <div class="outline-value font-bold mb-2">{{ element.outline.contents }}</div>
          <div>
            <ng-container *ngIf="element.outline.descriptions != null">
              <div class="root-container" *ngFor="let description of element.outline.descriptions" class="text-sm">
                <div class="description-container" *ngIf="element.outline.descriptions.length > 1">
                  <div class="label" *ngIf="description.problemOrder != null && description.problemOrder !== ''">
                    {{ description.problemOrder }}
                  </div>
                  <div class="value" *ngIf="description.field != null && description.field !== ''">{{ description.field }}</div>
                </div>
                <div class="description-container" *ngIf="description.genre != null && description.genre !== ''">
                  <div class="label">ジャンル:</div>
                  <div class="value">{{ description.genre }}</div>
                </div>
                <div class="description-container" *ngIf="description.author != null && description.author !== ''">
                  <div class="label">作者:</div>
                  <div class="value" innerHtml="{{ keywordHighlight(description.author) }}">{{ description.author }}</div>
                </div>
                <div class="description-container" *ngIf="description.title != null && description.title !== ''">
                  <div class="label">作品名:</div>
                  <div class="value" innerHtml="{{ keywordHighlight(description.title) }}">{{ description.title }}</div>
                </div>
                <div class="description-container" *ngIf="description.titleComplement != null && description.titleComplement !== ''">
                  <div class="label">作品名補足:</div>
                  <div class="value" innerHtml="{{ keywordHighlight(description.titleComplement) }}">{{ description.titleComplement }}</div>
                </div>
                <div class="description-container" *ngIf="description.characterCount != null && description.characterCount !== ''">
                  <div class="label">素材文の文字数:</div>
                  <div class="value">{{ description.characterCount }}</div>
                </div>
                <div
                  class="description-container"
                  *ngIf="
                    description.overview != null &&
                    description.overview !== '' &&
                    (element.outline.isCommonOverview == null || element.outline.isCommonOverview === false)
                  "
                >
                  <div class="label">要旨:</div>
                  <div class="value" innerHtml="{{ keywordHighlight(description.overview) }}">{{ description.overview }}</div>
                </div>
              </div>
            </ng-container>
            <div class="description-container text-sm" *ngIf="element.outline.isCommonOverview === true">
              <div class="label">共通要旨:</div>
              <div class="value" innerHtml="{{ keywordHighlight(element.outline.descriptions[0].overview) }}">
                {{ element.outline.descriptions[0].overview }}
              </div>
            </div>
            <div class="description-container text-sm" *ngIf="element.comment != null && element.comment !== ''">
              <div class="label">コメント:</div>
              <div class="value">{{ element.comment }}</div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="level">
        <th mat-header-cell *matHeaderCellDef class="level">難易度</th>
        <td mat-cell *matCellDef="let element" class="text-sm">{{ element.level }}</td>
      </ng-container>

      <ng-container matColumnDef="book">
        <th mat-header-cell *matHeaderCellDef class="book">掲載書籍</th>
        <td mat-cell *matCellDef="let element" class="text-sm">{{ element.book ? element.book : '-' }}</td>
      </ng-container>

      <ng-container matColumnDef="page">
        <th mat-header-cell *matHeaderCellDef class="page">ページ</th>
        <td mat-cell *matCellDef="let element" class="text-sm">{{ element.page === 0 ? '-' : element.page }}</td>
      </ng-container>

      <ng-container matColumnDef="problemNumber">
        <th mat-header-cell *matHeaderCellDef class="problem-number text-center">問題</th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <!-- 印刷画面用の view -->
          <ng-container *ngIf="problemButtonDisabled">{{ element.problemNumber }}</ng-container>

          <!-- 検索結果画面用の view -->
          <ng-container *ngIf="readableNationalLanguageProblems != null && !problemButtonDisabled">
            <button
              mat-button
              color="primary"
              *ngIf="element.hasExternalData === true"
              (click)="problemClick.emit({ problemId: element.id, year: element.year })"
            >
              <span class="problem-button-label">{{ element.problemNumber }}</span>
            </button>
            <div *ngIf="element.hasExternalData !== true">{{ element.problemNumber }}</div>
          </ng-container>

          <!-- おすすめ問題セット用の view -->
          <div class="download-button" *ngIf="readableNationalLanguagePlaylistProblems != null && !problemButtonDisabled">
            <button
              mat-button
              color="primary"
              *ngIf="element.hasExternalData"
              (click)="showPlayListProblemDetail(element)"
              [disabled]="element.pdfDownloading === true"
            >
              <span class="pdf-button-label">{{ element.problemNumber }}</span>
            </button>
            <span *ngIf="!element.hasExternalData">{{ element.problemNumber }}</span>
            <div class="progress-24" *ngIf="element.pdfDownloading === true">
              <mat-spinner [diameter]="24"></mat-spinner>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="wordDownload">
        <th mat-header-cell *matHeaderCellDef class="word-download text-center">
          <!-- 検索結果画面用の view -->
          <div>Word</div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <!-- 印刷画面, おすすめ問題セット用の view -->
          <div class="download-button text-center" *ngIf="wordButtonDisabled">
            <app-button-download-word-file
              *ngIf="element.hasWordData === true"
              [disabled]="wordButtonDisabled"
            ></app-button-download-word-file>
            <div *ngIf="element.hasWordData !== true">-</div>
          </div>

          <!-- 検索結果画面用の view -->
          <div class="download-button text-center" *ngIf="!wordButtonDisabled">
            <app-button-download-word-file
              *ngIf="element.hasWordData === true"
              (buttonClick)="openProblemWord(element)"
            ></app-button-download-word-file>
            <div *ngIf="element.hasWordData !== true">-</div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns" [ngClass]="{ 'no-bottom-border': hideLastBottomBorder }"></tr>
    </table>
  </app-table-overflow>
</div>
