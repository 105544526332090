import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/reducers';
import { navigate, setTitle } from '../../../actions/core.actions';
import { Meta } from '@angular/platform-browser';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { META_VIEWPORT_LP } from '../../../resources/config';
import { setCommonIdBrowserTitle } from '../../../actions/common-id/common-id-core.actions';

@Component({
  selector: 'app-top',
  templateUrl: './entrance.component.html',
  styleUrls: ['./entrance.component.scss']
})
export class EntranceComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private title = '大学入試過去問が見つかる';

  constructor(private store: Store<RootState>, private meta: Meta) {}

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_LP);
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
  }

  ngOnDestroy() {}

  gotoBToSLp() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveTop() }));
  }

  gotoBToCLp() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdTop() }));
  }
}
