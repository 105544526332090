import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { navigate, setTitle } from 'src/app/actions/core.actions';
import { RootState } from '../../../../reducers';
import * as CommonIdUniversityBookmarkActions from 'src/app/actions/common-id/common-id-university-bookmark.actions';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import { filter, take } from 'rxjs/operators';
import { UniversityBookmark, UniversityId } from 'src/app/models/common-id/common-id-bookmark';
import * as CommonIdUniversityBookmarkSelectors from 'src/app/selectors/common-id/common-id-university-bookmark.selectors';
import { getStaticCommonData } from 'src/app/selectors/static-data.selectors';
import { StaticCommonData } from 'src/app/models/static-common-data';
import { University } from 'src/app/models/common-data';
import { UniversityBookmarkCondition } from 'src/app/models/common-id/common-id-bookmark-conditions';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { Log } from 'src/app/utils/log';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { DELETE_BOOKMARK_DIALOG_WIDTH, DIALOG_ZERO_PADDING_PANEL_CLASS } from 'src/app/resources/config';
import { DeleteUniversityBookmarkDialogComponent } from '../delete-university-bookmark-dialog/delete-university-bookmark-dialog.component';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { enter } from 'src/app/resources/animations';
import { DataUtil } from '../../../../utils/data-util';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { GAUtil } from '../../../../utils/ga-util';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES, GA_EVENT_LABELS } from '../../../../resources/common-id/ga';

@Component({
  selector: 'app-common-id-mylist-universities',
  templateUrl: './mylist-universities.component.html',
  styleUrls: ['./mylist-universities.component.scss'],
  animations: [enter]
})
export class CommonIdMylistUniversitiesComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'マイリスト - お気に入り大学';
  private initializedUniversityBookmarkSubject = new BehaviorSubject(false);
  initializedUniversityBookmark$ = this.initializedUniversityBookmarkSubject.asObservable();

  unvIds: UniversityId[] = [];
  staticUniversities: University[];

  user: CommonIdUser;

  disabled = true;

  displayedColumns: string[] = ['isActive', 'university', 'school'];

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUpStaticUniversities();
    this.setUpUnvIds();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  menuClickHandler(key) {
    switch (key) {
      case 'mylist-problem':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistProblems() }));
        break;
      case 'mylist-playlist':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistPlaylists() }));
        break;
      case 'mylist-answer-problem':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistAnswerProblems() }));
        break;
    }
  }

  ngOnDestroy() {}

  setUpStaticUniversities() {
    this.store
      .select(getStaticCommonData)
      .pipe(
        filter<StaticCommonData>(it => it != null),
        take(1)
      )
      .subscribe(staticCommonData => {
        this.staticUniversities = staticCommonData.universities;
      });
  }

  setUpUnvIds() {
    this.disabled = true;
    this.store.dispatch(CommonIdUniversityBookmarkActions.initializeFindUniversityBookmarkState());

    this.store
      .select(getCommonIdSignedInUser)
      .pipe(filter<CommonIdUser>(it => it != null && it !== 'none'))
      .pipe(take(1))
      .subscribe(user => {
        this.user = user;
        this.store.dispatch(CommonIdUniversityBookmarkActions.initializeFindUniversityBookmarkState());

        this.store.dispatch(CommonIdUniversityBookmarkActions.findUniversityBookmark({ userId: this.user.id }));
        this.store
          .select(CommonIdUniversityBookmarkSelectors.getUniversityBookmark)
          .pipe(
            filter<UniversityBookmark>(it => it != null),
            take(1)
          )
          .subscribe(result => {
            if (result.universities) {
              const forSort = [...result.universities];
              this.unvIds = DataUtil.sortObjectArrayBySpecificKey(forSort, 'unvId');
            } else {
              this.unvIds = [];
            }
            this.disabled = false;
            this.store.dispatch(CommonIdUniversityBookmarkActions.initializeFindUniversityBookmarkState());
            this.initializedUniversityBookmarkSubject.next(true);
          });
      });
  }

  getUnivName(id: string): string {
    return this.staticUniversities.find(u => u.id === id).name;
  }

  deleteUniversityBookmarkDialog(unvId: string) {
    Log.debug(this.LOG_SOURCE, `次のお気に入り大学を解除します: `, unvId);
    const data: UniversityBookmarkCondition = {
      userId: this.user.id,
      universityId: unvId
    };

    const config: MatDialogConfig = {
      width: DELETE_BOOKMARK_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      data,
      disableClose: true
    };
    this.dialog
      .open(DeleteUniversityBookmarkDialogComponent, config)
      .afterClosed()
      .subscribe(result => {
        if (result) {
          setTimeout(() => this.setUpUnvIds(), 300);
        }
      });
  }

  goDetail(id: string) {
    const url = RoutingPathResolver.resolveCommonIdSearchUnivDetail(id);
    this.store.dispatch(navigate({ url }));
  }

  goSearchUniv() {
    const url = RoutingPathResolver.resolveCommonIdSearchUniv();
    this.store.dispatch(navigate({ url }));
  }

  bannerClick() {
    const eventParams = {
      'event_category': GA_EVENT_CATEGORIES.NYUSHI_PREMIUM_BANNER,
      'event_label': GA_EVENT_LABELS.MYLIST_UNIVERSITIES,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdTop(), extras: { queryParams: { to: 'premium' } } }));
  }
}
