import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, filter, shareReplay, switchMap } from 'rxjs/operators';

import { RootState } from '../../reducers';
import { getCommonIdSignedInUser } from '../../selectors/common-id/common-id-auth.selectors';
import { findCommonIdSignedInUser } from '../../actions/common-id/common-id-auth.actions';
import { Log } from '../../utils/log';
import { navigate, openWindow } from '../../actions/core.actions';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { CommonIdUser } from '../../models/common-id/common-id-user';
import { getCurrentDateTime, initializeGetCurrentDateTime } from '../../actions/current-date-time.actions';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../selectors/current-date-time.selectors';
import { Dates } from '../../utils/dates';
import { COMMON_ID_EXPIRED_DAYS, COOKIE_NAME_SSC_ID } from '../../resources/common-id-config';
import { CommonIdOidcUtil } from '../../utils/common-id/common-id-oidc-util';
import { WINDOW_OBJECT } from '../../utils/injection-tokens';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { getCookie } from 'typescript-cookie';

@Injectable({
  providedIn: 'root'
})
export class CommonIdAuthGuard implements CanActivate {
  user: CommonIdUser;

  constructor(protected store: Store<RootState>, @Inject(WINDOW_OBJECT) private window: Window, private http: HttpClient) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.findCommonIdSignedInUserIfNeeded();
    this.store.dispatch(initializeGetCurrentDateTime());

    return this.store
      .select(getCommonIdSignedInUser)
      .pipe(
        filter(it => it != null),
        take(1),
        switchMap(user => {
          const authenticated = user !== 'none';

          if (!authenticated) {
            return of(null);
          } else {
            if (user !== 'none') {
              this.user = user;
            }

            // 認証情報が取得できなかった場合はリダイレクト
            this.store.dispatch(getCurrentDateTime());
            const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
              filter(it => it != null),
              shareReplay(1)
            );
            return currentDateTime$;
          }
        })
      )
      .pipe(
        take(1),
        switchMap(currentDateTime => {
          const sscId: string = getCookie(COOKIE_NAME_SSC_ID) || '';
          if (currentDateTime === null) {
            Log.warn(this.constructor.name, `認証されていません`);
            localStorage.setItem('gotoUrl', state.url);
            if (!sscId) {
              this.getCookieFromApi(
                (response: any) => {
                  const url = CommonIdOidcUtil.getAuthorizationEndpoint();
                  this.store.dispatch(openWindow({ url, target: '_self' }));
                },
                e => {
                  const url = CommonIdOidcUtil.getAuthorizationEndpoint();
                  this.store.dispatch(openWindow({ url, target: '_self' }));
                }
              );
            } else {
              const url = CommonIdOidcUtil.getAuthorizationEndpoint();
              this.store.dispatch(openWindow({ url, target: '_self' }));
            }

            return of(false);
          } else {
            if (!this.user.lastLoggedInAt) {
              Log.warn(this.constructor.name, `最終ログイン日時が不明なため再ログインが必要です`);
              const url = RoutingPathResolver.resolveCommonIdSignOut();
              this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));

              return of(false);
            }

            const now = Dates.stdDateStringFromIso(currentDateTime.dateTime);
            const expiredDay = Dates.stdDateStringFromIso(Dates.getDaysLaterDay(this.user.lastLoggedInAt, COMMON_ID_EXPIRED_DAYS));
            if (now < expiredDay) {
              return of(true);
            } else {
              Log.warn(this.constructor.name, `最後にログインしてから規定日数が経過しているため再ログインが必要です`);
              localStorage.setItem('gotoUrl', state.url);
              localStorage.setItem('signInExpired', 'true');
              if (!sscId) {
                this.getCookieFromApi(
                  (response: any) => {
                    const url = RoutingPathResolver.resolveCommonIdSignOut();
                    this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));
                  },
                  e => {
                    const url = RoutingPathResolver.resolveCommonIdSignOut();
                    this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));
                  }
                );
              } else {
                const url = RoutingPathResolver.resolveCommonIdSignOut();
                this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));
              }

              return of(false);
            }
          }
        })
      );
  }

  private getCookieFromApi(next, error) {
    this.http
      .post<any>(environment.commonIdConfig.ssc.cookieApiUri, null, {
        withCredentials: true
      })
      .pipe(take(1))
      .subscribe({
        next,
        error,
        complete: () => {
          // This function is intentionally left blank
        }
      });
  }

  protected findCommonIdSignedInUserIfNeeded() {
    this.store
      .select(getCommonIdSignedInUser)
      .pipe(take(1))
      .subscribe(user => {
        if (user == null) this.store.dispatch(findCommonIdSignedInUser());
      });
  }
}
