<mat-form-field class="{{ matFormFieldClass }}" appearance="{{ appearance }}" floatLabel="{{ floatLabel }}">
  <mat-label>{{ matLabel }}</mat-label>
  <mat-chip-list #univChipList>
    <mat-chip *ngFor="let univ of selectedUniversities" [selectable]="true" [removable]="true" (removed)="removeSelectedUniversity(univ)">
      {{ univ.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      [placeholder]="universityNamesPlaceholder"
      #univNameInput
      [formControl]="universityNameFormControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="univChipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (click)="openUniversityNamesAutocomplete()"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="universitySelected($event)">
    <mat-option *ngFor="let univ of filteredUniversities$ | async" [value]="univ">{{ univ.name }}</mat-option>
  </mat-autocomplete>
</mat-form-field>
