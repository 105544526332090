import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, from } from 'rxjs';
import { map, switchMap, tap, shareReplay } from 'rxjs/operators';

import { User } from '../models/user';
import { CallableFunction, Collection } from '../resources/app-firebase';
import { Log } from '../utils/log';
import { FirebaseAuthError } from '../errors/firebase-auth-error';
import { AuthError } from '../errors/auth-error';
import { UserUtil } from '../utils/user-util';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  signedInUserId$: Observable<string>;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {
    this.signedInUserId$ = this.afAuth.authState.pipe(
      map(firebaseUser => (firebaseUser ? firebaseUser.uid : '')),
      shareReplay(1)
    );
  }

  signIn(email: string, password: string): Observable<User> {
    const preSignInPromise = this.afAuth.signInWithEmailAndPassword(email, password).then(cred => cred.user.uid);
    return from(preSignInPromise).pipe(
      switchMap(uid => {
        const callable = this.aff.httpsCallable<User, User>(CallableFunction.REFRESH_TOKEN_USER);
        return callable({ id: uid });
      }),
      switchMap(result => {
        const signInPromise = this.afAuth.signInWithEmailAndPassword(email, password).then(cred => cred.user.uid);
        return from(signInPromise).pipe(
          switchMap(uid => {
            return this.afs
              .collection(Collection.USER)
              .doc(uid)
              .get()
              .pipe(
                map(snapshot => {
                  if (!snapshot.exists) throw AuthError.userNotFound('指定されたユーザーが見つかりませんでした');
                  const user = snapshot.data() as User;
                  if (user.isActive === false) {
                    throw AuthError.userDisabled('指定されたユーザは無効です');
                  } else {
                    Sentry.setUser({ email: user.email, id: user.id, username: user.school + ' ' + user.familyName + '' + user.firstName });
                    return UserUtil.setDefaultValues(user);
                  }
                })
              );
          })
        );
      })
    );
  }

  signOut(): Observable<void> {
    const promise = this.afAuth.signOut();
    return from(promise).pipe(tap(() => Log.debug(this.constructor.name, 'sign out exec')));
  }

  sendPasswordResetEmail(email: string): Observable<void> {
    const promise = this.afAuth.sendPasswordResetEmail(email).catch(e => {
      throw FirebaseAuthError.from(e);
    });
    return from(promise);
  }
}
