<div class="button-more">
  <div class="button-more-inner">
    <ng-container *ngIf="showSpinner === false; else moreSpiner">
      <button class="button" [disabled]="disabled" (click)="toggle()" matRipple>
        <span class="button-text">もっと見る</span>
      </button>
    </ng-container>
    <ng-template #moreSpiner>
      <div class="progress-24">
        <mat-spinner [diameter]="17"></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
