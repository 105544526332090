import { createAction, props } from '@ngrx/store';
import { AppError } from '../../errors/app-error';
import { CommonIdAddPlaylistBookmarkResponse, CommonIdPlaylistProblemIds } from '../../models/common-id/common-id-playlist-bookmark';
import { CommonIdDeletePlaylistBookmarkResponse } from '../../models/common-id/common-id-playlist-bookmark';
import { CommonIdPlaylistBookmark } from '../../models/common-id/common-id-playlist-bookmark';

// findPlaylistBookmarks ------------------------------------------------------------
export const commonIdFindPlaylistBookmark = createAction(
  '[CommonIdPlaylistBookmark] CommonId Find Playlist Bookmark',
  props<{ userId: string }>()
);
export const commonIdFindPlaylistBookmarkSuccess = createAction(
  '[CommonIdPlaylistBookmark] CommonId Find Playlist Bookmark Success',
  props<{ playlistBookmark: CommonIdPlaylistBookmark }>()
);
export const commonIdFindPlaylistBookmarkFailure = createAction('[CommonIdPlaylistBookmark] FiCommonId Findnd Playlist Bookmark Failure');
export const initializeCommonIdFindPlaylistBookmarkState = createAction(
  '[CommonIdPlaylistBookmark] Initialize CommonId Find Playlist Bookmark State'
);

// findPlaylistProblemIdsByPlaylistBookmarkIds ------------------------------------------------------------
export const commonIdFindPlaylistProblemIdsByPlaylistBookmarkIds = createAction(
  '[CommonIdPlaylistBookmark] CommonId Find Playlist Problem Ids Playlist Bookmark Ids'
);
export const commonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsSuccess = createAction(
  '[CommonIdPlaylistBookmark] CommonId Find Playlist Problem Ids Playlist Bookmark Ids Success',
  props<{ response: CommonIdPlaylistProblemIds[] }>()
);
export const commonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsFailure = createAction(
  '[CommonIdPlaylistBookmark] CommonId Find Playlist Problem Ids Playlist Bookmark Ids Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdFindPlaylistProblemIdsByPlaylistBookmarkIdsState = createAction(
  '[CommonIdPlaylistBookmark] Initialize CommonId Find Playlist Problem Ids Playlist Bookmark Ids'
);

// addPlaylistBookmarks ------------------------------------------------------------
export const commonIdAddPlaylistBookmark = createAction(
  '[CommonIdPlaylistBookmark] CommonId Add Playlist Bookmark',
  props<{ userId: string; playlistId: string }>()
);
export const commonIdAddPlaylistBookmarkSuccess = createAction(
  '[CommonIdPlaylistBookmark] CommonId Add Playlist Bookmark Success',
  props<{ response: CommonIdAddPlaylistBookmarkResponse }>()
);
export const commonIdAddPlaylistBookmarkFailure = createAction(
  '[CommonIdPlaylistBookmark] CommonId Add Playlist Bookmark Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdAddPlaylistBookmarkState = createAction(
  '[CommonIdPlaylistBookmark] Initialize CommonId Add Playlist Bookmark State'
);

// deletePlaylistBookmarks ------------------------------------------------------------
export const commonIdDeletePlaylistBookmark = createAction(
  '[CommonIdPlaylistBookmark] CommonId Delete Playlist Bookmark',
  props<{ userId: string; playlistId: string }>()
);
export const commonIdDeletePlaylistBookmarkSuccess = createAction(
  '[CommonIdPlaylistBookmark] CommonId Delete Playlist Bookmark Success',
  props<{ response: CommonIdDeletePlaylistBookmarkResponse }>()
);
export const commonIdDeletePlaylistBookmarkFailure = createAction(
  '[CommonIdPlaylistBookmark] CommonId Delete Playlist Bookmark Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdDeletePlaylistBookmarkState = createAction(
  '[CommonIdPlaylistBookmark] Initialize CommonId Delete Playlist Bookmark State'
);
