import { createAction, props } from '@ngrx/store';

import { CommonIdUser } from '../../models/common-id/common-id-user';
import { AppError } from '../../errors/app-error';
import { CommonIdUpdateUserRequest } from 'src/app/models/common-id/common-id-update-user-request';

export const findCommonIdUser = createAction('[Common ID User] Find Common ID User ', props<{ userId: string }>());
export const findCommonIdUserSuccess = createAction('[Common ID User] Find Common ID User Success', props<{ user: CommonIdUser }>());
export const findCommonIdUserFailure = createAction('[Common ID User] Find Common ID User Failure');
export const initializeFindCommonIdUser = createAction('[Common ID User] Initialize Find Common ID User');

export const findCommonIdUserByEmail = createAction('[Common ID User] Find Common ID User By Email ', props<{ email: string }>());
export const findCommonIdUserByEmailSuccess = createAction(
  '[Common ID User] Find Common ID User By Email Success',
  props<{ user: CommonIdUser }>()
);
export const findCommonIdUserByEmailNotFound = createAction('[Common ID User] Find Common ID User By Email Not Found');
export const findCommonIdUserByEmailFailure = createAction('[Common ID User] Find Common ID User By Email Failure');
export const initializeFindCommonIdUserByEmail = createAction('[Common ID User] Initialize Find Common ID User By Email');

export const findCommonIdUserByCommonId = createAction('[Common ID User] Find Common ID User By Common ID', props<{ commonId: string }>());
export const findCommonIdUserByCommonIdSuccess = createAction(
  '[Common ID User] Find Common ID User By Common ID Success',
  props<{ user: CommonIdUser }>()
);
export const findCommonIdUserByCommonIdNotFound = createAction('[Common ID User] Find Common ID User By Common ID Not Found');
export const findCommonIdUserByCommonIdFailure = createAction('[Common ID User] Find Common ID User By Common ID Failure');
export const initializeFindCommonIdUserByCommonId = createAction('[Common ID User] Initialize Find Common ID User By Common ID');

export const watchCommonIdUsers = createAction('[Common ID User] Watch Common ID Users');
export const watchCommonIdUsersSuccess = createAction('[Common ID User] Watch Common ID Users Success', props<{ users: CommonIdUser[] }>());
export const watchCommonIdUsersFailure = createAction('[Common ID User] Watch Common ID Users Failure');
export const initializeWatchedCommonIdUsers = createAction('[Common ID User] Initialize Common ID Watched Users');

export const updateCommonIdUserIsActive = createAction('[Common ID User] update Common ID User Is Active', props<{ user: CommonIdUser }>());
export const updateCommonIdUserIsActiveSuccess = createAction(
  '[Common ID User] Common ID User Is Active Success',
  props<{ user: CommonIdUser }>()
);
export const updateCommonIdUserIsActiveFailure = createAction(
  '[Common ID User] Common ID User Is Active Failure',
  props<{ error: AppError }>()
);
export const initializeUpdateCommonIdUserIsActiveState = createAction('[Common ID User] Initialize Common ID User Is Active State');

export const updateCommonIdUser = createAction('[Common ID User] Update Common ID User', props<{ request: CommonIdUpdateUserRequest }>());
export const updateCommonIdUserSuccess = createAction('[Common ID User] Update Common ID User Success', props<{ userId: string }>());
export const updateCommonIdUserFailure = createAction('[Common ID User] Update Common ID User Failure', props<{ error: AppError }>());
export const initializeUpdateCommonIdUserState = createAction('[Common ID User] Initialize Update Common ID User State');
