<app-search-univ-detail-base
  *ngIf="isLoadingFinished$ | async"
  [isBToC]="true"
  [universityDetails$]="universityDetails$"
  [searchResults]="searchResults"
  [years]="years"
  [selectedYear]="selectedYear"
  [isPremiumIconShown]="isPremiumIconShown"
  [isBookmarked]="isBookmarked"
  [universityId]="universityId"
  [buttonAbled$]="initializedBookmark$"
  [errorUnivId$]="errorUnivId$"
  (yearsChangeClick)="onYearsChange($event)"
  (goToPaperDetailClick)="goToPaperDetail($event)"
  (addUniversityBookmarkClick)="addUniversityBookmark($event)"
  (deleteUniversityBookmarkClick)="deleteUniversityBookmark($event)"
></app-search-univ-detail-base>

<div *ngIf="(isLoadingFinished$ | async) === false">
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</div>
