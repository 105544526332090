import { SearchProblemsCondition, SearchCondition } from '../models/search-condition';

export class SearchConditionMapper {
  static mapSearchConditionFromProblemsCondition<T>(searchProblemsCondition: SearchProblemsCondition<T>): SearchCondition<T> {
    const source = searchProblemsCondition;
    const searchCondition: SearchCondition<T> = {
      subjectId: source.subjectId,
      startYear: source.startYear,
      endYear: source.endYear
    };
    if (source.hasExternalData != null) searchCondition.hasExternalData = source.hasExternalData;
    if (source.hasWordData != null) searchCondition.hasWordData = source.hasWordData;
    if (source.isThinking != null) searchCondition.isThinking = source.isThinking;
    if (source.levels && source.levels.length !== 0) searchCondition.levels = [...source.levels];
    if (source.universityTypeIds && source.universityTypeIds.length !== 0) {
      searchCondition.universityTypeIds = [...source.universityTypeIds];
    }
    if (source.areaIds && source.areaIds.length !== 0) searchCondition.areaIds = [...source.areaIds];
    if (source.universityIds && source.universityIds.length !== 0) {
      searchCondition.universityIds = [...source.universityIds];
    }
    if (source.departmentCategoryId) searchCondition.departmentCategoryId = source.departmentCategoryId;
    if (source.subjectCondition) searchCondition.subjectCondition = { ...source.subjectCondition };
    return searchCondition;
  }
}
