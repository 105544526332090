import { UAParser } from 'ua-parser-js';

export class CommonIdContactUtil {
  static getGoogleFormUrl(url: string, commonId: string, userAgent: string): string {
    const ua = new UAParser(userAgent).getResult();
    const osName = ua.os.name ? ua.os.name : '-';
    const browserName = ua.browser.name ? ua.browser.name : '-';
    const contactFormUrl = url.replace('<user-id>', commonId).replace('<user-agent>', `${osName}／${browserName}`);
    return contactFormUrl;
  }
}
