import { AppError } from './app-error';
import { AuthErrorInfo, ErrorInfo } from './error-info';

export class AuthError extends AppError {
  constructor(info: ErrorInfo, cause?: string | Error) {
    super(info.code, info.message, cause);
    Object.setPrototypeOf(this, AuthError.prototype);
  }

  static userNotFound(cause: string | Error): AuthError {
    return new AuthError(AuthErrorInfo.USER_NOT_FOUND, cause);
  }

  static userDisabled(cause: string | Error): AuthError {
    return new AuthError(AuthErrorInfo.USER_DISABLED, cause);
  }

  static alreadySignOut(cause: string | Error): AuthError {
    return new AuthError(AuthErrorInfo.ALREADY_SIGN_OUT, cause);
  }
}
