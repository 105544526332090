<app-dialog-basic [dialogTitle]="dialogTitle">
  <p>キャンパス所在地の都道府県で検索できます。</p>
  <form>
    <!-- 氏名 -->
    <app-content-row>
      <div class="flex flex-col gap-4">
        <app-accordion-list-item [isExpanded]="false" [openableInPc]="true" size="sm" [questionTitle]="'北海道・東北'">
          <div class="flex flex-col gap-4">
            <div class="w-full">
              <mat-checkbox color="primary" (change)="onSelectAll('01')" [checked]="hokkaidoTohokuSelectAllChecked"
                >すべてを選択する</mat-checkbox
              >
            </div>
            <div class="w-full flex flex-wrap gap-3 md:gap-5">
              <div *ngFor="let prefecture of hokkaidoTohoku">
                <mat-checkbox
                  color="primary"
                  name="hokkaidoTohoku"
                  [(ngModel)]="prefecture.checked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangePrefecture(prefecture)"
                  >{{ prefecture.name }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </app-accordion-list-item>
        <app-accordion-list-item [isExpanded]="false" [openableInPc]="true" size="sm" [questionTitle]="'関東'">
          <div class="flex flex-col gap-4">
            <div class="w-full">
              <mat-checkbox color="primary" (change)="onSelectAll('02')" [checked]="kantoSelectAllChecked">すべてを選択する</mat-checkbox>
            </div>
            <div class="w-full flex flex-wrap gap-3 md:gap-5">
              <div *ngFor="let prefecture of kanto">
                <mat-checkbox
                  color="primary"
                  name="kanto"
                  [(ngModel)]="prefecture.checked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangePrefecture(prefecture)"
                  >{{ prefecture.name }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </app-accordion-list-item>
        <app-accordion-list-item [isExpanded]="false" [openableInPc]="true" size="sm" [questionTitle]="'甲信越'">
          <div class="flex flex-col gap-4">
            <div class="w-full">
              <mat-checkbox color="primary" (change)="onSelectAll('03')" [checked]="koshinetsuSelectAllChecked"
                >すべてを選択する</mat-checkbox
              >
            </div>
            <div class="w-full flex flex-wrap gap-3 md:gap-5">
              <div *ngFor="let prefecture of koshinetsu">
                <mat-checkbox
                  color="primary"
                  name="koshinetsu"
                  [(ngModel)]="prefecture.checked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangePrefecture(prefecture)"
                  >{{ prefecture.name }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </app-accordion-list-item>
        <app-accordion-list-item [isExpanded]="false" [openableInPc]="true" size="sm" [questionTitle]="'北陸'">
          <div class="flex flex-col gap-4">
            <div class="w-full">
              <mat-checkbox color="primary" (change)="onSelectAll('04')" [checked]="hokurikuSelectAllChecked"
                >すべてを選択する</mat-checkbox
              >
            </div>
            <div class="w-full flex flex-wrap gap-3 md:gap-5">
              <div *ngFor="let prefecture of hokuriku">
                <mat-checkbox
                  color="primary"
                  name="hokuriku"
                  [(ngModel)]="prefecture.checked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangePrefecture(prefecture)"
                  >{{ prefecture.name }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </app-accordion-list-item>
        <app-accordion-list-item [isExpanded]="false" [openableInPc]="true" size="sm" [questionTitle]="'東海'">
          <div class="flex flex-col gap-4">
            <div class="w-full">
              <mat-checkbox color="primary" (change)="onSelectAll('05')" [checked]="tokaiSelectAllChecked">すべてを選択する</mat-checkbox>
            </div>
            <div class="w-full flex flex-wrap gap-3 md:gap-5">
              <div *ngFor="let prefecture of tokai">
                <mat-checkbox
                  color="primary"
                  name="tokai"
                  [(ngModel)]="prefecture.checked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangePrefecture(prefecture)"
                  >{{ prefecture.name }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </app-accordion-list-item>
        <app-accordion-list-item [isExpanded]="false" [openableInPc]="true" size="sm" [questionTitle]="'近畿'">
          <div class="flex flex-col gap-4">
            <div class="w-full">
              <mat-checkbox color="primary" (change)="onSelectAll('06')" [checked]="kinkiSelectAllChecked">すべてを選択する</mat-checkbox>
            </div>
            <div class="w-full flex flex-wrap gap-3 md:gap-5">
              <div *ngFor="let prefecture of kinki">
                <mat-checkbox
                  color="primary"
                  name="kinki"
                  [(ngModel)]="prefecture.checked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangePrefecture(prefecture)"
                  >{{ prefecture.name }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </app-accordion-list-item>
        <app-accordion-list-item [isExpanded]="false" [openableInPc]="true" size="sm" [questionTitle]="'中国'">
          <div class="flex flex-col gap-4">
            <div class="w-full">
              <mat-checkbox color="primary" (change)="onSelectAll('07')" [checked]="chugokuSelectAllChecked">すべてを選択する</mat-checkbox>
            </div>
            <div class="w-full flex flex-wrap gap-3 md:gap-5">
              <div *ngFor="let prefecture of chugoku">
                <mat-checkbox
                  color="primary"
                  name="chugoku"
                  [(ngModel)]="prefecture.checked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangePrefecture(prefecture)"
                  >{{ prefecture.name }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </app-accordion-list-item>
        <app-accordion-list-item [isExpanded]="false" [openableInPc]="true" size="sm" [questionTitle]="'四国'">
          <div class="flex flex-col gap-4">
            <div class="w-full">
              <mat-checkbox color="primary" (change)="onSelectAll('08')" [checked]="shikokuSelectAllChecked">すべてを選択する</mat-checkbox>
            </div>
            <div class="w-full flex flex-wrap gap-3 md:gap-5">
              <div *ngFor="let prefecture of shikoku">
                <mat-checkbox
                  color="primary"
                  name="shikoku"
                  [(ngModel)]="prefecture.checked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangePrefecture(prefecture)"
                  >{{ prefecture.name }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </app-accordion-list-item>
        <app-accordion-list-item [isExpanded]="false" [openableInPc]="true" size="sm" [questionTitle]="'九州・沖縄'">
          <div class="flex flex-col gap-4">
            <div class="w-full">
              <mat-checkbox color="primary" (change)="onSelectAll('09')" [checked]="kyusyuOkinawaSelectAllChecked"
                >すべてを選択する</mat-checkbox
              >
            </div>
            <div class="w-full flex flex-wrap gap-3 md:gap-5">
              <div *ngFor="let prefecture of kyusyuOkinawa">
                <mat-checkbox
                  color="primary"
                  name="kyusyuOkinawa"
                  [(ngModel)]="prefecture.checked"
                  [ngModelOptions]="{ standalone: true }"
                  (change)="onChangePrefecture(prefecture)"
                  >{{ prefecture.name }}</mat-checkbox
                >
              </div>
            </div>
          </div>
        </app-accordion-list-item>
      </div>
    </app-content-row>
  </form>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()">キャンセル</button>
    <button mat-flat-button color="primary" (click)="submit()">決定</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
