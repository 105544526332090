import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';

import { ReadableDataMapper } from 'src/app/mappers/readable-data-mapper';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { GeneralError } from 'src/app/errors/general-error';
import { RootState } from 'src/app/reducers';
import { dispatchAppError } from 'src/app/actions/core.actions';
import { CATEGORY_DELIMITER } from 'src/app/resources/config';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';

interface UnitData {
  id: string;
  unitName: string;
}

interface FieldData {
  id: string;
  fieldName: string;
  allUnitSelected: boolean;
  units: UnitData[];
}

interface SubjectData {
  id: string;
  subjectName: string;
  allFieldSelected: boolean;
  fields: FieldData[];
}

@Component({
  selector: 'app-selected-categories',
  templateUrl: './selected-categories.component.html',
  styleUrls: ['./selected-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedCategoriesComponent implements OnChanges {
  private LOG_SOURCE = this.constructor.name;

  @Input() scienceSearchCondition: ScienceSearchCondition | undefined;
  @Input() staticScienceData: StaticScienceData;
  @Input() historySearchCondition: HistorySearchCondition | undefined;
  @Input() staticHistoryData: StaticHistoryData;
  @Input() shortMode: boolean | undefined;

  subjectDataArray: SubjectData[] = [];

  constructor(private store: Store<RootState>) {}

  ngOnChanges() {
    if (this.shortMode === undefined) this.shortMode = false;
    if (!this.staticScienceData && !this.staticHistoryData) return;
    if (!this.scienceSearchCondition && !this.historySearchCondition) {
      this.subjectDataArray = [];
      return;
    }

    const subjectSourceDataArray =
      this.scienceSearchCondition && this.scienceSearchCondition.subjectIds
        ? this.scienceSearchCondition.subjectIds.map<SubjectData>(subjectId => ({
            id: subjectId,
            subjectName: ReadableDataMapper.getScienceSubjectName(subjectId, this.staticScienceData),
            allFieldSelected: true,
            fields: []
          }))
        : this.historySearchCondition && this.historySearchCondition.subjectIds
        ? this.historySearchCondition.subjectIds.map<SubjectData>(subjectId => ({
            id: subjectId,
            subjectName: ReadableDataMapper.getHistorySubjectName(subjectId, this.staticHistoryData),
            allFieldSelected: true,
            fields: []
          }))
        : [];

    const subjectAndFieldSourceArray =
      this.scienceSearchCondition && this.scienceSearchCondition.subjectAndFieldIds
        ? this.scienceSearchCondition.subjectAndFieldIds
            .reduce<Array<{ subjectId: string; fieldIds: string[] }>>((acc, subjectAndFieldId) => {
              const splitIds = subjectAndFieldId.split(CATEGORY_DELIMITER);
              if (splitIds.length !== 2) {
                const error = GeneralError.unknown(`subjectAndFieldIds が不正な値です: ${subjectAndFieldId}`);
                this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
                throw error;
              }

              const [subjectId, fieldId] = [splitIds[0], splitIds[1]];
              const existData = acc.find(it => it.subjectId === subjectId);
              existData ? existData.fieldIds.push(fieldId) : acc.push({ subjectId, fieldIds: [fieldId] });
              return acc;
            }, [])
            .map<SubjectData>(source => ({
              id: source.subjectId,
              subjectName: ReadableDataMapper.getScienceSubjectName(source.subjectId, this.staticScienceData),
              allFieldSelected: false,
              fields: source.fieldIds.map<FieldData>(fieldId => ({
                id: fieldId,
                fieldName: ReadableDataMapper.getScienceFieldName(fieldId, this.staticScienceData),
                allUnitSelected: true,
                units: []
              }))
            }))
        : this.historySearchCondition && this.historySearchCondition.subjectAndFieldIds
        ? this.historySearchCondition.subjectAndFieldIds
            .reduce<Array<{ subjectId: string; fieldIds: string[] }>>((acc, subjectAndFieldId) => {
              const splitIds = subjectAndFieldId.split(CATEGORY_DELIMITER);
              if (splitIds.length !== 2) {
                const error = GeneralError.unknown(`subjectAndFieldIds が不正な値です: ${subjectAndFieldId}`);
                this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
                throw error;
              }

              const [subjectId, fieldId] = [splitIds[0], splitIds[1]];
              const existData = acc.find(it => it.subjectId === subjectId);
              existData ? existData.fieldIds.push(fieldId) : acc.push({ subjectId, fieldIds: [fieldId] });
              return acc;
            }, [])
            .map<SubjectData>(source => ({
              id: source.subjectId,
              subjectName: ReadableDataMapper.getHistorySubjectName(source.subjectId, this.staticHistoryData),
              allFieldSelected: false,
              fields: source.fieldIds.map<FieldData>(fieldId => ({
                id: fieldId,
                fieldName: ReadableDataMapper.getHistoryFieldName(fieldId, this.staticHistoryData),
                allUnitSelected: true,
                units: []
              }))
            }))
        : [];

    const categorySourceDataArray =
      this.scienceSearchCondition && this.scienceSearchCondition.categories
        ? this.scienceSearchCondition.categories
            .reduce<SubjectData[]>((acc, category) => {
              const splitIds = category.split(CATEGORY_DELIMITER);
              if (splitIds.length !== 3) {
                const error = GeneralError.unknown(`category が不正な値です: ${category}`);
                this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
                throw error;
              }
              const [subjectId, fieldId, unitId] = [splitIds[0], splitIds[1], splitIds[2]];

              const existSubject = acc.find(it => it.id === subjectId);

              if (existSubject) {
                const existField = existSubject.fields.find(it => it.id === fieldId);
                if (existField) {
                  const unit = this.getUnitData(unitId);
                  existField.units.push(unit);
                } else {
                  const unit = this.getUnitData(unitId);
                  const field = this.getFieldData(fieldId, [unit]);
                  existSubject.fields.push(field);
                }
              } else {
                const unit = this.getUnitData(unitId);
                const field = this.getFieldData(fieldId, [unit]);

                acc.push({
                  id: subjectId,
                  allFieldSelected: false,
                  subjectName: ReadableDataMapper.getScienceSubjectName(subjectId, this.staticScienceData),
                  fields: [field]
                });
              }

              return acc;
            }, [])
            .map(subject => {
              const fields = subject.fields.map(field => {
                const sortedUnits = [...field.units].sort((a, b) => (a.id < b.id ? -1 : 1));
                return { ...field, units: sortedUnits };
              });
              return { ...subject, fields };
            })
        : this.historySearchCondition && this.historySearchCondition.categories
        ? this.historySearchCondition.categories
            .reduce<SubjectData[]>((acc, category) => {
              const splitIds = category.split(CATEGORY_DELIMITER);
              if (splitIds.length !== 3) {
                const error = GeneralError.unknown(`category が不正な値です: ${category}`);
                this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error }));
                throw error;
              }
              const [subjectId, fieldId, unitId] = [splitIds[0], splitIds[1], splitIds[2]];

              const existSubject = acc.find(it => it.id === subjectId);

              if (existSubject) {
                const existField = existSubject.fields.find(it => it.id === fieldId);
                if (existField) {
                  const unit = this.getUnitData(unitId);
                  existField.units.push(unit);
                } else {
                  const unit = this.getUnitData(unitId);
                  const field = this.getFieldData(fieldId, [unit]);
                  existSubject.fields.push(field);
                }
              } else {
                const unit = this.getUnitData(unitId);
                const field = this.getFieldData(fieldId, [unit]);

                acc.push({
                  id: subjectId,
                  allFieldSelected: false,
                  subjectName: ReadableDataMapper.getHistorySubjectName(subjectId, this.staticHistoryData),
                  fields: [field]
                });
              }

              return acc;
            }, [])
            .map(subject => {
              const fields = subject.fields.map(field => {
                const sortedUnits = [...field.units].sort((a, b) => (a.id < b.id ? -1 : 1));
                return { ...field, units: sortedUnits };
              });
              return { ...subject, fields };
            })
        : [];

    const fieldSorted = [...subjectAndFieldSourceArray, ...categorySourceDataArray]
      .reduce<SubjectData[]>((acc, subject) => {
        const existSubject = acc.find(it => it.id === subject.id);
        existSubject ? existSubject.fields.push(...subject.fields) : acc.push(subject);
        return acc;
      }, [])
      .map(subject => {
        const sortedFields = [...subject.fields].sort((a, b) => (a.id < b.id ? -1 : 1));
        return { ...subject, fields: sortedFields };
      });

    this.subjectDataArray = [...subjectSourceDataArray, ...fieldSorted].sort((a, b) => (a.id < b.id ? -1 : 1));
  }

  private getUnitData(unitId: string): UnitData {
    return {
      id: unitId,
      unitName: this.staticScienceData
        ? ReadableDataMapper.getScienceUnitName(unitId, this.staticScienceData)
        : ReadableDataMapper.getHistoryUnitName(unitId, this.staticHistoryData)
    };
  }

  private getFieldData(fieldId: string, units: UnitData[]): FieldData {
    return {
      id: fieldId,
      fieldName: this.staticScienceData
        ? ReadableDataMapper.getScienceFieldName(fieldId, this.staticScienceData)
        : ReadableDataMapper.getHistoryFieldName(fieldId, this.staticHistoryData),
      allUnitSelected: false,
      units: [...units]
    };
  }

  isSubjectDataType(): boolean {
    return this.staticHistoryData != null;
  }
}
