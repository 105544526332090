import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-label-subject',
  templateUrl: './label-subject.component.html',
  styleUrls: ['./label-subject.component.scss']
})
export class LabelSubjectComponent implements OnInit {
  @Input() subjectLabelStyle: string;
  @Input() subjectLabelName: string;
  subject: string;

  constructor() {}
  ngOnInit() {}
}
