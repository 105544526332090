<article>
  <app-wrapper size="xl">
    <div *ngIf="playlist$ | async as playlist">
      <app-content-row>
        <app-button-location-back (backClick)="backToPreviousView()">一覧に戻る</app-button-location-back>
      </app-content-row>
    </div>
  </app-wrapper>
  <app-wrapper size="xl" isFitWithSmallScreen="true">
    <div>
      <div @enter *ngIf="(loaded$ | async) === true">
        <div *ngIf="playlist$ | async as playlist">
          <app-content-row>
            <app-card-content [showSeparator]="false">
              <app-card-content-head>
                <mat-icon class="card-content-head-icon" aria-hidden="true">import_contacts</mat-icon>
                {{ playlist.name }} ({{ playlist.problemCount }})
              </app-card-content-head>
              <app-wrapper size="fluid">
                <app-content-row>
                  <div class="text-md mb-4">
                    {{ playlist.description || '' }}
                  </div>
                  <div class="text-right text-md text-sub mt-4">
                    <div class="inline-block ml-4">公開日: {{ playlist.publishedDate || '-' }}</div>
                    <div class="inline-block ml-4">更新日: {{ playlist.contentUpdatedDate || '-' }}</div>
                    <div class="inline-block ml-4">年度: {{ playlist.yearRange || '-' }}</div>
                    <div class="inline-block ml-4">選定者: {{ playlist.creator || '-' }}</div>
                  </div>
                </app-content-row>
              </app-wrapper>
            </app-card-content>
          </app-content-row>
        </div>

        <ng-container *ngIf="englishPlaylistThemes$ | async as englishPlaylistThemes">
          <div class="playlist-items" *ngFor="let theme of englishPlaylistThemes">
            <app-content-row>
              <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                    <mat-panel-title>
                      <div class="panel-header">
                        <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                        <span class="spacer"></span>
                        <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                          <button
                            mat-button
                            color="primary"
                            (click)="openThemePdf($event, theme)"
                            [disabled]="theme.pdfDownloading === true"
                          >
                            <span class="button-label">まとめて閲覧</span>
                            <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }"
                              >picture_as_pdf</mat-icon
                            >
                          </button>
                          <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                            <mat-spinner [diameter]="24"></mat-spinner>
                          </div>
                        </div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="-mx-6 md:mx-0">
                    <app-english-problems
                      [readableEnglishPlaylistProblems]="theme.problems"
                      [hideLastBottomBorder]="true"
                      [playListId]="theme.id.slice(0, 4)"
                      [themeId]="theme.id"
                      [isTrial]="this.isTrial"
                    ></app-english-problems>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </app-content-row>
          </div>
        </ng-container>

        <ng-container *ngIf="mathPlaylistThemes$ | async as mathPlaylistThemes">
          <div class="playlist-items" *ngFor="let theme of mathPlaylistThemes">
            <app-content-row>
              <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                    <div class="panel-header">
                      <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                      <span class="spacer"></span>
                      <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                        <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                          <span class="button-label">まとめて閲覧</span>
                          <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                        </button>
                        <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                          <mat-spinner [diameter]="24"></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="-mx-6 md:mx-0">
                    <app-science-problems
                      [readableSciencePlaylistProblems]="theme.problems"
                      [hideLastBottomBorder]="true"
                      [playListId]="theme.id.slice(0, 4)"
                      [themeId]="theme.id"
                      [isTrial]="this.isTrial"
                    ></app-science-problems>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </app-content-row>
          </div>
        </ng-container>

        <ng-container *ngIf="nationalLanguagePlaylistThemes$ | async as nationalLanguagePlaylistThemes">
          <div class="playlist-items" *ngFor="let theme of nationalLanguagePlaylistThemes">
            <app-content-row>
              <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                    <div class="panel-header">
                      <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                      <span class="spacer"></span>
                      <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                        <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                          <span class="button-label">まとめて閲覧</span>
                          <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                        </button>
                        <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                          <mat-spinner [diameter]="24"></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="-mx-6 md:mx-0">
                    <app-national-language-problems
                      [readableNationalLanguagePlaylistProblems]="theme.problems"
                      [hideLastBottomBorder]="true"
                      [playListId]="theme.id.slice(0, 4)"
                      [themeId]="theme.id"
                      [isTrial]="this.isTrial"
                    ></app-national-language-problems>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </app-content-row>
          </div>
        </ng-container>

        <ng-container *ngIf="physicsPlaylistThemes$ | async as physicsPlaylistThemes">
          <div class="playlist-items" *ngFor="let theme of physicsPlaylistThemes">
            <app-content-row>
              <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                    <div class="panel-header">
                      <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                      <span class="spacer"></span>
                      <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                        <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                          <span class="button-label">まとめて閲覧</span>
                          <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                        </button>
                        <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                          <mat-spinner [diameter]="24"></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>

                  <div class="-mx-6 md:mx-0">
                    <app-science-problems
                      [readableSciencePlaylistProblems]="theme.problems"
                      [hideLastBottomBorder]="true"
                      [playListId]="theme.id.slice(0, 4)"
                      [themeId]="theme.id"
                      [isTrial]="this.isTrial"
                    ></app-science-problems>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </app-content-row>
          </div>
        </ng-container>

        <ng-container *ngIf="chemistryPlaylistThemes$ | async as chemistryPlaylistThemes">
          <div class="playlist-items" *ngFor="let theme of chemistryPlaylistThemes">
            <app-content-row>
              <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                    <div class="panel-header">
                      <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                      <span class="spacer"></span>
                      <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                        <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                          <span class="button-label">まとめて閲覧</span>
                          <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                        </button>
                        <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                          <mat-spinner [diameter]="24"></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>

                  <div class="-mx-6 md:mx-0">
                    <app-science-problems
                      [readableSciencePlaylistProblems]="theme.problems"
                      [hideLastBottomBorder]="true"
                      [playListId]="theme.id.slice(0, 4)"
                      [themeId]="theme.id"
                      [isTrial]="this.isTrial"
                    ></app-science-problems>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </app-content-row>
          </div>
        </ng-container>

        <ng-container *ngIf="biologyPlaylistThemes$ | async as biologyPlaylistThemes">
          <div class="playlist-items" *ngFor="let theme of biologyPlaylistThemes">
            <app-content-row>
              <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                    <div class="panel-header">
                      <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                      <span class="spacer"></span>
                      <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                        <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                          <span class="button-label">まとめて閲覧</span>
                          <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                        </button>
                        <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                          <mat-spinner [diameter]="24"></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>

                  <div class="-mx-6 md:mx-0">
                    <app-science-problems
                      [readableSciencePlaylistProblems]="theme.problems"
                      [hideLastBottomBorder]="true"
                      [playListId]="theme.id.slice(0, 4)"
                      [themeId]="theme.id"
                      [isTrial]="this.isTrial"
                    ></app-science-problems>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </app-content-row>
          </div>
        </ng-container>

        <ng-container *ngIf="japaneseHistoryPlaylistThemes$ | async as japaneseHistoryPlaylistThemes">
          <div class="playlist-items" *ngFor="let theme of japaneseHistoryPlaylistThemes">
            <app-content-row>
              <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                    <div class="panel-header">
                      <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                      <span class="spacer"></span>
                      <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                        <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                          <span class="button-label">まとめて閲覧</span>
                          <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                        </button>
                        <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                          <mat-spinner [diameter]="24"></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="-mx-6 md:mx-0">
                    <app-history-problems
                      [readableHistoryPlaylistProblems]="theme.problems"
                      [hideLastBottomBorder]="true"
                      [playListId]="theme.id.slice(0, 4)"
                      [themeId]="theme.id"
                      [isTrial]="this.isTrial"
                    ></app-history-problems>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </app-content-row>
          </div>
        </ng-container>

        <ng-container *ngIf="worldHistoryPlaylistThemes$ | async as worldHistoryPlaylistThemes">
          <div class="playlist-items" *ngFor="let theme of worldHistoryPlaylistThemes">
            <app-content-row>
              <mat-accordion>
                <mat-expansion-panel [expanded]="true">
                  <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                    <div class="panel-header">
                      <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                      <span class="spacer"></span>
                      <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                        <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                          <span class="button-label">まとめて閲覧</span>
                          <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                        </button>
                        <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                          <mat-spinner [diameter]="24"></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="-mx-6 md:mx-0">
                    <app-history-problems
                      [readableHistoryPlaylistProblems]="theme.problems"
                      [hideLastBottomBorder]="true"
                      [playListId]="theme.id.slice(0, 4)"
                      [themeId]="theme.id"
                      [isTrial]="this.isTrial"
                    ></app-history-problems>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </app-content-row>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="geographyPlaylistThemes$ | async as geographyPlaylistThemes">
        <div class="playlist-items" *ngFor="let theme of geographyPlaylistThemes">
          <app-content-row>
            <mat-accordion>
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                  <div class="panel-header">
                    <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                    <span class="spacer"></span>
                    <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                      <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                        <span class="button-label">まとめて閲覧</span>
                        <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                      </button>
                      <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                        <mat-spinner [diameter]="24"></mat-spinner>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>

                <div class="-mx-6 md:mx-0">
                  <app-science-problems
                    [readableSciencePlaylistProblems]="theme.problems"
                    [hideLastBottomBorder]="true"
                    [playListId]="theme.id.slice(0, 4)"
                    [themeId]="theme.id"
                    [isTrial]="this.isTrial"
                  ></app-science-problems>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </app-content-row>
        </div>
      </ng-container>

      <ng-container *ngIf="politicalEconomyPlaylistThemes$ | async as politicalEconomyPlaylistThemes">
        <div class="playlist-items" *ngFor="let theme of politicalEconomyPlaylistThemes">
          <app-content-row>
            <mat-accordion>
              <mat-expansion-panel [expanded]="true">
                <mat-expansion-panel-header [collapsedHeight]="'80px'" [expandedHeight]="'80px'">
                  <div class="panel-header">
                    <div class="playlist-items-title">{{ theme.order }}. {{ theme.name }} ({{ theme.problemCount }})</div>
                    <span class="spacer"></span>
                    <div class="download-button" *ngIf="!this.isTrial && (playlist$ | async).enablePdfDownload">
                      <button mat-button color="primary" (click)="openThemePdf($event, theme)" [disabled]="theme.pdfDownloading === true">
                        <span class="button-label">まとめて閲覧</span>
                        <mat-icon color="primary" [ngClass]="{ 'icon-disabled': theme.pdfDownloading === true }">picture_as_pdf</mat-icon>
                      </button>
                      <div class="progress-24" *ngIf="theme.pdfDownloading === true">
                        <mat-spinner [diameter]="24"></mat-spinner>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>

                <div class="-mx-6 md:mx-0">
                  <app-science-problems
                    [readableSciencePlaylistProblems]="theme.problems"
                    [hideLastBottomBorder]="true"
                    [playListId]="theme.id.slice(0, 4)"
                    [themeId]="theme.id"
                    [isTrial]="this.isTrial"
                  ></app-science-problems>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </app-content-row>
        </div>
      </ng-container>

      <div class="progress-48" *ngIf="(loaded$ | async) === false">
        <mat-spinner [diameter]="48"></mat-spinner>
      </div>
    </div>
  </app-wrapper>
</article>
