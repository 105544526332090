import { Component, ViewChildren, QueryList, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { navigate, setTitle } from '../../../../actions/core.actions';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { Dates } from '../../../../utils/dates';
import { enter } from '../../../../resources/animations';

import * as CommonIdInformationSelectors from 'src/app/selectors/common-id/common-id-information.selectors';
import * as CommonIdInformationActions from 'src/app/actions/common-id/common-id-information.actions';
import { CommonIdInformation, CommonIdInformationResponse } from 'src/app/models/common-id/common-id-information';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';

@Component({
  selector: 'app-common-id-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  animations: [enter]
})
export class CommonIdInformationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('informations')
  informations: QueryList<any>;

  informations$: Observable<CommonIdInformation[]>;

  private title = 'お知らせ一覧';
  private lastAccessedInformation;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUp();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    window.scrollTo(0, 0);
    // ページ遷移する際に state の newInformation と localStorage の最終アクセス日時を更新
    this.store.dispatch(CommonIdInformationActions.commonIdUpdateNewInformation({ newInformation: false }));
    localStorage.setItem('lastAccessedInformation', Dates.now());
  }

  gotoDetail(informationId) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdInformationDetail(informationId) }));
  }

  private setUp() {
    this.informations$ = this.store.select(CommonIdInformationSelectors.getCommonIdInformations).pipe(
      filter(it => it != null),
      take(1),
      map(result => result.response.map(info => this.getInformation(info)))
    );
    this.lastAccessedInformation = localStorage.getItem('lastAccessedInformation');
  }

  private getInformation(information: CommonIdInformationResponse): CommonIdInformation {
    const date = Dates.simple4YmdStringFromIso(information.publishedAt);
    const isNew =
      this.lastAccessedInformation !== null ? Dates.jstDateStringFromIso(information.publishedAt) > this.lastAccessedInformation : true;
    return {
      id: information.id,
      isNew,
      categoryName: information.categoryName,
      date,
      title: information.title,
      body: information.content,
      importantFlag: information.importantFlag,
      linkLabel: information.linkLabel,
      linkUrl: information.linkUrl
    } as CommonIdInformation;
  }
}
