import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, forkJoin, fromEvent, of, retry } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { navigate } from '../../../actions/core.actions';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { ThemeForDisplay } from '../../../models/playlist';
import {
  ExamPaper,
  ReadableEnglishPlaylistProblem,
  ReadableEnglishProblem,
  ReadableHistoryPlaylistProblem,
  ReadableHistoryProblem,
  ReadableNationalLanguagePlaylistProblem,
  ReadableNationalLanguageProblem,
  ReadableProblem,
  ReadableSciencePlaylistProblem,
  ReadableScienceProblem
} from '../../../models/problem';
import { RootState } from '../../../reducers';
import { GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES, GA_EVENT_LABELS } from '../../../resources/common-id/ga';
import { SUBJECT_NAMES, SubjectId } from '../../../resources/config';
import { ScrollDirectionService } from '../../../services/scroll-direction.service';
import { GAUtil } from '../../../utils/ga-util';
import { Log } from '../../../utils/log';
import { ProblemImageUtil } from '../../../utils/problem-image-util';
import { ButtonTogglePaperBookmarkComponent } from '../button-toggle-paper-bookmark/button-toggle-paper-bookmark.component';
import { ButtonToggleSavedProblemComponent } from '../button-toggle-saved-problem/button-toggle-saved-problem.component';
import { Router } from '@angular/router';

interface ProblemImage {
  url: string;
  width: number;
  height: number;
}

type ProblemId = string;

@Component({
  selector: 'app-problem-detail-frame',
  templateUrl: './problem-detail-frame.component.html',
  styleUrls: ['./problem-detail-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProblemDetailFrameComponent implements OnInit, OnChanges {
  @Input() readableEnglishProblems: ReadableEnglishProblem[];
  @Input() readableScienceProblems: ReadableScienceProblem[];
  @Input() readableNationalLanguageProblems: ReadableNationalLanguageProblem[];
  @Input() readableJapaneseHistoryProblems: ReadableHistoryProblem[];
  @Input() readableWorldHistoryProblems: ReadableHistoryProblem[];
  @Input() englishPlaylistTheme: ThemeForDisplay<ReadableEnglishPlaylistProblem>;
  @Input() sciencePlaylistTheme: ThemeForDisplay<ReadableSciencePlaylistProblem>;
  @Input() nationalLanguagePlaylistTheme: ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>;
  @Input() japaneseHistoryPlaylistTheme: ThemeForDisplay<ReadableHistoryPlaylistProblem>;
  @Input() worldHistoryPlaylistTheme: ThemeForDisplay<ReadableHistoryPlaylistProblem>;
  @Input() paperMode: boolean;
  @Input() tryMode: boolean;
  @Input() trialMode: boolean;
  @Input() canDisplay = true;
  @Input() subjectId: string;
  @Input() hidePaperProblemBtn: boolean;
  @Input() showPaperBookmarkBtn: boolean;
  @Input() showComment: boolean;
  @Input() showSaveAnsweredBtn: boolean;
  @Input() disabledSaveAnsweredBtn: boolean;
  @Input() canBookmarkSpinner = false;
  @Input() isPaperBookmarked = false;
  @Input() isAnswered = false;
  @Input() tryDaimonId: string;

  @Output() menuTypeChangeHandler = new EventEmitter();
  @Output() paperClickHandler = new EventEmitter();
  @Output() printClickHandler = new EventEmitter();

  @Output() addPaperBookmarkClick = new EventEmitter<ProblemId>();
  @Output() deletePaperBookmarkClick = new EventEmitter<ProblemId>();
  @Output() saveAnsweredClick = new EventEmitter<ProblemId>();
  @Output() openAnsweredDialogClick = new EventEmitter();

  playlistTheme:
    | ThemeForDisplay<ReadableEnglishPlaylistProblem>
    | ThemeForDisplay<ReadableSciencePlaylistProblem>
    | ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>
    | ThemeForDisplay<ReadableHistoryPlaylistProblem>;
  readablePlaylistProblem:
    | ReadableEnglishPlaylistProblem
    | ReadableSciencePlaylistProblem
    | ReadableNationalLanguagePlaylistProblem
    | ReadableHistoryPlaylistProblem;
  loaded$: Observable<boolean> = of(false);
  status$: Observable<boolean> = of(true);
  problemId: string;
  menuType: 'article' | 'spellcheck' = 'article';
  layoutType: number;
  readableProblem: ReadableProblem;
  subjectName: string;
  examDate: string;
  nyushiName: string;
  examTime: string;
  specialInfoData: string[];
  fImage: ProblemImage;
  problemImages: ProblemImage[];
  private LOG_SOURCE = this.constructor.name;
  private daimonId: string | null;

  scrollDirectionIsUp = this.scrollDirectionService.scrollDirectionIsUp;

  @ViewChild('saveAnsweredBtn') buttonToggleAnsweredProblemComponent: ButtonToggleSavedProblemComponent;
  @ViewChild('savePaperBtn') buttonTogglePaperBookmarkComponent: ButtonTogglePaperBookmarkComponent;

  constructor(
    private store: Store<RootState>,
    private router: Router,
    private scrollDirectionService: ScrollDirectionService,
    private changeDetectorRefs: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes): void {
    // TODO あとで解くの登録/解除を実行した場合に isPaperBookmarked が更新され ngOnChanges が呼び出されてしまう
    // TODO 解答済みの登録/解除を実行した場合に isAnswered が更新され ngOnChanges が呼び出されてしまう
    if (
      (changes.isPaperBookmarked !== undefined && Object.keys(changes).length === 1) ||
      (changes.isAnswered !== undefined && Object.keys(changes).length === 1)
    ) {
      return;
    }

    this.setUpReadableProblemBySubjectId();
    // 問題詳細画面でログアウトを実行した際にエラーが発生する場合があるため
    if (!this.readableProblem) return null;

    this.setUpPlaylistThemeBySubjectId();

    this.menuType = 'article';
    this.subjectName = SUBJECT_NAMES[this.subjectId];
    if (this.tryDaimonId) {
      this.daimonId = this.tryDaimonId;
    } else if (this.trialMode) {
      // 体験版プランで大問単位表示の際は１つ目の大問を固定で表示する
      this.daimonId = !this.paperMode ? '01' : null;
    } else {
      this.daimonId = !this.paperMode ? this.problemId.slice(-2) : null;
    }
    const fImageUrl: string = environment.cloudfrontHost + ProblemImageUtil.getPath(this.readableProblem.fImage, this.trialMode);
    const examPaper: ExamPaper = this.readableProblem.examPaper;
    this.examDate = examPaper && examPaper.examDate ? examPaper.examDate : '-';
    this.nyushiName = examPaper && examPaper.nyushiName ? examPaper.nyushiName : '-';
    this.examTime = examPaper && examPaper.examTime ? examPaper.examTime : '-';
    this.specialInfoData = [
      examPaper && examPaper.bikou ? `〔備考〕${examPaper.bikou}` : '',
      examPaper && examPaper.nyushiKamoku ? `〔入試科目〕${examPaper.nyushiKamoku}` : '',
      examPaper && examPaper.chuui ? `〔注意〕${examPaper.chuui}` : ''
    ].filter(Boolean);
    // 体験版ではf画像を使用ない
    if (!this.trialMode && this.paperMode && examPaper.chkFlg === '1' && this.readableProblem.fImage !== '') {
      this.getImage(fImageUrl)
        .pipe(retry(3))
        .pipe(
          take(1),
          catchError(error => {
            Log.debug(this.LOG_SOURCE, `load failed:`, error);
            return of(null);
          })
        )
        .subscribe((image: ImageData) => {
          const isRetina = fImageUrl.includes('@2x');
          this.fImage = {
            width: isRetina ? image.width / 2 : image.width,
            height: isRetina ? image.height / 2 : image.height,
            url: fImageUrl
          };

          this.setUp();
        });
    } else {
      this.setUp();
    }
  }

  resetToggleAnsweredProblemBtn() {
    this.buttonToggleAnsweredProblemComponent.reset();
    this.changeDetectorRefs.detectChanges();
  }

  resetTogglePaperBookmarkBtn() {
    this.buttonTogglePaperBookmarkComponent.reset();
    this.changeDetectorRefs.detectChanges();
  }

  menuClickHandler(key) {
    this.menuType = key;
    this.menuTypeChangeHandler.emit(key);
    this.setUp();
  }

  calcImageLayout(layoutType: number, images: ProblemImage[]): Array<ProblemImage[]> {
    const layoutImages: Array<ProblemImage[]> = [];
    let containers: ProblemImage[] = [];
    let daimon = '';

    switch (layoutType) {
      case 1:
        images.map((image, index) => {
          const currentDaimon: string = this.getDaimonId(image.url);
          if (daimon === '') daimon = currentDaimon;
          if (currentDaimon === undefined || daimon !== currentDaimon) {
            daimon = currentDaimon;

            layoutImages.push(containers);
            containers = [];
          }
          containers.push(image);

          if (index === images.length - 1) {
            layoutImages.push(containers);
          }
        });
        break;
      case 2:
        let width = 0;
        const maxWidth = Number(String(getComputedStyle(document.querySelector('.pd-layout')).getPropertyValue('--pd-max-width')).trim());

        images.map((image, index) => {
          const currentDaimon: string = this.getDaimonId(image.url);
          if (daimon === '') daimon = currentDaimon;

          if (width > maxWidth || currentDaimon === undefined || daimon !== currentDaimon) {
            daimon = currentDaimon;

            layoutImages.push(containers);
            containers = [];
            width = 0;
          }

          width += image.width;
          containers.push(image);

          if (index === images.length - 1) {
            layoutImages.push(containers);
          }
        });
        break;
      case 3:
        let i = 0;
        images.map((image, index) => {
          if (i === 2) {
            layoutImages.push(containers);
            containers = [];
            i = 0;
          }
          i += 1;
          containers.push(image);

          if (index === images.length - 1) {
            layoutImages.push(containers);
          }
        });
        break;
      default:
    }

    return layoutImages;
  }

  gotoTop() {
    const searchByCategoriesDetailPath = `${RoutingPathResolver.resolveCommonIdSearchByCategories()}/`;
    const eventLabel =
      this.router.url.indexOf(searchByCategoriesDetailPath) !== -1
        ? GA_EVENT_LABELS.SEARCH_BY_CATEGORIES_DETAIL
        : GA_EVENT_LABELS.PROBLEM_DETAIL;
    const eventParams = {
      'event_category': GA_EVENT_CATEGORIES.PREMIUM_DETAIL_VIEW,
      'event_label': eventLabel,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    const url = RoutingPathResolver.resolveCommonIdTop();
    this.store.dispatch(navigate({ url, extras: { queryParams: { to: 'premium' } } }));
  }

  gotoPlan() {
    const searchByCategoriesDetailPath = `${RoutingPathResolver.resolveCommonIdSearchByCategories()}/`;
    const eventLabel =
      this.router.url.indexOf(searchByCategoriesDetailPath) !== -1
        ? GA_EVENT_LABELS.SEARCH_BY_CATEGORIES_DETAIL
        : GA_EVENT_LABELS.PROBLEM_DETAIL;
    const eventParams = {
      'event_category': GA_EVENT_CATEGORIES.PREMIUM_PLAN_VIEW,
      'event_label': eventLabel,
      'value': 1
    };
    GAUtil.sendEvent(GA_EVENT_ACTIONS.CLICK, eventParams);

    const url = RoutingPathResolver.resolveCommonIdPurchase();
    this.store.dispatch(navigate({ url }));
  }

  private getDaimonId(url: string): string {
    const daimonId: string | undefined = url.match('.+/(.+?).[a-z]+([?#;].*)?$')[1].split('_')[1];
    return daimonId
      ? daimonId
      : Math.random()
          .toString(32)
          .substring(2);
  }

  private setUp() {
    this.loaded$ = of(false);
    this.status$ = of(true);
    const daimonHead: string = this.menuType === 'article' ? 'q' : 'a';
    this.layoutType =
      this.menuType === 'article' ? this.readableProblem.questionLayout.layoutType : this.readableProblem.answerLayout.layoutType;
    const targetImages: string[] = this.menuType === 'article' ? this.readableProblem.questionImages : this.readableProblem.answerImages;
    if (targetImages === undefined || targetImages.length === 0) {
      this.loaded$ = of(true);
      this.status$ = of(false);
      return;
    }
    const imageUrls: string[] = targetImages
      .map(img => environment.cloudfrontHost + ProblemImageUtil.getPath(img, this.trialMode))
      .filter(url => this.daimonId === null || url.includes(daimonHead + this.daimonId));
    if (imageUrls.length === 0) {
      this.loaded$ = of(true);
      this.status$ = of(false);
      return;
    }

    const requests$: Observable<ImageData | Error>[] = imageUrls.map(url => this.getImage(url));
    forkJoin(requests$.map(request => request.pipe(retry(3))))
      .pipe(
        take(1),
        catchError(error => {
          Log.debug(this.LOG_SOURCE, `load failed:`, error);
          return of(null);
        })
      )
      .subscribe((images: ImageData[]) => {
        Log.debug(this.LOG_SOURCE, `images:`, images);
        if (images === null) {
          this.status$ = of(false);
        } else {
          this.problemImages = images.map((image: ImageData, i) => {
            const isRetina = imageUrls[i].includes('@2x');
            return {
              width: isRetina ? image.width / 2 : image.width,
              height: isRetina ? image.height / 2 : image.height,
              url: imageUrls[i]
            };
          });
        }
        this.loaded$ = of(true);
        this.changeDetectorRefs.detectChanges();
      });
  }

  private getImage(imageUrl: string): Observable<ImageData | Error> {
    return new Observable<any>(observer => {
      const image = new Image();
      fromEvent(image, 'load')
        .pipe(take(1))
        .subscribe(e => {
          // Log.debug(this.LOG_SOURCE, `load success:`, e.target);
          observer.next(e.target);
          observer.complete();
        });
      fromEvent(image, 'error')
        .pipe(take(1))
        .subscribe(error => {
          // Log.debug(this.LOG_SOURCE, `load error:`, error);
          observer.error(error);
          image.remove();
        });
      image.src = imageUrl;
    });
  }

  private setUpReadableProblemBySubjectId() {
    switch (this.subjectId) {
      case SubjectId.ENGLISH:
        this.readableProblem = this.readableEnglishProblems[0];
        break;
      case SubjectId.MATH:
      case SubjectId.PHYSICS:
      case SubjectId.CHEMISTRY:
      case SubjectId.BIOLOGY:
      case SubjectId.GEOGRAPHY:
      case SubjectId.POLITICAL_ECONOMY:
        this.readableProblem = this.readableScienceProblems[0];
        break;
      case SubjectId.NATIONAL_LANGUAGE:
        this.readableProblem = this.readableNationalLanguageProblems[0];
        break;
      case SubjectId.JAPANESE_HISTORY:
        this.readableProblem = this.readableJapaneseHistoryProblems[0];
        break;
      case SubjectId.WORLD_HISTORY:
        this.readableProblem = this.readableWorldHistoryProblems[0];
        break;
    }

    if (!this.readableProblem) return;
    Log.debug(this.LOG_SOURCE, `readableProblem:`, this.readableProblem);

    this.problemId = this.readableProblem.id;
  }

  private setUpPlaylistThemeBySubjectId() {
    switch (this.subjectId) {
      case SubjectId.ENGLISH:
        this.playlistTheme = this.englishPlaylistTheme;
        this.readablePlaylistProblem = this.englishPlaylistTheme
          ? this.englishPlaylistTheme.problems.find(problem => problem.id === this.problemId)
          : null;
        break;
      case SubjectId.MATH:
      case SubjectId.PHYSICS:
      case SubjectId.CHEMISTRY:
      case SubjectId.BIOLOGY:
      case SubjectId.GEOGRAPHY:
      case SubjectId.POLITICAL_ECONOMY:
        this.playlistTheme = this.sciencePlaylistTheme;
        this.readablePlaylistProblem = this.sciencePlaylistTheme
          ? this.sciencePlaylistTheme.problems.find(problem => problem.id === this.problemId)
          : null;
        break;
      case SubjectId.NATIONAL_LANGUAGE:
        this.playlistTheme = this.nationalLanguagePlaylistTheme;
        this.readablePlaylistProblem = this.nationalLanguagePlaylistTheme
          ? this.nationalLanguagePlaylistTheme.problems.find(problem => problem.id === this.problemId)
          : null;
        break;
      case SubjectId.JAPANESE_HISTORY:
        this.playlistTheme = this.japaneseHistoryPlaylistTheme;
        this.readablePlaylistProblem = this.japaneseHistoryPlaylistTheme
          ? this.japaneseHistoryPlaylistTheme.problems.find(problem => problem.id === this.problemId)
          : null;
        break;
      case SubjectId.WORLD_HISTORY:
        this.playlistTheme = this.worldHistoryPlaylistTheme;
        this.readablePlaylistProblem = this.worldHistoryPlaylistTheme
          ? this.worldHistoryPlaylistTheme.problems.find(problem => problem.id === this.problemId)
          : null;
        break;
    }
  }
}
