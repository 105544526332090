import { AppError } from './app-error';
import { CommonIdAuthErrorInfo, ErrorInfo } from './error-info';

export class CommonIdAuthError extends AppError {
  constructor(info: ErrorInfo, cause?: string | Error) {
    super(info.code, info.message, cause);
    Object.setPrototypeOf(this, CommonIdAuthError.prototype);
  }

  static userNotFound(cause: string | Error): CommonIdAuthError {
    return new CommonIdAuthError(CommonIdAuthErrorInfo.USER_NOT_FOUND, cause);
  }

  static userDisabled(cause: string | Error): CommonIdAuthError {
    return new CommonIdAuthError(CommonIdAuthErrorInfo.USER_DISABLED, cause);
  }

  static alreadySingOut(cause: string | Error): CommonIdAuthError {
    return new CommonIdAuthError(CommonIdAuthErrorInfo.ALREADY_SIGN_OUT, cause);
  }
}
