import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { Log } from '../../../../utils/log';
import { filter, take } from 'rxjs/operators';
import {
  initializeCommonIdGetCustomTokenResult,
  commonIdGetCustomToken
} from '../../../../actions/common-id/common-id-get-custom-token.actions';
import { getCustomToken } from '../../../../selectors/common-id/common-id-get-custom-token.selectors';
import { CommonIdTokenResponse } from '../../../../models/common-id/common-id-token-response';
import { CommonIdGetCustomTokenRequest } from '../../../../models/common-id/common-id-get-custom-token-request';
import { commonIdSignIn, initializeCommonIdSignInResult } from 'src/app/actions/common-id/common-id-auth.actions';
import { getCommonIdSignInResult } from 'src/app/selectors/common-id/common-id-auth.selectors';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { dispatchInfoMessage, navigate, setTitle } from 'src/app/actions/core.actions';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import querystring from 'querystring';

@Component({
  selector: 'app-common-id-callback',
  templateUrl: './sign-in-callback.component.html',
  styleUrls: ['./sign-in-callback.component.scss']
})
export class CommonIdSignInCallbackComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private title = 'サインイン処理中...';
  private gotoUrl: string;

  resCode: string;
  resState: string;
  tokenResponse: CommonIdTokenResponse;
  tokenResponseSubscription$;
  payload: any;
  error: any;
  state: string;
  nonce: string;

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.gotoUrl = localStorage.getItem('gotoUrl');
    localStorage.removeItem('gotoUrl');

    // 共通IDからのコールバックでリダイレクトされてくる
    this.resCode = this.activatedRoute.snapshot.queryParams.code;
    this.resState = this.activatedRoute.snapshot.queryParams.state;
    this.state = localStorage.getItem('state');
    this.nonce = localStorage.getItem('nonce');
    if (this.resCode && this.resState && this.state && this.nonce) {
      this.clearLocalStorage();

      // stateを確認
      if (this.state !== this.resState) {
        // TODO エラー
        Log.error(this.LOG_SOURCE, 'state検証エラー：', this.resState);
        this.gotoSignInError();
        return;
      }

      this.getCustomToken();
    } else {
      this.gotoSignInError();
    }
  }

  ngOnDestroy() {
    if (this.tokenResponseSubscription$) {
      this.tokenResponseSubscription$.unsubscribe();
    }
  }

  private getCustomToken() {
    const request: CommonIdGetCustomTokenRequest = {
      code: this.resCode,
      state: this.state,
      nonce: this.nonce,
      redirectUri: environment.commonIdConfig.redirectUri
    };
    this.store.dispatch(commonIdGetCustomToken({ request }));

    this.store
      .select(getCustomToken)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `get custom token :`, result);
        if (result.success) {
          this.store.dispatch(initializeCommonIdGetCustomTokenResult());
          const customToken = result.response.customToken;
          const tokenInfo = result.response.tokenInfo;

          this.setTokenForLocalStorage(tokenInfo);

          this.store.dispatch(commonIdSignIn({ customToken }));

          this.store
            .select(getCommonIdSignInResult)
            .pipe(
              filter(it => it != null),
              take(1)
            )
            .subscribe(signInResult => {
              Log.debug(this.LOG_SOURCE, 'sign in with custom token result: ', signInResult);
              if (signInResult.success) {
                const queryString = require('querystring');
                const splitUrlParams = this.gotoUrl ? this.gotoUrl.split('?') : '';
                const queryParams = splitUrlParams[1] ? queryString.parse(splitUrlParams[1]) : {};
                // ログイン前にアクセスしたURLが存在する場合は該当URLへ遷移させる
                const url = this.gotoUrl ? splitUrlParams[0] : RoutingPathResolver.resolveCommonIdSearchUniv();
                this.store.dispatch(navigate({ url, extras: { queryParams, replaceUrl: true } }));
              } else {
                this.gotoSignInError();
              }
              this.store.dispatch(initializeCommonIdSignInResult());
            });
        } else {
          Log.warn(this.LOG_SOURCE, `\`get custom token error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
          this.gotoSignInError();
        }
      });
  }

  private gotoSignInError() {
    const url = RoutingPathResolver.resolveCommonIdSignInError();
    this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));
  }

  private setTokenForLocalStorage(tokenInfo) {
    localStorage.setItem('tokenInfo', JSON.stringify(tokenInfo));
  }

  private clearLocalStorage() {
    localStorage.removeItem('state');
    localStorage.removeItem('nonce');
  }
}
