import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-id-mylist-banner',
  templateUrl: './mylist-banner.component.html',
  styleUrls: ['./mylist-banner.component.scss']
})
export class CommonIdMylistBannerComponent implements OnInit {
  @Output() bannerClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
