<app-dialog-basic dialogTitle="メンバー割当解除" [isLoading]="unassigning">
  <div>{{ user.familyName }} {{ user.firstName }} さんの割当を解除しますか？</div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="unassigning"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <div class="text-danger text-sm mr-auto" @enter *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
    <button mat-stroked-button (click)="cancel()" [disabled]="unassigning">キャンセル</button>
    <button mat-flat-button color="primary" (click)="unassign()" [disabled]="unassigning">解除</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
