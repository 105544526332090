import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMapTo } from 'rxjs/operators';
import { ToolsService } from '../services/tools.service';

import { dispatchAppError, dispatchInfoMessage } from '../actions/core.actions';
import * as ToolsActions from '../actions/tools.actions';
import { FirebaseCallableFunctionError } from '../errors/firebase-callable-function-error';
import { Log } from '../utils/log';

/** Visible for testing */
export enum ToolsSuccessMessage {
  TRANSFER_STATIC_DATA = 'Static Data の転送が完了しました',
  TRANSFER_PLAYLISTS = 'Playlists の転送が完了しました',
  COMMON_ID_TRANSFER_PLAYLISTS = 'Common Id Playlists の転送が完了しました',
  ROTATE_RAW_SEARCH_EVENT_GROUP = 'RawSearchEventGroup のローテーションが完了しました',
  EXPORT_SEARCH_EVENT_LOG = '検索ログの GCS 出力が完了しました',
  AGGREGATE_DAILY_SEARCH_EVENT_LOG = '検索ログの daily 集計処理が完了しました',
  AGGREGATE_WEEKLY_SEARCH_EVENT_LOG = '検索ログの weekly 集計処理が完了しました',
  CLEAN_UP_RAW_SEARCH_EVENT_GROUP = 'RawSearchEventGroup の掃除が完了しました',
  ROTATE_RAW_ADMIN_OPERATION_EVENT_GROUP = 'RawAdminOperationEventGroup のローテーションが完了しました',
  EXPORT_ADMIN_OPERATION_EVENT_LOG = '検索ログの GCS 出力が完了しました',
  AGGREGATE_DAILY_ADMIN_OPERATION_EVENT_LOG = '検索ログの daily 集計処理が完了しました',
  AGGREGATE_MONTHLY_ADMIN_OPERATION_EVENT_LOG = '検索ログの monthly 集計処理が完了しました',
  CLEAN_UP_RAW_ADMIN_OPERATION_EVENT_GROUP = 'RawAdminOperationEventGroup の掃除が完了しました',
  BACKUP_FIRESTORE = 'Firestore のバックアップが完了しました',
  COMMON_ID_AGGREGATE_NUMBER_OF_USERS = '当月利用人数の取得が完了しました',
  CHECK_BOOKMARK_MAX_COUNT = 'お気に入り最大数の取得が完了しました',
  CHECK_UNIVERSITY_BOOKMARK_MAX_COUNT = 'お気に入り大学最大数の取得が完了しました',
  CHECK_PAPER_BOOKMARK_MAX_COUNT = 'あとで解く（試験単位）最大数の取得が完了しました',
  CHECK_ANSWERED_PROBLEMS_MAX_COUNT = '解答済み（大問単位）最大数の取得が完了しました',
  CHECK_PLAYLIST_BOOKMARK_MAX_COUNT = 'おすすめ問題セットお気に入り最大数の取得が完了しました',
  PUBLISH_BULK_DELETE_USERS = 'メンバーの一括削除が完了しました'
}

@Injectable()
export class ToolsEffects {
  private LOG_SOURCE = this.constructor.name;

  transferStaticData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.transferStaticData),
      exhaustMap(() =>
        this.toolsService.transferStaticData().pipe(
          switchMapTo(
            of(ToolsActions.transferStaticDataSuccess(), dispatchInfoMessage({ message: ToolsSuccessMessage.TRANSFER_STATIC_DATA }))
          ),
          catchError(e =>
            of(
              ToolsActions.transferStaticDataFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  transferPlaylists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.transferPlaylists),
      exhaustMap(() =>
        this.toolsService.transferPlaylists().pipe(
          switchMapTo(
            of(ToolsActions.transferPlaylistsSuccess(), dispatchInfoMessage({ message: ToolsSuccessMessage.TRANSFER_PLAYLISTS }))
          ),
          catchError(e =>
            of(
              ToolsActions.transferPlaylistsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  commonIdTransferPlaylists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.commonIdTransferPlaylists),
      exhaustMap(() =>
        this.toolsService.commonIdTransferPlaylists().pipe(
          switchMapTo(
            of(
              ToolsActions.commonIdTransferPlaylistsSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.COMMON_ID_TRANSFER_PLAYLISTS })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.commonIdTransferPlaylistsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  rotateRawSearchEventGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.rotateRawSearchEventGroup),
      exhaustMap(() =>
        this.toolsService.forceRotateRawSearchEventGroup().pipe(
          switchMapTo(
            of(
              ToolsActions.rotateRawSearchEventGroupSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.ROTATE_RAW_SEARCH_EVENT_GROUP })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.rotateRawSearchEventGroupFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  exportSearchEventLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.exportSearchEventLog),
      exhaustMap(() =>
        this.toolsService.forceExportSearchEventLog().pipe(
          switchMapTo(
            of(ToolsActions.exportSearchEventLogSuccess(), dispatchInfoMessage({ message: ToolsSuccessMessage.EXPORT_SEARCH_EVENT_LOG }))
          ),
          catchError(e =>
            of(
              ToolsActions.exportSearchEventLogFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  aggregateDailySearchEventLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.aggregateDailySearchEventLog),
      exhaustMap(action =>
        this.toolsService.forceAggregateDailySearchEventLog(action.ymd).pipe(
          switchMapTo(
            of(
              ToolsActions.aggregateDailySearchEventLogSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.AGGREGATE_DAILY_SEARCH_EVENT_LOG })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.aggregateDailySearchEventLogFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  aggregateWeeklySearchEventLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.aggregateWeeklySearchEventLog),
      exhaustMap(action =>
        this.toolsService.forceAggregateWeeklySearchEventLog(action.ymd).pipe(
          switchMapTo(
            of(
              ToolsActions.aggregateWeeklySearchEventLogSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.AGGREGATE_WEEKLY_SEARCH_EVENT_LOG })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.aggregateWeeklySearchEventLogFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  cleanUpRawSearchEventGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.cleanUpRawSearchEventGroup),
      exhaustMap(() =>
        this.toolsService.cleanUpRawSearchEventGroup().pipe(
          switchMapTo(
            of(
              ToolsActions.cleanUpRawSearchEventGroupSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.CLEAN_UP_RAW_SEARCH_EVENT_GROUP })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.cleanUpRawSearchEventGroupFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  rotateRawAdminOperationEventGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.rotateRawAdminOperationEventGroup),
      exhaustMap(() =>
        this.toolsService.forceRotateRawAdminOperationEventGroup().pipe(
          switchMapTo(
            of(
              ToolsActions.rotateRawAdminOperationEventGroupSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.ROTATE_RAW_ADMIN_OPERATION_EVENT_GROUP })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.rotateRawAdminOperationEventGroupFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  exportAdminOperationEventLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.exportAdminOperationEventLog),
      exhaustMap(() =>
        this.toolsService.forceExportAdminOperationEventLog().pipe(
          switchMapTo(
            of(
              ToolsActions.exportAdminOperationEventLogSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.EXPORT_ADMIN_OPERATION_EVENT_LOG })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.exportAdminOperationEventLogFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  aggregateDailyAdminOperationEventLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.aggregateDailyAdminOperationEventLog),
      exhaustMap(action =>
        this.toolsService.forceAggregateDailyAdminOperationEventLog(action.ymd).pipe(
          switchMapTo(
            of(
              ToolsActions.aggregateDailyAdminOperationEventLogSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.AGGREGATE_DAILY_ADMIN_OPERATION_EVENT_LOG })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.aggregateDailyAdminOperationEventLogFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  aggregateMonthlyAdminOperationEventLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.aggregateMonthlyAdminOperationEventLog),
      exhaustMap(action =>
        this.toolsService.forceAggregateMonthlyAdminOperationEventLog(action.ymd).pipe(
          switchMapTo(
            of(
              ToolsActions.aggregateMonthlyAdminOperationEventLogSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.AGGREGATE_MONTHLY_ADMIN_OPERATION_EVENT_LOG })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.aggregateMonthlyAdminOperationEventLogFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  cleanUpRawAdminOperationEventGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.cleanUpRawAdminOperationEventGroup),
      exhaustMap(() =>
        this.toolsService.cleanUpRawAdminOperationEventGroup().pipe(
          switchMapTo(
            of(
              ToolsActions.cleanUpRawAdminOperationEventGroupSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.CLEAN_UP_RAW_ADMIN_OPERATION_EVENT_GROUP })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.cleanUpRawAdminOperationEventGroupFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  backupFirestore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.backupFirestore),
      exhaustMap(() =>
        this.toolsService.forceBackupFirestore().pipe(
          switchMapTo(of(ToolsActions.backupFirestoreSuccess(), dispatchInfoMessage({ message: ToolsSuccessMessage.BACKUP_FIRESTORE }))),
          catchError(e =>
            of(
              ToolsActions.backupFirestoreFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  checkBookmarkMaxCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.checkBookmarkMaxCount),
      exhaustMap(() =>
        this.toolsService.checkBookmarkMaxCount().pipe(
          map(response => Log.debug(this.LOG_SOURCE, `checkBookmarkMaxCount: ${response.count}`)),
          switchMapTo(
            of(ToolsActions.checkBookmarkMaxCountSuccess(), dispatchInfoMessage({ message: ToolsSuccessMessage.CHECK_BOOKMARK_MAX_COUNT }))
          ),
          catchError(e =>
            of(
              ToolsActions.checkBookmarkMaxCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  checkUniversityBookmarkMaxCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.checkUniversityBookmarkMaxCount),
      exhaustMap(() =>
        this.toolsService.checkUniversityBookmarkMaxCount().pipe(
          map(response => Log.debug(this.LOG_SOURCE, `checkUniversityBookmarkMaxCount: ${response.count}`)),
          switchMapTo(
            of(
              ToolsActions.checkUniversityBookmarkMaxCountSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.CHECK_UNIVERSITY_BOOKMARK_MAX_COUNT })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.checkUniversityBookmarkMaxCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  checkPaperBookmarkMaxCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.checkPaperBookmarkMaxCount),
      exhaustMap(() =>
        this.toolsService.checkPaperBookmarkMaxCount().pipe(
          map(response => Log.debug(this.LOG_SOURCE, `checkPaperBookmarkMaxCount: ${response.count}`)),
          switchMapTo(
            of(
              ToolsActions.checkPaperBookmarkMaxCountSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.CHECK_PAPER_BOOKMARK_MAX_COUNT })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.checkPaperBookmarkMaxCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  checkAnsweredProblemsMaxCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.checkAnsweredProblemsMaxCount),
      exhaustMap(() =>
        this.toolsService.checkAnsweredProblemsMaxCount().pipe(
          map(response => Log.debug(this.LOG_SOURCE, `checkAnsweredProblemsMaxCount: ${response.count}`)),
          switchMapTo(
            of(
              ToolsActions.checkAnsweredProblemsMaxCountSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.CHECK_ANSWERED_PROBLEMS_MAX_COUNT })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.checkAnsweredProblemsMaxCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  checkPlaylistBookmarkMaxCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.checkPlaylistBookmarkMaxCount),
      exhaustMap(() =>
        this.toolsService.checkPlaylistBookmarkMaxCount().pipe(
          map(response => Log.debug(this.LOG_SOURCE, `checkPlaylistBookmarkMaxCount: ${response.count}`)),
          switchMapTo(
            of(
              ToolsActions.checkPlaylistBookmarkMaxCountSuccess(),
              dispatchInfoMessage({ message: ToolsSuccessMessage.CHECK_PLAYLIST_BOOKMARK_MAX_COUNT })
            )
          ),
          catchError(e =>
            of(
              ToolsActions.checkPlaylistBookmarkMaxCountFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  deleteMembers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolsActions.deleteMembers),
      exhaustMap(action =>
        this.toolsService.deleteMembers(action.users).pipe(
          map(response => Log.debug(this.LOG_SOURCE, `Delete Members: ${response}`)),
          switchMapTo(
            of(ToolsActions.deleteMembersSuccess(), dispatchInfoMessage({ message: ToolsSuccessMessage.PUBLISH_BULK_DELETE_USERS }))
          ),
          catchError(e =>
            of(
              ToolsActions.deleteMembersFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirebaseCallableFunctionError.from(e) })
            )
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private toolsService: ToolsService) {}
}
