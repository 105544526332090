import { Component, Input, OnInit } from '@angular/core';

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'fluid';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss']
})
export class WrapperComponent implements OnInit {
  constructor() {}

  // wrapperサイズ
  @Input() public size: Size = 'md';

  // スマホ画面の際にはwrapper左右のoffsetを0にする
  @Input() isFitWithSmallScreen = false;

  ngOnInit() {}
}
