import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { NavigationExtras } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { RootState } from '../../../../reducers';
import { dispatchInfoMessage, navigate } from 'src/app/actions/core.actions';
import * as CommonIdUniversityBookmarkActions from 'src/app/actions/common-id/common-id-university-bookmark.actions';
import * as CommonIdUniversityBookmarkSelectors from 'src/app/selectors/common-id/common-id-university-bookmark.selectors';

import { UniversitySearchRouterService } from 'src/app/services/university-search-router.service';
import { UniversitySearchResultSharedService } from 'src/app/services/university-search-result-shared.service';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { DESCRIPTION_PREMIUM_DIALOG_WITDH } from '../../../../resources/common-id-config';
import { DIALOG_ZERO_PADDING_PANEL_CLASS } from '../../../../resources/config';
import { Log } from 'src/app/utils/log';
import { CommonIdDescriptionPremiumDialogComponent } from 'src/app/views/components/common-id/description-premium-dialog/description-premium-dialog.component';

import { UniversityBookmarkCondition } from 'src/app/models/common-id/common-id-bookmark-conditions';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { UniversityBookmark } from 'src/app/models/common-id/common-id-bookmark';
import { FindUniversityPapersResult } from 'src/app/models/find-university-papers-result';
import { UniversitySearchQueryParamsMapper } from 'src/app/mappers/university-search-query-params-mapper';
import { GoToPaperDetailParams } from '../../../../models/search-univ-interfaces';

@Component({
  selector: 'app-common-id-search-univ-result',
  templateUrl: './search-univ-result.component.html',
  styleUrls: ['./search-univ-result.component.scss']
})
export class CommonIdSearchUnivResultComponent implements OnInit, OnDestroy {
  @Input() searchResults: FindUniversityPapersResult[];
  @Input() signedInUser$: Observable<CommonIdUser>;
  @Input() universityBookmark$: Observable<UniversityBookmark>;
  @Input() isPremiumIconShown: boolean;
  @Input() isBtoC: boolean;
  @Input() buttonAbled$: Observable<boolean>;
  @Input() bookmarkClickEmitted: boolean;
  @Input() errorUnivId$: Observable<string>;

  constructor(
    private store: Store<RootState>,
    private dialog: MatDialog,
    private universitySearchRouterService: UniversitySearchRouterService,
    private universitySearchResultSharedService: UniversitySearchResultSharedService
  ) {}

  private LOG_SOURCE = this.constructor.name;
  private initializedBookmarkSubject = new BehaviorSubject(false);

  searchResultsToDisplay: FindUniversityPapersResult[] = [];
  universityBookmark: UniversityBookmark;

  initializedBookmark$ = this.initializedBookmarkSubject.asObservable();

  ngOnInit() {
    this.searchResultsToDisplay = this.searchResults;
    this.setUpIsBookmarkedFlag(null);
  }

  ngOnDestroy() {
    this.store.dispatch(CommonIdUniversityBookmarkActions.initializeFindUniversityBookmarkState());
    this.store.dispatch(CommonIdUniversityBookmarkActions.initializeAddUniversityBookmarkState());
    this.store.dispatch(CommonIdUniversityBookmarkActions.initializeDeleteUniversityBookmarkState());
  }

  addUniversityBookmark(universityId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入り大学を登録します: ', universityId);
    this.universitySearchResultSharedService.emitErrorUniversityId(null);
    this.initializedBookmarkSubject.next(false);
    this.universitySearchResultSharedService.emitBookmarkClick(true);

    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入り大学を登録しています`
      })
    );

    combineLatest([this.signedInUser$])
      .pipe(take(1))
      .subscribe(([signedInUser]) => {
        const request: UniversityBookmarkCondition = {
          userId: signedInUser.id,
          universityId
        };

        Log.debug(this.LOG_SOURCE, 'お気に入り大学の登録 request: ', request);
        this.store.dispatch(CommonIdUniversityBookmarkActions.addUniversityBookmark({ condition: request }));
      });

    this.store
      .select(CommonIdUniversityBookmarkSelectors.getAddUniversityBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `お気に入り大学の登録 result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入り大学を登録しました`
            })
          );
          this.store.dispatch(CommonIdUniversityBookmarkActions.initializeAddUniversityBookmarkState());
          this.setUpIsBookmarkedFlag(false);
        } else {
          Log.warn(this.LOG_SOURCE, `お気に入り大学の登録 error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);

          // エラーが出た際は、該当の大学IDを子コンポーネントに渡す (ボタンの色を変更せず、スピナーも非表示にするため)
          this.store.dispatch(CommonIdUniversityBookmarkActions.initializeAddUniversityBookmarkState());
          this.universitySearchResultSharedService.emitErrorUniversityId(universityId);
          this.initializedBookmarkSubject.next(true);
          this.universitySearchResultSharedService.emitBookmarkClick(false);

          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }
      });
  }

  deleteUniversityBookmark(universityId: string) {
    Log.debug(this.LOG_SOURCE, 'お気に入り大学を解除します ', universityId);
    this.universitySearchResultSharedService.emitErrorUniversityId(null);
    this.initializedBookmarkSubject.next(false);
    this.universitySearchResultSharedService.emitBookmarkClick(true);

    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入り大学を解除しています`
      })
    );

    combineLatest([this.signedInUser$])
      .pipe(take(1))
      .subscribe(([signedInUser]) => {
        const request: UniversityBookmarkCondition = {
          userId: signedInUser.id,
          universityId
        };

        Log.debug(this.LOG_SOURCE, 'お気に入り大学の解除 request: ', request);
        this.store.dispatch(CommonIdUniversityBookmarkActions.deleteUniversityBookmark({ condition: request }));
      });

    this.store
      .select(CommonIdUniversityBookmarkSelectors.getDeleteUniversityBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.LOG_SOURCE, `お気に入り大学の解除 result: `, result);
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入り大学を解除しました`
            })
          );
        } else {
          Log.warn(this.LOG_SOURCE, `お気に入り大学の解除 error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }

        this.store.dispatch(CommonIdUniversityBookmarkActions.initializeDeleteUniversityBookmarkState());
        this.setUpIsBookmarkedFlag(false);
      });
  }

  goToUnivDetail(universityId: string) {
    // Pathに大学IDを追加する
    Log.debug(this.LOG_SOURCE, '大学詳細を開きます: ', universityId);
    const url = RoutingPathResolver.resolveCommonIdSearchUnivDetail(universityId);

    // クエリパラメタに選択された年度を追加する
    const year = this.searchResults[0].year;
    Log.debug(this.LOG_SOURCE, '選択された年度: ', year);
    const extras: NavigationExtras = {
      queryParams: UniversitySearchQueryParamsMapper.encodeUniversitySearchSelectedYear(year)
    };

    this.store.dispatch(navigate({ url, extras }));

    // 大学詳細画面からの遷移フラグを初期化
    this.universitySearchRouterService.initializeIsFromLocationBack();
  }

  goToPaperDetail(event: GoToPaperDetailParams) {
    if (event.isPremiumIconShown) {
      const config: MatDialogConfig = {
        width: DESCRIPTION_PREMIUM_DIALOG_WITDH,
        panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
        autoFocus: false,
        disableClose: true
      };
      this.dialog.open(CommonIdDescriptionPremiumDialogComponent, config);
    } else {
      Log.debug(this.LOG_SOURCE, '問題詳細を開きます: ', event.paperId);
      const url = RoutingPathResolver.resolveCommonIdPaperDetail(event.paperId);

      const extras: NavigationExtras = {
        queryParams: UniversitySearchQueryParamsMapper.encodeDeptNameAndSpecialSubject(event.deptName, event.specialSubject)
      };

      this.store.dispatch(navigate({ url, extras }));
    }
  }

  private setUpIsBookmarkedFlag(bookmarkClickEmitted: boolean | null) {
    combineLatest([this.signedInUser$])
      .pipe(take(1))
      .subscribe(([signedInUser]) => {
        this.store.dispatch(CommonIdUniversityBookmarkActions.initializeFindUniversityBookmarkState());
        this.store.dispatch(CommonIdUniversityBookmarkActions.findUniversityBookmark({ userId: signedInUser.id }));
        this.universityBookmark$.subscribe(bookmark => {
          this.universityBookmark = bookmark;

          // お気に入り登録の有無フラグを更新
          if (this.searchResults.length > 0) {
            this.searchResultsToDisplay.forEach(searchResult => {
              searchResult.isBookmarked =
                bookmark.universities &&
                bookmark.universities.find(bookmarkUniversity => bookmarkUniversity.unvId === searchResult.universityId)
                  ? true
                  : false;
            });
          }
          this.initializedBookmarkSubject.next(true);
          // お気に入りの登録または解除が終了した場合はfalseを設定。初期処理の場合はnullを設定。
          this.universitySearchResultSharedService.emitBookmarkClick(bookmarkClickEmitted);
        });
      });
  }
}
