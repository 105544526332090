import { createAction, props } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { ComonIdFindBookmarkAndAnsweredPaperResponse } from 'src/app/models/common-id/common-id-bookmark-and-answered-paper';

export const commonIdFindBookmarkAndAnsweredPapers = createAction(
  '[CommonIdBookmarkAndAnsweredPaper] Common Id Find Bookmark And Answered Papers',
  props<{ paperIds: string[] }>()
);
export const commonIdFindBookmarkAndAnsweredPapersSuccess = createAction(
  '[CommonIdBookmarkAndAnsweredPaper] Common Id Find Bookmark And Answered Papers Success',
  props<{ response: ComonIdFindBookmarkAndAnsweredPaperResponse[] }>()
);
export const commonIdFindBookmarkAndAnsweredPapersFailure = createAction(
  '[CommonIdBookmarkAndAnsweredPaper] Common Id Find Bookmark And Answered Papers Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdFindBookmarkAndAnsweredPapers = createAction(
  '[CommonIdBookmarkAndAnsweredPaper] Initialize Common Id Find Bookmark And Answered Papers'
);
