<div class="problem-detail-frame" *ngIf="(loaded$ | async) === true">
  <div class="problem-detail-frame-head" *ngIf="(status$ | async) === true; else noImageData">
    <div class="flex">
      <app-card-content-nav
        class="problem-detail-frame-head-tabs"
        (menuClick)="menuClickHandler($event)"
        [menus]="[
          {
            key: 'article',
            icon: 'article',
            fontSet: 'material-icons-outlined',
            label: '問題',
            isActive: menuType === 'article',
            disabled: canDisplay !== true
          },
          {
            key: 'spellcheck',
            icon: 'spellcheck',
            label: '研究・解答',
            isActive: menuType === 'spellcheck',
            disabled: canDisplay !== true
          }
        ]"
      ></app-card-content-nav>

      <div class="problem-detail-frame-utils" *ngIf="tryMode !== true">
        <div class="problem-detail-frame-utils-actions">
          <div class="problem-detail-frame-utils-actions-inner">
            <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
          </div>
          <button
            mat-flat-button
            class="button-print-in-utils bg-gray-50 font-normal"
            (click)="printClickHandler.emit()"
            [disabled]="!canDisplay"
          >
            <mat-icon aria-hidden="true" class="text-primary">print</mat-icon>
            <span class="button-print-in-utils-label">印刷</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="problem-detail-frame-body" *ngIf="(status$ | async) === true">
    <div
      class="problem-detail-frame-body-actions-sp"
      [attr.data-scrollDirectionIsUp]="scrollDirectionIsUp | async"
      *ngIf="tryMode !== true"
    >
      <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
    </div>
    <div class="problem-detail-frame-content">
      <div *ngIf="paperMode" class="cover-meta" [attr.data-canDisplay]="canDisplay">
        <h1 class="cover-meta-title">{{ readableProblem?.university }}</h1>
        <div class="cover-meta-title-sub">
          <p>
            {{ subjectName }}&nbsp;&nbsp;&nbsp;&nbsp;{{ readableProblem?.examPaper.year }}年度入試&nbsp;&nbsp;&nbsp;&nbsp;
            <span *ngIf="menuType === 'spellcheck'">研究・解答</span>
            <span *ngIf="menuType === 'article'">問題</span>
          </p>
        </div>
        <div class="cover-meta-title-meta">
          <div class="cover-meta-title-meta-inner">
            <div>【学部】 {{ readableProblem?.departments }}</div>
            <div>【試験日】 {{ examDate }}</div>
            <div>【入試名】 {{ nyushiName }}</div>
            <div>【試験時間】{{ examTime }}</div>
            <div class="special-info" style="white-space: pre-wrap" *ngIf="specialInfoData.length > 0">
              <div>【その他特記事項】 {{ specialInfoData.join('　') }}</div>
            </div>
          </div>
        </div>
      </div>

      <app-wrapper
        class="pb-4"
        size="xl"
        isFitWithSmallScreen="true"
        *ngIf="showComment && readablePlaylistProblem && readablePlaylistProblem.comment"
      >
        <div class="problem-detail-frame-notice">
          <p>
            {{ readablePlaylistProblem.comment }}
          </p>
        </div>
      </app-wrapper>
      <!-- TODO リリースまでコメントアウトしておく 2023/09/04 -->
      <!--      <app-wrapper-->
      <!--        class="pb-4"-->
      <!--        size="xl"-->
      <!--        isFitWithSmallScreen="true"-->
      <!--        *ngIf="showComment && menuType === 'spellcheck' && readablePlaylistProblem && readablePlaylistProblem.answerComment"-->
      <!--      >-->
      <!--        <div class="problem-detail-frame-notice">-->
      <!--          <p>-->
      <!--            {{ readablePlaylistProblem.answerComment }}-->
      <!--          </p>-->
      <!--        </div>-->
      <!--      </app-wrapper>-->

      <!-- ▼ トライ連携用表示 -->
      <div class="mb-8 md:mb-10" *ngIf="tryMode">
        <app-problem-detail-data-table
          [subjectId]="subjectId"
          [daimonId]="tryDaimonId"
          [readableProblem]="readableProblem"
        ></app-problem-detail-data-table>
      </div>
      <!-- △ トライ連携用表示 -->

      <div *ngIf="canDisplay !== false">
        <!-- レイアウトパターン 1 -->
        <div class="pd-layout" data-layout-type="1" *ngIf="layoutType === 1">
          <div class="pd-f-area" data-layout-type="horizontal" *ngIf="fImage">
            <img src="{{ fImage.url }}" width="{{ fImage.width }}" height="{{ fImage.height }}" />
          </div>

          <div class="pd-layout-group" *ngFor="let containers of calcImageLayout(layoutType, problemImages)">
            <div class="pd-layout-group-item" *ngFor="let image of containers">
              <img src="{{ image.url }}" width="{{ image.width }}" height="{{ image.height }}" />
            </div>
          </div>
        </div>

        <!-- レイアウトパターン 2 -->
        <!-- レイアウトパターン 2 では、アスペクト比が重要な為、画像に width height をセットしなければならない -->
        <div class="pd-layout" data-layout-type="2" *ngIf="layoutType === 2">
          <div class="pd-f-area" data-layout-type="vertical" *ngIf="fImage">
            <img src="{{ fImage.url }}" width="{{ fImage.width }}" height="{{ fImage.height }}" />
          </div>

          <div class="pd-layout-group" *ngFor="let containers of calcImageLayout(layoutType, problemImages)">
            <div class="pd-layout-group-item" *ngFor="let image of containers">
              <img src="{{ image.url }}" width="{{ image.width }}" height="{{ image.height }}" />
            </div>
          </div>
        </div>

        <!-- レイアウトパターン 3 -->
        <div class="pd-layout" data-layout-type="3" *ngIf="layoutType === 3">
          <div class="pd-f-area" data-layout-type="horizontal" *ngIf="fImage">
            <img src="{{ fImage.url }}" width="{{ fImage.width }}" height="{{ fImage.height }}" />
          </div>

          <ng-container *ngFor="let containers of calcImageLayout(layoutType, problemImages); last as last">
            <div class="pd-layout-group">
              <ng-container *ngFor="let image of containers">
                <div class="pd-layout-group-item">
                  <img src="{{ image.url }}" width="{{ image.width }}" height="{{ image.height }}" />
                </div>
              </ng-container>
            </div>
            <app-problem-detail-separater *ngIf="!last"></app-problem-detail-separater>
          </ng-container>
        </div>
      </div>

      <div *ngIf="canDisplay === false">
        <!-- TODO BtoC無料ユーザの場合に表示するエリア -->
        <div class="description-premium-dialog">
          <div class="description-premium-dialog-inner">
            <picture class="description-premium-image">
              <source srcset="./assets/images/info-premium-required@2x.png" media="(min-width: 900px)" />
              <img src="./assets/images/info-premium-required-sp@2x.png" alt="入試正解デジタルプレミアムにご登録が必要です" />
            </picture>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4 description-premium-dialog-footer">
              <button mat-flat-button class="text-primary bg-gray-50 text-base" (click)="gotoTop()">詳しく見る</button>
              <button mat-flat-button color="primary" class="font-bold text-base" (click)="gotoPlan()">プレミアムプランの選択</button>
            </div>
          </div>
        </div>
      </div>

      <p class="pd-copyright"><small>© Obunsha Co.,Ltd. Powered by 全国大学入試問題正解</small></p>
    </div>
  </div>

  <ng-template #noImageData>
    <div class="text-center text-nega image-error">
      <div>
        <div class="mb-4 mt-24">
          <mat-icon style="width: 50px; height: 50px; font-size: 50px;">sentiment_very_dissatisfied</mat-icon>
        </div>
        <div class="text-md md:text-lg leading-6">画像を表示できません。申し訳ございません</div>
      </div>
    </div>
  </ng-template>
</div>
<div class="progress-48" *ngIf="(loaded$ | async) === false">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>

<ng-template #actionButtons>
  <app-button-toggle-paper-bookmark
    #savePaperBtn
    *ngIf="showPaperBookmarkBtn && !isPaperBookmarked"
    [isPaperBookmarked]="false"
    [canSpinner]="canBookmarkSpinner"
    (bookmarkClick)="addPaperBookmarkClick.emit()"
    class="button-toggle-paper-bookmark font-normal"
    [disabled]="!canDisplay"
  >
  </app-button-toggle-paper-bookmark>
  <app-button-toggle-paper-bookmark
    #savePaperBtn
    *ngIf="showPaperBookmarkBtn && isPaperBookmarked"
    [isPaperBookmarked]="true"
    [canSpinner]="canBookmarkSpinner"
    (bookmarkClick)="deletePaperBookmarkClick.emit()"
    class="button-toggle-paper-bookmark font-normal"
    [disabled]="!canDisplay"
  >
  </app-button-toggle-paper-bookmark>
  <button
    mat-flat-button
    class="button-open-answered-dialog bg-gray-50 font-normal"
    *ngIf="showPaperBookmarkBtn"
    (click)="openAnsweredDialogClick.emit()"
    [disabled]="!canDisplay"
  >
    <mat-icon aria-hidden="true" class="text-primary">check_circle</mat-icon>
    解答済み
  </button>
  <app-button-toggle-saved-problem
    #saveAnsweredBtn
    *ngIf="showSaveAnsweredBtn"
    [isAnswerd]="isAnswered"
    [canSpinner]="true"
    (saveAnsweredClick)="saveAnsweredClick.emit()"
    class="font-normal"
    [disabled]="disabledSaveAnsweredBtn || !canDisplay"
  >
  </app-button-toggle-saved-problem>
  <button
    mat-flat-button
    class="bg-gray-50 font-normal"
    (click)="paperClickHandler.emit()"
    *ngIf="!paperMode && hidePaperProblemBtn !== true"
    [disabled]="!canDisplay"
  >
    <mat-icon aria-hidden="true" class="text-primary">import_contacts</mat-icon>
    試験問題全体を確認
  </button>
</ng-template>
