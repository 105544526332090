import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, mapTo, exhaustMap } from 'rxjs/operators';

import * as CommonIdEventLogActions from '../../actions/common-id/common-id-event-log.actions';
import { Log } from '../../utils/log';
import { RootState } from '../../reducers';
import { CommonIdEventLogService } from '../../services/common-id/common-id-event-log.service';

@Injectable()
export class CommonIdEventLogEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdReportAppLaunchEvent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdEventLogActions.commonIdReportAppLaunchEvent),
      exhaustMap(() =>
        this.eventLogService.reportAppEvent({ type: 'launch-app' }).pipe(
          mapTo(CommonIdEventLogActions.commonIdReportAppLaunchEventSuccess()),
          catchError(e => {
            Log.error(this.LOG_SOURCE, `LaunchAppEvent の送信に失敗しました. error: `, e);
            return of(CommonIdEventLogActions.commonIdReportAppLaunchEventFailure());
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private store: Store<RootState>, private eventLogService: CommonIdEventLogService) {}
}
