import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-download-word-file',
  templateUrl: './button-download-word-file.component.html',
  styleUrls: ['./button-download-word-file.component.scss']
})
export class ButtonDownloadWordFileComponent implements OnInit {
  @Input() disabled = false;
  @Output() buttonClick = new EventEmitter();
  constructor() {}
  ngOnInit() {}
}
