<app-dialog-basic [dialogTitle]="dialogTitle" dialogIcon="check_circle">
  <p class="select-answered-notice">
    解き終わった大問を解答済みにしましょう。<br />
    解答済みにすると、<a (click)="goToMylistAnsweredProblems()">マイリスト > 解答済み </a>で振り返ることができます。
  </p>
  <div class="flex gap-4 bar">
    <b>進捗</b>
    <div class="grow">
      <app-progress-bar progress="{{ progress }}"></app-progress-bar>
    </div>
    <b class="text-primary">{{ savedCount }} / {{ totalCount }}問</b>
  </div>
  <form>
    <div class="mb-4">
      <div class="flex flex-col select-answered-all">
        <div class="select-answered-list-content flex items-center">
          <div class="select-answered-list-detail">
            すべての問題を<b>「解答済み」</b>にする
          </div>
          <div class="select-answered-list-toggle">
            <app-slide-toggle [checked]="allCheck" [disabled]="disabled" (slideClick)="allToggleClick($event)"></app-slide-toggle>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="select-answered-list">
          <div class="select-answered-list-content flex items-center" *ngFor="let daimon of daimonData">
            <div class="select-answered-list-detail">
              <p>大問{{ daimon.daimonId }}</p>
            </div>
            <div class="select-answered-list-toggle">
              <app-slide-toggle
                [checked]="daimon.isSaved"
                [disabled]="disabled"
                [problemId]="daimon.problemId"
                [particles]="true"
                (slideClick)="toggleClick($event)"
              ></app-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="saveAnsweredProblems()" [disabled]="disabled">保存</button>
  </app-dialog-basic-footer>
</app-dialog-basic>

<div class="progress-48">
  <mat-spinner [diameter]="48" @enter *ngIf="disabled"></mat-spinner>
</div>
