<app-wrapper size="lg" isFitWithSmallScreen="true">
  <div>
    <app-content-row>
      <app-article-content>
        <div class="icon-area">
          <mat-icon class="icon material-icons-outlined">check_circle</mat-icon>
        </div>
        <p class="text-2xl md:text-3xl text-center mb-8"><b>購入手続き完了</b></p>
        <div class="purchase-message">
          ご購入ありがとうございました。<br /><br />
          ご購入情報のメールを、ご購入者のメールアドレスと、まなびIDのご登録メール<br />アドレスにお送りさせていただきました。ご確認ください。<br /><br />
          プレミアムプランの反映にお時間をいただく可能性がございます。<br />
          反映されたかについては以下の購入情報ページよりご確認ください。
        </div>
        <div class="purchase-button-wrap grid">
          <button mat-flat-button color="primary" class="text-base" (click)="gotoPayments()">
            購入情報を確認する
          </button>
        </div>
      </app-article-content>
    </app-content-row>
  </div>
</app-wrapper>
