export interface CommonIdPlaylistSearchQueryParams {
  subjectId?: string;
  sortType?: CommonIdPlaylistSearchSortType;
  free?: true;
  tag?: string;
}

export enum CommonIdPlaylistSearchSortType {
  PUBLISHED_DATE = 'publishedDate',
  PLAYLIST_ID_ASC = 'playlistId-asc',
  PLAYLIST_ID_DESC = 'playlistId-desc',
  DURATION = 'duration'
}
