<div class="button-toggle-univ-bookmark">
  <div class="button-toggle-univ-bookmark-inner">
    <ng-container *ngIf="showSpinner === false; else bookmarkSpiner">
      <!-- OFF -->
      <button
        *ngIf="!isBookmarked"
        class="button"
        mat-flat-button
        [disabled]="disabled"
        (click)="toggle(true)"
        matTooltip="大学をお気に入りに登録"
        matTooltipPosition="above"
      >
        <mat-icon class="icon" aria-hidden="false" aria-label="お気に入りに登録">star_border</mat-icon>
        <span class="button-text">お気に入り大学</span>
      </button>

      <!-- ON -->
      <button
        *ngIf="isBookmarked"
        class="button bookmarked"
        mat-flat-button
        [disabled]="disabled"
        (click)="toggle(false)"
        matTooltip="お気に入りを解除します"
        matTooltipPosition="above"
      >
        <mat-icon class="icon" aria-hidden="false" aria-label="お気に入りを解除">star</mat-icon>
        <span class="button-text">お気に入り大学</span>
      </button>
    </ng-container>
    <ng-template #bookmarkSpiner>
      <div class="progress-24">
        <mat-spinner [diameter]="17"></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
