import { createAction, props } from '@ngrx/store';

import { AppError } from '../../errors/app-error';

import { UniversityBookmarkCondition } from '../../models/common-id/common-id-bookmark-conditions';
import { UniversityBookmark } from '../../models/common-id/common-id-bookmark';
import { AddUniversityBookmarkResponse } from '../../models/common-id/common-id-add-bookmark-response';
import { DeleteUniversityBookmarkResponse } from '../../models/common-id/common-id-delete-bookmark-response';

// お気に入り大学の検索処理 ------------------------------------------------------------
export const findUniversityBookmark = createAction('[Common ID Bookmark] Find University Bookmark', props<{ userId: string }>());
export const findUniversityBookmarkSuccess = createAction(
  '[Common ID Bookmark] Find University Bookmark Success',
  props<{ universityBookmark: UniversityBookmark }>()
);
export const findUniversityBookmarkFailure = createAction('[Common ID Bookmark] Find University Bookmark Failure');
export const initializeFindUniversityBookmarkState = createAction('[Common ID Bookmark] Initialize Find University Bookmark State');

// お気に入り大学の追加処理 ------------------------------------------------------------
export const addUniversityBookmark = createAction(
  '[Common ID Bookmark] Add University Bookmark',
  props<{ condition: UniversityBookmarkCondition }>()
);
export const addUniversityBookmarkSuccess = createAction(
  '[Common ID Bookmark] Add University Bookmark Success',
  props<{ response: AddUniversityBookmarkResponse }>()
);
export const addUniversityBookmarkFailure = createAction(
  '[Common ID Bookmark] Add University Bookmark Failure',
  props<{ error: AppError }>()
);
export const initializeAddUniversityBookmarkState = createAction('[Common ID Bookmark] Initialize Add University Bookmark State');

// お気に入り大学の削除処理 ------------------------------------------------------------
export const deleteUniversityBookmark = createAction(
  '[Common ID Bookmark] Delete University Bookmark',
  props<{ condition: UniversityBookmarkCondition }>()
);
export const deleteUniversityBookmarkSuccess = createAction(
  '[Common ID Bookmark] Delete University Bookmark Success',
  props<{ response: DeleteUniversityBookmarkResponse }>()
);
export const deleteUniversityBookmarkFailure = createAction(
  '[Common ID Bookmark] Delete University Bookmark Failure',
  props<{ error: AppError }>()
);
export const initializeDeleteUniversityBookmarkState = createAction('[Common ID Bookmark] Initialize Delete University Bookmark State');
