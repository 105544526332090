import { createAction, props } from '@ngrx/store';

import { CurrentDateTime } from '../models/current-date-time';

// getCurrentDateTime ------------------------------------------------------------
export const getCurrentDateTime = createAction('[CurrentDateTime] Get CurrentDateTime');
export const getCurrentDateTimeSuccess = createAction(
  '[CurrentDateTime] Get CurrentDateTime Success',
  props<{ currentDateTime: CurrentDateTime }>()
);
export const getCurrentDateTimeFailure = createAction('[CurrentDateTime] Get CurrentDateTime Failure');
export const initializeGetCurrentDateTime = createAction('[CurrentDateTime] Initialize Get CurrentDateTime State');
