import { Component, Inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { commonIdSignOut } from '../../../../actions/common-id/common-id-auth.actions';
import { navigate, openWindow, unsubscribeWatchedProps } from '../../../../actions/core.actions';
import { UnsubscribeTarget } from '../../../../resources/config';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { CommonIdOidcUtil } from '../../../../utils/common-id/common-id-oidc-util';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';

@Component({
  selector: 'app-common-id-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class CommonIdSignOutComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;
  private loginButtonDisabled = false;

  reSignIn: string = null;

  constructor(private store: Store<RootState>, @Inject(WINDOW_OBJECT) private window: Window) {}

  ngOnInit() {
    this.store.dispatch(commonIdSignOut());
    this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.ON_SIGN_OUT }));
    this.clearLocalStorage();

    this.reSignIn = localStorage.getItem('signInExpired');
    if (this.reSignIn) {
      localStorage.removeItem('signInExpired');
      setTimeout(() => {
        const url = CommonIdOidcUtil.getAuthorizationEndpoint();
        this.store.dispatch(openWindow({ url, target: '_self' }));
      });
    }
  }

  gotoTop() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdTop() }));
  }

  gotoSearch() {
    if (!this.loginButtonDisabled) {
      this.loginButtonDisabled = true;
      this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUniv() }));
    }
  }

  private clearLocalStorage() {
    localStorage.removeItem('previousUrl');
    localStorage.removeItem('state');
    localStorage.removeItem('nonce');
  }
}
