import { SearchCondition, SearchProblemsCondition } from '../models/search-condition';
import { EnglishSearchCondition } from '../models/english-search-condition';
import {
  EnglishSearchConditionQueryParams,
  ScienceSearchConditionQueryParams,
  HistorySearchConditionQueryParams,
  EnglishSearchProblemsConditionQueryParams,
  ScienceSearchProblemsConditionQueryParams,
  HistorySearchProblemsConditionQueryParams,
  NationalLanguageSearchConditionQueryParams,
  NationalLanguageSearchProblemsConditionQueryParams
} from '../models/query-params';
import { CommonSearchCondition } from '../models/common-search-condition';
import { ScienceSearchCondition } from '../models/science-search-condition';
import { HistorySearchCondition } from '../models/history-search-condition';
import { NATIONAL_LANGUAGE_HIGHEST_WORD_COUNT_STRING, NATIONAL_LANGUAGE_LOWEST_WORD_COUNT_STRING, SubjectId } from '../resources/config';
import { GeneralError } from '../errors/general-error';
import { Log } from '../utils/log';
import { NationalLanguageSearchCondition } from '../models/national-language-search-condition';

// NOTE:
// array で指定されていた値が 1 つしかない場合、ブラウザをリロードすると、
// query params には array として復元されずに単一の string として復元されるため、
// array かどうかを判定して処理を分けている
export class QueryParamsMapper {
  static encodeSearchCondition(
    condition: SearchCondition<any>
  ):
    | EnglishSearchConditionQueryParams
    | ScienceSearchConditionQueryParams
    | NationalLanguageSearchConditionQueryParams
    | HistorySearchConditionQueryParams {
    switch (condition.subjectId) {
      case SubjectId.ENGLISH:
        return QueryParamsMapper.encodeEnglishSearchCondition(condition);
      case SubjectId.MATH:
      case SubjectId.PHYSICS:
      case SubjectId.CHEMISTRY:
      case SubjectId.BIOLOGY:
      case SubjectId.GEOGRAPHY:
      case SubjectId.POLITICAL_ECONOMY:
        return QueryParamsMapper.encodeScienceSearchCondition(condition);
      case SubjectId.NATIONAL_LANGUAGE:
        return QueryParamsMapper.encodeNationalLanguageSearchCondition(condition);
      case SubjectId.JAPANESE_HISTORY:
      case SubjectId.WORLD_HISTORY:
        return QueryParamsMapper.encodeHistorySearchCondition(condition);
      default:
        throw GeneralError.invalidArguments(`不明な subject Id が指定されました: ${condition.subjectId}`);
    }
  }

  static decodeSearchConditionQueryParams(
    params: Partial<
      | EnglishSearchConditionQueryParams
      | ScienceSearchConditionQueryParams
      | NationalLanguageSearchConditionQueryParams
      | HistorySearchConditionQueryParams
    >
  ):
    | SearchCondition<EnglishSearchCondition | ScienceSearchCondition | NationalLanguageSearchCondition | HistorySearchCondition>
    | undefined {
    switch (params.subjectId) {
      case SubjectId.ENGLISH:
        return QueryParamsMapper.decodeEnglishSearchConditionParams(params);
      case SubjectId.MATH:
      case SubjectId.PHYSICS:
      case SubjectId.CHEMISTRY:
      case SubjectId.BIOLOGY:
      case SubjectId.GEOGRAPHY:
      case SubjectId.POLITICAL_ECONOMY:
        return QueryParamsMapper.decodeScienceSearchConditionParams(params);
      case SubjectId.NATIONAL_LANGUAGE:
        return QueryParamsMapper.decodeNationalLanguageSearchConditionParams(params);
      case SubjectId.JAPANESE_HISTORY:
      case SubjectId.WORLD_HISTORY:
        return QueryParamsMapper.decodeHistorySearchConditionParams(params);
      default:
        Log.warn('QueryParamsMapper', `不明な subject Id が指定されました: ${params.subjectId}`);
        return undefined;
    }
  }

  static encodeEnglishSearchCondition(condition: SearchCondition<EnglishSearchCondition>): EnglishSearchConditionQueryParams {
    const common = QueryParamsMapper.encodeCommonSearchCondition(condition);
    const params: EnglishSearchConditionQueryParams = {
      ...common
    };

    const subjectCondition = condition.subjectCondition;
    if (!subjectCondition) return params;

    if (subjectCondition.fieldIds && subjectCondition.fieldIds.length !== 0) {
      params.fieldIds = [...subjectCondition.fieldIds];
    }
    if (subjectCondition.subTypeIds && subjectCondition.subTypeIds.length !== 0) {
      params.subTypeIds = [...subjectCondition.subTypeIds];
    }
    if (subjectCondition.longSentenceTypeIds && subjectCondition.longSentenceTypeIds.length !== 0) {
      params.longSentenceTypeIds = [...subjectCondition.longSentenceTypeIds];
    }
    if (subjectCondition.longSentenceWordCount) {
      params.longSentenceWordCountMin = subjectCondition.longSentenceWordCount.min;
      params.longSentenceWordCountMax = subjectCondition.longSentenceWordCount.max;
    }
    if (subjectCondition.longSentenceKeywords && subjectCondition.longSentenceKeywords.length !== 0) {
      params.longSentenceKeywords = [...subjectCondition.longSentenceKeywords];
    }
    if (subjectCondition.subjectIds && subjectCondition.subjectIds.length !== 0) {
      params.subjectIds = [...subjectCondition.subjectIds];
    }
    if (subjectCondition.categories && subjectCondition.categories.length !== 0) {
      params.categories = [...subjectCondition.categories];
    }

    return params;
  }

  static decodeEnglishSearchConditionParams(
    params: Partial<EnglishSearchConditionQueryParams>
  ): SearchCondition<EnglishSearchCondition> | undefined {
    const common = QueryParamsMapper.decodeCommonSearchCondition(params);
    if (!common) return undefined;

    const condition: SearchCondition<EnglishSearchCondition> = {
      ...common,
      subjectCondition: {}
    };

    if (params.fieldIds) {
      condition.subjectCondition.fieldIds = Array.isArray(params.fieldIds) ? [...params.fieldIds] : [params.fieldIds];
    }
    if (params.subTypeIds) {
      condition.subjectCondition.subTypeIds = Array.isArray(params.subTypeIds) ? [...params.subTypeIds] : [params.subTypeIds];
    }
    if (params.longSentenceTypeIds) {
      condition.subjectCondition.longSentenceTypeIds = Array.isArray(params.longSentenceTypeIds)
        ? [...params.longSentenceTypeIds]
        : [params.longSentenceTypeIds];
    }
    if (params.longSentenceWordCountMin != null && params.longSentenceWordCountMax != null) {
      condition.subjectCondition.longSentenceWordCount = {
        min: Number(params.longSentenceWordCountMin),
        max: Number(params.longSentenceWordCountMax)
      };
    }
    if (params.longSentenceKeywords) {
      condition.subjectCondition.longSentenceKeywords = Array.isArray(params.longSentenceKeywords)
        ? [...params.longSentenceKeywords]
        : [params.longSentenceKeywords];
    }
    if (params.subjectIds) {
      condition.subjectCondition.subjectIds = Array.isArray(params.subjectIds) ? [...params.subjectIds] : [params.subjectIds];
    }
    if (params.categories) {
      condition.subjectCondition.categories = Array.isArray(params.categories) ? [...params.categories] : [params.categories];
    }

    return condition;
  }

  static encodeScienceSearchCondition(condition: SearchCondition<ScienceSearchCondition>): ScienceSearchConditionQueryParams {
    const common = QueryParamsMapper.encodeCommonSearchCondition(condition);
    const params: ScienceSearchConditionQueryParams = {
      ...common
    };

    const subjectCondition = condition.subjectCondition;
    if (!subjectCondition) return params;

    if (subjectCondition.subjectIds && subjectCondition.subjectIds.length !== 0) {
      params.subjectIds = [...subjectCondition.subjectIds];
    }
    if (subjectCondition.subjectAndFieldIds && subjectCondition.subjectAndFieldIds.length !== 0) {
      params.subjectAndFieldIds = [...subjectCondition.subjectAndFieldIds];
    }
    if (subjectCondition.categories && subjectCondition.categories.length !== 0) {
      params.categories = [...subjectCondition.categories];
    }

    return params;
  }

  static decodeScienceSearchConditionParams(
    params: Partial<ScienceSearchConditionQueryParams>
  ): SearchCondition<ScienceSearchCondition> | undefined {
    const common = QueryParamsMapper.decodeCommonSearchCondition(params);
    if (!common) return undefined;

    const condition: SearchCondition<ScienceSearchCondition> = {
      ...common,
      subjectCondition: {}
    };

    if (params.subjectIds) {
      condition.subjectCondition.subjectIds = Array.isArray(params.subjectIds) ? [...params.subjectIds] : [params.subjectIds];
    }
    if (params.subjectAndFieldIds) {
      condition.subjectCondition.subjectAndFieldIds = Array.isArray(params.subjectAndFieldIds)
        ? [...params.subjectAndFieldIds]
        : [params.subjectAndFieldIds];
    }
    if (params.categories) {
      condition.subjectCondition.categories = Array.isArray(params.categories) ? [...params.categories] : [params.categories];
    }

    return condition;
  }

  static encodeNationalLanguageSearchCondition(
    condition: SearchCondition<NationalLanguageSearchCondition>
  ): NationalLanguageSearchConditionQueryParams {
    const common = QueryParamsMapper.encodeCommonSearchCondition(condition);
    const params: NationalLanguageSearchConditionQueryParams = {
      ...common
    };

    const sc = condition.subjectCondition;
    if (!sc) return params;
    const notEmpty = (xs: unknown[]) => xs && xs.length > 0;

    return {
      ...params,
      ...(notEmpty(sc.subjectIds) && { subjectIds: [...sc.subjectIds] }),
      ...(notEmpty(sc.subjectAndFieldIds) && { subjectAndFieldIds: [...sc.subjectAndFieldIds] }),
      ...(notEmpty(sc.categories) && { categories: [...sc.categories] }),
      ...(notEmpty(sc.keywords) && { keywords: [...sc.keywords] }),
      ...(sc.wordCount && { wordCountMin: sc.wordCount.min, wordCountMax: sc.wordCount.max })
    };
  }

  static decodeNationalLanguageSearchConditionParams(
    params: Partial<NationalLanguageSearchConditionQueryParams>
  ): SearchCondition<NationalLanguageSearchCondition> | undefined {
    const common = QueryParamsMapper.decodeCommonSearchCondition(params);
    if (!common) return undefined;

    const condition: SearchCondition<NationalLanguageSearchCondition> = {
      ...common,
      subjectCondition: {}
    };

    if (params.subjectIds) {
      condition.subjectCondition.subjectIds = Array.isArray(params.subjectIds) ? [...params.subjectIds] : [params.subjectIds];
    }
    if (params.subjectAndFieldIds) {
      condition.subjectCondition.subjectAndFieldIds = Array.isArray(params.subjectAndFieldIds)
        ? [...params.subjectAndFieldIds]
        : [params.subjectAndFieldIds];
    }
    if (params.categories) {
      condition.subjectCondition.categories = Array.isArray(params.categories) ? [...params.categories] : [params.categories];
    }
    if (params.keywords) {
      condition.subjectCondition.keywords = Array.isArray(params.keywords) ? [...params.keywords] : [params.keywords];
    }
    if (params.wordCountMin) {
      if (condition.subjectCondition.wordCount) condition.subjectCondition.wordCount.min = params.wordCountMin;
      else {
        condition.subjectCondition.wordCount = { min: params.wordCountMin, max: parseInt(NATIONAL_LANGUAGE_HIGHEST_WORD_COUNT_STRING, 10) };
      }
    }
    if (params.wordCountMax) {
      if (condition.subjectCondition.wordCount) condition.subjectCondition.wordCount.max = params.wordCountMax;
      else {
        condition.subjectCondition.wordCount = { min: parseInt(NATIONAL_LANGUAGE_LOWEST_WORD_COUNT_STRING, 10), max: params.wordCountMax };
      }
    }
    return condition;
  }

  static encodeHistorySearchCondition(condition: SearchCondition<HistorySearchCondition>): HistorySearchConditionQueryParams {
    const common = QueryParamsMapper.encodeCommonSearchCondition(condition);
    const params: HistorySearchConditionQueryParams = {
      ...common
    };

    const subjectCondition = condition.subjectCondition;
    if (!subjectCondition) return params;

    if (subjectCondition.subjectIds && subjectCondition.subjectIds.length !== 0) {
      params.subjectIds = [...subjectCondition.subjectIds];
    }
    if (subjectCondition.subjectAndFieldIds && subjectCondition.subjectAndFieldIds.length !== 0) {
      params.subjectAndFieldIds = [...subjectCondition.subjectAndFieldIds];
    }
    if (subjectCondition.categories && subjectCondition.categories.length !== 0) {
      params.categories = [...subjectCondition.categories];
    }
    if (subjectCondition.keywords && subjectCondition.keywords.length !== 0) {
      params.keywords = [...subjectCondition.keywords];
    }

    return params;
  }

  static decodeHistorySearchConditionParams(
    params: Partial<HistorySearchConditionQueryParams>
  ): SearchCondition<HistorySearchCondition> | undefined {
    const common = QueryParamsMapper.decodeCommonSearchCondition(params);
    if (!common) return undefined;

    const condition: SearchCondition<HistorySearchCondition> = {
      ...common,
      subjectCondition: {}
    };

    if (params.subjectIds) {
      condition.subjectCondition.subjectIds = Array.isArray(params.subjectIds) ? [...params.subjectIds] : [params.subjectIds];
    }
    if (params.subjectAndFieldIds) {
      condition.subjectCondition.subjectAndFieldIds = Array.isArray(params.subjectAndFieldIds)
        ? [...params.subjectAndFieldIds]
        : [params.subjectAndFieldIds];
    }
    if (params.categories) {
      condition.subjectCondition.categories = Array.isArray(params.categories) ? [...params.categories] : [params.categories];
    }
    if (params.keywords) {
      condition.subjectCondition.keywords = Array.isArray(params.keywords) ? [...params.keywords] : [params.keywords];
    }

    return condition;
  }

  /** 印刷画面用の queryParams を生成 */
  static encodeSearchProblemsCondition(
    condition: SearchProblemsCondition<any>
  ): EnglishSearchProblemsConditionQueryParams | ScienceSearchProblemsConditionQueryParams | HistorySearchProblemsConditionQueryParams {
    switch (condition.subjectId) {
      case SubjectId.ENGLISH:
        return QueryParamsMapper.encodeEnglishSearchProblemsCondition(condition);
      case SubjectId.MATH:
      case SubjectId.PHYSICS:
      case SubjectId.CHEMISTRY:
      case SubjectId.BIOLOGY:
      case SubjectId.GEOGRAPHY:
      case SubjectId.POLITICAL_ECONOMY:
        return QueryParamsMapper.encodeScienceSearchProblemsCondition(condition);
      case SubjectId.NATIONAL_LANGUAGE:
        return QueryParamsMapper.encodeNationalLanguageSearchProblemsCondition(condition);
      case SubjectId.JAPANESE_HISTORY:
      case SubjectId.WORLD_HISTORY:
        return QueryParamsMapper.encodeHistorySearchProblemsCondition(condition);
      default:
        throw GeneralError.invalidArguments(`不明な subject id が指定されました: ${condition.subjectId}`);
    }
  }

  /** 印刷画面用の queryParams から復元 */
  static decodeSearchProblemsConditionQueryParams(
    params: Partial<EnglishSearchProblemsConditionQueryParams | ScienceSearchProblemsConditionQueryParams>
  ): SearchProblemsCondition<EnglishSearchCondition | ScienceSearchCondition> | undefined {
    switch (params.subjectId) {
      case SubjectId.ENGLISH:
        return QueryParamsMapper.decodeEnglishSearchProblemsConditionQueryParams(params);
      case SubjectId.MATH:
      case SubjectId.PHYSICS:
      case SubjectId.CHEMISTRY:
      case SubjectId.BIOLOGY:
      case SubjectId.GEOGRAPHY:
      case SubjectId.POLITICAL_ECONOMY:
        return QueryParamsMapper.decodeScienceSearchProblemsConditionQueryParams(params);
      case SubjectId.NATIONAL_LANGUAGE:
        return QueryParamsMapper.decodeNationalLanguageSearchProblemsConditionQueryParams(params);
      case SubjectId.JAPANESE_HISTORY:
      case SubjectId.WORLD_HISTORY:
        return QueryParamsMapper.decodeHistorySearchProblemsConditionQueryParams(params);
      default:
        Log.warn('QueryParamsMapper', `不明な subject Id が指定されました: ${params.subjectId}`);
        return undefined;
    }
  }

  /** 印刷画面用の queryParams を生成 */
  static encodeEnglishSearchProblemsCondition(
    condition: SearchProblemsCondition<EnglishSearchCondition>
  ): EnglishSearchProblemsConditionQueryParams {
    const source = QueryParamsMapper.encodeEnglishSearchCondition(condition);
    const params: EnglishSearchProblemsConditionQueryParams = {
      ...source,
      sortType: condition.sortType
    };
    return params;
  }

  /** 印刷画面用の queryParams を生成 */
  static encodeScienceSearchProblemsCondition(
    condition: SearchProblemsCondition<ScienceSearchCondition>
  ): ScienceSearchProblemsConditionQueryParams {
    const source = QueryParamsMapper.encodeScienceSearchCondition(condition);
    const params: ScienceSearchProblemsConditionQueryParams = {
      ...source,
      sortType: condition.sortType
    };
    return params;
  }

  /** 印刷画面用の queryParams を生成 */
  static encodeNationalLanguageSearchProblemsCondition(
    condition: SearchProblemsCondition<NationalLanguageSearchCondition>
  ): NationalLanguageSearchProblemsConditionQueryParams {
    const source = QueryParamsMapper.encodeNationalLanguageSearchCondition(condition);
    const params: NationalLanguageSearchProblemsConditionQueryParams = {
      ...source,
      sortType: condition.sortType
    };
    return params;
  }

  /** 印刷画面用の queryParams を生成 */
  static encodeHistorySearchProblemsCondition(
    condition: SearchProblemsCondition<HistorySearchCondition>
  ): HistorySearchProblemsConditionQueryParams {
    const source = QueryParamsMapper.encodeHistorySearchCondition(condition);
    const params: HistorySearchProblemsConditionQueryParams = {
      ...source,
      sortType: condition.sortType
    };
    return params;
  }

  /**
   * 印刷画面用の queryParams から復元
   * mode は 'print' に固定されるので注意
   */
  static decodeEnglishSearchProblemsConditionQueryParams(
    params: Partial<EnglishSearchProblemsConditionQueryParams>
  ): SearchProblemsCondition<EnglishSearchCondition> | undefined {
    const englishCondition = QueryParamsMapper.decodeEnglishSearchConditionParams(params);
    if (!englishCondition) return undefined;

    const condition: SearchProblemsCondition<EnglishSearchCondition> = {
      ...englishCondition,
      sortType: params.sortType
    };
    return condition;
  }

  /**
   * 印刷画面用の queryParams から復元
   * mode は 'print' に固定されるので注意
   */
  static decodeScienceSearchProblemsConditionQueryParams(
    params: Partial<ScienceSearchProblemsConditionQueryParams>
  ): SearchProblemsCondition<ScienceSearchCondition> | undefined {
    const scienceCondition = QueryParamsMapper.decodeScienceSearchConditionParams(params);
    if (!scienceCondition) return undefined;

    const condition: SearchProblemsCondition<ScienceSearchCondition> = {
      ...scienceCondition,
      sortType: params.sortType
    };
    return condition;
  }

  /**
   * 印刷画面用の queryParams から復元
   * mode は 'print' に固定されるので注意
   */
  static decodeNationalLanguageSearchProblemsConditionQueryParams(
    params: Partial<NationalLanguageSearchProblemsConditionQueryParams>
  ): SearchProblemsCondition<NationalLanguageSearchCondition> | undefined {
    const nationalLanguageCondition = QueryParamsMapper.decodeNationalLanguageSearchConditionParams(params);
    if (!nationalLanguageCondition) return undefined;

    const condition: SearchProblemsCondition<NationalLanguageSearchCondition> = {
      ...nationalLanguageCondition,
      sortType: params.sortType
    };
    return condition;
  }

  /**
   * 印刷画面用の queryParams から復元
   * mode は 'print' に固定されるので注意
   */
  static decodeHistorySearchProblemsConditionQueryParams(
    params: Partial<HistorySearchProblemsConditionQueryParams>
  ): SearchProblemsCondition<HistorySearchCondition> | undefined {
    const historyCondition = QueryParamsMapper.decodeHistorySearchConditionParams(params);
    if (!historyCondition) return undefined;

    const condition: SearchProblemsCondition<HistorySearchCondition> = {
      ...historyCondition,
      sortType: params.sortType
    };
    return condition;
  }

  /** Visible for testing */
  static encodeCommonSearchCondition(source: Partial<CommonSearchCondition>): CommonSearchCondition {
    const condition: CommonSearchCondition = {
      subjectId: source.subjectId,
      startYear: source.startYear,
      endYear: source.endYear
    };
    if (source.hasExternalData) condition.hasExternalData = true;
    if (source.hasWordData) condition.hasWordData = true;
    if (source.isThinking) condition.isThinking = true;
    if (source.levels && source.levels.length !== 0) condition.levels = [...source.levels];
    if (source.universityTypeIds && source.universityTypeIds.length !== 0) {
      condition.universityTypeIds = [...source.universityTypeIds];
    }
    if (source.areaIds && source.areaIds.length !== 0) condition.areaIds = [...source.areaIds];
    if (source.universityIds && source.universityIds.length !== 0) {
      condition.universityIds = [...source.universityIds];
    }
    if (source.departmentCategoryId) condition.departmentCategoryId = source.departmentCategoryId;
    if (source.excludeAnswered) condition.excludeAnswered = true;

    return condition;
  }

  /** Visible for testing */
  static decodeCommonSearchCondition(source: Partial<CommonSearchCondition>): CommonSearchCondition | undefined {
    if (!source.subjectId) return undefined;

    const condition: CommonSearchCondition = {
      subjectId: source.subjectId
    };

    if (source.startYear) condition.startYear = Number(source.startYear);
    if (source.endYear) condition.endYear = Number(source.endYear);

    if (source.hasExternalData) condition.hasExternalData = true;
    if (source.hasWordData) condition.hasWordData = true;
    if (source.isThinking) condition.isThinking = true;
    if (source.levels != null) {
      condition.levels = Array.isArray(source.levels) ? source.levels.map(level => Number(level)) : [Number(source.levels)];
    }
    if (source.universityTypeIds) {
      condition.universityTypeIds = Array.isArray(source.universityTypeIds) ? [...source.universityTypeIds] : [source.universityTypeIds];
    }
    if (source.areaIds) {
      condition.areaIds = Array.isArray(source.areaIds) ? [...source.areaIds] : [source.areaIds];
    }
    if (source.universityIds) {
      condition.universityIds = Array.isArray(source.universityIds) ? [...source.universityIds] : [source.universityIds];
    }
    if (source.departmentCategoryId) condition.departmentCategoryId = source.departmentCategoryId;
    if (source.excludeAnswered) condition.excludeAnswered = source.excludeAnswered;

    return condition;
  }
}
