import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';

import { RootState } from 'src/app/reducers';
import { sendPasswordResetEmail } from 'src/app/actions/auth.actions';
import { dispatchInfoMessage } from 'src/app/actions/core.actions';

export interface PasswordResetDialogDialogData {
  mode: string;
}

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.scss']
})
export class PasswordResetDialogComponent implements OnInit {
  emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<PasswordResetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PasswordResetDialogDialogData
  ) {}

  ngOnInit() {}

  sendEmail() {
    if (this.emailFormControl.invalid) return;

    const email = this.emailFormControl.value;

    this.store.dispatch(sendPasswordResetEmail({ email }));
    this.dialogRef.close();
    this.store.dispatch(dispatchInfoMessage({ message: `${email} にパスワードリセットメールを送信しました` }));
  }

  getModeTitle() {
    let title = '';
    switch (this.data.mode) {
      case 'initial':
        title = '初回サインイン';
        break;
      case 'reset':
        title = 'パスワードをお忘れの場合';
        break;
      default:
        break;
    }
    return title;
  }

  cancel() {
    this.dialogRef.close();
  }
}
