import { Action, createReducer, on } from '@ngrx/store';

import * as CoreActions from '../actions/core.actions';

export const coreFeatureKey = 'core';

export interface State {
  title: string | null;
}

export const initialState: State = {
  title: null
};

const coreReducer = createReducer(
  initialState,
  on(CoreActions.setTitle, (state, { title }) => ({ ...state, title }))
);

export function reducer(state: State | undefined, action: Action) {
  return coreReducer(state, action);
}
