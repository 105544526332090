import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, commonIdUserFeatureKey } from '../../reducers/common-id/common-id-user.reducer';

const selectCommonIdUserState = createFeatureSelector<State>(commonIdUserFeatureKey);

export const getCommonIdUser = createSelector(selectCommonIdUserState, state => state.findCommonIdUser);
export const getCommonIdUserSearching = createSelector(selectCommonIdUserState, state => state.isCommonIdUserSearching);
export const getCommonIdUserNotFoundFlag = createSelector(selectCommonIdUserState, state => state.isCommonIdUserNotFound);
export const getCommonIdWatchedUsers = createSelector(selectCommonIdUserState, state => state.watchedCommonIdUsers);
export const getCommonIdWatchedUsersLoaded = createSelector(getCommonIdWatchedUsers, watchedCommonIdUsers => watchedCommonIdUsers != null);
export const getUpdateCommonIdUserIsActiveResult = createSelector(selectCommonIdUserState, state => state.updateCommonIdUserIsActiveResult);
export const getUpdateCommonIdUserResult = createSelector(selectCommonIdUserState, state => state.updateCommonIdUserResult);
