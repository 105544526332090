import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

import { FirebaseStorageError } from '../errors/firebase-storage-error';
import { SearchLogFileMetadata } from '../models/search-log-file-metadata';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Collection, CallableFunction } from '../resources/app-firebase';
import { AppEvent } from '../models/event-log';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class EventLogService {
  constructor(
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private aff: AngularFireFunctions,
    private http: HttpClient
  ) {}

  findSearchLogFileMetadataList(): Observable<SearchLogFileMetadata[]> {
    return this.afs
      .collection(Collection.SEARCH_LOG_FILE_METADATA)
      .get()
      .pipe(map(snapshot => (snapshot.empty ? [] : snapshot.docs.map(doc => doc.data() as SearchLogFileMetadata))));
  }

  getSearchLogFileObjectUrl(filePath: string, isCRLF: boolean): Observable<string> {
    return this.storage
      .ref(filePath)
      .getDownloadURL()
      .pipe(
        catchError(e => throwError(() => FirebaseStorageError.from(e))),
        switchMap(url =>
          this.http.get(url, { responseType: 'text' }).pipe(
            map(text => {
              const lineFeedReplaced = isCRLF ? text.replace(/\n/g, '\r\n') : text;
              const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
              const blobWithBom = new Blob([bom, lineFeedReplaced], { type: 'text/csv' });
              return URL.createObjectURL(blobWithBom);
            })
          )
        )
      );
  }

  reportAppEvent(event: AppEvent): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.RECORD_APP_EVENT);
    return callable(event);
  }
}
