import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { dispatchInfoMessage } from 'src/app/actions/core.actions';
import { createUsers, initializeCreateUsersState } from 'src/app/actions/user.actions';
import { RootState } from 'src/app/reducers';
import { getCreateUsersResult } from 'src/app/selectors/user.selectors';

import { enter } from '../../../resources/animations';
import { CreateUsersRequest } from 'src/app/models/create-users-request';
import { Log } from 'src/app/utils/log';

@Component({
  selector: 'app-members-import-dialog',
  templateUrl: './members-import-dialog.component.html',
  styleUrls: ['./members-import-dialog.component.scss'],
  animations: [enter]
})
export class MembersImportDialogComponent implements OnInit {
  disabled = false;
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<MembersImportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateUsersRequest
  ) {}

  ngOnInit() {}

  registerMembers() {
    if (!this.data) {
      Log.warn(this.LOG_SOURCE, `一括登録メンバーが設定されていません: `, this.data);
      return;
    }

    this.disableForms();
    this.store.dispatch(
      dispatchInfoMessage({
        message: `メンバーの一括登録を開始しました。`
      })
    );

    this.store.dispatch(createUsers(this.data));

    this.store
      .select(getCreateUsersResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeCreateUsersState());
        if (result.success) {
          Log.debug(this.LOG_SOURCE, 'メンバーの一括登録処理', this.data, result);
          this.dialogRef.close(true);
        } else {
          Log.warn(this.LOG_SOURCE, `メンバーの一括登録処理 エラーコード: ${result.error ? result.error.code : 'none'}`, result.error);
          this.dialogRef.close(false);
        }
        this.enableForms();
      });
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close();
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
