import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';

import { RootState } from '../reducers';
import { getSignedInUser } from '../selectors/auth.selectors';
import { findSignedInUser } from '../actions/auth.actions';
import { Log } from '../utils/log';
import { navigate } from '../actions/core.actions';
import { RoutingPathResolver } from '../app-routing-path-resolver';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private store: Store<RootState>) {}

  canActivate(): Observable<boolean> {
    this.findSignedInUserIfNeeded();

    return this.store.select(getSignedInUser).pipe(
      filter(it => it != null),
      take(1),
      map(user => {
        const isAdmin = user !== 'none' && user.isAdmin === true;

        if (!isAdmin) {
          Log.warn(this.constructor.name, `admin ではありません`);
          this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearch() }));
        }

        return isAdmin;
      })
    );
  }

  private findSignedInUserIfNeeded() {
    this.store
      .select(getSignedInUser)
      .pipe(take(1))
      .subscribe(user => {
        if (user == null) this.store.dispatch(findSignedInUser());
      });
  }
}
