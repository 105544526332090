import { Action, createReducer, on } from '@ngrx/store';

import * as FindUniversityActions from '../actions/find-university.actions';
import { AppError } from '../errors/app-error';
import { FindUniversityResponse } from '../models/find-university-response';

export const findUniversityFeatureKey = 'findUniversity';

export interface FindUniversityResult {
  success: boolean;
  response: FindUniversityResponse | null;
  error: AppError | null;
}

export interface State {
  result: FindUniversityResult;
}

export const initialState: State = {
  result: null
};

const findUniversityReducer = createReducer(
  initialState,

  on(FindUniversityActions.findUniversitySuccess, (state, { response }) => {
    const next: State = {
      ...state,
      result: { success: true, response, error: null }
    };
    return next;
  }),
  on(FindUniversityActions.findUniversityFailure, (state, { error }) => {
    const next: State = {
      ...state,
      result: { success: false, response: null, error }
    };
    return next;
  }),
  on(FindUniversityActions.initializeFindUniversityState, state => {
    const next: State = { ...state, result: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return findUniversityReducer(state, action);
}
