import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { signIn, initializeSignInResult } from 'src/app/actions/auth.actions';
import { getSignInResult } from 'src/app/selectors/auth.selectors';
import { Log } from 'src/app/utils/log';
import { navigate, setBrowserTitle } from 'src/app/actions/core.actions';
import { fadeInOut } from 'src/app/resources/animations';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { DIALOG_ZERO_PADDING_PANEL_CLASS, META_VIEWPORT_OTHER, PASSWORD_RESET_DIALOG_WIDTH } from 'src/app/resources/config';
import { PasswordResetDialogComponent } from '../password-reset-dialog/password-reset-dialog.component';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  animations: [fadeInOut]
})
export class SignInComponent implements OnInit {
  signInForm: UntypedFormGroup;
  passwordVisible = false;
  loading = false;

  constructor(private store: Store<RootState>, private dialog: MatDialog, private meta: Meta) {
    this.setUpForms();
  }

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.store.dispatch(setBrowserTitle({ subTitle: 'サインイン' }));
  }

  signIn() {
    if (this.signInForm.invalid) return;

    const [email, password] = [this.signInForm.get('email').value, this.signInForm.get('password').value];

    this.loading = true;
    this.store.dispatch(signIn({ email, password }));

    this.store
      .select(getSignInResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        Log.debug(this.constructor.name, 'sign in result: ', result);
        if (result.success) {
          const gotoUrl = localStorage.getItem('btosGotoUrl');
          localStorage.removeItem('btosGotoUrl');
          const queryString = require('querystring');
          const splitUrlParams = gotoUrl ? gotoUrl.split('?') : '';
          const queryParams = splitUrlParams[1] ? queryString.parse(splitUrlParams[1]) : {};
          // ログイン前にアクセスしたURLが存在する場合は該当URLへ遷移させる
          const url = gotoUrl ? splitUrlParams[0] : RoutingPathResolver.resolveSearch();
          this.store.dispatch(navigate({ url, extras: { queryParams, replaceUrl: true } }));

          // const url = RoutingPathResolver.resolveSearch();
          // this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));
        } else {
          this.loading = false;
        }
        this.store.dispatch(initializeSignInResult());
      });
  }

  gotoTop() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveTop() }));
  }

  changePasswordVisibility(change: MatCheckboxChange) {
    this.passwordVisible = change.checked;
  }

  showPasswordResetView(mode) {
    const config: MatDialogConfig = {
      width: PASSWORD_RESET_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: true,
      data: { mode }
    };
    this.dialog.open(PasswordResetDialogComponent, config);
  }

  private setUpForms() {
    this.signInForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required])
    });
  }
}
