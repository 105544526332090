import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as BulkMailActions from '../actions/bulk-mail.action';
import { BulkMailService } from '../services/bulk-mail.service';
import { FirebaseCallableFunctionError } from '../errors/firebase-callable-function-error';

@Injectable()
export class BulkMailEffects {
  sendBulkMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BulkMailActions.sendBulkMail),
      switchMap(action =>
        this.bulkMailService.sendBulkMail(action.title, action.body, action.organizations).pipe(
          map(response => BulkMailActions.sendBulkMailSuccess({ response })),
          catchError(e => of(BulkMailActions.sendBulkMailFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private bulkMailService: BulkMailService) {}
}
