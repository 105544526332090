<div class="button-toggle-paper-bookmark">
  <div class="button-toggle-paper-bookmark-inner">
    <ng-container *ngIf="showSpinner === false; else bookmarkSpiner">
      <!-- OFF -->
      <button
        *ngIf="!isPaperBookmarked"
        class="button text-gray-300"
        mat-icon-button
        [disabled]="disabled"
        (click)="toggle()"
        matTooltip="あとで解くに登録します"
        matTooltipPosition="above"
      >
        <mat-icon aria-hidden="false" aria-label="あとで解くに登録">attach_file</mat-icon>
      </button>

      <!-- ON -->
      <button
        *ngIf="isPaperBookmarked"
        class="button bookmarked"
        mat-icon-button
        [disabled]="disabled"
        (click)="toggle()"
        matTooltip="あとで解くを解除します"
        matTooltipPosition="above"
      >
        <mat-icon class="text-primary" aria-hidden="false" aria-label="あとで解くを解除">attach_file</mat-icon>
      </button>
    </ng-container>
    <ng-template #bookmarkSpiner>
      <div class="progress-24">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
