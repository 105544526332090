import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

import { CallableFunction } from '../resources/app-firebase';
import { SendBulkMailRequest } from '../models/bulk-email-request';
import { SendBulkMailResponse } from '../models/bulk-email-response';

@Injectable({
  providedIn: 'root'
})
export class BulkMailService {
  constructor(private aff: AngularFireFunctions) {}

  sendBulkMail(title: string, body: string, organizations: string[]): Observable<SendBulkMailResponse> {
    const req: SendBulkMailRequest = { title, body, organizations };
    const callable = this.aff.httpsCallable(CallableFunction.SEND_BULK_MAIL);
    return callable(req);
  }
}
