import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

import { CallableFunction } from '../../resources/app-firebase';
import { CommonIdFindUniversityRequest } from '../../models/common-id/common-id-find-university-request';
import { CommonIdFindUniversityResponse } from '../../models/common-id/common-id-find-university-response';

@Injectable({
  providedIn: 'root'
})
export class CommonIdFindUniversityService {
  constructor(private aff: AngularFireFunctions) {}

  findUniversity(request: CommonIdFindUniversityRequest): Observable<CommonIdFindUniversityResponse> {
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_UNIVERSITY);
    return callable(request);
  }
}
