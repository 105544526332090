import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';

import * as PlaylistActions from '../actions/playlist.actions';
import { PlaylistService } from '../services/playlist.service';
import { FirebaseStorageError } from '../errors/firebase-storage-error';
import { GeneralError } from '../errors/general-error';
import { dispatchAppError } from '../actions/core.actions';
import { FirestoreError } from '../errors/firestore-error';

@Injectable()
export class PlaylistEffects {
  private LOG_SOURCE = this.constructor.name;

  findPlaylists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findPlaylists),
      switchMap(() =>
        this.playlistService.findPlaylists().pipe(
          map(playlists => PlaylistActions.findPlaylistsSuccess({ playlists })),
          catchError(e =>
            of(PlaylistActions.findPlaylistsFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) }))
          )
        )
      )
    )
  );

  findEnglishPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findEnglishPlaylistProblems),
      switchMap(action =>
        this.playlistService.findEnglishPlaylistProblems(action.playlistId).pipe(
          map(englishPlaylistProblems => PlaylistActions.findEnglishPlaylistProblemsSuccess({ englishPlaylistProblems })),
          catchError(e =>
            of(
              PlaylistActions.findEnglishPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findPlaylistProblemIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findPlaylistProblemIds),
      switchMap(action =>
        this.playlistService.findPlyaListProblemIds(action.subjectId, action.playlistId).pipe(
          map(playlistProblemIds => PlaylistActions.findPlaylistProblemIdsSuccess({ playlistProblemIds })),
          catchError(e =>
            of(
              PlaylistActions.findPlaylistProblemIdsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findMathPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findMathPlaylistProblems),
      switchMap(action =>
        this.playlistService.findMathPlaylistProblems(action.playlistId).pipe(
          map(mathPlaylistProblems => PlaylistActions.findMathPlaylistProblemsSuccess({ mathPlaylistProblems })),
          catchError(e =>
            of(
              PlaylistActions.findMathPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findNationalLanguagePlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findNationalLanguagePlaylistProblems),
      switchMap(action =>
        this.playlistService.findNationalLanguagePlaylistProblems(action.playlistId).pipe(
          map(nationalLanguagePlaylistProblems =>
            PlaylistActions.findNationalLanguagePlaylistProblemsSuccess({ nationalLanguagePlaylistProblems })
          ),
          catchError(e =>
            of(
              PlaylistActions.findNationalLanguagePlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findPhysicsPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findPhysicsPlaylistProblems),
      switchMap(action =>
        this.playlistService.findPhysicsPlaylistProblems(action.playlistId).pipe(
          map(physicsPlaylistProblems => PlaylistActions.findPhysicsPlaylistProblemsSuccess({ physicsPlaylistProblems })),
          catchError(e =>
            of(
              PlaylistActions.findPhysicsPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findChemistryPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findChemistryPlaylistProblems),
      switchMap(action =>
        this.playlistService.findChemistryPlaylistProblems(action.playlistId).pipe(
          map(chemistryPlaylistProblems => PlaylistActions.findChemistryPlaylistProblemsSuccess({ chemistryPlaylistProblems })),
          catchError(e =>
            of(
              PlaylistActions.findChemistryPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findBiologyPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findBiologyPlaylistProblems),
      switchMap(action =>
        this.playlistService.findBiologyPlaylistProblems(action.playlistId).pipe(
          map(biologyPlaylistProblems => PlaylistActions.findBiologyPlaylistProblemsSuccess({ biologyPlaylistProblems })),
          catchError(e =>
            of(
              PlaylistActions.findBiologyPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findJapaneseHistoryPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findJapaneseHistoryPlaylistProblems),
      switchMap(action =>
        this.playlistService.findJapaneseHistoryPlaylistProblems(action.playlistId).pipe(
          map(japaneseHistoryPlaylistProblems =>
            PlaylistActions.findJapaneseHistoryPlaylistProblemsSuccess({ japaneseHistoryPlaylistProblems })
          ),
          catchError(e =>
            of(
              PlaylistActions.findJapaneseHistoryPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findWorldHistoryPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findWorldHistoryPlaylistProblems),
      switchMap(action =>
        this.playlistService.findWorldHistoryPlaylistProblems(action.playlistId).pipe(
          map(worldHistoryPlaylistProblems => PlaylistActions.findWorldHistoryPlaylistProblemsSuccess({ worldHistoryPlaylistProblems })),
          catchError(e =>
            of(
              PlaylistActions.findWorldHistoryPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findGeographyPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findGeographyPlaylistProblems),
      switchMap(action =>
        this.playlistService.findGeographyPlaylistProblems(action.playlistId).pipe(
          map(geographyPlaylistProblems => PlaylistActions.findGeographyPlaylistProblemsSuccess({ geographyPlaylistProblems })),
          catchError(e =>
            of(
              PlaylistActions.findGeographyPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  findPoliticalEconomyPlaylistProblems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.findPoliticalEconomyPlaylistProblems),
      switchMap(action =>
        this.playlistService.findPoliticalEconomyPlaylistProblems(action.playlistId).pipe(
          map(politicalEconomyPlaylistProblems =>
            PlaylistActions.findPoliticalEconomyPlaylistProblemsSuccess({ politicalEconomyPlaylistProblems })
          ),
          catchError(e =>
            of(
              PlaylistActions.findPoliticalEconomyPlaylistProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })
            )
          )
        )
      )
    )
  );

  resolvePdfObjectUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlaylistActions.resolvePdfObjectUrl),
      mergeMap(action =>
        this.playlistService.getPdfObjectUrl(action.pdfPath).pipe(
          map(objectUrl => PlaylistActions.resolvePdfObjectUrlSuccess({ pdfPath: action.pdfPath, objectUrl })),
          catchError(e => {
            const error = e instanceof FirebaseStorageError ? e : GeneralError.unknown(e);
            return of(
              PlaylistActions.resolvePdfObjectUrlFailure({ pdfPath: action.pdfPath }),
              dispatchAppError({ source: this.LOG_SOURCE, error })
            );
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private playlistService: PlaylistService) {}
}
