<mat-form-field class="text-sm w-full example-full-width" appearance="fill" appearance="outline" floatLabel="always">
  <mat-label>割当するメンバーを選択</mat-label>
  <mat-chip-list #chipList (click)="openPanel()">
    <mat-chip *ngFor="let selectedOption of selectedOptions">
      {{ selectedOption.name }}
      <mat-icon matChipRemove (click)="removeChip(selectedOption)">cancel</mat-icon>
    </mat-chip>
    <input
      #memberInput
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      [formControl]="selectControl"
      [matChipInputFor]="chipList"
      [matChipInputAddOnBlur]="true"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let selectableOption of selectableOptions">
      <div (click)="optionClicked($event, selectableOption)">
        <mat-checkbox
          color="primary"
          [checked]="selectableOption.selected"
          (change)="toggleSelection(selectableOption)"
          (click)="$event.stopPropagation()"
          [disabled]="isCheckboxDisabled && !selectableOption.selected"
        >
          {{ selectableOption.name }}
        </mat-checkbox>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
