<div class="root-container">
  <app-print-header></app-print-header>

  <div class="search-result-container" @enter *ngIf="initialized$ | async">
    <div>
      <div class="mat-title">検索結果 ({{ totalProblemCount$ | async }} 件)</div>
    </div>

    <div class="search-condition-container">
      <div class="condition-container">
        <div class="condition-label">科目</div>
        <div>{{ (readableSearchCondition$ | async)?.subject }}</div>
      </div>

      <div class="condition-container">
        <div class="condition-label">問題・解答データ</div>
        <div>{{ (readableSearchCondition$ | async)?.hasData }}</div>
      </div>

      <!-- 英語の検索条件 -->
      <ng-container *ngIf="(currentSubjectId$ | async) === ENGLISH_SUBJECT_ID">
        <div class="condition-container">
          <div class="condition-label">大問分野</div>
          <div>{{ (readableEnglishCondition$ | async)?.fields }}</div>
        </div>

        <div class="condition-container">
          <div class="condition-label">小問形式</div>
          <div>{{ (readableEnglishCondition$ | async)?.subTypes }}</div>
        </div>

        <div class="condition-container">
          <div class="condition-label">長文-英文形態</div>
          <div>{{ (readableEnglishCondition$ | async)?.longSentenceTypes }}</div>
        </div>

        <div class="condition-container">
          <div class="condition-label">長文-英単語数</div>
          <div>{{ (readableEnglishCondition$ | async)?.longSentenceWordCountRange }}</div>
        </div>

        <div class="condition-container">
          <div class="condition-label">長文-キーワード</div>
          <div>{{ (readableEnglishCondition$ | async)?.longSentenceKeywords }}</div>
        </div>
      </ng-container>

      <!-- 数学の検索条件 -->
      <ng-container *ngIf="(currentSubjectId$ | async) === MATH_SUBJECT_ID">
        <div class="condition-container">
          <div class="condition-label">分野</div>
          <div class="selected-categories">
            <app-selected-categories
              [staticScienceData]="staticMathData$ | async"
              [scienceSearchCondition]="mathSearchCondition$ | async"
            ></app-selected-categories>
          </div>
        </div>
      </ng-container>

      <!-- 国語の検索条件 -->
      <ng-container *ngIf="(currentSubjectId$ | async) === NATIONAL_LANGUAGE_SUBJECT_ID">
        <div class="condition-container">
          <div class="condition-label">作者・作品名・要旨キーワード</div>
          <div>{{ (readableNationalLanguageCondition$ | async)?.keywords }}</div>
        </div>
        <div class="category-container">
          <div class="category-label">現代文</div>
          <div class="unit-container">
            <div class="condition-container">
              <div class="national-language-category-condition-label">大問ジャンル</div>
              <div>{{ (readableNationalLanguageCondition$ | async)?.categories[0]?.genres.join(', ') }}</div>
            </div>
            <div class="condition-container">
              <div class="national-language-category-condition-label">小問内容</div>
              <div>{{ (readableNationalLanguageCondition$ | async)?.categories[0]?.contents.join(', ') }}</div>
            </div>
          </div>
        </div>
        <div class="category-container">
          <div class="category-label">古文</div>
          <div class="unit-container">
            <div class="condition-container">
              <div class="national-language-category-condition-label">大問ジャンル</div>
              <div>{{ (readableNationalLanguageCondition$ | async)?.categories[1]?.genres.join(', ') }}</div>
            </div>
            <div class="condition-container">
              <div class="national-language-category-condition-label">小問内容</div>
              <div>{{ (readableNationalLanguageCondition$ | async)?.categories[1]?.contents.join(', ') }}</div>
            </div>
          </div>
        </div>
        <div class="category-container">
          <div class="category-label">漢文</div>
          <div class="unit-container">
            <div class="condition-container">
              <div class="national-language-category-condition-label">大問ジャンル</div>
              <div>{{ (readableNationalLanguageCondition$ | async)?.categories[2]?.genres.join(', ') }}</div>
            </div>
            <div class="condition-container">
              <div class="national-language-category-condition-label">小問内容</div>
              <div>{{ (readableNationalLanguageCondition$ | async)?.categories[2]?.contents.join(', ') }}</div>
            </div>
          </div>
        </div>
        <div class="condition-container">
          <div class="condition-label">素材文の文字数</div>
          <div>{{ (readableNationalLanguageCondition$ | async)?.wordCountRange }}</div>
        </div>
      </ng-container>

      <!-- 物理の検索条件 -->
      <ng-container *ngIf="(currentSubjectId$ | async) === PHYSICS_SUBJECT_ID">
        <div class="condition-container">
          <div class="condition-label">分野</div>
          <div class="selected-categories">
            <app-selected-categories
              [staticScienceData]="staticPhysicsData$ | async"
              [scienceSearchCondition]="physicsSearchCondition$ | async"
            ></app-selected-categories>
          </div>
        </div>
      </ng-container>

      <!-- 化学の検索条件 -->
      <ng-container *ngIf="(currentSubjectId$ | async) === CHEMISTRY_SUBJECT_ID">
        <div class="condition-container">
          <div class="condition-label">分野</div>
          <div class="selected-categories">
            <app-selected-categories
              [staticScienceData]="staticChemistryData$ | async"
              [scienceSearchCondition]="chemistrySearchCondition$ | async"
            ></app-selected-categories>
          </div>
        </div>
      </ng-container>

      <!-- 生物の検索条件 -->
      <ng-container *ngIf="(currentSubjectId$ | async) === BIOLOGY_SUBJECT_ID">
        <div class="condition-container">
          <div class="condition-label">分野</div>
          <div class="selected-categories">
            <app-selected-categories
              [staticScienceData]="staticBiologyData$ | async"
              [scienceSearchCondition]="biologySearchCondition$ | async"
            ></app-selected-categories>
          </div>
        </div>
      </ng-container>

      <!-- 日本史の検索条件 -->
      <ng-container *ngIf="(currentSubjectId$ | async) === JAPANESE_HISTORY_SUBJECT_ID">
        <div class="condition-container">
          <div class="condition-label">分野</div>
          <div class="selected-categories">
            <app-selected-categories
              [staticHistoryData]="staticJapaneseHistoryData$ | async"
              [historySearchCondition]="japaneseHistorySearchCondition$ | async"
            ></app-selected-categories>
          </div>
        </div>
        <div class="condition-container">
          <div class="condition-label">分野・単元・出題テーマ</div>
          <div>{{ (readableJapaneseHistoryCondition$ | async)?.keywords }}</div>
        </div>
      </ng-container>

      <!-- 世界史の検索条件 -->
      <ng-container *ngIf="(currentSubjectId$ | async) === WORLD_HISTORY_SUBJECT_ID">
        <div class="condition-container">
          <div class="condition-label">分野</div>
          <div class="selected-categories">
            <app-selected-categories
              [staticHistoryData]="staticWorldHistoryData$ | async"
              [historySearchCondition]="worldHistorySearchCondition$ | async"
            ></app-selected-categories>
          </div>
        </div>
        <div class="condition-container">
          <div class="condition-label">分野・単元・出題テーマ</div>
          <div>{{ (readableWorldHistoryCondition$ | async)?.keywords }}</div>
        </div>
      </ng-container>

      <!-- 地理の検索条件 -->
      <ng-container *ngIf="(currentSubjectId$ | async) === GEOGRAPHY_SUBJECT_ID">
        <div class="condition-container">
          <div class="condition-label">分野</div>
          <div class="selected-categories">
            <app-selected-categories
              [staticScienceData]="staticGeographyData$ | async"
              [scienceSearchCondition]="geographySearchCondition$ | async"
            ></app-selected-categories>
          </div>
        </div>
      </ng-container>

      <!-- 政治経済の検索条件 -->
      <ng-container *ngIf="(currentSubjectId$ | async) === POLITICAL_ECONOMY_SUBJECT_ID">
        <div class="condition-container">
          <div class="condition-label">分野</div>
          <div class="selected-categories">
            <app-selected-categories
              [staticScienceData]="staticPoliticalEconomyData$ | async"
              [scienceSearchCondition]="politicalEconomySearchCondition$ | async"
            ></app-selected-categories>
          </div>
        </div>
      </ng-container>

      <!-- 共通の検索条件 -->
      <div class="condition-container">
        <div class="condition-label">問題画像</div>
        <div>{{ (readableSearchCondition$ | async)?.hasExternalData }}</div>
      </div>

      <div class="condition-container">
        <div class="condition-label">Wordファイル</div>
        <div>{{ (readableSearchCondition$ | async)?.hasWordData }}</div>
      </div>

      <div class="condition-container">
        <div class="condition-label">思考力問題</div>
        <div>{{ (readableSearchCondition$ | async)?.thinking }}</div>
      </div>

      <div class="condition-container">
        <div class="condition-label">難易度</div>
        <div>{{ (readableSearchCondition$ | async)?.levels }}</div>
      </div>

      <div class="condition-container">
        <div class="condition-label">年度</div>
        <div>{{ (readableSearchCondition$ | async).yearRange }}</div>
      </div>

      <div class="condition-container">
        <div class="condition-label">大学種別</div>
        <div>{{ (readableSearchCondition$ | async)?.universityTypes }}</div>
      </div>

      <div class="condition-container">
        <div class="condition-label">エリア</div>
        <div>{{ (readableSearchCondition$ | async)?.areas }}</div>
      </div>

      <div class="condition-container">
        <div class="condition-label">大学名</div>
        <div>{{ (readableSearchCondition$ | async)?.universities }}</div>
      </div>

      <div class="condition-container">
        <div class="condition-label">学部系統</div>
        <div>{{ (readableSearchCondition$ | async)?.departmentCategory }}</div>
      </div>

      <div class="condition-container">
        <div class="condition-label">並び替え</div>
        <div>{{ readableSortType$ | async }}</div>
      </div>
    </div>

    <!-- 英語の検索結果 -->
    <div class="sub-container" *ngIf="(currentSubjectId$ | async) === ENGLISH_SUBJECT_ID">
      <div>
        <app-english-problems
          [readableEnglishProblems]="readableEnglishProblems$ | async"
          [longSentenceKeywords]="(englishLongSentenceKeywords$ | async) || []"
          [problemButtonDisabled]="true"
          [wordButtonDisabled]="true"
        ></app-english-problems>
      </div>
    </div>

    <!-- 数学の検索結果 -->
    <div class="sub-container" *ngIf="(currentSubjectId$ | async) === MATH_SUBJECT_ID">
      <div>
        <app-science-problems
          [readableScienceProblems]="readableMathProblems$ | async"
          [problemButtonDisabled]="true"
          [wordButtonDisabled]="true"
        ></app-science-problems>
      </div>
    </div>

    <!-- 国語の検索結果 -->
    <div class="sub-container" *ngIf="(currentSubjectId$ | async) === NATIONAL_LANGUAGE_SUBJECT_ID">
      <div>
        <app-national-language-problems
          [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
          [keywords]="(nationalLanguageSearchCondition$ | async)?.keywords"
          [problemButtonDisabled]="true"
          [wordButtonDisabled]="true"
        ></app-national-language-problems>
      </div>
    </div>

    <!-- 物理の検索結果 -->
    <div class="sub-container" *ngIf="(currentSubjectId$ | async) === PHYSICS_SUBJECT_ID">
      <div>
        <app-science-problems
          [readableScienceProblems]="readablePhysicsProblems$ | async"
          [problemButtonDisabled]="true"
          [wordButtonDisabled]="true"
        ></app-science-problems>
      </div>
    </div>

    <!-- 化学の検索結果 -->
    <div class="sub-container" *ngIf="(currentSubjectId$ | async) === CHEMISTRY_SUBJECT_ID">
      <div>
        <app-science-problems
          [readableScienceProblems]="readableChemistryProblems$ | async"
          [problemButtonDisabled]="true"
          [wordButtonDisabled]="true"
        ></app-science-problems>
      </div>
    </div>

    <!-- 生物の検索結果 -->
    <div class="sub-container" *ngIf="(currentSubjectId$ | async) === BIOLOGY_SUBJECT_ID">
      <div>
        <app-science-problems
          [readableScienceProblems]="readableBiologyProblems$ | async"
          [problemButtonDisabled]="true"
          [wordButtonDisabled]="true"
        ></app-science-problems>
      </div>
    </div>

    <!-- 日本史の検索結果 -->
    <div class="sub-container" *ngIf="(currentSubjectId$ | async) === JAPANESE_HISTORY_SUBJECT_ID">
      <div>
        <app-history-problems
          [readableHistoryProblems]="readableJapaneseHistoryProblems$ | async"
          [keywords]="(japaneseHistorySearchCondition$ | async)?.keywords"
          [problemButtonDisabled]="true"
          [wordButtonDisabled]="true"
        ></app-history-problems>
      </div>
    </div>

    <!-- 世界史の検索結果 -->
    <div class="sub-container" *ngIf="(currentSubjectId$ | async) === WORLD_HISTORY_SUBJECT_ID">
      <div>
        <app-history-problems
          [readableHistoryProblems]="readableWorldHistoryProblems$ | async"
          [keywords]="(worldHistorySearchCondition$ | async)?.keywords"
          [problemButtonDisabled]="true"
          [wordButtonDisabled]="true"
        ></app-history-problems>
      </div>
    </div>

    <!-- 地理の検索結果 -->
    <div class="sub-container" *ngIf="(currentSubjectId$ | async) === GEOGRAPHY_SUBJECT_ID">
      <div>
        <app-science-problems
          [readableScienceProblems]="readableGeographyProblems$ | async"
          [problemButtonDisabled]="true"
          [wordButtonDisabled]="true"
        ></app-science-problems>
      </div>
    </div>

    <!-- 政治経済の検索結果 -->
    <div class="sub-container" *ngIf="(currentSubjectId$ | async) === POLITICAL_ECONOMY_SUBJECT_ID">
      <div>
        <app-science-problems
          [readableScienceProblems]="readablePoliticalEconomyProblems$ | async"
          [problemButtonDisabled]="true"
          [wordButtonDisabled]="true"
        ></app-science-problems>
      </div>
    </div>
  </div>
</div>

<!-- 問題取得中のプログレス -->
<div class="progress-48" *ngIf="(initialized$ | async) === false">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
