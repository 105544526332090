<app-main-layout-spacer>
  <app-common-id-sign-out-header></app-common-id-sign-out-header>

  <div class="flex flex-col h-full justify-center">
    <app-wrapper size="xs">
      <div>
        <app-content-row>
          <app-card-content>
            <app-wrapper size="fluid">
              <app-content-row>
                <div class="text-center">
                  <header class="mb-6">
                    <h1 class="text-center text-lg font-bold">ログイン</h1>
                  </header>
                  <button class="h-12" mat-flat-button color="primary" (click)="gotoSearch()">
                    サインイン
                  </button>
                </div>
              </app-content-row>
            </app-wrapper>
          </app-card-content>
        </app-content-row>
      </div>
    </app-wrapper>
  </div>

  <app-common-id-footer></app-common-id-footer>
</app-main-layout-spacer>
