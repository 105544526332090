import { createFeatureSelector, createSelector } from '@ngrx/store';
import { universitySearchFeatureKey, State } from '../reducers/university-search.reducer';

const selectSearchState = createFeatureSelector<State>(universitySearchFeatureKey);

export const getSearchCondition = createSelector(selectSearchState, state => state.searchCondition);

export const getMatchedUniversityCount = createSelector(selectSearchState, state => state.matchedUniversityCount);
export const getUniversityCountSearching = createSelector(selectSearchState, state => state.isUniversityCountSearching);

export const getUniversitySearching = createSelector(selectSearchState, state => state.isUniversitySearching);
export const getUniversities = createSelector(selectSearchState, state => state.matchedUniversities);
