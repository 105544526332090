import { GeneralError } from '../errors/general-error';
import {
  RawSubject,
  RawArea,
  RawDepartmentCategory,
  RawLevel,
  RawUniversityType,
  RawUniversity,
  RawHighSchool,
  RawStaticCommonData,
  StaticCommonData
} from '../models/static-common-data';
import { Subject, DepartmentCategory, Level, Area, UniversityType, University, HighSchool } from '../models/common-data';
import { AppError } from '../errors/app-error';
import {
  EnglishField,
  EnglishSubType,
  EnglishLongSentenceType,
  EnglishLongSentenceWordCount,
  EnglishBunyaSubject,
  EnglishBunyaField,
  EnglishBunyaUnit
} from '../models/english-data';
import {
  RawEnglishField,
  RawEnglishSubType,
  RawEnglishLongSentenceType,
  RawEnglishLongSentenceWordCount,
  RawStaticEnglishData,
  StaticEnglishData,
  RawEnglishBunyaField,
  RawEnglishBunyaSubject,
  RawEnglishBunyaUnit
} from '../models/static-english-data';
import { RawStaticScienceData, StaticScienceData, RawScienceSubject, RawScienceField, RawScienceUnit } from '../models/static-science-data';
import { ScienceSubject, ScienceField, ScienceUnit } from '../models/science-data';
import { RawStaticHistoryData, StaticHistoryData, RawHistorySubject, RawHistoryField, RawHistoryUnit } from '../models/static-history-data';
import { HistorySubject, HistoryField, HistoryUnit } from '../models/history-data';
import {
  RawNationalLanguageField,
  RawNationalLanguageSubject,
  RawNationalLanguageUnit,
  RawStaticNationalLanguageData,
  StaticNationalLanguageData
} from '../models/static-national-language-data';
import { NationalLanguageField, NationalLanguageSubject, NationalLanguageUnit } from '../models/national-language-data';

export class StaticDataMapper {
  // Common Data -----------------------------------------------------------------
  static mapCommonDataFromRaw(raw: Partial<RawStaticCommonData>): StaticCommonData {
    try {
      const data: StaticCommonData = {
        areas: StaticDataMapper.mapAreasFromJsonString(raw.areas),
        departmentCategories: StaticDataMapper.mapDepartmentCategoriesFromJsonString(raw.depCategories),
        levels: StaticDataMapper.mapLevelsFromJsonString(raw.levels),
        subjects: StaticDataMapper.mapSubjectsFromJsonString(raw.subjects),
        universityTypes: StaticDataMapper.mapUniversityTypesFromJsonString(raw.univTypes),
        universities: StaticDataMapper.mapUniversitiesFromJsonString(raw.univs),
        highSchools: StaticDataMapper.mapHighSchoolsFromJsonString(raw.highs)
      };
      return data;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapCommonDataFromRaw: データの変換に失敗しました: ${e}`);
    }
  }

  static mapAreasFromJsonString(str: string): Area[] {
    try {
      const source: RawArea[] = JSON.parse(str);
      return source.map<Area>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapAreaFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapAreasFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapDepartmentCategoriesFromJsonString(str: string): DepartmentCategory[] {
    try {
      const source: RawDepartmentCategory[] = JSON.parse(str);
      return source.map<DepartmentCategory>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapDepartmentCategoriesFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapDepartmentCategoriesFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapLevelsFromJsonString(str: string): Level[] {
    try {
      const source: RawLevel[] = JSON.parse(str);
      return source.map<Level>(s => {
        if (s.l == null || !s.d) throw GeneralError.invalidArguments(`mapLevelsFromJsonString: データ変換に失敗しました (levels)`);
        return { level: s.l, displayName: s.d };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapLevelsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapSubjectsFromJsonString(str: string): Subject[] {
    try {
      const source: RawSubject[] = JSON.parse(str);
      return source.map<Subject>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapSubjectsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapSubjectsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapUniversityTypesFromJsonString(str: string): UniversityType[] {
    try {
      const source: RawUniversityType[] = JSON.parse(str);
      return source.map<UniversityType>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapUniversityTypesFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapUniversityTypesFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapUniversitiesFromJsonString(str: string): University[] {
    try {
      const source: RawUniversity[] = JSON.parse(str);
      return source.map<University>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapUniversitiesFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapUniversitiesFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapHighSchoolsFromJsonString(str: string): HighSchool[] {
    try {
      const source: RawHighSchool[] = JSON.parse(str);
      return source.map<HighSchool>(s => {
        if (!s.i || !s.n || !s.p) throw GeneralError.invalidArguments(`mapHighSchoolsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n, prefectureId: s.p };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapHighSchoolsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  // English Data -----------------------------------------------------------------
  static mapEnglishDataFromRaw(raw: RawStaticEnglishData): StaticEnglishData {
    try {
      const data: StaticEnglishData = {
        fields: StaticDataMapper.mapEnglishFieldsFromJsonString(raw.fields),
        subTypes: StaticDataMapper.mapEnglishSubTypesFromJsonString(raw.subTypes),
        longSentenceTypes: StaticDataMapper.mapEnglishLongSentenceTypesFromJsonString(raw.lsTypes),
        longSentenceWordCount: StaticDataMapper.mapEnglishLongSentenceWordCountFromJsonString(raw.wordCount),
        bunyaSubjects: StaticDataMapper.mapEnglishBunyaSubjectsFromJsonString(raw.bunyaSubjects),
        bunyaFields: StaticDataMapper.mapEnglishBunyaFieldsFromJsonString(raw.bunyaFields),
        bunyaUnits: StaticDataMapper.mapEnglishBunyaUnitsFromJsonString(raw.bunyaUnits)
      };
      return data;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapEnglishDataFromRaw: データの変換に失敗しました: ${e}`);
    }
  }

  static mapEnglishFieldsFromJsonString(str: string): EnglishField[] {
    try {
      const source: RawEnglishField[] = JSON.parse(str);
      return source.map<EnglishField>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapEnglishFieldsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapEnglishFieldsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapEnglishSubTypesFromJsonString(str: string): EnglishSubType[] {
    try {
      const source: RawEnglishSubType[] = JSON.parse(str);
      return source.map<EnglishSubType>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapEnglishSubTypesFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapEnglishSubTypesFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapEnglishLongSentenceTypesFromJsonString(str: string): EnglishLongSentenceType[] {
    try {
      const source: RawEnglishLongSentenceType[] = JSON.parse(str);
      return source.map<EnglishLongSentenceType>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapEnglishLongSentenceTypesFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapEnglishLongSentenceTypesFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapEnglishLongSentenceWordCountFromJsonString(str: string): EnglishLongSentenceWordCount {
    try {
      const source: RawEnglishLongSentenceWordCount = JSON.parse(str);
      if (!source.l || !source.h || source.l.length === 0 || source.h.length === 0) {
        throw GeneralError.invalidArguments(`mapEnglishLongSentenceWordCountFromJsonString: パラメータが不正です`);
      }
      return { lowCounts: source.l, highCounts: source.h };
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapEnglishLongSentenceWordCountFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapEnglishBunyaSubjectsFromJsonString(str: string): EnglishBunyaSubject[] {
    try {
      const source: RawEnglishBunyaSubject[] = JSON.parse(str);
      return source.map<EnglishBunyaSubject>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapEnglishBunyaSubjectsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapEnglishBunyaSubjectsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapEnglishBunyaFieldsFromJsonString(str: string): EnglishBunyaField[] {
    try {
      const source: RawEnglishBunyaField[] = JSON.parse(str);
      return source.map<EnglishBunyaField>(s => {
        if (!s.i || !s.n || !s.si) throw GeneralError.invalidArguments(`mapEnglishBunyaFieldsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n, parentSubjectId: s.si };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapEnglishBunyaFieldsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapEnglishBunyaUnitsFromJsonString(str: string): EnglishBunyaUnit[] {
    try {
      const source: RawEnglishBunyaUnit[] = JSON.parse(str);
      return source.map<EnglishBunyaUnit>(s => {
        if (!s.i || !s.n || !s.fi) throw GeneralError.invalidArguments(`mapEnglishBunyaUnitsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n, parentFieldId: s.fi };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapEnglishBunyaUnitsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  // Science Data -----------------------------------------------------------------
  static mapScienceDataFromRaw(raw: RawStaticScienceData): StaticScienceData {
    try {
      const data: StaticScienceData = {
        subjects: StaticDataMapper.mapScienceSubjectsFromJsonString(raw.subjects),
        fields: StaticDataMapper.mapScienceFieldsFromJsonString(raw.fields),
        units: StaticDataMapper.mapScienceUnitsFromJsonString(raw.units)
      };
      return data;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapScienceDataFromRaw: データ変換に失敗しました: ${e}`);
    }
  }

  static mapScienceSubjectsFromJsonString(str: string): ScienceSubject[] {
    try {
      const source: RawScienceSubject[] = JSON.parse(str);
      return source.map<ScienceSubject>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapScienceSubjectsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapScienceSubjectsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapScienceFieldsFromJsonString(str: string): ScienceField[] {
    try {
      const source: RawScienceField[] = JSON.parse(str);
      return source.map<ScienceField>(s => {
        if (!s.i || !s.n || !s.si) throw GeneralError.invalidArguments(`mapScienceFieldsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n, parentSubjectId: s.si };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapScienceFieldsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapScienceUnitsFromJsonString(str: string): ScienceUnit[] {
    try {
      const source: RawScienceUnit[] = JSON.parse(str);
      return source.map<ScienceUnit>(s => {
        if (!s.i || !s.n || !s.fi) throw GeneralError.invalidArguments(`mapScienceUnitsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n, parentFieldId: s.fi };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapScienceUnitsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  // History Data -----------------------------------------------------------------
  static mapHistoryDataFromRaw(raw: RawStaticHistoryData): StaticHistoryData {
    try {
      const data: StaticHistoryData = {
        subjects: StaticDataMapper.mapHistorySubjectsFromJsonString(raw.subjects),
        fields: StaticDataMapper.mapHistoryFieldsFromJsonString(raw.fields),
        units: StaticDataMapper.mapHistoryUnitsFromJsonString(raw.units)
      };
      return data;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapHistoryDataFromRaw: データ変換に失敗しました: ${e}`);
    }
  }

  static mapHistorySubjectsFromJsonString(str: string): HistorySubject[] {
    try {
      const source: RawHistorySubject[] = JSON.parse(str);
      return source.map<HistorySubject>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapHistorySubjectsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapHistorySubjectsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapHistoryFieldsFromJsonString(str: string): HistoryField[] {
    try {
      const source: RawHistoryField[] = JSON.parse(str);
      return source.map<HistoryField>(s => {
        if (!s.i || !s.n || !s.si) throw GeneralError.invalidArguments(`mapHistoryFieldsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n, parentSubjectId: s.si };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapHistoryFieldsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapHistoryUnitsFromJsonString(str: string): HistoryUnit[] {
    try {
      const source: RawHistoryUnit[] = JSON.parse(str);
      return source.map<HistoryUnit>(s => {
        if (!s.i || !s.n || !s.fi) throw GeneralError.invalidArguments(`mapHistoryUnitsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n, parentFieldId: s.fi };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapHistoryUnitsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  // National Language Data -----------------------------------------------------------------
  static mapNationalLanguageDataFromRaw(raw: RawStaticNationalLanguageData): StaticNationalLanguageData {
    try {
      const data: StaticNationalLanguageData = {
        subjects: StaticDataMapper.mapNationalLanguageSubjectsFromJsonString(raw.subjects),
        fields: StaticDataMapper.mapNationalLanguageFieldsFromJsonString(raw.fields),
        units: StaticDataMapper.mapNationalLanguageUnitsFromJsonString(raw.units)
      };
      return data;
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapNationalLanguageDataFromRaw: データ変換に失敗しました: ${e}`);
    }
  }

  static mapNationalLanguageSubjectsFromJsonString(str: string): NationalLanguageSubject[] {
    try {
      const source: RawNationalLanguageSubject[] = JSON.parse(str);
      return source.map<NationalLanguageSubject>(s => {
        if (!s.i || !s.n) throw GeneralError.invalidArguments(`mapNationalLanguageSubjectsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapNationalLanguageSubjectsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapNationalLanguageFieldsFromJsonString(str: string): NationalLanguageField[] {
    try {
      const source: RawNationalLanguageField[] = JSON.parse(str);
      return source.map<NationalLanguageField>(s => {
        if (!s.i || !s.n || !s.si) throw GeneralError.invalidArguments(`mapNationalLanguageFieldsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n, parentSubjectId: s.si };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapNationalLanguageFieldsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }

  static mapNationalLanguageUnitsFromJsonString(str: string): NationalLanguageUnit[] {
    try {
      const source: RawNationalLanguageUnit[] = JSON.parse(str);
      return source.map<NationalLanguageUnit>(s => {
        if (!s.i || !s.n || !s.fi) throw GeneralError.invalidArguments(`mapNationalLanguageUnitsFromJsonString: パラメータが不正です`);
        return { id: s.i, name: s.n, parentFieldId: s.fi };
      });
    } catch (e) {
      if (e instanceof AppError) throw e;
      throw GeneralError.unknown(`mapNationalLanguageFieldsFromJsonString: データ変換に失敗しました: ${e}`);
    }
  }
}
