import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as CommonIdFindUniversityActions from '../../actions/common-id/common-id-find-university.actions';
import { CommonIdFindUniversityService } from '../../services/common-id/common-id-find-university.service';
import { FirebaseCallableFunctionError } from '../../errors/firebase-callable-function-error';

@Injectable()
export class CommonIdFindUniversityEffects {
  private LOG_SOURCE = this.constructor.name;

  findUniversity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdFindUniversityActions.findUniversity),
      switchMap(action =>
        this.commonIdFindUniversityService.findUniversity(action.request).pipe(
          map(response => CommonIdFindUniversityActions.findUniversitySuccess({ response })),
          catchError(e => of(CommonIdFindUniversityActions.findUniversityFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdFindUniversityService: CommonIdFindUniversityService) {}
}
