<app-main-layout-spacer>
  <app-sign-out-header></app-sign-out-header>

  <app-wrapper size="lg">
    <app-content-row>
      <app-article-content>
        <app-article-content-head>「入試正解デジタル for School」利用規約</app-article-content-head>
        <app-terms-content></app-terms-content>
      </app-article-content>
    </app-content-row>
  </app-wrapper>

  <app-footer></app-footer>
</app-main-layout-spacer>
