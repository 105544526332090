import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-plan-members-item',
  templateUrl: './plan-members-item.component.html',
  styleUrls: ['./plan-members-item.component.scss']
})
export class PlanMembersItemComponent implements OnInit {
  constructor() {}

  @Input() isDeletable = true;

  ngOnInit() {}
}
