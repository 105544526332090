<app-main-layout-spacer>
  <app-common-id-sign-out-header></app-common-id-sign-out-header>

  <app-wrapper size="lg">
    <app-content-row>
      <app-article-content>
        <app-article-content-head>「入試正解デジタル」利用規約</app-article-content-head>
        <app-common-id-terms-content></app-common-id-terms-content>
      </app-article-content>
    </app-content-row>
  </app-wrapper>

  <app-common-id-footer></app-common-id-footer>
</app-main-layout-spacer>
