<app-dialog-basic dialogTitle="お気に入り大学を解除します。" [isLoading]="disabled">
  <div class="pb-4">
    <div>お気に入り大学を解除します。よろしいですか？</div>
  </div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="disabled"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="deleteUniversityBookmark()" [disabled]="disabled">解除</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
