<app-wrapper size="xl">
  <app-content-row>
    <app-card-content [showSeparator]="false">
      <app-wrapper size="fluid">
        <app-content-row>
          <div class="text-center py-36">
            <mat-icon
              class="text-primary"
              aria-hidden="true"
              fontSet="material-icons-outlined"
              style="width:80px;height:80px;font-size: 80px;"
              >sentiment_dissatisfied</mat-icon
            >
            <div *ngIf="(user$ | async).isOrganizationAdmin; then organizationAdminBlock; else userBlock"></div>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<ng-template #organizationAdminBlock>
  <div class="mt-4" #userBlock>
    現在ご利用いただける契約プランがないか、<br />
    あなたがメンバー割当されていないため、この機能は使えません。
  </div>
  <button class="mt-6" mat-flat-button color="primary" (click)="gotoPlans()">プラン・アカウント管理へ</button>
</ng-template>
<ng-template #userBlock>
  <div class="mt-4">
    ご契約プランの利用期間外か、ご契約プランにあなたがメンバー割当されていないため、この機能は使えません。<br />
    詳しくは所属の団体管理ユーザーに確認してください。
  </div>
</ng-template>
