import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';

import { AngularFirestore } from '@angular/fire/compat/firestore';
import { CallableFunction } from '../../resources/app-firebase';
import { AppEvent } from '../../models/event-log';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root'
})
export class CommonIdEventLogService {
  constructor(private afs: AngularFirestore, private storage: AngularFireStorage, private aff: AngularFireFunctions) {}

  reportAppEvent(event: AppEvent): Observable<void> {
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_RECORD_APP_EVENT);
    return callable(event);
  }
}
