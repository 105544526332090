<div class="search-univ" [attr.data-is-search-summary-displayed]="isSearchSummaryDisplayed">
  <app-wrapper size="lg" isFitWithSmallScreen="true">
    <app-content-row>
      <app-card-content [showSeparator]="false">
        <app-card-content-head>
          <mat-icon class="card-content-head-icon">manage_search</mat-icon>
          分野から探す
        </app-card-content-head>
        <app-wrapper size="fluid">
          <div class="flex flex-col md:flex-row gap-6 md:gap-4 md:items-end">
            <div class="grow">
              <mat-form-field class="subject-form-field kamoku-form-field md:w-full" appearance="outline" floatLabel="always">
                <mat-label>科目</mat-label>
                <div class="subject-form-field-select">
                  <mat-select (selectionChange)="onSubjectSelectionChange()" [formControl]="subjectFormControl">
                    <mat-option *ngFor="let subject of selectableSubjects" [value]="subject.id">{{ subject.name }}</mat-option>
                  </mat-select>
                </div>
              </mat-form-field>
            </div>

            <!-- 難易度 -->
            <div class="grow md:min-w-[400px]">
              <div class="mat-caption mb-1">難易度</div>
              <div class="level-select-switches">
                <ul class="level-select-switches-list">
                  <li class="level-select-switches-item" *ngFor="let level of levels">
                    <mat-checkbox color="primary" [(ngModel)]="level.checked" (change)="onLevelChange()">
                      <div class="level-select-switches-stars">
                        <span [class.star-on]="level.level >= 1" [class.star-off]="level.level < 1">★</span>
                        <span [class.star-on]="level.level >= 2" [class.star-off]="level.level < 2">★</span>
                        <span [class.star-on]="level.level >= 3" [class.star-off]="level.level < 3">★</span>
                        <span [class.star-on]="level.level >= 4" [class.star-off]="level.level < 4">★</span>
                      </div>
                      <div class="level-select-switches-label">{{ level.displayName }}</div>
                    </mat-checkbox>
                  </li>
                </ul>
              </div>
            </div>

            <!-- 解答済み -->
            <div class="grow min-w-[200px]">
              <div class="mat-caption mb-1">解答済み</div>
              <div class="md:h-[50px] md:flex md:items-center">
                <mat-checkbox
                  class="ml-1"
                  color="primary"
                  [checked]="isExcludeAnsweredChecked"
                  [value]="isExcludeAnsweredChecked"
                  (change)="onExcludeAnsweredChange($event.checked)"
                >
                  未解答のみ表示
                </mat-checkbox>
              </div>
            </div>
          </div>

          <div class="flex flex-col md:flex-row gap-4 mt-8 mb-4">
            <!-- 分野で絞り込む -->
            <div class="grow narrow-down-col">
              <mat-form-field class="narrow-down-field" appearance="outline" floatLabel="always">
                <mat-label>分野で絞り込む</mat-label>
                <div>
                  <input matInput placeholder="分野を設定する" [value]="selectedCategoryConditionText || ''" />
                </div>
              </mat-form-field>
              <button class="narrow-down-button" mat-flat-button color="primary" (click)="openCategoryDialog()">
                設定
              </button>
            </div>
            <!-- 詳しい検索条件で絞り込む -->
            <div class="grow narrow-down-col">
              <mat-form-field class="narrow-down-field" appearance="outline" floatLabel="always">
                <mat-label>詳しい検索条件で絞り込む</mat-label>
                <div>
                  <input matInput placeholder="詳しい条件を設定する" [value]="selectedDetailedConditionText || ''" />
                </div>
              </mat-form-field>
              <button class="narrow-down-button" mat-flat-button color="primary" (click)="openDetailedSearchConditionDialog()">
                設定
              </button>
            </div>
          </div>
        </app-wrapper>

        <app-wrapper size="xl">
          <div class="py-4 md:py-6 flex flex-col md:flex-row md:items-center md:justify-between" @enter>
            <div class="pr-4">
              <div class="flex items-center text-lg md:text-xl">
                <div class="font-bold">検索結果</div>
                <div class="text-primary font-bold ml-2">
                  {{ (matchedProblemCount$ | async) == null ? '-' : (matchedProblemCount$ | async) }}
                </div>
                <div class="ml-2 mr-2 text-md relative">件</div>
                <div [@inOut]="problemCountSearching$ | async">
                  <mat-spinner [diameter]="24"></mat-spinner>
                </div>
              </div>
            </div>
            <div class="flex mt-4 md:mt-0">
              <button mat-flat-button class="mr-4 bg-gray-50 md:w-36 w-1/2 md:h-12 md:text-base" (click)="resetForms()">
                条件をリセット
              </button>
              <button
                mat-flat-button
                class="mat-primary md:w-36 w-1/2 md:h-12 md:text-base"
                [disabled]="searchButtonDisabled$ | async"
                (click)="showSearchResult()"
              >
                結果を表示
              </button>
            </div>
          </div>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
  </app-wrapper>
</div>

<div class="search-summary" [attr.data-is-search-summary-displayed]="isSearchSummaryDisplayed">
  <app-content-row>
    <app-card-content [showSeparator]="false">
      <app-content-row>
        <app-wrapper size="fluid">
          <div class="text-gray-500 mb-2">
            {{ selectedConditionTextForSp }}
          </div>
          <div class="flex items-center">
            <div class="flex items-center">
              <div class="font-bold">検索結果</div>
              <div class="text-primary font-bold mx-1">
                {{ (matchedProblemCount$ | async) == null ? '-' : (matchedProblemCount$ | async) }}
              </div>
              <div>件</div>
            </div>
            <div class="ml-auto">
              <button mat-flat-button class="bg-gray-50 md:w-36" (click)="onChangeConditions()">
                条件を変更
              </button>
            </div>
          </div>
        </app-wrapper>
      </app-content-row>
    </app-card-content>
  </app-content-row>
</div>
