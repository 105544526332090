<button
  class="button-download-word-file"
  mat-icon-button
  [disabled]="disabled"
  (click)="buttonClick.emit()"
  matTooltip="Wordダウンロード"
  matTooltipPosition="above"
>
  <div class="button-download-word-file-inner">
    <app-icon-word></app-icon-word>
  </div>
</button>
