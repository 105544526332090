<app-dialog-basic dialogTitle="塾・予備校削除" [isLoading]="disabled">
  <div class="pb-4">
    <div>
      <strong>{{ data.jukuName }}</strong> の塾・予備校を削除します。
    </div>
    <div>この操作は取り消せません。削除を実行しますか？</div>
  </div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="disabled"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="deleteJuku()" [disabled]="disabled">削除</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
