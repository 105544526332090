import { AppError } from './app-error';
import { ErrorInfo, FirebaseCallableFunctionErrorInfo } from './error-info';

const errorCodeMapping: { [key: string]: ErrorInfo } = {
  'GE01': FirebaseCallableFunctionErrorInfo.GENERAL_INVALID_ARGUMENT,
  'GE02': FirebaseCallableFunctionErrorInfo.GENERAL_NOT_FOUND,
  'GE98': FirebaseCallableFunctionErrorInfo.GENERAL_INTERNAL,
  'GE99': FirebaseCallableFunctionErrorInfo.GENERAL_UNKNOWN,
  'AU01': FirebaseCallableFunctionErrorInfo.AUTH_UNAUTHENTICATED,
  'AU02': FirebaseCallableFunctionErrorInfo.AUTH_PERMISSION_DENIED,
  'AU03': FirebaseCallableFunctionErrorInfo.AUTH_EMAIL_ALREADY_EXISTS,
  'AU04': FirebaseCallableFunctionErrorInfo.AUTH_INVALID_ARGUMENTS,
  'AU05': FirebaseCallableFunctionErrorInfo.AUTH_INVALID_USER,
  'AU99': FirebaseCallableFunctionErrorInfo.AUTH_UNKNOWN,
  'PE01': FirebaseCallableFunctionErrorInfo.INVALID_START_AT,
  'PE02': FirebaseCallableFunctionErrorInfo.INVALID_END_AT,
  'PE03': FirebaseCallableFunctionErrorInfo.DUPLICATE_EXPIRATION_DATE,
  'PE04': FirebaseCallableFunctionErrorInfo.ALREADY_EXISTS_RESERVE_PLAN,
  'PE05': FirebaseCallableFunctionErrorInfo.FEW_MEMBER_COUNT,
  'PE06': FirebaseCallableFunctionErrorInfo.ALREADY_ASSIGNED,
  'PE07': FirebaseCallableFunctionErrorInfo.ALREADY_ASSIGNED_FOR_OTHER,
  'PE08': FirebaseCallableFunctionErrorInfo.EXCEEDS_ASSIGNED_MEMBERS,
  'PE099': FirebaseCallableFunctionErrorInfo.PLAN_UNKNOWN,
  'BE01': FirebaseCallableFunctionErrorInfo.ALREADY_EXISTS_BOOKMARK,
  'BE02': FirebaseCallableFunctionErrorInfo.NOT_FOUND_BOOKMARK,
  'BE03': FirebaseCallableFunctionErrorInfo.EXCEEDS_BOOKMARKS,
  'JE01': FirebaseCallableFunctionErrorInfo.ALREADY_EXISTS_JUKU_CODE,
  'JE02': FirebaseCallableFunctionErrorInfo.ALREADY_EXISTS_JUKU_NAME,
  'JE03': FirebaseCallableFunctionErrorInfo.NOT_FOUND_JUKU,
  'JE04': FirebaseCallableFunctionErrorInfo.JUKU_CAN_NOT_DELETE,

  'CAE01': FirebaseCallableFunctionErrorInfo.COMMON_ID_AUTH_INCORRECT_ID_TOKEN,
  'CAE02': FirebaseCallableFunctionErrorInfo.COMMON_ID_AUTH_API_NOT_FOUND_USER,
  'CAE03': FirebaseCallableFunctionErrorInfo.COMMON_ID_AUTH_API_INVALID_ARGUMENT,
  'CUBE01': FirebaseCallableFunctionErrorInfo.COMMON_ID_ALREADY_EXISTS_UNIVERSITY_BOOKMARK,
  'CUBE02': FirebaseCallableFunctionErrorInfo.COMMON_ID_NOT_FOUND_UNIVERSITY_BOOKMARK,
  'CUBE03': FirebaseCallableFunctionErrorInfo.COMMON_ID_UNIVERSITY_EXCEEDS_BOOKMARKS,
  'CUBE99': FirebaseCallableFunctionErrorInfo.COMMON_ID_UNIVERSITY_BOOKMARK_UNKNOWN,
  'CPBE01': FirebaseCallableFunctionErrorInfo.COMMON_ID_ALREADY_EXISTS_PAPER_BOOKMARK,
  'CPBE02': FirebaseCallableFunctionErrorInfo.COMMON_ID_NOT_FOUND_PAPER_BOOKMARK,
  'CPBE03': FirebaseCallableFunctionErrorInfo.COMMON_ID_PAPER_EXCEEDS_BOOKMARKS,
  'CPBE99': FirebaseCallableFunctionErrorInfo.COMMON_ID_UNIVERSITY_PAPER_BOOKMARK_UNKNOWN,
  'CPLBE01': FirebaseCallableFunctionErrorInfo.COMMON_ID_ALREADY_EXISTS_PLAYLIST_BOOKMARK,
  'CPLBE02': FirebaseCallableFunctionErrorInfo.COMMON_ID_NOT_FOUND_PLAYLIST_BOOKMARK,
  'CPLBE03': FirebaseCallableFunctionErrorInfo.COMMON_ID_PLAYLIST_EXCEEDS_BOOKMARKS,
  'CPLBE99': FirebaseCallableFunctionErrorInfo.COMMON_ID_PLAYLIST_BOOKMARK_UNKNOWN,
  'CSE01': FirebaseCallableFunctionErrorInfo.COMMON_ID_STRIPE_NOT_MATCH_PURCHASED_USER_ID,
  'CSE02': FirebaseCallableFunctionErrorInfo.COMMON_ID_ALREADY_PURCHASED,
  'CAPBE01': FirebaseCallableFunctionErrorInfo.COMMON_ID_ANSWERED_PROBLEM_EXCEEDS_BOOKMARKS
};

type FunctionsErrorCode = string;

interface CallableFunctionsError extends Error {
  code: FunctionsErrorCode;
  message: string;
  details?: {
    code: string;
    uid: string;
  };
}

export class FirebaseCallableFunctionError extends AppError {
  constructor(info: ErrorInfo, cause?: string | Error) {
    super(info.code, info.message, cause);
    Object.setPrototypeOf(this, FirebaseCallableFunctionError.prototype);
  }

  static from(err: CallableFunctionsError): FirebaseCallableFunctionError {
    if (err.details) {
      const info = errorCodeMapping[err.details.code];
      if (info) return new FirebaseCallableFunctionError(info, err);
    }
    return FirebaseCallableFunctionError.unknown(err);
  }

  static unknown(cause: string | Error): FirebaseCallableFunctionError {
    return new FirebaseCallableFunctionError(FirebaseCallableFunctionErrorInfo.UNKNOWN, cause);
  }
}
