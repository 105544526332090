import { createAction, props } from '@ngrx/store';
import { ToolsDeleteMembersRequest } from '../models/tools-delete-members.request';

export const transferStaticData = createAction('[Tools] Transfer Static Data');
export const transferStaticDataSuccess = createAction('[Tools] Transfer Static Data Success');
export const transferStaticDataFailure = createAction('[Tools] Transfer Static Data Failure');

export const transferPlaylists = createAction('[Tools] Transfer Playlists');
export const transferPlaylistsSuccess = createAction('[Tools] Transfer Playlists Success');
export const transferPlaylistsFailure = createAction('[Tools] Transfer Playlists Failure');

export const commonIdTransferPlaylists = createAction('[Tools] Common Id Transfer Playlists');
export const commonIdTransferPlaylistsSuccess = createAction('[Tools] Common Id Transfer Playlists Success');
export const commonIdTransferPlaylistsFailure = createAction('[Tools] Common Id Transfer Playlists Failure');

export const rotateRawSearchEventGroup = createAction('[Tools] Rotate Raw Search Event Group');
export const rotateRawSearchEventGroupSuccess = createAction('[Tools] Rotate Raw Search Event Group Success');
export const rotateRawSearchEventGroupFailure = createAction('[Tools] Rotate Raw Search Event Group Failure');

export const exportSearchEventLog = createAction('[Tools] Export Search Event Log');
export const exportSearchEventLogSuccess = createAction('[Tools] Export Search Event Log Success');
export const exportSearchEventLogFailure = createAction('[Tools] Export Search Event Log Failure');

export const aggregateDailySearchEventLog = createAction('[Tools] Aggregate Daily Search Event Log', props<{ ymd: string }>());
export const aggregateDailySearchEventLogSuccess = createAction('[Tools] Aggregate Daily Search Event Log Success');
export const aggregateDailySearchEventLogFailure = createAction('[Tools] Aggregate Daily Search Event Log Failure');

export const aggregateWeeklySearchEventLog = createAction('[Tools] Aggregate Weekly Search Event Log', props<{ ymd: string }>());
export const aggregateWeeklySearchEventLogSuccess = createAction('[Tools] Aggregate Weekly Search Event Log Success');
export const aggregateWeeklySearchEventLogFailure = createAction('[Tools] Aggregate Weekly Search Event Log Failure');

export const cleanUpRawSearchEventGroup = createAction('[Tools] Clean Up Raw Search Event Group');
export const cleanUpRawSearchEventGroupSuccess = createAction('[Tools] Clean Up Raw Search Event Group Success');
export const cleanUpRawSearchEventGroupFailure = createAction('[Tools] Clean Up Raw Search Event Group Failure');

export const rotateRawAdminOperationEventGroup = createAction('[Tools] Rotate Raw Admin Operation Event Group');
export const rotateRawAdminOperationEventGroupSuccess = createAction('[Tools] Rotate Raw Admin Operation Event Group Success');
export const rotateRawAdminOperationEventGroupFailure = createAction('[Tools] Rotate Raw Admin Operation Event Group Failure');

export const exportAdminOperationEventLog = createAction('[Tools] Export Admin Operation Event Log');
export const exportAdminOperationEventLogSuccess = createAction('[Tools] Export Admin Operation Event Log Success');
export const exportAdminOperationEventLogFailure = createAction('[Tools] Export Admin Operation Event Log Failure');

export const aggregateDailyAdminOperationEventLog = createAction(
  '[Tools] Aggregate Daily Admin Operation Event Log',
  props<{ ymd: string }>()
);
export const aggregateDailyAdminOperationEventLogSuccess = createAction('[Tools] Aggregate Daily Admin Operation Event Log Success');
export const aggregateDailyAdminOperationEventLogFailure = createAction('[Tools] Aggregate Daily Admin Operation Event Log Failure');

export const aggregateMonthlyAdminOperationEventLog = createAction(
  '[Tools] Aggregate Monthly Admin Operation Event Log',
  props<{ ymd: string }>()
);
export const aggregateMonthlyAdminOperationEventLogSuccess = createAction('[Tools] Aggregate Monthly Admin Operation Event Log Success');
export const aggregateMonthlyAdminOperationEventLogFailure = createAction('[Tools] Aggregate Monthly Admin Operation Event Log Failure');

export const cleanUpRawAdminOperationEventGroup = createAction('[Tools] Clean Up Raw Admin Operation Event Group');
export const cleanUpRawAdminOperationEventGroupSuccess = createAction('[Tools] Clean Up Raw Admin Operation Event Group Success');
export const cleanUpRawAdminOperationEventGroupFailure = createAction('[Tools] Clean Up Raw Admin Operation Event Group Failure');

export const backupFirestore = createAction('[Tools] Backup Firestore');
export const backupFirestoreSuccess = createAction('[Tools] Backup Firestore Success');
export const backupFirestoreFailure = createAction('[Tools] Backup Firestore Failure');

export const checkBookmarkMaxCount = createAction('[Tools] Check Bookmark Max Count');
export const checkBookmarkMaxCountSuccess = createAction('[Tools] Check Bookmark Max Count Success');
export const checkBookmarkMaxCountFailure = createAction('[Tools] Check Bookmark Max Count Failure');

export const checkUniversityBookmarkMaxCount = createAction('[Tools] Check University Bookmark Max Count');
export const checkUniversityBookmarkMaxCountSuccess = createAction('[Tools] Check University Bookmark Max Count Success');
export const checkUniversityBookmarkMaxCountFailure = createAction('[Tools] Check University Bookmark Max Count Failure');

export const checkPaperBookmarkMaxCount = createAction('[Tools] Check Paper Bookmark Max Count');
export const checkPaperBookmarkMaxCountSuccess = createAction('[Tools] Check Paper Bookmark Max Count Success');
export const checkPaperBookmarkMaxCountFailure = createAction('[Tools] Check Paper Bookmark Max Count Failure');

export const checkAnsweredProblemsMaxCount = createAction('[Tools] Check Answered Problems Max Count');
export const checkAnsweredProblemsMaxCountSuccess = createAction('[Tools] Check Answered Problems Max Count Success');
export const checkAnsweredProblemsMaxCountFailure = createAction('[Tools] Check Answered Problems Max Count Failure');

export const checkPlaylistBookmarkMaxCount = createAction('[Tools] Check Playlist Bookmark Max Count');
export const checkPlaylistBookmarkMaxCountSuccess = createAction('[Tools] Check Playlist Bookmark Max Count Success');
export const checkPlaylistBookmarkMaxCountFailure = createAction('[Tools] Check Playlist Bookmark Max Count Failure');

export const deleteMembers = createAction('[Tools] Delete Members', props<ToolsDeleteMembersRequest>());
export const deleteMembersSuccess = createAction('[Tools] Delete Members Success');
export const deleteMembersFailure = createAction('[Tools] Delete Members Failure');
