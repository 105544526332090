import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { initializeSendBulkMailState, sendBulkMail } from './../../../actions/bulk-mail.action';
import { RootState } from 'src/app/reducers';
import { getSendBulkMailResult } from 'src/app/selectors/bulk-mail.selectors';
import { Log } from 'src/app/utils/log';
import { dispatchInfoMessage, dispatchAppError } from 'src/app/actions/core.actions';
import { enter } from '../../../resources/animations';
import { MailReceiverOptions } from '../bulk-mail/bulk-mail.component';
import { DEFAULT_MAIL_RECEIVER_ID } from '../../../resources/config';

export interface BulkMailDialogDialogData {
  title: string;
  body: string;
  organizations: MailReceiverOptions[];
}

@Component({
  selector: 'app-bulk-mail-dialog',
  templateUrl: './bulk-mail-dialog.component.html',
  styleUrls: ['./bulk-mail-dialog.component.scss'],
  animations: [enter]
})
export class BulkMailDialogComponent implements OnInit {
  disabled = false;
  mailReceivers: MailReceiverOptions[];
  selectedOrganizationIds: string[];
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<BulkMailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BulkMailDialogDialogData
  ) {}

  ngOnInit() {
    this.mailReceivers = this.data.organizations;
    this.selectedOrganizationIds = this.mailReceivers
      .filter(receiver => receiver.id !== DEFAULT_MAIL_RECEIVER_ID)
      .map(organization => organization.id);
  }

  send() {
    this.disableForms();
    Log.debug(this.LOG_SOURCE, `一斉メール送信を行います`);

    this.store
      .select(getSendBulkMailResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeSendBulkMailState());
        if (result.success) {
          const message = `一斉メール送信が完了しました`;
          this.store.dispatch(dispatchInfoMessage({ message }));
          Log.debug(this.LOG_SOURCE, `一斉メール送信完了`);
          this.dialogRef.close(true);
        } else {
          Log.error(this.LOG_SOURCE, `一斉メール送信でエラーが発生しました: `, JSON.stringify(result.error));
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error: result.error }));
          this.dialogRef.close(false);
        }

        this.enableForms();
      });

    this.store.dispatch(sendBulkMail({ title: this.data.title, body: this.data.body, organizations: this.selectedOrganizationIds }));
    this.store.dispatch(
      dispatchInfoMessage({
        message: `一斉メール送信をしています…`
      })
    );
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close(false);
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
