// findPurchases ------------------------------------------------------------
import { createAction, props } from '@ngrx/store';
import { CommonIdPurchases } from '../../models/common-id/common-id-purchases';

export const commonIdFindPurchases = createAction('[CommonIdPurchases] CommonId Find Purchases', props<{ userId: string }>());
export const commonIdFindPurchasesSuccess = createAction(
  '[CommonIdPurchases] CommonId Find Purchases Success',
  props<{ purchases: CommonIdPurchases }>()
);
export const commonIdFindPurchasesFailure = createAction('[CommonIdPurchases] CommonId Find Purchases Failure');
export const initializeCommonIdFindPurchasesState = createAction('[CommonIdPurchases] Initialize CommonId Find Purchases State');
