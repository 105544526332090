<app-wrapper size="lg" @enter *ngIf="informations$ | async; else loadingProgress">
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon" aria-hidden="true">notifications_none</mat-icon>
        お知らせ
      </app-card-content-head>
      <app-wrapper size="fluid">
        <ul class="information-list">
          <li class="information-list-item" #informations *ngFor="let information of informations$ | async">
            <a class="information-list-item-hit items-center" (click)="gotoDetail(information.id)">
              <time datetime="2021-08-13">{{ information.date }}</time>
              <span class="information-list-item-tag flex md:grid gap-1">
                <span *ngIf="information.importantFlag" class="bg-primary text-white font-bold">重要</span>
                <span class="bg-gray-50 text-black font-bold">{{ information.categoryName }}</span>
              </span>
              <span *ngIf="information.isNew" class="block pl-4 information-list-item-new font-bold">NEW</span>
              <span class="text-black md:pl-4 information-list-item-title">{{ information.title }}</span>
            </a>
          </li>
        </ul>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<ng-template #loadingProgress>
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>
