import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { Log } from '../../../../utils/log';
import { updateCommonIdUserIsActive, initializeUpdateCommonIdUserIsActiveState } from 'src/app/actions/common-id/common-id-user.actions';
import { getUpdateCommonIdUserIsActiveResult } from 'src/app/selectors/common-id/common-id-user.selectors';
import { dispatchInfoMessage, dispatchAppError } from 'src/app/actions/core.actions';
import { enter } from '../../../../resources/animations';

export interface CommonIdChangeActiveAccountDialogData {
  userId: string;
  commonId: string;
  isActive: boolean;
}

@Component({
  selector: 'app-common-id-change-active-account-dialog',
  templateUrl: './change-active-account-dialog.component.html',
  styleUrls: ['./change-active-account-dialog.component.scss'],
  animations: [enter]
})
export class CommonIdChangeActiveAccountDialogComponent implements OnInit {
  disabled = false;
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<CommonIdChangeActiveAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonIdChangeActiveAccountDialogData
  ) {}

  ngOnInit() {}

  changeActiveAccount() {
    if (!this.data || !this.data.userId || this.data.isActive === undefined || this.data.isActive === null) {
      Log.warn(this.LOG_SOURCE, `dialog.data または data.userId または data.isActive が設定されていません: `, this.data);
      return;
    }

    this.disableForms();

    Log.debug(this.LOG_SOURCE, `アカウント有効／無効の変更をします`);
    this.store
      .select(getUpdateCommonIdUserIsActiveResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeUpdateCommonIdUserIsActiveState());
        if (result.success) {
          const message = `${this.data.commonId} のアカウント有効／無効の変更が完了しました`;
          this.store.dispatch(dispatchInfoMessage({ message }));
          Log.debug(this.LOG_SOURCE, `アカウント有効／無効の変更完了: `, result.updatedUser);
          this.enableForms();
        } else {
          Log.error(this.LOG_SOURCE, `アカウント有効／無効の変更でエラーが発生しました: `, JSON.stringify(result.error));
          this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error: result.error }));
        }

        this.dialogRef.close();
      });
    this.store.dispatch(updateCommonIdUserIsActive({ user: { id: this.data.userId, isActive: !this.data.isActive } }));
    this.store.dispatch(
      dispatchInfoMessage({
        message: `${this.data.commonId} のアカウント有効／無効の変更をしています…`
      })
    );
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close();
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
