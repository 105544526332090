export const CHEER_UP_MESSAGES: string[] = [
  '千里の道も一歩から。一歩一歩進んでいこう！',
  '継続は力なり。引き続き頑張ってください。',
  '蒔かぬ種は生えぬ。今日の頑張りはきっと未来のあなたの糧になります。',
  '七転び八起き。何度転んでもその度に立ち上がれば大丈夫！',
  '為せば成る、為さねば成らぬ何事も。どんなことでもやれば出来る！',
  '高きに登るには低きよりす。出来ることから堅実にやりましょう！',
  'この調子です！',
  '頑張りましたね！',
  '出来なかった問題をしっかり復習して実力アップ！',
  'There is no royal road to learning.　ー学問に王道なしー',
  'I will prepare and someday my chance will come.　ー準備をしよう！いつか自分のチャンスはやってくる。 ー　（エイブラハム・リンカーン ）',
  'Without haste, but without rest.　ー急がずに、だが休まずにー　（ゲーテ）',
  '点滴穿石！コツコツと積み重ねた努力はいずれ大きな力となることでしょう。',
  '日進月歩！少しずつ前に進んでいますね。',
  '百折不撓！ちょっと挫けそうな時は、基礎に戻ってみましょう。',
  'お疲れさまです！',
  'You can make it !',
  'Heaven helps those who help themselves.　ー天は自ら助くる者を助くー',
  'いつも頑張っているあなたを応援しています！',
  '体調に気をつけてくださいね。',
  '天才なんてあるもんか。誰よりも3倍、4倍、5倍勉強する者、それが天才だ。（野口英世）',
  '一念通天！努力は実を結びます。',
  '学んで思わざれば即ち罔し（孔子）。学んだことを自分の頭でしっかり考えて実力アップにつなげましょう。',
  '小さな努力の積み重ねが大きな力になります！',
  '戦いに勝つのは実際のところか必ず勝とうと固く決心した者なのだ（トルストイ）。',
  '之を知る者は、之を好む者に如かず。之を好む者は、之を楽しむ者に如かず（孔子）。受験勉強も楽しんだもの勝ちです！',
  '目が疲れたら遠くを見てみましょう。目をあたためるのもおすすめですよ。',
  '頑張りましたね。体を少し動かすと良い気分転換になりますよ。',
  'In the middle of difficulty lies opportunity.　ー困難の中に、機会がある。 ー　（アインシュタイン）'
];
