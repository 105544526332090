import { createAction, props } from '@ngrx/store';
import { CommonIdUser, CommonIdUserPurchaseData } from '../../models/common-id/common-id-user';
import { CommonIdSavePurchaseRequest } from '../../models/common-id/common-id-save-purchase-request';
import { AppError } from '../../errors/app-error';

export const commonIdSignIn = createAction('[CommonIdAuth] Common Id Sign In', props<{ customToken: string }>());
export const commonIdSignInSuccess = createAction('[CommonIdAuth] Common Id Sign In Success', props<{ user: CommonIdUser }>());
export const commonIdSignInFailure = createAction('[CommonIdAuth] Common Id Sign In Failure');
export const initializeCommonIdSignInResult = createAction('[CommonIdAuth] Initialize Sign In Result');

export const commonIdSignOut = createAction('[CommonIdAuth] Common Id Sign Out');
export const commonIdSignOutSuccess = createAction('[CommonIdAuth] Common Id Sign Out Success');
export const commonIdSignOutFailure = createAction('[CommonIdAuth] Common Id Sign Out Failure');

export const findCommonIdSignedInUser = createAction('[CommonIdAuth] Find Common Id Signed In User');
export const findCommonIdSignedInUserSuccess = createAction(
  '[CommonIdAuth] Find Common Id Signed In User Success',
  props<{ user: CommonIdUser }>()
);
export const findCommonIdSignedInUserFailure = createAction('[CommonIdAuth], Find Common Id Signed In User Failure');

export const commonIdSavePurchase = createAction(
  '[CommonIdSavePurchase] Common Id Save Purchase',
  props<{ condition: CommonIdSavePurchaseRequest }>()
);
export const commonIdSavePurchaseSuccess = createAction(
  '[CommonIdSavePurchase] Common Id Save Purchase Success',
  props<{ response: CommonIdUserPurchaseData; result: boolean }>()
);
export const commonIdSavePurchaseFailure = createAction(
  '[CommonIdSavePurchase] Common Id Save Purchase Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdSavePurchaseResult = createAction('[CommonIdAuth] Initialize Save Purchase Result');
