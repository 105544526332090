<div class="slide-toggle" [attr.data-animated]="isAnimated && particles">
  <mat-slide-toggle [color]="'primary'" [(ngModel)]="isChecked" [disabled]="disabled" (change)="onChange()">
    <ng-content></ng-content>
  </mat-slide-toggle>
  <div *ngIf="particles" class="particles">
    <i class="s"></i>
    <i class="s"></i>
    <i class="s"></i>
    <i class="s"></i>
    <i class="s"></i>
    <i class="s"></i>
    <i class="s"></i>
    <i class="s"></i>
    <span class="l"></span>
    <span class="l"></span>
    <span class="l"></span>
    <span class="l"></span>
    <span class="l"></span>
    <span class="l"></span>
    <span class="l"></span>
    <span class="l"></span>
  </div>
</div>
