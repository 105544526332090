import { Component, Input, OnInit } from '@angular/core';

type TagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div';

@Component({
  selector: 'app-article-content-head',
  templateUrl: './article-content-head.component.html',
  styleUrls: ['./article-content-head.component.scss']
})
export class ArticleContentHeadComponent implements OnInit {
  constructor() {}
  @Input() tagName: TagName = 'h1';
  ngOnInit() {}
}
