import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

import { CallableFunction } from '../resources/app-firebase';
import { UniversitySearchCountResponse } from '../models/university-search-count-response';
import {
  UniversitySearchCountRequest,
  UniversitySearchCondition,
  UniversityCondition,
  PrefectureCondition,
  UniversitySearchRequest
} from '../models/university-search-condition';
import { FindUniversityPapersResponse } from '../models/find-university-papers-response';

@Injectable({
  providedIn: 'root'
})
export class UniversitySearchService {
  constructor(private aff: AngularFireFunctions) {}

  findUniversityCountByUniversityIds(
    condition: UniversitySearchCondition<UniversityCondition>,
    logging: boolean
  ): Observable<UniversitySearchCountResponse> {
    const req: UniversitySearchCountRequest<UniversityCondition> = { ...condition, logging };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_UNIVERSITY_COUNT);
    return callable(req);
  }

  findUniversityCountByPrefectureIds(
    condition: UniversitySearchCondition<PrefectureCondition>,
    logging: boolean
  ): Observable<UniversitySearchCountResponse> {
    const req: UniversitySearchCountRequest<PrefectureCondition> = { ...condition, logging };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_UNIVERSITY_COUNT);
    return callable(req);
  }

  findUniversityPapersByUniversityIds(
    condition: UniversitySearchCondition<UniversityCondition>,
    page: number
  ): Observable<FindUniversityPapersResponse[]> {
    const req: UniversitySearchRequest<UniversityCondition> = { ...condition, page };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_UNIVERSITY_PAPERS);
    return callable(req);
  }

  findUniversityPapersByPrefectureIds(
    condition: UniversitySearchCondition<PrefectureCondition>,
    page: number
  ): Observable<FindUniversityPapersResponse[]> {
    const req: UniversitySearchRequest<PrefectureCondition> = { ...condition, page };
    const callable = this.aff.httpsCallable(CallableFunction.FIND_UNIVERSITY_PAPERS);
    return callable(req);
  }
}
