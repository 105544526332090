import { createFeatureSelector, createSelector } from '@ngrx/store';
import { commonIdAnsweredProblemFeatureKey, State } from 'src/app/reducers/common-id/common-id-answered-problem.reducer';

const selectCommonIdAnsweredProblemState = createFeatureSelector<State>(commonIdAnsweredProblemFeatureKey);

export const getCommonIdAnsweredProblem = createSelector(selectCommonIdAnsweredProblemState, state => state.answeredProblem);
export const getCommonIdSaveAnsweredProblemsResult = createSelector(
  selectCommonIdAnsweredProblemState,
  state => state.saveAnsweredProblemResult
);
