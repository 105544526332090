import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, exhaustMap } from 'rxjs/operators';

import * as PlanActions from '../actions/plan.actions';
import { PlanService } from '../services/plan.service';
import { dispatchAppError } from '../actions/core.actions';
import { FirestoreError } from '../errors/firestore-error';
import { FirebaseCallableFunctionError } from '../errors/firebase-callable-function-error';
import { AppError } from '../errors/app-error';

@Injectable()
export class PlanEffects {
  private LOG_SOURCE = this.constructor.name;

  findPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.findPlan),
      switchMap(action =>
        this.planService.findPlan(action.planId).pipe(
          map(plan => PlanActions.findPlanSuccess({ plan })),
          catchError(e => {
            const error = e instanceof AppError ? e : FirestoreError.from(e);
            return of(PlanActions.findPlanFailure(), dispatchAppError({ source: this.LOG_SOURCE, error }));
          })
        )
      )
    )
  );

  findPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.findPlans),
      switchMap(action =>
        this.planService.findPlans(action.organization, action.schoolId, action.subjectId).pipe(
          map(plans => PlanActions.findPlansSuccess({ plans })),
          catchError(e => of(PlanActions.findPlansFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })))
        )
      )
    )
  );

  findSignedInUserPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.findSignedInUserPlans),
      switchMap(action =>
        this.planService.findPlans(action.signedInUser.organization, action.signedInUser.schoolId, null).pipe(
          map(signedInUserPlans => PlanActions.findSignedInUserPlansSuccess({ signedInUserPlans })),
          catchError(e =>
            of(PlanActions.findSignedInUserPlansFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) }))
          )
        )
      )
    )
  );

  createPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.createPlan),
      exhaustMap(action =>
        this.planService.createPlan(action.plan).pipe(
          map(plan => PlanActions.createPlanSuccess({ plan })),
          catchError(e => of(PlanActions.createPlanFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  updatePlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.updatePlan),
      exhaustMap(action =>
        this.planService.updatePlan(action.plan).pipe(
          map(plan => PlanActions.updatePlanSuccess({ plan })),
          catchError(e => of(PlanActions.updatePlanFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  deletePlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.deletePlan),
      switchMap(action =>
        this.planService.deletePlan(action.plan).pipe(
          map(response => PlanActions.deletePlanSuccess({ response })),
          catchError(e => of(PlanActions.deletePlanFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  assignPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.assignPlan),
      switchMap(action =>
        this.planService.assignPlan(action.userIds, action.planId, action.subjectId).pipe(
          map(userIds => PlanActions.assignPlanSuccess({ userIds })),
          catchError(e => of(PlanActions.assignPlanFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  unAssignPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.unAssignPlan),
      switchMap(action =>
        this.planService.unAssignPlan(action.userIds, action.planId, action.subjectId).pipe(
          map(userIds => PlanActions.unAssignPlanSuccess({ userIds })),
          catchError(e => of(PlanActions.unAssignPlanFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  updateDisableRenewPlanAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlanActions.updateDisableRenewPlanAlert),
      exhaustMap(action =>
        this.planService.updateDisableRenewPlanAlert(action.updateDisableRenewPlanAlert).pipe(
          map(response => PlanActions.updateDisableRenewPlanAlertSuccess({ response })),
          catchError(e => of(PlanActions.updateDisableRenewPlanAlertFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private planService: PlanService) {}
}
