import { Action, createReducer, on } from '@ngrx/store';

import * as PlanActions from '../actions/plan.actions';
import { Plan } from '../models/plan';
import { AppError } from '../errors/app-error';
import { DeletePlanResponse } from '../models/delete-plan-response';
import { UpdateDisableRenewPlanAlertResponse } from './../models/update-disable-renew-plan-alert-response';

export const planFeatureKey = 'plan';

export interface CreatePlanResult {
  success: boolean;
  createdPlan: Plan | null;
  error: AppError | null;
}

export interface UpdatePlanResult {
  success: boolean;
  updatedPlan: Plan | null;
  error: AppError | null;
}

export interface DeletePlanResult {
  success: boolean;
  response: DeletePlanResponse | null;
  error: AppError | null;
}

export interface AssignPlanResult {
  success: boolean;
  userIds: string[] | null;
  error: AppError | null;
}

export interface UnAssignPlanResult {
  success: boolean;
  userIds: string[] | null;
  error: AppError | null;
}

export interface UpdateDisableRenewPlanAlertResult {
  success: boolean;
  response: UpdateDisableRenewPlanAlertResponse | null;
  error: AppError | null;
}

export interface State {
  plan: Plan | null;
  plans: Plan[] | null;
  signedInUserPlans: Plan[] | null;
  createPlanResult: CreatePlanResult | null;
  updatePlanResult: UpdatePlanResult | null;
  deletePlanResult: DeletePlanResult | null;
  assignPlanResult: AssignPlanResult | null;
  unAssignPlanResult: UnAssignPlanResult | null;
  updateDisableRenewPlanAlertResult: UpdateDisableRenewPlanAlertResult | null;
}

export const initialState: State = {
  plan: null,
  plans: null,
  signedInUserPlans: null,
  createPlanResult: null,
  updatePlanResult: null,
  deletePlanResult: null,
  assignPlanResult: null,
  unAssignPlanResult: null,
  updateDisableRenewPlanAlertResult: null
};

const planReducer = createReducer(
  initialState,

  // Find Plan -------------------------------------------------------
  on(PlanActions.findPlanSuccess, (state, { plan }) => ({ ...state, plan })),
  on(PlanActions.initializeFindPlanState, state => {
    const next: State = { ...state, plan: null };
    return next;
  }),

  // Find Plans -------------------------------------------------------
  on(PlanActions.findPlansSuccess, (state, { plans }) => ({ ...state, plans })),
  on(PlanActions.initializeFindPlansState, state => {
    const next: State = { ...state, plans: null };
    return next;
  }),

  // Find SignedIn User Plans -------------------------------------------------------
  on(PlanActions.findSignedInUserPlansSuccess, (state, { signedInUserPlans }) => ({ ...state, signedInUserPlans })),
  on(PlanActions.initializeFindSignedInUserPlansState, state => {
    const next: State = { ...state, signedInUserPlans: null };
    return next;
  }),

  // Create Plan ----------------------------------------------------------------
  on(PlanActions.createPlanSuccess, (state, { plan }) => {
    const next: State = {
      ...state,
      createPlanResult: { success: true, createdPlan: plan, error: null }
    };
    return next;
  }),
  on(PlanActions.createPlanFailure, (state, { error }) => {
    const next: State = {
      ...state,
      createPlanResult: { success: false, createdPlan: null, error }
    };
    return next;
  }),
  on(PlanActions.initializeCreatePlanState, state => {
    const next: State = { ...state, createPlanResult: null };
    return next;
  }),

  // Update Plan ----------------------------------------------------------------
  on(PlanActions.updatePlanSuccess, (state, { plan }) => {
    const next: State = {
      ...state,
      updatePlanResult: { success: true, updatedPlan: plan, error: null }
    };
    return next;
  }),
  on(PlanActions.updatePlanFailure, (state, { error }) => {
    const next: State = {
      ...state,
      updatePlanResult: { success: false, updatedPlan: null, error }
    };
    return next;
  }),
  on(PlanActions.initializeUpdatePlanState, state => {
    const next: State = { ...state, updatePlanResult: null };
    return next;
  }),

  // Delete Plan ----------------------------------------------------------------
  on(PlanActions.deletePlanSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      deletePlanResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(PlanActions.deletePlanFailure, (state, { error }) => {
    const next: State = {
      ...state,
      deletePlanResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(PlanActions.initializeDeletePlanState, state => {
    const next: State = { ...state, deletePlanResult: null };
    return next;
  }),

  // Assign Plan ----------------------------------------------------------------
  on(PlanActions.assignPlanSuccess, (state, { userIds }) => {
    const next: State = {
      ...state,
      assignPlanResult: { success: true, userIds, error: null }
    };
    return next;
  }),
  on(PlanActions.assignPlanFailure, (state, { error }) => {
    const next: State = {
      ...state,
      assignPlanResult: { success: false, userIds: null, error }
    };
    return next;
  }),
  on(PlanActions.initializeAssignPlanState, state => {
    const next: State = { ...state, assignPlanResult: null };
    return next;
  }),

  // Un Assign Plan ----------------------------------------------------------------
  on(PlanActions.unAssignPlanSuccess, (state, { userIds }) => {
    const next: State = {
      ...state,
      unAssignPlanResult: { success: true, userIds, error: null }
    };
    return next;
  }),
  on(PlanActions.unAssignPlanFailure, (state, { error }) => {
    const next: State = {
      ...state,
      unAssignPlanResult: { success: false, userIds: null, error }
    };
    return next;
  }),
  on(PlanActions.initializeUnAssignPlanState, state => {
    const next: State = { ...state, unAssignPlanResult: null };
    return next;
  }),

  // Update Disable Renew Plan Alert -----------------------------------------------
  on(PlanActions.updateDisableRenewPlanAlertSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      updateDisableRenewPlanAlertResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(PlanActions.updateDisableRenewPlanAlertFailure, (state, { error }) => {
    const next: State = {
      ...state,
      updateDisableRenewPlanAlertResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(PlanActions.initializeUpdateDisableRenewPlanAlertState, state => {
    const next: State = { ...state, updateDisableRenewPlanAlertResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return planReducer(state, action);
}
