<app-dialog-basic dialogTitle="メンバー割当" [isLoading]="IsAssigning">
  <form class="input-form">
    <app-autocomplete-multiselect
      placeholder=""
      [multiselectOptions]="selectableUsers"
      [maxAssignableNum]="maxAssignableNum"
      [isFormDisabled]="IsAssigning"
      (emitSelectedOptions)="multiselectChange($event)"
    >
    </app-autocomplete-multiselect>
  </form>

  <div class="mt-4">割当可能なメンバーが表示されない場合、下記の場合が考えられます。</div>
  <app-content-row class="text-md">
    <div class="mt-4">
      <app-tips-content>
        <div class="font-bold">メンバー一覧に登録されていない</div>
        <button (click)="goMembers()" class="text-primary underline hover:no-underline">
          プラン・アカウント管理-所属メンバー一覧
        </button>
        よりメンバーの登録を行ってください。
      </app-tips-content>
    </div>
    <div class="mt-4">
      <app-tips-content>
        <div class="font-bold">メンバーが既に他のプランに割当されている</div>
        各メンバーにプランは1つまでの設定になります。既に他のプランに割当されている場合は、プラン割当を解除してください。
      </app-tips-content>
    </div>
  </app-content-row>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="IsAssigning"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <div class="text-danger text-sm mr-auto" @enter *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
    <button mat-stroked-button (click)="cancel()" [disabled]="IsAssigning">キャンセル</button>
    <button mat-flat-button color="primary" (click)="assign()" [disabled]="IsAssigning || selectedUsers.length === 0">割当</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
