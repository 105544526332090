import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-theme-default',
  templateUrl: './document-theme-default.component.html',
  styleUrls: ['./document-theme-default.component.scss']
})
export class DocumentThemeDefaultComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
