import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-playlist-tag-label',
  templateUrl: './playlist-tag-label.component.html',
  styleUrls: ['./playlist-tag-label.component.scss']
})
export class PlaylistTagLabelComponent implements OnInit {
  constructor() {}
  @Input() tagname: string;
  @Input() noHash = false;

  ngOnInit() {}
}
