import { createAction, props } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { CommonIdInformationResponse } from 'src/app/models/common-id/common-id-information';

export const commonIdGetInformations = createAction('[CommonIdInformation] CommonId Get Informations');
export const commonIdGetInformationsSuccess = createAction(
  '[CommonIdInformation] CommonId Get Informations Success',
  props<{ response: CommonIdInformationResponse[] }>()
);
export const commonIdGetInformationsFailure = createAction(
  '[CommonIdInformation] Common Id Get Informations Failure',
  props<{ error: AppError }>()
);
export const initializeCommonIdGetInformationsState = createAction('[CommonIdInformation] Initialize CommonId Get Informations State');

export const commonIdUpdateNewInformation = createAction(
  '[CommonIdInformation] CommonId Update New Information',
  props<{ newInformation: boolean }>()
);
