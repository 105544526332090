<div *ngFor="let result of searchResults">
  <app-content-row>
    <app-wrapper size="lg" isFitWithSmallScreen="true">
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <div class="flex items-center gap-x-2 md:my-3">
            <app-univ-cat-mark
              univCatType="{{ result.universityTypeName }}"
              univCatPref="{{ result.prefectureName }}"
              univType="{{ result.universityType }}"
            ></app-univ-cat-mark>
            <div role="heading" class="text-xl md:text-2xl ml-1">
              <a routerLink="." class="text-primary hover:underline" (click)="goToUnivDetailClick.emit(result.universityId)">{{
                result.universityName
              }}</a>
            </div>
          </div>
          <div class="card-content-head-util" *ngIf="isBtoC">
            <div class="pl-2">
              <app-button-toggle-univ-bookmark
                *ngIf="!result.isBookmarked"
                [disabled]="(this.buttonAbled$ | async) === false"
                [isBookmarked]="false"
                [universityId]="result.universityId"
                [bookmarkClickEmitted]="bookmarkClickEmitted"
                [errorUnivId]="errorUnivId$ | async"
                (backClick)="addUniversityBookmarkClick.emit($event)"
              ></app-button-toggle-univ-bookmark>
              <app-button-toggle-univ-bookmark
                *ngIf="result.isBookmarked"
                [disabled]="(this.buttonAbled$ | async) === false"
                [isBookmarked]="true"
                [universityId]="result.universityId"
                [bookmarkClickEmitted]="bookmarkClickEmitted"
                [errorUnivId]="errorUnivId$ | async"
                (backClick)="deleteUniversityBookmarkClick.emit($event)"
              ></app-button-toggle-univ-bookmark>
            </div>
          </div>
        </app-card-content-head>

        <app-wrapper size="fluid">
          <app-content-row>
            <div class="flex items-center justify-start flex-wrap gap-4">
              <app-header-with-listmark>
                <span class="text-base md:text-xl">{{ result.year }}年度の過去問</span
                ><app-button-subject-name
                  *ngIf="isPremiumIconShown"
                  subject="プレミアム会員限定"
                  isPremium="true"
                  isArrow="true"
                  class="text-xs ml-4 align-middle md:align-bottom"
                ></app-button-subject-name>
              </app-header-with-listmark>
              <div class="ml-auto text-sm md:text-base pb-2">
                <a
                  routerLink="."
                  class="text-primary decoration-solid font-bold hover:underline"
                  (click)="goToUnivDetailClick.emit(result.universityId)"
                  >他の年度・学部系統<span class="hidden sm:inline">を詳しく</span>
                </a>
                <mat-icon class="icon text-primary relative inset-y-2 sm:inset-y-1.5" aria-hidden="false" aria-label=""
                  >keyboard_arrow_right</mat-icon
                >
              </div>
            </div>
          </app-content-row>
          <app-content-row>
            <div class="flex flex-col gap-4">
              <div *ngFor="let department of result.departments">
                <app-accordion-list-item [isExpanded]="true" [openableInPc]="false" [questionTitle]="department.deptName" size="sm">
                  <!-- <div class="-mx-1 md:-my-2"></div> -->
                  <div *ngFor="let paper of department.papers; let last = last">
                    <div class="md:flex items-center">
                      <div class="md:w-1/3 mb-4 md:mb-0">
                        <p class="mb-0 md:mr-4">{{ paper.specialSubject }} {{ paper.nyushiName }} 試験日：{{ paper.examDate }}</p>
                      </div>
                      <div class="md:w-2/3">
                        <div class="grid gap-2 grid-cols-2 md:flex md:flex-wrap md:grid-cols-none">
                          <div *ngFor="let paperId of paper.paperIds">
                            <app-button-subject-name
                              *ngIf="isBtoC"
                              subject="{{ paperId.subjectName }}"
                              [isPremium]="paperId.isPremiumIconShown"
                              [isVisited]="paperId.isVisited"
                              (click)="
                                goToPaperDetailClick.emit({
                                  paperId: paperId.id,
                                  daimonIds: paperId.daimonIds,
                                  isPremiumIconShown: paperId.isPremiumIconShown,
                                  deptName: department.deptName,
                                  specialSubject: paper.specialSubject
                                })
                              "
                            ></app-button-subject-name>
                            <app-button-subject-name
                              *ngIf="!isBtoC"
                              subject="{{ paperId.subjectName }}"
                              [isVisited]="paperId.isVisited"
                              (click)="goToPaperDetailClick.emit({ paperId: paperId.id, daimonIds: paperId.daimonIds })"
                            ></app-button-subject-name>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="my-4 border-t-2 border-gray-50" *ngIf="!last" />
                  </div>
                </app-accordion-list-item>
              </div>
            </div>
            <app-content-row>
              <div class="text-right text-sm md:text-base pb-2">
                <a
                  routerLink="."
                  class="text-primary decoration-solid font-bold hover:underline"
                  (click)="goToUnivDetailClick.emit(result.universityId)"
                  >他の年度・学部系統<span class="hidden sm:inline">を詳しく</span></a
                >
                <mat-icon class="icon text-primary relative inset-y-2 sm:inset-y-1.5" aria-hidden="false" aria-label=""
                  >keyboard_arrow_right</mat-icon
                >
              </div>
            </app-content-row>
          </app-content-row>
        </app-wrapper>
      </app-card-content>
    </app-wrapper>
  </app-content-row>
</div>
