import { createReducer, on } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { ComonIdFindBookmarkAndAnsweredPaperResponse } from 'src/app/models/common-id/common-id-bookmark-and-answered-paper';
import * as CommonIdBookmarkAndAnsweredPaperActions from 'src/app/actions/common-id/common-id-bookmark-and-answered-paper.actions';

export const commonIdBookmarkAndAnsweredPaperFeatureKey = 'commonIdBookmarkAndAnsweredPaper';

export interface CommonIdBookmarkAndAnsweredPapersResult {
  success: boolean;
  response: ComonIdFindBookmarkAndAnsweredPaperResponse[] | null;
  error: AppError | null;
}

export interface State {
  findBookmarkAndAnsweredPapersResult: CommonIdBookmarkAndAnsweredPapersResult | null;
}

export const initialState: State = {
  findBookmarkAndAnsweredPapersResult: null
};

export const reducer = createReducer(
  initialState,

  on(CommonIdBookmarkAndAnsweredPaperActions.commonIdFindBookmarkAndAnsweredPapersSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      findBookmarkAndAnsweredPapersResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(CommonIdBookmarkAndAnsweredPaperActions.commonIdFindBookmarkAndAnsweredPapersFailure, (state, { error }) => {
    const next: State = {
      ...state,
      findBookmarkAndAnsweredPapersResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(CommonIdBookmarkAndAnsweredPaperActions.initializeCommonIdFindBookmarkAndAnsweredPapers, state => {
    const next: State = { ...state, findBookmarkAndAnsweredPapersResult: null };
    return next;
  })
);
