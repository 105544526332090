import { Component, OnChanges, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Log } from 'src/app/utils/log';
import { enter } from 'src/app/resources/animations';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  animations: [enter],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnChanges {
  private LOG_SOURCE = this.constructor.name;
  private paginationWindowDelta: number;

  @Input() currentPage: number;
  @Input() totalItemCount: number;

  /** 1 page に表示する item の数 */
  @Input() pageSize: number;

  /** page 番号ボタンとして表示する最大の数 (奇数で指定) */
  @Input() windowSize: number;

  @Output() pageChange = new EventEmitter<number>();

  lastPage: number;
  leftPages: number[];
  centerPages: number[];
  rightPages: number[];
  previousPageButtonVisible: boolean;
  nextPageButtonVisible: boolean;
  leftReaderVisible: boolean;
  rightReaderVisible: boolean;

  constructor() {}

  ngOnChanges(): void {
    if (this.currentPage == null || this.totalItemCount == null || this.pageSize == null) {
      Log.warn(
        this.LOG_SOURCE,
        `input の何れかが null です. ` +
          `currentPage: ${this.currentPage}, totalItemCount: ${this.totalItemCount}, ` +
          `pageSize: ${this.pageSize}, windowSize: ${this.windowSize}`
      );
      return;
    }

    this.lastPage = Math.ceil(this.totalItemCount / this.pageSize);
    this.paginationWindowDelta = Math.floor(this.windowSize / 2);

    this.previousPageButtonVisible = this.currentPage !== 1;
    this.nextPageButtonVisible = this.currentPage !== this.lastPage;

    if (this.lastPage <= this.windowSize + 1) {
      this.centerPages = [...Array(this.lastPage).keys()].map(key => key + 1);

      this.leftPages = [];
      this.leftReaderVisible = false;
      this.rightPages = [];
      this.rightReaderVisible = false;
    } else {
      const centerWindow = [...Array(this.windowSize).keys()]
        .map(i => {
          const value = i + (this.currentPage - this.paginationWindowDelta);
          if (value <= 0) return value + this.windowSize;
          if (value > this.lastPage) return value - this.windowSize;
          return value;
        })
        .sort((a, b) => a - b);

      this.centerPages = [...centerWindow];

      const windowFirst = centerWindow[0];
      const windowLast = centerWindow[this.windowSize - 1];

      this.leftPages = windowFirst >= 2 ? [1] : [];
      this.leftReaderVisible = windowFirst >= 3 ? true : false;

      this.rightPages = windowLast <= this.lastPage - 1 ? [this.lastPage] : [];
      this.rightReaderVisible = windowLast <= this.lastPage - 2 ? true : false;
    }
  }

  onNextClick() {
    const nextPage = this.currentPage + 1;
    if (nextPage > this.lastPage) {
      Log.warn(this.LOG_SOURCE, `lastPage を超える page になるため無視します. currentPage: ${this.currentPage}`);
      return;
    }
    this.pageChange.emit(nextPage);
  }

  onPreviousClick() {
    const prevPage = this.currentPage - 1;
    if (prevPage < 1) {
      Log.warn(this.LOG_SOURCE, `1 より小さい page になるため無視します`);
      return;
    }
    this.pageChange.emit(prevPage);
  }
}
