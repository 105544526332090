<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<element [ngSwitch]="tagName">
  <h1 *ngSwitchCase="'h1'" class="article-content-head"><ng-container *ngTemplateOutlet="content"></ng-container></h1>
  <h2 *ngSwitchCase="'h2'" class="article-content-head"><ng-container *ngTemplateOutlet="content"></ng-container></h2>
  <h3 *ngSwitchCase="'h3'" class="article-content-head"><ng-container *ngTemplateOutlet="content"></ng-container></h3>
  <h4 *ngSwitchCase="'h4'" class="article-content-head"><ng-container *ngTemplateOutlet="content"></ng-container></h4>
  <h5 *ngSwitchCase="'h5'" class="article-content-head"><ng-container *ngTemplateOutlet="content"></ng-container></h5>
  <h6 *ngSwitchCase="'h6'" class="article-content-head"><ng-container *ngTemplateOutlet="content"></ng-container></h6>
  <p *ngSwitchCase="'p'" class="article-content-head"><ng-container *ngTemplateOutlet="content"></ng-container></p>
  <div *ngSwitchCase="'div'" class="article-content-head"><ng-container *ngTemplateOutlet="content"></ng-container></div>
</element>
