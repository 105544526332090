import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { enter } from 'src/app/resources/animations';
import { Log } from 'src/app/utils/log';
import { RootState } from 'src/app/reducers';
import { initializeUpdateUserEmailState, updateUserEmail } from 'src/app/actions/user.actions';
import { User } from 'src/app/models/user';
import { getUpdateUserEmailResult } from 'src/app/selectors/user.selectors';
import { dispatchInfoMessage, navigate, unsubscribeWatchedProps } from 'src/app/actions/core.actions';
import { signOut } from 'src/app/actions/auth.actions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UnsubscribeTarget } from '../../../resources/config';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';

export interface EditEmailDialogData {
  id: string;
  email: string;
}

@Component({
  selector: 'app-edit-email-dialog',
  templateUrl: './edit-email-dialog.component.html',
  styleUrls: ['./edit-email-dialog.component.scss'],
  animations: [enter]
})
export class EditEmailDialogComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;

  emailForm: UntypedFormGroup;
  email: string;

  mode = 'input';
  emailUpdating = false;
  errorMessage = '';

  constructor(
    private store: Store<RootState>,
    private afAuth: AngularFireAuth,
    private dialogRef: MatDialogRef<EditEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: EditEmailDialogData
  ) {}

  ngOnInit() {
    this.email = this.data.email;
    this.setUpForms();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeUpdateUserEmailState());
  }

  confirm() {
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
      return;
    }

    this.errorMessage = '';

    // 確認画面表示
    this.mode = 'confirm';
  }

  returnInput() {
    // 入力画面表示
    this.mode = 'input';
  }

  async editEmail() {
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
      this.mode = 'input';
      return;
    }
    Log.debug(this.LOG_SOURCE, 'メールアドレスを変更します');
    this.disableForms();
    this.errorMessage = '';

    const password: string = this.emailForm.get('password').value;
    const email: string = this.emailForm.get('email').value;

    const user: User = {
      id: this.data.id,
      email
    };

    // const currentUser = await this.afAuth.currentUser;
    // const credential = firebase.auth.EmailAuthProvider.credential(currentUser.email, password);
    // currentUser
    //   .reauthenticateWithCredential(credential)
    //   .then(() => {
    //     currentUser
    //       .updateEmail(user.email)
    //       .then(() => {
    //         console.log('メールアドレス変更完了');
    //         this.store.dispatch(updateUserEmail({ user }));
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         this.enableForms();
    //         this.returnInput();
    //         this.errorMessage = 'メールアドレスの変更に失敗しました';
    //       });
    //   })
    //   .catch(error => {
    //     this.enableForms();
    //     this.returnInput();
    //     this.errorMessage = '認証に失敗しました';
    //     // throw FirebaseAuthError.from(error);
    //   });
    this.store.dispatch(updateUserEmail({ user, password }));

    this.store
      .select(getUpdateUserEmailResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeUpdateUserEmailState());
        Log.debug(this.LOG_SOURCE, `update user email result: `, result);
        if (result.success) {
          this.dialogRef.close();
          this.store.dispatch(
            dispatchInfoMessage({
              message: `${email} にメールアドレスを変更しました`
            })
          );

          // sign out
          this.store.dispatch(signOut());
          this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.ON_SIGN_OUT }));
          setTimeout(() => this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSignIn() })));

          return;
        }

        Log.warn(this.LOG_SOURCE, `update user email error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
        this.enableForms();
        this.returnInput();
        // TODO: メッセージ内容を要検討
        this.errorMessage = result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました';
      });
  }

  cancel() {
    this.dialogRef.close();
  }

  // set ups or private method ---------------------------------------------------------------

  private setUpForms() {
    this.emailForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, Validators.email])
    });
  }

  private disableForms() {
    Object.keys(this.emailForm.controls).forEach(ctrlName => {
      this.emailForm.get(ctrlName).disable();
    });
    this.emailUpdating = true;
  }

  private enableForms() {
    Object.keys(this.emailForm.controls).forEach(ctrlName => {
      this.emailForm.get(ctrlName).enable();
    });
    this.emailUpdating = false;
  }
}
