import { Action, createReducer, on } from '@ngrx/store';

import * as AuthActions from '../actions/auth.actions';
import { User } from '../models/user';

interface SignInResult {
  success: boolean;
  signedInUser: User | null;
}

interface UpdateSignedInUserIsTermsAgreeResult {
  success: boolean;
}

export const authFeatureKey = 'auth';

export interface State {
  signedInUser: User | 'none' | null;
  signInResult: SignInResult | null;
  updateSignedInUserIsTermsAgreeResult: UpdateSignedInUserIsTermsAgreeResult | null;
}

export const initialState: State = {
  signedInUser: null,
  signInResult: null,
  updateSignedInUserIsTermsAgreeResult: null
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.signInSuccess, (state, { user }) => ({
    ...state,
    signedInUser: user,
    signInResult: { success: true, signedInUser: user }
  })),
  on(AuthActions.signInFailure, state => {
    const next: State = { ...state, signInResult: { success: false, signedInUser: null } };
    return next;
  }),
  on(AuthActions.initializeSignInResult, state => {
    const next: State = { ...state, signInResult: null };
    return next;
  }),
  on(AuthActions.signOutSuccess, state => {
    const next: State = { ...state, signedInUser: null };
    return next;
  }),
  on(AuthActions.findSignedInUserSuccess, (state, { user }) => ({ ...state, signedInUser: user })),
  on(AuthActions.findSignedInUserFailure, state => {
    const next: State = { ...state, signedInUser: 'none' };
    return next;
  }),
  on(AuthActions.updateSignedInUserIsTermsAgreeSuccess, (state, { user }) => ({
    ...state,
    signedInUser: user,
    updateSignedInUserIsTermsAgreeResult: { success: true }
  })),
  on(AuthActions.initializeUpdateSignedInUserIsTermsAgreeResult, state => {
    const next: State = { ...state, updateSignedInUserIsTermsAgreeResult: null };
    return next;
  }),
  on(AuthActions.updateSignedInUser, (state, { user }) => ({
    ...state,
    signedInUser: user
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}
