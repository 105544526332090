import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { ActivatedRoute } from '@angular/router';
import { navigate, setBrowserTitle, setTitle } from '../../../actions/core.actions';
import { Log } from '../../../utils/log';
import { findPlan, initializeFindPlanState } from '../../../actions/plan.actions';
import { getPlan } from '../../../selectors/plan.selectors';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Plan } from '../../../models/plan';
import { CurrentDateTime } from '../../../models/current-date-time';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../selectors/current-date-time.selectors';
import { User } from '../../../models/user';
import { getUsers } from '../../../selectors/user.selectors';
import { HighSchool } from '../../../models/common-data';
import { getHighSchool } from '../../../selectors/static-data.selectors';
import { getCurrentDateTime, initializeGetCurrentDateTime } from '../../../actions/current-date-time.actions';
import { UserSearchCondition } from '../../../models/user-search-condition';
import { findUsers, initializeFindUsers } from '../../../actions/user.actions';
import { PlanUtil, PriorityRecord } from '../../../utils/plan-util';
import { Dates } from '../../../utils/dates';
import {
  ADD_PLAN_DIALOG_HEIGHT,
  ADD_PLAN_DIALOG_WIDTH,
  DELETE_PLAN_DIALOG_WIDTH,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  EDIT_PLAN_DIALOG_HEIGHT,
  EDIT_PLAN_DIALOG_WIDTH,
  MEMBER_CONTACT_URL,
  PLAN_NAMES,
  PlanStatuses,
  SCHOOL_ORGANIZATION_ID,
  JUKU_ORGANIZATION_ID,
  PlanSubjectId,
  CHANGE_ACTIVE_ACCOUNT_DIALOG_WIDTH
} from '../../../resources/config';
import { getSignedInUser } from '../../../selectors/auth.selectors';
import { StaticDataUtil } from '../../../utils/static-data-util';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AddPlanDialogComponent, AddPlanDialogData } from '../add-plan-dialog/add-plan-dialog.component';
import { enter } from '../../../resources/animations';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { EditPlanDialogComponent, EditPlanDialogData } from '../edit-plan-dialog/edit-plan-dialog.component';
import { DeletePlanDialogComponent, DeletePlanDialogData } from '../delete-plan-dialog/delete-plan-dialog.component';
import { findJukus, initializeFindJukusState } from 'src/app/actions/juku.actions';
import { Juku } from 'src/app/models/juku';
import { WidgetsCardContentNavMenuData } from 'src/app/models/widgets-card-content-nav';
import { getJukus } from 'src/app/selectors/juku.selectors';
import { JukuUtil } from 'src/app/utils/juku-util';
import {
  ChangeRenewPlanAlertDialogComponent,
  ChangeRenewPlanAlertDialogData
} from '../change-renew-plan-alert-dialog/change-renew-plan-alert-dialog.component';

interface PlanRecordData {
  planRecordId: string;
  planStatus: string;
  planStartAt: string;
  planEndAt: string;
  planLeftDay: number;
  planToStartDay: number;
  memberCount: number;
  memberList: string[];
  isRenewPlanAlertDisabled: boolean;
}

interface Breadcrumb {
  key: string;
  label: string;
}

@Component({
  selector: 'app-plan-statuses',
  templateUrl: './plan-statuses.component.html',
  styleUrls: ['./plan-statuses.component.scss'],
  animations: [enter]
})
export class PlanStatusesComponent implements OnInit, OnDestroy {
  isAdmin: boolean;
  isOrganizationAdmin: boolean;
  navMenu: WidgetsCardContentNavMenuData[];
  schoolName: string;
  plan: Plan;
  priorityRecord: PriorityRecord;
  memberNum: number;
  memberCount: number;
  planSubscription: Subscription;
  records$: Observable<PlanRecordData[]>;
  planName: string;
  planActiveStatus: string = PlanStatuses.ACTIVE;
  planEndedStatus: string = PlanStatuses.ENDED;
  planReserveStatus: string = PlanStatuses.RESERVE;
  memberContactUrl: string = MEMBER_CONTACT_URL;
  breadcrumbs: Breadcrumb[] = [];

  displayedColumns: string[] = ['planStatus', 'validityPeriod', 'memberCount', 'memberList', 'isAlertDisabled', 'action'];

  private LOG_SOURCE = this.constructor.name;
  private title = 'プラン一覧';

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.setUp();
  }

  ngOnDestroy(): void {
    this.store.dispatch(initializeFindPlanState());
    this.store.dispatch(initializeGetCurrentDateTime());
    this.store.dispatch(initializeFindUsers());
    this.store.dispatch(initializeFindJukusState());

    this.planSubscription.unsubscribe();
  }

  plans() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlans(null, null) }));
  }

  addPlan() {
    const config: MatDialogConfig<AddPlanDialogData> = {
      height: ADD_PLAN_DIALOG_HEIGHT,
      width: ADD_PLAN_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data: { schoolId: this.plan.schoolId, school: this.schoolName, organization: this.plan.organization, subjectId: this.plan.subjectId },
      disableClose: true
    };
    this.dialog
      .open(AddPlanDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) this.store.dispatch(findPlan({ planId: this.plan.id }));
      });
  }

  editPlan(recordId: number) {
    const config: MatDialogConfig<EditPlanDialogData> = {
      height: EDIT_PLAN_DIALOG_HEIGHT,
      width: EDIT_PLAN_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data: {
        plan: this.plan,
        recordId,
        school: this.schoolName,
        organization: this.plan.organization
      },
      disableClose: true
    };
    this.dialog
      .open(EditPlanDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) this.store.dispatch(findPlan({ planId: this.plan.id }));
      });
  }

  updateRenewAlert(recordId: number, isAlertDisabled: boolean) {
    Log.debug(this.LOG_SOURCE, `${this.planName}のメール停止・停止解除のダイアログを開きます: `, this.plan);
    const data: ChangeRenewPlanAlertDialogData = {
      planId: this.plan.id,
      planName: this.planName,
      recordId,
      isAlertDisabled
    };
    const config: MatDialogConfig = {
      width: CHANGE_ACTIVE_ACCOUNT_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      data,
      disableClose: true
    };
    this.dialog
      .open(ChangeRenewPlanAlertDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        // テーブルにデータの変更を反映する
        this.store.dispatch(findPlan({ planId: this.plan.id }));
      });
  }

  removePlan(recordId: number) {
    Log.debug(this.LOG_SOURCE, `次のプランを削除します: `, this.plan);
    const data: DeletePlanDialogData = { planId: this.plan.id, recordId, school: this.schoolName, subjectId: this.plan.subjectId };
    const config: MatDialogConfig = {
      width: DELETE_PLAN_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      data,
      disableClose: true
    };
    this.dialog
      .open(DeletePlanDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) this.store.dispatch(findPlan({ planId: this.plan.id }));
      });
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'admin':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        break;
      case 'admin/schools':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSchools() }));
        break;
      case 'admin/plans':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlans(this.plan.organization, this.plan.schoolId) }));
        break;
      case 'admin/jukus':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveJukus() }));
        break;
    }
  }

  menuClickHandler(key) {
    switch (key) {
      case 'admin/plans':
        break;
      case 'admin/plan-assign':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlanAssign(this.plan.id) }));
        break;
      case 'admin/jukus':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveJukus() }));
        break;
    }
  }

  private setUp() {
    const planId: string = this.activatedRoute.snapshot.paramMap.get('planId');
    Log.debug(this.LOG_SOURCE, `query params: `, planId);

    const plan$: Observable<Plan> = this.store.select(getPlan).pipe(
      filter<Plan>(it => it != null),
      shareReplay(1)
    );
    const signedInUser$: Observable<User> = this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null && it !== 'none'),
      shareReplay(1)
    );

    this.planSubscription = combineLatest([plan$, signedInUser$]).subscribe(([plan, signedInUser]) => {
      if (!plan.id) {
        if (this.plan) {
          this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlans(this.plan.organization, this.plan.schoolId) }));
        } else {
          this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearch() }));
        }

        return;
      }

      this.isAdmin = signedInUser.isAdmin;
      this.isOrganizationAdmin = signedInUser.isOrganizationAdmin;

      if (!this.isAdmin) {
        this.displayedColumns = this.displayedColumns.filter(column => column !== 'action' && column !== 'isAlertDisabled');
      }

      if (!this.isAdmin && (plan.schoolId !== signedInUser.schoolId || plan.organization !== signedInUser.organization)) {
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearch() }));
        return;
      }

      this.plan = plan;
      this.planName = PLAN_NAMES[plan.subjectId];
      this.setUpNavMenu();
      this.setUpData();
    });

    this.store.dispatch(findPlan({ planId }));
  }

  private setUpNavMenu() {
    this.navMenu =
      this.isOrganizationAdmin && this.plan.subjectId === PlanSubjectId.TRIAL
        ? [
            {
              key: 'fact_check_black',
              icon: 'fact_check_black',
              fontSet: 'material-icons-outlined',
              label: 'プラン契約状況・履歴',
              isActive: true
            }
          ]
        : [
            {
              key: 'fact_check_black',
              icon: 'fact_check_black',
              fontSet: 'material-icons-outlined',
              label: 'プラン契約状況・履歴',
              isActive: true
            },
            {
              key: 'admin/plan-assign',
              icon: 'person_add',
              label: 'メンバー割当管理',
              isActive: false
            }
          ];
  }

  private setUpData() {
    const currentDateTime$: Observable<CurrentDateTime> = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const users$: Observable<User[]> = this.store.select(getUsers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const highSchools$: Observable<HighSchool[]> = this.store.select(getHighSchool).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const jukus$: Observable<Juku[]> = this.store.select(getJukus).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.records$ = combineLatest([currentDateTime$, users$, highSchools$, jukus$])
      .pipe(take(1))
      .pipe(
        map(([currentDateTime, users, highSchools, jukus]) => {
          if (this.plan.organization === SCHOOL_ORGANIZATION_ID) {
            this.schoolName = StaticDataUtil.getHiSchoolName(highSchools, this.plan.schoolId);
          } else if (this.plan.organization === JUKU_ORGANIZATION_ID) {
            const juku: Juku = JukuUtil.getJukuByJukuCode(jukus, this.plan.schoolId);
            if (juku !== undefined && juku !== null) {
              this.schoolName = JukuUtil.getDisplayJukuName(juku);
            }
          }

          if (this.schoolName === '') {
            this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
            return;
          }

          this.breadcrumbs = [];
          this.breadcrumbs.push({ key: 'admin', label: 'Admin機能' });

          if (this.plan.organization === SCHOOL_ORGANIZATION_ID) {
            this.breadcrumbs.push({ key: 'admin/schools', label: 'アカウント管理 - 学校' });
          } else if (this.plan.organization === JUKU_ORGANIZATION_ID) {
            this.breadcrumbs.push({ key: 'admin/jukus', label: 'アカウント管理 - 塾・予備校' });
          }

          this.breadcrumbs.push({ key: 'admin/plans', label: this.schoolName + ' - プラン一覧' });
          this.breadcrumbs.push({ key: '', label: this.planName });

          this.priorityRecord = PlanUtil.getPriorityRecord(this.plan.records, currentDateTime);
          this.memberNum = PlanUtil.getAssignmentMemberCount(this.priorityRecord.record);
          this.memberCount = this.priorityRecord.record.memberCount;

          return this.plan.records.map(record => this.setUpRecord(record, currentDateTime, users));
        }),
        shareReplay(1)
      );

    this.store.dispatch(getCurrentDateTime());
    const userSearchCondition: UserSearchCondition = {
      organization: this.plan.organization,
      schoolId: this.plan.schoolId
    };
    this.store.dispatch(findUsers({ userSearchCondition }));
    this.store.dispatch(findJukus({}));
  }

  private setUpRecord(record, currentDateTime, users): PlanRecordData {
    return {
      planRecordId: record.id,
      planStatus: PlanUtil.getRecordStatus(record, currentDateTime),
      planStartAt: Dates.simple4YmdStringFromIso(record.startAt),
      planEndAt: Dates.simple4YmdStringFromIso(record.endAt),
      planLeftDay: Dates.getDiffDay(Dates.simple4YmdStringFromIso(currentDateTime.dateTime), record.endAt),
      planToStartDay: Dates.getDiffDay(Dates.simple4YmdStringFromIso(currentDateTime.dateTime), record.startAt),
      memberCount: record.memberCount,
      memberList: [...new Set(PlanUtil.getAssignmentUserNames(record, users))],
      isRenewPlanAlertDisabled: record.isRenewPlanAlertDisabled && record.isRenewPlanAlertDisabled === true ? true : false
    };
  }
}
