import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppError } from '../../errors/app-error';
import { FirestoreError } from '../../errors/firestore-error';
import { of } from 'rxjs';
import { dispatchAppError } from '../../actions/core.actions';
import * as CommonIdPurchasesActions from '../../actions/common-id/common-id-purchases.actions';

import { CommonIdPurchasesService } from '../../services/common-id/common-id-purchases.service';

@Injectable()
export class CommonIdPurchasesEffects {
  private LOG_SOURCE = this.constructor.name;

  constructor(private actions$: Actions, private commonIdPurchasesService: CommonIdPurchasesService) {}

  commonIdFindPurchases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPurchasesActions.commonIdFindPurchases),
      switchMap(action =>
        this.commonIdPurchasesService.commonIdFindPurchases(action.userId).pipe(
          map(purchases => CommonIdPurchasesActions.commonIdFindPurchasesSuccess({ purchases })),
          catchError(e => {
            const error = e instanceof AppError ? e : FirestoreError.from(e);
            return of(CommonIdPurchasesActions.commonIdFindPurchasesFailure(), dispatchAppError({ source: this.LOG_SOURCE, error }));
          })
        )
      )
    )
  );
}
