import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { JUKU_ORGANIZATION_ID, SCHOOL_ORGANIZATION_ID } from '../resources/config';
import { Dates } from './dates';

export class CustomValidators {
  static isSchoolInMaster(master: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '' || control.value == null) return null;

      const organization: string = control.root.get('organization').value ? control.root.get('organization').value.id : '';
      const school: string = control.root.get('school').value;
      const result: boolean = organization === SCHOOL_ORGANIZATION_ID ? master.includes(school) : true;

      return result ? null : { isSchoolInMaster: true };
    };
  }

  static isJukuInMaster(master: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '' || control.value == null) return null;

      const organization: string = control.root.get('organization').value ? control.root.get('organization').value.id : '';
      const school: string = control.root.get('school').value;
      const result: boolean = organization === JUKU_ORGANIZATION_ID ? master.includes(school) : true;

      return result ? null : { isJukuInMaster: true };
    };
  }

  static endAtIsAfterStartAt(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '' || control.value == null) return null;

      const startAt: string = control.root.get('startAt').value;
      const endAt: string = control.root.get('endAt').value;

      const result = startAt === '' || endAt === '' ? true : Dates.isAfter(startAt, endAt);
      return result ? null : { endAtIsAfterStartAt: true };
    };
  }
}
