import { Component, OnInit, Input } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

// Redux
import { navigate, setTitle } from 'src/app/actions/core.actions';
import { setCommonIdBrowserTitle } from 'src/app/actions/common-id/common-id-core.actions';
import { RootState } from 'src/app/reducers';

// models
import { CommonIdPlaylist } from 'src/app/models/common-id/common-id-playlist';
import { CommonIdPlaylistSearchCondition } from 'src/app/models/common-id/common-id-playlist-search-condition';

// config
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

// mapper
import { CommonIdPlaylistSearchQueryParamsMapper } from '../../../../mappers/common-id/common-id-playlist-search-query-params-mapper';

@Component({
  selector: 'app-common-id-playlists-search-result',
  templateUrl: './playlists-search-result.component.html',
  styleUrls: ['./playlists-search-result.component.scss']
})
export class CommonIdPlaylistsSearchResultComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'おすすめ問題セット検索結果';

  @Input() playlists$: Observable<CommonIdPlaylist[]>;
  @Input() isPremiumUser: boolean;
  @Input() searchCondition: CommonIdPlaylistSearchCondition;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
  }

  goPlaylistDetail(playlistId: string) {
    const queryParams = CommonIdPlaylistSearchQueryParamsMapper.encodeCommonIdPlaylistSearchCondition(this.searchCondition);

    this.store.dispatch(
      navigate({
        url: RoutingPathResolver.resolveCommonIdPlaylistDetail(playlistId),
        extras: { queryParams }
      })
    );
  }
}
