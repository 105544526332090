<header class="top-header" [attr.data-is-show]="headerIsShow">
  <div class="top-header-inner">
    <div class="top-header-logo">
      <img src="assets/images/lp/student/logo-with-company.svg" alt="旺文社 入試正解デジタル" width="241" height="43" />
    </div>
    <div class="top-header-buttons">
      <button (click)="openManabiModal()">
        <img src="assets/images/lp/student/logo-manabi-id.svg" alt="まなびID" width="47" height="22" />
        <span class="font-bold text-sm">新規登録・ログイン</span>
      </button>
    </div>
  </div>
</header>
