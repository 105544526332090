<app-dialog-basic dialogTitle="アカウントの有効化" [isLoading]="disabled" *ngIf="!data.isActive">
  <div class="mb-8 text-md">
    <dl class="confirm-list">
      <dt>ユーザーID：</dt>
      <dd>{{ data.commonId }}</dd>
    </dl>
  </div>

  <div class="text-md">上記アカウントを有効にします。</div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="changeActiveAccount()" [disabled]="disabled">有効化する</button>
  </app-dialog-basic-footer>
</app-dialog-basic>

<app-dialog-basic dialogTitle="アカウントの無効化" [isLoading]="disabled" *ngIf="data.isActive">
  <div class="mb-8 text-md">
    <dl class="confirm-list">
      <dt>ユーザーID：</dt>
      <dd>{{ data.commonId }}</dd>
    </dl>
  </div>

  <div class="text-md">上記アカウントを無効にします。</div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="disabled"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="changeActiveAccount()" [disabled]="disabled">無効化する</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
