import { Component, OnInit, OnDestroy } from '@angular/core';
import { navigate, openWindow } from '../../../../actions/core.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { Store } from '@ngrx/store';
import { filter, shareReplay } from 'rxjs/operators';
import { RootState } from '../../../../reducers';
import { getCommonIdSignedInUser } from '../../../../selectors/common-id/common-id-auth.selectors';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { CommonIdUser } from '../../../../models/common-id/common-id-user';
import { NavigationEnd, Router } from '@angular/router';
import * as CommonIdInformationSelectors from '../../../../selectors/common-id/common-id-information.selectors';
import { CommonIdUserUtil } from '../../../../utils/common-id/common-id-user-util';
import { CurrentDateTime } from '../../../../models/current-date-time';
import { getCurrentDateTime } from '../../../../actions/current-date-time.actions';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../../selectors/current-date-time.selectors';
import { environment } from '../../../../../environments/environment';
import { CommonIdAuthService } from '../../../../services/common-id/common-id-auth.service';

@Component({
  selector: 'app-common-id-sign-in-header',
  templateUrl: './sign-in-header.component.html',
  styleUrls: ['./sign-in-header.component.scss']
})
export class CommonIdSignInHeaderComponent implements OnInit, OnDestroy {
  user$: Observable<CommonIdUser>;
  currentDateTime$: Observable<CurrentDateTime>;
  newInformation$: Observable<boolean>;
  isPremium$: Observable<boolean>;
  nickname$: Observable<string>;
  currentUrl: string;
  userInfoParentIsFocused = false;
  userInfoItemsIsFocused: boolean[] = [];

  private signedInUserIdSubscription: Subscription;

  constructor(private store: Store<RootState>, private router: Router, private commonIdAuthService: CommonIdAuthService) {}

  ngOnInit() {
    this.setUpUser();
    this.currentUrl = this.router.url;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
    });
    this.newInformation$ = this.store.select(CommonIdInformationSelectors.getNewInformation).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.signedInUserIdSubscription = this.commonIdAuthService.signedInUserId$.subscribe(userId => {
      if (!userId.includes('common-id')) {
        this.store.dispatch(openWindow({ url: RoutingPathResolver.resolveCommonIdSignOut(), target: '_self' }));
      }
    });
  }

  ngOnDestroy(): void {
    if (this.signedInUserIdSubscription) {
      this.signedInUserIdSubscription.unsubscribe();
    }
  }

  touchendUserInfoDropdown() {
    if (this.userInfoParentIsFocused) {
      setTimeout(() => {
        // 遅延させないと遷移が発生しなくなる
        this.resetUserInfoDropdown();
      }, 10);
    } else {
      this.userInfoParentIsFocused = true;
    }
  }

  hoverUserInfoDropdown() {
    this.userInfoParentIsFocused = true;
  }

  focusUserInfoParent() {
    this.userInfoParentIsFocused = true;
  }

  blurUserInfoParent() {
    this.userInfoParentIsFocused = false;
  }

  focusUserInfoItem(index: number) {
    this.userInfoItemsIsFocused[index] = true;
  }

  blurUserInfoItem(index: number) {
    this.userInfoItemsIsFocused[index] = false;
  }

  showUserInfoDropdown(): boolean {
    function checkItem(val: boolean): boolean {
      return val;
    }
    return this.userInfoParentIsFocused || this.userInfoItemsIsFocused.some(b => checkItem(b));
  }

  resetUserInfoDropdown() {
    this.userInfoParentIsFocused = false;
    this.userInfoItemsIsFocused = [];
  }

  showSearch() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchUniv() }));
  }

  showSearchByCategories() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSearchByCategories() }));
  }

  showPlaylists() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPlaylists() }));
  }

  showMylist() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdMylistAnswerProblems() }));
  }

  showHelp() {
    this.resetUserInfoDropdown();
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdHelp() }));
  }

  showInformation() {
    this.resetUserInfoDropdown();
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdInformation() }));
  }

  showPurchase() {
    this.resetUserInfoDropdown();
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPurchase() }));
  }

  showPayments() {
    this.resetUserInfoDropdown();
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPayments() }));
  }

  gotoProfile() {
    this.resetUserInfoDropdown();
    this.store.dispatch(openWindow({ url: environment.commonIdConfig.profileUrl }));
  }

  isCurrentSearch(): boolean {
    return this.currentUrl.includes(RoutingPathResolver.resolveCommonIdSearchUniv());
  }

  isCurrentSearchByCategories(): boolean {
    return this.currentUrl.includes(RoutingPathResolver.resolveCommonIdSearchByCategories());
  }

  isCurrentPlaylists(): boolean {
    return this.currentUrl.includes(RoutingPathResolver.resolveCommonIdPlaylists());
  }

  isCurrentMylist(): boolean {
    return this.currentUrl.includes(RoutingPathResolver.resolveCommonIdMylist());
  }

  signOut() {
    this.resetUserInfoDropdown();
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdSignOut() }));
  }

  private setUpUser() {
    this.store.dispatch(getCurrentDateTime());
    const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(
      filter<CommonIdUser>(it => it != null && it !== 'none'),
      shareReplay(1)
    );

    combineLatest([currentDateTime$, signedInUser$]).subscribe(([currentDateTime, signedInUser]) => {
      this.nickname$ = of(signedInUser.nickname);
      this.isPremium$ = of(CommonIdUserUtil.getIsPremiumUser(currentDateTime, signedInUser));
    });
  }
}
