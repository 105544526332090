import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ProblemResultClickParams {
  problemId: string;
  isPremiumMemberOnly: boolean;
}

@Component({
  selector: 'app-common-id-problem-list-item-small',
  templateUrl: './problem-list-item-small.component.html',
  styleUrls: ['./problem-list-item-small.component.scss']
})
export class CommonIdProblemListItemSmallComponent implements OnInit {
  @Input() problemId: string;
  @Input() isPremiumMemberOnly: boolean;
  @Input() subjectLabelStyleName: string;
  @Input() subjectName: string;
  @Input() universityName: string;
  @Input() year: string;
  @Input() daimonNumber: string;

  @Output() resultClick = new EventEmitter<ProblemResultClickParams>();

  constructor() {}

  private LOG_SOURCE = this.constructor.name;

  ngOnInit() {}
}
