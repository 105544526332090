import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

import * as StaticDataActions from '../actions/static-data.actions';
import { dispatchAppError } from '../actions/core.actions';
import { FirestoreError } from '../errors/firestore-error';
import { StaticDataService } from '../services/static-data.service';
import { AppError } from '../errors/app-error';
import { Dates } from '../utils/dates';

@Injectable()
export class StaticDataEffects {
  private LOG_SOURCE = this.constructor.name;

  findStaticData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StaticDataActions.findStaticData),
      switchMap(() =>
        this.staticDataService.findAllStaticData().pipe(
          map(allStaticData =>
            StaticDataActions.findStaticDataSuccess({
              common: allStaticData.common,
              english: allStaticData.english,
              math: allStaticData.math,
              nationalLanguage: allStaticData.nationalLanguage,
              physics: allStaticData.physics,
              chemistry: allStaticData.chemistry,
              biology: allStaticData.biology,
              japaneseHistory: allStaticData.japaneseHistory,
              worldHistory: allStaticData.worldHistory,
              geography: allStaticData.geography,
              politicalEconomy: allStaticData.politicalEconomy,
              fetchedDate: Dates.now()
            })
          ),
          catchError(e => {
            const error = e instanceof AppError ? e : FirestoreError.from(e);
            return of(StaticDataActions.findStaticDataFailure(), dispatchAppError({ source: this.LOG_SOURCE, error }));
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private staticDataService: StaticDataService) {}
}
