import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SignInComponent } from './views/components/sign-in/sign-in.component';
import { TermsAgreeComponent } from './views/components/terms-agree/terms-agree.component';
import { MainComponent } from './views/components/main/main.component';
import { AuthGuard } from './guards/auth.guard';
import { TermsGuard } from './guards/terms.guard';
import { SearchComponent } from './views/components/search/search.component';
import { SearchResultComponent } from './views/components/search-result/search-result.component';
import { PlaylistsComponent } from './views/components/playlists/playlists.component';
import { PlaylistDetailComponent } from './views/components/playlist-detail/playlist-detail.component';
import { PlaylistProblemDetailComponent } from './views/components/playlist-problem-detail/playlist-problem-detail.component';
import { AccountsComponent } from './views/components/accounts/accounts.component';
import { AdminGuard } from './guards/admin.guard';
import { SubjectGuard } from './guards/subject.guard';
import { CommonIdAuthGuard } from './guards/common-id/common-id-auth.guard';
import { CommonIdUnauthGuard } from './guards/common-id/common-id-unauth.guard';
import { UntryGuard } from './guards/untry.guard';
import { TryGuard } from './guards/try.guard';

import { PrintComponent } from './views/components/print/print.component';
import { ReportsComponent } from './views/components/reports/reports.component';
import { ToolsComponent } from './views/components/tools/tools.component';
import { ProblemDetailComponent } from './views/components/problem-detail/problem-detail.component';
import { UnauthGuard } from './guards/unauth.guard';
import { TermsComponent } from './views/components/terms/terms.component';
import { TokusyouhouComponent } from './views/components/tokusyouhou/tokusyouhou.component';
import { BookmarkProblemsComponent } from './views/components/bookmark-problems/bookmark-problems.component';
import { AdminComponent } from './views/components/admin/admin.component';
import { HelpComponent } from './views/components/help/help.component';
import { InformationComponent } from './views/components/information/information.component';
import { InformationDetailComponent } from './views/components/information-detail/information-detail.component';
import { PlansComponent } from './views/components/plans/plans.component';
import { OrganizationAdminGuard } from './guards/organizationAdminGuard.guard';
import { PlanStatusesComponent } from './views/components/plan-statuses/plan-statuses.component';
import { CheckActivePlanErrorComponent } from './views/components/check-active-plan-error/check-active-plan-error.component';
import { CheckActivePlanGuard } from './guards/checkActivePlanGuard.guard';
import { MembersComponent } from './views/components/members/members.component';
import { SchoolsComponent } from './views/components/schools/schools.component';
import { PlanAssignComponent } from './views/components/plan-assign/plan-assign.component';
import { TopComponent } from './views/components/top/top.component';
import { JukusComponent } from './views/components/jukus/jukus.component';
import { BulkMailComponent } from './views/components/bulk-mail/bulk-mail.component';
import { MaintenanceComponent } from './views/components/maintenance/maintenance.component';
import { CommonIdMainComponent } from './views/components/common-id/main/main.component';
import { CommonIdSignInCallbackComponent } from './views/components/common-id/sign-in-callback/sign-in-callback.component';
import { CommonIdSignInComponent } from './views/components/common-id/sign-in/sign-in.component';
import { CommonIdSignOutComponent } from './views/components/common-id/sign-out/sign-out.component';
import { CommonIdSearchUnivComponent } from './views/components/common-id/search-univ/search-univ.component';
import { CommonIdSearchUnivDetailComponent } from './views/components/common-id/search-univ-detail/search-univ-detail.component';
import { CommonIdProblemDetailComponent } from './views/components/common-id/problem-detail/problem-detail.component';
import { CommonIdSearchUnivPrefComponent } from './views/components/common-id/search-univ-pref/search-univ-pref.component';
import { CommonIdInformationComponent } from './views/components/common-id/information/information.component';
import { CommonIdInformationDetailComponent } from './views/components/common-id/information-detail/information-detail.component';
import { CommonIdPlaylistsComponent } from './views/components/common-id/playlists/playlists.component';
import { CommonIdPlaylistDetailComponent } from './views/components/common-id/playlist-detail/playlist-detail.component';
import { CommonIdPlaylistProblemDetailComponent } from './views/components/common-id/playlist-problem-detail/playlist-problem-detail.component';
import { CommonIdMylistUniversitiesComponent } from './views/components/common-id/mylist-universities/mylist-universities.component';
import { CommonIdPaymentsComponent } from './views/components/common-id/payments/payments.component';
import { CommonIdHelpComponent } from './views/components/common-id/help/help.component';
import { CommonIdPurchaseComponent } from './views/components/common-id/purchase/purchase.component';
import { CommonIdPurchaseCompleteComponent } from './views/components/common-id/purchase-complete/purchase-complete.component';
import { CommonIdMylistProblemsComponent } from './views/components/common-id/mylist-problems/mylist-problems.component';
import { CommonIdMylistPlaylistsComponent } from './views/components/common-id/mylist-playlists/mylist-playlists.component';
import { CommonIdAccountsComponent } from './views/components/common-id/accounts/accounts.component';
import { CommonIdAccountSearchComponent } from './views/components/common-id/account-search/account-search.component';
import { CommonIdTopComponent } from './views/components/common-id/top/top.component';
import { CommonIdPurchaseCallbackComponent } from './views/components/common-id/purchase-callback/purchase-callback.component';
import { CommonIdTermsComponent } from './views/components/common-id/terms/terms.component';
import { CommonIdTokusyouhouComponent } from './views/components/common-id/tokusyouhou/tokusyouhou.component';
import { EntranceComponent } from './views/components/entrance/entrance.component';
import { CommonIdSignInErrorComponent } from './views/components/common-id/sign-in-error/sign-in-error.component';
import { SearchUnivComponent } from './views/components/search-univ/search-univ.component';
import { SearchUnivPrefComponent } from './views/components/search-univ-pref/search-univ-pref.component';
import { SearchUnivDetailComponent } from './views/components/search-univ-detail/search-univ-detail.component';
import { TryProblemDetailComponent } from './views/components/try/problem-detail/problem-detail.component';
import { TryKakomonImageComponent } from './views/components/try/kakomon-image/kakomon-image.component';
import { CommonIdSearchByCategoriesComponent } from './views/components/common-id/search-by-categories/search-by-categories.component';
import { CommonIdSearchByCategoriesProblemDetailComponent } from './views/components/common-id/search-by-categories-problem-detail/search-by-categories-problem-detail.component';
import { CommonIdMylistAnswerProblemsComponent } from './views/components/common-id/mylist-answer-problems/mylist-answer-problems.component';

import { StyleGuideComponent } from './views/components/style-guide/style-guide.component';
import { MembersImportComponent } from './views/components/members-import/members-import.component';
import { ToolsDeleteMembersComponent } from './views/components/tools-delete-members/tools-delete-members.component';

const routes: Routes = [
  { path: '', component: EntranceComponent },
  { path: 'lp', component: TopComponent },
  { path: 'sign-in', component: SignInComponent, canActivate: [UnauthGuard] },
  { path: 'terms-agree', component: TermsAgreeComponent, canActivate: [AuthGuard, UntryGuard] },
  { path: 'terms', component: TermsComponent },
  { path: 'tokusyouhou', component: TokusyouhouComponent },
  { path: 'print', component: PrintComponent, canActivate: [TermsGuard, UntryGuard, SubjectGuard, CheckActivePlanGuard] },
  {
    path: 'search-results/:problemId/detail',
    component: ProblemDetailComponent,
    canActivate: [TermsGuard, UntryGuard, SubjectGuard, CheckActivePlanGuard]
  },
  {
    path: 'playlists/:playlistId/detail/:themeId/:problemId',
    component: PlaylistProblemDetailComponent,
    canActivate: [TermsGuard, UntryGuard, SubjectGuard, CheckActivePlanGuard]
  },
  { path: 'maintenance', component: MaintenanceComponent },
  // { path: 'style-guide', component: StyleGuideComponent },

  { path: 'try-kakomon-preview', component: TryProblemDetailComponent, canActivate: [TryGuard] },
  { path: 'try-kakomon', component: TryKakomonImageComponent },

  {
    path: '',
    component: MainComponent,
    canActivate: [TermsGuard, UntryGuard],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: 'search', component: SearchComponent, canActivate: [CheckActivePlanGuard] },
      { path: 'search-results', component: SearchResultComponent, canActivate: [SubjectGuard, CheckActivePlanGuard] },
      { path: 'search-univ', component: SearchUnivComponent, canActivate: [CheckActivePlanGuard] },
      { path: 'search-univ-pref', component: SearchUnivPrefComponent, canActivate: [CheckActivePlanGuard] },
      { path: 'search-univ-detail/:universityId', component: SearchUnivDetailComponent, canActivate: [CheckActivePlanGuard] },
      { path: 'bookmark-problems', component: BookmarkProblemsComponent, canActivate: [CheckActivePlanGuard] },
      { path: 'playlists', component: PlaylistsComponent, canActivate: [CheckActivePlanGuard] },
      { path: 'playlists/:playlistId', component: PlaylistDetailComponent, canActivate: [SubjectGuard, CheckActivePlanGuard] },
      { path: 'help', component: HelpComponent },
      { path: 'information', component: InformationComponent },
      { path: 'information-detail/:informationId', component: InformationDetailComponent },

      // for organization or admin
      { path: 'admin/plans/:organization/:schoolId', component: PlansComponent, canActivate: [OrganizationAdminGuard] },
      { path: 'admin/plans', component: PlansComponent, canActivate: [OrganizationAdminGuard] },
      { path: 'admin/plan-statuses/:planId', component: PlanStatusesComponent, canActivate: [OrganizationAdminGuard] },
      { path: 'admin/members/:organization/:schoolId/import', component: MembersImportComponent, canActivate: [OrganizationAdminGuard] },
      { path: 'admin/members/import', component: MembersImportComponent, canActivate: [OrganizationAdminGuard] },
      { path: 'admin/members/:organization/:schoolId', component: MembersComponent, canActivate: [OrganizationAdminGuard] },
      { path: 'admin/members', component: MembersComponent, canActivate: [OrganizationAdminGuard] },
      { path: 'admin/plan-assign/:planId', component: PlanAssignComponent, canActivate: [OrganizationAdminGuard] },

      // for admin --------------
      { path: 'admin', component: AdminComponent, canActivate: [AdminGuard] },
      { path: 'admin/c-accounts', component: CommonIdAccountsComponent, canActivate: [AdminGuard] },
      { path: 'admin/c-account-search', component: CommonIdAccountSearchComponent, canActivate: [AdminGuard] },
      { path: 'admin/accounts', component: AccountsComponent, canActivate: [AdminGuard] },
      { path: 'admin/schools', component: SchoolsComponent, canActivate: [AdminGuard] },
      { path: 'admin/reports', component: ReportsComponent, canActivate: [AdminGuard] },
      { path: 'admin/tools', component: ToolsComponent, canActivate: [AdminGuard] },
      { path: 'admin/tools/delete-members', component: ToolsDeleteMembersComponent, canActivate: [AdminGuard] },
      { path: 'admin/jukus', component: JukusComponent, canActivate: [AdminGuard] },
      { path: 'admin/bulk-mail', component: BulkMailComponent, canActivate: [AdminGuard] },
      // for error --------------
      { path: 'check-active-plan-error', component: CheckActivePlanErrorComponent }
    ]
  },
  { path: 'c', component: CommonIdTopComponent },
  { path: 'c/sign-in/error', component: CommonIdSignInErrorComponent, canActivate: [CommonIdUnauthGuard] },
  { path: 'c/sign-in/callback', component: CommonIdSignInCallbackComponent, canActivate: [CommonIdUnauthGuard] },
  { path: 'c/sign-in', component: CommonIdSignInComponent, canActivate: [CommonIdUnauthGuard] },
  { path: 'c/sign-out', component: CommonIdSignOutComponent },
  // { path: 'c/purchase/callback', component: CommonIdPurchaseCallbackComponent, canActivate: [CommonIdAuthGuard] },
  { path: 'c/terms', component: CommonIdTermsComponent },
  { path: 'c/tokusyouhou', component: CommonIdTokusyouhouComponent },
  {
    path: 'c',
    component: CommonIdMainComponent,
    canActivate: [CommonIdAuthGuard],
    children: [
      { path: 'exam/:paperId', component: CommonIdProblemDetailComponent },
      { path: 'search-univ', component: CommonIdSearchUnivComponent },
      { path: 'search-univ-pref', component: CommonIdSearchUnivPrefComponent },
      { path: 'search-univ-detail/:universityId', component: CommonIdSearchUnivDetailComponent },
      { path: 'search-by-categories', component: CommonIdSearchByCategoriesComponent },
      { path: 'search-by-categories/:problemId/detail', component: CommonIdSearchByCategoriesProblemDetailComponent },
      { path: 'playlists/:playlistId/detail/:themeId/:problemId', component: CommonIdPlaylistProblemDetailComponent },
      { path: 'playlists/:playlistId', component: CommonIdPlaylistDetailComponent },
      { path: 'playlists', component: CommonIdPlaylistsComponent },
      { path: 'purchase', component: CommonIdPurchaseComponent },
      { path: 'purchase/complete', component: CommonIdPurchaseCompleteComponent },
      { path: 'mylist/universities', component: CommonIdMylistUniversitiesComponent },
      { path: 'mylist/problems', component: CommonIdMylistProblemsComponent },
      { path: 'mylist/answer-problems', component: CommonIdMylistAnswerProblemsComponent },
      { path: 'mylist/playlists', component: CommonIdMylistPlaylistsComponent },
      { path: 'payments', component: CommonIdPaymentsComponent },
      { path: 'help', component: CommonIdHelpComponent },
      { path: 'information', component: CommonIdInformationComponent },
      { path: 'information-detail/:informationId', component: CommonIdInformationDetailComponent },
      { path: '**', redirectTo: 'search-univ', pathMatch: 'full' }
    ]
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
