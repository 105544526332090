<app-try-sign-in-header></app-try-sign-in-header>
<app-content-row data-no-print>
  <app-content-row>
    <app-content-row>
      <app-content-row>
        <div data-no-print>
          <div *ngIf="!isPreconditionError">
            <div [@fadeInOut]="(searching$ | async) === false">
              <app-content-row *ngIf="(problem$ | async)?.hasExternalData; else noExternalData">
                <app-wrapper size="xl" isFitWithSmallScreen="true">
                  <!-- ▼ テスト表示領域 -->
                  <app-problem-detail-frame
                    [readableScienceProblems]="readableScienceProblems$ | async"
                    [readableEnglishProblems]="readableEnglishProblems$ | async"
                    [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
                    [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
                    [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
                    [paperMode]="paperMode$ | async"
                    [tryMode]="true"
                    [trialMode]="trialMode$ | async"
                    [canDisplay]="canDisplay$ | async"
                    [tryDaimonId]="daimonId$ | async"
                    [subjectId]="subjectId$ | async"
                    (menuTypeChangeHandler)="changeMenuType($event)"
                  ></app-problem-detail-frame>
                  <!-- △ テスト表示領域 -->
                </app-wrapper>
              </app-content-row>
            </div>

            <div class="progress-48" [@fadeInOut]="searching$ | async">
              <mat-spinner [diameter]="48"></mat-spinner>
            </div>
          </div>

          <ng-template #noExternalData>
            <div class="text-center text-nega">
              <app-problem-detail-no-external-data></app-problem-detail-no-external-data>
            </div>
          </ng-template>
        </div>
      </app-content-row>
    </app-content-row>
  </app-content-row>
</app-content-row>
<!-- △ 印刷時は消す ここまで -->
