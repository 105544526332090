import { Action, createReducer, on } from '@ngrx/store';
import * as CommonIdUserActions from '../../actions/common-id/common-id-user.actions';
import { CommonIdUser } from '../../models/common-id/common-id-user';
import { AppError } from '../../errors/app-error';

export const commonIdUserFeatureKey = 'commonIdUser';

export interface UpdateCommonIdUserIsActiveResult {
  success: boolean;
  updatedUser: CommonIdUser | null;
  error: AppError | null;
}

export interface UpdateCommonIdUserResult {
  success: boolean;
  updatedUser: string | null;
  error: AppError | null;
}

export interface State {
  findCommonIdUser: CommonIdUser | null;
  isCommonIdUserSearching: boolean;
  isCommonIdUserNotFound: boolean;
  watchedCommonIdUsers: CommonIdUser[] | null;
  updateCommonIdUserIsActiveResult: UpdateCommonIdUserIsActiveResult | null;
  updateCommonIdUserResult: UpdateCommonIdUserResult | null;
}

export const initialState: State = {
  findCommonIdUser: null,
  isCommonIdUserSearching: false,
  isCommonIdUserNotFound: false,
  watchedCommonIdUsers: null,
  updateCommonIdUserIsActiveResult: null,
  updateCommonIdUserResult: null
};

const commonIdUserReducer = createReducer(
  initialState,

  // Find Common ID User  ----------------------------------------------------------------
  on(CommonIdUserActions.findCommonIdUser, state => ({ ...state, isCommonIdUserSearching: true })),
  on(CommonIdUserActions.findCommonIdUserSuccess, (state, { user }) => ({
    ...state,
    findCommonIdUser: user,
    isCommonIdUserSearching: false
  })),
  on(CommonIdUserActions.findCommonIdUserFailure, state => ({ ...state, isCommonIdUserSearching: false })),
  on(CommonIdUserActions.initializeFindCommonIdUser, state => {
    const next: State = { ...state, findCommonIdUser: null, isCommonIdUserSearching: false };
    return next;
  }),

  // Find Common ID User By Email --------------------------------------------------------
  on(CommonIdUserActions.findCommonIdUserByEmail, state => ({ ...state, isCommonIdUserSearching: true })),
  on(CommonIdUserActions.findCommonIdUserByEmailSuccess, (state, { user }) => ({
    ...state,
    findCommonIdUser: user,
    isCommonIdUserSearching: false
  })),
  on(CommonIdUserActions.findCommonIdUserByEmailNotFound, state => ({
    ...state,
    isCommonIdUserNotFound: true,
    isCommonIdUserSearching: false
  })),
  on(CommonIdUserActions.findCommonIdUserByEmailFailure, state => ({ ...state, isCommonIdUserSearching: false })),
  on(CommonIdUserActions.initializeFindCommonIdUserByEmail, state => {
    const next: State = { ...state, findCommonIdUser: null, isCommonIdUserSearching: false, isCommonIdUserNotFound: false };
    return next;
  }),

  // Find Common ID User By Common ID ----------------------------------------------------
  on(CommonIdUserActions.findCommonIdUserByCommonId, state => ({ ...state, isCommonIdUserSearching: true })),
  on(CommonIdUserActions.findCommonIdUserByCommonIdSuccess, (state, { user }) => ({
    ...state,
    findCommonIdUser: user,
    isCommonIdUserSearching: false
  })),
  on(CommonIdUserActions.findCommonIdUserByCommonIdNotFound, state => ({
    ...state,
    isCommonIdUserNotFound: true,
    isCommonIdUserSearching: false
  })),
  on(CommonIdUserActions.findCommonIdUserByCommonIdFailure, state => ({ ...state, isCommonIdUserSearching: false })),
  on(CommonIdUserActions.initializeFindCommonIdUserByCommonId, state => {
    const next: State = { ...state, findCommonIdUser: null, isCommonIdUserSearching: false, isCommonIdUserNotFound: false };
    return next;
  }),

  // Watch Common ID Users ----------------------------------------------------------------
  on(CommonIdUserActions.watchCommonIdUsersSuccess, (state, { users }) => ({ ...state, watchedCommonIdUsers: users })),
  on(CommonIdUserActions.initializeWatchedCommonIdUsers, state => {
    const next: State = { ...state, watchedCommonIdUsers: null };
    return next;
  }),

  // Update Common ID User Is Active ----------------------------------------------------------------
  on(CommonIdUserActions.updateCommonIdUserIsActiveSuccess, (state, { user }) => {
    const next: State = {
      ...state,
      updateCommonIdUserIsActiveResult: { success: true, updatedUser: user, error: null }
    };
    return next;
  }),
  on(CommonIdUserActions.updateCommonIdUserIsActiveFailure, (state, { error }) => {
    const next: State = {
      ...state,
      updateCommonIdUserIsActiveResult: { success: false, updatedUser: null, error }
    };
    return next;
  }),
  on(CommonIdUserActions.initializeUpdateCommonIdUserIsActiveState, state => {
    const next: State = { ...state, updateCommonIdUserIsActiveResult: null };
    return next;
  }),

  // Update Common ID User ----------------------------------------------------------------
  on(CommonIdUserActions.updateCommonIdUserSuccess, (state, { userId }) => {
    const next: State = {
      ...state,
      updateCommonIdUserResult: { success: true, updatedUser: userId, error: null }
    };
    return next;
  }),
  on(CommonIdUserActions.updateCommonIdUserFailure, (state, { error }) => {
    const next: State = {
      ...state,
      updateCommonIdUserResult: { success: false, updatedUser: null, error }
    };
    return next;
  }),
  on(CommonIdUserActions.initializeUpdateCommonIdUserState, state => {
    const next: State = { ...state, updateCommonIdUserResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return commonIdUserReducer(state, action);
}
