<div
  class="accordion-list-item"
  [attr.aria-expanded]="isExpanded"
  [attr.data-openable-in-pc]="openableInPc"
  [attr.data-fixed-accordion]="fixedAccordion"
  [attr.data-size]="size"
>
  <div class="accordion-list-item-head">
    <button class="accordion-list-item-head-hit" (click)="toggleIsExpanded()" [disabled]="fixedAccordion">
      <div class="accordion-list-item-head-label">{{ questionTitle }}</div>
      <div class="accordion-list-item-head-arrow" *ngIf="!fixedAccordion">
        <mat-icon *ngIf="!isExpanded" class="icon" aria-hidden="true">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="isExpanded" class="icon" aria-hidden="true">keyboard_arrow_up</mat-icon>
      </div>
    </button>
  </div>
  <div class="accordion-list-item-body" [attr.aria-hidden]="!isExpanded">
    <app-provider-accordion [isExpanded]="isExpanded || fixedAccordion">
      <div class="accordion-list-item-body-inner">
        <div class="accordion-list-item-body-content">
          <ng-content></ng-content>
        </div>
      </div>
    </app-provider-accordion>
  </div>
</div>
