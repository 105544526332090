import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

// Redux
import { findCommonIdUser, initializeFindCommonIdUser } from 'src/app/actions/common-id/common-id-user.actions';
import { openWindow } from 'src/app/actions/core.actions';
import { getCommonIdUser } from 'src/app/selectors/common-id/common-id-user.selectors';
import { RootState } from 'src/app/reducers';

// models
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { AccountTableData } from 'src/app/models/account-table-data';

// config
import {
  UPDATE_ACCOUNT_DIALOG_WIDTH,
  UPDATE_ACCOUNT_DIALOG_HEIGHT,
  CHANGE_ACTIVE_ACCOUNT_DIALOG_WIDTH,
  DIALOG_ZERO_PADDING_PANEL_CLASS
} from 'src/app/resources/common-id-config';

// components
import {
  CommonIdChangeActiveAccountDialogComponent,
  CommonIdChangeActiveAccountDialogData
} from '../../../components/common-id/change-active-account-dialog/change-active-account-dialog.component';
import { CommonIdUpdateAccountDialogComponent } from '../../../components/common-id/update-account-dialog/update-account-dialog.component';

// others
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-common-id-accounts-table',
  templateUrl: './accounts-table.component.html',
  styleUrls: ['./accounts-table.component.scss']
})
export class CommonIdAccountsTableComponent implements OnInit {
  @Input() dataSource: any[];
  @Input() displayedColumns: string[];
  @Input() isSingleSearch: boolean;
  @Input() commonIdUser: CommonIdUser;
  @Output() accountUpdated = new EventEmitter<void>();

  commonIdUser$: Observable<CommonIdUser>;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  ngOnInit() {}

  updateAccount(userId: string) {
    if (this.isSingleSearch) {
      this.openAccountDialog(this.commonIdUser);
    } else {
      this.store.dispatch(initializeFindCommonIdUser());
      this.store.dispatch(findCommonIdUser({ userId }));
      this.commonIdUser$ = this.store.select(getCommonIdUser).pipe(filter(it => it != null));

      combineLatest([this.commonIdUser$])
        .pipe(take(1))
        .subscribe(([commonIdUser]) => {
          this.openAccountDialog(commonIdUser);
        });
    }
  }

  changeActiveAccount(account: AccountTableData) {
    const data: CommonIdChangeActiveAccountDialogData = {
      userId: account.id,
      commonId: account.commonId,
      isActive: account.isActive
    };
    const config: MatDialogConfig = {
      width: CHANGE_ACTIVE_ACCOUNT_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      data,
      disableClose: true
    };
    this.dialog
      .open(CommonIdChangeActiveAccountDialogComponent, config)
      .afterClosed()
      .subscribe(() => this.accountUpdated.emit());
  }

  goToStripe(userId: string) {
    const url = environment.stripeConfig.userPaymentsUrl.replace('<user-id>', userId);
    this.store.dispatch(openWindow({ url }));
  }

  private openAccountDialog(commonIdUser: CommonIdUser) {
    const config: MatDialogConfig = {
      height: UPDATE_ACCOUNT_DIALOG_HEIGHT,
      width: UPDATE_ACCOUNT_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data: commonIdUser,
      disableClose: true
    };
    this.dialog
      .open(CommonIdUpdateAccountDialogComponent, config)
      .afterClosed()
      .subscribe(result => {
        if (result) this.accountUpdated.emit();
      });
  }
}
