import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormControl, Validators } from '@angular/forms';

export interface SingleInputDialogData {
  title: string;
  description: string;
  inputFieldPlaceholder: string;
  inputValidationPattern?: string | RegExp;
  patternErrorMessage?: string;
  positiveButtonLabel?: string;
  negativeButtonLabel?: string;
}

export interface SingleInputDialogResult {
  inputValue: string;
}

export type SingleInputDialogResultType = SingleInputDialogResult | undefined;

@Component({
  selector: 'app-single-input-dialog',
  templateUrl: './single-input-dialog.component.html',
  styleUrls: ['./single-input-dialog.component.scss']
})
export class SingleInputDialogComponent implements OnInit {
  control: UntypedFormControl;

  constructor(
    private dialogRef: MatDialogRef<SingleInputDialogComponent, SingleInputDialogResultType>,
    @Inject(MAT_DIALOG_DATA) public data: SingleInputDialogData
  ) {
    const validators = data.inputValidationPattern
      ? [Validators.required, Validators.pattern(data.inputValidationPattern)]
      : [Validators.required];
    this.control = new UntypedFormControl('', validators);
  }

  ngOnInit() {}

  onPositiveClick() {
    if (this.control.invalid) {
      this.control.markAsTouched();
      return;
    }

    const inputValue = this.control.value;
    this.dialogRef.close({ inputValue });
  }

  onNegativeClick() {
    this.dialogRef.close();
  }
}
