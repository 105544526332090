import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, of, combineLatest, Subscription } from 'rxjs';
import { filter, shareReplay, map, startWith } from 'rxjs/operators';

// Redux
import { navigate, setTitle } from 'src/app/actions/core.actions';
import { setCommonIdBrowserTitle } from 'src/app/actions/common-id/common-id-core.actions';
import { findPlaylists } from 'src/app/actions//common-id/common-id-playlist.actions';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import { RootState } from 'src/app/reducers';
import { getPlaylists } from 'src/app/selectors/common-id/common-id-playlist.selectors';
import * as StaticDataSelectors from '../../../../selectors/static-data.selectors';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';

// models
import { Subject } from 'src/app/models/common-data';
import { CurrentDateTime } from 'src/app/models/current-date-time';
import { CommonIdPlaylist } from 'src/app/models/common-id/common-id-playlist';
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { CommonIdPlaylistSearchCondition } from 'src/app/models/common-id/common-id-playlist-search-condition';

// utils
import { DataUtil } from 'src/app/utils/data-util';
import { Dates } from 'src/app/utils/dates';
import { CommonIdUserUtil } from 'src/app/utils/common-id/common-id-user-util';

// components & services
import { CommonIdPlaylistsRouterService } from './../../../../services/common-id/common-id-playlists-router-service';

// config
import { EXCLUDE_PLAYLIST_TAGS } from 'src/app/resources/common-id-config';
import { PLAYLISTS_SUBJECT_ALL_ID, PlaylistSortType } from 'src/app/resources/config';
import { enter } from 'src/app/resources/animations';
import { CommonIdPlaylistSearchQueryParamsMapper } from 'src/app/mappers/common-id/common-id-playlist-search-query-params-mapper';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

@Component({
  selector: 'app-common-id-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss'],
  animations: [enter]
})
export class CommonIdPlaylistsComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'おすすめ問題セット';
  private routerSubscription: Subscription;

  SUBJECT_ALL_ID = PLAYLISTS_SUBJECT_ALL_ID;

  playlists$: Observable<CommonIdPlaylist[]>;
  subjects$: Observable<Subject[]>;
  loaded$: Observable<boolean>;
  signedInUser$: Observable<CommonIdUser>;
  isPremiumUser: boolean;
  currentDateTime: CurrentDateTime;
  currentDateTimeString: string;
  currentDateTime$: Observable<CurrentDateTime>;

  playlistsTags$: Observable<string[]>;

  currentUrl: string;
  selectedSearchCondition: CommonIdPlaylistSearchCondition;

  constructor(
    private store: Store<RootState>,
    private router: Router,
    private location: Location,
    private commonIdPlaylistsRouterService: CommonIdPlaylistsRouterService
  ) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.loaded$ = of(false);
    this.setUpUser();
    this.setUpSubjects();

    // URL全体の変更を監視 - 変更があるたびに、クエリパラメタから検索条件を取得し、その条件で検索結果を表示
    this.routerSubscription = this.router.events.subscribe(() => {
      if (this.currentUrl !== this.router.url) {
        this.currentUrl = this.router.url;
        const condition = this.commonIdPlaylistsRouterService.getConditionFromQueryParams(this.currentUrl);
        this.selectedSearchCondition = this.setUpSelectedSearchCondition(condition);
        this.setUpPlaylists(this.selectedSearchCondition);
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  goBack() {
    this.location.back();
  }

  setUpPlaylists(condition: CommonIdPlaylistSearchCondition) {
    this.store.dispatch(findPlaylists());

    this.store.dispatch(getCurrentDateTime());
    const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const playlists$ = this.store.select(getPlaylists).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([currentDateTime$, this.signedInUser$]).subscribe(([currentDateTime, signedInUser]) => {
      this.currentDateTime = currentDateTime;
      this.currentDateTimeString = Dates.stdDateStringFromIso(this.currentDateTime?.dateTime);

      this.isPremiumUser = CommonIdUserUtil.getIsPremiumUser(this.currentDateTime, signedInUser);

      this.playlists$ = playlists$.pipe(
        map(playlists => {
          const filteredPlaylists = this.filterPlaylists(playlists, condition.subjectId);
          const filteredAndSortedPlaylists = this.sortPlaylists(
            filteredPlaylists,
            this.selectedSearchCondition.sortType,
            this.isPremiumUser
          );

          // タグ一覧に表示するタグを取得
          if (condition.subjectId === this.SUBJECT_ALL_ID) {
            // 全科目の場合は上位20件に絞り込む
            this.playlistsTags$ = of(this.getTags(filteredAndSortedPlaylists).slice(0, 20));
          } else {
            this.playlistsTags$ = of(this.getTags(filteredAndSortedPlaylists));
          }

          if (condition.tag) {
            // 指定されたタグで検索結果をさらに絞り込み
            const result = filteredAndSortedPlaylists.filter(playlist =>
              condition.tag === '' ? true : playlist.tags.find(playlistTag => playlistTag === condition.tag) !== undefined
            );
            return result;
          } else {
            return filteredAndSortedPlaylists;
          }
        })
      );

      this.loaded$ = this.playlists$.pipe(
        map(it => it != null),
        startWith(false)
      );
    });
  }

  clickTag(tag: string) {
    this.selectedSearchCondition.tag = tag;
    const queryParams = CommonIdPlaylistSearchQueryParamsMapper.encodeCommonIdPlaylistSearchCondition(this.selectedSearchCondition);
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPlaylists(), extras: { queryParams } }));
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(filter<CommonIdUser>(it => it != null && it !== 'none'));
  }

  private setUpSubjects() {
    this.subjects$ = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpSelectedSearchCondition(condition: CommonIdPlaylistSearchCondition): CommonIdPlaylistSearchCondition {
    if (condition === undefined || Object.keys(condition).length === 0) {
      const initializedCondition = this.getInitializedSearchCondition();
      return initializedCondition;
    } else {
      return condition;
    }
  }

  private getInitializedSearchCondition(): CommonIdPlaylistSearchCondition {
    const condition: CommonIdPlaylistSearchCondition = {
      subjectId: this.SUBJECT_ALL_ID,
      sortType: PlaylistSortType.PUBLISHED_DATE,
      free: true
    };
    return condition;
  }

  private sortPlaylists(playlists: CommonIdPlaylist[], sortType: PlaylistSortType, isPremiumUser: boolean): CommonIdPlaylist[] {
    // 無料会員で、無料問題を優先する場合は、無料の問題を優先して表示
    if (!isPremiumUser && this.selectedSearchCondition.free) {
      const freePlaylists = playlists.filter(playlist => playlist.freeFlg === true);
      const sortedFreePlaylists = this.sortPlaylistsBySortType(freePlaylists, sortType);
      const chargedPlaylists = playlists.filter(playlist => playlist.freeFlg === false);
      const sortedChargedPlaylists = this.sortPlaylistsBySortType(chargedPlaylists, sortType);
      return sortedFreePlaylists.concat(sortedChargedPlaylists);
    } else {
      return this.sortPlaylistsBySortType(playlists, sortType);
    }
  }

  private sortPlaylistsBySortType(playlists: CommonIdPlaylist[], sortType: PlaylistSortType): CommonIdPlaylist[] {
    let sortedPlaylists: CommonIdPlaylist[] = playlists;

    if (sortType === PlaylistSortType.PUBLISHED_DATE) {
      sortedPlaylists = [...playlists].sort((a, b) => {
        // 更新日時の降順のみでソート
        if (a.publishedDate < b.publishedDate) return 1;
        if (a.publishedDate > b.publishedDate) return -1;
        // 更新日時が同じなら、科目順にソート
        if (a.playlistId < b.playlistId) return -1;
        if (a.playlistId > b.playlistId) return 1;
        return 0;
      });
    } else if (sortType === PlaylistSortType.PLAYLIST_ID_ASC) {
      sortedPlaylists = DataUtil.sortObjectArrayBySpecificKey(playlists, 'playlistId');
    } else if (sortType === PlaylistSortType.PLAYLIST_ID_DESC) {
      sortedPlaylists = DataUtil.sortObjectArrayBySpecificKey(playlists, 'playlistId', 'desc');
    } else if (sortType === PlaylistSortType.DURATION) {
      sortedPlaylists = this.sortPlaylistsByDuration(playlists);
    }
    return sortedPlaylists;
  }

  private sortPlaylistsByDuration(playlists: CommonIdPlaylist[]): CommonIdPlaylist[] {
    // 目安時間を分に換算
    const playlistsDurationEdited = playlists.map(playlist => {
      let durationEdited: number;
      if (playlist.duration.includes('時間')) {
        const duration = playlist.duration.split('時間');
        const hours = Number(duration[0]);
        const minutes = Number(duration[1].split('分')[0]);
        durationEdited = hours * 60 + minutes;
      } else {
        const duration = playlist.duration.split('分');
        durationEdited = Number(duration[0]);
      }

      return { ...playlist, durationEdited };
    });

    // 換算した目安時間と教科順でソートをかける
    const sortedPlaylistsDurationEdited = playlistsDurationEdited.sort((a, b) => {
      // 換算した目安時間の昇順でのみソート
      if (a.durationEdited < b.durationEdited) return -1;
      if (a.durationEdited > b.durationEdited) return 1;
      // 目安時間が同じなら、科目順にソート
      if (a.playlistId < b.playlistId) return -1;
      if (a.playlistId > b.playlistId) return 1;
      return 0;
    });

    // 換算した目安時間をオブジェクトから削除
    const sortedPlaylists = sortedPlaylistsDurationEdited.map(sortedPlaylistDurationEdited => {
      delete sortedPlaylistDurationEdited.durationEdited;
      return sortedPlaylistDurationEdited;
    });

    return sortedPlaylists;
  }

  private filterPlaylists(playlists: CommonIdPlaylist[], subjectId: string) {
    // 教科ごとにデータを絞り込み
    const playlistsFilteredBySubject =
      subjectId && subjectId !== this.SUBJECT_ALL_ID ? playlists.filter(playlist => playlist.subjectId === subjectId) : playlists;

    return playlistsFilteredBySubject;
  }

  private getTags(playlists: CommonIdPlaylist[]): string[] {
    const playlistsAllTags = playlists
      .map(playlist => {
        return playlist.tags;
      })
      .flat();

    // 紐づく問題数の多い順にタグを並び替え
    const sortedTagsByFrequency = this.sortByFrequency(playlistsAllTags);

    // 重複データの削除
    const tagsWithoutDupulicates = sortedTagsByFrequency.filter((item, index) => playlistsAllTags.indexOf(item) === index);

    // 除外データの削除
    const playlistsTags = tagsWithoutDupulicates.filter(tag => !EXCLUDE_PLAYLIST_TAGS.includes(tag));

    return playlistsTags;
  }

  private sortByFrequency(arr: string[]): string[] {
    // 出現回数をカウントするオブジェクトを作成
    const frequency: { [key: string]: number } = {};
    arr.forEach(value => {
      frequency[value] = (frequency[value] || 0) + 1;
    });

    // 出現回数が多い順にソート
    arr.sort((a, b) => {
      // 出現回数が同じ場合は文字コード順
      if (frequency[a] === frequency[b]) {
        return a.localeCompare(b);
      }
      return frequency[b] - frequency[a];
    });

    return arr;
  }
}
