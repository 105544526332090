import { createAction, props } from '@ngrx/store';

import { CreateJukuData, UpdateJukuData, Juku } from '../models/juku';
import { AppError } from '../errors/app-error';
import { DeleteJukuResponse } from '../models/delete-juku-response';

// findJuku ------------------------------------------------------------
export const findJuku = createAction('[Juku] Find Juku', props<{ jukuId: string }>());
export const findJukuSuccess = createAction('[Juku] Find Juku Success', props<{ juku: Juku }>());
export const findJukuFailure = createAction('[Juku] Find Juku Failure');
export const initializeFindJukuState = createAction('[Juku] Initialize Find Juku State');

// findJukus ------------------------------------------------------------
export const findJukus = createAction(
  '[Juku] Find Jukus',
  props<{ jukuId?: string; jukuCode?: string; jukuName?: string; jukuSubName?: string; prefectureId?: string }>()
);
export const findJukusSuccess = createAction('[Juku] Find Jukus Success', props<{ jukus: Juku[] }>());
export const findJukusFailure = createAction('[Juku] Find Jukus Failure');
export const initializeFindJukusState = createAction('[Juku] Initialize Find Jukus State');

export const createJuku = createAction('[Juku] Create Juku', props<{ juku: CreateJukuData }>());
export const createJukuSuccess = createAction('[Juku] Create Juku Success', props<{ juku: Juku }>());
export const createJukuFailure = createAction('[Juku] Create Juku Failure', props<{ error: AppError }>());
export const initializeCreateJukuState = createAction('[Juku] Initialize Crate Juku State');

export const updateJuku = createAction('[Juku] Update Juku', props<{ juku: UpdateJukuData }>());
export const updateJukuSuccess = createAction('[Juku] Update Juku Success', props<{ juku: Juku }>());
export const updateJukuFailure = createAction('[Juku] Update Juku Failure', props<{ error: AppError }>());
export const initializeUpdateJukuState = createAction('[Juku] Initialize Update Juku State');

export const deleteJuku = createAction('[Juku] Delete Juku', props<{ id: string }>());
export const deleteJukuSuccess = createAction('[Juku] Delete Juku Success', props<{ response: DeleteJukuResponse }>());
export const deleteJukuFailure = createAction('[Juku] Delete Juku Failure', props<{ error: AppError }>());
export const initializeDeleteJukuState = createAction('[Juku] Initialize Delete Juku State');
