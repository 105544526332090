import { Action, createReducer, on } from '@ngrx/store';

import * as BulkMailActions from '../actions/bulk-mail.action';
import { AppError } from '../errors/app-error';
import { SendBulkMailResponse } from '../models/bulk-email-response';

export interface SendBulkMailResult {
  success: boolean;
  response: SendBulkMailResponse | null;
  error: AppError | null;
}

export interface State {
  //  bulkMail: SendBulkMailRequest | null;
  sendBulkMailResult: SendBulkMailResult | null;
}

export const initialState: State = {
  //  bulkMail: null,
  sendBulkMailResult: null
};

const BulkMailReducer = createReducer(
  initialState,
  on(BulkMailActions.sendBulkMailSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      sendBulkMailResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(BulkMailActions.sendBulkMailFailure, (state, { error }) => {
    const next: State = {
      ...state,
      sendBulkMailResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(BulkMailActions.initializeSendBulkMailState, state => {
    const next: State = { ...state, sendBulkMailResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return BulkMailReducer(state, action);
}
