import { Component, HostListener, Input, OnInit } from '@angular/core';
import { RESPONSIVE_WIDTH } from '../../../resources/config';

@Component({
  selector: 'app-accordion-list-item',
  templateUrl: './accordion-list-item.component.html',
  styleUrls: ['./accordion-list-item.component.scss']
})
export class AccordionListItemComponent implements OnInit {
  constructor() {}
  @Input() questionTitle: string;
  @Input() isExpanded: boolean;
  @Input() openableInPc = true;
  @Input() questionContent: string;
  @Input() size: 'sm' | 'md' = 'md';
  // @Input() isResponsive = false; // 参: #427 #1216

  public fixedAccordion = false;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.resizeObservable(event.target.innerWidth);
  }

  /**
   * リサイズを監視し、PCサイズの場合はアコーディオンを固定するかどうかのフラグを管理する
   */
  resizeObservable(width) {
    // 900(PC／SP間のしきい値)
    if (width >= RESPONSIVE_WIDTH && this.openableInPc === false) {
      this.fixedAccordion = true;
    } else {
      this.fixedAccordion = false;
    }
  }

  toggleIsExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  ngOnInit() {
    // 初期化時にリサイズを監視する
    this.resizeObservable(window.innerWidth);
  }
}
