import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';
import { dispatchAppError } from 'src/app/actions/core.actions';
import { AppError } from 'src/app/errors/app-error';
import { FirestoreError } from 'src/app/errors/firestore-error';

import * as CommonIdPaperBookmarkActions from '../../actions/common-id/common-id-paper-bookmark.actions';
import { FirebaseCallableFunctionError } from '../../errors/firebase-callable-function-error';
import { CommonIdPaperBookmarkService } from '../../services/common-id/common-id-paper-bookmark.service';

@Injectable()
export class CommonIdPaperBookmarkEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdAddPaperBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPaperBookmarkActions.commonIdAddPaperBookmark),
      switchMap(action =>
        this.commonIdPaperBookmarkService.commonIdAddPaperBookmark(action.userId, action.paperId).pipe(
          map(response => CommonIdPaperBookmarkActions.commonIdAddPaperBookmarkSuccess({ response })),
          catchError(e =>
            of(CommonIdPaperBookmarkActions.commonIdAddPaperBookmarkFailure({ error: FirebaseCallableFunctionError.from(e) }))
          )
        )
      )
    )
  );

  commonIdDeletePaperBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPaperBookmarkActions.commonIdDeletePaperBookmark),
      switchMap(action =>
        this.commonIdPaperBookmarkService.commonIdDeletePaperBookmark(action.userId, action.paperId).pipe(
          map(response => CommonIdPaperBookmarkActions.commonIdDeletePaperBookmarkSuccess({ response })),
          catchError(e =>
            of(CommonIdPaperBookmarkActions.commonIdDeletePaperBookmarkFailure({ error: FirebaseCallableFunctionError.from(e) }))
          )
        )
      )
    )
  );

  commonIdFindPaperBookmark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdPaperBookmarkActions.commonIdFindPaperBookmark),
      switchMap(action =>
        this.commonIdPaperBookmarkService.commonIdFindPaperBookmark(action.userId).pipe(
          map(paperBookmark => CommonIdPaperBookmarkActions.commonIdFindPaperBookmarkSuccess({ paperBookmark })),
          catchError(e => {
            const error = e instanceof AppError ? e : FirestoreError.from(e);
            return of(
              CommonIdPaperBookmarkActions.commonIdFindPaperBookmarkFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error })
            );
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdPaperBookmarkService: CommonIdPaperBookmarkService) {}
}
