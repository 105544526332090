import { Action, createReducer, on } from '@ngrx/store';

import * as StaticDataActions from '../actions/static-data.actions';
import { StaticCommonData } from '../models/static-common-data';
import { StaticEnglishData } from '../models/static-english-data';
import { ISO8601 } from '../utils/dates';
import { StaticScienceData } from '../models/static-science-data';
import { StaticHistoryData } from '../models/static-history-data';
import { StaticNationalLanguageData } from '../models/static-national-language-data';

export const staticDataFeatureKey = 'staticData';

export interface State {
  common: StaticCommonData | null;
  english: StaticEnglishData | null;
  math: StaticScienceData | null;
  nationalLanguage: StaticNationalLanguageData | null;
  physics: StaticScienceData | null;
  chemistry: StaticScienceData | null;
  biology: StaticScienceData | null;
  japaneseHistory: StaticHistoryData | null;
  worldHistory: StaticHistoryData | null;
  geography: StaticScienceData | null;
  politicalEconomy: StaticScienceData | null;
  fetchedDate: ISO8601 | null;
}

export const initialState: State = {
  common: null,
  english: null,
  math: null,
  nationalLanguage: null,
  physics: null,
  chemistry: null,
  biology: null,
  japaneseHistory: null,
  worldHistory: null,
  geography: null,
  politicalEconomy: null,
  fetchedDate: null
};

const staticDataReducer = createReducer(
  initialState,
  on(
    StaticDataActions.findStaticDataSuccess,
    (
      state,
      {
        common,
        english,
        math,
        nationalLanguage,
        physics,
        chemistry,
        biology,
        japaneseHistory,
        worldHistory,
        geography,
        politicalEconomy,
        fetchedDate
      }
    ) => ({
      ...state,
      common,
      english,
      math,
      nationalLanguage,
      physics,
      chemistry,
      biology,
      japaneseHistory,
      worldHistory,
      geography,
      politicalEconomy,
      fetchedDate
    })
  ),
  on(StaticDataActions.initializeStaticDataState, () => {
    const next: State = {
      common: null,
      english: null,
      math: null,
      nationalLanguage: null,
      physics: null,
      chemistry: null,
      biology: null,
      japaneseHistory: null,
      worldHistory: null,
      geography: null,
      politicalEconomy: null,
      fetchedDate: null
    };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return staticDataReducer(state, action);
}
