import { createAction, props } from '@ngrx/store';

import { CommonIdPlaylist } from '../../models/common-id/common-id-playlist';
import {
  SciencePlaylistProblem,
  EnglishPlaylistProblem,
  NationalLanguagePlaylistProblem,
  HistoryPlaylistProblem
} from '../../models/problem';

// findPlaylists ------------------------------------------------------------
export const findPlaylists = createAction('[CommnIdPlaylist] Find Common Id Play Lists');
export const findPlaylistsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id Play Lists Success',
  props<{ playlists: CommonIdPlaylist[] }>()
);
export const findPlaylistsFailure = createAction('[CommnIdPlaylist] Find Common Id Play Lists Failure');

// findPlaylistProblemIds ------------------------------------------------------------
export const findPlaylistProblemIds = createAction(
  '[CommnIdPlaylist] Find Common Id English Playlist Problem Ids',
  props<{ subjectId: string; playlistId: string }>()
);

export const findPlaylistProblemIdsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id Playlist Problem Ids Success',
  props<{ playlistProblemIds: string[] }>()
);
export const findPlaylistProblemIdsFailure = createAction('[CommnIdPlaylist] Find Common Id Playlist Problem Ids Failure');
export const initializePlaylistProblemIdsState = createAction('[CommnIdPlaylist] Initialize Common Id Playlist Problem Ids State');

// findEnglishPlaylistProblems ------------------------------------------------------------
export const findEnglishPlaylistProblems = createAction(
  '[CommnIdPlaylist] Find Common Id English Playlist Problems',
  props<{ playlistId: string }>()
);
export const findEnglishPlaylistProblemsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id English Playlist Problems Success',
  props<{ englishPlaylistProblems: EnglishPlaylistProblem[] }>()
);
export const findEnglishPlaylistProblemsFailure = createAction('[CommnIdPlaylist] Find Common Id English Playlist Problems Failure');
export const initializeEnglishPlaylistProblemsState = createAction(
  '[CommnIdPlaylist] Initialize Common Id English Playlist Problems State'
);

// findMathPlaylistProblems ------------------------------------------------------------
export const findMathPlaylistProblems = createAction(
  '[CommnIdPlaylist] Find Common Id Math Playlist Problems',
  props<{ playlistId: string }>()
);
export const findMathPlaylistProblemsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id Math Playlist Problems Success',
  props<{ mathPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findMathPlaylistProblemsFailure = createAction('[CommnIdPlaylist] Find Common Id Math Playlist Problems Failure');
export const initializeMathPlaylistProblemsState = createAction('[CommnIdPlaylist] Initialize Common Id Math Playlist Problems State');

// findNationalLanguagePlaylistProblems ------------------------------------------------------------
export const findNationalLanguagePlaylistProblems = createAction(
  '[CommnIdPlaylist] Find Common Id National Language Playlist Problems',
  props<{ playlistId: string }>()
);
export const findNationalLanguagePlaylistProblemsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id National Language Playlist Problems Success',
  props<{ nationalLanguagePlaylistProblems: NationalLanguagePlaylistProblem[] }>()
);
export const findNationalLanguagePlaylistProblemsFailure = createAction(
  '[CommnIdPlaylist] Find Common Id National Language Playlist Problems Failure'
);
export const initializeNationalLanguagePlaylistProblemsState = createAction(
  '[CommnIdPlaylist] Initialize Common Id National Language Playlist Problems State'
);

// findPhysicsPlaylistProblems ------------------------------------------------------------
export const findPhysicsPlaylistProblems = createAction(
  '[CommnIdPlaylist] Find Common Id Physics Playlist Problems',
  props<{ playlistId: string }>()
);
export const findPhysicsPlaylistProblemsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id Physics Playlist Problems Success',
  props<{ physicsPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findPhysicsPlaylistProblemsFailure = createAction('[CommnIdPlaylist] Find Common Id Physics Playlist Problems Failure');
export const initializePhysicsPlaylistProblemsState = createAction(
  '[CommnIdPlaylist] Initialize Common Id Physics Playlist Problems State'
);

// findChemistryPlaylistProblems ------------------------------------------------------------
export const findChemistryPlaylistProblems = createAction(
  '[CommnIdPlaylist] Find Common Id Chemistry Playlist Problems',
  props<{ playlistId: string }>()
);
export const findChemistryPlaylistProblemsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id Chemistry Playlist Problems Success',
  props<{ chemistryPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findChemistryPlaylistProblemsFailure = createAction('[CommnIdPlaylist] Find Common Id Chemistry Playlist Problems Failure');
export const initializeChemistryPlaylistProblemsState = createAction(
  '[CommnIdPlaylist] Initialize Common Id Chemistry Playlist Problems State'
);

// findBiologyPlaylistProblems ------------------------------------------------------------
export const findBiologyPlaylistProblems = createAction(
  '[CommnIdPlaylist] Find Common Id Biology Playlist Problems',
  props<{ playlistId: string }>()
);
export const findBiologyPlaylistProblemsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id Biology Playlist Problems Success',
  props<{ biologyPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findBiologyPlaylistProblemsFailure = createAction('[CommnIdPlaylist] Find Common Id Biology Playlist Problems Failure');
export const initializeBiologyPlaylistProblemsState = createAction(
  '[CommnIdPlaylist] Initialize Common Id Biology Playlist Problems State'
);

// findJapaneseHistoryPlaylistProblems ------------------------------------------------------------
export const findJapaneseHistoryPlaylistProblems = createAction(
  '[CommnIdPlaylist] Find Common Id Japanese History Playlist Problems',
  props<{ playlistId: string }>()
);
export const findJapaneseHistoryPlaylistProblemsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id Japanese History Playlist Problems Success',
  props<{ japaneseHistoryPlaylistProblems: HistoryPlaylistProblem[] }>()
);
export const findJapaneseHistoryPlaylistProblemsFailure = createAction(
  '[CommnIdPlaylist] Find Common Id Japanese History Playlist Problems Failure'
);
export const initializeJapaneseHistoryPlaylistProblemsState = createAction(
  '[CommnIdPlaylist] Initialize Common Id Japanese History Playlist Problems State'
);

// findWorldHistoryPlaylistProblems ------------------------------------------------------------
export const findWorldHistoryPlaylistProblems = createAction(
  '[CommnIdPlaylist] Find Common Id World History Playlist Problems',
  props<{ playlistId: string }>()
);
export const findWorldHistoryPlaylistProblemsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id World History Playlist Problems Success',
  props<{ worldHistoryPlaylistProblems: HistoryPlaylistProblem[] }>()
);
export const findWorldHistoryPlaylistProblemsFailure = createAction(
  '[CommnIdPlaylist] Find Common Id World History Playlist Problems Failure'
);
export const initializeWorldHistoryPlaylistProblemsState = createAction(
  '[CommnIdPlaylist] Initialize Common Id World History Playlist Problems State'
);

// findGeographyPlaylistProblems ------------------------------------------------------------
export const findGeographyPlaylistProblems = createAction(
  '[CommnIdPlaylist] Find Common Id Geography Playlist Problems',
  props<{ playlistId: string }>()
);
export const findGeographyPlaylistProblemsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id Geography Playlist Problems Success',
  props<{ geographyPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findGeographyPlaylistProblemsFailure = createAction('[CommnIdPlaylist] Find Common Id Geography Playlist Problems Failure');
export const initializeGeographyPlaylistProblemsState = createAction(
  '[CommnIdPlaylist] Initialize Common Id Geography Playlist Problems State'
);

// findPoliticalEconomyPlaylistProblems ------------------------------------------------------------
export const findPoliticalEconomyPlaylistProblems = createAction(
  '[CommnIdPlaylist] Find Common Id Political Economy Playlist Problems',
  props<{ playlistId: string }>()
);
export const findPoliticalEconomyPlaylistProblemsSuccess = createAction(
  '[CommnIdPlaylist] Find Common Id Political Economy Playlist Problems Success',
  props<{ politicalEconomyPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findPoliticalEconomyPlaylistProblemsFailure = createAction(
  '[CommnIdPlaylist] Find Common Id Political Economy Playlist Problems Failure'
);
export const initializePoliticalEconomyPlaylistProblemsState = createAction(
  '[CommnIdPlaylist] Initialize Common Id Political Economy Playlist Problems State'
);

// findPdfUrl ------------------------------------------------------------
export const resolvePdfObjectUrl = createAction('[CommnIdPlaylist] Resolve Common Id Pdf Object Url', props<{ pdfPath: string }>());
export const resolvePdfObjectUrlSuccess = createAction(
  '[CommnIdPlaylist] Resolve Common Id Pdf Object Url Success',
  props<{ pdfPath: string; objectUrl: string }>()
);
export const resolvePdfObjectUrlFailure = createAction('[CommnIdPlaylist] Resolve Pdf Object Url Failure', props<{ pdfPath: string }>());
export const initializePdfObjectUrl = createAction('[CommnIdPlaylist] Initialize Pdf Object Url', props<{ pdfPath: string }>());
export const initializePdfObjectUrlState = createAction('[CommnIdPlaylist] Initialize Pdf Object Url State');
