<nav class="bottom-tab-ui">
  <ul class="bottom-tab-ui-list">
    <li class="bottom-tab-ui-list-item" [attr.data-is-active]="isCurrentSearch()">
      <button class="bottom-tab-ui-list-item-button" (click)="showSearch()">
        <mat-icon aria-hidden="true">search</mat-icon>
        大学から探す
      </button>
    </li>
    <li class="bottom-tab-ui-list-item" [attr.data-is-active]="isCurrentSearchByCategories()">
      <button class="bottom-tab-ui-list-item-button" (click)="showSearchByCategories()">
        <mat-icon aria-hidden="true">manage_search</mat-icon>
        分野から探す
      </button>
    </li>
    <li class="bottom-tab-ui-list-item" [attr.data-is-active]="isCurrentPlaylists()">
      <button class="bottom-tab-ui-list-item-button" (click)="showPlaylists()">
        <mat-icon aria-hidden="true">view_module</mat-icon>
        おすすめ問題
      </button>
    </li>
    <li class="bottom-tab-ui-list-item" [attr.data-is-active]="isCurrentMylist()">
      <button class="bottom-tab-ui-list-item-button" (click)="showMylist()">
        <mat-icon aria-hidden="true" fontSet="material-icons-outlined">fact_check</mat-icon>
        マイリスト
      </button>
    </li>
    <!--  
    <li class="bottom-tab-ui-list-item" [attr.data-is-active]="isInformation()">
      <button class="bottom-tab-ui-list-item-button" (click)="showInformation()">
        <mat-icon
          aria-hidden="true"
          fontSet="material-icons-outlined"
          matBadge="!"
          matBadgeSize="small"
          (click)="showInformation()"
          [matBadgeHidden]="(newInformation$ | async) !== true"
          >notifications</mat-icon
        >
        お知らせ
      </button>
    </li>
    -->
  </ul>
</nav>
