<app-content-row>
  <app-common-id-mylist-banner (bannerClick)="bannerClick()"></app-common-id-mylist-banner>

  <app-content-row>
    <app-wrapper size="lg" isFitWithSmallScreen="true">
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon aria-hidden="true" class="card-content-head-icon" fontSet="material-icons-outlined">fact_check</mat-icon>
          マイリスト
        </app-card-content-head>
        <app-card-content-nav
          (menuClick)="menuClickHandler($event)"
          [menus]="[
            {
              key: 'mylist-answer-problem',
              icon: 'check_circle',
              fontSet: 'material-icons-outlined',
              label: '解答済み',
              isActive: false
            },
            {
              key: 'mylist-univ',
              icon: 'star',
              label: '大学',
              isActive: false
            },
            {
              key: 'mylist-problem',
              icon: 'attach_file',
              fontSet: 'material-icons-outlined',
              label: '過去問',
              isActive: true
            },
            {
              key: 'mylist-playlist',
              icon: 'attach_file',
              fontSet: 'material-icons-outlined',
              label: 'おすすめ問題セット',
              isActive: false
            }
          ]"
        ></app-card-content-nav>

        <div @enter *ngIf="initializedMylistPaper$ | async; else loadingProgress">
          <app-wrapper size="fluid" *ngIf="targtPapers.length > 0">
            <app-content-row>
              <form class="row-sort-area" action="">
                <div class="flex gap-4 items-center sm:justify-between flex-wrap">
                  <div class="flex flex-col gap-2 sm:flex-row flex-wrap w-full sm:w-auto">
                    <div class="flex gap-2 w-full sm:w-auto">
                      <mat-form-field
                        floatLabel="always"
                        appearance="outline"
                        class="text-sm grow sm:grow-0 sm:w-32"
                        style="max-width: calc(50% - 0.25rem);"
                      >
                        <mat-label>科目</mat-label>
                        <mat-select [(value)]="selectedSubjectId" (selectionChange)="papersFilter()">
                          <mat-option *ngFor="let subject of subjects$ | async" [value]="subject.id">
                            {{ subject.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field
                        floatLabel="always"
                        appearance="outline"
                        class="text-sm grow sm:grow-0 sm:w-32"
                        style="max-width: calc(50% - 0.25rem);"
                      >
                        <mat-label>年度</mat-label>
                        <mat-select [(value)]="selectedYear" (selectionChange)="papersFilter()">
                          <mat-option *ngFor="let year of years" [value]="year">
                            {{ year }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field class="text-sm w-full example-full-width" appearance="fill" appearance="outline" floatLabel="always">
                        <mat-label>大学名で絞り込む</mat-label>
                        <input
                          type="text"
                          [placeholder]="universityNamesPlaceholder"
                          aria-label="大学名"
                          matInput
                          [formControl]="universityNameFormControl"
                          [matAutocomplete]="auto"
                        />
                        <mat-autocomplete
                          autoActiveFirstOption
                          #auto="matAutocomplete"
                          (optionSelected)="universitySelected($event)"
                          [displayWith]="univForAutoComplete"
                        >
                          <mat-option [value]="allUniversities">
                            {{ allUniversities.name }}
                          </mat-option>
                          <mat-option *ngFor="let univ of filteredUniversities$ | async" [value]="univ">
                            {{ univ.name }}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="flex gap-4 flex-wrap">
                    <mat-checkbox color="primary" [checked]="onlyNotAnsweredPapers" (change)="notAnsweredPaperFilter($event.checked)"
                      >未解答だけを表示</mat-checkbox
                    >
                  </div>
                </div>
              </form>
            </app-content-row>
          </app-wrapper>
          <!-- １件も登録していない場合に表示するエリア -->
          <app-wrapper size="fluid" *ngIf="showingPapers.length === 0">
            <app-content-row>
              <app-content-no-data>
                <div class="flex flex-col-reverse md:flex-row items-center justify-center w-9/12 mx-auto my-4">
                  <div class="md:w-1/2 text-center leading-relaxed">
                    <p class="text-md md:text-lg font-bold">過去問の登録がありません</p>
                    <p class="text-sm md:text-md my-6">
                      あとで解くに登録した過去問がすぐに解ける！<br />
                      大問ごとに解答済みにしていきましょう。
                    </p>
                    <button mat-flat-button class="mr-2 bg-gray-50 text-primary font-normal" (click)="goSearchUniv()">
                      <mat-icon aria-hidden="true" class="card-content-head-icon">search</mat-icon>
                      大学から探す
                    </button>
                  </div>
                  <div class="md:w-1/2 -mt-4 md:mt-0">
                    <picture>
                      <source media="(min-width: 900px)" srcset="assets/images/mylist-example-problem@2x.png" />
                      <img
                        class="pointer-events-none"
                        src="assets/images/mylist-example-problem-sp@2x.png"
                        width="940"
                        height="840"
                        alt=""
                      />
                    </picture>
                  </div>
                </div>
              </app-content-no-data>
            </app-content-row>
          </app-wrapper>
          <app-wrapper size="fluid" isFitWithSmallScreen="true" *ngIf="showingPapers.length > 0">
            <app-content-row>
              <app-table-overflow>
                <table mat-table [dataSource]="showingPapers" class="w-full">
                  <ng-container matColumnDef="bookmark">
                    <th mat-header-cell *matHeaderCellDef class="w-16"></th>
                    <td mat-cell *matCellDef="let paper">
                      <!-- OFF -->
                      <app-button-toggle-paper-bookmark-icon
                        *ngIf="!paper.bookmarked"
                        [isPaperBookmarked]="false"
                        [canSpinner]="true"
                        (bookmarkClick)="addPaperBookmark(paper.paperId)"
                        class="button-toggle-paper-bookmark"
                        [disabled]="!canDisplay"
                      >
                      </app-button-toggle-paper-bookmark-icon>
                      <!-- ON -->
                      <app-button-toggle-paper-bookmark-icon
                        *ngIf="paper.bookmarked"
                        [isPaperBookmarked]="true"
                        [canSpinner]="true"
                        (bookmarkClick)="deletePaperBookmark(paper.paperId)"
                        class="button-toggle-paper-bookmark"
                        [disabled]="!canDisplay"
                      >
                      </app-button-toggle-paper-bookmark-icon>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="progress">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 100px;">進捗</th>
                    <td mat-cell *matCellDef="let paper" class="font-bold">
                      <span *ngIf="!paper.fulllAswered">{{ paper.answeredProblemCount }}/{{ paper.problemIds.length }}問</span>
                      <span *ngIf="paper.fulllAswered" class="text-primary flex items-center">
                        {{ paper.answeredProblemCount }}/{{ paper.problemIds.length }}問
                        <mat-icon class="icon" aria-hidden="true" style="width:1rem; height:1rem; font-size: 1rem;">check_circle</mat-icon>
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 240px;">大学名 / 試験名</th>
                    <td mat-cell *matCellDef="let paper">
                      <button
                        class="text-primary font-bold underline hover:no-underline text-left"
                        (click)="goProblemdetail(paper.paperId)"
                      >
                        {{ paper.universityName }} {{ paper.subjectName }} {{ paper.year }}年度入試
                      </button>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="schedule">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 150px;">日程</th>
                    <td mat-cell *matCellDef="let paper" class="text-sm">{{ paper.nyushiName }} 試験日：{{ paper.examDate }}</td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="['bookmark', 'progress', 'name', 'schedule']"></tr>
                  <tr mat-row *matRowDef="let row; columns: ['bookmark', 'progress', 'name', 'schedule']"></tr>
                </table>
              </app-table-overflow>
            </app-content-row>
          </app-wrapper>
          <!-- <app-wrapper size="fluid">
            <app-content-row>
              <div class="flex justify-end">
                <app-paginator [currentPage]="2" [totalItemCount]="10" [pageSize]="20" [windowSize]="5"></app-paginator>
              </div>
            </app-content-row>
          </app-wrapper> -->
        </div>

        <ng-template #loadingProgress>
          <app-content-row>
            <div class="spinner-spacer">
              <div class="progress-48">
                <mat-spinner [diameter]="48"></mat-spinner>
              </div>
            </div>
          </app-content-row>
        </ng-template>
      </app-card-content>
    </app-wrapper>
  </app-content-row>
</app-content-row>
