<app-wrapper size="xl" isFitWithSmallScreen="true">
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon" aria-hidden="true">poll</mat-icon>
        レポート
      </app-card-content-head>
      <app-wrapper size="fluid">
        <app-content-row>
          <div @enter *ngIf="metadataLists != null">
            <div class="mb-8">
              <mat-checkbox
                [(ngModel)]="isWindows"
                matTooltip="チェック済みの場合、改行コードが CRLF になります"
                matTooltipShowDelay="1000"
              >
                Windows 用の CSV をダウンロードする
              </mat-checkbox>
            </div>

            <div class="logs-container-overflow mb-8">
              <div class="logs-container-overflow-inner">
                <div class="logs-container">
                  <div class="logs" *ngFor="let metadataList of metadataLists">
                    <div class="mat-subheading-2 subject-name">{{ metadataList.subjectName }}</div>
                    <div class="buttons-container">
                      <ng-container *ngIf="metadataList.dataList.length !== 0; else noLogs">
                        <div *ngFor="let metadata of metadataList.dataList">
                          <button
                            mat-stroked-button
                            color="primary"
                            (click)="downloadSearchLogFile(metadata)"
                            [disabled]="metadata.downloading"
                          >
                            {{ metadata.buttonLabel }}
                          </button>
                          <div class="progress-24" *ngIf="metadata.downloading">
                            <mat-spinner [diameter]="24"></mat-spinner>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ng-template #noLogs>
            <app-content-no-data>
              <span>ログが生成されていません</span>
            </app-content-no-data>
          </ng-template>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<div class="progress-48" *ngIf="metadataLists == null">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
