import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { Log } from 'src/app/utils/log';
import { dispatchInfoMessage } from 'src/app/actions/core.actions';
import { enter } from '../../../resources/animations';
import { DeleteBookmarkData } from '../../../models/bookmark';
import { deleteBookmark, initializeDeleteBookmarkState } from 'src/app/actions/bookmark.actions';
import * as BookmarkSelectors from '../../../selectors/bookmark.selectors';

@Component({
  selector: 'app-delete-bookmark-dialog',
  templateUrl: './delete-bookmark-dialog.component.html',
  styleUrls: ['./delete-bookmark-dialog.component.scss'],
  animations: [enter]
})
export class DeleteBookmarkDialogComponent implements OnInit {
  disabled = false;
  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<DeleteBookmarkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteBookmarkData
  ) {}

  ngOnInit() {}

  deleteBookmark() {
    if (!this.data) {
      Log.warn(this.LOG_SOURCE, `dialog.data が設定されていません: `, this.data);
      return;
    }

    this.disableForms();
    this.store.dispatch(
      dispatchInfoMessage({
        message: `お気に入りを解除しています。`
      })
    );

    this.store
      .select(BookmarkSelectors.getDeleteBookmarkResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeDeleteBookmarkState());
        if (result.success) {
          this.store.dispatch(
            dispatchInfoMessage({
              message: `お気に入りを解除しました`
            })
          );
          Log.debug(this.LOG_SOURCE, 'delete bookmark', this.data, result);
          this.dialogRef.close(true);
        } else {
          Log.warn(this.LOG_SOURCE, `delete bookmark error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
          this.dialogRef.close(false);
        }

        this.enableForms();
      });
    this.store.dispatch(deleteBookmark(this.data));
  }

  cancel() {
    this.disableForms();
    this.dialogRef.close(false);
  }

  private disableForms() {
    this.disabled = true;
  }

  private enableForms() {
    this.disabled = false;
  }
}
