<app-wrapper size="lg" isFitWithSmallScreen="true">
  <!--
  <app-content-row>
    <app-breadcrumbs
      (breadcrumbsClick)="breadcrumbsClickHandler($event)"
      [breadcrumbs]="[
        { key: 'admin', label: 'Admin機能' },
        { key: 'admin/accounts', label: '一斉メール送信' }
      ]"
    ></app-breadcrumbs>
  </app-content-row>
  -->

  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">mail</mat-icon>
        一斉メール送信
      </app-card-content-head>

      <app-wrapper size="fluid">
        <div class="item-container">
          <div class="mat-caption">お知らせの対象</div>
          <div class="checkbox-list">
            <div *ngFor="let mailReceiver of mailReceiverOptions">
              <mat-checkbox
                color="primary"
                (change)="onChangeMailReceiver()"
                [disabled]="mailReceiver.disabled"
                [(ngModel)]="mailReceiver.checked"
                >{{ mailReceiver.name }}</mat-checkbox
              >
            </div>
          </div>
          <div class="mt-4 warning-message break-words">
            ※入試正解デジタル担当者(<span class="text-primary">nyushi-digi@obunsha.co.jp</span>)には必ず送信されます。
          </div>
        </div>

        <mat-form-field appearance="outline" class="title-field" floatLabel="always">
          <mat-label>お知らせタイトル</mat-label>
          <input matInput [formControl]="bulkMailTitle" placeholder="お知らせタイトルを入力して下さい" />
        </mat-form-field>
        <ckeditor
          #editor
          class="override-ckeditor"
          (change)="onEditorChange($event)"
          [config]="config"
          [editor]="classicEditor"
          [(ngModel)]="editorData"
          (ready)="onReady($event)"
        ></ckeditor>
      </app-wrapper>

      <app-wrapper class="send-button" size="fluid">
        <button mat-flat-button color="primary" (click)="confirm()" [disabled]="isConfirmButtonDisabled">送信内容の確認</button>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>
