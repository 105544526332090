<section class="dialog-basic" [attr.data-is-loading]="isLoading">
  <p class="dialog-basic-head" *ngIf="dialogTitle !== ''">
    <mat-icon *ngIf="dialogIcon" [attr.data-dialogIconOutline]="dialogIconOutline">{{ dialogIcon }}</mat-icon
    >{{ dialogTitle }}
  </p>
  <div class="dialog-basic-body">
    <ng-content></ng-content>
  </div>
  <div class="dialog-basic-foot">
    <ng-content select="app-dialog-basic-footer"></ng-content>
  </div>
</section>
