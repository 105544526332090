import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';

@Component({
  selector: 'app-button-more',
  templateUrl: './button-more.component.html',
  styleUrls: ['./button-more.component.scss']
})
export class ButtonMoreComponent implements OnInit, OnChanges {
  constructor() {}

  @Input() disabled = false;
  @Input() canSpinner = false;
  @Input() pageButton: number;
  @Output() buttonClick = new EventEmitter();

  showSpinner = false;

  ngOnInit() {}

  ngOnChanges() {
    // ページが変わるたびに、もっと見るボタンのローてぃんぐアイコンを表示する
    this.showSpinner = false;
  }

  toggle() {
    if (this.canSpinner) {
      this.showSpinner = true;
    }

    this.buttonClick.emit();
  }
}
