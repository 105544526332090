import { Component, OnInit } from '@angular/core';
import { navigate } from '../../../actions/core.actions';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { RootState } from '../../../reducers';
import { signOut } from 'src/app/actions/auth.actions';
import { unsubscribeWatchedProps } from 'src/app/actions/core.actions';
import { UnsubscribeTarget } from 'src/app/resources/config';
import { getSignedInUser } from '../../../selectors/auth.selectors';
import { Observable } from 'rxjs';
import { User } from '../../../models/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-try-sign-in-header',
  templateUrl: './try-sign-in-header.component.html',
  styleUrls: ['./try-sign-in-header.component.scss']
})
export class TrySignInHeaderComponent implements OnInit {
  user$: Observable<User>;
  userInfoParentIsFocused = false;
  userInfoItemsIsFocused: boolean[] = [];

  constructor(private store: Store<RootState>, private router: Router) {}

  ngOnInit() {
    this.setUpUser();
  }

  touchendUserInfoDropdown() {
    if (this.userInfoParentIsFocused) {
      setTimeout(() => {
        // 遅延させないと遷移が発生しなくなる
        this.resetUserInfoDropdown();
      }, 10);
    } else {
      this.userInfoParentIsFocused = true;
    }
  }

  hoverUserInfoDropdown() {
    this.userInfoParentIsFocused = true;
  }

  focusUserInfoParent() {
    this.userInfoParentIsFocused = true;
  }

  blurUserInfoParent() {
    this.userInfoParentIsFocused = false;
  }

  focusUserInfoItem(index: number) {
    this.userInfoItemsIsFocused[index] = true;
  }

  blurUserInfoItem(index: number) {
    this.userInfoItemsIsFocused[index] = false;
  }

  showUserInfoDropdown(): boolean {
    function checkItem(val: boolean): boolean {
      return val;
    }
    return this.userInfoParentIsFocused || this.userInfoItemsIsFocused.some(b => checkItem(b));
  }

  resetUserInfoDropdown() {
    this.userInfoParentIsFocused = false;
    this.userInfoItemsIsFocused = [];
  }

  signOut() {
    this.resetUserInfoDropdown();
    this.store.dispatch(signOut());
    this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.ON_SIGN_OUT }));
    setTimeout(() => this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSignIn() })));
  }

  private setUpUser() {
    this.user$ = this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none'));
  }
}
