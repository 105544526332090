<app-wrapper size="fluid" isFitWithSmallScreen="true">
  <div @enter *ngIf="usersLoaded$ | async; else loadingProgress">
    <app-content-row>
      <app-breadcrumbs
        (breadcrumbsClick)="breadcrumbsClickHandler($event)"
        [breadcrumbs]="[
          { key: 'admin', label: 'Admin機能' },
          { key: 'admin/accounts', label: 'アカウント管理 - ユーザー' }
        ]"
      ></app-breadcrumbs>
    </app-content-row>
    <app-content-row>
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon class="card-content-head-icon">group</mat-icon>
          アカウント管理
        </app-card-content-head>
        <app-card-content-nav
          (menuClick)="menuClickHandler($event)"
          [menus]="[
            {
              key: 'admin/accounts',
              icon: 'group',
              label: 'ユーザー',
              isActive: true
            },
            {
              key: 'admin/schools',
              icon: 'school',
              label: '学校',
              isActive: false
            },
            {
              key: 'admin/jukus',
              icon: 'domain',
              label: '塾・予備校',
              isActive: false
            }
          ]"
        ></app-card-content-nav>
        <app-wrapper size="fluid">
          <app-content-row>
            <div class="flex flex-wrap gap-4">
              <button mat-stroked-button color="primary" (click)="toggleSortDirection()">並び順を反転する</button>
              <app-button-toggle
                labelIfTrue="メールアドレスを非表示にする"
                labelIfFalse="メールアドレスを表示する"
                (toggleClick)="toggleDisplayEmail($event)"
              ></app-button-toggle>
              <span class="mx-auto"></span>
              <button mat-flat-button color="primary" (click)="addAccount()">
                <mat-icon class="">add_circle_outline</mat-icon>
                ユーザーアカウントを登録
              </button>
            </div>
          </app-content-row>

          <!-- 絞り込み機能 -->
          <app-content-row>
            <div class="">
              <form
                class="grid grid-cols-2 md:flex md:flex-wrap gap-2 row-sort-area"
                [formGroup]="filterForm"
                (keydown.enter)="$event.preventDefault()"
              >
                <mat-form-field floatLabel="always" appearance="outline" class="text-sm md:w-40">
                  <mat-label>アカウント有効無効</mat-label>
                  <mat-select formControlName="accountStatus" (selectionChange)="accountStatusChange()">
                    <mat-option *ngFor="let accountStatus of accountStatusFilterTypes" [value]="accountStatus.id">{{
                      accountStatus.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline" class="text-sm md:w-40">
                  <mat-label>団体種別</mat-label>
                  <mat-select formControlName="organization" (selectionChange)="organizationChange()">
                    <mat-option *ngFor="let organization of organizationFilterTypes" [value]="organization.id">{{
                      organization.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline" class="text-sm md:w-40">
                  <mat-label>権限</mat-label>
                  <mat-select formControlName="authorization" (selectionChange)="authorizationChange()">
                    <mat-option *ngFor="let authorization of authorizationFilterTypes" [value]="authorization.id">{{
                      authorization.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline" class="text-sm md:w-40">
                  <mat-label>割当状況</mat-label>
                  <mat-select formControlName="planStatus" (selectionChange)="planStatusChange()">
                    <mat-option *ngFor="let planStatus of planStatusFilterTypes" [value]="planStatus.id">{{ planStatus.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline" class="text-sm md:w-40 md:mr-auto">
                  <mat-label>科目</mat-label>
                  <mat-select formControlName="planSubject" (selectionChange)="planSubjectChange()">
                    <mat-option *ngFor="let planSubject of planSubjectFilterTypes" [value]="planSubject.id">{{
                      planSubject.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="col-span-2 md:col-auto flex flex-wrap gap-2 items-start">
                  <mat-form-field class="text-sm w-full sm:w-64 md:w-48" floatLabel="always" appearance="outline">
                    <mat-label>メールアドレス</mat-label>
                    <input matInput [placeholder]="emailPlaceholder" formControlName="email" />
                  </mat-form-field>
                  <mat-form-field class="text-sm w-full sm:w-64 md:w-48" floatLabel="always" appearance="outline">
                    <mat-label>氏名</mat-label>
                    <input matInput [placeholder]="namePlaceholder" formControlName="name" />
                  </mat-form-field>
                  <!-- 検索ボタンとリセットボタンが必要になれば、この部分のコメントを外す
                  <button mat-flat-button color="primary" class="button-search">
                    <mat-icon class="icon">search</mat-icon>
                  </button>
                  <button mat-stroked-button color="primary" (click)="resetFilter()">条件をリセット</button>
                  -->
                </div>
              </form>
            </div>
          </app-content-row>
        </app-wrapper>
        <app-wrapper size="fluid" isFitWithSmallScreen="true">
          <app-content-row>
            <mat-toolbar>
              <mat-toolbar-row class="tableFooter">
                <div class="numberOfUsers">絞り込み件数：{{ totalFilteredAccounts }}</div>
                <div class="numberOfUsers">全件数：{{ totalAccounts }}</div>
                <mat-paginator
                  #paginator
                  (page)="pageChangeEvent($event)"
                  [length]="totalFilteredAccounts"
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex"
                  [pageSizeOptions]="pageSizeOptions"
                  showFirstLastButtons
                >
                </mat-paginator>
              </mat-toolbar-row>
            </mat-toolbar>

            <app-table-overflow>
              <table mat-table [dataSource]="accounts$" class="w-full">
                <ng-container matColumnDef="isActive">
                  <th mat-header-cell *matHeaderCellDef class="text-xs leading-3 text-center" style="min-width:84px;">
                    アカウント<br />有効/無効
                  </th>
                  <td mat-cell *matCellDef="let account">
                    <button mat-button *ngIf="!account.isActive" (click)="changeActiveAccount(account)" class="button-is-active disabled">
                      <mat-icon>remove_circle_outline</mat-icon>
                      <span class="label">無効</span>
                    </button>
                    <button mat-button *ngIf="account.isActive" (click)="changeActiveAccount(account)" class="button-is-active enabled">
                      <mat-icon>check_circle_outline</mat-icon>
                      <span class="label">有効</span>
                    </button>
                  </td>
                </ng-container>

                <ng-container matColumnDef="email">
                  <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">メールアドレス</th>
                  <td mat-cell *matCellDef="let account" class="text-xs break-words">{{ account.email }}</td>
                </ng-container>

                <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">ユーザー ID</th>
                  <td mat-cell *matCellDef="let account" class="text-xs break-words">{{ account.id }}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">氏名</th>
                  <td mat-cell *matCellDef="let account" class="text-sm font-bold">{{ account.name }}</td>
                </ng-container>

                <ng-container matColumnDef="kana">
                  <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">ふりがな</th>
                  <td mat-cell *matCellDef="let account" class="text-xs">{{ account.kana }}</td>
                </ng-container>

                <ng-container matColumnDef="school">
                  <th mat-header-cell *matHeaderCellDef class="">所属団体名</th>
                  <td mat-cell *matCellDef="let account" class="text-sm" style="min-width: 100px;">
                    <ng-container *ngIf="account.isSchoolOrganization; else noSchooolOrganizationBlock">
                      <button (click)="goPlans(account.organization, account.schoolId)" class="text-primary underline hover:no-underline">
                        {{ account.school }}
                      </button>
                    </ng-container>
                    <ng-template #noSchooolOrganizationBlock>
                      {{ account.school }}
                    </ng-template>
                  </td>
                </ng-container>

                <ng-container matColumnDef="organization">
                  <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">団体種別</th>
                  <td mat-cell *matCellDef="let account" class="text-sm">{{ account.organizationName }}</td>
                </ng-container>

                <ng-container matColumnDef="authority">
                  <th mat-header-cell *matHeaderCellDef class="" style="min-width: 150px;">権限</th>
                  <td mat-cell *matCellDef="let account" class="">
                    <div *ngIf="account.isAdmin; else nonAdminBlock" class="text-sm">
                      <app-label-with-icon iconName="admin_panel_settings">Admin</app-label-with-icon>
                    </div>
                    <ng-template #nonAdminBlock>
                      <div *ngIf="account.isOrganizationAdmin" class="text-sm">
                        <app-label-with-icon iconName="supervisor_account">団体管理ユーザー</app-label-with-icon>
                      </div>
                    </ng-template>
                  </td>
                </ng-container>
                <ng-container matColumnDef="planName">
                  <th mat-header-cell *matHeaderCellDef class="" style="min-width: 150px;">プラン科目</th>
                  <td mat-cell *matCellDef="let account" class="text-sm">
                    <!-- TODO: 未割り当ての際に (!)マークにツールチップ 「 プラン一覧より、契約プランにユーザーを割り当ててください 」 -->
                    <ng-container *ngIf="account.plan !== null; else noPlanBlock">
                      <button (click)="goPlanStatuses(account.plan.id)" class="text-primary underline hover:no-underline">
                        {{ account.planName }}
                      </button>
                    </ng-container>
                    <ng-template #noPlanBlock>
                      {{ account.planName }}
                    </ng-template>
                  </td>
                </ng-container>

                <ng-container matColumnDef="latestAppLaunchedDate">
                  <th mat-header-cell *matHeaderCellDef class="" style="min-width: 100px;">最終ログイン</th>
                  <td mat-cell *matCellDef="let account" class="latest-app-launched-date-value text-xs">
                    {{ account.latestAppLaunchedDate }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="editAccount">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">編集</th>
                  <td mat-cell *matCellDef="let account" style="text-align: center;">
                    <button mat-icon-button (click)="editAccount(account)" class="icon-button-edit">
                      <mat-icon>create</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <ng-container matColumnDef="removeAccount">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;">削除</th>
                  <td mat-cell *matCellDef="let account" style="text-align: center;">
                    <button mat-icon-button (click)="removeAccount(account)" class="icon-button-delete">
                      <mat-icon>delete_forever</mat-icon>
                    </button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </app-table-overflow>

            <mat-toolbar>
              <mat-toolbar-row class="tableFooter">
                <div class="numberOfUsers">絞り込み件数：{{ totalFilteredAccounts }}</div>
                <div class="numberOfUsers">全件数：{{ totalAccounts }}</div>
                <mat-paginator
                  #paginator
                  (page)="pageChangeEvent($event)"
                  [length]="totalFilteredAccounts"
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex"
                  [pageSizeOptions]="pageSizeOptions"
                  showFirstLastButtons
                >
                </mat-paginator>
              </mat-toolbar-row>
            </mat-toolbar>
          </app-content-row>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
  </div>
</app-wrapper>

<ng-template #loadingProgress>
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>
