import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, take, catchError, takeUntil, exhaustMap, filter } from 'rxjs/operators';

import * as UserActions from '../actions/user.actions';
import { UserService } from '../services/user.service';
import { dispatchAppError } from '../actions/core.actions';
import { FirestoreError } from '../errors/firestore-error';
import { SubscriptionService } from '../services/subscription.service';
import { FirebaseAuthError } from '../errors/firebase-auth-error';
import { FirebaseCallableFunctionError } from '../errors/firebase-callable-function-error';
import { UnsubscribeTarget } from '../resources/config';

@Injectable()
export class UserEffects {
  private LOG_SOURCE = this.constructor.name;

  findUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.findUsers),
      switchMap(action =>
        this.userService.findUsers(action.userSearchCondition).pipe(
          map(users => UserActions.findUsersSuccess({ users })),
          catchError(e => of(UserActions.findUsersFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })))
        )
      )
    )
  );

  findUsersByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.findUsersByEmail),
      switchMap(action =>
        this.userService.findUsersByEmail(action.emailGroups).pipe(
          map(users => UserActions.findUsersByEmailSuccess({ users })),
          catchError(e =>
            of(UserActions.findUsersByEmailFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) }))
          )
        )
      )
    )
  );

  watchUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.watchUsers),
      switchMap(action =>
        this.userService.watchUsers(action.sortDirection).pipe(
          takeUntil(this.subscriptionService.unsubscribed$.pipe(filter(it => it === UnsubscribeTarget.WATCH_USERS))),
          map(users => UserActions.watchUsersSuccess({ users })),
          catchError(e => of(UserActions.watchUsersFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })))
        )
      )
    )
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createUser),
      exhaustMap(action =>
        this.userService.createUser(action.user).pipe(
          map(user => UserActions.createUserSuccess({ user })),
          catchError(e => of(UserActions.createUserFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  createUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createUsers),
      exhaustMap(action =>
        this.userService.createUsers(action.organization, action.schoolId, action.users).pipe(
          map(response => UserActions.createUsersSuccess({ response })),
          catchError(e => of(UserActions.createUsersFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      exhaustMap(action =>
        this.userService.updateUser(action.user).pipe(
          map(user => UserActions.updateUserSuccess({ user })),
          catchError(e => of(UserActions.updateUserFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  updateUserEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserEmail),
      exhaustMap(action =>
        this.userService.reauthenticate(action.user, action.password).pipe(
          take(1),
          exhaustMap(() =>
            this.userService.updateUserEmail(action.user).pipe(
              take(1),
              map(user => UserActions.updateUserEmailSuccess({ user })),
              catchError(e => {
                return of(UserActions.updateUserEmailFailure({ error: FirebaseCallableFunctionError.from(e) }));
              })
            )
          ),
          catchError(e => {
            return of(UserActions.updateUserEmailFailure({ error: FirebaseAuthError.from(e) }));
          })
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUser),
      exhaustMap(action =>
        this.userService.deleteUser(action.user).pipe(
          map(user => UserActions.deleteUserSuccess({ user })),
          catchError(e => of(UserActions.deleteUserFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  updateUserIsActive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserIsActive),
      exhaustMap(action =>
        this.userService.updateUserIsActive(action.user).pipe(
          map(user => UserActions.updateUserIsActiveSuccess({ user })),
          catchError(e => of(UserActions.updateUserIsActiveFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  updateUserIsOrganizationAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserIsOrganizationAdmin),
      exhaustMap(action =>
        this.userService.updateUserIsOrganizationAdmin(action.user).pipe(
          map(user => UserActions.updateUserIsOrganizationAdminSuccess({ user })),
          catchError(e => of(UserActions.updateUserIsOrganizationAdminFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  updateUserIsTermsAgree$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUserIsTermsAgree),
      exhaustMap(action =>
        this.userService.updateUserIsTermsAgree(action.user).pipe(
          map(user => UserActions.updateUserIsTermsAgreeSuccess({ user })),
          catchError(e => of(UserActions.updateUserIsTermsAgreeFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private userService: UserService, private subscriptionService: SubscriptionService) {}
}
