import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import {
  ComonIdFindBookmarkAndAnsweredPaperRequest,
  ComonIdFindBookmarkAndAnsweredPaperResponse
} from 'src/app/models/common-id/common-id-bookmark-and-answered-paper';
import { CallableFunction } from 'src/app/resources/app-firebase';

@Injectable({
  providedIn: 'root'
})
export class CommonIdBookmarkAndAnsweredPaperService {
  constructor(private aff: AngularFireFunctions) {}

  commonIdFindBookmarkAndAnsweredPapers(paperIds: string[]): Observable<ComonIdFindBookmarkAndAnsweredPaperResponse[]> {
    const req: ComonIdFindBookmarkAndAnsweredPaperRequest = { paperIds };
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_FIND_BOOKMARK_AND_ANSWERED_PAPERS);
    return callable(req);
  }
}
