import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

// Redux
import { navigate, setBrowserTitle, setTitle } from 'src/app/actions/core.actions';
import {
  findCommonIdUserByCommonId,
  findCommonIdUserByEmail,
  initializeFindCommonIdUserByCommonId,
  initializeFindCommonIdUserByEmail
} from 'src/app/actions/common-id/common-id-user.actions';
import {
  getCommonIdUser,
  getCommonIdUserSearching,
  getCommonIdUserNotFoundFlag
} from 'src/app/selectors/common-id/common-id-user.selectors';
import { RootState } from 'src/app/reducers';

// models
import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { Breadcrumb, MenuTab } from 'src/app/models/menu';
import { AccountTableData } from 'src/app/models/account-table-data';

// utils
import { Log } from 'src/app/utils/log';
import { Dates } from 'src/app/utils/dates';

// config
import { PURCHASE_PLAN_NAMES, PURCHASE_STATUS_NAMES, COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR } from 'src/app/resources/common-id-config';

// others
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';

type MenuType = 'email' | 'userId';

@Component({
  selector: 'app-common-id-account-search',
  templateUrl: './account-search.component.html',
  styleUrls: ['./account-search.component.scss']
})
export class CommonIdAccountSearchComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  title = '個人アカウント検索';

  account: AccountTableData[];
  commonIdUser$: Observable<CommonIdUser>;
  commonIdUser: CommonIdUser;
  isCommonIdUserSearching$: Observable<boolean>;
  isCommonIdUserNotFound$: Observable<boolean>;
  isResultDisplayed = false;
  isSearchButtonDisabled = true;

  filterForm: UntypedFormGroup;

  emailPlaceholder = 'メールアドレスで検索';
  enteredEmail: string;

  userIdPlaceholder = 'ユーザーIDで検索';
  enteredUserId: string;

  EMAIL_MENU_LABEL = 'メールアドレスで検索';
  USER_ID_MENU_LABEL = 'ユーザーIDで検索';
  breadcrumbsLabel: string;
  breadcrumbs: Breadcrumb[];
  menus: MenuTab[];
  selectedMenu: MenuType = 'email';

  defaultDisplayedColumns: string[] = [
    'isActive',
    'email',
    'commonId',
    'purchaseStatus',
    'lastPurchasedAt',
    'premiumEndAt',
    'purchasePlan',
    'latestAppLaunchedDate',
    'updatePurchaseStatus',
    'viewPurchaseHistory'
  ];
  displayedColumns: string[] = [...this.defaultDisplayedColumns];

  ACCOUNT_SEARCH_PATH: string;
  ACCOUNT_SEARCH_EMAIL_MENU_KEY: string;
  ACCOUNT_SEARCH_USERID_MENU_KEY: string;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUpPath();
    this.setUpBreadcrumbs();
    this.setUpTabMenus();
    this.setUpForms();
    this.resetForms();
    this.setUpCommonIdUser();
    this.setUpIsCommonIdUserSearching();
    this.setUpIsCommonIdUserNotFound();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeFindCommonIdUserByEmail());
    this.store.dispatch(initializeFindCommonIdUserByCommonId());
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'admin':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        break;
    }
  }

  menuClickHandler(key) {
    this.resetFlags();
    this.resetForms();

    switch (key) {
      case this.ACCOUNT_SEARCH_EMAIL_MENU_KEY:
        this.selectedMenu = 'email';

        // breadcrumbsのラベルを設定
        this.breadcrumbsLabel = `${this.title} - ${this.EMAIL_MENU_LABEL}`;
        this.breadcrumbs[1].label = this.breadcrumbsLabel;

        // メニュータブのオンオフを切り替え
        this.menus[0].isActive = true;
        this.menus[1].isActive = false;
        break;

      case this.ACCOUNT_SEARCH_USERID_MENU_KEY:
        this.selectedMenu = 'userId';
        // breadcrumbsのラベルを設定
        this.breadcrumbsLabel = `${this.title} - ${this.USER_ID_MENU_LABEL}`;
        this.breadcrumbs[1].label = this.breadcrumbsLabel;

        // メニュータブのオンオフを切り替え
        this.menus[0].isActive = false;
        this.menus[1].isActive = true;
        break;
    }
  }

  onInputChanges(value) {
    this.isSearchButtonDisabled = value.length > 0 ? false : true;
  }

  search(selectedMenu: MenuType) {
    this.isResultDisplayed = false;
    if (selectedMenu === 'email') {
      this.enteredEmail = this.filterForm.get(selectedMenu).value;
      this.searchByEmail();
    } else {
      this.enteredUserId = this.filterForm.get(selectedMenu).value;
      this.searchByCommonId();
    }

    combineLatest([this.commonIdUser$])
      .pipe(take(1))
      .subscribe(([commonIdUser]) => {
        this.account = [this.setUpAccountData(commonIdUser)];
        this.isResultDisplayed = true;
      });
  }

  resetFilter(selectedMenu: MenuType) {
    this.resetFlags();
    if (selectedMenu === 'email') {
      this.store.dispatch(initializeFindCommonIdUserByEmail());
    } else {
      this.store.dispatch(initializeFindCommonIdUserByCommonId());
    }
    this.resetForms();
  }

  private setUpAccountData(user: CommonIdUser): AccountTableData {
    const isActive = user.isActive === undefined ? true : user.isActive;
    const purchaseStatus = user.purchaseStatus
      ? PURCHASE_STATUS_NAMES.find(status => status.id === user.purchaseStatus).name
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;
    const purchasePlan = user.purchasePlanId
      ? PURCHASE_PLAN_NAMES.find(status => status.id === user.purchasePlanId).name
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;

    const lastPurchasedAt = user.lastPurchasedAt
      ? Dates.stdDateStringFromIso(user.lastPurchasedAt)
          .split(' ')
          .join('\n')
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;

    const premiumEndAt = user.premiumEndAt
      ? Dates.simple4YmdStringFromIso(user.premiumEndAt)
          .split(' ')
          .join('\n')
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;

    const latestAppLaunchedDate = user.latestAppLaunchedDate
      ? Dates.stdDateStringFromIso(user.latestAppLaunchedDate)
          .split(' ')
          .join('\n')
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;

    return {
      id: user.id,
      email: user.email,
      commonId: user.commonId,
      isActive,
      purchaseStatus,
      purchasePlan,
      lastPurchasedAt,
      premiumEndAt,
      latestAppLaunchedDate
    };
  }

  private setUpPath() {
    this.ACCOUNT_SEARCH_PATH = RoutingPathResolver.resolveCommonIdAccountSearch();
    this.ACCOUNT_SEARCH_EMAIL_MENU_KEY = `${this.ACCOUNT_SEARCH_PATH}/email`;
    this.ACCOUNT_SEARCH_USERID_MENU_KEY = `${this.ACCOUNT_SEARCH_PATH}/userId`;
  }

  private setUpBreadcrumbs() {
    this.breadcrumbsLabel = `${this.title} - ${this.EMAIL_MENU_LABEL}`;
    this.breadcrumbs = [
      { key: 'admin', label: 'Admin機能' },
      { key: this.ACCOUNT_SEARCH_PATH, label: this.breadcrumbsLabel }
    ];
  }

  private setUpTabMenus() {
    this.menus = [
      {
        key: this.ACCOUNT_SEARCH_EMAIL_MENU_KEY,
        icon: 'mail_outline',
        label: this.EMAIL_MENU_LABEL,
        isActive: true
      },
      {
        key: this.ACCOUNT_SEARCH_USERID_MENU_KEY,
        icon: 'badge',
        fontSet: 'material-icons-outlined',
        label: this.USER_ID_MENU_LABEL,
        isActive: false
      }
    ];
  }

  private setUpForms() {
    this.filterForm = new UntypedFormGroup({
      email: new UntypedFormControl(),
      userId: new UntypedFormControl()
    });
  }

  private resetForms() {
    this.filterForm.get('email').setValue('');
    this.enteredEmail = '';
    this.filterForm.get('userId').setValue('');
    this.enteredUserId = '';
  }

  private setUpCommonIdUser() {
    this.commonIdUser$ = this.store.select(getCommonIdUser).pipe(filter(it => it != null));
  }

  private setUpIsCommonIdUserSearching() {
    this.isCommonIdUserSearching$ = this.store.select(getCommonIdUserSearching);
  }

  private setUpIsCommonIdUserNotFound() {
    this.isCommonIdUserNotFound$ = this.store.select(getCommonIdUserNotFoundFlag);
  }

  private searchByEmail() {
    Log.debug(this.LOG_SOURCE, `入力されたメールアドレス: ${this.enteredEmail}`);
    // アカウント情報の取得
    this.store.dispatch(initializeFindCommonIdUserByEmail());
    this.store.dispatch(findCommonIdUserByEmail({ email: this.enteredEmail }));
  }

  private searchByCommonId() {
    Log.debug(this.LOG_SOURCE, `入力されたユーザーID: ${this.enteredUserId}`);
    // アカウント情報の取得
    this.store.dispatch(initializeFindCommonIdUserByCommonId());
    this.store.dispatch(findCommonIdUserByCommonId({ commonId: this.enteredUserId }));
  }

  private resetFlags() {
    this.isResultDisplayed = false;
    this.isSearchButtonDisabled = true;
  }
}
