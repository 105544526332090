<div class="root-container">
  <mat-tab-group [(selectedIndex)]="selectedTabIndex">
    <mat-tab *ngFor="let subject of subjectDataArray" label="{{ subject.name }}">
      <div class="subject-container" *ngIf="!isSubjectDataType()">
        <div class="field-container" *ngFor="let field of subject.fields">
          <mat-checkbox
            color="primary"
            [(indeterminate)]="field.indeterminate"
            [(ngModel)]="field.checked"
            (change)="onChangeField(field)"
            >{{ field.name }}</mat-checkbox
          >
          <div class="unit-container">
            <ng-container *ngFor="let unit of field.units">
              <mat-checkbox color="primary" [(ngModel)]="unit.checked" (change)="onChangeUnit(field)">{{ unit.name }}</mat-checkbox>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="subject-container" *ngIf="isSubjectDataType()">
        <div class="field-container">
          <ng-container *ngFor="let unit of subject.fields[0].units">
            <mat-checkbox color="primary" [(ngModel)]="unit.checked" (change)="onChangeUnit(subject.fields[0])">{{
              unit.name
            }}</mat-checkbox>
          </ng-container>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="buttons-container">
    <button mat-flat-button color="primary" (click)="selectCurrentTabAllFields()">全て選択</button>
    <span class="spacer"></span>
    <button mat-stroked-button (click)="cancel()">キャンセル</button>
    <button mat-flat-button color="primary" (click)="submit()">決定</button>
  </div>
</div>
