import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { Log } from 'src/app/utils/log';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';
import { Diff } from 'src/app/utils/diff';
import { KEYBOARD_INPUT_DEBOUNCE_TIME } from 'src/app/resources/config';

@Component({
  selector: 'app-history-search-form',
  templateUrl: './history-search-form.component.html',
  styleUrls: ['./history-search-form.component.scss']
})
export class HistorySearchFormComponent implements OnInit, OnDestroy {
  @Input() historySearchCondition: HistorySearchCondition | undefined;

  @Output() changeCondition = new EventEmitter<HistorySearchCondition>();
  @Output() formIsValid = new EventEmitter<boolean>();

  private LOG_SOURCE = this.constructor.name;
  private lastNotifiedCondition: HistorySearchCondition | undefined;
  private keyWordSubscription: Subscription;

  /**
   * resetForms した際に、keyword の input が空文字で初期化された直後に、
   * change event を発火してしまうため、それを回避するために使用する flag
   */
  private needToIgnoreChangeEvent = false;

  keywordFormControl = new UntypedFormControl('');

  constructor() {}

  ngOnInit() {
    this.setUpKeywordChange();
  }

  ngOnDestroy() {
    if (this.keyWordSubscription) this.keyWordSubscription.unsubscribe();
  }

  // exposed methods --------------------------------------------------------------

  getCurrentCondition(): HistorySearchCondition {
    return this.collectCondition();
  }

  resetForms() {
    this.needToIgnoreChangeEvent = true;
    this.keywordFormControl.setValue('');
    this.lastNotifiedCondition = undefined;
  }

  patchValue(condition: HistorySearchCondition) {
    Log.debug(this.LOG_SOURCE, '値を復元します', condition);

    if (condition.keywords && condition.keywords.length !== 0) {
      this.keywordFormControl.setValue(condition.keywords.join(' '));
    }

    const currentCondition = this.collectCondition();
    this.lastNotifiedCondition = { ...currentCondition };
  }

  // components internal methods ---------------------------------------------------------------

  onChangeValues() {
    const currentCondition = this.collectCondition();
    Log.debug(this.LOG_SOURCE, `現在の選択状況: `, currentCondition);

    if (!Diff.isDifferentObject(this.lastNotifiedCondition, currentCondition)) {
      Log.debug(this.LOG_SOURCE, '前回 emit したデータと同じため無視します');
      return;
    }

    if (this.needToIgnoreChangeEvent) {
      Log.debug(this.LOG_SOURCE, 'notify 不要な event のため無視します');
      this.needToIgnoreChangeEvent = false;
      return;
    }

    Log.debug(this.LOG_SOURCE, '前回と異なるデータのため emit します');
    this.lastNotifiedCondition = { ...currentCondition };
    this.changeCondition.emit(this.lastNotifiedCondition);
  }

  // set ups ---------------------------------------------------------------

  /** 一定時間入力値が変化しなければ onChangeValues() をコールします */
  private setUpKeywordChange() {
    this.keyWordSubscription = this.keywordFormControl.valueChanges
      .pipe(
        debounceTime(KEYBOARD_INPUT_DEBOUNCE_TIME),
        tap(keywords => Log.debug(this.LOG_SOURCE, `after debounce keyword: ${keywords}`))
      )
      .subscribe(() => this.onChangeValues());
  }

  // utils ---------------------------------------------------------------

  private collectCondition(): HistorySearchCondition {
    const condition = this.historySearchCondition || {};
    if (this.keywordFormControl.value !== '') {
      const rawString: string = this.keywordFormControl.value;
      const keywords = rawString.split(/[ 　]+/).filter(it => it !== '');
      if (keywords.length !== 0) condition.keywords = [...keywords];
    } else {
      condition.keywords = [];
    }

    return condition;
  }
}
