import { Component, OnDestroy, OnInit } from '@angular/core';
import { getSignedInUser } from '../../../selectors/auth.selectors';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { User } from '../../../models/user';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { navigate, setBrowserTitle, setTitle } from '../../../actions/core.actions';
import { getPlans } from '../../../selectors/plan.selectors';
import { findPlans, initializeFindPlansState } from '../../../actions/plan.actions';
import { ActivatedRoute } from '@angular/router';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { combineLatest, Observable } from 'rxjs';
import { Plan } from '../../../models/plan';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../selectors/current-date-time.selectors';
import { CurrentDateTime } from '../../../models/current-date-time';
import { getCurrentDateTime, initializeGetCurrentDateTime } from '../../../actions/current-date-time.actions';
import { getUsers } from '../../../selectors/user.selectors';
import { findUsers, initializeFindUsers } from '../../../actions/user.actions';
import { UserSearchCondition } from '../../../models/user-search-condition';
import { PlanUtil, PriorityRecord } from '../../../utils/plan-util';
import {
  ADD_PLAN_DIALOG_HEIGHT,
  ADD_PLAN_DIALOG_WIDTH,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  OTHER_SCHOOL_ID,
  PLAN_AVAILABLE_ORGANIZATION_IDS,
  PLAN_NAMES,
  PLANS_SORT,
  PlanStatuses,
  SCHOOL_ORGANIZATION_ID,
  MEMBER_CONTACT_URL,
  JUKU_ORGANIZATION_ID,
  OTHER_JUKU_CODE,
  PlanSubjectId
} from '../../../resources/config';
import { getHighSchool } from '../../../selectors/static-data.selectors';
import { HighSchool } from '../../../models/common-data';
import { StaticDataUtil } from '../../../utils/static-data-util';
import { Dates } from '../../../utils/dates';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { AddPlanDialogComponent, AddPlanDialogData } from '../add-plan-dialog/add-plan-dialog.component';
import { enter } from '../../../resources/animations';
import { Juku } from 'src/app/models/juku';
import { getJukus } from 'src/app/selectors/juku.selectors';
import { JukuUtil } from 'src/app/utils/juku-util';
import { findJukus, initializeFindJukusState } from 'src/app/actions/juku.actions';

interface PlanData {
  planId: string;
  subjectId: string;
  planName: string;
  memberNum: string;
  memberNames: string[];
  planStatus: string;
  planStartAt: string;
  planEndAt: string;
  planLeftDay: number;
  planToStartDay: number;
  isTrial: boolean;
}

interface Breadcrumb {
  key: string;
  label: string;
}

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
  animations: [enter]
})
export class PlansComponent implements OnInit, OnDestroy {
  isAdmin: boolean;
  isOrganizationAdmin: boolean;
  schoolId: string;
  schoolName: string;
  organization: string;
  plansData$: Observable<PlanData[]>;
  planActiveStatus: string = PlanStatuses.ACTIVE;
  planEndedStatus: string = PlanStatuses.ENDED;
  planReserveStatus: string = PlanStatuses.RESERVE;
  memberContactUrl: string = MEMBER_CONTACT_URL;
  breadcrumbs: Breadcrumb[] = [];
  schoolOrganizationId: string = SCHOOL_ORGANIZATION_ID;
  jukuOrganizationId: string = JUKU_ORGANIZATION_ID;

  displayedColumns: string[] = ['planName', 'memberNum', 'memberNames', 'planStatus', 'action'];

  private LOG_SOURCE = this.constructor.name;
  private title = 'プラン一覧';

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private dialog: MatDialog) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.setUp();
  }

  ngOnDestroy(): void {
    this.store.dispatch(initializeFindPlansState());
    this.store.dispatch(initializeGetCurrentDateTime());
    this.store.dispatch(initializeFindUsers());
    this.store.dispatch(initializeFindJukusState());
  }

  otherSchoolCheck(): boolean {
    return (
      (this.organization === SCHOOL_ORGANIZATION_ID && this.schoolId === OTHER_SCHOOL_ID) ||
      (this.organization === JUKU_ORGANIZATION_ID && this.schoolId === OTHER_JUKU_CODE)
    );
  }

  addPlan() {
    const config: MatDialogConfig<AddPlanDialogData> = {
      height: ADD_PLAN_DIALOG_HEIGHT,
      width: ADD_PLAN_DIALOG_WIDTH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      data: { schoolId: this.schoolId, school: this.schoolName, organization: this.organization },
      disableClose: true
    };
    this.dialog
      .open(AddPlanDialogComponent, config)
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) this.setUpPlans(this.organization, this.schoolId);
      });
  }

  planStatuses(planId: string) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlanStatuses(planId) }));
  }

  planAssign(planId: string) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlanAssign(planId) }));
  }

  breadcrumbsClickHandler(key) {
    switch (key) {
      case 'admin':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
        break;
      case 'admin/schools':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSchools() }));
        break;
      case 'admin/jukus':
        this.store.dispatch(navigate({ url: RoutingPathResolver.resolveJukus() }));
        break;
    }
  }

  menuClickHandler(key) {
    switch (key) {
      case 'admin/plans':
        break;
      case 'admin/members':
        this.store.dispatch(
          navigate({
            url: RoutingPathResolver.resolveMembers(this.isAdmin ? this.organization : null, this.isAdmin ? this.schoolId : null)
          })
        );
        break;
    }
  }

  private setUp() {
    const organization: string = this.activatedRoute.snapshot.paramMap.get('organization') || '';
    const schoolId: string = this.activatedRoute.snapshot.paramMap.get('schoolId') || '';

    this.store
      .select(getSignedInUser)
      .pipe(filter<User>(it => it != null && it !== 'none'))
      .pipe(take(1))
      .subscribe(user => {
        this.isAdmin = user.isAdmin;
        this.isOrganizationAdmin = user.isOrganizationAdmin;
        user.isAdmin && organization && schoolId
          ? this.setUpPlans(organization, schoolId)
          : user.isOrganizationAdmin
          ? organization !== '' || schoolId !== ''
            ? this.store.dispatch(navigate({ url: RoutingPathResolver.resolvePlans() }))
            : this.setUpPlans(user.organization, user.schoolId)
          : this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
      });
  }

  private setUpPlans(organization: string, schoolId: string) {
    this.schoolId = schoolId;
    this.organization = organization;

    if (!this.organizationCheck()) {
      this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
      return;
    }

    const plans$: Observable<Plan[]> = this.store.select(getPlans).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const currentDateTime$: Observable<CurrentDateTime> = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const users$: Observable<User[]> = this.store.select(getUsers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const highSchools$: Observable<HighSchool[]> = this.store.select(getHighSchool).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const jukus$: Observable<Juku[]> = this.store.select(getJukus).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    this.plansData$ = combineLatest([plans$, currentDateTime$, users$, highSchools$, jukus$])
      .pipe(take(1))
      .pipe(
        map(([plans, currentDateTime, users, highSchools, jukus]) => {
          if (organization === SCHOOL_ORGANIZATION_ID) {
            this.schoolName = StaticDataUtil.getHiSchoolName(highSchools, schoolId);
          } else if (organization === JUKU_ORGANIZATION_ID) {
            const juku: Juku = JukuUtil.getJukuByJukuCode(jukus, schoolId);
            if (juku !== undefined && juku !== null) {
              this.schoolName = JukuUtil.getDisplayJukuName(juku);
            }
          }

          if (this.schoolName === '') {
            this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAdmin() }));
            return;
          }

          this.breadcrumbs = [];
          this.breadcrumbs.push({ key: 'admin', label: 'Admin機能' });

          if (organization === SCHOOL_ORGANIZATION_ID) {
            this.breadcrumbs.push({ key: 'admin/schools', label: 'アカウント管理 - 学校' });
          } else if (organization === JUKU_ORGANIZATION_ID) {
            this.breadcrumbs.push({ key: 'admin/jukus', label: 'アカウント管理 - 塾・予備校' });
          }

          this.breadcrumbs.push({ key: 'admin/plans', label: this.schoolName + ' - プラン一覧' });

          return plans
            .map(plan => this.setUpPlanData(plan, currentDateTime, users))
            .sort((a, b) => PLANS_SORT.indexOf(a.subjectId) - PLANS_SORT.indexOf(b.subjectId));
        }),
        shareReplay(1)
      );

    this.store.dispatch(getCurrentDateTime());
    this.store.dispatch(findPlans({ organization, schoolId }));
    const userSearchCondition: UserSearchCondition = { organization, schoolId };
    this.store.dispatch(findUsers({ userSearchCondition }));
    this.store.dispatch(findJukus({}));
  }

  private setUpPlanData(plan: Plan, currentDateTime: CurrentDateTime, users: User[]): PlanData {
    const priorityRecord: PriorityRecord = PlanUtil.getPriorityRecord(plan.records, currentDateTime);

    return {
      planId: plan.id,
      subjectId: plan.subjectId,
      planName: PLAN_NAMES[plan.subjectId],
      memberNum: PlanUtil.getAssignmentMemberCount(priorityRecord.record) + '/' + priorityRecord.record.memberCount,
      memberNames: [...new Set(PlanUtil.getAssignmentUserNames(priorityRecord.record, users))],
      planStatus: priorityRecord.status,
      planStartAt: Dates.simple4YmdStringFromIso(priorityRecord.record.startAt),
      planEndAt: Dates.simple4YmdStringFromIso(priorityRecord.record.endAt),
      planLeftDay: Dates.getDiffDay(Dates.simple4YmdStringFromIso(currentDateTime.dateTime), priorityRecord.record.endAt),
      planToStartDay: Dates.getDiffDay(Dates.simple4YmdStringFromIso(currentDateTime.dateTime), priorityRecord.record.startAt),
      isTrial: plan.subjectId === PlanSubjectId.TRIAL ? true : false
    };
  }

  private organizationCheck(): boolean {
    return PLAN_AVAILABLE_ORGANIZATION_IDS.includes(this.organization);
  }
}
