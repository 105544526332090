import {
  firebaseConfig,
  firebaseLocation,
  commonIdConfig,
  passNaviConfig,
  gaConfig,
  redirectDomainConfig,
  manualPdfFiles,
  microCmsConfig,
  downloadFiles,
  stripeConfig,
  cloudfrontHost,
  lambdaHost,
  sentryDsn,
  release
} from './.prod.config';

export const environment = {
  production: true,
  envName: 'prod',
  firebase: firebaseConfig,
  firebaseLocation,
  commonIdConfig,
  passNaviConfig,
  gaConfig,
  redirectDomainConfig,
  manualPdfFiles,
  microCmsConfig,
  downloadFiles,
  stripeConfig,
  cloudfrontHost,
  lambdaHost,
  sentryDsn,
  release
};
