import { createAction, props } from '@ngrx/store';

import { SearchCondition, SearchProblemsCondition } from '../models/search-condition';
import { ScienceSearchCondition } from '../models/science-search-condition';
import { ScienceProblem, EnglishProblem, HistoryProblem, NationalLanguageProblem } from '../models/problem';
import { SearchCountResponse } from '../models/search-count-response';
import { EnglishSearchCondition } from '../models/english-search-condition';
import { HistorySearchCondition } from '../models/history-search-condition';
import { NationalLanguageSearchCondition } from '../models/national-language-search-condition';

// setSearchCondition ------------------------------------------------------------
export const setSearchCondition = createAction(
  '[Search] Set Search Condition',
  // NOTE: 教科が増えたら Generics を追加
  props<{ condition: SearchCondition<EnglishSearchCondition | ScienceSearchCondition | HistorySearchCondition> }>()
);
export const initializeSearchCondition = createAction('[Search] Initialize Search Condition');

// findEnglishProblemCount ------------------------------------------------------------
export const findEnglishProblemCount = createAction(
  '[Search] Find English Problem Count',
  props<{ condition: SearchCondition<EnglishSearchCondition>; logging: boolean }>()
);
export const findEnglishProblemCountSuccess = createAction(
  '[Search] Find English Problem Count Success',
  props<{ result: SearchCountResponse }>()
);
export const findEnglishProblemCountFailure = createAction('[Search] Find English Problem Count Failure');

// findMathProblemCount ------------------------------------------------------------
export const findMathProblemCount = createAction(
  '[Search] Find Math Problem Count',
  props<{ condition: SearchCondition<ScienceSearchCondition>; logging: boolean }>()
);
export const findMathProblemCountSuccess = createAction(
  '[Search] Find Math Problem Count Success',
  props<{ result: SearchCountResponse }>()
);
export const findMathProblemCountFailure = createAction('[Search] Find Math Problem Count Failure');

// findNationalLanguageProblemCount ------------------------------------------------------------
export const findNationalLanguageProblemCount = createAction(
  '[Search] Find National Language Problem Count',
  props<{ condition: SearchCondition<NationalLanguageSearchCondition>; logging: boolean }>()
);
export const findNationalLanguageProblemCountSuccess = createAction(
  '[Search] Find National Language Problem Count Success',
  props<{ result: SearchCountResponse }>()
);
export const findNationalLanguageProblemCountFailure = createAction('[Search] Find National Language Problem Count Failure');

// findPhysicsProblemCount ------------------------------------------------------------
export const findPhysicsProblemCount = createAction(
  '[Search] Find Physics Problem Count',
  props<{ condition: SearchCondition<ScienceSearchCondition>; logging: boolean }>()
);
export const findPhysicsProblemCountSuccess = createAction(
  '[Search] Find Physics Problem Count Success',
  props<{ result: SearchCountResponse }>()
);
export const findPhysicsProblemCountFailure = createAction('[Search] Find Physics Problem Count Failure');

// findChemistryProblemCount ------------------------------------------------------------
export const findChemistryProblemCount = createAction(
  '[Search] Find Chemistry Problem Count',
  props<{ condition: SearchCondition<ScienceSearchCondition>; logging: boolean }>()
);
export const findChemistryProblemCountSuccess = createAction(
  '[Search] Find Chemistry Problem Count Success',
  props<{ result: SearchCountResponse }>()
);
export const findChemistryProblemCountFailure = createAction('[Search] Find Chemistry Problem Count Failure');

// findBiologyProblemCount ------------------------------------------------------------
export const findBiologyProblemCount = createAction(
  '[Search] Find Biology Problem Count',
  props<{ condition: SearchCondition<ScienceSearchCondition>; logging: boolean }>()
);
export const findBiologyProblemCountSuccess = createAction(
  '[Search] Find Biology Problem Count Success',
  props<{ result: SearchCountResponse }>()
);
export const findBiologyProblemCountFailure = createAction('[Search] Find Biology Problem Count Failure');

// findJapaneseHistoryProblemCount ------------------------------------------------------------
export const findJapaneseHistoryProblemCount = createAction(
  '[Search] Find Japanese History Problem Count',
  props<{ condition: SearchCondition<HistorySearchCondition>; logging: boolean }>()
);
export const findJapaneseHistoryProblemCountSuccess = createAction(
  '[Search] Find Japanese History Problem Count Success',
  props<{ result: SearchCountResponse }>()
);
export const findJapaneseHistoryProblemCountFailure = createAction('[Search] Find Japanese History Problem Count Failure');

// findWorldHistoryProblemCount ------------------------------------------------------------
export const findWorldHistoryProblemCount = createAction(
  '[Search] Find World History Problem Count',
  props<{ condition: SearchCondition<HistorySearchCondition>; logging: boolean }>()
);
export const findWorldHistoryProblemCountSuccess = createAction(
  '[Search] Find World History Problem Count Success',
  props<{ result: SearchCountResponse }>()
);
export const findWorldHistoryProblemCountFailure = createAction('[Search] Find World History Problem Count Failure');

// findGeographyProblemCount ------------------------------------------------------------
export const findGeographyProblemCount = createAction(
  '[Search] Find Geography Problem Count',
  props<{ condition: SearchCondition<ScienceSearchCondition>; logging: boolean }>()
);
export const findGeographyProblemCountSuccess = createAction(
  '[Search] Find Geography Problem Count Success',
  props<{ result: SearchCountResponse }>()
);
export const findGeographyProblemCountFailure = createAction('[Search] Find Geography Problem Count Failure');

// findPoliticalEconomyProblemCount ------------------------------------------------------------
export const findPoliticalEconomyProblemCount = createAction(
  '[Search] Find Political Economy Problem Count',
  props<{ condition: SearchCondition<ScienceSearchCondition>; logging: boolean }>()
);
export const findPoliticalEconomyProblemCountSuccess = createAction(
  '[Search] Find Political Economy Problem Count Success',
  props<{ result: SearchCountResponse }>()
);
export const findPoliticalEconomyProblemCountFailure = createAction('[Search] Find Political Economy Problem Count Failure');

/** 教科共通で使用する */
export const initializeProblemCountState = createAction('[Search] Initialize Problem Count State');

// findEnglishProblems ------------------------------------------------------------
export const findEnglishProblems = createAction(
  '[Search] Find English Problems',
  props<{ condition: SearchProblemsCondition<EnglishSearchCondition> }>()
);
export const findEnglishProblemsSuccess = createAction('[Search] Find English Problems Success', props<{ problems: EnglishProblem[] }>());
export const findEnglishProblemsFailure = createAction('[Search] Find English Problems Failure');

// findMathProblems ------------------------------------------------------------
export const findMathProblems = createAction(
  '[Search] Find Math Problems',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findMathProblemsSuccess = createAction('[Search] Find Math Problems Success', props<{ problems: ScienceProblem[] }>());
export const findMathProblemsFailure = createAction('[Search] Find Math Problems Failure');

// findNationalLanguageProblems ------------------------------------------------------------
export const findNationalLanguageProblems = createAction(
  '[Search] Find National Language Problems',
  props<{ condition: SearchProblemsCondition<NationalLanguageSearchCondition> }>()
);
export const findNationalLanguageProblemsSuccess = createAction(
  '[Search] Find National Language Problems Success',
  props<{ problems: NationalLanguageProblem[] }>()
);
export const findNationalLanguageProblemsFailure = createAction('[Search] Find National Language Problems Failure');

// findPhysicsProblems ------------------------------------------------------------
export const findPhysicsProblems = createAction(
  '[Search] Find Physics Problems',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findPhysicsProblemsSuccess = createAction('[Search] Find Physics Problems Success', props<{ problems: ScienceProblem[] }>());
export const findPhysicsProblemsFailure = createAction('[Search] Find Physics Problems Failure');

// findChemistryProblems ------------------------------------------------------------
export const findChemistryProblems = createAction(
  '[Search] Find Chemistry Problems',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findChemistryProblemsSuccess = createAction(
  '[Search] Find Chemistry Problems Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findChemistryProblemsFailure = createAction('[Search] Find Chemistry Problems Failure');

// findBiologyProblems ------------------------------------------------------------
export const findBiologyProblems = createAction(
  '[Search] Find Biology Problems',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findBiologyProblemsSuccess = createAction('[Search] Find Biology Problems Success', props<{ problems: ScienceProblem[] }>());
export const findBiologyProblemsFailure = createAction('[Search] Find Biology Problems Failure');

// findJapaneseHistoryProblems ------------------------------------------------------------
export const findJapaneseHistoryProblems = createAction(
  '[Search] Find Japanese History Problems',
  props<{ condition: SearchProblemsCondition<HistorySearchCondition> }>()
);
export const findJapaneseHistoryProblemsSuccess = createAction(
  '[Search] Find Japanese History Problems Success',
  props<{ problems: HistoryProblem[] }>()
);
export const findJapaneseHistoryProblemsFailure = createAction('[Search] Find Japanese History Problems Failure');

// findWorldHistoryProblems ------------------------------------------------------------
export const findWorldHistoryProblems = createAction(
  '[Search] Find World History Problems',
  props<{ condition: SearchProblemsCondition<HistorySearchCondition> }>()
);
export const findWorldHistoryProblemsSuccess = createAction(
  '[Search] Find World History Problems Success',
  props<{ problems: HistoryProblem[] }>()
);
export const findWorldHistoryProblemsFailure = createAction('[Search] Find World History Problems Failure');

// findGepgraphyProblems ------------------------------------------------------------
export const findGeographyProblems = createAction(
  '[Search] Find Geography Problems',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findGeographyProblemsSuccess = createAction(
  '[Search] Find Geography Problems Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findGeographyProblemsFailure = createAction('[Search] Find Geography Problems Failure');

// findPoliticalEconomyProblems ------------------------------------------------------------
export const findPoliticalEconomyProblems = createAction(
  '[Search] Find Political Economy Problems',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findPoliticalEconomyProblemsSuccess = createAction(
  '[Search] Find Political Economy Problems Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findPoliticalEconomyProblemsFailure = createAction('[Search] Find Political Economy Problems Failure');

/** 教科共通で使用する */
export const initializeProblemsState = createAction('[Search] Initialize Problems State');
export const initializeMatchedProblemIdsState = createAction('[Search] Initialize Matched Problem Ids State');

// findEnglishProblemsForPrint ------------------------------------------------------------
export const findEnglishProblemsForPrint = createAction(
  '[Search] Find English Problems For Print',
  props<{ condition: SearchProblemsCondition<EnglishSearchCondition> }>()
);
export const findEnglishProblemsForPrintSuccess = createAction(
  '[Search] Find English Problems For Print Success',
  props<{ problems: EnglishProblem[] }>()
);
export const findEnglishProblemsForPrintFailure = createAction('[Search] Find English Problems For Print Failure');

// findMathProblemsForPrint ------------------------------------------------------------
export const findMathProblemsForPrint = createAction(
  '[Search] Find Math Problems For Print',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findMathProblemsForPrintSuccess = createAction(
  '[Search] Find Math Problems For Print Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findMathProblemsForPrintFailure = createAction('[Search] Find Math Problems For Print Failure');

// findNationalLanguageProblemsForPrint ------------------------------------------------------------
export const findNationalLanguageProblemsForPrint = createAction(
  '[Search] Find National Language Problems For Print',
  props<{ condition: SearchProblemsCondition<NationalLanguageSearchCondition> }>()
);
export const findNationalLanguageProblemsForPrintSuccess = createAction(
  '[Search] Find National Language Problems For Print Success',
  props<{ problems: NationalLanguageProblem[] }>()
);
export const findNationalLanguageProblemsForPrintFailure = createAction('[Search] Find National Language Problems For Print Failure');

// findPhysicsProblemsForPrint ------------------------------------------------------------
export const findPhysicsProblemsForPrint = createAction(
  '[Search] Find Physics Problems For Print',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findPhysicsProblemsForPrintSuccess = createAction(
  '[Search] Find Physics Problems For Print Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findPhysicsProblemsForPrintFailure = createAction('[Search] Find Physics Problems For Print Failure');

// findChemistryProblemsForPrint ------------------------------------------------------------
export const findChemistryProblemsForPrint = createAction(
  '[Search] Find Chemistry Problems For Print',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findChemistryProblemsForPrintSuccess = createAction(
  '[Search] Find Chemistry Problems For Print Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findChemistryProblemsForPrintFailure = createAction('[Search] Find Chemistry Problems For Print Failure');

// findBiologyProblemsForPrint ------------------------------------------------------------
export const findBiologyProblemsForPrint = createAction(
  '[Search] Find Biology Problems For Print',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findBiologyProblemsForPrintSuccess = createAction(
  '[Search] Find Biology Problems For Print Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findBiologyProblemsForPrintFailure = createAction('[Search] Find Biology Problems For Print Failure');

// findJapaneseHistoryProblemsForPrint ------------------------------------------------------------
export const findJapaneseHistoryProblemsForPrint = createAction(
  '[Search] Find Japanese History Problems For Print',
  props<{ condition: SearchProblemsCondition<HistorySearchCondition> }>()
);
export const findJapaneseHistoryProblemsForPrintSuccess = createAction(
  '[Search] Find Japanese History Problems For Print Success',
  props<{ problems: HistoryProblem[] }>()
);
export const findJapaneseHistoryProblemsForPrintFailure = createAction('[Search] Find Japanese History Problems For Print Failure');

// findWorldHistoryProblemsForPrint ------------------------------------------------------------
export const findWorldHistoryProblemsForPrint = createAction(
  '[Search] Find World History Problems For Print',
  props<{ condition: SearchProblemsCondition<HistorySearchCondition> }>()
);
export const findWorldHistoryProblemsForPrintSuccess = createAction(
  '[Search] Find World History Problems For Print Success',
  props<{ problems: HistoryProblem[] }>()
);
export const findWorldHistoryProblemsForPrintFailure = createAction('[Search] Find World History Problems For Print Failure');

// findGeographyProblemsForPrint ------------------------------------------------------------
export const findGeographyProblemsForPrint = createAction(
  '[Search] Find Geography Problems For Print',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findGeographyProblemsForPrintSuccess = createAction(
  '[Search] Find Geography Problems For Print Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findGeographyProblemsForPrintFailure = createAction('[Search] Find Geography Problems For Print Failure');

// findPoliticalEconomyProblemsForPrint ------------------------------------------------------------
export const findPoliticalEconomyProblemsForPrint = createAction(
  '[Search] Find Political Economy Problems For Print',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findPoliticalEconomyProblemsForPrintSuccess = createAction(
  '[Search] Find Political Economy Problems For Print Success',
  props<{ problems: ScienceProblem[] }>()
);
export const findPoliticalEconomyProblemsForPrintFailure = createAction('[Search] Find Political Economy Problems For Print Failure');

/** 教科共通で使用する */
export const initializeProblemsForPrintState = createAction('[Search] Initialize Problems For Print State');

// findScienceProblemIds ------------------------------------------------------------
export const findScienceProblemIds = createAction(
  '[Search] Find Science Problem Ids',
  props<{ condition: SearchProblemsCondition<ScienceSearchCondition> }>()
);
export const findScienceProblemIdsSuccess = createAction('[Search] Find Science Problem Ids Success', props<{ ids: string[] }>());
export const findScienceProblemIdsFailure = createAction('[Search] Find Science Problem Ids Failure');

// findEnglishProblemIds ------------------------------------------------------------
export const findEnglishProblemIds = createAction(
  '[Search] Find English Problem Ids',
  props<{ condition: SearchProblemsCondition<EnglishSearchCondition> }>()
);
export const findEnglishProblemIdsSuccess = createAction('[Search] Find English Problem Ids Success', props<{ ids: string[] }>());
export const findEnglishProblemIdsFailure = createAction('[Search] Find English Problem Ids Failure');

// findNationalLanguageProblemIds ------------------------------------------------------------
export const findNationalLanguageProblemIds = createAction(
  '[Search] Find NationalLanguage Problem Ids',
  props<{ condition: SearchProblemsCondition<NationalLanguageSearchCondition> }>()
);
export const findNationalLanguageProblemIdsSuccess = createAction(
  '[Search] Find NationalLanguage Problem Ids Success',
  props<{ ids: string[] }>()
);
export const findNationalLanguageProblemIdsFailure = createAction('[Search] Find NationalLanguage Problem Ids Failure');

// findHistoryProblemIds ------------------------------------------------------------
export const findHistoryProblemIds = createAction(
  '[Search] Find History Problem Ids',
  props<{ condition: SearchProblemsCondition<HistorySearchCondition> }>()
);
export const findHistoryProblemIdsSuccess = createAction('[Search] Find History Problem Ids Success', props<{ ids: string[] }>());
export const findHistoryProblemIdsFailure = createAction('[Search] Find History Problem Ids Failure');

// findScienceProblem ------------------------------------------------------------
export const findScienceProblem = createAction('[Search] Find Science Problem', props<{ subjectId: string; problemId: string }>());
export const findScienceProblemByPaperId = createAction(
  '[Search] Find Science Problem By Paper Id',
  props<{ subjectId: string; paperId: string }>()
);
export const findScienceProblemSuccess = createAction('[Search] Find Science Problem Success', props<{ problem: ScienceProblem }>());
export const findScienceProblemFailure = createAction('[Search] Find Science Problem Failure');

// findEnglishProblem ------------------------------------------------------------
export const findEnglishProblem = createAction('[Search] Find English Problem', props<{ subjectId: string; problemId: string }>());
export const findEnglishProblemByPaperId = createAction(
  '[Search] Find English Problem By Paper Id',
  props<{ subjectId: string; paperId: string }>()
);
export const findEnglishProblemSuccess = createAction('[Search] Find English Problem Success', props<{ problem: EnglishProblem }>());
export const findEnglishProblemFailure = createAction('[Search] Find English Problem Failure');

// findNationalLanguageProblem ------------------------------------------------------------
export const findNationalLanguageProblem = createAction(
  '[Search] Find National Language Problem',
  props<{ subjectId: string; problemId: string }>()
);
export const findNationalLanguageProblemByPaperId = createAction(
  '[Search] Find National Language Problem By Paper Id',
  props<{ subjectId: string; paperId: string }>()
);
export const findNationalLanguageProblemSuccess = createAction(
  '[Search] Find National Language Problem Success',
  props<{ problem: NationalLanguageProblem }>()
);
export const findNationalLanguageProblemFailure = createAction('[Search] Find National Language Problem Failure');

// findNationalLanguageProblemsById ------------------------------------------------------------
export const findNationalLanguageProblemsById = createAction(
  '[Search] Find National Language Problems By Id',
  props<{ subjectId: string; problemId: string }>()
);
export const findNationalLanguageProblemsByIdSuccess = createAction(
  '[Search] Find National Language Problems By Id Success',
  props<{ problems: NationalLanguageProblem[] }>()
);
export const findNationalLanguageProblemsByIdFailure = createAction('[Search] Find National Language Problems By Id Failure');

// findHistoryProblem ------------------------------------------------------------
export const findHistoryProblem = createAction('[Search] Find History Problem', props<{ subjectId: string; problemId: string }>());
export const findHistoryProblemByPaperId = createAction(
  '[Search] Find History Problem By Paper Id',
  props<{ subjectId: string; paperId: string }>()
);
export const findHistoryProblemSuccess = createAction('[Search] Find History Problem Success', props<{ problem: HistoryProblem }>());
export const findHistoryProblemFailure = createAction('[Search] Find History Problem Failure');

/** 教科共通で使用する */
export const initializeProblemState = createAction('[Search] Initialize Problem State');
