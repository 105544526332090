import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { navigate, openWindow, setTitle } from '../../../../actions/core.actions';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { getCurrentDateTime as getCurrentDateTimeSelector } from '../../../../selectors/current-date-time.selectors';
import { filter, shareReplay, take } from 'rxjs/operators';
import { getCurrentDateTime } from '../../../../actions/current-date-time.actions';
import { getCommonIdSignedInUser } from '../../../../selectors/common-id/common-id-auth.selectors';
import { CommonIdUser } from '../../../../models/common-id/common-id-user';
import { CommonIdUserUtil } from '../../../../utils/common-id/common-id-user-util';
import { CommonIdPurchases } from '../../../../models/common-id/common-id-purchases';
import { getCommonIdPurchases } from '../../../../selectors/common-id/common-id-purchases.selectors';
import { commonIdFindPurchases, initializeCommonIdFindPurchasesState } from '../../../../actions/common-id/common-id-purchases.actions';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { CONTACT_GOOGLE_FORM_URL, PURCHASE_METHOD_NAMES, PURCHASE_PLAN_NAMES } from '../../../../resources/common-id-config';
import { Dates } from '../../../../utils/dates';
import { enter } from '../../../../resources/animations';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { findCommonIdSignedInUser } from '../../../../actions/common-id/common-id-auth.actions';
import { CommonIdContactUtil } from '../../../../utils/common-id/common-id-contact-util';

interface PurchaseData {
  date: string;
  plan: string;
  price: string;
  method: string;
  expiry: string;
  status: number;
}

@Component({
  selector: 'app-common-id-payments',
  templateUrl: './payments.component.html',
  animations: [enter],
  styleUrls: ['./payments.component.scss']
})
export class CommonIdPaymentsComponent implements OnInit, OnDestroy, AfterViewInit {
  private LOG_SOURCE = this.constructor.name;

  private title = '購入履歴';
  private signedInUserSubscription: Subscription;
  topUrl: string;
  loaded$: Observable<boolean> = of(false);
  isPremiumUser$: Observable<boolean>;
  userPlan: {
    planName: string;
    premiumEndAt: string;
    leftDay: number;
  };
  purchases: PurchaseData[] = [];

  constructor(private store: Store<RootState>, @Inject(WINDOW_OBJECT) private window: Window) {}

  ngOnInit() {
    this.topUrl = RoutingPathResolver.resolveCommonIdTop();
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.setUp();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    this.store.dispatch(initializeCommonIdFindPurchasesState());
    if (this.signedInUserSubscription) {
      this.signedInUserSubscription.unsubscribe();
    }
  }

  setUp() {
    this.getUser();
  }

  getUser() {
    this.loaded$ = of(false);
    // 最新のユーザ情報を取得
    this.store.dispatch(findCommonIdSignedInUser());
    this.store.dispatch(getCurrentDateTime());

    if (this.signedInUserSubscription) {
      this.signedInUserSubscription.unsubscribe();
    }

    const currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    const signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(
      filter<CommonIdUser>(it => it != null && it !== 'none'),
      shareReplay(1)
    );

    this.signedInUserSubscription = combineLatest([currentDateTime$, signedInUser$])
      .pipe(filter(it => it != null))
      .subscribe(([currentDateTime, signedInUser]) => {
        const plan = PURCHASE_PLAN_NAMES.find(it => it.id === signedInUser.purchasePlanId);
        if (plan) {
          this.userPlan = {
            planName: plan.prefix ? `${plan.prefix}（${plan.name}）` : plan.name,
            premiumEndAt: Dates.simple4YmdStringFromIso(signedInUser.premiumEndAt),
            leftDay: CommonIdUserUtil.getPremiumUserLeftDay(currentDateTime, signedInUser)
          };
        }
        this.isPremiumUser$ = of(CommonIdUserUtil.getIsPremiumUser(currentDateTime, signedInUser));
      });
    this.setUpPurchases();
  }

  setUpPurchases() {
    this.purchases = [];
    this.store.dispatch(initializeCommonIdFindPurchasesState());
    this.store
      .select(getCommonIdSignedInUser)
      .pipe(
        filter<CommonIdUser>(it => it != null && it !== 'none'),
        take(1)
      )
      .subscribe(signedInUser => {
        this.store.dispatch(commonIdFindPurchases({ userId: signedInUser.id }));
      });
    this.store
      .select(getCommonIdPurchases)
      .pipe(
        filter<CommonIdPurchases>(it => it != null),
        take(1)
      )
      .subscribe(purchases => {
        if (!purchases.purchases) {
          this.loaded$ = of(true);
          return;
        }

        purchases.purchases.forEach(purchase => {
          const plan = PURCHASE_PLAN_NAMES.find(it => it.id === purchase.planId);
          const method = PURCHASE_METHOD_NAMES.find(it => it.id === purchase.paymentMethod);
          if (plan && method) {
            this.purchases.push({
              date: Dates.simple4YmdStringFromIso(purchase.purchasedAt),
              plan: plan.prefix ? `${plan.prefix}（${plan.name}）` : plan.name,
              price: '¥ ' + purchase.price.toLocaleString('ja-JP'),
              method: method.name,
              expiry: Dates.simple4YmdStringFromIso(purchase.premiumEndAt),
              status: purchase.status
            });
          }
        });

        this.purchases = this.purchases.reverse();

        this.loaded$ = of(true);
      });
  }

  gotoContact() {
    this.store
      .select(getCommonIdSignedInUser)
      .pipe(
        filter<CommonIdUser>(it => it != null && it !== 'none'),
        take(1)
      )
      .subscribe(user => {
        const url = CommonIdContactUtil.getGoogleFormUrl(CONTACT_GOOGLE_FORM_URL, user.commonId, this.window.navigator.userAgent);
        this.store.dispatch(openWindow({ url }));
      });
  }

  gotoPurchase() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdPurchase() }));
  }

  gotoPremium() {
    const url = RoutingPathResolver.resolveCommonIdTop();
    this.store.dispatch(navigate({ url, extras: { queryParams: { to: 'premium' } } }));
  }
}
