import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, combineLatest, throwError } from 'rxjs';
import { take, filter, map, shareReplay, switchMap, startWith } from 'rxjs/operators';

import * as PlaylistActions from 'src/app/actions/playlist.actions';
import * as PlaylistSelectors from 'src/app/selectors/playlist.selectors';
import { RootState } from 'src/app/reducers';
import { SubjectId } from 'src/app/resources/config';
import { setTitle, dispatchAppError, openWindow, setBrowserTitle } from 'src/app/actions/core.actions';
import { Playlist } from 'src/app/models/playlist';
import {
  ReadablePlaylistProblem,
  ReadableEnglishPlaylistProblem,
  ReadableSciencePlaylistProblem,
  ReadableNationalLanguagePlaylistProblem,
  ReadableHistoryPlaylistProblem
} from 'src/app/models/problem';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { QueryParamsMapper } from 'src/app/mappers/query-params-mapper';
import { SearchProblemsCondition } from 'src/app/models/search-condition';
import { Log } from 'src/app/utils/log';
import { GeneralError } from 'src/app/errors/general-error';
import { enter } from 'src/app/resources/animations';
import { CustomErrorMessage } from 'src/app/errors/error-info';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { SubjectUtil } from '../../../utils/subject-util';
import { User } from 'src/app/models/user';
import { ThemeForDisplay } from 'src/app/models/playlist';
import { reportAppEvent } from 'src/app/actions/event-log.actions';
import { AppEvent } from 'src/app/models/event-log';

@Component({
  selector: 'app-playlist-detail',
  templateUrl: './playlist-detail.component.html',
  styleUrls: ['./playlist-detail.component.scss'],
  animations: [enter]
})
export class PlaylistDetailComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private objectUrls: string[] = [];

  playlist$: Observable<Playlist>;
  englishPlaylistThemes$: Observable<ThemeForDisplay<ReadableEnglishPlaylistProblem>[]>;
  mathPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  nationalLanguagePlaylistThemes$: Observable<ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>[]>;
  physicsPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  chemistryPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  biologyPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  japaneseHistoryPlaylistThemes$: Observable<ThemeForDisplay<ReadableHistoryPlaylistProblem>[]>;
  worldHistoryPlaylistThemes$: Observable<ThemeForDisplay<ReadableHistoryPlaylistProblem>[]>;
  geographyPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  politicalEconomyPlaylistThemes$: Observable<ThemeForDisplay<ReadableSciencePlaylistProblem>[]>;
  loaded$: Observable<boolean>;
  signedInUser$: Observable<User>;
  isTrial: boolean;

  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute, private location: Location) {}

  ngOnInit() {
    this.setUpUser();
    this.findPlaylistIfNeeded();
    this.setUpPlaylist();
    this.setUpTitle();
    this.setUpEnglishPlaylistThemes();
    this.setUpMathPlaylistThemes();
    this.setUpNationalLanguagePlaylistThemes();
    this.setUpPhysicsPlaylistThemes();
    this.setUpChemistryPlaylistThemes();
    this.setUpBiologyPlaylistThemes();
    this.setUpJapaneseHistoryPlaylistThemes();
    this.setUpWorldHistoryPlaylistThemes();
    this.setUpGeographyPlaylistThemes();
    this.setUpPoliticalEconomyPlaylistThemes();
    this.setUpLoaded();
    this.findPlaylistProblems();
  }

  ngOnDestroy() {
    this.objectUrls.forEach(url => URL.revokeObjectURL(url));
    this.store.dispatch(PlaylistActions.initializePdfObjectUrlState());
    this.store.dispatch(PlaylistActions.initializeEnglishPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeMathPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeNationalLanguagePlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializePhysicsPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeChemistryPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeBiologyPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeJapaneseHistoryPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeWorldHistoryPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializeGeographyPlaylistProblemsState());
    this.store.dispatch(PlaylistActions.initializePoliticalEconomyPlaylistProblemsState());
  }

  backToPreviousView() {
    this.location.back();
  }

  openThemePdf(event: Event, theme: ThemeForDisplay<ReadablePlaylistProblem>) {
    event.stopPropagation();
    if (!theme.pdfPath) {
      Log.warn(this.LOG_SOURCE, `pdfPath が設定されていません. themeId: `, theme.id);
      return;
    }
    Log.debug(this.LOG_SOURCE, `theme の pdf をダウンロードして開きます. pdfPath: `, theme.pdfPath);
    theme.pdfDownloading = true;
    const pdfPath = theme.pdfPath;
    this.store.dispatch(PlaylistActions.resolvePdfObjectUrl({ pdfPath }));
    this.store
      .select(PlaylistSelectors.getSpecificResolvePdfObjectUrlResult(pdfPath))
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        theme.pdfDownloading = false;
        if (result.success) {
          this.store.dispatch(openWindow({ url: result.objectUrl }));
          this.objectUrls.push(result.objectUrl);
          this.store.dispatch(PlaylistActions.initializePdfObjectUrl({ pdfPath }));
        }
      });

    const subjectId = theme.id.slice(0, 2);
    const splitPdfPath = theme.pdfPath.split('/');
    const selectedPlaylistPdf = splitPdfPath[splitPdfPath.length - 1];
    const selectedPlaylistSetName = selectedPlaylistPdf.slice(0, 3);
    const appEvent: AppEvent = {
      type: 'select-playlist-pdf',
      value: JSON.stringify({
        subjectId,
        selectedPlaylistSetName,
        selectedPlaylistPdf
      })
    };
    setTimeout(() => this.store.dispatch(reportAppEvent({ event: appEvent })));
  }

  private setUpUser() {
    this.store
      .select(getSignedInUser)
      .pipe(
        filter<User>(it => it != null && it !== 'none'),
        take(1)
      )
      .subscribe(user => {
        this.isTrial = user.isTrial;
      });
  }

  private setUpTitle() {
    const subjects$ = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
    combineLatest([subjects$, this.playlist$.pipe(filter(it => it != null))])
      .pipe(take(1))
      .subscribe(([subjects, playlist]) => {
        let suffix = SubjectUtil.getName(subjects, playlist.subjectId);
        suffix = suffix === '' ? '' : `- ${suffix}`;
        const title = `おすすめ問題セット ${suffix}`;
        setTimeout(() => {
          this.store.dispatch(setBrowserTitle({ subTitle: title }));
          this.store.dispatch(setTitle({ title }));
        });
      });
  }

  private setUpPlaylist() {
    this.playlist$ = combineLatest([
      this.store.select(PlaylistSelectors.getPlaylists).pipe(filter(it => it != null)),
      this.activatedRoute.paramMap.pipe(map(paramMap => paramMap.get('playlistId') || ''))
    ]).pipe(
      map(([playlists, playlistId]) => {
        const selectedPlaylist = playlists.find(it => it.playlistId === playlistId);
        if (!playlistId || !selectedPlaylist) {
          this.store.dispatch(
            dispatchAppError({
              source: this.LOG_SOURCE,
              error: GeneralError.customMessage(CustomErrorMessage.PLAYLIST_DETAIL_INVALID_PLAYLIST_ID)
            })
          );
          throwError(() => GeneralError.invalidArguments(`指定された playlistId は playlists に存在しません. playlistId: ${playlistId}`));
          return null;
        }
        return selectedPlaylist;
      }),
      shareReplay(1)
    );
  }

  private setUpEnglishPlaylistThemes() {
    this.englishPlaylistThemes$ = combineLatest([
      this.store.select(PlaylistSelectors.getEnglishPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.ENGLISH))
    ]).pipe(
      switchMap(([englishProblems, playlist]) =>
        this.store.select(PlaylistSelectors.getReadableEnglishPlaylistProblems(englishProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableEnglishPlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id));
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpMathPlaylistThemes() {
    this.mathPlaylistThemes$ = combineLatest([
      this.store.select(PlaylistSelectors.getMathPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.MATH))
    ]).pipe(
      switchMap(([mathProblems, playlist]) =>
        this.store.select(PlaylistSelectors.getReadableMathPlaylistProblems(mathProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id));
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpNationalLanguagePlaylistThemes() {
    this.nationalLanguagePlaylistThemes$ = combineLatest([
      this.store.select(PlaylistSelectors.getNationalLanguagePlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.NATIONAL_LANGUAGE))
    ]).pipe(
      switchMap(([nationalLanguageProblems, playlist]) =>
        this.store.select(PlaylistSelectors.getReadableNationalLanguagePlaylistProblems(nationalLanguageProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id));
              // 国語の場合は problemId のユニーク数を problemCount とする（同一 problemId が複数存在するため）
              const ids = themeProblems.map(themeProblem => themeProblem.id);
              const problemCount = Array.from(new Set(ids)).length;
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpPhysicsPlaylistThemes() {
    this.physicsPlaylistThemes$ = combineLatest([
      this.store.select(PlaylistSelectors.getPhysicsPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.PHYSICS))
    ]).pipe(
      switchMap(([physicsProblems, playlist]) =>
        this.store.select(PlaylistSelectors.getReadablePhysicsPlaylistProblems(physicsProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id));
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpChemistryPlaylistThemes() {
    this.chemistryPlaylistThemes$ = combineLatest([
      this.store.select(PlaylistSelectors.getChemistryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.CHEMISTRY))
    ]).pipe(
      switchMap(([chemistryProblems, playlist]) =>
        this.store.select(PlaylistSelectors.getReadableChemistryPlaylistProblems(chemistryProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id));
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpBiologyPlaylistThemes() {
    this.biologyPlaylistThemes$ = combineLatest([
      this.store.select(PlaylistSelectors.getBiologyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.BIOLOGY))
    ]).pipe(
      switchMap(([biologyProblems, playlist]) =>
        this.store.select(PlaylistSelectors.getReadableBiologyPlaylistProblems(biologyProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id));
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpJapaneseHistoryPlaylistThemes() {
    this.japaneseHistoryPlaylistThemes$ = combineLatest([
      this.store.select(PlaylistSelectors.getJapaneseHistoryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.JAPANESE_HISTORY))
    ]).pipe(
      switchMap(([japaneseHistoryProblems, playlist]) =>
        this.store.select(PlaylistSelectors.getReadableJapaneseHistoryPlaylistProblems(japaneseHistoryProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableHistoryPlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id));
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpWorldHistoryPlaylistThemes() {
    this.worldHistoryPlaylistThemes$ = combineLatest([
      this.store.select(PlaylistSelectors.getWorldHistoryPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.WORLD_HISTORY))
    ]).pipe(
      switchMap(([worldHistoryProblems, playlist]) =>
        this.store.select(PlaylistSelectors.getReadableWorldHistoryPlaylistProblems(worldHistoryProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableHistoryPlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id));
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpGeographyPlaylistThemes() {
    this.geographyPlaylistThemes$ = combineLatest([
      this.store.select(PlaylistSelectors.getGeographyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.GEOGRAPHY))
    ]).pipe(
      switchMap(([geographyProblems, playlist]) =>
        this.store.select(PlaylistSelectors.getReadableGeographyPlaylistProblems(geographyProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id));
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpPoliticalEconomyPlaylistThemes() {
    this.politicalEconomyPlaylistThemes$ = combineLatest([
      this.store.select(PlaylistSelectors.getPoliticalEconomyPlaylistProblems).pipe(filter(it => it != null)),
      this.playlist$.pipe(filter(it => it != null && it.subjectId === SubjectId.POLITICAL_ECONOMY))
    ]).pipe(
      switchMap(([politicalEconomyProblems, playlist]) =>
        this.store.select(PlaylistSelectors.getReadablePoliticalEconomyPlaylistProblems(politicalEconomyProblems)).pipe(
          filter(it => it != null),
          map(readableProblems => {
            const themesForDisplay = playlist.themes.map<ThemeForDisplay<ReadableSciencePlaylistProblem>>(theme => {
              const themeProblems = this.sortPlaylistProblems(readableProblems.filter(it => it.themeId === theme.id));
              return {
                id: theme.id,
                name: theme.name,
                order: theme.order,
                problemCount: themeProblems.length,
                pdfPath: theme.pdfPath,
                pdfDownloading: false,
                problems: themeProblems
              };
            });
            const sortedThemes = this.sortTheme(themesForDisplay);
            return sortedThemes;
          })
        )
      )
    );
  }

  private setUpLoaded() {
    this.loaded$ = combineLatest([
      this.englishPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableEnglishPlaylistProblem>)),
      this.mathPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>)),
      this.nationalLanguagePlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableNationalLanguagePlaylistProblem>)),
      this.physicsPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>)),
      this.chemistryPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>)),
      this.biologyPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>)),
      this.japaneseHistoryPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableHistoryPlaylistProblem>)),
      this.worldHistoryPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableHistoryPlaylistProblem>)),
      this.geographyPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>)),
      this.politicalEconomyPlaylistThemes$.pipe(startWith(null as ThemeForDisplay<ReadableSciencePlaylistProblem>))
    ]).pipe(
      map(themes => themes.some(it => it != null)),
      shareReplay(1)
    );
  }

  private findPlaylistIfNeeded() {
    this.store
      .select(PlaylistSelectors.getPlaylists)
      .pipe(take(1))
      .subscribe(playlists => {
        if (!playlists) this.store.dispatch(PlaylistActions.findPlaylists());
      });
  }

  private findPlaylistProblems() {
    this.playlist$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(playlist => {
        if (playlist.subjectId === SubjectId.ENGLISH) {
          this.store.dispatch(PlaylistActions.findEnglishPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.MATH) {
          this.store.dispatch(PlaylistActions.findMathPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.NATIONAL_LANGUAGE) {
          this.store.dispatch(PlaylistActions.findNationalLanguagePlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.PHYSICS) {
          this.store.dispatch(PlaylistActions.findPhysicsPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.CHEMISTRY) {
          this.store.dispatch(PlaylistActions.findChemistryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.BIOLOGY) {
          this.store.dispatch(PlaylistActions.findBiologyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.JAPANESE_HISTORY) {
          this.store.dispatch(PlaylistActions.findJapaneseHistoryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.WORLD_HISTORY) {
          this.store.dispatch(PlaylistActions.findWorldHistoryPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.GEOGRAPHY) {
          this.store.dispatch(PlaylistActions.findGeographyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        if (playlist.subjectId === SubjectId.POLITICAL_ECONOMY) {
          this.store.dispatch(PlaylistActions.findPoliticalEconomyPlaylistProblems({ playlistId: playlist.playlistId }));
          return;
        }
        this.store.dispatch(
          dispatchAppError({
            source: this.LOG_SOURCE,
            error: GeneralError.unknown(`不明な科目の playlist が指定されました: ${JSON.stringify(playlist)}`)
          })
        );
      });
  }

  private sortPlaylistProblems<T extends ReadablePlaylistProblem>(problems: T[]): T[] {
    return [...problems].sort((a, b) => {
      if (a.orderInTheme < b.orderInTheme) return -1;
      if (a.orderInTheme > b.orderInTheme) return 1;
      return 0;
    });
  }

  private sortTheme<T extends ReadablePlaylistProblem>(themes: ThemeForDisplay<T>[]): ThemeForDisplay<T>[] {
    return [...themes].sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
  }
}
