import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { CreateJukuData, Juku, UpdateJukuData } from '../models/juku';
import { CallableFunction, Collection } from '../resources/app-firebase';
import { map } from 'rxjs/operators';
import { DeleteJukuResponse } from '../models/delete-juku-response';
import { DeleteJukuRequest } from '../models/delete-juku-request';

@Injectable({
  providedIn: 'root'
})
export class JukuService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  findJuku(jukuId: string): Observable<Juku> {
    return this.afs
      .collection(Collection.JUKU)
      .doc<Juku>(jukuId)
      .get()
      .pipe(map(snapshot => (snapshot.exists ? snapshot.data() : {}) as Juku));
  }

  findJukus(jukuId: string, jukuCode: string, jukuName: string, jukuSubName: string, prefectureId: string): Observable<Juku[]> {
    return this.afs
      .collection<Juku>(Collection.JUKU, ref => {
        let query: CollectionReference | Query = ref;
        if (jukuId) query = query.where('jukuId', '==', jukuId);
        if (jukuCode) query = query.where('jukuCode', '==', jukuCode);
        if (jukuName) query = query.where('jukuName', '==', jukuName);
        if (jukuSubName) query = query.where('jukuSubName', '==', jukuSubName);
        if (prefectureId) query = query.where('prefectureId', '==', prefectureId);
        return query;
      })
      .get()
      .pipe(
        map(snapshot =>
          snapshot.empty
            ? []
            : snapshot.docs.map(doc => {
                const data = doc.data();
                data.id = doc.id;
                return data as Juku;
              })
        ),
        map(juku => juku)
      );
  }

  createJuku(juku: CreateJukuData): Observable<Juku> {
    const callable = this.aff.httpsCallable<CreateJukuData, Juku>(CallableFunction.CREATE_JUKU);
    return callable(juku);
  }

  updateJuku(juku: UpdateJukuData): Observable<Juku> {
    const callable = this.aff.httpsCallable<UpdateJukuData, Juku>(CallableFunction.UPDATE_JUKU);
    return callable(juku);
  }

  deleteJuku(id: string): Observable<DeleteJukuResponse> {
    const req: DeleteJukuRequest = { id };
    const callable = this.aff.httpsCallable(CallableFunction.DELETE_JUKU);
    return callable(req);
  }
}
