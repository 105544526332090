import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Collection } from '../resources/app-firebase';
import { CurrentDateTime } from '../models/current-date-time';

@Injectable({
  providedIn: 'root'
})
export class CurrentDateTimeService {
  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private aff: AngularFireFunctions) {}

  getCurrentDateTime(): Observable<CurrentDateTime> {
    return this.afs
      .collection(Collection.CURRENT_DATE_TIME)
      .doc<CurrentDateTime>('currentDateTime')
      .get()
      .pipe(
        map(snapshot => {
          return snapshot.exists ? (snapshot.data() as CurrentDateTime) : undefined;
        })
      );
  }
}
