<!-- // #427 , #1216 [attr.data-is-responsive]="isResponsive" -->
<footer class="footer" *ngIf="view">
  <nav class="footer-nav">
    <a class="item" [href]="termsUrl" tabindex="0" target="_blank">利用規約</a>
    <a class="item" href="https://www.obunsha.co.jp/privacy_policy" target="_blank">プライバシーポリシー</a>
    <a class="item" [href]="tokusyouhouUrl" tabindex="0" target="_blank">特定商取引法</a>
    <a class="item" href="https://www.obunsha.co.jp/" target="_blank">運営会社</a>
  </nav>
  <p class="footer-copyright">株式会社 旺文社 | Copyright Obunsha Co.,Ltd. All Rights Reserved.</p>
</footer>
