import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-id-terms-content',
  templateUrl: './terms-content.component.html',
  styleUrls: ['./terms-content.component.scss']
})
export class CommonIdTermsContentComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
