import { Plan, PlanAssignment, PlanRecord } from '../models/plan';
import { Dates } from './dates';
import { CurrentDateTime } from '../models/current-date-time';
import { PlanStatuses, PlanSubjectId, SubjectId } from '../resources/config';
import { User, UserId } from '../models/user';
import { UserUtil } from './user-util';

export interface PriorityRecord {
  status: string;
  record: PlanRecord;
}

export interface UserAssignmentPlan {
  plan: Plan;
  status: string;
  assignmentSubjectIds: string[];
}

export class PlanUtil {
  static getRecordStatus(record: PlanRecord, currentDateTime: CurrentDateTime): string {
    let status: string = PlanStatuses.UNASSIGNED;
    const now: string = Dates.stdDateStringFromIso(currentDateTime.dateTime);
    const startAt: string = Dates.stdDateStringFromIso(record.startAt + ' 00:00:00');
    const endAt: string = Dates.stdDateStringFromIso(record.endAt + ' 23:59:59');

    if (endAt >= now && startAt <= now) {
      status = PlanStatuses.ACTIVE;
    } else if (endAt < now && (status === PlanStatuses.ENDED || status === PlanStatuses.UNASSIGNED)) {
      status = PlanStatuses.ENDED;
    } else if (startAt > now) {
      status = PlanStatuses.RESERVE;
    }

    return status;
  }

  static getPriorityRecord(records: PlanRecord[], currentDateTime: CurrentDateTime): PriorityRecord {
    const recordsForSort = [...records];
    let status: string = PlanStatuses.UNASSIGNED;
    let reserveRecord: PlanRecord = null;
    if (recordsForSort.length === 0) return { status, record: null };

    recordsForSort.sort((a, b) => (a.id > b.id ? 1 : -1));
    for (const record of recordsForSort) {
      status = this.getRecordStatus(record, currentDateTime);
      if (status === PlanStatuses.ACTIVE) {
        return { status, record };
      } else if (status === PlanStatuses.RESERVE) {
        reserveRecord = record;
      }
    }

    if (reserveRecord !== null) return { status: PlanStatuses.RESERVE, record: reserveRecord };

    return { status, record: recordsForSort.slice(-1)[0] };
  }

  static getAssignmentMemberCount(record: PlanRecord): number {
    let count = 0;

    for (const assign of record.assignment) {
      count = count + assign.memberIds.length;
    }

    return count;
  }

  static getAssignmentUserNames(record: PlanRecord, users: User[]): string[] {
    const userNames: string[] = [];
    for (const assign of record.assignment) {
      for (const memberId of assign.memberIds) {
        userNames.push(UserUtil.getUserName(users, memberId));
      }
    }

    return userNames;
  }

  static getUserAssignmentPlan(userId: UserId, plans: Plan[], currentDateTime: CurrentDateTime): UserAssignmentPlan | null {
    const assignPlans: Plan[] = [];
    plans.forEach(plan => {
      plan.records.forEach(record => {
        if (record.assignment.filter(assignment => assignment.memberIds.includes(userId)).length > 0) {
          assignPlans.push(plan);
        }
      });
    });

    if (assignPlans.length === 0) return null;

    let assignPlan: Plan;
    let assignmentSubjectIds: string[];
    let assignPlanStatus: string = PlanStatuses.UNASSIGNED;
    for (const plan of assignPlans) {
      const priorityRecord: PriorityRecord = this.getPriorityRecord(plan.records, currentDateTime);
      const assignments: PlanAssignment[] = priorityRecord.record.assignment.filter(assignment => assignment.memberIds.includes(userId));

      if (assignments.length < 1) {
        continue;
      }

      if (priorityRecord.status === PlanStatuses.ACTIVE) {
        assignPlan = plan;
        assignmentSubjectIds = assignments.map(assignment => assignment.subjectId);
        assignPlanStatus = PlanStatuses.ACTIVE;
        break;
      } else if (priorityRecord.status === PlanStatuses.RESERVE) {
        if (assignments.length > 0) {
          assignPlan = plan;
          assignmentSubjectIds = assignments.map(assignment => assignment.subjectId);
          assignPlanStatus = PlanStatuses.RESERVE;
        }
      } else if (assignPlanStatus !== PlanStatuses.RESERVE) {
        if (assignments.length > 0) {
          assignPlan = plan;
          assignmentSubjectIds = assignments.map(assignment => assignment.subjectId);
          assignPlanStatus = priorityRecord.status;
        }
      }
    }

    if (assignPlan === undefined || assignmentSubjectIds === undefined) {
      return null;
    }

    return {
      plan: assignPlan,
      status: assignPlanStatus,
      assignmentSubjectIds
    };
  }

  static getAssignmentUsers(record: PlanRecord, users: User[]): User[] {
    const assignmentUsers: User[] = [];
    for (const assign of record.assignment) {
      for (const memberId of assign.memberIds) {
        for (const user of users) if (user.id === memberId) assignmentUsers.push(user);
      }
    }

    return assignmentUsers;
  }

  static isAssignableUsers(
    assignPlan: Plan,
    user: User,
    plans: Plan[],
    currentDateTime: CurrentDateTime,
    assignmentSubjectId: string = null
  ): boolean {
    const priorityRecord = this.getPriorityRecord(assignPlan.records, currentDateTime);
    const now: string = Dates.stdDateStringFromIso(currentDateTime.dateTime);
    const priorityStartAt: string = Dates.stdDateStringFromIso(priorityRecord.record.startAt + ' 00:00:00');
    const priorityEndAt: string = Dates.stdDateStringFromIso(priorityRecord.record.endAt + ' 23:59:59');

    let isAssigned = false;

    for (const plan of plans) {
      // アクティブまたは予約
      const records = plan.records.filter(record => {
        const startAt: string = Dates.stdDateStringFromIso(record.startAt + ' 00:00:00');
        const endAt: string = Dates.stdDateStringFromIso(record.endAt + ' 23:59:59');
        return (endAt >= now && startAt <= now) || startAt > now;
      });

      // アサイン可能かどうかのチェック
      for (const record of records) {
        const assignments = record.assignment.filter(assignment => assignment.memberIds.includes(user.id));

        // 全教科&同一のプランの場合はプラン内でアサイン可となるようにする
        if (assignPlan.subjectId === PlanSubjectId.ALL && plan.id === assignPlan.id) {
          if (assignments.length === Object.keys(SubjectId).length) {
            isAssigned = true;
            break;
          }

          if (assignmentSubjectId !== null) {
            const sameSubjectAssignments = assignments.filter(assignment => assignment.subjectId === assignmentSubjectId);
            if (sameSubjectAssignments.length > 0) {
              isAssigned = true;
              break;
            }
          }
        } else {
          if (assignments.length > 0) {
            isAssigned = true;
            break;
          }
        }
      }

      if (isAssigned) {
        break;
      }
    }

    return !isAssigned;
  }
}
