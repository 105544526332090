export class RoutingPathResolver {
  static resolveDefault = () => '';

  static resolveTop = () => 'lp';
  static resolveTerms = () => 'terms';
  static resolveTokusyouhou = () => 'tokusyouhou';
  static resolvePrivacyPolicy = () => 'privacy-policy';
  static resolveSignIn = () => 'sign-in';
  static resolveTermsAgree = () => 'terms-agree';
  static resolveSearch = () => 'search';
  static resolveSearchResults = () => 'search-results';
  static resolveProblemDetail = (problemId: string) => `search-results/${problemId}/detail`;
  static resolveMaintenance = () => 'maintenance';
  static resolvePrint = () => 'print';
  static resolvePlaylists = () => 'playlists';
  static resolveBookmarkProblems = () => 'bookmark-problems';
  static resolveHelp = () => 'help';
  static resolveInformation = () => 'information';
  static resolveInformationDetail = (informationId: string) => `information-detail/${informationId}`;
  static resolvePlans = (organization?: string, schoolId?: string) =>
    organization && schoolId ? `admin/plans/${organization}/${schoolId}` : 'admin/plans';
  static resolveMembers = (organization?: string, schoolId?: string) =>
    organization && schoolId ? `admin/members/${organization}/${schoolId}` : 'admin/members';
  static resolveMembersImport = (organization?: string, schoolId?: string) =>
    organization && schoolId ? `admin/members/${organization}/${schoolId}/import` : 'admin/members/import';
  static resolvePlanStatuses = (planId: string) => `admin/plan-statuses/${planId}`;
  static resolvePlanAssign = (planId: string) => `admin/plan-assign/${planId}`;
  static resolvePlaylistDetail = (playlistId: string) => `playlists/${playlistId}`;
  static resolvePlaylistProblemDetail = (playlistId: string, themeId: string, problemId: string) =>
    `playlists/${playlistId}/detail/${themeId}/${problemId}`;
  static resolveCheckActivePlanError = () => 'check-active-plan-error';

  // 大学検索用
  static resolveSearchUniv = () => 'search-univ';
  static resolveSearchUnivDetail = (universityId: string) => `search-univ-detail/${universityId}`;
  static resolveSearchUnivPref = () => 'search-univ-pref';

  // admin 用
  static resolveAdmin = () => 'admin';
  static resolveSchools = () => 'admin/schools';
  static resolveJukus = () => 'admin/jukus';
  static resolveAccounts = () => 'admin/accounts';
  static resolveCommonIdAccounts = () => 'admin/c-accounts';
  static resolveCommonIdAccountSearch = () => 'admin/c-account-search';
  static resolveBulkMail = () => 'admin/bulk-mail';
  static resolveReports = () => 'admin/reports';
  static resolveTools = () => 'admin/tools';
  static resolveToolsDeleteMembers = () => 'admin/tools/delete-members';

  // BtoC 用
  static resolveCommonIdTop = () => 'c';
  static resolveCommonIdSignIn = () => 'c/sign-in';
  static resolveCommonIdSignOut = () => 'c/sign-out';
  static resolveCommonIdSignInError = () => 'c/sign-in/error';
  static resolveCommonIdSearchUniv = () => 'c/search-univ';
  static resolveCommonIdSearchUnivDetail = (universityId: string) => `c/search-univ-detail/${universityId}`;
  static resolveCommonIdSearchUnivPref = () => 'c/search-univ-pref';
  static resolveCommonIdSearchByCategories = () => 'c/search-by-categories';
  static resolveCommonIdSearchByCategoriesProblemDetail = (problemId: string) => `c/search-by-categories/${problemId}/detail`;
  static resolveCommonIdPaperDetail = (paperId: string) => `c/exam/${paperId}`;
  static resolveCommonIdPlaylists = () => 'c/playlists';
  static resolveCommonIdPlaylistDetail = (playlistId: string) => `c/playlists/${playlistId}`;
  static resolveCommonIdPlaylistProblemDetail = (playlistId: string, themeId: string, problemId: string) =>
    `c/playlists/${playlistId}/detail/${themeId}/${problemId}`;
  static resolveCommonIdMylist = () => 'c/mylist';
  static resolveCommonIdMylistUniversities = () => 'c/mylist/universities';
  static resolveCommonIdMylistProblems = () => 'c/mylist/problems';
  static resolveCommonIdMylistPlaylists = () => 'c/mylist/playlists';
  static resolveCommonIdMylistAnswerProblems = () => 'c/mylist/answer-problems';
  static resolveCommonIdPayments = () => 'c/payments';
  static resolveCommonIdPurchase = () => 'c/purchase';
  static resolveCommonIdPurchaseComplete = () => 'c/purchase/complete';
  static resolveCommonIdInformation = () => 'c/information';
  static resolveCommonIdInformationDetail = (informationId: string) => `c/information-detail/${informationId}`;
  static resolveCommonIdHelp = () => 'c/help';
  static resolveCommonIdTerms = () => 'c/terms';
  static resolveCommonIdTokusyouhou = () => 'c/tokusyouhou';
}
