import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss']
})
export class SlideToggleComponent implements OnChanges, OnInit {
  constructor() {}

  /**
   * 参考: https://material.angular.io/components/slide-toggle/api
   */
  @Input() checked = false;
  @Input() disabled = false;
  @Input() particles = false;
  @Input() problemId: string;
  @Output() slideClick = new EventEmitter<{ problemId: string; isAdd: boolean }>();

  isChecked = this.checked;
  isAnimated = false;

  ngOnInit() {}

  ngOnChanges() {
    this.isChecked = this.checked;
    this.isAnimated = this.isChecked;
  }

  onChange() {
    this.isAnimated = this.isChecked;
    this.slideClick.emit({ problemId: this.problemId, isAdd: this.isChecked });
  }
}
