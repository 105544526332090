<footer class="footer" data-has-bottom-tab-ui="true" [attr.data-footerType]="footerTypeLP">
  <nav class="footer-nav">
    <a *ngIf="!footerTypeEntrance" class="item" [href]="termsUrl" tabindex="0" target="_blank">利用規約</a>
    <a class="item" href="https://id.obunsha.co.jp/privacy_policy" target="_blank">プライバシーポリシー</a>
    <a *ngIf="!footerTypeEntrance" class="item" [href]="tokusyouhouUrl" tabindex="0" target="_blank">特定商取引法</a>
    <a class="item" href="https://www.obunsha.co.jp/" target="_blank">運営会社</a>
    <button *ngIf="!footerTypeEntrance && !footerTypeLP" class="item" (click)="gotoTop()">入試正解デジタルについて</button>
    <button *ngIf="footerTypeLP" class="item" (click)="gotoContact()">お問い合わせ</button>
  </nav>
  <p class="footer-copyright">株式会社 旺文社 | Copyright Obunsha Co.,Ltd. All Rights Reserved.</p>
</footer>
