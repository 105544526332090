import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, userFeatureKey } from '../reducers/user.reducer';

const selectUserState = createFeatureSelector<State>(userFeatureKey);

export const getUsers = createSelector(selectUserState, state => state.findUsers);
export const getUsersByEmail = createSelector(selectUserState, state => state.findUsersByEmail);
export const getWatchedUsers = createSelector(selectUserState, state => state.watchedUsers);
export const getCreateUserResult = createSelector(selectUserState, state => state.createUserResult);
export const getCreateUsersResult = createSelector(selectUserState, state => state.createUsersResult);
export const getUpdateUserResult = createSelector(selectUserState, state => state.updateUserResult);
export const getUpdateUserEmailResult = createSelector(selectUserState, state => state.updateUserEmailResult);
export const getDeleteUserResult = createSelector(selectUserState, state => state.deleteUserResult);
export const getUpdateUserIsActiveResult = createSelector(selectUserState, state => state.updateUserIsActiveResult);
export const getUpdateUserIsOrganizationAdminResult = createSelector(selectUserState, state => state.updateUserIsOrganizationAdminResult);
export const getUpdateUserIsTermsAgreeResult = createSelector(selectUserState, state => state.updateUserIsTermsAgreeResult);

export const getUsersLoaded = createSelector(getWatchedUsers, watchedUsers => watchedUsers != null);
