import { AppError } from './app-error';
import { FirebaseStorageErrorInfo, ErrorInfo } from './error-info';
import Firebase from 'firebase/compat';

const errorCodeMapping: { [key: string]: ErrorInfo } = {
  'storage/unknown': FirebaseStorageErrorInfo.UNKNOWN,
  'storage/object-not-found': FirebaseStorageErrorInfo.OBJECT_NOT_FOUND,
  'storage/bucket-not-found': FirebaseStorageErrorInfo.BUCKET_NOT_FOUND,
  'storage/project-not-found': FirebaseStorageErrorInfo.PROJECT_NOT_FOUND,
  'storage/quota-exceeded': FirebaseStorageErrorInfo.QUOTA_EXCEEDED,
  'storage/unauthenticated': FirebaseStorageErrorInfo.UNAUTHENTICATED,
  'storage/unauthorized': FirebaseStorageErrorInfo.UNAUTHORIZED,
  'storage/retry-limit-exceeded': FirebaseStorageErrorInfo.RETRY_LIMIT_EXCEEDED,
  'storage/invalid-checksum': FirebaseStorageErrorInfo.INVALID_CHECKSUM,
  'storage/canceled': FirebaseStorageErrorInfo.CANCELED,
  'storage/invalid-event-name': FirebaseStorageErrorInfo.INVALID_EVENT_NAME,
  'storage/invalid-url': FirebaseStorageErrorInfo.INVALID_URL,
  'storage/invalid-argument': FirebaseStorageErrorInfo.INVALID_ARGUMENT,
  'storage/no-default-bucket': FirebaseStorageErrorInfo.NO_DEFAULT_BUCKET,
  'storage/cannot-slice-blob': FirebaseStorageErrorInfo.CANNOT_SLICE_BLOB
};

export class FirebaseStorageError extends AppError {
  constructor(info: ErrorInfo, cause?: string | Error) {
    super(info.code, info.message, cause);
    Object.setPrototypeOf(this, FirebaseStorageError.prototype);
  }

  static from(err: Firebase.FirebaseError): FirebaseStorageError {
    const info = errorCodeMapping[err.code];
    if (info) return new FirebaseStorageError(info, err);
    return FirebaseStorageError.unknown(err);
  }

  static unknown(cause: string | Error): FirebaseStorageError {
    return new FirebaseStorageError(FirebaseStorageErrorInfo.UNKNOWN, cause);
  }
}
