import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-toggle-bookmark',
  templateUrl: './button-toggle-bookmark.component.html',
  styleUrls: ['./button-toggle-bookmark.component.scss']
})
export class ButtonToggleBookmarkComponent implements OnInit {
  constructor() {}

  @Input() isBookmarked = false;
  @Input() disabled = false;
  @Input() canSpinner = false;
  @Output() backClick = new EventEmitter();

  showSpinner = false;

  ngOnInit() {}

  toggle() {
    if (this.canSpinner) {
      this.showSpinner = true;
    }

    this.backClick.emit();
  }
}
