<article class="article-content">
  <app-wrapper size="md">
    <!-- 見出し -->
    <header>
      <ng-content select="app-article-content-head"></ng-content>
    </header>

    <!-- コンテンツ -->
    <div class="article-content-body">
      <ng-content></ng-content>
    </div>
  </app-wrapper>
</article>
