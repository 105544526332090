<div
  class="overflow-wrapper-x"
  #wrapper
  [attr.data-is-scrolled]="isScrolled"
  [attr.data-is-scrolled-end]="isScrolledEnd"
  [attr.data-overflowing]="isOverflowing"
  [attr.data-snap]="snap"
  [style.scroll-padding-left]="snapPaddingLeft"
>
  <div class="overflow-wrapper-x-inner">
    <ng-content></ng-content>
  </div>
</div>
