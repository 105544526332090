<app-dialog-basic [dialogTitle]="dialogTitle" [isLoading]="accountEditing">
  <form [formGroup]="accountForm">
    <!-- ユーザーID -->
    <div class="mb-4">
      <div class="mb-1">
        <label class="text-sm">ユーザーID</label>
      </div>
      <div class="p-4 bg-gray-30 rounded-md">{{ userId }}</div>
    </div>

    <!-- 料金プラン -->
    <div class="mb-4">
      <div class="mb-1">
        <label class="text-sm">料金プラン</label>
      </div>
      <div class="p-4 bg-gray-30 rounded-md">{{ purchasePlanName }}</div>
    </div>

    <!-- 入試正解デジタル無料会員登録日 -->
    <div class="mb-4">
      <div class="mb-1">
        <label class="text-sm">入試正解デジタル無料会員登録日</label>
      </div>
      <div class="p-4 bg-gray-30 rounded-md">{{ createdAt }}</div>
    </div>

    <!-- 購入日時 -->
    <div class="mb-4">
      <div class="mb-1">
        <label class="text-sm">購入日時</label>
      </div>
      <div class="p-4 bg-gray-30 rounded-md">{{ lastPurchasedAt }}</div>
    </div>

    <!-- 有効期限 -->
    <mat-form-field appearance="outline">
      <mat-label>有効期限</mat-label>
      <input
        matInput
        formControlName="premiumEndAt"
        [matDatepicker]="premiumEndAt"
        readonly
        (click)="premiumEndAt.open()"
        [min]="minDate"
        (dateChange)="onChangePremiumEndAt()"
      />
      <mat-datepicker-toggle matSuffix [for]="premiumEndAt"></mat-datepicker-toggle>
      <mat-datepicker #premiumEndAt></mat-datepicker>
      <mat-error *ngIf="accountForm.get('premiumEndAt').hasError('required')">入力してください</mat-error>
    </mat-form-field>
  </form>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="accountEditing"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <div class="text-danger text-sm mr-auto" @enter *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
    <button mat-stroked-button (click)="cancel()" [disabled]="accountEditing">キャンセル</button>
    <button mat-flat-button color="primary" (click)="updateAccount()" [disabled]="accountEditing">更新</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
