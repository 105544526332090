import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, eventLogFeatureKey, ResolveSearchLogFileObjectUrlResult } from '../reducers/event-log.reducer';

const selectEventLogState = createFeatureSelector<State>(eventLogFeatureKey);

export const getSearchLogFileMetadataList = createSelector(selectEventLogState, state => state.searchLogFileMetadataList);

export const getSpecificResolveSearchLogFileObjectUrlResult = (filePath: string) =>
  createSelector(selectEventLogState, (state): ResolveSearchLogFileObjectUrlResult | null => {
    if (state.resolveSearchLogFileObjectUrlResults.length === 0) return null;
    const result = state.resolveSearchLogFileObjectUrlResults.find(it => it.filePath === filePath);
    return result ? result : null;
  });
