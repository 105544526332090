import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';
import {
  UniversitySearchCondition,
  UniversitySearchType,
  PrefectureCondition,
  UniversityCondition
} from 'src/app/models/university-search-condition';

@Injectable({
  providedIn: 'root'
})
export class UniversitySearchRouterService {
  private previousUrl: string = undefined;
  private previousSearchCondition: UniversitySearchCondition<UniversityCondition>;
  private previousSearchConditionPrefecture: UniversitySearchCondition<PrefectureCondition>;
  // location.backからかどうか判断Flag
  public isFromLocationBack = false;

  constructor(private router: Router, private locationStrate: LocationStrategy) {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((event: any) => {
        this.previousUrl = event[1].urlAfterRedirects;

        if (this.previousUrl.includes('?')) {
          // 前々回のURLからクエリパラメータを取得
          const previoueQueryParamsString = this.previousUrl.split('?')[1];
          const previoueQueryParams = new URLSearchParams(previoueQueryParamsString);

          const type = previoueQueryParams.get('type');
          const universityIds = previoueQueryParams.getAll('universityIds');
          const year = previoueQueryParams.get('year');
          const departmentCategoryId = previoueQueryParams.get('departmentCategoryId');
          const prefectureIds = previoueQueryParams.getAll('prefectureIds');
          const universityTypeIds = previoueQueryParams.getAll('universityTypeIds');

          if (type === UniversitySearchType.UNIV) {
            this.previousSearchCondition = this.setPreviousSearchCondition(universityIds, year, departmentCategoryId);
          } else {
            this.previousSearchConditionPrefecture = this.setPreviousSearchConditionPrefecture(
              prefectureIds,
              year,
              departmentCategoryId,
              universityTypeIds
            );
          }
        } else {
          this.previousSearchCondition = undefined;
          this.previousSearchConditionPrefecture = undefined;
        }
      });
    this.locationStrate.onPopState(result => {
      // locationback からの時だけここにくる
      this.isFromLocationBack = true;
    });
  }

  // 前のURLから大学検索条件を取得 (大学名検索の場合)
  public getPreviousSearchCondition() {
    return this.previousSearchCondition;
  }

  // 前のURLから大学検索条件を取得 (都道府県別検索の場合)
  public getPreviousSearchConditionPrefecture() {
    return this.previousSearchConditionPrefecture;
  }

  public initializeIsFromLocationBack() {
    this.isFromLocationBack = false;
  }

  // クエリパラメータから、大学検索条件の型に変換 (大学名検索の場合)
  private setPreviousSearchCondition(
    universityIds: string[],
    year: string,
    departmentCategoryId: string
  ): UniversitySearchCondition<UniversityCondition> {
    const condition: UniversitySearchCondition<UniversityCondition> = {
      type: UniversitySearchType.UNIV,
      universityCondition: { universityIds },
      year: Number(year)
    };

    if (departmentCategoryId) condition.departmentCategoryId = Number(departmentCategoryId);

    return condition;
  }

  // クエリパラメータから、大学検索条件の型に変換 (都道府県検索の場合)
  private setPreviousSearchConditionPrefecture(
    prefectureIds: string[],
    year: string,
    departmentCategoryId: string,
    universityTypeIds: string[]
  ): UniversitySearchCondition<PrefectureCondition> {
    const universityCondition: PrefectureCondition = {
      prefectureIds
    };
    if (universityTypeIds.length !== 0) universityCondition.universityTypeIds = universityTypeIds;

    const condition: UniversitySearchCondition<PrefectureCondition> = {
      type: UniversitySearchType.PREF,
      universityCondition,
      year: Number(year)
    };

    if (departmentCategoryId) condition.departmentCategoryId = Number(departmentCategoryId);

    return condition;
  }
}
