<app-dialog-basic [dialogTitle]="getModeTitle()">
  <div *ngIf="data.mode === 'initial'">
    「入試正解デジタル for
    School」の貴団体管理ユーザーにご登録いただいたメールアドレスを入力して「送信」をクリックしてください。パスワード設定のメールをお送りいたします。
  </div>

  <div *ngIf="data.mode === 'reset'">
    ログインに使用しているメールアドレスを入力して「送信」をクリックしてください。パスワード再設定のメールをお送りいたします。
  </div>

  <div class="mt-4">
    <mat-form-field appearance="outline" class="block">
      <mat-label>Email</mat-label>
      <input matInput [formControl]="emailFormControl" />
      <mat-error *ngIf="emailFormControl.hasError('required')">入力してください</mat-error>
      <mat-error *ngIf="emailFormControl.hasError('email')">メールアドレス形式で入力してください</mat-error>
    </mat-form-field>
  </div>

  <div class="p-4 bg-gray-50 text-sm rounded-md mt-4">
    ログインでお困りの場合は、 nyushi-digi=obunsha.co.jp（=を@におきかえてください）までお問い合わせください。<br />
    ※ご返信にお時間を頂く場合がございます。あらかじめご了承ください。
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()">キャンセル</button>
    <button mat-flat-button color="primary" (click)="sendEmail()">送信</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
