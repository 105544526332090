import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { Log } from 'src/app/utils/log';
import { Diff } from 'src/app/utils/diff';
import {
  KEYBOARD_INPUT_DEBOUNCE_TIME,
  NATIONAL_LANGUAGE_HIGHEST_WORD_COUNT_STRING,
  NATIONAL_LANGUAGE_LOWEST_WORD_COUNT_STRING
} from 'src/app/resources/config';
import { NationalLanguageSearchCondition } from 'src/app/models/national-language-search-condition';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { NationalLanguageField, NationalLanguageUnit } from 'src/app/models/national-language-data';

interface FieldData extends NationalLanguageField {
  indeterminate: boolean;
  checked: boolean;
  units: UnitData[];
}

interface UnitData extends NationalLanguageUnit {
  checked: boolean;
}

const VALIDATION_INVALID_VALUE_ERROR_KEY = 'invalid-value';
const VALIDATION_INVALID_VALUE_ERROR = { 'invalid-value': true };
const VALIDATION_TOO_SMALL_VALUE_ERROR_KEY = 'too-small-value';
const VALIDATION_TOO_SMALL_VALUE_ERROR = { 'too-small-value': true };

@Component({
  selector: 'app-national-language-search-form',
  templateUrl: './national-language-search-form.component.html',
  styleUrls: ['./national-language-search-form.component.scss']
})
export class NationalLanguageSearchFormComponent implements OnInit, OnDestroy {
  @Input() staticNationalLanguageData: StaticNationalLanguageData;
  @Input() nationalLanguageSearchCondition: NationalLanguageSearchCondition | undefined;

  @Output() changeCondition = new EventEmitter<void>();
  @Output() formIsValid = new EventEmitter<boolean>();

  private LOG_SOURCE = this.constructor.name;
  private lastNotifiedCondition: NationalLanguageSearchCondition | undefined;
  private keyWordSubscription: Subscription;
  private minWordCountSubscription: Subscription;
  private maxWordCountSubscription: Subscription;

  /**
   * resetForms した際に、keyword の input が空文字で初期化された直後に、
   * change event を発火してしまうため、それを回避するために使用する flag
   */
  private needToIgnoreChangeEvent = false;

  fields: FieldData[];
  units: UnitData[];

  minWordCountFormControl = new UntypedFormControl('');
  maxWordCountFormControl = new UntypedFormControl('');
  keywordFormControl = new UntypedFormControl('');

  constructor() {}

  ngOnInit() {
    this.setUpFields();
    this.setUpUnits();
    this.setUpKeywordChange();
    this.setUpMinWordCountChange();
    this.setUpMaxWordCountChange();
  }

  ngOnDestroy() {
    if (this.keyWordSubscription) this.keyWordSubscription.unsubscribe();
    if (this.minWordCountSubscription) this.minWordCountSubscription.unsubscribe();
    if (this.maxWordCountSubscription) this.maxWordCountSubscription.unsubscribe();
  }

  // exposed methods --------------------------------------------------------------

  isValid(): boolean {
    return (
      !this.maxWordCountFormControl.hasError(VALIDATION_TOO_SMALL_VALUE_ERROR_KEY) &&
      !this.minWordCountFormControl.hasError(VALIDATION_INVALID_VALUE_ERROR_KEY) &&
      !this.maxWordCountFormControl.hasError(VALIDATION_INVALID_VALUE_ERROR_KEY)
    );
  }

  getCurrentCondition(): NationalLanguageSearchCondition {
    return this.collectCondition();
  }

  resetForms() {
    this.needToIgnoreChangeEvent = true;
    this.fields.forEach(field => (field.checked = false));
    this.units.forEach(unit => (unit.checked = false));
    this.minWordCountFormControl.setValue('');
    this.maxWordCountFormControl.setValue('');
    this.keywordFormControl.setValue('');
    this.lastNotifiedCondition = undefined;
  }

  patchValue(condition: NationalLanguageSearchCondition) {
    Log.debug(this.LOG_SOURCE, '値を復元します', condition);
    if (condition.subjectAndFieldIds && condition.subjectAndFieldIds.length !== 0) {
      this.fields.forEach(field => {
        if (condition.subjectAndFieldIds.includes(`${field.id}-${field.id}`)) field.checked = true;
      });
    }
    if (condition.categories && condition.categories.length !== 0) {
      this.units.forEach(unit => {
        if (condition.categories.includes(`${unit.parentFieldId}-${unit.parentFieldId}-${unit.id}`)) unit.checked = true;
      });
    }
    if (condition.wordCount) {
      const raw = condition.wordCount;
      this.minWordCountFormControl.setValue(raw.min != null ? raw.min.toString() : '');
      this.maxWordCountFormControl.setValue(raw.max != null ? raw.max.toString() : '');
    }
    if (condition.keywords && condition.keywords.length !== 0) {
      this.keywordFormControl.setValue(condition.keywords.join(' '));
    }

    const currentCondition = this.collectCondition();
    this.lastNotifiedCondition = { ...currentCondition };
  }

  // components internal methods ---------------------------------------------------------------

  onChangeField(field: FieldData, checked: boolean) {
    if (this.units == null) {
      return;
    }
    this.units.filter(unit => unit.parentFieldId === field.id).forEach(unit => (unit.checked = checked));

    this.emitIfNeeded();
  }

  private emitIfNeeded() {
    const currentCondition = this.collectCondition();
    Log.debug(this.LOG_SOURCE, `現在の選択状況: `, currentCondition);

    if (!Diff.isDifferentObject(this.lastNotifiedCondition, currentCondition)) {
      Log.debug(this.LOG_SOURCE, '前回 emit したデータと同じため無視します');
      return;
    }

    if (this.needToIgnoreChangeEvent) {
      Log.debug(this.LOG_SOURCE, 'notify 不要な event のため無視します');
      this.needToIgnoreChangeEvent = false;
      return;
    }

    Log.debug(this.LOG_SOURCE, '前回と異なるデータのため emit します');
    this.lastNotifiedCondition = { ...currentCondition };
    this.changeCondition.emit();
  }

  onChangeValues() {
    this.emitIfNeeded();
  }

  onChangeMaxWordCount() {
    const minWordCount: string = this.minWordCountFormControl.value;
    const maxWordCount: string = this.maxWordCountFormControl.value;

    if (maxWordCount !== '' && isNaN(parseInt(maxWordCount, 10))) {
      Log.warn(this.LOG_SOURCE, '素材文の文字数の max の値が数字ではありません');
      this.maxWordCountFormControl.setErrors(VALIDATION_INVALID_VALUE_ERROR);
      this.formIsValid.emit(false);
      return;
    }

    if (minWordCount !== '' && maxWordCount !== '' && parseInt(minWordCount, 10) > parseInt(maxWordCount, 10)) {
      Log.warn(this.LOG_SOURCE, '素材文の文字数の max の値が min より小さいです');
      this.maxWordCountFormControl.setErrors(VALIDATION_TOO_SMALL_VALUE_ERROR);
      this.formIsValid.emit(false);
      return;
    }

    this.formIsValid.emit(true);

    this.emitIfNeeded();
  }

  onChangeMinWordCount() {
    const minWordCount: string = this.minWordCountFormControl.value;
    const maxWordCount: string = this.maxWordCountFormControl.value;

    if (minWordCount !== '' && isNaN(parseInt(minWordCount, 10))) {
      Log.warn(this.LOG_SOURCE, '素材文の文字数の min の値が数字ではありません');
      this.minWordCountFormControl.setErrors(VALIDATION_INVALID_VALUE_ERROR);
      this.formIsValid.emit(false);
      return;
    }

    if (minWordCount !== '' && maxWordCount !== '' && parseInt(minWordCount, 10) > parseInt(maxWordCount, 10)) {
      Log.warn(this.LOG_SOURCE, '素材文の文字数の max の値が min より小さいです');
      this.maxWordCountFormControl.setErrors(VALIDATION_TOO_SMALL_VALUE_ERROR);
      this.formIsValid.emit(false);
      return;
    }

    this.formIsValid.emit(true);

    this.emitIfNeeded();
  }

  // set ups ---------------------------------------------------------------

  private setUpFields() {
    const fields = this.staticNationalLanguageData.fields;
    const data: FieldData[] = fields.map(field => {
      const units = this.staticNationalLanguageData.units.filter(it => it.parentFieldId === field.id);
      const fieldData: FieldData = {
        ...field,
        checked: false,
        indeterminate: false,
        units: units.map<UnitData>(unit => ({ ...unit, checked: false, parentSubjectId: field.id }))
      };
      return fieldData;
    });
    this.fields = data;
  }

  private setUpUnits() {
    const data = this.sortNumberIdentifiedData([...this.staticNationalLanguageData.units]).map<UnitData>(unit => ({
      ...unit,
      checked: false
    }));
    this.units = data;
  }

  /** 一定時間入力値が変化しなければ onChangeValues() をコールします */
  private setUpKeywordChange() {
    this.keyWordSubscription = this.keywordFormControl.valueChanges
      .pipe(
        debounceTime(KEYBOARD_INPUT_DEBOUNCE_TIME),
        tap(keywords => Log.debug(this.LOG_SOURCE, `after debounce keyword: ${keywords}`))
      )
      .subscribe(() => this.onChangeValues());
  }

  /** 一定時間入力値が変化しなければ onChangeValues() をコールします */
  private setUpMinWordCountChange() {
    this.minWordCountSubscription = this.minWordCountFormControl.valueChanges
      .pipe(
        debounceTime(KEYBOARD_INPUT_DEBOUNCE_TIME),
        tap(count => Log.debug(this.LOG_SOURCE, `after debounce min word count: ${count}`))
      )
      .subscribe(() => this.onChangeMinWordCount());
  }

  /** 一定時間入力値が変化しなければ onChangeValues() をコールします */
  private setUpMaxWordCountChange() {
    this.maxWordCountSubscription = this.maxWordCountFormControl.valueChanges
      .pipe(
        debounceTime(KEYBOARD_INPUT_DEBOUNCE_TIME),
        tap(count => Log.debug(this.LOG_SOURCE, `after debounce max word count: ${count}`))
      )
      .subscribe(() => this.onChangeMaxWordCount());
  }

  // utils ---------------------------------------------------------------

  private sortNumberIdentifiedData<T extends { id?: string }>(array: T[]): T[] {
    return array.sort((a, b) => {
      if (Number(a.id) < Number(b.id)) return -1;
      if (Number(a.id) > Number(b.id)) return 1;
      return 0;
    });
  }

  private collectCondition(): NationalLanguageSearchCondition {
    const condition: NationalLanguageSearchCondition = {};

    const selectedFieldIds = this.fields.filter(it => it.checked).map(it => it.id);
    const selectedUnits = this.units.filter(it => it.checked);
    if (selectedFieldIds.length !== 0) condition.subjectAndFieldIds = selectedFieldIds.map(it => `${it}-${it}`);
    if (selectedUnits.length !== 0) condition.categories = selectedUnits.map(it => `${it.parentFieldId}-${it.parentFieldId}-${it.id}`);

    const minWordCount = this.minWordCountFormControl.value
      ? this.minWordCountFormControl.value
      : NATIONAL_LANGUAGE_LOWEST_WORD_COUNT_STRING;
    const maxWordCount = this.maxWordCountFormControl.value
      ? this.maxWordCountFormControl.value
      : NATIONAL_LANGUAGE_HIGHEST_WORD_COUNT_STRING;
    if (this.minWordCountFormControl.value !== '' || this.maxWordCountFormControl.value !== '') {
      const min = parseInt(minWordCount, 10);
      const max = parseInt(maxWordCount, 10);
      condition.wordCount = { min, max };
    }

    if (this.keywordFormControl.value !== '') {
      const rawString: string = this.keywordFormControl.value;
      const keywords = rawString.split(/[ 　]+/).filter(it => it !== '');
      if (keywords.length !== 0) condition.keywords = [...keywords];
    }

    return condition;
  }
}
