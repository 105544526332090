<div class="loading-dialog">
  <app-dialog-basic dialogTitle="プラン選択中" [isLoading]="disabled">
    <div>
      <div class="progress">
        <mat-spinner [diameter]="48" @enter></mat-spinner>
      </div>
      <div class="pt-8 px-4 text-center">
        <div>少々お待ち下さい...</div>
      </div>
    </div>
  </app-dialog-basic>
</div>
