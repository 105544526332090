import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
  MatLegacySnackBarRef as MatSnackBarRef
} from '@angular/material/legacy-snack-bar';

import { AppError } from 'src/app/errors/app-error';

@Component({
  selector: 'app-error-snack-bar',
  templateUrl: './error-snack-bar.component.html',
  styleUrls: ['./error-snack-bar.component.scss']
})
export class ErrorSnackBarComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public appError: AppError, private snackBarRef: MatSnackBarRef<ErrorSnackBarComponent>) {}

  ngOnInit() {}

  dismiss() {
    this.snackBarRef.dismiss();
  }
}
