<div class="terms-content">
  <app-document-theme-default>
    <div class="text-md">
      <p>
        この規約（以下「本規約」といいます。）は、株式会社旺文社（以下「当社」といいます。）が提供する大学入試過去問題の検索・閲覧Webサービス「入試正解デジタル
        for
        School」（以下「本サービス」といいます。）の利用に関する条件を定めるものであり、本サービスを利用するすべてのユーザー（以下「ユーザー」といいます。）に適用されます。ユーザーは、本サービスを利用する前に、本規約をよくお読みください。
      </p>
      <section>
        <h2>第 1 条 （定義）</h2>
        <p>本規約において、次の各号に掲げる用語の意義は、当該各号に定めるところによるものとします。</p>
        <p>
          （1）「コンテンツ」<br />
          ユーザーが本サービスを通じてアクセスすることができる情報（文章、画像、動画、音声、音楽その他のサウンド、イメージ、ソフトウェア、プログラム、コードその他のデータを含みますが、これらに限られません。）をいいます。<br />
          （2）「学校等」<br />
          学校、塾、専門学校または英会話学校等で、本サービスの利用権を購入する者をいいます。<br />
          （3）「ユーザー」<br />
          本サービスの利用権を購入した学校等または学校等に所属・在籍する教員、講師で、当社から本サービスの利用権を付与されたユーザーをいいます。<br />
          （4）「団体管理ユーザー」<br />
          ユーザーのうち、本サービスの利用権を購入した学校等において、当該学校等の一般ユーザーを本サービスに登録する管理者をいいます。<br />
          （5）「一般ユーザー」<br />
          ユーザーのうち、当社または団体管理ユーザーから本サービスの利用登録を受け、本サービスを利用するユーザーをいいます。<br />
          （6）「提供コンテンツ」<br />
          本条第1号のコンテンツのうち、本サービスにおいて当社がユーザーに対し提供するコンテンツその他全ての情報をいいます。<br />
          （7）「出力コンテンツ」<br />
          本条第1号のコンテンツのうち、ユーザーが本サービスを利用した上で、本サービスの機能に基づいて出力した提供コンテンツ（データによる出力、プリントアウト等、媒体を問わないものします。）をいいます。<br />
          （8）「アカウント」<br />
          ユーザーが、本サービスへの登録を申請し、当社または団体管理ユーザーから登録された場合における本サービスの使用権をいいます。
        </p>
      </section>
      <section>
        <h2>第 2 条 （登録）</h2>
        <p>
          1
          本サービスの利用を希望する学校等（以下「登録希望者」といいます。）は、当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。<br />
          2
          当社は、当社の基準に従って、前項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合には団体管理ユーザーを登録の上、登録申請者に通知します。登録申請者のユーザーとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。なお、団体管理ユーザーは、当社の設定したユーザー数を超えない範囲で一般ユーザーの登録を行うことができます。<br />
          3 登録申請者は、当社が事前に提示した販売価格の支払いをもって本サービスの利用権を購入するものとします。<br />
          4
          本条第2項に定める登録の完了時に、サービス利用契約が学校等と当社の間に成立し、当該学校等に所属するユーザーは本サービスを本規約に従い利用することができるようになります。<br />
          5 本サービスに関する当社からユーザーへの連絡は、当社が適当と判断する方法により行うものとします。<br />
          6 ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を速やかに当社に通知するものとします。
        </p>
      </section>
      <section>
        <h2>第 3 条 （パスワードおよびログイン用メールアドレスの管理）</h2>
        <p>
          1
          ユーザーは、自己の責任において、本サービスに関するパスワードおよびログイン用メールアドレスを適切に管理および保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。<br />
          2
          パスワードまたはログイン用メールアドレスの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はユーザーが負うものとし、当社は一切の責任を負いません。<br />
          3
          ユーザーは、パスワードまたはログイン用メールアドレスを忘れた場合、またはアカウントが第三者に使用されていることが判明した場合には、直ちに当社所定の連絡先に連絡の上、当社の指示に従うものとします。<br />
          4
          ユーザーは、パスワードおよびログイン用メールアドレスを自己の責任において管理し、当社は、ユーザーのアカウントにより本サービスが利用された場合、当該アカウントを保有するユーザーが本サービスを利用したものとみなします。
        </p>
      </section>
      <section>
        <h2>第 4 条（本規約の改定・変更）</h2>
        <p>
          1
          当社は、当社の判断において、いつでも本規約の内容を変更または追加できるものとします。変更後の利用規約は、当社が別途定める場合を除いて、本サービス上または当社の運営するウェブサイトに掲示します。<br />
          2
          ユーザーは、変更後の本規約に同意しない場合には、本サービスをご利用いただくことができません。ユーザーが本規約の変更後も本サービスの利用を継続する場合、当該ユーザーは、変更後の本規約に同意したものとみなされます。なお、当社は、本規約の変更または追加につき、各ユーザーに個別に通知することはいたしません。ユーザーは、ユーザーの責任において、随時、本規約の最新の内容を確認の上、本サービスをご利用ください。<br />
          3 本規約外における本サービスのサービス内容等の記載が、本規約の内容と異なる場合は、本規約が優先して適用されるものとします。
        </p>
      </section>
      <section>
        <h2>第 5 条（本サービスの利用）</h2>
        <p>
          1
          ユーザーは、当社の定める方法に従い、ユーザーの所属する学校等において、当該学校等における教育・学習目的として使用する範囲に限り、本サービスを利用できるものとします。<br />
          2
          本サービスを利用するために必要な情報端末、ソフトウェア、通信回線その他の通信環境等の準備および維持は、ユーザーの費用と責任において行うものとします。<br />
          3
          当社は、ユーザーに対し提供コンテンツ毎に利用可能な期間等の利用条件を定めるものとします。ユーザーは、当該利用条件の範囲内においてのみ、本サービスにおいて提供コンテンツを利用することができます。
        </p>
      </section>
      <section>
        <h2>第 6 条（出力コンテンツの利用）</h2>
        <p>
          ユーザーは、本サービスの機能を利用して作成した出力コンテンツについて、ユーザーが所属する学校等における授業・講義またはユーザー自らの研究活動のために、生徒もしくは受講者に印刷し配布する方法またはユーザー自らが使用する方法に限り利用することができます。ユーザーは、出力コンテンツの単独による販売を行うことなどで、独自の対価を得ることはできません。
        </p>
      </section>
      <section>
        <h2>第 7 条 （ユーザーに関する情報の取扱い）</h2>
        <p>
          1
          当社は、登録事項、ユーザー情報（登録情報以外の電話番号・問い合わせ内容等の情報をいいます）、端末情報、ユーザーが操作したアクセスログ等その他本サービスの利用に関しユーザーから収集する情報を、本サービスの運営、開発、改良、宣伝広告および販売促進、サービス向上ならびに新商品の開発等を目的として、個人を特定できない統計情報等として利用、公開等を行うことができます。<br />
          2
          当社は、登録事項、ユーザー情報、端末情報、ユーザーが操作したアクセスログ等その他本サービスの利用に関しユーザーから収集する情報のうち、個人情報については、本サービスの運営、開発および改良、本サービスに関する通知および対応等の目的のために利用
          し、別途当社が定めるプライバシーポリシー（ https://www.obunsha.co.jp/privacy_policy/
          ）に従い、適切に取り扱います。また、本項に定める目的に必要な限りにおいて、個人情報の取扱いを外部委託先へ委託する場合があります。
        </p>
      </section>
      <section>
        <h2>第 8 条 （権利の帰属）</h2>
        <p>
          本サービス、本サービス内の提供コンテンツおよび出力コンテンツに関する一切の知的財産権は、当社または当社にライセンスを許諾している者に帰属します。
        </p>
      </section>
      <section>
        <h2>第 9 条 （禁止行為）</h2>
        <p>
          1
          ユーザーは、本サービスの利用にあたり、自らまたは第三者をして以下の各号のいずれかに該当する行為または該当するおそれのある行為をしてはなりません。<br />
          （1）法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為またはこれらを助長する行為<br />
          （2）犯罪に結びつくまたはそのおそれのある行為（予告、関与、誘引、勧誘または助長などを含みます。）<br />
          （3）当社または他のユーザーその他の第三者に対する詐欺または脅迫行為<br />
          （4）公序良俗に反しまたは善良な風俗を害する行為<br />
          （5）当社または他のユーザーその他の第三者の知的財産権、肖像権、プライバシー、名誉、その他の権利または利益を侵害する行為<br />
          （6）
          本サービス上のコンテンツを、当社の承諾なく本規約または別途当社が定める範囲を超えて複製、公衆送信または送信可能化等をする行為<br />
          （7）本サービスの誤作動を誘引する行為または誤作動もしくは不具合を利用する行為<br />
          （8）本サービスに関連して当社が秘密である旨指定した情報を、当社の承諾なく開示する行為<br />
          （9）本サービス上での勧誘、宣伝または広告に関する行為<br />
          （10）本サービスの内外を問わず、本サービス上のアカウントまたはコンテンツを第三者に有償で貸与、譲渡、売買等をする行為（いわゆるリアルマネートレード行為）およびその申込みの誘引、申込み、承諾を含む一切の準備行為<br />
          （11）以下に該当しまたは該当すると当社が判断した情報を本サービス上に送信・投稿する行為<br />
          ア 差別を助長する表現を含む情報<br />
          イ 異性、同性を問わず、面識のない第三者との出会いまたはわいせつな行為等を目的とする情報<br />
          ウ スパムメール等に該当する情報またはコンピュータウィルス等の有害なプログラムを流布させる情報<br />
          エ その他当社が不適切と判断する情報<br />
          オ ユーザー本人または第三者の個人が特定できる情報<br />
          （12）本サービスの内外を問わず、本サービスに関連するイベントの開催またはグッズの販売等、本サービスが意図しない方法で利益を得ることを目的とする行為<br />
          （13）本サービスを逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本サービスのソースコードを解析する行為<br />
          （14）本サービスに接続しているシステムに権限なく不正にアクセスしまたは当社設備に蓄積された情報を不正に書き換えもしくは消去するもしくは本サービスまたは当社サーバー等に過度の負担をかける行為<br />
          （15）故意に虚偽の情報、データ等を当社へ送信、公開または投稿する行為<br />
          （16）当社または他のユーザーの情報の収集を目的とする行為<br />
          （17）当社または他のユーザーその他の第三者に成りすます行為<br />
          （18）他のユーザーのアカウントを利用、または自己のアカウントその他の本サービスに関する権利および義務を貸与もしくは譲渡等する行為<br />
          （19）その他、本規約および本サービスの趣旨・目的に反する等当社が不適切と判断する行為<br />
          2
          前項に定める禁止行為を行ったことによりユーザーに生じた損害の責任またはユーザーが他のユーザーもしくは第三者に生じさせた損害は、ユーザーが負うものとし、当社は一切の責任を負いません。
        </p>
      </section>
      <section>
        <h2>第 10 条 （規約違反に対する措置）</h2>
        <p>
          1
          当社は、ユーザーが以下各号に該当しまたは該当するおそれがあると当社が判断した場合には、当社の裁量により、何らの通知も行うことなく、当該ユーザーに対し、出力コンテンツその他の情報の全部もしくは一部の削除、提供コンテンツの利用の停止、本サービスの利用の一時停止もしくは制限、アカウントの削除を含む本利用契約の解除等の措置（以下「利用停止等」といいます。）を講じることができるものとします。<br />
          （1）本規約のいずれかの条項に違反した場合<br />
          （2）ユーザーの所属する学校等による本サービス利用料金の支払い遅延が生じた場合<br />
          （3）反社会的勢力等であるか、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等、反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合<br />
          （4）本サービスの運営、保守管理上必要であると当社が判断した場合<br />
          （5）その他前各号に類する事由があると当社が判断した場合<br />
          2 前項に定める利用禁止措置を行ったことによりユーザーに損害が生じても、当社は当該ユーザーの損害に対して、いかなる責任も負いません。
        </p>
      </section>
      <section>
        <h2>第 11 条 （損害賠償）</h2>
        <p>
          1
          ユーザーによる本規約違反行為その他故意または過失により、直接的または間接的に、当社に損害が生じた場合、ユーザーは、当社に対し、その全ての損害（弁護士等専門家費用および当社において対応に要した人件費相当額を含みます。）を賠償するものとします。<br />
          2
          本規約に定める当社の免責規定にもかかわらず消費者契約法その他の法令等によって、本サービスの利用に関連してユーザーが被った損害につき、当社が損害賠償責任を負う場合、当社の軽度の過失による債務不履行責任または不法行為責任については、当社は、ユーザーに現実に発生した直接かつ通常の損害に限り、かつ、5,000円を上限として、これを賠償する責任を負うものとします。
        </p>
      </section>
      <section>
        <h2>第 12 条 （本サービスの利用終了）</h2>
        <p>
          1 ユーザーは、学校等と当社の間で成立したサービス利用契約が終了した時点で、本サービスの利用を終了することになります。<br />
          2 ユーザーは、本サービスの利用契約を有効期間の途中で解約することはできません。<br />
          3
          ユーザーは、いかなる理由であっても、ユーザーが本サービスの利用を終了または利用する権利を失った場合、当該アカウントにおける提供コンテンツ、出力コンテンツその他本サービスに蓄積した情報を利用することができなくなること、および保有する出力コンテンツを削除することを、あらかじめ承諾するものとします。<br />
          4
          ユーザーは、本サービス利用終了後も、当社および第三者に対する本利用契約上の一切の義務および債務（損害賠償を含みますが、これに限りません。）を免れるものではありません。<br />
          5 当社は、ユーザーが本サービスの利用を終了した後も、当該ユーザーが当社に提供した情報を保有・利用することができるものとします。<br />
          6
          当社は、当社の裁量により、あらかじめ通知することなく、最終のアクセスから1年以上経過している、または本サービスの利用を終了したユーザーのユーザー情報を削除することができるものとします。<br />
          7
          ユーザーが本サービスの利用を終了した後、ユーザーが登録事項の削除を希望する場合、当社所定の方法によりユーザー自ら削除の申出を当社に対して行うものとします。
        </p>
      </section>
      <section>
        <h2>第 13 条 （保証の否認および免責）</h2>
        <p>
          1
          当社は、本サービスに関する動作保証、端末OS等のバージョンアップへの対応またはその他安全な本サービスの提供について、如何なる保証も行うものではありません。<br />
          2
          当社は、ユーザーに事前に通知することなく、本サービスの内容の全部もしくは一部を変更、追加または廃止することができ、または本サービスの提供の全部または一部を中断、中止、または終了することができます。当社は、本項の措置を行ったことにより、ユーザーに生じた損害に対して一切の責任を負わないものとします。<br />
          3
          本サービスに関し、ユーザー間またはユーザーと第三者との間で紛争が生じた場合、ユーザーは、当社にこれを通知の上、自己の責任と費用において解決するものとします。当社は、当該紛争に関与する義務を負わず、何ら責任を負わないものとします。<br />
          4 当社は、ユーザーによる本サービスの利用に関連してユーザーに生じた損害につき、一切の責任を負わないものとします。<br />
          5 当社は、本サービスの利用にあたり支払った料金の返金の要請には、一切応じないものとします。
        </p>
      </section>
      <section>
        <h2>第 14 条 （外部サービスの利用）</h2>
        <p>
          1
          ユーザーは、本サービスの利用にあたり、外部サービス等を利用する場合には、本規約のほか、当該外部サービスの定める利用規約その他の条件（当該利用規約等が変更された場合には変更後の条件を含みます。）に従うものとします。<br />
          2
          外部サービスについては、当該外部サービスを提供する企業または個人が責任を負うものとし、当社は、当該外部サービスにつき、一切の動作保証、正確性、有用性、不具合等が存しないこと、第三者の権利を侵害しないこと、端末OS等のバージョンアップへの対応またはその他安全な本サービスの提供について、如何なる保証も行うものではありません。
        </p>
      </section>
      <section>
        <h2>第 15 条 （事業譲渡等の場合の取扱い）</h2>
        <p>
          当社が、本サービスもしくは本サービスにかかる事業を第三者に譲渡し、または合併もしくは会社分割等により本サービスにかかる事業を承継させたときは、当社は、当該譲渡等に伴い、本利用契約上の地位、権利および義務ならびに登録情報その他のユーザー情報を当該譲渡等の譲受人等に承継させることができるものとします。ユーザーは、かかる譲渡等につき本条において予めこれに同意したものとみなします。
        </p>
      </section>
      <section>
        <h2>第 16 条 （分離可能性）</h2>
        <p>
          本規約のいずれかの条項または条項の一部が、消費者契約法その他の法令等によって無効と判断とされた場合であっても、他の条項または無効と判断された条項の残部は、引き続き有効に存続します。
        </p>
      </section>
      <section>
        <h2>第 17条 （準拠法および合意管轄）</h2>
        <p>
          1 本規約の正文は日本語版とし、準拠法は日本法とします。<br />
          2 本サービスまたは本規約に起因しもしくは関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
      </section>

      <p>
        <strong>【附則】</strong><br />
        ・本規約は、2021年 11 月 15 日から施行します。
      </p>

      <p class="text-right text-xs">
        株式会社　旺文社<br />
        「入試正解デジタル for School」公式サイト<br />
        Copyright Obunsha Co.,Ltd. All Rights Reserved.
      </p>
    </div>
  </app-document-theme-default>
</div>
