import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-button-toggle-univ-bookmark',
  templateUrl: './button-toggle-univ-bookmark.component.html',
  styleUrls: ['./button-toggle-univ-bookmark.component.scss']
})
export class ButtonToggleUnivBookmarkComponent implements OnInit, OnChanges {
  constructor() {}

  @Input() isBookmarked = false;
  @Input() disabled = false;
  @Input() universityId: string;
  @Input() bookmarkClickEmitted: boolean;
  @Input() errorUnivId: string;
  @Output() backClick = new EventEmitter<string>();

  showSpinner = false;

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.bookmarkClickEmitted !== undefined) {
      this.disabled = this.bookmarkClickEmitted;
    }

    // 登録の際にエラーが発生した場合は、ボタンの色を変更せず、スピナーも非表示にする
    if (this.universityId === this.errorUnivId) {
      this.isBookmarked = false;
      this.showSpinner = false;
    }
  }

  toggle(flag: boolean) {
    this.isBookmarked = flag;
    this.backClick.emit(this.universityId);
    this.showSpinner = true;
  }
}
