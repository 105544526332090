import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';

import { RootState } from '../../reducers';
import { getCommonIdSignedInUser } from '../../selectors/common-id/common-id-auth.selectors';
import { findCommonIdSignedInUser } from '../../actions/common-id/common-id-auth.actions';
import { Log } from '../../utils/log';
import { RoutingPathResolver } from '../../app-routing-path-resolver';
import { navigate } from '../../actions/core.actions';

@Injectable({
  providedIn: 'root'
})
export class CommonIdUnauthGuard implements CanActivate {
  constructor(private store: Store<RootState>) {}

  canActivate(): Observable<boolean> {
    this.findSignedInUserIfNeeded();

    return this.store.select(getCommonIdSignedInUser).pipe(
      filter(it => it != null),
      take(1),
      map(user => {
        const unauthenticated = user === 'none';

        if (!unauthenticated) {
          Log.debug(this.constructor.name, '認証済みのため search-univ path にリダイレクトします');
          const url = RoutingPathResolver.resolveCommonIdSearchUniv();
          this.store.dispatch(navigate({ url, extras: { replaceUrl: true } }));
        }

        return unauthenticated;
      })
    );
  }

  private findSignedInUserIfNeeded() {
    this.store
      .select(getCommonIdSignedInUser)
      .pipe(take(1))
      .subscribe(user => {
        if (user == null) this.store.dispatch(findCommonIdSignedInUser());
      });
  }
}
