import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State, jukuFeatureKey } from '../reducers/juku.reducer';

const selectJukuState = createFeatureSelector<State>(jukuFeatureKey);

export const getJuku = createSelector(selectJukuState, state => state.juku);
export const getJukus = createSelector(selectJukuState, state => state.jukus);
export const getCreateJukuResult = createSelector(selectJukuState, state => state.createJukuResult);
export const getUpdateJukuResult = createSelector(selectJukuState, state => state.updateJukuResult);
export const getDeleteJukuResult = createSelector(selectJukuState, state => state.deleteJukuResult);
