<app-dialog-basic [dialogTitle]="dialogTitle">
  <div class="flex flex-col gap-6">
    <!-- 思考力問題 -->
    <div>
      <div class="mat-caption label">思考力問題</div>
      <mat-checkbox color="primary" [(ngModel)]="isThinking" (change)="onChangeValues()">思考力問題のみ</mat-checkbox>
    </div>

    <!-- 入試年度 -->
    <div class="years-container">
      <mat-form-field class="year-form-field" appearance="outline">
        <mat-label>開始年度</mat-label>
        <mat-select (selectionChange)="onStartYearChangeValues()" [formControl]="startYearFormControl">
          <mat-option *ngFor="let year of selectableYears" [value]="year.value">{{ year.label }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="tilde">〜</div>

      <mat-form-field class="year-form-field" appearance="outline">
        <mat-label>終了年度</mat-label>
        <mat-select (selectionChange)="onEndYearChangeValues()" [formControl]="endYearFormControl">
          <mat-option *ngFor="let year of selectableYears" [value]="year.value">{{ year.label }}</mat-option>
        </mat-select>
        <mat-error *ngIf="endYearFormControl.hasError('min')">開始年度以上の値を設定してください</mat-error>
      </mat-form-field>
    </div>

    <!-- 大学種別 -->
    <div>
      <div class="mat-caption label">大学種別</div>
      <div class="checkbox-list">
        <div *ngFor="let universityType of universityTypes">
          <mat-checkbox color="primary" [(ngModel)]="universityType.checked" (change)="onChangeValues()">{{
            universityType.name
          }}</mat-checkbox>
        </div>
      </div>
    </div>

    <!-- エリア -->
    <div>
      <div class="mat-caption label">エリア</div>
      <div class="checkbox-list">
        <div *ngFor="let area of areas">
          <mat-checkbox color="primary" [(ngModel)]="area.checked" (change)="onChangeValues()">{{ area.name }}</mat-checkbox>
        </div>
      </div>
    </div>

    <!-- 大学名 -->
    <div>
      <app-univ-chip-list
        [matLabel]="univChipListLabelName"
        [floatLabel]="univChipListFloatLabel"
        [appearance]="univChipListAppearance"
        [matFormFieldClass]="univChipListMatFormFiledClass"
        (changeSelectedUniversities)="changeSelectedUniversities($event)"
      >
      </app-univ-chip-list>
      <div class="leading-3">
        <p><small class="text-gray-500 text-xs">※ 大学名 ―大学入学共通テスト（旧大学入試センター試験）を含む</small></p>
        <p><small class="text-gray-500 text-xs">※ 複数入力可</small></p>
      </div>
    </div>

    <!-- 学部系統 -->
    <div>
      <mat-form-field appearance="outline">
        <mat-label>学部系統</mat-label>
        <mat-select [(value)]="selectedDepartmentCategory" (selectionChange)="onChangeValues()">
          <mat-option *ngFor="let category of selectableDepartmentCategories" [value]="category">{{ category.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <app-dialog-basic-footer>
    <div class="flex justify-start items-center text-lg md:text-xl">
      <div class="font-bold">検索結果</div>
      <div class="text-primary font-bold ml-2">
        {{ (matchedProblemCount$ | async) == null ? '-' : (matchedProblemCount$ | async) }}
      </div>
      <div class="ml-2 mr-2 text-md relative">件</div>
      <div [@inOut]="problemCountSearching$ | async">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>
    </div>
    <span class="spacer"></span>
    <button mat-stroked-button (click)="reset()">
      <mat-icon class="text-gray-300 -mt-[1px]">delete</mat-icon>
      リセット
    </button>
    <button mat-flat-button color="primary" (click)="close()">閉じる</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
