import { createAction, props } from '@ngrx/store';
import { AppError } from '../../errors/app-error';
import { CommonIdFindUniversityRequest } from '../../models/common-id/common-id-find-university-request';
import { CommonIdFindUniversityResponse } from '../../models/common-id/common-id-find-university-response';

// 各大学の大学情報と年度情報を取得する -------------------------------------------------------------------
export const findUniversity = createAction(
  '[Common ID Find University] Find University',
  props<{ request: CommonIdFindUniversityRequest }>()
);
export const findUniversitySuccess = createAction(
  '[Common ID Find University] Find University Success',
  props<{ response: CommonIdFindUniversityResponse }>()
);
export const findUniversityFailure = createAction('[Common ID Find University] Find University Failure', props<{ error: AppError }>());

// 各大学の大学情報と年度情報をリセットする場合 -------------------------------------------------------------------------------------
export const initializeFindUniversityState = createAction('[Common ID Find University] Initialize Find University State');
