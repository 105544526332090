import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, shareReplay, map, startWith, take } from 'rxjs/operators';

import { SubjectId } from 'src/app/resources/config';
import { RootState } from 'src/app/reducers';
import { setTitle, navigate, setBrowserTitle } from 'src/app/actions/core.actions';
import { getPlaylists } from 'src/app/selectors/playlist.selectors';
import { findPlaylists } from 'src/app/actions/playlist.actions';
import { Playlist } from 'src/app/models/playlist';
import { enter } from 'src/app/resources/animations';
import { DataUtil } from 'src/app/utils/data-util';
import { Log } from 'src/app/utils/log';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';
import { SubjectUtil } from '../../../utils/subject-util';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { User } from 'src/app/models/user';

interface PlaylistSubject {
  subjectName: string;
  playlists: Playlist[];
}

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss'],
  animations: [enter]
})
export class PlaylistsComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private title = 'おすすめ問題セット';

  playlistSubjects$: Observable<PlaylistSubject[]>;
  loaded$: Observable<boolean>;

  signedInUser$: Observable<User>;

  constructor(protected store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUpPlaylistSubjects();
    this.loaded$ = this.playlistSubjects$.pipe(
      map(it => it != null),
      startWith(false)
    );
  }

  ngOnDestroy() {}

  showPlaylistDetail(playlist: Playlist) {
    if (playlist.pending) return;

    Log.debug(this.LOG_SOURCE, 'playlist詳細を開きます: ', playlist);
    const url = RoutingPathResolver.resolvePlaylistDetail(playlist.playlistId);
    this.store.dispatch(navigate({ url }));
  }

  private setUpPlaylistSubjects() {
    this.store.dispatch(findPlaylists());

    const playlists$ = this.store.select(getPlaylists).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    const subjects$ = this.store.select(StaticDataSelectors.getSubject).pipe(
      filter(it => it != null),
      shareReplay(1)
    );

    combineLatest([subjects$, this.store.select(getSignedInUser).pipe(filter<User>(it => it != null && it !== 'none'))])
      .pipe(take(1))
      .subscribe(([subjects, signedInUser]) => {
        // 体験プランのユーザーは、各教科１つ目のみクリック可とする
        const editPlayList = (playlists: Playlist[]) => {
          return playlists.map(playlist =>
            playlist.order !== 1 && signedInUser.isTrial ? { ...playlist, pending: true } : { ...playlist, pending: false }
          );
        };

        const toPlayList = (subjectName: string) => (playLists: Playlist[]) => ({
          subjectName,
          playlists: editPlayList(DataUtil.sortObjectArrayBySpecificKey(playLists, 'order'))
        });

        const _playlistSubjects: Observable<PlaylistSubject>[] = [];

        if (signedInUser.visibleSubjectIds.includes(SubjectId.ENGLISH)) {
          const englishPlaylists$ = playlists$.pipe(
            map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.ENGLISH))
          );
          _playlistSubjects.push(englishPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.ENGLISH)))));
        }

        if (signedInUser.visibleSubjectIds.includes(SubjectId.MATH)) {
          const mathPlaylists$ = playlists$.pipe(map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.MATH)));
          _playlistSubjects.push(mathPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.MATH)))));
        }

        if (signedInUser.visibleSubjectIds.includes(SubjectId.NATIONAL_LANGUAGE)) {
          const nationalLanguagePlaylists$ = playlists$.pipe(
            map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.NATIONAL_LANGUAGE))
          );
          _playlistSubjects.push(
            nationalLanguagePlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.NATIONAL_LANGUAGE))))
          );
        }

        if (signedInUser.visibleSubjectIds.includes(SubjectId.PHYSICS)) {
          const physicsPlaylists$ = playlists$.pipe(
            map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.PHYSICS))
          );
          _playlistSubjects.push(physicsPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.PHYSICS)))));
        }

        if (signedInUser.visibleSubjectIds.includes(SubjectId.CHEMISTRY)) {
          const chemistryPlaylists$ = playlists$.pipe(
            map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.CHEMISTRY))
          );
          _playlistSubjects.push(chemistryPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.CHEMISTRY)))));
        }

        if (signedInUser.visibleSubjectIds.includes(SubjectId.BIOLOGY)) {
          const biologyPlaylists$ = playlists$.pipe(
            map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.BIOLOGY))
          );
          _playlistSubjects.push(biologyPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.BIOLOGY)))));
        }

        if (signedInUser.visibleSubjectIds.includes(SubjectId.JAPANESE_HISTORY)) {
          const japaneseHistoryPlaylists$ = playlists$.pipe(
            map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.JAPANESE_HISTORY))
          );
          _playlistSubjects.push(
            japaneseHistoryPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.JAPANESE_HISTORY))))
          );
        }

        if (signedInUser.visibleSubjectIds.includes(SubjectId.WORLD_HISTORY)) {
          const worldHistoryPlaylists$ = playlists$.pipe(
            map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.WORLD_HISTORY))
          );
          _playlistSubjects.push(worldHistoryPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.WORLD_HISTORY)))));
        }

        if (signedInUser.visibleSubjectIds.includes(SubjectId.GEOGRAPHY)) {
          const geographyPlaylists$ = playlists$.pipe(
            map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.GEOGRAPHY))
          );
          _playlistSubjects.push(geographyPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.GEOGRAPHY)))));
        }

        if (signedInUser.visibleSubjectIds.includes(SubjectId.POLITICAL_ECONOMY)) {
          const politicalEconomyPlaylists$ = playlists$.pipe(
            map(playlists => playlists.filter(playlist => playlist.subjectId === SubjectId.POLITICAL_ECONOMY))
          );
          _playlistSubjects.push(
            politicalEconomyPlaylists$.pipe(map(toPlayList(SubjectUtil.getName(subjects, SubjectId.POLITICAL_ECONOMY))))
          );
        }

        this.playlistSubjects$ = combineLatest(_playlistSubjects);
      });
  }
}
