<app-dialog-basic dialogTitle="" [isLoading]="disabled">
  <div class="py-4">
    <div>検索結果が印刷の上限を超えています。</div>
    <div>
      <strong>{{ maxPrintableCount }} 件</strong> のみ印刷を実行しますか？
    </div>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="confirm()" [disabled]="disabled">印刷を実行</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
