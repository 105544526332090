import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonSearchCondition } from 'src/app/models/common-search-condition';
import { EnglishSearchCondition } from 'src/app/models/english-search-condition';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';
import { NationalLanguageSearchCondition } from 'src/app/models/national-language-search-condition';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';

@Injectable({
  providedIn: 'root'
})
export class CommonIdSearchCategoryDialogSharedService {
  private englishCategorySelection = new BehaviorSubject<EnglishSearchCondition>(null);
  englishCategoryEmitted$ = this.englishCategorySelection.asObservable();

  private scienceCategorySelection = new BehaviorSubject<ScienceSearchCondition>(null);
  scienceCategoryEmitted$ = this.scienceCategorySelection.asObservable();

  private nationalLanguageCategorySelection = new BehaviorSubject<NationalLanguageSearchCondition>(null);
  nationalLanguageCategoryEmitted$ = this.nationalLanguageCategorySelection.asObservable();

  private historyCategorySelection = new BehaviorSubject<HistorySearchCondition>(null);
  historyCategoryEmitted$ = this.historyCategorySelection.asObservable();

  private commonSearchConditionSelection = new BehaviorSubject<CommonSearchCondition>(null);
  commonSearchConditionEmitted$ = this.commonSearchConditionSelection.asObservable();

  emitEnglishCategory(condition: EnglishSearchCondition) {
    this.englishCategorySelection.next(condition);
  }

  emitScienceCategory(condition: ScienceSearchCondition) {
    this.scienceCategorySelection.next(condition);
  }

  emitNationalLanguageCategory(condition: NationalLanguageSearchCondition) {
    this.nationalLanguageCategorySelection.next(condition);
  }

  emitHistoryCategory(condition: HistorySearchCondition) {
    this.historyCategorySelection.next(condition);
  }

  emitSelectedCommonSearchCondition(commonSearchCondition: CommonSearchCondition) {
    this.commonSearchConditionSelection.next(commonSearchCondition);
  }
}
