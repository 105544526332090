import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError, exhaustMap } from 'rxjs/operators';

import * as JukuActions from '../actions/juku.actions';
import { JukuService } from '../services/juku.service';
import { dispatchAppError } from '../actions/core.actions';
import { FirestoreError } from '../errors/firestore-error';
import { FirebaseCallableFunctionError } from '../errors/firebase-callable-function-error';
import { AppError } from '../errors/app-error';

@Injectable()
export class JukuEffects {
  private LOG_SOURCE = this.constructor.name;

  findJuku$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JukuActions.findJuku),
      switchMap(action =>
        this.jukuService.findJuku(action.jukuId).pipe(
          map(juku => JukuActions.findJukuSuccess({ juku })),
          catchError(e => {
            const error = e instanceof AppError ? e : FirestoreError.from(e);
            return of(JukuActions.findJukuFailure(), dispatchAppError({ source: this.LOG_SOURCE, error }));
          })
        )
      )
    )
  );

  findJukus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JukuActions.findJukus),
      switchMap(action =>
        this.jukuService.findJukus(action.jukuId, action.jukuCode, action.jukuName, action.jukuSubName, action.prefectureId).pipe(
          map(jukus => JukuActions.findJukusSuccess({ jukus })),
          catchError(e => of(JukuActions.findJukusFailure(), dispatchAppError({ source: this.LOG_SOURCE, error: FirestoreError.from(e) })))
        )
      )
    )
  );

  createJuku$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JukuActions.createJuku),
      exhaustMap(action =>
        this.jukuService.createJuku(action.juku).pipe(
          map(juku => JukuActions.createJukuSuccess({ juku })),
          catchError(e => of(JukuActions.createJukuFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  updateJuku$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JukuActions.updateJuku),
      exhaustMap(action =>
        this.jukuService.updateJuku(action.juku).pipe(
          map(juku => JukuActions.updateJukuSuccess({ juku })),
          catchError(e => of(JukuActions.updateJukuFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  deleteJuku$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JukuActions.deleteJuku),
      switchMap(action =>
        this.jukuService.deleteJuku(action.id).pipe(
          map(response => JukuActions.deleteJukuSuccess({ response })),
          catchError(e => of(JukuActions.deleteJukuFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private jukuService: JukuService) {}
}
