<app-wrapper size="xl" isFitWithSmallScreen="true">
  <app-content-row>
    <app-card-content [showSeparator]="false">
      <app-wrapper size="fluid">
        <app-content-row>
          <div @enter *ngIf="initialDataLoaded$ | async; else loading">
            <mat-form-field appearance="outline">
              <mat-label>科目</mat-label>
              <mat-select [(value)]="selectedSubjectId" (selectionChange)="onChangeSubject()">
                <mat-option *ngFor="let subject of subjects$ | async" [value]="subject.id">
                  {{ subject.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div class="item-container">
              <div class="mat-caption label">問題・解答データ</div>
              <div class="checkbox-list">
                <div>
                  <mat-checkbox color="primary" [(ngModel)]="hasExternalData" (change)="onChangeSearchCondition()">掲載あり</mat-checkbox>
                </div>
                <div>
                  <mat-checkbox color="primary" [(ngModel)]="hasWordData" (change)="onChangeSearchCondition()"
                    >Wordファイルあり</mat-checkbox
                  >
                </div>
              </div>
            </div>

            <!-- 英語特有条件 -->
            <app-content-row>
              <div class="" @enter *ngIf="selectedSubjectId === ENGLISH_SUBJECT_ID">
                <app-english-search-form
                  [staticEnglishData]="staticEnglishData$ | async"
                  (changeCondition)="onChangeSearchCondition()"
                  (formIsValid)="onChangeEnglishSearchFormIsValid($event)"
                >
                </app-english-search-form>
              </div>
            </app-content-row>

            <!-- 数学特有条件 -->
            <app-content-row>
              <div class="" @enter *ngIf="selectedSubjectId === MATH_SUBJECT_ID">
                <div class="mat-caption mb-2">分野</div>
                <div class="selected-categories-container">
                  <div class="px-4">
                    <app-selected-categories
                      [staticScienceData]="staticMathData$ | async"
                      [scienceSearchCondition]="mathSearchCondition"
                    ></app-selected-categories>
                  </div>
                  <button mat-flat-button color="primary" (click)="openMathCategoryDialog()">編集</button>
                </div>
              </div>
            </app-content-row>

            <!-- 国語特有条件 -->
            <app-content-row>
              <div class="" @enter *ngIf="selectedSubjectId === NATIONAL_LANGUAGE_SUBJECT_ID">
                <app-national-language-search-form
                  [staticNationalLanguageData]="staticNationalLanguageData$ | async"
                  [nationalLanguageSearchCondition]="nationalLanguageSearchCondition"
                  (changeCondition)="onChangeSearchCondition()"
                  (formIsValid)="onChangeNationalLanguageSearchFormIsValid($event)"
                >
                </app-national-language-search-form>
              </div>
            </app-content-row>

            <!-- 物理特有条件 -->
            <app-content-row>
              <div class="" @enter *ngIf="selectedSubjectId === PHYSICS_SUBJECT_ID">
                <div class="mat-caption mb-2">分野</div>
                <div class="selected-categories-container">
                  <div class="px-4">
                    <app-selected-categories
                      [staticScienceData]="staticPhysicsData$ | async"
                      [scienceSearchCondition]="physicsSearchCondition"
                    ></app-selected-categories>
                  </div>
                  <button mat-flat-button color="primary" (click)="openPhysicsCategoryDialog()">編集</button>
                </div>
              </div>
            </app-content-row>

            <!-- 化学特有条件 -->
            <app-content-row>
              <div class="" @enter *ngIf="selectedSubjectId === CHEMISTRY_SUBJECT_ID">
                <div class="mat-caption mb-2">分野</div>
                <div class="selected-categories-container">
                  <div class="px-4">
                    <app-selected-categories
                      [staticScienceData]="staticChemistryData$ | async"
                      [scienceSearchCondition]="chemistrySearchCondition"
                    ></app-selected-categories>
                  </div>
                  <button mat-flat-button color="primary" (click)="openChemistryCategoryDialog()">編集</button>
                </div>
              </div>
            </app-content-row>

            <!-- 生物特有条件 -->
            <app-content-row>
              <div class="" @enter *ngIf="selectedSubjectId === BIOLOGY_SUBJECT_ID">
                <div class="mat-caption mb-2">分野</div>
                <div class="selected-categories-container">
                  <div class="px-4">
                    <app-selected-categories
                      [staticScienceData]="staticBiologyData$ | async"
                      [scienceSearchCondition]="biologySearchCondition"
                    ></app-selected-categories>
                  </div>
                  <button mat-flat-button color="primary" (click)="openBiologyCategoryDialog()">編集</button>
                </div>
              </div>
            </app-content-row>

            <!-- 日本史特有条件 -->
            <app-content-row>
              <div class="" @enter *ngIf="selectedSubjectId === JAPANESE_HISTORY_SUBJECT_ID">
                <div class="mat-caption mb-2">分野</div>
                <div class="selected-categories-container">
                  <div class="px-4">
                    <app-selected-categories
                      [staticHistoryData]="staticJapaneseHistoryData$ | async"
                      [historySearchCondition]="japaneseHistorySearchCondition"
                    ></app-selected-categories>
                  </div>
                  <button mat-flat-button color="primary" (click)="openJapaneseHistoryCategoryDialog()">編集</button>
                </div>
                <div class="form-field-container">
                  <app-history-search-form
                    [historySearchCondition]="japaneseHistorySearchCondition"
                    (changeCondition)="onChangeSearchCondition()"
                  >
                  </app-history-search-form>
                </div>
              </div>
            </app-content-row>

            <!-- 世界史特有条件 -->
            <app-content-row>
              <div class="" @enter *ngIf="selectedSubjectId === WORLD_HISTORY_SUBJECT_ID">
                <div class="mat-caption mb-2">分野</div>
                <div class="selected-categories-container">
                  <div class="px-4">
                    <app-selected-categories
                      [staticHistoryData]="staticWorldHistoryData$ | async"
                      [historySearchCondition]="worldHistorySearchCondition"
                    ></app-selected-categories>
                  </div>
                  <button mat-flat-button color="primary" (click)="openWorldHistoryCategoryDialog()">編集</button>
                </div>
                <div class="form-field-container">
                  <app-history-search-form
                    [historySearchCondition]="worldHistorySearchCondition"
                    (changeCondition)="onChangeSearchCondition()"
                  >
                  </app-history-search-form>
                </div>
              </div>
            </app-content-row>

            <!-- 地理特有条件 -->
            <app-content-row>
              <div class="" @enter *ngIf="selectedSubjectId === GEOGRAPHY_SUBJECT_ID">
                <div class="mat-caption mb-2">分野</div>
                <div class="selected-categories-container">
                  <div class="px-4">
                    <app-selected-categories
                      [staticScienceData]="staticGeographyData$ | async"
                      [scienceSearchCondition]="geographySearchCondition"
                    ></app-selected-categories>
                  </div>
                  <button mat-flat-button color="primary" (click)="openGeographyCategoryDialog()">編集</button>
                </div>
              </div>
            </app-content-row>

            <!-- 政治経済特有条件 -->
            <app-content-row>
              <div class="" @enter *ngIf="selectedSubjectId === POLITICAL_ECONOMY_SUBJECT_ID">
                <div class="mat-caption mb-2">分野</div>
                <div class="selected-categories-container">
                  <div class="px-4">
                    <app-selected-categories
                      [staticScienceData]="staticPoliticalEconomyData$ | async"
                      [scienceSearchCondition]="politicalEconomySearchCondition"
                    ></app-selected-categories>
                  </div>
                  <button mat-flat-button color="primary" (click)="openPoliticalEconomyCategoryDialog()">編集</button>
                </div>
              </div>
            </app-content-row>

            <!-- 教科共通条件 -->
            <app-content-row>
              <div class="">
                <app-common-search-form
                  [subjectId]="selectedSubjectId"
                  [staticCommonData]="staticCommonData$ | async"
                  (changeCondition)="onChangeSearchCondition()"
                  (formIsValid)="onChangeCommonSearchFormIsValid($event)"
                ></app-common-search-form>
              </div>
            </app-content-row>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<!-- 下部UI -->
<div class="buttons-sub-container-spacer"></div>
<div class="buttons-sub-container">
  <app-wrapper size="xl">
    <div class="action-buttons-container" @enter *ngIf="initialDataLoaded$ | async; else loading">
      <div class="count-container">
        <div class="font-bold text-lg md:text-lg">検索結果</div>
        <div class="font-bold text-lg md:text-2xl text-primary">
          {{ (matchedProblemCount$ | async) == null ? '-' : (matchedProblemCount$ | async) }}
        </div>
        <div class="font-bold text-lg md:text-lg">件</div>

        <div [@inOut]="problemCountSearching$ | async">
          <mat-spinner [diameter]="24"></mat-spinner>
        </div>
        <span class="spacer"></span>
      </div>
      <span class="spacer hidden md:block"></span>
      <div class="flex md:block mt-4 md:mt-0">
        <button mat-flat-button class="mr-2 bg-gray-50 text-primary font-normal grow w-1/2 md:w-36" (click)="resetForms()">
          条件をリセット
        </button>
        <button
          mat-flat-button
          class="grow w-1/2 md:w-36"
          color="primary"
          (click)="showSearchResultView()"
          [disabled]="searchButtonDisabled$ | async"
        >
          結果を表示
        </button>
      </div>
    </div>
  </app-wrapper>
</div>
<!-- 下部UI -->

<ng-template #loading>
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>
