<div class="playlist-items">
  <app-content-row>
    <div *ngFor="let result of searchResults">
      <app-common-id-problem-list-item
        [problemId]="result.id"
        [isPremiumMemberOnly]="result.isPremiumMemberOnly"
        [subjectLabelStyleName]="result.subjectLabelStyleName"
        [subjectName]="result.subjectName"
        [level]="result.level"
        [levelName]="result.levelName"
        [universityName]="result.universityName"
        [year]="result.year"
        [daimonNumber]="result.daimonNumber"
        [problemSummary]="result.problemSummary"
        [isAnswered]="result.isAnswered"
        (resultClick)="clickResult($event)"
      ></app-common-id-problem-list-item>
    </div>
  </app-content-row>
</div>
<!-- おすすめ問題よりコピー -->
