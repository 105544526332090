import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';

import { RootState } from 'src/app/reducers';
import {
  setTitle,
  setBrowserTitle,
  unsubscribeWatchedProps,
  navigate,
  dispatchInfoMessage,
  dispatchAppError
} from 'src/app/actions/core.actions';
import { signOut } from 'src/app/actions/auth.actions';
import { enter, inOut } from 'src/app/resources/animations';
import { META_VIEWPORT_OTHER, UnsubscribeTarget } from '../../../resources/config';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { Log } from '../../../utils/log';
import { getUpdateUserIsTermsAgreeResult } from '../../../selectors/user.selectors';
import { filter, take } from 'rxjs/operators';
import { initializeUpdateSignedInUserIsTermsAgreeResult, updateSignedInUserIsTermsAgreeSuccess } from '../../../actions/auth.actions';
import { initializeUpdateUserIsTermsAgreeState, updateUserIsTermsAgree } from '../../../actions/user.actions';
import { getSignedInUser, getUpdateSignedInUserIsTermsAgreeResult } from '../../../selectors/auth.selectors';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../../models/user';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-agree',
  templateUrl: './terms-agree.component.html',
  styleUrls: ['./terms-agree.component.scss'],
  animations: [enter, inOut]
})
export class TermsAgreeComponent implements OnInit, OnDestroy {
  private LOG_SOURCE = this.constructor.name;
  private title = '利用規約';
  loading = false;
  user$: Observable<User>;

  constructor(private store: Store<RootState>, private dialog: MatDialog, private meta: Meta) {}

  ngOnInit() {
    this.meta.updateTag(META_VIEWPORT_OTHER);
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUp();
  }

  ngOnDestroy() {}

  signOut() {
    this.store.dispatch(signOut());
    this.store.dispatch(unsubscribeWatchedProps({ target: UnsubscribeTarget.ON_SIGN_OUT }));
    setTimeout(() => this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSignIn() })));
  }

  private setUp() {
    this.user$ = this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null && it !== 'none'),
      take(1)
    );
  }

  agree() {
    this.loading = true;

    // 利用規約同意APIの呼び出し処理をここに
    Log.debug(this.LOG_SOURCE, `利用規約の同意フラグを変更をします`);
    this.store
      .select(getUpdateUserIsTermsAgreeResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeUpdateUserIsTermsAgreeState());
        if (result.success) {
          Log.debug(this.LOG_SOURCE, `利用規約の同意完了: `, result.updatedUser);
          this.store
            .select(getUpdateSignedInUserIsTermsAgreeResult)
            .pipe(
              filter(it => it != null),
              take(1)
            )
            .subscribe(() => {
              // store上のsignedInUserの更新完了後に基本検索画面へ遷移
              this.store.dispatch(initializeUpdateSignedInUserIsTermsAgreeResult());
              setTimeout(() => this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearch() })));
            });
          this.user$.subscribe(user => {
            // store上のsignedInUserを更新
            const signedInUser = Object.assign({}, user);
            signedInUser.isTermsAgree = true;
            this.store.dispatch(updateSignedInUserIsTermsAgreeSuccess({ user: signedInUser }));
          });
          return;
        }

        Log.error(this.LOG_SOURCE, `利用規約の同意でエラーが発生しました: `, JSON.stringify(result.error));
        this.store.dispatch(dispatchAppError({ source: this.LOG_SOURCE, error: result.error }));
        this.loading = false;
      });
    this.user$.subscribe(user => {
      this.store.dispatch(updateUserIsTermsAgree({ user: { id: user.id } }));
    });
    // this.store.dispatch(
    //   dispatchInfoMessage({
    //     message: `${this.data.email} のアカウント有効／無効の変更をしています…`
    //   })
    // );
  }
}
