import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Area, DepartmentCategory, Level, University, UniversityType } from 'src/app/models/common-data';
import { CommonSearchCondition } from 'src/app/models/common-search-condition';
import { StaticCommonData } from 'src/app/models/static-common-data';
import {
  BIOLOGY_SEARCH_YEARS,
  DISPLAY_UNIVERSITY_TYPES,
  GEOGRAPHY_SEARCH_YEARS,
  JAPANESE_HISTORY_SEARCH_YEARS,
  NO_DISPLAY_DEPARTMENT_CATEGORY_IDS,
  NO_DISPLAY_LEVELS,
  POLITICAL_ECONOMY_SEARCH_YEARS,
  SEARCH_YEARS,
  SubjectId
} from 'src/app/resources/config';
import { Diff } from 'src/app/utils/diff';
import { Log } from 'src/app/utils/log';
import { DataUtil } from 'src/app/utils/data-util';
import { openWindow } from '../../../actions/core.actions';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { RootState } from '../../../reducers';
import { UnivChipListComponent } from '../../widgets/univ-chip-list/univ-chip-list-component';

interface LevelData extends Level {
  checked: boolean;
}

interface UniversityTypeData extends UniversityType {
  checked: boolean;
}

interface AreaData extends Area {
  checked: boolean;
}

const DEFAULT_DEPARTMENT_CATEGORY: DepartmentCategory = { id: '0', name: '指定なし' };

@Component({
  selector: 'app-common-search-form',
  templateUrl: './common-search-form.component.html',
  styleUrls: ['./common-search-form.component.scss']
})
export class CommonSearchFormComponent implements OnInit, OnChanges {
  @Input() subjectId: string;
  @Input() staticCommonData: StaticCommonData;

  @Output() changeCondition = new EventEmitter<void>();
  @Output() formIsValid = new EventEmitter<boolean>();

  // static:trueを設定することで、ngOnInitより、下記のコンポーネントにアクセスできる
  @ViewChild(UnivChipListComponent, { static: true }) univChipListComponent: UnivChipListComponent;

  private LOG_SOURCE = this.constructor.name;
  private defaultDepartmentCategory: DepartmentCategory;
  private defaultYear: number;
  private lastNotifiedCondition: CommonSearchCondition | undefined;

  univChipListLabelName = '大学名 ―大学入学共通テスト（旧大学入試センター試験）を含む・複数入力可―';
  univChipListAppearance = 'outline';
  univChipListMatFormFieldClass = 'university-form-field-common-search';

  /** two-way binding */
  isThinking = false;
  levels: LevelData[];
  universityTypes: UniversityTypeData[];
  areas: AreaData[];
  selectedDepartmentCategory: DepartmentCategory;

  selectedUniversities: University[] = [];
  selectableYears: number[];
  selectableDepartmentCategories: DepartmentCategory[];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  universityNamesPlaceholder: string;

  universityNameFormControl = new UntypedFormControl();
  startYearFormControl = new UntypedFormControl();
  endYearFormControl = new UntypedFormControl();

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.setUpLevels();
    this.setUpUniversityTypes();
    this.setUpAreas();
    this.setUpDepartmentCategories();
  }

  ngOnChanges() {
    this.setUpYears();
  }

  // exposed methods ---------------------------------------------------------------

  isValid(): boolean {
    return !this.endYearFormControl.hasError('min');
  }

  getCurrentCondition(): CommonSearchCondition {
    return this.collectCondition();
  }

  resetForms() {
    this.isThinking = false;
    this.levels.forEach(level => (level.checked = false));
    this.universityTypes.forEach(univType => (univType.checked = false));
    this.areas.forEach(area => (area.checked = false));
    this.selectedDepartmentCategory = this.defaultDepartmentCategory;
    this.startYearFormControl.setValue(this.defaultYear);
    this.endYearFormControl.setValue(this.defaultYear);
    this.endYearFormControl.updateValueAndValidity();
    this.selectedUniversities = [];
    this.univChipListComponent.initializeSelectedUniversitiesAndNamePlaceholder();
    this.univChipListComponent.updateSelectableUniversities();
    this.lastNotifiedCondition = undefined;
  }

  patchValue(condition: CommonSearchCondition) {
    Log.debug(this.LOG_SOURCE, `値を復元します: `, condition);
    if (condition.isThinking) this.isThinking = true;
    if (condition.levels && condition.levels.length !== 0) {
      this.levels.forEach(level => {
        if (condition.levels.includes(level.level)) level.checked = true;
      });
    }
    if (condition.universityTypeIds && condition.universityTypeIds.length !== 0) {
      this.universityTypes.forEach(univType => {
        if (condition.universityTypeIds.includes(univType.id)) univType.checked = true;
      });
    }
    if (condition.areaIds && condition.areaIds.length !== 0) {
      this.areas.forEach(area => {
        if (condition.areaIds.includes(area.id)) area.checked = true;
      });
    }
    if (condition.departmentCategoryId) {
      const depCategory = this.selectableDepartmentCategories.find(it => it.id === condition.departmentCategoryId);
      if (depCategory) this.selectedDepartmentCategory = depCategory;
    }
    if (condition.startYear) this.startYearFormControl.setValue(condition.startYear);
    if (condition.endYear) this.endYearFormControl.setValue(condition.endYear);
    if (condition.universityIds && condition.universityIds.length !== 0) {
      const selectedUnivs = this.staticCommonData.universities.filter(univ => condition.universityIds.includes(univ.id));
      selectedUnivs.forEach(selectedUniv => this.selectedUniversities.push(selectedUniv));
      this.univChipListComponent.updateUnivChipList(this.selectedUniversities);
      const currentCondition = this.collectCondition();
      this.lastNotifiedCondition = { ...currentCondition };
    }
  }

  // components internal methods ---------------------------------------------------------------

  goTop() {
    this.store.dispatch(openWindow({ url: RoutingPathResolver.resolveTop(), extras: { queryParams: { to: 'subject' } } }));
  }

  onChangeValues() {
    const [startYear, endYear] = [this.startYearFormControl.value, this.endYearFormControl.value];
    if (this.invalidEndYear(startYear, endYear)) {
      Log.warn(this.LOG_SOURCE, '入試年度が不正な範囲です');
      this.endYearFormControl.setErrors({ 'min': true });
      this.formIsValid.emit(false);
      return;
    }

    this.endYearFormControl.updateValueAndValidity();
    this.formIsValid.emit(true);

    const currentCondition = this.collectCondition();
    Log.debug(this.LOG_SOURCE, '現在の選択状況: ', currentCondition);

    if (!Diff.isDifferentObject(this.lastNotifiedCondition, currentCondition)) {
      Log.debug(this.LOG_SOURCE, '前回 emit したデータと同じため無視します');
      return;
    }

    this.lastNotifiedCondition = { ...currentCondition };
    this.changeCondition.emit();
  }

  changeSelectedUniversities(selectedUniversities: University[]) {
    this.selectedUniversities = selectedUniversities;
    this.onChangeValues();
  }

  // set ups ---------------------------------------------------------------
  private setUpLevels() {
    const data = [...this.staticCommonData.levels]
      .filter(l => !NO_DISPLAY_LEVELS.includes(l.level))
      .sort((a, b) => a.level - b.level)
      .map<LevelData>(l => ({ ...l, checked: false }));
    this.levels = data;
  }

  private setUpUniversityTypes() {
    const data = DataUtil.sortObjectArrayBySpecificKey(this.staticCommonData.universityTypes, 'id')
      .filter(ut => DISPLAY_UNIVERSITY_TYPES.includes(ut.id))
      .map<UniversityTypeData>(ut => ({
        ...ut,
        checked: false
      }));
    this.universityTypes = data;
  }

  private setUpAreas() {
    const data = DataUtil.sortObjectArrayBySpecificKey(this.staticCommonData.areas, 'id').map<AreaData>(area => ({
      ...area,
      checked: false
    }));
    this.areas = data;
  }

  private setUpYears() {
    switch (this.subjectId) {
      case SubjectId.BIOLOGY:
        this.selectableYears = [...BIOLOGY_SEARCH_YEARS].reverse();
        this.defaultYear = [...BIOLOGY_SEARCH_YEARS].pop();
        break;
      case SubjectId.JAPANESE_HISTORY:
        this.selectableYears = [...JAPANESE_HISTORY_SEARCH_YEARS].reverse();
        this.defaultYear = [...JAPANESE_HISTORY_SEARCH_YEARS].pop();
        break;
      case SubjectId.GEOGRAPHY:
        this.selectableYears = [...GEOGRAPHY_SEARCH_YEARS].reverse();
        this.defaultYear = [...GEOGRAPHY_SEARCH_YEARS].pop();
        break;
      case SubjectId.POLITICAL_ECONOMY:
        this.selectableYears = [...POLITICAL_ECONOMY_SEARCH_YEARS].reverse();
        this.defaultYear = [...POLITICAL_ECONOMY_SEARCH_YEARS].pop();
        break;
      default:
        this.selectableYears = [...SEARCH_YEARS].reverse();
        this.defaultYear = [...SEARCH_YEARS].pop();
    }
    this.startYearFormControl.setValue(this.defaultYear);
    this.endYearFormControl.setValue(this.defaultYear);
  }

  private setUpDepartmentCategories() {
    this.defaultDepartmentCategory = DEFAULT_DEPARTMENT_CATEGORY;
    this.selectableDepartmentCategories = DataUtil.sortNumberIdentifiedData([
      ...this.staticCommonData.departmentCategories.filter(it => !NO_DISPLAY_DEPARTMENT_CATEGORY_IDS.includes(it.id)),
      this.defaultDepartmentCategory
    ]);
    this.selectedDepartmentCategory = this.defaultDepartmentCategory;
  }

  // utils ---------------------------------------------------------------

  private invalidEndYear(startYear: number, endYear: number): boolean {
    return endYear < startYear;
  }

  private collectCondition(): CommonSearchCondition {
    const [startYear, endYear] = [this.startYearFormControl.value, this.endYearFormControl.value];
    const condition: CommonSearchCondition = {
      subjectId: this.subjectId,
      startYear,
      endYear
    };
    const selectedLevels = this.levels.filter(it => it.checked).map(it => it.level);
    const selectedUniversityTypeIds = this.universityTypes.filter(it => it.checked).map(it => it.id);
    const selectedAreaIds = this.areas.filter(it => it.checked).map(it => it.id);
    const selectedUniversityIds = this.selectedUniversities.map(it => it.id);
    if (selectedLevels.length !== 0) condition.levels = selectedLevels;
    if (selectedUniversityTypeIds.length !== 0) condition.universityTypeIds = selectedUniversityTypeIds;
    if (selectedAreaIds.length !== 0) condition.areaIds = selectedAreaIds;
    if (selectedUniversityIds.length !== 0) condition.universityIds = selectedUniversityIds;

    if (this.isThinking) condition.isThinking = true;
    if (this.selectedDepartmentCategory.id !== this.defaultDepartmentCategory.id) {
      condition.departmentCategoryId = this.selectedDepartmentCategory.id;
    }

    return condition;
  }
}
