import * as uuid from 'uuid';
import { environment } from '../../../environments/environment';

export class CommonIdOidcUtil {
  private static responseType = 'code';
  private static scope = 'openid+email+profile';

  static getAuthorizationEndpoint(): string {
    const state = uuid.v4();
    const nonce = uuid.v4();
    localStorage.setItem('state', state);
    localStorage.setItem('nonce', nonce);
    return (
      `${environment.commonIdConfig.authorizationEndpoint}?` +
      `client_id=${environment.commonIdConfig.clientId}&redirect_uri=${environment.commonIdConfig.redirectUri}&` +
      `response_type=${this.responseType}&scope=${this.scope}&` +
      `state=${state}&nonce=${nonce}`
    );
  }
}
