import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommonIdAnsweredProblemService } from 'src/app/services/common-id/common-id-answered-problem.service';
import * as CommonIdAnsweredProblemActions from '../../actions/common-id/common-id-answered-problem.actions';
import { catchError, switchMap, map } from 'rxjs/operators';
import { AppError } from 'src/app/errors/app-error';
import { FirestoreError } from 'src/app/errors/firestore-error';
import { of } from 'rxjs';
import { dispatchAppError } from 'src/app/actions/core.actions';
import { FirebaseCallableFunctionError } from 'src/app/errors/firebase-callable-function-error';

@Injectable()
export class CommonIdAnsweredProblemEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdSaveAnsweredProblem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdAnsweredProblemActions.commonIdSaveAnsweredProblems),
      switchMap(action =>
        this.commonIdAnsweredProblemService.commonIdSaveAnsweredProblems(action.request.userId, action.request.problems).pipe(
          map(response => CommonIdAnsweredProblemActions.commonIdSaveAnsweredProblemsSuccess({ response })),
          catchError(e =>
            of(CommonIdAnsweredProblemActions.commonIdSaveAnsweredProblemsFailure({ error: FirebaseCallableFunctionError.from(e) }))
          )
        )
      )
    )
  );
  commonIdFindAnsweredProblem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdAnsweredProblemActions.commonIdFindAnsweredProblems),
      switchMap(action =>
        this.commonIdAnsweredProblemService.commonIdFindAnsweredProblems(action.userId).pipe(
          map(answeredProblem => CommonIdAnsweredProblemActions.commonIdFindAnsweredProblemsSuccess({ answeredProblem })),
          catchError(e => {
            const error = e instanceof AppError ? e : FirestoreError.from(e);
            return of(
              CommonIdAnsweredProblemActions.commonIdFindAnsweredProblemsFailure(),
              dispatchAppError({ source: this.LOG_SOURCE, error })
            );
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdAnsweredProblemService: CommonIdAnsweredProblemService) {}
}
