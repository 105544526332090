import { Component, OnInit } from '@angular/core';
import { navigate, openWindow, setBrowserTitle, setTitle } from '../../../actions/core.actions';
import { RoutingPathResolver } from '../../../app-routing-path-resolver';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;
  private title = 'Admin機能';

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
  }

  accounts() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveAccounts() }));
  }

  bulkMail() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveBulkMail() }));
  }

  reports() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveReports() }));
  }

  commonIdAccounts() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdAccounts() }));
  }

  commonIdAccountSingleSearch() {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdAccountSearch() }));
  }

  microCms() {
    this.store.dispatch(openWindow({ url: environment.microCmsConfig.adminUrl }));
  }
}
