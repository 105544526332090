import { Component, OnInit, Input, Inject } from '@angular/core';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { filter, take } from 'rxjs';
import { getCommonIdSignedInUser } from '../../../../selectors/common-id/common-id-auth.selectors';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { CommonIdContactUtil } from '../../../../utils/common-id/common-id-contact-util';
import { navigate, openWindow } from '../../../../actions/core.actions';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { findCommonIdSignedInUser } from 'src/app/actions/common-id/common-id-auth.actions';
import { CommonIdUser } from '../../../../models/common-id/common-id-user';
import { CONTACT_GOOGLE_FORM_URL } from 'src/app/resources/common-id-config';

@Component({
  selector: 'app-common-id-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class CommonIdFooterComponent implements OnInit {
  topUrl: string;
  termsUrl: string;
  tokusyouhouUrl: string;

  constructor(private store: Store<RootState>, @Inject(WINDOW_OBJECT) private window: Window) {}

  @Input() footerTypeEntrance = false;
  @Input() footerTypeLP = false;

  ngOnInit() {
    this.findCommonIdSignedInUserIfNeeded();
    this.topUrl = RoutingPathResolver.resolveCommonIdTop();
    this.termsUrl = RoutingPathResolver.resolveCommonIdTerms();
    this.tokusyouhouUrl = RoutingPathResolver.resolveCommonIdTokusyouhou();
  }

  gotoTop() {
    const url = RoutingPathResolver.resolveCommonIdTop();
    this.store.dispatch(navigate({ url }));
  }

  gotoContact() {
    this.store
      .select(getCommonIdSignedInUser)
      .pipe(
        filter<CommonIdUser>(it => it != null),
        take(1)
      )
      .subscribe(user => {
        const commonId = user !== 'none' ? user.commonId : '-';
        const url = CommonIdContactUtil.getGoogleFormUrl(CONTACT_GOOGLE_FORM_URL, commonId, this.window.navigator.userAgent);
        this.store.dispatch(openWindow({ url }));
      });
  }

  private findCommonIdSignedInUserIfNeeded() {
    this.store
      .select(getCommonIdSignedInUser)
      .pipe(take(1))
      .subscribe(user => {
        if (user == null) this.store.dispatch(findCommonIdSignedInUser());
      });
  }
}
