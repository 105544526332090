import { Action, createReducer, on } from '@ngrx/store';

import * as CommonIdPaperBookmarkActions from '../../actions/common-id/common-id-paper-bookmark.actions';
import { AppError } from '../../errors/app-error';
import { CommonIdAddPaperBookmarkResponse } from '../../models/common-id/common-id-add-paper-bookmark-response';
import { CommonIdDeletePaperBookmarkResponse } from '../../models/common-id/common-id-delete-paper-bookmark-response';
import { CommonIdPaperBookmark, CommonIdBookmarkPaper } from '../../models/common-id/common-id-paper-bookmark';

export const commonIdPaperBookmarkFeatureKey = 'commonIdPaperBookmark';

export interface CommonIdAddPaperBookmarkResult {
  success: boolean;
  response: CommonIdAddPaperBookmarkResponse | null;
  error: AppError | null;
}

export interface CommonIdDeletePaperBookmarkResult {
  success: boolean;
  response: CommonIdDeletePaperBookmarkResponse | null;
  error: AppError | null;
}

export interface State {
  paperBookmark: CommonIdPaperBookmark | null;
  addPaperBookmarkResult: CommonIdAddPaperBookmarkResult | null;
  deletePaperBookmarkResult: CommonIdDeletePaperBookmarkResult | null;

  paperBookmarkProblems: CommonIdBookmarkPaper[] | null;
}

export const initialState: State = {
  paperBookmark: null,
  addPaperBookmarkResult: null,
  deletePaperBookmarkResult: null,
  paperBookmarkProblems: null
};

const CommonIdPaperBookmarkReducer = createReducer(
  initialState,

  // Find Bookmarks -------------------------------------------------------
  on(CommonIdPaperBookmarkActions.commonIdFindPaperBookmarkSuccess, (state, { paperBookmark }) => ({ ...state, paperBookmark })),
  on(CommonIdPaperBookmarkActions.initializeCommonIdFindPaperBookmarkState, state => {
    const next: State = { ...state, paperBookmark: null };
    return next;
  }),

  // Add Bookmark ----------------------------------------------------------------
  on(CommonIdPaperBookmarkActions.commonIdAddPaperBookmarkSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      addPaperBookmarkResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(CommonIdPaperBookmarkActions.commonIdAddPaperBookmarkFailure, (state, { error }) => {
    const next: State = {
      ...state,
      addPaperBookmarkResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(CommonIdPaperBookmarkActions.initializeCommonIdAddPaperBookmarkState, state => {
    const next: State = { ...state, addPaperBookmarkResult: null };
    return next;
  }),

  // Delete Bookmark ----------------------------------------------------------------
  on(CommonIdPaperBookmarkActions.commonIdDeletePaperBookmarkSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      deletePaperBookmarkResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(CommonIdPaperBookmarkActions.commonIdDeletePaperBookmarkFailure, (state, { error }) => {
    const next: State = {
      ...state,
      deletePaperBookmarkResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(CommonIdPaperBookmarkActions.initializeCommonIdDeletePaperBookmarkState, state => {
    const next: State = { ...state, deletePaperBookmarkResult: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return CommonIdPaperBookmarkReducer(state, action);
}
