<app-search-univ-result-base
  [searchResults]="searchResultsToDisplay"
  [isPremiumIconShown]="isPremiumIconShown"
  [isBtoC]="isBtoC"
  [buttonAbled$]="initializedBookmark$"
  [bookmarkClickEmitted]="bookmarkClickEmitted"
  [errorUnivId$]="errorUnivId$"
  (goToPaperDetailClick)="goToPaperDetail($event)"
  (goToUnivDetailClick)="goToUnivDetail($event)"
  (addUniversityBookmarkClick)="addUniversityBookmark($event)"
  (deleteUniversityBookmarkClick)="deleteUniversityBookmark($event)"
></app-search-univ-result-base>
