<app-wrapper size="lg">
  <div @enter *ngIf="(loaded$ | async) === true">
    <!-- 戻るボタン -->
    <app-content-row>
      <div class="flex items-center gap-x-2" *ngIf="this.selectedSearchCondition.tag !== undefined">
        <app-button-location-back (backClick)="goBack()">戻る</app-button-location-back>
      </div>
    </app-content-row>

    <!-- 検索条件 -->
    <app-content-row>
      <app-common-id-playlists-search-condition
        [isPremiumUser]="isPremiumUser"
        [subjects]="subjects$ | async"
        [selectedSearchCondition]="selectedSearchCondition"
      ></app-common-id-playlists-search-condition>
    </app-content-row>

    <!-- タグ一覧 -->
    <app-content-row>
      <app-common-id-playlists-tag-buttons
        [playlistsTags$]="playlistsTags$"
        (tagClick)="clickTag($event)"
      ></app-common-id-playlists-tag-buttons>
    </app-content-row>

    <!-- 検索結果 -->
    <app-content-row>
      <app-common-id-playlists-search-result
        [playlists$]="playlists$"
        [isPremiumUser]="isPremiumUser"
        [searchCondition]="selectedSearchCondition"
      >
      </app-common-id-playlists-search-result>
    </app-content-row>
  </div>
</app-wrapper>

<div class="progress-48" *ngIf="(loaded$ | async) === false">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
