import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-label-answered',
  templateUrl: './label-answered.component.html',
  styleUrls: ['./label-answered.component.scss']
})
export class LabelAnsweredComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
