<app-dialog-basic dialogTitle="権限を変更" [isLoading]="disabled">
  <div class="pb-4">
    <div *ngIf="!data.user.isOrganizationAdmin">
      <div>
        <strong>{{ data.user.email }}</strong> のユーザーを団体管理ユーザーに変更します。
      </div>
      <div>よろしいですか？</div>
    </div>

    <div *ngIf="data.user.isOrganizationAdmin">
      <div>
        <strong>{{ data.user.email }}</strong> のユーザーを一般ユーザーに変更します。
      </div>
      <div>よろしいですか？</div>
    </div>
  </div>

  <div class="progress-48">
    <mat-spinner [diameter]="48" @enter *ngIf="disabled"></mat-spinner>
  </div>

  <app-dialog-basic-footer>
    <button mat-stroked-button (click)="cancel()" [disabled]="disabled">キャンセル</button>
    <button mat-flat-button color="primary" (click)="changeAuthority()" [disabled]="disabled">変更</button>
  </app-dialog-basic-footer>
</app-dialog-basic>
