import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { StaticScienceData } from 'src/app/models/static-science-data';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { ScienceUnit } from 'src/app/models/science-data';
import { HistoryUnit } from 'src/app/models/history-data';
import { Log } from 'src/app/utils/log';
import { CATEGORY_DELIMITER, NO_DISPLAY_MATH_CATEGORY_PREFIX } from 'src/app/resources/config';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';
import { GeneralError } from 'src/app/errors/general-error';

export interface SelectCategoryDialogData {
  dataType: 'MATH' | 'PHYSICS' | 'CHEMISTRY' | 'BIOLOGY' | 'JAPANESE_HISTORY' | 'WORLD_HISTORY' | 'GEOGRAPHY' | 'POLITICAL_ECONOMY';
  staticScienceData?: StaticScienceData;
  staticHistoryData?: StaticHistoryData;

  scienceSearchCondition?: ScienceSearchCondition;
  historySearchCondition?: HistorySearchCondition;
}

/** cancel で close のときは undefined */
export type SelectCategoryDialogResult = ScienceSearchCondition | HistorySearchCondition | undefined;

interface UnitData extends ScienceUnit, HistoryUnit {
  checked: boolean;
  parentSubjectId: string;
}

interface FieldData {
  id: string;
  name: string;
  parentSubjectId: string;
  checked: boolean;
  indeterminate: boolean;
  units: UnitData[];
}

interface SubjectData {
  id: string;
  index: number;
  name: string;
  fields: FieldData[];
}

@Component({
  selector: 'app-select-category-dialog',
  templateUrl: './select-category-dialog.component.html',
  styleUrls: ['./select-category-dialog.component.scss']
})
export class SelectCategoryDialogComponent implements OnInit {
  private LOG_SOURCE = this.constructor.name;

  subjectDataArray: SubjectData[];

  /** two-way binding */
  selectedTabIndex = 0;

  constructor(
    private dialogRef: MatDialogRef<SelectCategoryDialogComponent, SelectCategoryDialogResult>,
    @Inject(MAT_DIALOG_DATA) private data: SelectCategoryDialogData
  ) {}

  ngOnInit() {
    this.setUpCheckboxes();
    this.setInitialValues();
  }

  onChangeField(field: FieldData) {
    const allChecked = field.units.filter(it => it.checked).length === field.units.length;

    if (allChecked) {
      field.units.forEach(unit => (unit.checked = false));
    } else {
      field.units.forEach(unit => (unit.checked = field.checked));
    }
  }

  onChangeUnit(field: FieldData) {
    const allChecked = field.units.filter(it => it.checked).length === field.units.length;
    const allUnchecked = field.units.filter(it => it.checked).length === 0;

    field.indeterminate = !allChecked && !allUnchecked;
    if (allChecked) field.checked = true;
    if (allUnchecked) field.checked = false;
  }

  selectCurrentTabAllFields() {
    const currentSubject = this.subjectDataArray.find(subject => subject.index === this.selectedTabIndex);
    if (!currentSubject) return;

    currentSubject.fields.forEach(field => {
      field.indeterminate = false;
      field.checked = true;
      field.units.forEach(unit => (unit.checked = true));
    });
  }

  submit() {
    const allFieldSelectedSubjects = this.subjectDataArray.filter(subject => {
      const fieldAllChecked =
        subject.fields.filter(field => {
          const unitAllChecked = field.units.filter(unit => unit.checked).length === field.units.length;
          return unitAllChecked;
        }).length === subject.fields.length;
      return fieldAllChecked;
    });
    const subjectIds = allFieldSelectedSubjects.map(subject => subject.id);

    const selectedSubjectIgnoredFields = this.subjectDataArray
      .filter(subject => !subjectIds.includes(subject.id))
      .reduce<FieldData[]>((acc, subject) => [...acc, ...subject.fields], []);
    const allUnitSelectedFields = selectedSubjectIgnoredFields.filter(field => {
      const unitAllChecked = field.units.filter(unit => unit.checked).length === field.units.length;
      return unitAllChecked;
    });
    const allUnitSelectedFieldIds = allUnitSelectedFields.map(field => field.id);
    const subjectAndFieldIds = allUnitSelectedFields.map(field => `${field.parentSubjectId}${CATEGORY_DELIMITER}${field.id}`);

    const selectedUnits = selectedSubjectIgnoredFields
      .filter(field => !allUnitSelectedFieldIds.includes(field.id))
      .reduce<UnitData[]>((acc, field) => [...acc, ...field.units], [])
      .filter(unit => unit.checked);
    const categories = selectedUnits.map(
      unit => `${unit.parentSubjectId}${CATEGORY_DELIMITER}${unit.parentFieldId}${CATEGORY_DELIMITER}${unit.id}`
    );

    const condition: ScienceSearchCondition | HistorySearchCondition = {
      subjectIds,
      subjectAndFieldIds,
      categories
    };
    Log.debug(this.LOG_SOURCE, 'selected values: ', condition);

    this.dialogRef.close(condition);
  }

  cancel() {
    this.dialogRef.close(undefined);
  }

  isSubjectDataType() {
    return this.data.dataType === 'JAPANESE_HISTORY' || this.data.dataType === 'WORLD_HISTORY' ? true : null;
  }

  private setUpCheckboxes() {
    if (!this.data || !this.data.dataType || (!this.data.staticScienceData && !this.data.staticHistoryData)) {
      throw GeneralError.failedPrecondition(`必要なデータが設定されていません`);
    }
    const filteredStaticData = this.getFilteredStaticData();

    this.subjectDataArray = filteredStaticData.subjects.map((subject, index) => {
      const fields = filteredStaticData.fields.filter(it => it.parentSubjectId === subject.id);

      const fieldsWithUnit: FieldData[] = fields.map(field => {
        const units = filteredStaticData.units.filter(it => it.parentFieldId === field.id);
        const fieldData: FieldData = {
          id: field.id,
          name: field.name,
          parentSubjectId: field.parentSubjectId,
          checked: false,
          indeterminate: false,
          units: units.map<UnitData>(unit => ({ ...unit, checked: false, parentSubjectId: subject.id }))
        };
        return fieldData;
      });

      const subjectData: SubjectData = {
        id: subject.id,
        index,
        name: subject.name,
        fields: fieldsWithUnit
      };
      return subjectData;
    });
  }

  private setInitialValues() {
    const selected = this.data.scienceSearchCondition || this.data.historySearchCondition;
    if (!selected) {
      Log.debug(this.LOG_SOURCE, '何も選択されていないため初期値をセットしません');
      return;
    }

    if (selected.subjectIds) {
      selected.subjectIds.forEach(subjectId => this.selectSubject(subjectId));
    }
    if (selected.subjectAndFieldIds) {
      selected.subjectAndFieldIds.forEach(subjectAndFieldId => this.selectField(subjectAndFieldId));
    }
    if (selected.categories) {
      this.selectCategories(selected.categories);
    }
  }

  private selectSubject(subjectId: string) {
    const subject = this.subjectDataArray.find(it => it.id === subjectId);
    if (subject) {
      subject.fields.forEach(field => {
        field.checked = true;
        field.units.forEach(unit => (unit.checked = true));
      });
    }
  }

  private selectField(subjectAndFieldId: string) {
    const splitIds = subjectAndFieldId.split(CATEGORY_DELIMITER);
    if (splitIds.length !== 2) {
      Log.warn(this.LOG_SOURCE, `subjectAndFieldIds が不正な値です: ${subjectAndFieldId}`);
      return;
    }

    const [subjectId, fieldId] = [splitIds[0], splitIds[1]];
    const subject = this.subjectDataArray.find(it => it.id === subjectId);
    if (!subject) {
      Log.warn(this.LOG_SOURCE, `指定された ID の subject が見つかりませんでした. subjectId: ${subjectId}`);
      return;
    }
    const field = subject.fields.find(it => it.id === fieldId);
    if (!field) {
      Log.warn(this.LOG_SOURCE, `指定された ID の field が見つかりませんでした. fieldId: ${fieldId}`);
      return;
    }

    field.checked = true;
    field.units.forEach(unit => (unit.checked = true));
  }

  private selectCategories(categories: string[]) {
    categories.forEach(category => {
      const splitIds = category.split(CATEGORY_DELIMITER);
      if (splitIds.length !== 3) {
        Log.warn(this.LOG_SOURCE, `subjectAndFieldIds が不正な値です: ${category}`);
        return;
      }

      const [subjectId, fieldId, unitId] = [splitIds[0], splitIds[1], splitIds[2]];
      const subject = this.subjectDataArray.find(it => it.id === subjectId);
      if (!subject) {
        Log.warn(this.LOG_SOURCE, `指定された ID の subject が見つかりませんでした. subjectId: ${subjectId}`);
        return;
      }
      const field = subject.fields.find(it => it.id === fieldId);
      if (!field) {
        Log.warn(this.LOG_SOURCE, `指定された ID の field が見つかりませんでした. fieldId: ${fieldId}`);
        return;
      }

      const unit = field.units.find(it => it.id === unitId);
      if (!unit) {
        Log.warn(this.LOG_SOURCE, `指定された ID の unit が見つかりませんでした. unitId: ${unitId}`);
        return;
      }

      field.indeterminate = true;
      unit.checked = true;
    });
  }

  private getFilteredStaticData(): StaticScienceData | StaticHistoryData {
    if (this.data.dataType === 'MATH') {
      return {
        subjects: this.data.staticScienceData.subjects.filter(subject => !subject.id.startsWith(NO_DISPLAY_MATH_CATEGORY_PREFIX)),
        fields: this.data.staticScienceData.fields.filter(field => !field.id.startsWith(NO_DISPLAY_MATH_CATEGORY_PREFIX)),
        units: this.data.staticScienceData.units.filter(unit => !unit.id.startsWith(NO_DISPLAY_MATH_CATEGORY_PREFIX))
      };
    }

    if (
      this.data.dataType === 'PHYSICS' ||
      this.data.dataType === 'CHEMISTRY' ||
      this.data.dataType === 'BIOLOGY' ||
      this.data.dataType === 'GEOGRAPHY' ||
      this.data.dataType === 'POLITICAL_ECONOMY'
    ) {
      return {
        subjects: this.data.staticScienceData.subjects,
        fields: this.data.staticScienceData.fields,
        units: this.data.staticScienceData.units
      };
    }

    if (this.data.dataType === 'JAPANESE_HISTORY' || this.data.dataType === 'WORLD_HISTORY') {
      return {
        subjects: this.data.staticHistoryData.subjects,
        fields: this.data.staticHistoryData.fields,
        units: this.data.staticHistoryData.units
      };
    }
  }
}
