import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { dispatchInfoMessage } from 'src/app/actions/core.actions';
import { initializeUpdateCommonIdUserState, updateCommonIdUser } from 'src/app/actions/common-id/common-id-user.actions';
import { getUpdateCommonIdUserResult } from 'src/app/selectors/common-id/common-id-user.selectors';

import { enter } from 'src/app/resources/animations';
import { COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR, PURCHASE_PLAN_NAMES } from './../../../../resources/common-id-config';
import { Log } from 'src/app/utils/log';
import { Dates } from '../../../../utils/dates';

import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { CommonIdUpdateUserRequest } from 'src/app/models/common-id/common-id-update-user-request';

@Component({
  selector: 'app-common-id-update-account-dialog',
  templateUrl: './update-account-dialog.component.html',
  styleUrls: ['./update-account-dialog.component.scss'],
  animations: [enter]
})
export class CommonIdUpdateAccountDialogComponent implements OnInit, OnDestroy {
  accountForm: UntypedFormGroup;
  accountEditing = false;
  errorMessage = '';
  dialogTitle: string;
  minDate: Date;

  userId: string;
  purchasePlanName: string;
  createdAt: string;
  lastPurchasedAt: string;

  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<CommonIdUpdateAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: CommonIdUser
  ) {}

  ngOnInit() {
    this.dialogTitle = 'ステータス更新';

    this.setUpForms();
    this.setUpValues();
  }

  ngOnDestroy() {
    this.store.dispatch(initializeUpdateCommonIdUserState());
  }

  onChangePremiumEndAt() {
    this.accountForm.get('premiumEndAt').updateValueAndValidity();
  }

  updateAccount() {
    if (this.accountForm.invalid) {
      this.accountForm.markAllAsTouched();
      return;
    }
    Log.debug(this.LOG_SOURCE, 'アカウントの購入ステータス(有効期限)を変更します');
    this.disableForms();
    this.errorMessage = '';

    const premiumEndAt = Dates.simple4YmdStringFromIso(this.accountForm.get('premiumEndAt').value);

    const request: CommonIdUpdateUserRequest = {
      id: this.data.id,
      premiumEndAt
    };
    Log.debug(this.LOG_SOURCE, '更新する共通IDユーザー', request.id, '更新する有効期限', request.premiumEndAt);

    this.store.dispatch(updateCommonIdUser({ request }));

    this.store
      .select(getUpdateCommonIdUserResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeUpdateCommonIdUserState());
        Log.debug(this.LOG_SOURCE, `共通IDユーザーの更新結果: `, result);
        if (result.success) {
          this.dialogRef.close(true);
          Log.debug(this.LOG_SOURCE, 'result.updatedUser', result.updatedUser);
          const message = `${this.data.commonId} のアカウント変更が完了しました`;
          this.store.dispatch(
            dispatchInfoMessage({
              message
            })
          );
          Log.debug(this.LOG_SOURCE, `アカウント更新完了: `, result.updatedUser);
          return;
        }

        Log.warn(this.LOG_SOURCE, `アカウント更新エラー: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
        this.enableForms();
        // TODO: メッセージ内容を要検討
        this.errorMessage = result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました';
      });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  private setUpForms() {
    this.accountForm = new UntypedFormGroup({
      premiumEndAt: new UntypedFormControl('', [Validators.required])
    });
  }

  private setUpValues() {
    this.userId = this.data.commonId;
    this.purchasePlanName = this.data.purchasePlanId
      ? PURCHASE_PLAN_NAMES.find(status => status.id === this.data.purchasePlanId).name
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;
    this.createdAt = Dates.stdDateStringFromIso(this.data.createdAt);
    this.lastPurchasedAt = this.data.lastPurchasedAt
      ? Dates.stdDateStringFromIso(this.data.lastPurchasedAt)
          .split(' ')
          .join('\n')
      : COMMON_ID_ACCOUNT_NO_DATA_INDICATIOR;

    this.minDate = new Date(this.lastPurchasedAt);
    this.accountForm.patchValue({
      premiumEndAt: this.data.premiumEndAt
    });
  }

  private disableForms() {
    Object.keys(this.accountForm.controls).forEach(ctrlName => {
      this.accountForm.get(ctrlName).disable();
    });
    this.accountEditing = true;
  }

  private enableForms() {
    Object.keys(this.accountForm.controls).forEach(ctrlName => {
      this.accountForm.get(ctrlName).enable();
    });
    this.accountEditing = false;
  }
}
