<div class="button-toggle-playlist-bookmark">
  <div class="button-toggle-playlist-bookmark-inner">
    <ng-container *ngIf="showSpinner === false; else bookmarkSpiner">
      <!-- OFF -->
      <button
        *ngIf="!isPlaylistBookmarked"
        class="button"
        mat-icon-button
        [disabled]="disabled"
        (click)="toggle()"
        matTooltip="あとで解くに登録します"
        matTooltipPosition="above"
      >
        <mat-icon class="icon text-primary" aria-hidden="false" aria-label="あとで解くに登録">attach_file</mat-icon>
        <span class="button-text">あとで解く</span>
      </button>

      <!-- ON -->
      <button
        *ngIf="isPlaylistBookmarked"
        class="button bookmarked"
        mat-icon-button
        [disabled]="disabled"
        (click)="toggle()"
        matTooltip="あとで解くを解除します"
        matTooltipPosition="above"
      >
        <mat-icon class="icon" aria-hidden="false" aria-label="あとで解くを解除">attach_file</mat-icon>
        <span class="button-text">あとで解く</span>
      </button>
    </ng-container>
    <ng-template #bookmarkSpiner>
      <div class="progress-24">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>
