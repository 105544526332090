<app-main-layout-spacer>
  <app-common-id-sign-out-header></app-common-id-sign-out-header>

  <app-wrapper size="lg">
    <app-content-row>
      <app-article-content>
        <app-article-content-head>ただいまメンテナンス中です</app-article-content-head>
        <div class="icon-area">
          <mat-icon class="icon">build</mat-icon>
        </div>
        <div class="maintenance-message">
          メンテナンス中は、入試正解デジタルをご利用いただけません。<br /><br />
          ご不便をおかけして申し訳ございませんが、<br />
          メンテナンス作業が完了するまで今しばらくお待ち下さい。
        </div>
      </app-article-content>
    </app-content-row>
  </app-wrapper>

  <app-footer></app-footer>
</app-main-layout-spacer>
