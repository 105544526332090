import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';

import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

// config
import {
  CATEGORY_DELIMITER,
  DIALOG_ZERO_PADDING_PANEL_CLASS,
  NO_DISPLAY_LEVELS,
  SELECT_CATEGORY_DIALOG_HEIGHT,
  SELECT_CATEGORY_DIALOG_WIDTH,
  SELECT_DETAILED_SEARCH_DIALOG_HEIGHT,
  SELECT_DETAILED_SEARCH_DIALOG_WIDTH,
  SUBJECT_NAMES,
  SubjectId,
  THEME_HISTORY
} from '../../../../resources/config';

// models
import { Level, Subject } from 'src/app/models/common-data';
import { StaticCommonData } from '../../../../models/static-common-data';
import { StaticScienceData } from 'src/app/models/static-science-data';
import { ScienceSearchCondition } from 'src/app/models/science-search-condition';
import { SearchCondition, SearchConditionTypeForAllSubjects } from 'src/app/models/search-condition';
import { StaticHistoryData } from 'src/app/models/static-history-data';
import { HistorySearchCondition } from 'src/app/models/history-search-condition';
import { StaticEnglishData } from 'src/app/models/static-english-data';
import { EnglishBunyaSearchCondition, EnglishSearchCondition } from 'src/app/models/english-search-condition';
import { StaticNationalLanguageData } from 'src/app/models/static-national-language-data';
import { NationalLanguageSearchCondition } from 'src/app/models/national-language-search-condition';
import { CommonSearchCondition } from 'src/app/models/common-search-condition';

// utils
import { Log } from 'src/app/utils/log';
import { Diff } from 'src/app/utils/diff';

// components & services
import {
  CommonIdSelectDetailedSearchConditionDialogComponent,
  SelectDetailedSearchConditionDialogData
} from 'src/app/views/components/common-id/select-detailed-search-condition-dialog/select-detailed-search-condition-dialog.component';
import {
  CommonIdSelectSubjectCategoryDialogComponent,
  SelectSubjectCategoryDialogData,
  SelectSubjectCategoryDialogResult
} from 'src/app/views/components/common-id/select-subject-category-dialog/select-subject-category-dialog.component';
import { CommonIdSearchCategoryDialogSharedService } from 'src/app/services/common-id/common-id-search-category-dialog-shared.service';

// others
import { ReadableDataMapper } from 'src/app/mappers/readable-data-mapper';
import { enter, inOut } from 'src/app/resources/animations';

interface LevelData extends Level {
  checked: boolean;
}

@Component({
  selector: 'app-common-id-search-by-categories-base',
  templateUrl: './search-by-categories-base.component.html',
  styleUrls: ['./search-by-categories-base.component.scss'],
  animations: [enter, inOut]
})
export class CommonIdSearchByCategoriesBaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isPremiumUser: boolean;
  @Input() isSearchSummaryDisplayed: boolean;
  @Input() matchedProblemCount$: Observable<number>;
  @Input() selectedSearchCondition: SearchConditionTypeForAllSubjects;
  @Input() searchButtonDisabled$: Observable<boolean>;
  @Input() problemCountSearching$: Observable<boolean>;
  @Input() staticCommonData$: Observable<StaticCommonData>;

  @Input() staticEnglishData$: Observable<StaticEnglishData>;
  @Input() staticMathData$: Observable<StaticScienceData>;
  @Input() staticNationalLanguageData$: Observable<StaticNationalLanguageData>;
  @Input() staticPhysicsData$: Observable<StaticScienceData>;
  @Input() staticChemistryData$: Observable<StaticScienceData>;
  @Input() staticBiologyData$: Observable<StaticScienceData>;
  @Input() staticJapaneseHistoryData$: Observable<StaticHistoryData>;
  @Input() staticWorldHistoryData$: Observable<StaticHistoryData>;
  @Input() staticGeographyData$: Observable<StaticScienceData>;
  @Input() staticPoliticalEconomyData$: Observable<StaticScienceData>;

  @Output() changeSearchCondition = new EventEmitter<SearchConditionTypeForAllSubjects>();
  @Output() showSearchResultViewClick = new EventEmitter<SearchConditionTypeForAllSubjects>();
  @Output() resetClick = new EventEmitter<string>();
  @Output() changeSubject = new EventEmitter<string>();

  constructor(private dialog: MatDialog, private commonIdSearchCategoryDialogSharedService: CommonIdSearchCategoryDialogSharedService) {}

  private LOG_SOURCE = this.constructor.name;
  private commonSearchConditionEmittedSubsription: Subscription;
  private englishCategoryEmittedSubsription: Subscription;
  private scienceCategoryEmittedSubsription: Subscription;
  private nationalLanguageCategoryEmittedSubsription: Subscription;
  private historyCategoryEmittedSubsription: Subscription;

  // 教科ID
  ENGLISH_SUBJECT_ID: string = SubjectId.ENGLISH;
  MATH_SUBJECT_ID: string = SubjectId.MATH;
  NATIONAL_LANGUAGE_SUBJECT_ID: string = SubjectId.NATIONAL_LANGUAGE;
  PHYSICS_SUBJECT_ID: string = SubjectId.PHYSICS;
  CHEMISTRY_SUBJECT_ID: string = SubjectId.CHEMISTRY;
  BIOLOGY_SUBJECT_ID: string = SubjectId.BIOLOGY;
  JAPANESE_HISTORY_SUBJECT_ID: string = SubjectId.JAPANESE_HISTORY;
  WORLD_HISTORY_SUBJECT_ID: string = SubjectId.WORLD_HISTORY;
  GEOGRAPHY_SUBJECT_ID: string = SubjectId.GEOGRAPHY;
  POLITICAL_ECONOMY_SUBJECT_ID: string = SubjectId.POLITICAL_ECONOMY;

  subjectFormControl = new UntypedFormControl();
  selectableSubjects: Subject[];
  defaultSubjectId: string;
  selectedSubjectId: string | null;

  staticCommonData: StaticCommonData;

  // 教科ごとの検索条件
  englishBunyaSearchCondition: EnglishSearchCondition | undefined;
  englishSearchCondition: SearchCondition<EnglishSearchCondition>;
  mathScienceSearchCondition: ScienceSearchCondition | undefined;
  nationalLanguageCondition: NationalLanguageSearchCondition;
  physicsScienceSearchCondition: ScienceSearchCondition | undefined;
  chemistryScienceSearchCondition: ScienceSearchCondition | undefined;
  biologyScienceSearchCondition: ScienceSearchCondition | undefined;
  japaneseHistoryCondition: HistorySearchCondition | undefined;
  worldHistoryCondition: HistorySearchCondition | undefined;
  geographyScienceSearchCondition: ScienceSearchCondition | undefined;
  politicalEconomyScienceSearchCondition: ScienceSearchCondition | undefined;

  levels: LevelData[];
  selectedLevels: number[] = [];
  isExcludeAnsweredChecked = false;
  commonSearchCondition: CommonSearchCondition;

  // 選択した分野・詳しい条件を表示するため
  selectedDetailedConditionText: string;
  selectedCategoryConditionText: string;

  // 選択した分野・詳しい条件を表示するため - SP時
  selectedConditionTextForSp: string;
  selectedSubjectName: string;
  selectedLevelNames: string;
  selectedExcludeAnsweredText: string;

  ngOnInit() {
    this.setUpSelectableSubjects();
    this.setUpLevels();

    // 詳しい条件または分野の選択がされた場合
    this.watchConditionChange();
  }

  ngOnChanges() {
    if (this.selectedSearchCondition !== undefined) {
      // 教科ID以外の検索条件が設定されていない場合は、検索条件を初期化する
      if (
        Object.keys(this.selectedSearchCondition).length === 2 &&
        this.selectedSearchCondition.subjectId &&
        this.selectedSearchCondition.hasExternalData === true
      ) {
        this.initializeSearchCondition();
      } else {
        this.commonSearchCondition = { subjectId: this.selectedSearchCondition.subjectId, hasExternalData: true };
        this.setSearchCondition(this.selectedSearchCondition);
      }
    }
  }

  ngOnDestroy() {
    // 分野の監視を終了する
    this.commonIdSearchCategoryDialogSharedService.emitEnglishCategory(null);
    this.commonIdSearchCategoryDialogSharedService.emitScienceCategory(null);
    this.commonIdSearchCategoryDialogSharedService.emitNationalLanguageCategory(null);
    this.commonIdSearchCategoryDialogSharedService.emitHistoryCategory(null);
    // 詳しい条件の監視を終了する
    this.commonIdSearchCategoryDialogSharedService.emitSelectedCommonSearchCondition(null);

    if (this.commonSearchConditionEmittedSubsription) this.commonSearchConditionEmittedSubsription.unsubscribe();
    if (this.englishCategoryEmittedSubsription) this.englishCategoryEmittedSubsription.unsubscribe();
    if (this.scienceCategoryEmittedSubsription) this.scienceCategoryEmittedSubsription.unsubscribe();
    if (this.nationalLanguageCategoryEmittedSubsription) this.nationalLanguageCategoryEmittedSubsription.unsubscribe();
    if (this.historyCategoryEmittedSubsription) this.historyCategoryEmittedSubsription.unsubscribe();
  }

  resetForms() {
    this.initializeSearchCondition();
    this.initializeSearchSummaryDisplayed();
    this.resetClick.emit(this.selectedSubjectId);
  }

  onSubjectSelectionChange() {
    // 教科IDを設定して、リセット
    this.selectedSubjectId = this.subjectFormControl.value;
    this.selectedSubjectName = SUBJECT_NAMES[this.selectedSubjectId];
    this.initializeSearchCondition();
    this.initializeSearchSummaryDisplayed();
    this.changeSubject.emit(this.selectedSubjectId);
  }

  onLevelChange() {
    const condition: CommonSearchCondition = { ...this.commonSearchCondition };

    // 難易度を取得
    this.selectedLevels = this.levels.filter(it => it.checked).map(it => it.level);
    if (this.selectedLevels.length === 0) {
      if (condition.levels) delete condition.levels;

      this.selectedLevelNames = '';
    } else {
      condition.levels = this.selectedLevels;

      this.selectedLevelNames = this.selectedLevels
        .map(selectedLevel => {
          return this.getLevelNameWithStarIcon(selectedLevel);
        })
        .join(', ');
    }

    this.commonSearchCondition = condition;
    this.sendSearchConditionToParentComponent(this.commonSearchCondition);
  }

  onExcludeAnsweredChange(checked: boolean) {
    this.isExcludeAnsweredChecked = checked;

    const condition: CommonSearchCondition = { ...this.commonSearchCondition };

    // 未解答のみ表示を取得
    if (this.isExcludeAnsweredChecked) {
      condition.excludeAnswered = this.isExcludeAnsweredChecked;
      this.selectedExcludeAnsweredText = '未解答のみ表示';
    } else {
      delete condition.excludeAnswered;
      this.selectedExcludeAnsweredText = '';
    }

    this.commonSearchCondition = condition;

    this.sendSearchConditionToParentComponent(this.commonSearchCondition);
  }

  openCategoryDialog() {
    if (this.selectedSubjectId === SubjectId.ENGLISH) this.openEnglishCategoryDialog();
    if (this.selectedSubjectId === SubjectId.MATH) this.openMathCategoryDialog();
    if (this.selectedSubjectId === SubjectId.NATIONAL_LANGUAGE) this.openNationalLanguageCategoryDialog();
    if (this.selectedSubjectId === SubjectId.PHYSICS) this.openPhysicsCategoryDialog();
    if (this.selectedSubjectId === SubjectId.CHEMISTRY) this.openChemistryCategoryDialog();
    if (this.selectedSubjectId === SubjectId.BIOLOGY) this.openBiologyCategoryDialog();
    if (this.selectedSubjectId === SubjectId.JAPANESE_HISTORY) this.openJapaneseHistoryCategoryDialog();
    if (this.selectedSubjectId === SubjectId.WORLD_HISTORY) this.openWorldHistoryCategoryDialog();
    if (this.selectedSubjectId === SubjectId.GEOGRAPHY) this.openGeographyCategoryDialog();
    if (this.selectedSubjectId === SubjectId.POLITICAL_ECONOMY) this.openPoliticalEconomyCategoryDialog();
  }

  openDetailedSearchConditionDialog() {
    this.staticCommonData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticCommonData => {
        const config: MatDialogConfig<SelectDetailedSearchConditionDialogData> = {
          height: SELECT_DETAILED_SEARCH_DIALOG_HEIGHT,
          width: SELECT_DETAILED_SEARCH_DIALOG_WIDTH,
          autoFocus: false,
          disableClose: true,
          panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
          restoreFocus: false,
          data: {
            commonSearchCondition: this.commonSearchCondition,
            staticCommonData,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$,
            isPremiumUser: this.isPremiumUser
          }
        };

        this.dialog
          .open(CommonIdSelectDetailedSearchConditionDialogComponent, config)
          .afterClosed()
          .subscribe((result: CommonSearchCondition) => {
            if (!result || !Diff.isDifferentObject(this.commonSearchCondition, result)) return;
            this.commonSearchCondition = { ...result, ...this.commonSearchCondition };
          });
      });
  }

  showSearchResult() {
    // SP時用の選択した条件を表示する欄の編集を行う
    this.setSelectedConditionTextForSp();

    Log.debug(this.LOG_SOURCE, 'この条件で検索結果を表示', this.selectedSearchCondition);
    this.showSearchResultViewClick.emit(this.selectedSearchCondition);
  }

  onChangeConditions() {
    this.initializeSearchSummaryDisplayed();

    const top = 0;
    window.scrollTo({
      top,
      behavior: 'smooth'
    });
  }

  private initializeSubjectSearchCondition() {
    // 選択された分野の表示を初期値に戻す
    this.englishBunyaSearchCondition = undefined;
    this.mathScienceSearchCondition = undefined;
    this.nationalLanguageCondition = undefined;
    this.physicsScienceSearchCondition = undefined;
    this.chemistryScienceSearchCondition = undefined;
    this.biologyScienceSearchCondition = undefined;
    this.japaneseHistoryCondition = undefined;
    this.worldHistoryCondition = undefined;
    this.selectedCategoryConditionText = '';
    this.geographyScienceSearchCondition = undefined;
    this.politicalEconomyScienceSearchCondition = undefined;
  }

  private initializeCommonSearchCondition() {
    // 選択された詳しい条件の表示を初期値に戻す
    this.commonSearchCondition = { subjectId: this.selectedSubjectId, hasExternalData: true };
    this.selectedDetailedConditionText = '';
    this.selectedLevelNames = '';
    this.selectedExcludeAnsweredText = '';
  }

  private getCommonSearchCondition(commonSearchCondition: CommonSearchCondition): CommonSearchCondition {
    const condition: CommonSearchCondition = { ...commonSearchCondition };

    // 難易度を取得
    this.selectedLevels = this.levels.filter(it => it.checked).map(it => it.level);
    if (this.selectedLevels.length === 0) {
      delete condition.levels;
    } else {
      condition.levels = this.selectedLevels;
    }

    // 未解答のみ表示を取得
    if (this.isExcludeAnsweredChecked) {
      condition.excludeAnswered = this.isExcludeAnsweredChecked;
    } else {
      delete condition.excludeAnswered;
    }

    Log.debug(this.LOG_SOURCE, '選択された教科共通条件: ', condition);
    return condition;
  }

  private sendSearchConditionToParentComponent(commonSearchCondition: CommonSearchCondition) {
    const subjectId = commonSearchCondition.subjectId;

    this.selectedSearchCondition = commonSearchCondition;

    // 分野の条件が存在する場合は、共通条件と合わせてemitする
    if (subjectId === SubjectId.ENGLISH && this.englishBunyaSearchCondition) {
      this.selectedSearchCondition.subjectCondition = this.englishBunyaSearchCondition;
    }
    if (subjectId === SubjectId.MATH && this.mathScienceSearchCondition) {
      this.selectedSearchCondition.subjectCondition = this.mathScienceSearchCondition;
    }
    if (subjectId === SubjectId.NATIONAL_LANGUAGE && this.nationalLanguageCondition) {
      this.selectedSearchCondition.subjectCondition = this.nationalLanguageCondition;
    }
    if (subjectId === SubjectId.PHYSICS && this.physicsScienceSearchCondition) {
      this.selectedSearchCondition.subjectCondition = this.physicsScienceSearchCondition;
    }
    if (subjectId === SubjectId.CHEMISTRY && this.chemistryScienceSearchCondition) {
      this.selectedSearchCondition.subjectCondition = this.chemistryScienceSearchCondition;
    }
    if (subjectId === SubjectId.BIOLOGY && this.biologyScienceSearchCondition) {
      this.selectedSearchCondition.subjectCondition = this.biologyScienceSearchCondition;
    }
    if (subjectId === SubjectId.JAPANESE_HISTORY && this.japaneseHistoryCondition) {
      this.selectedSearchCondition.subjectCondition = this.japaneseHistoryCondition;
    }
    if (subjectId === SubjectId.WORLD_HISTORY && this.worldHistoryCondition) {
      this.selectedSearchCondition.subjectCondition = this.worldHistoryCondition;
    }
    if (subjectId === SubjectId.GEOGRAPHY && this.geographyScienceSearchCondition) {
      this.selectedSearchCondition.subjectCondition = this.geographyScienceSearchCondition;
    }
    if (subjectId === SubjectId.POLITICAL_ECONOMY && this.politicalEconomyScienceSearchCondition) {
      this.selectedSearchCondition.subjectCondition = this.politicalEconomyScienceSearchCondition;
    }

    this.changeSearchCondition.emit(this.selectedSearchCondition);
  }

  private setUpLevels() {
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        this.levels = staticCommonData.levels
          .filter(level => !NO_DISPLAY_LEVELS.includes(level.level))
          .sort((a, b) => a.level - b.level)
          .map<LevelData>(level => ({ ...level, checked: false }));
      });
  }

  private setUpSelectableSubjects() {
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        this.selectableSubjects = [...staticCommonData.subjects];
        this.defaultSubjectId = this.selectableSubjects.map(subject => subject)[0].id;
        this.subjectFormControl.setValue(this.defaultSubjectId);
        this.selectedSubjectId = this.defaultSubjectId;
        this.selectedSubjectName = SUBJECT_NAMES[this.selectedSubjectId];

        // 検索条件に初期値の教科IDを設定
        this.selectedSearchCondition = { subjectId: this.defaultSubjectId, hasExternalData: true };
        this.commonSearchCondition = { subjectId: this.defaultSubjectId, hasExternalData: true };
      });
  }

  private initializeSearchSummaryDisplayed() {
    this.isSearchSummaryDisplayed = false;
  }

  private watchConditionChange() {
    // 詳しい条件が選択された場合
    this.commonSearchConditionEmittedSubsription = this.commonIdSearchCategoryDialogSharedService.commonSearchConditionEmitted$
      .pipe(filter(commonSearchConditionEmitted => commonSearchConditionEmitted !== null))
      .subscribe(commonSearchConditionSelection => {
        this.commonSearchCondition = this.getCommonSearchCondition(commonSearchConditionSelection);
        this.sendSearchConditionToParentComponent(this.commonSearchCondition);
        this.setSelectedDetailedConditionText(this.commonSearchCondition);
      });

    // 分野の選択がされた場合
    this.englishCategoryEmittedSubsription = this.commonIdSearchCategoryDialogSharedService.englishCategoryEmitted$
      .pipe(filter(categorySelectionEmitted => categorySelectionEmitted !== null))
      .subscribe(categorySelection => {
        this.commonSearchCondition = this.getCommonSearchCondition(this.commonSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: categorySelection };
        this.changeSearchCondition.emit(this.selectedSearchCondition);
      });

    this.scienceCategoryEmittedSubsription = this.commonIdSearchCategoryDialogSharedService.scienceCategoryEmitted$
      .pipe(filter(categorySelectionEmitted => categorySelectionEmitted !== null))
      .subscribe(categorySelection => {
        this.commonSearchCondition = this.getCommonSearchCondition(this.commonSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: categorySelection };
        this.changeSearchCondition.emit(this.selectedSearchCondition);
      });

    this.nationalLanguageCategoryEmittedSubsription = this.commonIdSearchCategoryDialogSharedService.nationalLanguageCategoryEmitted$
      .pipe(filter(categorySelectionEmitted => categorySelectionEmitted !== null))
      .subscribe(categorySelection => {
        this.commonSearchCondition = this.getCommonSearchCondition(this.commonSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: categorySelection };
        this.changeSearchCondition.emit(this.selectedSearchCondition);
      });

    this.historyCategoryEmittedSubsription = this.commonIdSearchCategoryDialogSharedService.historyCategoryEmitted$
      .pipe(filter(categorySelectionEmitted => categorySelectionEmitted !== null))
      .subscribe(categorySelection => {
        this.commonSearchCondition = this.getCommonSearchCondition(this.commonSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: categorySelection };
        this.changeSearchCondition.emit(this.selectedSearchCondition);
      });
  }

  private setSearchCondition(condition: SearchConditionTypeForAllSubjects) {
    // 科目に検索条件を設定
    this.subjectFormControl.setValue(condition.subjectId);
    this.selectedSubjectId = this.subjectFormControl.value;
    this.selectedSubjectName = SUBJECT_NAMES[this.selectedSubjectId];

    // 難易度に検索条件を設定
    if (condition.levels) {
      this.levels.forEach(level => {
        condition.levels.includes(level.level) ? (level.checked = true) : (level.checked = false);
      });
      this.selectedLevels = condition.levels;

      this.commonSearchCondition = { ...this.commonSearchCondition, levels: condition.levels };

      // SP用に選択された難易度を編集
      this.selectedLevelNames = this.selectedLevels
        .map(selectedLevel => {
          return this.getLevelNameWithStarIcon(selectedLevel);
        })
        .join(', ');
    }

    // 解答済みに検索条件を設定
    if (condition.excludeAnswered) {
      this.isExcludeAnsweredChecked = true;
      this.commonSearchCondition = { ...this.commonSearchCondition, excludeAnswered: true };
      this.selectedExcludeAnsweredText = '未解答のみ表示';
    }

    // 詳しい条件を設定
    if (condition.isThinking) this.commonSearchCondition = { ...this.commonSearchCondition, isThinking: condition.isThinking };
    if (condition.startYear) this.commonSearchCondition = { ...this.commonSearchCondition, startYear: condition.startYear };
    if (condition.endYear) this.commonSearchCondition = { ...this.commonSearchCondition, endYear: condition.endYear };
    if (condition.universityTypeIds)
      this.commonSearchCondition = { ...this.commonSearchCondition, universityTypeIds: condition.universityTypeIds };
    if (condition.areaIds) this.commonSearchCondition = { ...this.commonSearchCondition, areaIds: condition.areaIds };
    if (condition.universityIds) this.commonSearchCondition = { ...this.commonSearchCondition, universityIds: condition.universityIds };
    if (condition.departmentCategoryId)
      this.commonSearchCondition = { ...this.commonSearchCondition, departmentCategoryId: condition.departmentCategoryId };
    this.setSelectedDetailedConditionText(this.commonSearchCondition);

    // 分野を設定
    if (condition.subjectCondition) {
      if (this.selectedSubjectId === SubjectId.ENGLISH) {
        this.englishBunyaSearchCondition = condition.subjectCondition;
        this.setSelectedEnglishConditionText(this.englishBunyaSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: this.englishBunyaSearchCondition };
      }
      if (this.selectedSubjectId === SubjectId.MATH) {
        this.mathScienceSearchCondition = condition.subjectCondition;
        this.setSelectedMathConditionText(this.mathScienceSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: this.mathScienceSearchCondition };
      }
      if (this.selectedSubjectId === SubjectId.NATIONAL_LANGUAGE) {
        this.nationalLanguageCondition = condition.subjectCondition;
        this.setSelectedNationalLanguageConditionText(this.nationalLanguageCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: this.nationalLanguageCondition };
      }
      if (this.selectedSubjectId === SubjectId.PHYSICS) {
        this.physicsScienceSearchCondition = condition.subjectCondition;
        this.setSelectedPhysicsConditionText(this.physicsScienceSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: this.physicsScienceSearchCondition };
      }
      if (this.selectedSubjectId === SubjectId.CHEMISTRY) {
        this.chemistryScienceSearchCondition = condition.subjectCondition;
        this.setSelectedChemistryConditionText(this.chemistryScienceSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: this.chemistryScienceSearchCondition };
      }
      if (this.selectedSubjectId === SubjectId.BIOLOGY) {
        this.biologyScienceSearchCondition = condition.subjectCondition;
        this.setSelectedBiologyConditionText(this.biologyScienceSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: this.biologyScienceSearchCondition };
      }
      if (this.selectedSubjectId === SubjectId.JAPANESE_HISTORY) {
        this.japaneseHistoryCondition = condition.subjectCondition;
        this.setSelectedJapaneseHistoryConditionText(this.japaneseHistoryCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: this.japaneseHistoryCondition };
      }
      if (this.selectedSubjectId === SubjectId.WORLD_HISTORY) {
        this.worldHistoryCondition = condition.subjectCondition;
        this.setSelectedWorldHistoryConditionText(this.worldHistoryCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: this.worldHistoryCondition };
      }
      if (this.selectedSubjectId === SubjectId.GEOGRAPHY) {
        this.geographyScienceSearchCondition = condition.subjectCondition;
        this.setSelectedGeographyConditionText(this.geographyScienceSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: this.geographyScienceSearchCondition };
      }
      if (this.selectedSubjectId === SubjectId.POLITICAL_ECONOMY) {
        this.politicalEconomyScienceSearchCondition = condition.subjectCondition;
        this.setSelectedPoliticalEconomyConditionText(this.politicalEconomyScienceSearchCondition);
        this.selectedSearchCondition = { ...this.commonSearchCondition, subjectCondition: this.politicalEconomyScienceSearchCondition };
      }
    }

    // 検索条件を表示 (SP用)
    this.setSelectedConditionTextForSp();
  }

  private openEnglishCategoryDialog() {
    this.staticEnglishData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticEnglishData => {
        const englishBunyaSearchCondition = this.englishBunyaSearchCondition ? { ...this.englishBunyaSearchCondition } : undefined;
        const commonConfig = this.getConfigForSelectSubjectCategoryDialog();

        const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
          ...commonConfig,
          data: {
            subjectId: this.ENGLISH_SUBJECT_ID,
            staticEnglishData,
            englishSearchCondition: englishBunyaSearchCondition,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$
          }
        };
        this.dialog
          .open(CommonIdSelectSubjectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectSubjectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.englishBunyaSearchCondition, result)) return;
            this.englishBunyaSearchCondition = { ...result };
            this.setSelectedEnglishConditionText(this.englishBunyaSearchCondition);
          });
      });
  }

  private openMathCategoryDialog() {
    this.staticMathData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticMathData => {
        const mathScienceSearchCondition = this.mathScienceSearchCondition ? { ...this.mathScienceSearchCondition } : undefined;
        const commonConfig = this.getConfigForSelectSubjectCategoryDialog();

        const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
          ...commonConfig,
          data: {
            subjectId: this.MATH_SUBJECT_ID,
            staticScienceData: staticMathData,
            scienceSearchCondition: mathScienceSearchCondition,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$
          }
        };
        this.dialog
          .open(CommonIdSelectSubjectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectSubjectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.mathScienceSearchCondition, result)) return;
            this.mathScienceSearchCondition = { ...result };
            this.setSelectedMathConditionText(this.mathScienceSearchCondition);
          });
      });
  }

  private openNationalLanguageCategoryDialog() {
    this.staticNationalLanguageData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticNationalLanguageData => {
        const nationalLanguageCondition = this.nationalLanguageCondition ? { ...this.nationalLanguageCondition } : undefined;
        const commonConfig = this.getConfigForSelectSubjectCategoryDialog();

        const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
          ...commonConfig,
          data: {
            subjectId: this.NATIONAL_LANGUAGE_SUBJECT_ID,
            staticNationalLanguageData,
            nationalLanguageSearchCondition: nationalLanguageCondition,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$
          }
        };
        this.dialog
          .open(CommonIdSelectSubjectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectSubjectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.nationalLanguageCondition, result)) return;
            this.nationalLanguageCondition = { ...result };
            this.setSelectedNationalLanguageConditionText(this.nationalLanguageCondition);
          });
      });
  }

  private openPhysicsCategoryDialog() {
    this.staticPhysicsData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticPhysicsData => {
        const physicsScienceSearchCondition = this.physicsScienceSearchCondition ? { ...this.physicsScienceSearchCondition } : undefined;
        const commonConfig = this.getConfigForSelectSubjectCategoryDialog();

        const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
          ...commonConfig,
          data: {
            subjectId: this.PHYSICS_SUBJECT_ID,
            staticScienceData: staticPhysicsData,
            scienceSearchCondition: physicsScienceSearchCondition,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$
          }
        };
        this.dialog
          .open(CommonIdSelectSubjectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectSubjectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.physicsScienceSearchCondition, result)) return;
            this.physicsScienceSearchCondition = { ...result };
            this.setSelectedPhysicsConditionText(this.physicsScienceSearchCondition);
          });
      });
  }

  private openChemistryCategoryDialog() {
    this.staticChemistryData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticChemistryData => {
        const chemistryScienceSearchCondition = this.chemistryScienceSearchCondition
          ? { ...this.chemistryScienceSearchCondition }
          : undefined;
        const commonConfig = this.getConfigForSelectSubjectCategoryDialog();

        const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
          ...commonConfig,
          data: {
            subjectId: this.CHEMISTRY_SUBJECT_ID,
            staticScienceData: staticChemistryData,
            scienceSearchCondition: chemistryScienceSearchCondition,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$
          }
        };
        this.dialog
          .open(CommonIdSelectSubjectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectSubjectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.chemistryScienceSearchCondition, result)) return;
            this.chemistryScienceSearchCondition = { ...result };
            this.setSelectedChemistryConditionText(this.chemistryScienceSearchCondition);
          });
      });
  }

  private openBiologyCategoryDialog() {
    this.staticBiologyData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticBiologyData => {
        const biologyScienceSearchCondition = this.biologyScienceSearchCondition ? { ...this.biologyScienceSearchCondition } : undefined;
        const commonConfig = this.getConfigForSelectSubjectCategoryDialog();

        const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
          ...commonConfig,
          data: {
            subjectId: this.BIOLOGY_SUBJECT_ID,
            staticScienceData: staticBiologyData,
            scienceSearchCondition: biologyScienceSearchCondition,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$
          }
        };
        this.dialog
          .open(CommonIdSelectSubjectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectSubjectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.biologyScienceSearchCondition, result)) return;
            this.biologyScienceSearchCondition = { ...result };
            this.setSelectedBiologyConditionText(this.biologyScienceSearchCondition);
          });
      });
  }

  openJapaneseHistoryCategoryDialog() {
    this.staticJapaneseHistoryData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticJapaneseHistoryData => {
        const japaneseHistoryHistorySearchCondition = this.japaneseHistoryCondition ? { ...this.japaneseHistoryCondition } : undefined;
        const themeHistoryId = staticJapaneseHistoryData.fields.find(it => it.name === THEME_HISTORY).id;
        const dataWithoutThemeHistory: StaticHistoryData = {
          fields: staticJapaneseHistoryData.fields.filter(it => it.id !== themeHistoryId),
          subjects: staticJapaneseHistoryData.subjects.filter(it => it.id !== themeHistoryId),
          units: staticJapaneseHistoryData.units.filter(it => it.parentFieldId !== themeHistoryId)
        };
        const commonConfig = this.getConfigForSelectSubjectCategoryDialog();

        const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
          ...commonConfig,
          data: {
            subjectId: this.JAPANESE_HISTORY_SUBJECT_ID,
            staticHistoryData: dataWithoutThemeHistory,
            historySearchCondition: japaneseHistoryHistorySearchCondition,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$
          }
        };
        this.dialog
          .open(CommonIdSelectSubjectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectSubjectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.japaneseHistoryCondition, result)) return;
            this.japaneseHistoryCondition = { ...result };
            this.setSelectedJapaneseHistoryConditionText(this.japaneseHistoryCondition);
          });
      });
  }

  openWorldHistoryCategoryDialog() {
    this.staticWorldHistoryData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticWorldHistoryData => {
        const worldHistoryHistorySearchCondition = this.worldHistoryCondition ? { ...this.worldHistoryCondition } : undefined;
        const themeHistoryId = staticWorldHistoryData.fields.find(it => it.name === THEME_HISTORY).id;
        const dataWithoutThemeHistory: StaticHistoryData = {
          fields: staticWorldHistoryData.fields.filter(it => it.id !== themeHistoryId),
          subjects: staticWorldHistoryData.subjects.filter(it => it.id !== themeHistoryId),
          units: staticWorldHistoryData.units.filter(it => it.parentFieldId !== themeHistoryId)
        };
        const commonConfig = this.getConfigForSelectSubjectCategoryDialog();

        const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
          ...commonConfig,
          data: {
            subjectId: this.WORLD_HISTORY_SUBJECT_ID,
            staticHistoryData: dataWithoutThemeHistory,
            historySearchCondition: worldHistoryHistorySearchCondition,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$
          }
        };
        this.dialog
          .open(CommonIdSelectSubjectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectSubjectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.worldHistoryCondition, result)) return;
            this.worldHistoryCondition = { ...result };
            this.setSelectedWorldHistoryConditionText(this.worldHistoryCondition);
          });
      });
  }

  private openGeographyCategoryDialog() {
    this.staticGeographyData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticGeographyData => {
        const geographyScienceSearchCondition = this.geographyScienceSearchCondition
          ? { ...this.geographyScienceSearchCondition }
          : undefined;
        const commonConfig = this.getConfigForSelectSubjectCategoryDialog();

        const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
          ...commonConfig,
          data: {
            subjectId: this.GEOGRAPHY_SUBJECT_ID,
            staticScienceData: staticGeographyData,
            scienceSearchCondition: geographyScienceSearchCondition,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$
          }
        };
        this.dialog
          .open(CommonIdSelectSubjectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectSubjectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.geographyScienceSearchCondition, result)) return;
            this.geographyScienceSearchCondition = { ...result };
            this.setSelectedGeographyConditionText(this.geographyScienceSearchCondition);
          });
      });
  }

  private openPoliticalEconomyCategoryDialog() {
    this.staticPoliticalEconomyData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticPoliticalEconomyData => {
        const politicalEconomyScienceSearchCondition = this.politicalEconomyScienceSearchCondition
          ? { ...this.politicalEconomyScienceSearchCondition }
          : undefined;
        const commonConfig = this.getConfigForSelectSubjectCategoryDialog();

        const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
          ...commonConfig,
          data: {
            subjectId: this.POLITICAL_ECONOMY_SUBJECT_ID,
            staticScienceData: staticPoliticalEconomyData,
            scienceSearchCondition: politicalEconomyScienceSearchCondition,
            matchedProblemCount$: this.matchedProblemCount$,
            problemCountSearching$: this.problemCountSearching$
          }
        };
        this.dialog
          .open(CommonIdSelectSubjectCategoryDialogComponent, config)
          .afterClosed()
          .subscribe((result: SelectSubjectCategoryDialogResult) => {
            if (!result || !Diff.isDifferentObject(this.politicalEconomyScienceSearchCondition, result)) return;
            this.politicalEconomyScienceSearchCondition = { ...result };
            this.setSelectedPoliticalEconomyConditionText(this.politicalEconomyScienceSearchCondition);
          });
      });
  }

  private getConfigForSelectSubjectCategoryDialog(): MatDialogConfig<SelectSubjectCategoryDialogData> {
    const config: MatDialogConfig<SelectSubjectCategoryDialogData> = {
      height: SELECT_CATEGORY_DIALOG_HEIGHT,
      width: SELECT_CATEGORY_DIALOG_WIDTH,
      autoFocus: false,
      disableClose: true,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      restoreFocus: false
    };

    return config;
  }

  private setSelectedDetailedConditionText(commonSearchCondition: CommonSearchCondition) {
    combineLatest([this.staticCommonData$])
      .pipe(take(1))
      .subscribe(([staticCommonData]) => {
        const thinking = commonSearchCondition.isThinking ? '思考力問題のみ' : '';
        const yearRange = commonSearchCondition.startYear ? `${commonSearchCondition.startYear} 〜 ${commonSearchCondition.endYear}` : '';
        const universityTypes = commonSearchCondition.universityTypeIds
          ? commonSearchCondition.universityTypeIds
              .map(univTypeId => ReadableDataMapper.getUniversityTypeName(univTypeId, staticCommonData))
              .join(', ')
          : '';
        const areas = commonSearchCondition.areaIds
          ? commonSearchCondition.areaIds.map(areaId => ReadableDataMapper.getAreaName(areaId, staticCommonData)).join(', ')
          : '';
        const universities = commonSearchCondition.universityIds
          ? commonSearchCondition.universityIds.map(univId => ReadableDataMapper.getUniversityName(univId, staticCommonData)).join(', ')
          : '';
        const departmentCategory = commonSearchCondition.departmentCategoryId
          ? ReadableDataMapper.getDepartmentCategoryName(commonSearchCondition.departmentCategoryId, staticCommonData)
          : '';

        const selectedDetailedConditionArray = [];
        if (thinking !== '') selectedDetailedConditionArray.push(thinking);
        if (yearRange !== '') selectedDetailedConditionArray.push(yearRange);
        if (universityTypes !== '') selectedDetailedConditionArray.push(universityTypes);
        if (areas !== '') selectedDetailedConditionArray.push(areas);
        if (universities !== '') selectedDetailedConditionArray.push(universities);
        if (departmentCategory !== '') selectedDetailedConditionArray.push(departmentCategory);

        this.selectedDetailedConditionText = selectedDetailedConditionArray.join(', ');
      });
  }

  private setSelectedEnglishConditionText(scienceCondition: EnglishBunyaSearchCondition) {
    this.staticEnglishData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticEnglishData => {
        this.selectedCategoryConditionText = this.setSelectedEnglishBunyaConditionText(scienceCondition, staticEnglishData);
      });
  }

  private setSelectedMathConditionText(scienceCondition: ScienceSearchCondition) {
    this.staticMathData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticMathData => {
        this.selectedCategoryConditionText = this.setSelectedScienceConditionText(scienceCondition, staticMathData);
      });
  }

  private setSelectedNationalLanguageConditionText(nationalLanguageCondition: NationalLanguageSearchCondition) {
    this.staticNationalLanguageData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticNationalLanguageData => {
        this.selectedCategoryConditionText = this.setSelectedNationalLanguageText(nationalLanguageCondition, staticNationalLanguageData);
      });
  }

  private setSelectedPhysicsConditionText(scienceCondition: ScienceSearchCondition) {
    this.staticPhysicsData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticPhysicsData => {
        this.selectedCategoryConditionText = this.setSelectedScienceConditionText(scienceCondition, staticPhysicsData);
      });
  }

  private setSelectedChemistryConditionText(scienceCondition: ScienceSearchCondition) {
    this.staticChemistryData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticChemistryData => {
        this.selectedCategoryConditionText = this.setSelectedScienceConditionText(scienceCondition, staticChemistryData);
      });
  }

  private setSelectedBiologyConditionText(scienceCondition: ScienceSearchCondition) {
    this.staticBiologyData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticBiologyData => {
        this.selectedCategoryConditionText = this.setSelectedScienceConditionText(scienceCondition, staticBiologyData);
      });
  }

  private setSelectedJapaneseHistoryConditionText(historyCondition: HistorySearchCondition) {
    this.staticJapaneseHistoryData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticJapaneseHistoryData => {
        this.selectedCategoryConditionText = this.setSelectedHistoryConditionText(historyCondition, staticJapaneseHistoryData);
      });
  }

  private setSelectedWorldHistoryConditionText(historyCondition: HistorySearchCondition) {
    this.staticWorldHistoryData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticWorldHistoryData => {
        this.selectedCategoryConditionText = this.setSelectedHistoryConditionText(historyCondition, staticWorldHistoryData);
      });
  }

  private setSelectedGeographyConditionText(scienceCondition: ScienceSearchCondition) {
    this.staticGeographyData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticGeographyData => {
        this.selectedCategoryConditionText = this.setSelectedScienceConditionText(scienceCondition, staticGeographyData);
      });
  }

  private setSelectedPoliticalEconomyConditionText(scienceCondition: ScienceSearchCondition) {
    this.staticPoliticalEconomyData$
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(staticPoliticalEconomyData => {
        this.selectedCategoryConditionText = this.setSelectedScienceConditionText(scienceCondition, staticPoliticalEconomyData);
      });
  }

  private setSelectedEnglishBunyaConditionText(
    englishBunyaCondition: EnglishBunyaSearchCondition,
    staticEnglishData: StaticEnglishData
  ): string {
    const selectedCategoriesSorted = this.getSelectedCategoriesSorted(englishBunyaCondition);

    // 選択した分野を格納した配列の、分野名を取得する (下の階層から順に)
    let result = '';
    if (selectedCategoriesSorted.length > 0) {
      result = selectedCategoriesSorted
        .map(sortedText => {
          const splitIds = sortedText.split(CATEGORY_DELIMITER);
          const [subjectId, fieldId, unitId] = [splitIds[0], splitIds[1], splitIds[2]];

          if (unitId !== undefined) {
            return ReadableDataMapper.getEnglishBunyaUnitName(unitId, staticEnglishData);
          } else {
            if (fieldId !== undefined) {
              return ReadableDataMapper.getEnglishBunyaFieldName(fieldId, staticEnglishData);
            } else {
              return ReadableDataMapper.getEnglishBunyaSubjectName(subjectId, staticEnglishData);
            }
          }
        })
        .join(', ');
    }

    return result;
  }

  private setSelectedScienceConditionText(scienceCondition: ScienceSearchCondition, staticScienceData: StaticScienceData): string {
    const selectedCategoriesSorted = this.getSelectedCategoriesSorted(scienceCondition);

    // 選択した分野を格納した配列の、分野名を取得する (下の階層から順に)
    let result = '';
    if (selectedCategoriesSorted.length > 0) {
      result = selectedCategoriesSorted
        .map(sortedText => {
          const splitIds = sortedText.split(CATEGORY_DELIMITER);
          const [subjectId, fieldId, unitId] = [splitIds[0], splitIds[1], splitIds[2]];

          if (unitId !== undefined) {
            return ReadableDataMapper.getScienceUnitName(unitId, staticScienceData);
          } else {
            if (fieldId !== undefined) {
              return ReadableDataMapper.getScienceFieldName(fieldId, staticScienceData);
            } else {
              return ReadableDataMapper.getScienceSubjectName(subjectId, staticScienceData);
            }
          }
        })
        .join(', ');
    }

    return result;
  }

  private setSelectedHistoryConditionText(historyCondition: HistorySearchCondition, staticHistoryData: StaticHistoryData): string {
    const selectedCategoriesSorted = this.getSelectedCategoriesSorted(historyCondition);

    // 選択した分野を格納した配列の、分野名を取得する (下の階層から順に)
    let result = '';
    if (selectedCategoriesSorted.length > 0) {
      result = selectedCategoriesSorted
        .map(sortedText => {
          const splitIds = sortedText.split(CATEGORY_DELIMITER);
          const [subjectId, unitId] = [splitIds[0], splitIds[2]];

          if (unitId !== undefined) {
            return ReadableDataMapper.getHistoryUnitName(unitId, staticHistoryData);
          } else {
            return ReadableDataMapper.getHistorySubjectName(subjectId, staticHistoryData);
          }
        })
        .join(', ');
    }

    return result;
  }

  private setSelectedNationalLanguageText(
    nationalLanguageCondition: NationalLanguageSearchCondition,
    staticNationalLanguageData: StaticNationalLanguageData
  ): string {
    const selectedCategoriesSorted = this.getSelectedCategoriesSorted(nationalLanguageCondition);

    // 選択した分野を格納した配列の、分野名を取得する (下の階層から順に)
    let result = '';
    if (selectedCategoriesSorted.length > 0) {
      result = selectedCategoriesSorted
        .map(sortedText => {
          const splitIds = sortedText.split(CATEGORY_DELIMITER);
          const [subjectId, unitId] = [splitIds[0], splitIds[2]];

          if (unitId !== undefined) {
            return ReadableDataMapper.getNationalLanguageUnitName(unitId, staticNationalLanguageData);
          } else {
            return ReadableDataMapper.getNationalLanguageSubjectName(subjectId, staticNationalLanguageData);
          }
        })
        .join(', ');
    }

    return result;
  }

  private getSelectedCategoriesSorted(
    condition: EnglishBunyaSearchCondition | ScienceSearchCondition | HistorySearchCondition | NationalLanguageSearchCondition
  ): string[] {
    const selectedCategories: string[] = [];

    // サブジェクトにデータが存在する場合は、選択した分野を格納する配列に追加する
    if (condition.subjectIds && condition.subjectIds.length > 0) {
      condition.subjectIds.map(subjectId => {
        selectedCategories.push(subjectId);
      });
    }

    // フィールドにデータが存在する場合は、選択した分野を格納する配列に追加する
    if (condition.subjectAndFieldIds && condition.subjectAndFieldIds.length > 0) {
      condition.subjectAndFieldIds.map(subjectAndFieldId => {
        selectedCategories.push(subjectAndFieldId);
      });
    }

    // ユニットにデータが存在する場合は、選択した分野を格納する配列に追加する
    if (condition.categories && condition.categories.length > 0) {
      condition.categories.map(category => {
        selectedCategories.push(category);
      });
    }

    // 選択した分野を格納した配列を昇順に並べる
    const sortedTextArray = selectedCategories.sort((a, b) => (a < b ? -1 : 1));

    return sortedTextArray;
  }

  private initializeSearchCondition() {
    // 難易度の初期化
    this.levels.forEach(level => (level.checked = false));
    // 未解答のみ表示の初期化
    this.isExcludeAnsweredChecked = false;

    // 分野の検索条件を初期化する
    this.initializeSubjectSearchCondition();

    // 詳しい検索条件を初期化する
    this.initializeCommonSearchCondition();
    this.selectedSearchCondition = this.commonSearchCondition;
  }

  private getLevelNameWithStarIcon(levelId: number): string {
    switch (levelId) {
      case 1:
        return '★基礎';
      case 2:
        return '★★標準';
      case 3:
        return '★★★やや難';
      case 4:
        return '★★★★難';
      default:
        return null;
    }
  }

  private setSelectedConditionTextForSp() {
    const subject = `科目 : ${this.selectedSubjectName}`;
    const level = this.selectedLevelNames === '' || this.selectedLevelNames === undefined ? '' : `難易度 : ${this.selectedLevelNames}`;
    const excludeAnswered =
      this.selectedExcludeAnsweredText === '' || this.selectedExcludeAnsweredText === undefined
        ? ''
        : `解答済み : ${this.selectedExcludeAnsweredText}`;
    const category =
      this.selectedCategoryConditionText === '' || this.selectedCategoryConditionText === undefined
        ? ''
        : `分野 : ${this.selectedCategoryConditionText}`;
    const detailedCondition =
      this.selectedDetailedConditionText === '' || this.selectedDetailedConditionText === undefined
        ? ''
        : `詳しい検索条件 : ${this.selectedDetailedConditionText}`;

    const editedArray: string[] = [];
    editedArray.push(subject);
    if (level !== '') editedArray.push(level);
    if (excludeAnswered !== '') editedArray.push(excludeAnswered);
    if (category !== '') editedArray.push(category);
    if (detailedCondition !== '') editedArray.push(detailedCondition);

    this.selectedConditionTextForSp = editedArray.join(' / ');
  }
}
