<!-- ▼ 印刷時のみ表示 ここから -->
<div class="playlist-problem-detail-show-print-only-wrapper" data-print-only *ngIf="(problem$ | async)?.examPaper">
  <app-problem-detail-frame-for-print
    [menuType]="menuType$ | async"
    [readableScienceProblems]="readableScienceProblems$ | async"
    [readableEnglishProblems]="readableEnglishProblems$ | async"
    [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
    [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
    [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
    [sciencePlaylistTheme]="sciencePlaylistTheme$ | async"
    [englishPlaylistTheme]="englishPlaylistTheme$ | async"
    [nationalLanguagePlaylistTheme]="nationalLanguagePlaylistTheme$ | async"
    [japaneseHistoryPlaylistTheme]="japaneseHistoryPlaylistTheme$ | async"
    [worldHistoryPlaylistTheme]="worldHistoryPlaylistTheme$ | async"
    [playlist]="playlist$ | async"
    [theme]="theme$ | async"
    [paperMode]="paperMode$ | async"
    [trialMode]="trialMode$ | async"
    [subjectId]="subjectId$ | async"
    [isBToC]="false"
    [signedInUser]="signedInUser$ | async"
  ></app-problem-detail-frame-for-print>
</div>
<!-- △ 印刷時のみ表示 ここまで -->
<!-- ▼ 印刷時は消す ここから -->
<div data-no-print>
  <div *ngIf="!isPreconditionError">
    <div [@fadeInOut]="(searching$ | async) === false && (loadingReadableProblems$ | async) === false">
      <!-- ▼ 前へ/次へボタン 試験単位では消す -->
      <app-content-row *ngIf="navigation$ | async">
        <app-wrapper size="xl">
          <app-problem-detail-back-forward-button
            [isFirstProblem]="isFirstProblem$ | async"
            [isLastProblem]="isLastProblem$ | async"
            [navigation]="navigation$ | async"
            [parentComponent]="parentComponent"
            [playlistProblems]="playListProblems$ | async"
            [problemId]="problemId"
            [themeId]="themeId$ | async"
            [playlistId]="playlistId"
            (menuTypeChangeHandler)="changeMenuType($event)"
            (setLoadingReadableProblems)="setLoadingReadableProblems(true)"
          ></app-problem-detail-back-forward-button>
        </app-wrapper>
      </app-content-row>
      <!-- △ 前へ/次へボタン 試験単位では消す -->

      <app-content-row>
        <app-wrapper size="xl" isFitWithSmallScreen="true">
          <app-card-content [showSeparator]="false">
            <app-card-content-head>
              <mat-icon class="card-content-head-icon" aria-hidden="true">import_contacts</mat-icon>
              {{ (playlist$ | async) ? (playlist$ | async).name : '' }}
            </app-card-content-head>
          </app-card-content>
        </app-wrapper>
      </app-content-row>

      <app-content-row>
        <app-wrapper size="xl" isFitWithSmallScreen="true">
          <app-card-content [showSeparator]="false">
            <app-card-content-head>
              <div class="playlist-items-title" *ngIf="theme$ | async as theme">{{ theme.order }}.{{ theme.name }}</div>
            </app-card-content-head>
            <app-wrapper size="xl" isFitWithSmallScreen="true">
              <div class="-mt-4 md:-mt-6 -mb-4 md:mb-auto">
                <app-content-row *ngIf="daimonMode$ | async">
                  <!-- 理系科目の概要 -->
                  <app-card-content [showSeparator]="false" *ngIf="sciencePlaylistTheme$ | async as playlistTheme">
                    <div class="table-frame">
                      <app-science-problems
                        [readableSciencePlaylistProblems]="playlistTheme.problems"
                        [problemButtonDisabled]="true"
                        [verticalAlignTop]="false"
                        (addBookmarkClick)="addBookmark($event)"
                        (deleteBookmarkClick)="deleteBookmark($event)"
                        [initializedBookmark$]="this.initializedBookmark$"
                        [bookmarkProblems]="this.bookmarkSubjectProblems"
                        [canBookmarkSpiner]="true"
                        [isTrial]="this.user.isTrial"
                      ></app-science-problems>
                      <!-- △ 大問単位 理系のテーブル -->
                    </div>
                  </app-card-content>
                  <!-- 英語の概要 -->
                  <app-card-content [showSeparator]="false" *ngIf="englishPlaylistTheme$ | async as playlistTheme">
                    <div class="table-frame">
                      <app-english-problems
                        [readableEnglishPlaylistProblems]="playlistTheme.problems"
                        [problemButtonDisabled]="true"
                        (addBookmarkClick)="addBookmark($event)"
                        (deleteBookmarkClick)="deleteBookmark($event)"
                        [initializedBookmark$]="this.initializedBookmark$"
                        [bookmarkProblems]="this.bookmarkSubjectProblems"
                        [canBookmarkSpiner]="true"
                        [isTrial]="this.user.isTrial"
                      ></app-english-problems>
                    </div>
                  </app-card-content>

                  <!-- 国語の概要 -->
                  <app-card-content [showSeparator]="false" *ngIf="nationalLanguagePlaylistTheme$ | async as playlistTheme">
                    <div class="table-frame">
                      <app-national-language-problems
                        [readableNationalLanguagePlaylistProblems]="playlistTheme.problems"
                        [problemButtonDisabled]="true"
                        (addBookmarkClick)="addBookmark($event)"
                        (deleteBookmarkClick)="deleteBookmark($event)"
                        [initializedBookmark$]="this.initializedBookmark$"
                        [bookmarkProblems]="this.bookmarkSubjectProblems"
                        [canBookmarkSpiner]="true"
                        [isTrial]="this.user.isTrial"
                      ></app-national-language-problems>
                    </div>
                  </app-card-content>

                  <!-- 日本史の概要 -->
                  <app-card-content [showSeparator]="false" *ngIf="japaneseHistoryPlaylistTheme$ | async as playlistTheme">
                    <div class="table-frame">
                      <app-history-problems
                        [readableHistoryPlaylistProblems]="playlistTheme.problems"
                        [problemButtonDisabled]="true"
                        (addBookmarkClick)="addBookmark($event)"
                        (deleteBookmarkClick)="deleteBookmark($event)"
                        [initializedBookmark$]="this.initializedBookmark$"
                        [bookmarkProblems]="this.bookmarkSubjectProblems"
                        [canBookmarkSpiner]="true"
                        [isTrial]="this.user.isTrial"
                      ></app-history-problems>
                    </div>
                  </app-card-content>

                  <!-- 世界史の概要 -->
                  <app-card-content [showSeparator]="false" *ngIf="worldHistoryPlaylistTheme$ | async as playlistTheme">
                    <div class="table-frame">
                      <app-history-problems
                        [readableHistoryPlaylistProblems]="playlistTheme.problems"
                        [problemButtonDisabled]="true"
                        (addBookmarkClick)="addBookmark($event)"
                        (deleteBookmarkClick)="deleteBookmark($event)"
                        [initializedBookmark$]="this.initializedBookmark$"
                        [bookmarkProblems]="this.bookmarkSubjectProblems"
                        [canBookmarkSpiner]="true"
                        [isTrial]="this.user.isTrial"
                      ></app-history-problems>
                    </div>
                  </app-card-content>
                </app-content-row>
              </div>
            </app-wrapper>
          </app-card-content>
        </app-wrapper>
      </app-content-row>

      <app-content-row *ngIf="(problem$ | async)?.hasExternalData; else noExternalData">
        <app-wrapper size="xl" isFitWithSmallScreen="true">
          <!-- ▼ テスト表示領域 -->
          <app-problem-detail-frame
            [readableScienceProblems]="readableScienceProblems$ | async"
            [readableEnglishProblems]="readableEnglishProblems$ | async"
            [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
            [readableJapaneseHistoryProblems]="readableJapaneseHistoryProblems$ | async"
            [readableWorldHistoryProblems]="readableWorldHistoryProblems$ | async"
            [paperMode]="paperMode$ | async"
            [trialMode]="trialMode$ | async"
            [hidePaperProblemBtn]="hidePaperProblemBtn"
            [subjectId]="subjectId$ | async"
            (menuTypeChangeHandler)="changeMenuType($event)"
            (printClickHandler)="showPrint()"
          ></app-problem-detail-frame>
          <!-- △ テスト表示領域 -->
        </app-wrapper>
      </app-content-row>
    </div>

    <div class="progress-48" [@fadeInOut]="(searching$ | async) || (loadingReadableProblems$ | async)">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>
  </div>

  <ng-template #noExternalData>
    <div class="text-center text-nega" *ngIf="(searching$ | async) === false && (loadingReadableProblems$ | async) === false">
      <app-problem-detail-no-external-data></app-problem-detail-no-external-data>
    </div>
  </ng-template>
</div>
<!-- △ 印刷時は消す ここまで -->
