<div #pageTop>
  <app-wrapper size="xl" isFitWithSmallScreen="true" @enter *ngIf="firstPageInitialized$ | async; else loadingProgress">
    <app-content-row>
      <app-card-content [showSeparator]="false">
        <app-card-content-head>
          <mat-icon class="card-content-head-icon">bookmarks</mat-icon>
          お気に入り管理
        </app-card-content-head>
        <app-wrapper size="fluid">
          <app-content-row>
            <div class="flex flex-wrap gap-y-2">
              <div class="mr-4 -mb-6">
                <mat-form-field appearance="outline" class="w-32 md:w-auto">
                  <mat-label>科目</mat-label>
                  <mat-select [(value)]="selectedSubjectId" (selectionChange)="onChangeSubject()">
                    <mat-option *ngFor="let subject of subjects$ | async" [value]="subject.id">
                      {{ subject.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="mr-4 -mb-6">
                <mat-form-field appearance="outline" class="w-40 md:w-auto">
                  <mat-label>並び順</mat-label>
                  <mat-select [(value)]="selectedSortType.type" (selectionChange)="onChangeSortType()">
                    <mat-option *ngFor="let sortType of selectableSortTypes" [value]="sortType.type">{{ sortType.displayName }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <span class="mx-auto"></span>
            </div>
          </app-content-row>
        </app-wrapper>

        <app-wrapper size="fluid" [isFitWithSmallScreen]="totalBookmarkCount !== 0">
          <app-content-row>
            <app-content-no-data *ngIf="totalBookmarkCount === 0">
              <span>お気に入りされている問題がありません</span>
            </app-content-no-data>
            <ng-container *ngIf="totalBookmarkCount != null && totalBookmarkCount !== 0">
              <div>
                <!-- 英語の検索結果 -->
                <div class="" *ngIf="selectedSubjectId === ENGLISH_SUBJECT_ID">
                  <div [@fadeInOut]="(bookmarkSearching$ | async) === false">
                    <app-english-problems
                      [readableEnglishProblems]="readableEnglishProblems$ | async"
                      (problemClick)="showEnglishProblemDetail($event)"
                      (addBookmarkClick)="addBookmark($event)"
                      (deleteBookmarkClick)="deleteBookmarkDialog($event)"
                      [initializedBookmark$]="this.initializedBookmark$"
                      [bookmarkedAllFlag]="this.bookmarkedAllFlag"
                      [isTrial]="this.user.isTrial"
                    ></app-english-problems>
                  </div>
                </div>
                <!-- 数学の検索結果 -->
                <div class="" *ngIf="selectedSubjectId === MATH_SUBJECT_ID">
                  <div [@fadeInOut]="(bookmarkSearching$ | async) === false">
                    <app-science-problems
                      [readableScienceProblems]="readableMathProblems$ | async"
                      (problemClick)="showScienceProblemDetail($event)"
                      (addBookmarkClick)="addBookmark($event)"
                      (deleteBookmarkClick)="deleteBookmarkDialog($event)"
                      [initializedBookmark$]="this.initializedBookmark$"
                      [bookmarkedAllFlag]="this.bookmarkedAllFlag"
                      [isTrial]="this.user.isTrial"
                    ></app-science-problems>
                  </div>
                </div>

                <!-- 国語の検索結果 -->
                <div class="" *ngIf="selectedSubjectId === NATIONAL_LANGUAGE_SUBJECT_ID">
                  <div [@fadeInOut]="(bookmarkSearching$ | async) === false">
                    <app-national-language-problems
                      [readableNationalLanguageProblems]="readableNationalLanguageProblems$ | async"
                      (problemClick)="showNationalLanguageProblemDetail($event)"
                      (addBookmarkClick)="addBookmark($event)"
                      (deleteBookmarkClick)="deleteBookmarkDialog($event)"
                      [initializedBookmark$]="this.initializedBookmark$"
                      [bookmarkedAllFlag]="this.bookmarkedAllFlag"
                      [isTrial]="this.user.isTrial"
                    ></app-national-language-problems>
                  </div>
                </div>

                <!-- 物理の検索結果 -->
                <div class="" *ngIf="selectedSubjectId === PHYSICS_SUBJECT_ID">
                  <div [@fadeInOut]="(bookmarkSearching$ | async) === false">
                    <app-science-problems
                      [readableScienceProblems]="readablePhysicsProblems$ | async"
                      (problemClick)="showScienceProblemDetail($event)"
                      (addBookmarkClick)="addBookmark($event)"
                      (deleteBookmarkClick)="deleteBookmarkDialog($event)"
                      [initializedBookmark$]="this.initializedBookmark$"
                      [bookmarkedAllFlag]="this.bookmarkedAllFlag"
                      [isTrial]="this.user.isTrial"
                    ></app-science-problems>
                  </div>
                </div>

                <!-- 化学の検索結果 -->
                <div class="" *ngIf="selectedSubjectId === CHEMISTRY_SUBJECT_ID">
                  <div [@fadeInOut]="(bookmarkSearching$ | async) === false">
                    <app-science-problems
                      [readableScienceProblems]="readableChemistryProblems$ | async"
                      (problemClick)="showScienceProblemDetail($event)"
                      (addBookmarkClick)="addBookmark($event)"
                      (deleteBookmarkClick)="deleteBookmarkDialog($event)"
                      [initializedBookmark$]="this.initializedBookmark$"
                      [bookmarkedAllFlag]="this.bookmarkedAllFlag"
                      [isTrial]="this.user.isTrial"
                    ></app-science-problems>
                  </div>
                </div>

                <!-- 生物の検索結果 -->
                <div class="" *ngIf="selectedSubjectId === BIOLOGY_SUBJECT_ID">
                  <div [@fadeInOut]="(bookmarkSearching$ | async) === false">
                    <app-science-problems
                      [readableScienceProblems]="readableBiologyProblems$ | async"
                      (problemClick)="showScienceProblemDetail($event)"
                      (addBookmarkClick)="addBookmark($event)"
                      (deleteBookmarkClick)="deleteBookmarkDialog($event)"
                      [initializedBookmark$]="this.initializedBookmark$"
                      [bookmarkedAllFlag]="this.bookmarkedAllFlag"
                      [isTrial]="this.user.isTrial"
                    ></app-science-problems>
                  </div>
                </div>

                <!-- 日本史の検索結果 -->
                <div class="" *ngIf="selectedSubjectId === JAPANESE_HISTORY_SUBJECT_ID">
                  <div [@fadeInOut]="(bookmarkSearching$ | async) === false">
                    <app-history-problems
                      [readableHistoryProblems]="readableJapaneseHistoryProblems$ | async"
                      (problemClick)="showJapaneseHistoryProblemDetail($event)"
                      (addBookmarkClick)="addBookmark($event)"
                      (deleteBookmarkClick)="deleteBookmarkDialog($event)"
                      [initializedBookmark$]="this.initializedBookmark$"
                      [bookmarkedAllFlag]="this.bookmarkedAllFlag"
                      [isTrial]="this.user.isTrial"
                    ></app-history-problems>
                  </div>
                </div>

                <!-- 世界史の検索結果 -->
                <div class="" *ngIf="selectedSubjectId === WORLD_HISTORY_SUBJECT_ID">
                  <div [@fadeInOut]="(bookmarkSearching$ | async) === false">
                    <app-history-problems
                      [readableHistoryProblems]="readableWorldHistoryProblems$ | async"
                      (problemClick)="showWorldHistoryProblemDetail($event)"
                      (addBookmarkClick)="addBookmark($event)"
                      (deleteBookmarkClick)="deleteBookmarkDialog($event)"
                      [initializedBookmark$]="this.initializedBookmark$"
                      [bookmarkedAllFlag]="this.bookmarkedAllFlag"
                      [isTrial]="this.user.isTrial"
                    ></app-history-problems>
                  </div>
                </div>

                <!-- 地理の検索結果 -->
                <div class="" *ngIf="selectedSubjectId === GEOGRAPHY_SUBJECT_ID">
                  <div [@fadeInOut]="(bookmarkSearching$ | async) === false">
                    <app-science-problems
                      [readableScienceProblems]="readableGeographyProblems$ | async"
                      (problemClick)="showScienceProblemDetail($event)"
                      (addBookmarkClick)="addBookmark($event)"
                      (deleteBookmarkClick)="deleteBookmarkDialog($event)"
                      [initializedBookmark$]="this.initializedBookmark$"
                      [bookmarkedAllFlag]="this.bookmarkedAllFlag"
                      [isTrial]="this.user.isTrial"
                    ></app-science-problems>
                  </div>
                </div>

                <!-- 政治・経済の検索結果 -->
                <div class="" *ngIf="selectedSubjectId === POLITICAL_ECONOMY_SUBJECT_ID">
                  <div [@fadeInOut]="(bookmarkSearching$ | async) === false">
                    <app-science-problems
                      [readableScienceProblems]="readablePoliticalEconomyProblems$ | async"
                      (problemClick)="showScienceProblemDetail($event)"
                      (addBookmarkClick)="addBookmark($event)"
                      (deleteBookmarkClick)="deleteBookmarkDialog($event)"
                      [initializedBookmark$]="this.initializedBookmark$"
                      [bookmarkedAllFlag]="this.bookmarkedAllFlag"
                      [isTrial]="this.user.isTrial"
                    ></app-science-problems>
                  </div>
                </div>

                <!-- 問題取得中のプログレス -->
                <div class="progress-48" [@fadeInOut]="bookmarkSearching$ | async">
                  <mat-spinner [diameter]="48"></mat-spinner>
                </div>

                <div class="mt-4">
                  <app-paginator
                    [currentPage]="currentPage"
                    [totalItemCount]="totalBookmarkCount"
                    [pageSize]="paginationPageSize"
                    [windowSize]="paginationWindowSize"
                    (pageChange)="selectPage($event)"
                  ></app-paginator>
                </div>
              </div>
            </ng-container>
          </app-content-row>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
  </app-wrapper>

  <ng-template #loadingProgress>
    <div class="progress-48">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>
  </ng-template>
</div>
