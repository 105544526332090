<app-wrapper size="lg" isFitWithSmallScreen="true">
  <div @enter *ngIf="(loaded$ | async) === true">
    <app-content-row>
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon class="card-content-head-icon">view_module</mat-icon>
          おすすめ問題セット
        </app-card-content-head>
        <app-wrapper size="fluid">
          <div>
            <div class="" *ngFor="let subject of playlistSubjects$ | async">
              <div class="mt-6">
                <app-header-with-listmark>{{ subject.subjectName }}</app-header-with-listmark>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-3 gap-4 my-6">
                <button
                  class="playlist-card"
                  [ngClass]="playlist.pending ? 'pending-card' : 'enable-pointer'"
                  *ngFor="let playlist of subject.playlists"
                  (click)="showPlaylistDetail(playlist)"
                  matRipple
                  [matRippleDisabled]="playlist.pending"
                  [attr.tabindex]="playlist.pending ? '-1' : '0'"
                >
                  <div class="playlist-name" role="heading">
                    <mat-icon class="icon" aria-hidden="true">import_contacts</mat-icon>
                    {{ playlist.name }}
                  </div>
                  <div class="playlist-description" ellipsis>{{ playlist.description }}</div>
                  <div class="playlist-info">
                    <div>
                      <div>公開日: {{ playlist.publishedDate || '-' }}</div>
                      <div>問題数: {{ playlist.problemCount || '-' }}</div>
                      <div>選定者: {{ playlist.creator || '-' }}</div>
                    </div>
                    <div>
                      <div>更新日: {{ playlist.contentUpdatedDate || '-' }}</div>
                      <div>年　度: {{ playlist.yearRange || '-' }}</div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
  </div>
</app-wrapper>

<div class="progress-48" *ngIf="(loaded$ | async) === false">
  <mat-spinner [diameter]="48"></mat-spinner>
</div>
