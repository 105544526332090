import { Component, ViewChildren, QueryList, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { navigate, setTitle, setBrowserTitle } from 'src/app/actions/core.actions';
import * as InformationActions from 'src/app/actions/information.actions';
import { RootState } from 'src/app/reducers';
import * as InformationSelectors from 'src/app/selectors/information.selectors';

import { Information, InformationResponse } from 'src/app/models/information';
import { Dates } from 'src/app/utils/dates';
import { enter } from 'src/app/resources/animations';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  animations: [enter]
})
export class InformationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('informations')
  informations: QueryList<any>;

  informations$: Observable<Information[]>;

  private title = 'お知らせ一覧';
  private lastAccessedInformationToS;

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUp();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    window.scrollTo(0, 0);
    // ページ遷移する際に state の newInformation と localStorage の最終アクセス日時を更新
    this.store.dispatch(InformationActions.updateNewInformation({ newInformation: false }));
    localStorage.setItem('lastAccessedInformationToS', Dates.now());
  }

  gotoDetail(informationId) {
    this.store.dispatch(navigate({ url: RoutingPathResolver.resolveInformationDetail(informationId) }));
  }

  private setUp() {
    this.informations$ = this.store.select(InformationSelectors.getInformations).pipe(
      filter(it => it != null),
      take(1),
      map(result => result.response.map(info => this.getInformation(info)))
    );
    this.lastAccessedInformationToS = localStorage.getItem('lastAccessedInformationToS');
  }

  private getInformation(information: InformationResponse): Information {
    const date = Dates.simple4YmdStringFromIso(information.publishedAt);
    const isNew =
      this.lastAccessedInformationToS !== null
        ? Dates.jstDateStringFromIso(information.publishedAt) > this.lastAccessedInformationToS
        : true;
    return {
      id: information.id,
      isNew,
      categoryName: information.categoryName,
      date,
      title: information.title,
      body: information.content,
      importantFlag: information.importantFlag
    } as Information;
  }
}
