<app-wrapper size="fluid" isFitWithSmallScreen="true">
  <div @enter *ngIf="usersLoaded$ | async; else loadingProgress">
    <app-content-row>
      <app-breadcrumbs
        (breadcrumbsClick)="breadcrumbsClickHandler($event)"
        [breadcrumbs]="[
          { key: 'admin', label: 'Admin機能' },
          { key: 'admin/c-accounts', label: 'アカウント管理 - ユーザー' }
        ]"
      ></app-breadcrumbs>
    </app-content-row>
    <app-content-row>
      <app-card-content [showSeparator]="true">
        <app-card-content-head>
          <mat-icon class="card-content-head-icon">group</mat-icon>
          アカウント管理
        </app-card-content-head>
        <app-wrapper size="fluid">
          <app-content-row>
            <div class="flex flex-wrap gap-4">
              <app-button-toggle
                labelIfTrue="メールアドレスを非表示にする"
                labelIfFalse="メールアドレスを表示する"
                (toggleClick)="toggleDisplayEmail($event)"
              ></app-button-toggle>
              <span class="mx-auto"></span>
            </div>
          </app-content-row>

          <!-- 絞り込み機能 -->
          <app-content-row>
            <div class="">
              <form
                class="grid grid-cols-2 md:flex md:flex-wrap gap-2 row-sort-area"
                [formGroup]="filterForm"
                (keydown.enter)="$event.preventDefault()"
              >
                <mat-form-field floatLabel="always" appearance="outline" class="text-sm md:w-40">
                  <mat-label>アカウント有効無効</mat-label>
                  <mat-select formControlName="accountStatus" (selectionChange)="accountStatusChange()">
                    <mat-option *ngFor="let accountStatus of accountStatusFilterTypes" [value]="accountStatus.id">{{
                      accountStatus.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline" class="text-sm md:w-40">
                  <mat-label>課金ステータス</mat-label>
                  <mat-select formControlName="purchaseStatus" (selectionChange)="purchaseStatusChange()">
                    <mat-option *ngFor="let purchaseStatus of purchaseStatusFilterTypes" [value]="purchaseStatus.id">{{
                      purchaseStatus.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="always" appearance="outline" class="text-sm md:w-40 md:mr-auto">
                  <mat-label>料金プラン</mat-label>
                  <mat-select formControlName="purchasePlan" (selectionChange)="purchasePlanChange()">
                    <mat-option *ngFor="let purchasePlan of purchasePlanFilterTypes" [value]="purchasePlan.id">{{
                      purchasePlan.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div class="col-span-2 md:col-auto flex flex-wrap gap-2 items-start">
                  <mat-form-field class="text-sm w-full sm:w-64 md:w-48" floatLabel="always" appearance="outline">
                    <mat-label>メールアドレス</mat-label>
                    <input matInput [placeholder]="emailPlaceholder" formControlName="email" />
                  </mat-form-field>
                  <mat-form-field class="text-sm w-full sm:w-64 md:w-48" floatLabel="always" appearance="outline">
                    <mat-label>User ID</mat-label>
                    <input matInput [placeholder]="userIdPlaceholder" formControlName="userId" />
                  </mat-form-field>

                  <!-- 検索ボタンとリセットボタンが必要になれば、この部分のコメントを外す
                  <button mat-flat-button color="primary" class="button-search">
                    <mat-icon class="icon">search</mat-icon>
                  </button>
                  <button mat-stroked-button color="primary" (click)="resetFilter()">条件をリセット</button>
                  -->
                </div>
              </form>
            </div>
          </app-content-row>
        </app-wrapper>
        <app-wrapper size="fluid" isFitWithSmallScreen="true">
          <app-content-row>
            <mat-toolbar>
              <mat-toolbar-row class="tableFooter">
                <div class="numberOfUsers">絞り込み件数：{{ totalFilteredAccounts }}</div>
                <div class="numberOfUsers">全件数：{{ totalAccounts }}</div>
                <mat-paginator
                  #paginator
                  (page)="pageChangeEvent($event)"
                  [length]="totalFilteredAccounts"
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex"
                  [pageSizeOptions]="pageSizeOptions"
                  showFirstLastButtons
                >
                </mat-paginator>
              </mat-toolbar-row>
            </mat-toolbar>

            <app-common-id-accounts-table [dataSource]="accounts" [displayedColumns]="displayedColumns" [isSingleSearch]="false">
            </app-common-id-accounts-table>

            <mat-toolbar>
              <mat-toolbar-row class="tableFooter">
                <div class="numberOfUsers">絞り込み件数：{{ totalFilteredAccounts }}</div>
                <div class="numberOfUsers">全件数：{{ totalAccounts }}</div>
                <mat-paginator
                  #paginator
                  (page)="pageChangeEvent($event)"
                  [length]="totalFilteredAccounts"
                  [pageSize]="pageSize"
                  [pageIndex]="pageIndex"
                  [pageSizeOptions]="pageSizeOptions"
                  showFirstLastButtons
                >
                </mat-paginator>
              </mat-toolbar-row>
            </mat-toolbar>
          </app-content-row>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
  </div>
</app-wrapper>

<ng-template #loadingProgress>
  <div class="progress-48">
    <mat-spinner [diameter]="48"></mat-spinner>
  </div>
</ng-template>
