<app-main-layout-spacer>
  <app-common-id-sign-out-header></app-common-id-sign-out-header>
  <div class="sing-in-callback-container">
    <div class="sing-in-callback-content">
      <div class="progress">
        <mat-spinner [diameter]="48"></mat-spinner>
      </div>
      <div class="maintenance-message">
        ログイン処理を行っています...
      </div>
    </div>
  </div>
</app-main-layout-spacer>
