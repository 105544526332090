import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, take, shareReplay } from 'rxjs/operators';

import { RootState } from '../../../reducers';
import { dispatchInfoMessage, openWindow, navigate, setTitle, setBrowserTitle } from 'src/app/actions/core.actions';
import * as UniversitySearchActions from 'src/app/actions/university-search.actions';
import * as FindUniversityActions from 'src/app/actions/find-university.actions';
import { getCurrentDateTime } from 'src/app/actions/current-date-time.actions';
import * as CommonIdVisitedPaperActions from 'src/app/actions/common-id/common-id-visited-paper.actions';
import * as CommonIdVisitedPaperSelectors from 'src/app/selectors/common-id/common-id-visited-paper.selectors';
import * as StaticDataSelectors from '../../../selectors/static-data.selectors';
import { getUniversities } from 'src/app/selectors/university-search.selectors';
import { getFindUniversityResult } from 'src/app/selectors/find-university.selectors';
import { getCommonIdVisitedPapers } from 'src/app/selectors/common-id/common-id-visited-paper.selectors';
import { getCurrentDateTime as getCurrentDateTimeSelector } from 'src/app/selectors/current-date-time.selectors';

import { RoutingPathResolver } from '../../../app-routing-path-resolver';

import { UniversitySearchCondition, UniversitySearchType, UniversityCondition } from 'src/app/models/university-search-condition';
import { StaticCommonData } from '../../../models/static-common-data';
import { User } from 'src/app/models/user';
import { FindUniversityPapersResponse } from 'src/app/models/find-university-papers-response';
import { Department } from 'src/app/models/find-university-papers-result';
import { FindUniversityRequest } from 'src/app/models/find-university-request';
import { CurrentDateTime } from 'src/app/models/current-date-time';

import { Log } from 'src/app/utils/log';
import { SubjectUtil } from 'src/app/utils/subject-util';
import { CommonIdSubjectUtil } from 'src/app/utils/common-id/common-id-subject-util';
import { COMMON_ID_PREFECTURES, COMMON_TEST_PREFECTURE_NAME, COMMON_TEST_UNIVERSITY_ID } from 'src/app/resources/common-id-config';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';
import { UniversitySearchQueryParamsMapper } from 'src/app/mappers/university-search-query-params-mapper';
import { SearchUnivProblemCondition } from 'src/app/models/search-univ-problem-condition';
import { GoToPaperDetailParams, UniversityDetails, Year } from '../../../models/search-univ-interfaces';

@Component({
  selector: 'app-search-univ-detail',
  templateUrl: './search-univ-detail.component.html',
  styleUrls: ['./search-univ-detail.component.scss']
})
export class SearchUnivDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(private store: Store<RootState>, private activatedRoute: ActivatedRoute) {}

  private LOG_SOURCE = this.constructor.name;
  private title = '大学詳細';

  currentDateTime$: Observable<CurrentDateTime>;

  staticCommonData$: Observable<StaticCommonData>;
  staticCommonData: StaticCommonData;
  signedInUser$: Observable<User>;

  universities$: Observable<FindUniversityPapersResponse[]>;
  searchResults: Department[];

  years: Year[];
  selectedYear: string;
  defaultYear: string;

  universityId: string;
  universityDetails$: Observable<UniversityDetails>;

  commonIdVisitedPapers$: Observable<string[]>;
  commonIdVisitedPapers: string[];

  isLoadingFinished$: Observable<boolean>;

  yearFromQueryParams: string;

  ngOnInit() {
    this.store.dispatch(setBrowserTitle({ subTitle: this.title }));

    this.isLoadingFinished$ = of(false);
    this.setUpCurrentDateTime();
    this.setUpUser();
    this.setUpStaticCommonData();
    this.setUpCommonIdVisitedPapers();

    // クエリパラメータの大学IDと年度を取得
    this.universityId = this.activatedRoute.snapshot.paramMap.get('universityId');
    Log.debug(this.LOG_SOURCE, `大学詳細画面　クエリパラメータ 大学ID : `, this.universityId);
    this.yearFromQueryParams = this.activatedRoute.snapshot.queryParams.year;
    Log.debug(this.LOG_SOURCE, `大学詳細画面　クエリパラメータ 年度 : `, this.yearFromQueryParams);

    // 大学詳細と利用可能な年度を取得
    this.setUpUniversityAndYears();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    this.store.dispatch(UniversitySearchActions.initializeUniversityPapersState());
    this.store.dispatch(FindUniversityActions.initializeFindUniversityState());
  }

  onYearsChange(selectedYear: string) {
    // 年度の値を取得
    this.selectedYear = selectedYear;

    this.initializeSearchResults();
    this.setUpUniversityDetails();
  }

  goToPaperDetail(event: GoToPaperDetailParams) {
    Log.debug(this.LOG_SOURCE, '問題詳細を開きます: ', event.paperId);

    const condition: SearchUnivProblemCondition = {
      subjectId: event.paperId.slice(6, 8),
      year: Number('20' + event.paperId.slice(0, 2)),
      universityId: 'D' + event.paperId.slice(3, 6)
    };

    const problemId = event.paperId + event.daimonIds[0];
    const url = RoutingPathResolver.resolveProblemDetail(problemId);
    const queryParams = UniversitySearchQueryParamsMapper.encodeSearchUnivProblemCondition(condition);

    this.store.dispatch(openWindow({ url, extras: { queryParams: { ...queryParams, mode: 'paper' } } }));

    // 開いた問題詳細のIDを格納する
    this.addVisitedPaper(event.paperId);
  }

  private findUniversity(condition: UniversitySearchCondition<UniversityCondition>, page: number) {
    this.store.dispatch(UniversitySearchActions.initializeUniversityPapersState());
    this.store.dispatch(UniversitySearchActions.findUniversityPapersByUniversityIds({ condition, page }));

    this.universities$ = this.store.select(getUniversities).pipe(filter(it => it != null));

    this.universities$.pipe(take(1)).subscribe(universities => {
      // staticCommonDataとcommonIdVisitedPapersの取得
      this.staticCommonData$.subscribe(staticCommonData => {
        this.staticCommonData = staticCommonData;
      });

      this.commonIdVisitedPapers$.subscribe(commonIdVisitedPapers => {
        this.commonIdVisitedPapers = commonIdVisitedPapers;
      });

      // 大学検索結果に、教科名称、閲覧済みフラグを追加
      this.searchResults = universities[0].departments.map(department => {
        return {
          ...department,
          papers: department.papers.map(paper => {
            return {
              ...paper,
              paperIds: paper.paperIds.map(paperId => {
                const subjectId = paperId.paperId.substring(6, 8);
                const subjectName =
                  this.universityId === COMMON_TEST_UNIVERSITY_ID
                    ? CommonIdSubjectUtil.getSubjectNameForCommonTest(paperId.paperId, this.staticCommonData.subjects)
                    : SubjectUtil.getName(this.staticCommonData.subjects, subjectId);

                return {
                  id: paperId.paperId,
                  daimonIds: paperId.daimonIds,
                  subjectName,
                  isVisited:
                    this.commonIdVisitedPapers &&
                    this.commonIdVisitedPapers.find(commonIdVisitedPaper => commonIdVisitedPaper === paperId.paperId)
                      ? true
                      : false
                };
              })
            };
          })
        };
      });
    });
  }

  private setUpUniversityAndYears() {
    this.store.dispatch(FindUniversityActions.initializeFindUniversityState());
    const request: FindUniversityRequest = {
      universityId: this.universityId
    };
    this.store.dispatch(FindUniversityActions.findUniversity({ request }));
    this.store
      .select(getFindUniversityResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        if (result.success) {
          // 大学詳細を設定する
          this.universityDetails$ = combineLatest([this.staticCommonData$]).pipe(
            map(([staticCommonData]) => {
              const universityName = staticCommonData.universities.find(staticData => staticData.id === this.universityId).name;
              const universityType = result.response.universityType;
              const universityTypeName = staticCommonData.universityTypes.find(staticData => staticData.id === universityType).name;
              const prefecture = COMMON_ID_PREFECTURES.find(pref => pref.id === result.response.prefectureId);
              const prefectureName = prefecture ? prefecture.name : COMMON_TEST_PREFECTURE_NAME;

              const title = `${this.title} - ${universityName}`;
              this.store.dispatch(setBrowserTitle({ subTitle: title }));
              setTimeout(() => this.store.dispatch(setTitle({ title })));

              return {
                universityName,
                universityType,
                universityTypeName,
                prefectureName
              };
            }),
            take(1)
          );

          if (result.response.years.length > 0) {
            // 年度を並び替え
            const yearsSorted = [...result.response.years];
            yearsSorted.sort();
            yearsSorted.reverse();

            this.years = yearsSorted.map(year => {
              return {
                label: year,
                value: year
              };
            });

            // 存在しない年度の場合は無視する
            const yearValues = this.years.map(year => year.value);
            if (!yearValues.includes(this.yearFromQueryParams)) {
              this.store.dispatch(
                navigate({ url: RoutingPathResolver.resolveSearchUnivDetail(this.universityId), extras: { replaceUrl: true } })
              );
              this.yearFromQueryParams = null;
            }

            // クエリパラメタに年度の情報がある際は、その年度を初期設定とし、ない場合は最新年度を初期設定とする
            this.defaultYear = this.years[0].value;
            if (this.yearFromQueryParams) {
              this.selectedYear = this.yearFromQueryParams;
            } else {
              this.selectedYear = this.defaultYear;
            }

            // ローディングアイコンを非表示にする
            this.isLoadingFinished$ = of(true);

            // 選択された年度の大学詳細を検索する
            this.setUpUniversityDetails();
          } else {
            // 年度情報が空の場合は大学検索画面へリダイレクト
            this.store.dispatch(navigate({ url: RoutingPathResolver.resolveSearchUniv(), extras: { replaceUrl: true } }));
          }
        } else {
          Log.warn(
            this.LOG_SOURCE,
            `大学詳細の取得(FindUniversity) error: err.code: ${result.error ? result.error.code : 'none'}`,
            result.error
          );
          this.store.dispatch(
            dispatchInfoMessage({
              message: result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました'
            })
          );
        }
      });
  }

  private addVisitedPaper(paperId: string) {
    // 開いた問題詳細のIDを格納する
    this.store
      .select(CommonIdVisitedPaperSelectors.getCommonIdVisitedPapers)
      .pipe(take(1))
      .subscribe(paperIds => {
        const _paperIds: string[] = paperIds != null ? paperIds.concat() : [];
        if (!_paperIds.includes(paperId)) {
          _paperIds.push(paperId);
          this.store.dispatch(CommonIdVisitedPaperActions.commonIdAddVisitedPaper({ paperIds: _paperIds }));
        }

        // 開いた問題詳細のフラグを更新する
        this.searchResults = this.searchResults.map(department => {
          return {
            ...department,
            papers: department.papers.map(paper => {
              return {
                ...paper,
                paperIds: paper.paperIds.map(paperIdfromSearchResults => {
                  return {
                    ...paperIdfromSearchResults,
                    isVisited: _paperIds && _paperIds.find(_paperId => _paperId === paperIdfromSearchResults.id) ? true : false
                  };
                })
              };
            })
          };
        });
      });
  }

  private setUpUniversityDetails() {
    Log.debug(this.LOG_SOURCE, '選択された過去問の年度: ', this.selectedYear);
    const universityIds = [this.universityId];

    const condition: UniversitySearchCondition<UniversityCondition> = {
      type: UniversitySearchType.DETAIL,
      universityCondition: { universityIds },
      year: Number(this.selectedYear)
    };

    this.findUniversity(condition, 1);
  }

  private setUpCurrentDateTime() {
    this.store.dispatch(getCurrentDateTime());
    this.currentDateTime$ = this.store.select(getCurrentDateTimeSelector).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpUser() {
    this.signedInUser$ = this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null),
      shareReplay(1)
    );
  }

  private setUpStaticCommonData() {
    this.staticCommonData$ = this.store.select(StaticDataSelectors.getStaticCommonData).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private setUpCommonIdVisitedPapers() {
    this.commonIdVisitedPapers$ = this.store.select(getCommonIdVisitedPapers).pipe(
      filter(it => it != null),
      shareReplay(1)
    );
  }

  private initializeSearchResults() {
    this.searchResults = [];
  }
}
