<app-wrapper size="lg" isFitWithSmallScreen="true">
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon" aria-hidden="true">help_outline</mat-icon>
        マニュアル / 使い方
        <div class="card-content-head-util">
          <app-link-with-arrow (linkClick)="contactClickHandler()">お問合せはこちら</app-link-with-arrow>
        </div>
      </app-card-content-head>
      <app-wrapper size="fluid">
        <app-content-row>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
            <a class="manual-item" matRipple target="_blank" [href]="userManualUrl">
              <div class="manual-item-title">
                <mat-icon class="icon" aria-hidden="true">picture_as_pdf</mat-icon>
                基本操作マニュアル
              </div>
              <div class="manual-item-description">入試正解デジタル for Schoolの使い方に関する説明はこちらからご確認ください。</div>
            </a>
            <a class="manual-item" matRipple target="_blank" [href]="adminManualUrl">
              <div class="manual-item-title">
                <mat-icon class="icon" aria-hidden="true">picture_as_pdf</mat-icon>
                団体管理ユーザー操作マニュアル
              </div>
              <div class="manual-item-description">
                ユーザーの登録とユーザーの割当、プランの詳細に関する説明はこちらからご確認ください。
              </div>
            </a>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>

  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">help_outline</mat-icon>
        よくあるご質問
        <div class="card-content-head-util">
          <app-link-with-arrow (linkClick)="contactClickHandler()">お問合せはこちら</app-link-with-arrow>
        </div>
      </app-card-content-head>
      <app-wrapper size="fluid">
        <div id="faq-1">
          <app-content-row>
            <app-faq-list-item
              [isExpanded]="faqs['faq-1']"
              questionTitle="ログインしたら「あなたのアカウントは契約プランに登録されていないため、この機能は使えません」と表示されました。どうしたら良いでしょうか。"
            >
              ご契約いただいているプランにアカウントの割り当てが行われておりません。管理者の先生（団体管理ユーザー）に、契約中のプランにお使いのアカウントの割り当て（メンバーの割当）を行っていただくようご連絡ください。<br />
              割り当てが完了しますと、アカウントのメールアドレスにメールが自動で送信されますのでご確認ください。<br />
              ※団体管理ユーザーの「契約プランへのメンバーの割当」の詳しい方法は「ヘルプ」の右上にある、「団体管理ユーザー操作マニュアル」P.7
              をご確認ください。<br />
              ※プランの割り当てを行っているにもかかわらず、表示されない等不明な場合はこちらからご連絡ください。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-2">
          <app-content-row>
            <app-faq-list-item [isExpanded]="faqs['faq-2']" questionTitle="一般ユーザーと団体管理ユーザーはどう違うのですか。">
              団体管理ユーザーはアカウントの追加や編集、契約プランへの割り当てが可能です。<br />
              団体管理ユーザーは学校につき１人以上を必ずご登録いただいておりますが、複数人登録することが可能です。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-3">
          <app-content-row>
            <app-faq-list-item [isExpanded]="faqs['faq-3']" questionTitle="団体管理ユーザーになるにはどうしたら良いですか。">
              契約時にご登録いただきました、団体管理ユーザーの先生に「プラン・アカウント管理」の「所属メンバー一覧画面」より、権限を付与いただくよう申し出てください。<br />
              ※「権限の変更」については「ヘルプ」の右上にある、「団体管理ユーザー操作マニュアル」P.6 をご確認ください。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-4">
          <app-content-row>
            <app-faq-list-item
              [isExpanded]="faqs['faq-4']"
              questionTitle="1つのアカウントで、複数の端末から同時にサインインして利用できますか。"
            >
              本サービスでは、同時に複数の端末からはサインインできない仕組みとなっております。
              異なる端末に同一アカウントでサインインした場合、後からサインインした端末が有効となり、先にサインインしていた端末では強制的にサインアウトされますのでご注意ください。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-5">
          <app-content-row>
            <app-faq-list-item [isExpanded]="faqs['faq-5']" questionTitle="プリントを印刷して配布したいのですが可能ですか。">
              可能です。ただし、ユーザーが所属する団体等における授業・講義において、生徒もしくは受講者に印刷配布する場合に限ります。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-6">
          <app-content-row>
            <app-faq-list-item [isExpanded]="faqs['faq-6']" questionTitle="Wordデータのダウンロードができません。">
              Microsoft Edgeにおいて、Wordファイルをダウンロードしようとすると、エラーになる場合がございます。 Microsoft
              Edgeのブラウザを開いた状態で、右上［…］、［設定］を順にクリックし「ダウンロード」の［Officeファイルをブラウザーで開く］をオフにしていただき、あらためてダウンロードをお試しください。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-7">
          <app-content-row>
            <app-faq-list-item
              [isExpanded]="faqs['faq-7']"
              questionTitle="検索した問題の詳細を見ようとしたら「都合により、この問題はご覧になれません」と表示されてしまいました。"
            >
              恐れ入りますが、著作権等の都合により、一部表示できない問題がございます。書籍「全国大学入試問題正解」をお持ちの場合は、書籍でご確認いただけますと幸いです。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-8">
          <app-content-row>
            <app-faq-list-item [isExpanded]="faqs['faq-8']" questionTitle="パスワードを忘れてしまいログインできません。">
              パスワードを忘れてしまった場合は、ご自身でパスワードの再設定をしてください。トップページの「パスワードをお忘れの方はこちら」からご設定いただけます。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-9">
          <app-content-row>
            <app-faq-list-item
              [isExpanded]="faqs['faq-9']"
              questionTitle="オフライン時（インターネットに接続していない場合）にも利用できますか。"
            >
              本サービスはWebブラウザでインターネットに接続してご利用いただくサービスですので、オフライン環境でご利用いただくことはできません。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-10">
          <app-content-row>
            <app-faq-list-item
              [isExpanded]="faqs['faq-10']"
              questionTitle="契約中のサービスの利用期限について知るにはどうしたら良いですか。"
            >
              プラン割り当て時に送信される「【旺文社入試正解デジタル for School】へのユーザー登録の招待」という<br />
              件名のメールに「利用期間」が表示されておりますのでご確認ください。<br />
              なおアカウント管理者のみ表示される「プラン・アカウント管理」の「契約状況」で確認いただくことが可能です。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-11">
          <app-content-row>
            <app-faq-list-item
              [isExpanded]="faqs['faq-11']"
              questionTitle="利用アカウントを増やしたい、もしくはプランの追加をご相談したいのですが、どこから連絡すれば良いですか。"
            >
              利用アカウントの申し込みは、アカウント管理者のみが表示される「アカウント管理画面」からお申込みいただくことが可能です。<br />
              「アカウント管理画面」の「プラン更新・変更のご相談」ボタンをタップし、フォームからご連絡ください。担当から改めてご連絡させていただきます。
            </app-faq-list-item>
          </app-content-row>
        </div>
        <div id="faq-12">
          <app-content-row>
            <app-faq-list-item [isExpanded]="faqs['faq-12']" questionTitle="システムの動作環境を教えてください。">
              当社が推奨している環境は、OSはWindows/Mac/Chrome OS、ブラウザはGoogle Chrome 最新版、Microsoft Edge
              最新版に対応しています。<br />
              <br />
              ※上記推奨環境以外では、正常に機能しない、または正常に表示されない場合があります。また、上記推奨環境であっても、今後のOS・ブラウザ等のバージョンアップなどに伴い、正常に動作しなくなる場合もございますのであらかじめご了承ください。<br />
              ※パソコンからのみご利用いただけます。スマートフォン・タブレット端末には対応しておりません。
            </app-faq-list-item>
          </app-content-row>
        </div>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>
