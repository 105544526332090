<app-wrapper size="lg" *ngIf="!isRegisteringSuccess && !isRegisteringError">
  <div>
    <app-content-row>
      <app-button-location-back (backClick)="goToMembers()">戻る</app-button-location-back>
    </app-content-row>
  </div>
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">person_add</mat-icon>
        所属ユーザ一一括登録
      </app-card-content-head>
      <app-wrapper size="fluid">
        <app-content-row>
          <section class="flex flex-col gap-6">
            <div class="flex items-center justify-between">
              <app-header-with-listmark>登録方法</app-header-with-listmark>
              <app-link-with-arrow (linkClick)="openManualPdf()">ユーザー一括登録に関するマニュアル</app-link-with-arrow>
            </div>
            <div class="border-2 border-border-line rounded-md p-6 flex gap-4 flex-col md:flex-row">
              <p class="mb-0 md:mr-auto">
                CSVによるメンバーの一括登録を行います。<br />
                「雛形CSV」をダウンロードし、CSVフォーマットを変えずにメンバーの情報を入力してください。
              </p>
              <div class="shrink-0">
                <button mat-flat-button color="primary" (click)="openTemplateCsv()">
                  <mat-icon>save_alt</mat-icon>
                  雛形CSVのダウンロード
                </button>
              </div>
            </div>
            <app-alert-box type="tips" [closeable]="false">
              <ul class="list-disc pl-5 text-sm">
                <li>CSVの一括登録は、新しいユーザーの登録のみできます。登録済みのユーザー情報の変更は個別に編集してください。</li>
                <li>雛形CSVのヘッダー（1行目）は変更しないでください。</li>
                <li>一つのメールアドレスに紐づけられる先生は一人のみです。</li>
                <li>「権限」の項目では、一般ユーザーならば0、団体管理ユーザーならば1を半角で入力してください。</li>
                <li>既に登録済みのメールアドレスは登録ができません。</li>
                <li>同時に複数のユーザーがアップロードすると、エラーが起きる場合がありますのでご注意ください。</li>
              </ul>
            </app-alert-box>
          </section>
        </app-content-row>
        <app-content-row>
          <section class="flex flex-col gap-6">
            <!--ファイルのアップロード-->
            <div class="flex items-center justify-between">
              <app-header-with-listmark>CSVアップロード</app-header-with-listmark>
              <app-link-with-arrow (linkClick)="openManualPdf()">ユーザー一括登録に関するマニュアル</app-link-with-arrow>
            </div>
            <div class="border-2 border-border-line rounded-md p-6 flex gap-6 flex-col">
              <p class="mb-0">
                CSVをアップロードします。１つのファイルで登録できる
                <span class="text-primary font-bold">上限は100件</span>です。超える場合はCSVファイルを分けて登録してください。
              </p>
              <div *ngIf="!isReadyToRegister">
                <label class="bg-gray-50 p-6 pr-12 rounded-md block cursor-pointer relative">
                  <input
                    #fileInput
                    type="file"
                    accept="text/csv"
                    (click)="onClick($event)"
                    (change)="onFileUpload($event)"
                    [disabled]="isCsvUploading"
                  />
                  <div class="absolute right-4 top-1/2 -translate-y-1/2">
                    <mat-spinner [diameter]="24" *ngIf="isCsvUploading"></mat-spinner>
                  </div>
                </label>
              </div>

              <!--エラーメッセージ-->
              <div class="flex flex-col gap-6" *ngIf="csvErrors.length > 0 && isCsvUploading === false">
                <p class="text-danger mb-0">{{ csvErrors.length }}件のエラーがあります。CSVを修正し再登録してください。</p>
                <div class="flex flex-col gap-2">
                  <div *ngFor="let csvError of csvErrors">
                    <app-alert-box
                      *ngIf="csvError.item !== 'csv'"
                      [closeable]="false"
                      class="[&_.alert-box]:p-2 [&_.alert-box]:gap-2"
                      type="danger"
                      >行番号 {{ csvError.recordIndex }} : {{ csvError.message }}。</app-alert-box
                    >
                    <app-alert-box
                      *ngIf="csvError.item === 'csv'"
                      [closeable]="false"
                      class="[&_.alert-box]:p-2 [&_.alert-box]:gap-2"
                      type="danger"
                      >{{ csvError.message }}。</app-alert-box
                    >
                  </div>
                </div>
              </div>

              <!--エラーがない場合に表示されるエリア-->
              <div class="border-2 border-border-line rounded-md p-6 flex gap-6 flex-col" *ngIf="isReadyToRegister">
                <!--CSVファイル変更エリア-->
                <div class="bg-success-50 text-success-700 flex gap-4 p-6 rounded-md">
                  <mat-icon class="text-success-500">check_circle</mat-icon>
                  <p class="mb-0 grow">{{ fileName }}</p>
                  <button matTooltip="CSVを変更する" matTooltipPosition="above" (click)="resetFile()">
                    <mat-icon class="align-bottom text-success-500">delete</mat-icon>
                  </button>
                </div>

                <!--登録メンバー確認エリア-->
                <p class="mb-0">
                  <span class="text-primary font-bold">まだ登録は完了してません。</span><br />
                  以下の内容を確認し、問題がなければ<span class="text-primary font-bold">「登録」</span
                  >を押し一括登録を開始します。修正が必要な場合は再度CSVをアップロードしてください。
                </p>
                <hr class="border-t-2 border-dashed border-border-line" />

                <div class="flex items-center">
                  <div class="hidden md:block md:min-w-[120px]"></div>
                  <div class="font-bold flex flex-wrap gap-2 mx-auto items-center">
                    <p class="mb-0 text-lg">
                      登録人数 : <span class="text-primary">{{ numOfCsvUsers }}名</span>
                    </p>
                    <p class="mb-0 text-sm">
                      ( 団体管理ユーザー : <span class="text-primary">{{ numOfOrganizationAdminUsers }}名</span> / 一般ユーザー :
                      <span class="text-primary">{{ numOfNonOrganizationAdminUsers }}名</span> )
                    </p>
                  </div>
                  <button class="md:min-w-[120px] md:h-12 md:text-base" mat-flat-button color="primary" (click)="register()">
                    登録
                  </button>
                </div>

                <!--登録メンバーテーブル-->
                <app-table-overflow>
                  <table mat-table [dataSource]="dataSource" class="w-full">
                    <ng-container matColumnDef="lineNumber">
                      <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap">{{ HEADER_ITEM_NAMES[0] }}</th>
                      <td mat-cell *matCellDef="let element" class="text-sm md:text-md whitespace-nowrap">
                        {{ element.recordIndex }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="familyName">
                      <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap">{{ HEADER_ITEM_NAMES[1] }}</th>
                      <td mat-cell *matCellDef="let element" class="text-sm md:text-md whitespace-nowrap">
                        {{ element.familyName }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="firstName">
                      <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap">{{ HEADER_ITEM_NAMES[2] }}</th>
                      <td mat-cell *matCellDef="let element" class="text-sm md:text-md whitespace-nowrap">
                        {{ element.firstName }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="familyNameKana">
                      <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap">{{ HEADER_ITEM_NAMES[3] }}</th>
                      <td mat-cell *matCellDef="let element" class="text-sm md:text-md whitespace-nowrap">
                        {{ element.familyNameKana }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="firstNameKana">
                      <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap">{{ HEADER_ITEM_NAMES[4] }}</th>
                      <td mat-cell *matCellDef="let element" class="text-sm md:text-md whitespace-nowrap">
                        {{ element.firstNameKana }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="mail">
                      <th mat-header-cell *matHeaderCellDef class="whitespace-nowrap">{{ HEADER_ITEM_NAMES[5] }}</th>
                      <td mat-cell *matCellDef="let element" class="text-sm md:text-md whitespace-nowrap">
                        {{ element.email }}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="authority">
                      <th mat-header-cell *matHeaderCellDef class="text-right">{{ HEADER_ITEM_NAMES[6] }}</th>
                      <td mat-cell *matCellDef="let element" class="text-right whitespace-nowrap">
                        <div class="inline-block text-sm whitespace-nowrap" *ngIf="element.isOrganizationAdmin === true">
                          <app-label-with-icon iconName="check_circle">団体管理ユーザー</app-label-with-icon>
                        </div>
                        <div class="inline-block text-sm whitespace-nowrap" *ngIf="element.isOrganizationAdmin === false">一般ユーザー</div>
                      </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  </table>
                </app-table-overflow>

                <div class="flex items-center justify-end">
                  <button class="md:min-w-[120px] md:h-12 md:text-base" mat-flat-button color="primary" (click)="register()">
                    登録
                  </button>
                </div>
              </div>
            </div>
          </section>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<!--登録成功の際に表示されるエリア-->
<app-wrapper size="lg" *ngIf="isRegisteringSuccess">
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">person_add</mat-icon>
        所属ユーザ一一括登録
      </app-card-content-head>
      <app-wrapper size="fluid">
        <div class="flex flex-col gap-8 items-center py-8 md:py-16">
          <p class="text-xl md:text-2xl text-center mb-0"><b>ユーザー一括登録を開始しました</b></p>
          <div class="text-center mb-0 break-keep">
            登録が完了次第、登録結果をメールでお知らせいたします。<br />
            何か問題が発生した場合にも、<wbr />詳細をメールでご連絡します。<br />
            <br />
            登録処理はバックグラウンドで進行します。<br />
            ブラウザを閉じていただいて問題ございません。
          </div>
          <div>
            <button mat-flat-button color="primary" class="mb-4" (click)="goToMembers()">
              所属メンバー一覧へ戻る
            </button>
          </div>
        </div>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>

<!--登録エラーの際に表示されるエリア-->
<app-wrapper size="lg" *ngIf="isRegisteringError">
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">person_add</mat-icon>
        所属ユーザ一一括登録
      </app-card-content-head>
      <app-wrapper size="fluid">
        <div class="flex flex-col gap-8 items-center py-8 md:py-16">
          <div class="text-center">
            <mat-icon class="text-primary h-20 w-20 text-[5rem]">warning</mat-icon>
          </div>
          <p class="text-xl md:text-2xl text-center mb-0"><b>エラーが発生しました</b></p>
          <div class="text-center mb-0 break-keep">
            一括登録処理にエラーが発生しました。<br />
            CSVの内容に問題がないか再度確認し、<wbr />最初から登録をやり直してください。
          </div>
          <app-alert-box type="tips" [closeable]="false">
            <ul class="list-disc pl-5 text-sm">
              <li>CSVの一括登録は、新しいユーザーの登録のみできます。登録済みのユーザー情報の変更は個別に編集してください。</li>
              <li>雛形CSVのヘッダー（1行目）は変更しないでください。</li>
              <li>一つのメールアドレスに紐づけられる先生は一人のみです。</li>
              <li>「権限」の項目では、一般ユーザーならば0、団体管理ユーザーならば1を半角で入力してください。</li>
              <li>既に登録済みのメールアドレスは登録ができません。</li>
              <li>同時に複数のユーザーがアップロードすると、エラーが起きる場合がありますのでご注意ください。</li>
            </ul>
          </app-alert-box>
          <div>
            <button mat-flat-button color="primary" class="mb-4" (click)="goToMembers()">
              所属メンバー一覧へ戻る
            </button>
          </div>
        </div>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>
