import { createAction, props } from '@ngrx/store';

import { User } from '../models/user';
import { AppError } from '../errors/app-error';
import { UserSearchCondition } from '../models/user-search-condition';
import { CreateUsersRequest } from '../models/create-users-request';
import { CreateUsersResponse } from '../models/create-users-response';

export const findUsers = createAction('[User] Find Users', props<{ userSearchCondition: UserSearchCondition }>());
export const findUsersSuccess = createAction('[User] Find Users Success', props<{ users: User[] }>());
export const findUsersFailure = createAction('[User] Find Users Failure');
export const initializeFindUsers = createAction('[User] Initialize Find Users');

export const findUsersByEmail = createAction('[User] Find Users By Email', props<{ emailGroups: string[][] }>());
export const findUsersByEmailSuccess = createAction('[User] Find Users By Email Success', props<{ users: User[] }>());
export const findUsersByEmailFailure = createAction('[User] Find Users By Email Failure');
export const initializeFindUsersByEmail = createAction('[User] Initialize Find Users By Email');

export const watchUsers = createAction('[User] Watch Users', props<{ sortDirection: 'asc' | 'desc' }>());
export const watchUsersSuccess = createAction('[User] Watch Users Success', props<{ users: User[] }>());
export const watchUsersFailure = createAction('[User] Watch Users Failure');
export const initializeWatchedUsers = createAction('[User] Initialize Watched Users');

export const createUser = createAction('[User] Create User', props<{ user: User }>());
export const createUserSuccess = createAction('[User] Create User Success', props<{ user: User }>());
export const createUserFailure = createAction('[User] Create User Failure', props<{ error: AppError }>());
export const initializeCreateUserState = createAction('[User] Initialize Crate User State');

export const createUsers = createAction('[User] Create Users', props<CreateUsersRequest>());
export const createUsersSuccess = createAction('[User] Create Users Success', props<{ response: CreateUsersResponse }>());
export const createUsersFailure = createAction('[User] Create Users Failure', props<{ error: AppError }>());
export const initializeCreateUsersState = createAction('[User] Initialize Crate Users State');

export const updateUser = createAction('[User] Update User', props<{ user: User }>());
export const updateUserSuccess = createAction('[User] Update User Success', props<{ user: User }>());
export const updateUserFailure = createAction('[User] Update User Failure', props<{ error: AppError }>());
export const initializeUpdateUserState = createAction('[User] Initialize Update User State');

export const updateUserEmail = createAction('[User] Update User Email', props<{ user: User; password: string }>());
export const updateUserEmailSuccess = createAction('[User] Update User Email Success', props<{ user: User }>());
export const updateUserEmailFailure = createAction('[User] Update User Email Failure', props<{ error: AppError }>());
export const initializeUpdateUserEmailState = createAction('[User] Initialize Update User Email State');

export const deleteUser = createAction('[User] Delete User', props<{ user: User }>());
export const deleteUserSuccess = createAction('[User] Delete User Success', props<{ user: User }>());
export const deleteUserFailure = createAction('[User] Delete User Failure', props<{ error: AppError }>());
export const initializeDeleteUserState = createAction('[User] Initialize Delete User State');

export const updateUserIsActive = createAction('[User] update User Is Active', props<{ user: User }>());
export const updateUserIsActiveSuccess = createAction('[User] User Is Active Success', props<{ user: User }>());
export const updateUserIsActiveFailure = createAction('[User] User Is Active Failure', props<{ error: AppError }>());
export const initializeUpdateUserIsActiveState = createAction('[User] Initialize User Is Active State');

export const updateUserIsOrganizationAdmin = createAction('[User] update user is organization admin', props<{ user: User }>());
export const updateUserIsOrganizationAdminSuccess = createAction(
  '[User] update user is organization admin Success',
  props<{ user: User }>()
);
export const updateUserIsOrganizationAdminFailure = createAction(
  '[User] update user is organization admin Failure',
  props<{ error: AppError }>()
);
export const initializeUpdateUserIsOrganizationAdminState = createAction('[User] Initialize update user is organization admin State');

export const updateUserIsTermsAgree = createAction('[User] update User Is Terms Agree', props<{ user: User }>());
export const updateUserIsTermsAgreeSuccess = createAction('[User] User Is Terms Agree Success', props<{ user: User }>());
export const updateUserIsTermsAgreeFailure = createAction('[User] User Is Terms Agree Failure', props<{ error: AppError }>());
export const initializeUpdateUserIsTermsAgreeState = createAction('[User] Initialize User Is Terms Agree State');
