import { NationalLanguageSearchProblemsConditionQueryParams } from './../../../../models/query-params';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';

import { navigate } from 'src/app/actions/core.actions';
import { RootState } from '../../../../reducers';

import {
  EnglishProblemSearchResult,
  HistoryProblemSearchResult,
  NationalLanguageProblemSearchResult,
  ScienceProblemSearchResult
} from 'src/app/models/common-id/common-id-search-result';
import {
  EnglishSearchProblemsConditionQueryParams,
  HistorySearchProblemsConditionQueryParams,
  ScienceSearchProblemsConditionQueryParams
} from 'src/app/models/query-params';

import { Log } from 'src/app/utils/log';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { DIALOG_ZERO_PADDING_PANEL_CLASS } from 'src/app/resources/config';
import { DESCRIPTION_PREMIUM_DIALOG_WITDH } from 'src/app/resources/common-id-config';

import { CommonIdDescriptionPremiumDialogComponent } from '../description-premium-dialog/description-premium-dialog.component';
import { ProblemResultClickParams } from 'src/app/views/widgets/common-id/problem-list-item/problem-list-item.component';

@Component({
  selector: 'app-common-id-search-by-categories-result',
  templateUrl: './search-by-categories-result.component.html',
  styleUrls: ['./search-by-categories-result.component.scss']
})
export class CommonIdSearchByCategoriesResultComponent implements OnInit {
  @Input() searchResults:
    | EnglishProblemSearchResult[]
    | ScienceProblemSearchResult[]
    | NationalLanguageProblemSearchResult[]
    | HistoryProblemSearchResult[];

  @Input() queryParams:
    | EnglishSearchProblemsConditionQueryParams
    | ScienceSearchProblemsConditionQueryParams
    | NationalLanguageSearchProblemsConditionQueryParams
    | HistorySearchProblemsConditionQueryParams;

  isPremiumMemberOnly: boolean;

  constructor(private store: Store<RootState>, private dialog: MatDialog) {}

  private LOG_SOURCE = this.constructor.name;
  ngOnInit() {}

  clickResult(event: ProblemResultClickParams) {
    if (event.isPremiumMemberOnly) {
      this.openDialog();
    } else {
      this.goToProblemDetail(event.problemId);
    }
  }

  private openDialog() {
    const config: MatDialogConfig = {
      width: DESCRIPTION_PREMIUM_DIALOG_WITDH,
      panelClass: DIALOG_ZERO_PADDING_PANEL_CLASS,
      autoFocus: false,
      disableClose: true
    };
    this.dialog.open(CommonIdDescriptionPremiumDialogComponent, config);
  }

  private goToProblemDetail(problemId: string) {
    Log.debug(this.LOG_SOURCE, '問題詳細を開きます: ', problemId);

    // Pathに問題IDを追加する
    const url = RoutingPathResolver.resolveCommonIdSearchByCategoriesProblemDetail(problemId);

    Log.debug(this.LOG_SOURCE, '分野検索詳細画面用のクエリパラメタ: ', this.queryParams);
    this.store.dispatch(navigate({ url, extras: { queryParams: this.queryParams } }));
  }
}
