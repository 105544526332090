import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { enter } from '../../../resources/animations';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PLAN_NAMES, SCHOOL_ORGANIZATION_ID, JUKU_ORGANIZATION_ID, PlanSubjectId, PLANS_SORT } from '../../../resources/config';
import { Log } from '../../../utils/log';
import { CreatePlanData, Plan } from '../../../models/plan';
import { Dates } from '../../../utils/dates';
import { CustomValidators } from '../../../utils/custom-validator';
import { createPlan, findPlans, initializeCreatePlanState, initializeFindPlansState } from '../../../actions/plan.actions';
import { filter, take } from 'rxjs/operators';
import { getCreatePlanResult, getPlans } from '../../../selectors/plan.selectors';
import { dispatchInfoMessage } from '../../../actions/core.actions';

export interface AddPlanDialogData {
  schoolId: string;
  school: string;
  organization: string;
  subjectId?: string;
}

@Component({
  selector: 'app-add-plan-dialog',
  templateUrl: './add-plan-dialog.component.html',
  styleUrls: ['./add-plan-dialog.component.scss'],
  animations: [enter]
})
export class AddPlanDialogComponent implements OnInit, OnDestroy {
  minDate: Date = new Date();
  planForm: UntypedFormGroup;
  schoolId: string;
  school: string;
  organization: string;
  defaultSubjectId: string;
  plans: Plan[];
  subjects = [];
  errorMessage = '';
  planCreating = false;
  schoolOrganizationId = SCHOOL_ORGANIZATION_ID;
  jukuOrganizationId = JUKU_ORGANIZATION_ID;

  private LOG_SOURCE = this.constructor.name;

  constructor(
    private store: Store<RootState>,
    private dialogRef: MatDialogRef<AddPlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: AddPlanDialogData
  ) {}

  ngOnInit(): void {
    this.schoolId = this.data.schoolId;
    this.school = this.data.school;
    this.organization = this.data.organization;
    this.defaultSubjectId = this.data.subjectId ? this.data.subjectId : null;
    PLANS_SORT.map(id => this.subjects.push({ id, name: PLAN_NAMES[id] }));

    this.setUp();
    this.setUpForms();
  }

  ngOnDestroy(): void {
    this.store.dispatch(initializeFindPlansState());
  }

  addPlan() {
    Object.keys(this.planForm.controls).forEach(key => {
      this.planForm.controls[key].updateValueAndValidity();
    });

    if (this.planForm.invalid) {
      this.planForm.markAllAsTouched();
      return;
    }
    Log.debug(this.LOG_SOURCE, 'プランを作成します');
    this.disableForms();
    this.errorMessage = '';
    const duplicationSubjectPlan: Plan | undefined = this.plans.find(p => p.subjectId === this.planForm.get('subjectId').value);

    const plan: CreatePlanData = {
      organization: this.organization,
      schoolId: this.schoolId,
      school: this.school,
      subjectId: this.planForm.get('subjectId').value,
      memberCount: Number(this.planForm.get('memberCount').value),
      startAt: Dates.simple4YmdStringFromIso(this.planForm.get('startAt').value),
      endAt: Dates.simple4YmdStringFromIso(this.planForm.get('endAt').value)
    };
    if (duplicationSubjectPlan !== undefined) plan.id = duplicationSubjectPlan.id;

    Log.debug(this.LOG_SOURCE, 'create plan', plan);
    this.store.dispatch(createPlan({ plan }));

    this.store
      .select(getCreatePlanResult)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        this.store.dispatch(initializeCreatePlanState());
        Log.debug(this.LOG_SOURCE, `create plan result: `, result);
        if (result.success) {
          this.dialogRef.close(true);
          this.store.dispatch(
            dispatchInfoMessage({
              message: `プランを登録しました`
            })
          );
          return;
        }

        Log.warn(this.LOG_SOURCE, `create plan error: err.code: ${result.error ? result.error.code : 'none'}`, result.error);
        this.enableForms();
        this.errorMessage = result.error ? `[${result.error.code}] ${result.error.message}` : 'エラーが発生しました';
      });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  onChangeSubject() {
    if (this.planForm.get('subjectId').value === PlanSubjectId.TRIAL) {
      this.planForm.patchValue({ memberCount: 1 });
      this.planForm.patchValue({ endAt: Dates.get10DaysLaterDay(this.planForm.get('startAt').value) });
    } else {
      this.planForm.patchValue({ memberCount: '' });
      this.planForm.patchValue({ startAt: '' });
      this.planForm.patchValue({ endAt: '' });
    }
  }

  onChangeStartAt() {
    if (this.planForm.get('subjectId').value === PlanSubjectId.TRIAL) {
      this.planForm.patchValue({ endAt: Dates.get10DaysLaterDay(this.planForm.get('startAt').value) });
    } else {
      if (this.planForm.get('endAt').value === '') {
        this.planForm.patchValue({ endAt: Dates.get1YearsLaterDay(this.planForm.get('startAt').value) });
      } else {
        this.planForm.get('endAt').updateValueAndValidity();
      }
    }
  }

  private setUp() {
    this.store
      .select(getPlans)
      .pipe(filter(it => it != null))
      .pipe(take(1))
      .subscribe(plans => {
        this.plans = plans;
      });

    this.store.dispatch(findPlans({ organization: this.organization, schoolId: this.schoolId }));
  }

  private setUpForms() {
    this.planForm = new UntypedFormGroup({
      subjectId: new UntypedFormControl('', [Validators.required]),
      memberCount: new UntypedFormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      startAt: new UntypedFormControl('', [Validators.required]),
      endAt: new UntypedFormControl('', [Validators.required, CustomValidators.endAtIsAfterStartAt()])
    });

    this.planForm.get('subjectId').setValue(this.defaultSubjectId);

    this.enableForms();
  }

  private disableForms() {
    Object.keys(this.planForm.controls).forEach(ctrlName => {
      this.planForm.get(ctrlName).disable();
    });
    this.planCreating = true;
  }

  private enableForms() {
    Object.keys(this.planForm.controls).forEach(ctrlName => {
      this.planForm.get(ctrlName).enable();
    });
    this.planCreating = false;
  }
}
