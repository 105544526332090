import { createAction, props } from '@ngrx/store';

import { StaticCommonData } from '../models/static-common-data';
import { StaticScienceData } from '../models/static-science-data';
import { StaticEnglishData } from '../models/static-english-data';
import { StaticHistoryData } from '../models/static-history-data';
import { ISO8601 } from '../utils/dates';
import { StaticNationalLanguageData } from '../models/static-national-language-data';

export const findStaticData = createAction('[StaticData] Find Static Data');
export const findStaticDataSuccess = createAction(
  '[StaticData] Find Static Data Success',
  props<{
    common: StaticCommonData;
    english: StaticEnglishData;
    math: StaticScienceData;
    nationalLanguage: StaticNationalLanguageData;
    physics: StaticScienceData;
    chemistry: StaticScienceData;
    biology: StaticScienceData;
    japaneseHistory: StaticHistoryData;
    worldHistory: StaticHistoryData;
    geography: StaticScienceData;
    politicalEconomy: StaticScienceData;
    fetchedDate: ISO8601;
  }>()
);
export const findStaticDataFailure = createAction('[StaticData] Find Static Data Failure');

export const initializeStaticDataState = createAction('[StaticData] Initialize Static Data state');
