import { Component, Input, OnInit } from '@angular/core';
import { ReadableProblem } from '../../../../models/problem';
import { SUBJECT_NAMES } from '../../../../resources/config';

@Component({
  selector: 'app-problem-detail-data-table',
  templateUrl: './problem-detail-data-table.component.html',
  styleUrls: ['./problem-detail-data-table.component.scss']
})
export class TryProblemDetailDataTableComponent implements OnInit {
  @Input() subjectId: string;
  @Input() daimonId: string;
  @Input() readableProblem: ReadableProblem;

  universityName: string;
  universityId: string;
  year: string;
  nyushiName: string;
  subjectName: string;
  shikenNumber: string;
  departments: string;

  constructor() {}
  ngOnInit() {
    this.universityName = this.readableProblem.university;
    this.universityId = this.readableProblem.id.substring(2, 6);
    this.year = this.readableProblem.year.toString().substring(2, 4);
    this.nyushiName =
      this.readableProblem.examPaper && this.readableProblem.examPaper.nyushiName ? this.readableProblem.examPaper.nyushiName : '-';
    this.subjectName = SUBJECT_NAMES[this.subjectId];
    this.shikenNumber = this.readableProblem.id.substring(8, 10);
    this.departments = this.readableProblem.departments;
  }
}
