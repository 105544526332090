import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { CommonIdSavePurchaseRequest } from '../../models/common-id/common-id-save-purchase-request';
import { CallableFunction } from '../../resources/app-firebase';
import { CommonIdUserPurchaseData } from '../../models/common-id/common-id-user';

@Injectable({
  providedIn: 'root'
})
export class CommonIdSavePurchaseService {
  constructor(private aff: AngularFireFunctions) {}

  commonIdSavePurchase({ stripeSessionId }: CommonIdSavePurchaseRequest): Observable<CommonIdUserPurchaseData> {
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_SAVE_PURCHASE);
    return callable({ stripeSessionId });
  }
}
