export enum GA_EVENT_ACTIONS {
  CLICK = 'click'
}

export enum GA_EVENT_CATEGORIES {
  PRINT_PREVIEW = 'print-preview',
  SAVE_PAPER_ON = 'save-paper-on',
  PROBLEM_TAB = 'problem-tab',
  ANSWER_TAB = 'answer-tab',
  SOLVED_ON = 'solved-on',
  SET_SAVE_PAPER_ON = 'set_save-paper-on',
  SET_SOLVED_ON = 'set_solved-on',
  SET_DAIMON_PRINT_PREVIEW = 'setdaimon_print-preview',
  SET_DAIMON_SOLVED_ON = 'setdaimon_solved-on',
  PREMIUM_DETAIL_VIEW = 'premium-detail-view',
  PREMIUM_PLAN_VIEW = 'premium-plan-view',
  MONTH_3_BUY = '3month-buy',
  MONTH_6_BUY = '6month-buy',
  MONTH_12_BUY = '12month-buy',
  NYUSHI_PREMIUM_BANNER = 'nyushipremium-banner'
}

export enum GA_EVENT_LABELS {
  PROBLEM_DETAIL = '問題詳細',
  SEARCH_UNIV = '大学検索',
  UNIV_DETAIL = '大学詳細',
  PLAYLISTS = 'おすすめ問題セット',
  MYLIST_UNIVERSITIES = 'お気に入り大学',
  MYLIST_PROBLEMS = '問題管理',
  MYLIST_PLAYLISTS = 'おすすめ問題セット',
  PLAYLIST_DETAIL = 'おすすめ問題セット詳細',
  PLAYLIST_PROBLEM_DETAIL = 'おすすめ問題セット詳細大問表示',
  SEARCH_BY_CATEGORIES = '分野検索結果',
  SEARCH_BY_CATEGORIES_DETAIL = '分野検索結果詳細',
  MYLIST_ANSWERED_PROBLEMS = 'マイリスト（学習履歴）'
}
