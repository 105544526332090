import { createReducer, on } from '@ngrx/store';

import * as CommonIdFindPlaylistOriginalProblemIdsActions from '../../actions/common-id/common-id-find-playlist-original-problems.action';
import { AppError } from '../../errors/app-error';
import { CommonIdFindPlaylistOriginalProblemIdsResponse } from 'src/app/models/common-id/common-id-playlist-original-problem';

export const commonIdFindPlaylistOriginalProblemIdsFeatureKey = 'commonIdFindPlaylistOriginalProblemIds';

export interface CommonIdFindPlaylistOriginalProblemIdsResult {
  success: boolean;
  response: CommonIdFindPlaylistOriginalProblemIdsResponse[] | null;
  error: AppError | null;
}

export interface State {
  findPlaylistOriginalProblemIdsResult: CommonIdFindPlaylistOriginalProblemIdsResult | null;
}

export const initialState: State = {
  findPlaylistOriginalProblemIdsResult: null
};

export const reducer = createReducer(
  initialState,

  on(CommonIdFindPlaylistOriginalProblemIdsActions.commonIdFindPlaylistOriginalProblemIdsSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      findPlaylistOriginalProblemIdsResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(CommonIdFindPlaylistOriginalProblemIdsActions.commonIdFindPlaylistOriginalProblemIdsFailure, (state, { error }) => {
    const next: State = {
      ...state,
      findPlaylistOriginalProblemIdsResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(CommonIdFindPlaylistOriginalProblemIdsActions.initializeCommonIdFindPlaylistOriginalProblemIdsState, state => {
    const next: State = { ...state, findPlaylistOriginalProblemIdsResult: null };
    return next;
  })
);
