import { createAction, props } from '@ngrx/store';

import { Playlist } from '../models/playlist';
import { SciencePlaylistProblem, EnglishPlaylistProblem, NationalLanguagePlaylistProblem, HistoryPlaylistProblem } from '../models/problem';

// findPlaylists ------------------------------------------------------------
export const findPlaylists = createAction('[Playlist] Find Play Lists');
export const findPlaylistsSuccess = createAction('[Playlist] Find Play Lists Success', props<{ playlists: Playlist[] }>());
export const findPlaylistsFailure = createAction('[Playlist] Find Play Lists Failure');

// findPlaylistProblemIds ------------------------------------------------------------
export const findPlaylistProblemIds = createAction(
  '[Playlist] Find English Playlist Problem Ids',
  props<{ subjectId: string; playlistId: string }>()
);

export const findPlaylistProblemIdsSuccess = createAction(
  '[Playlist] Find Playlist Problem Ids Success',
  props<{ playlistProblemIds: string[] }>()
);
export const findPlaylistProblemIdsFailure = createAction('[Playlist] Find Playlist Problem Ids Failure');
export const initializePlaylistProblemIdsState = createAction('[Playlist] Initialize Playlist Problem Ids State');

// findEnglishPlaylistProblems ------------------------------------------------------------
export const findEnglishPlaylistProblems = createAction('[Playlist] Find English Playlist Problems', props<{ playlistId: string }>());
export const findEnglishPlaylistProblemsSuccess = createAction(
  '[Playlist] Find English Playlist Problems Success',
  props<{ englishPlaylistProblems: EnglishPlaylistProblem[] }>()
);
export const findEnglishPlaylistProblemsFailure = createAction('[Playlist] Find English Playlist Problems Failure');
export const initializeEnglishPlaylistProblemsState = createAction('[Playlist] Initialize English Playlist Problems State');

// findMathPlaylistProblems ------------------------------------------------------------
export const findMathPlaylistProblems = createAction('[Playlist] Find Math Playlist Problems', props<{ playlistId: string }>());
export const findMathPlaylistProblemsSuccess = createAction(
  '[Playlist] Find Math Playlist Problems Success',
  props<{ mathPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findMathPlaylistProblemsFailure = createAction('[Playlist] Find Math Playlist Problems Failure');
export const initializeMathPlaylistProblemsState = createAction('[Playlist] Initialize Math Playlist Problems State');

// findNationalLanguagePlaylistProblems ------------------------------------------------------------
export const findNationalLanguagePlaylistProblems = createAction(
  '[Playlist] Find National Language Playlist Problems',
  props<{ playlistId: string }>()
);
export const findNationalLanguagePlaylistProblemsSuccess = createAction(
  '[Playlist] Find National Language Playlist Problems Success',
  props<{ nationalLanguagePlaylistProblems: NationalLanguagePlaylistProblem[] }>()
);
export const findNationalLanguagePlaylistProblemsFailure = createAction('[Playlist] Find National Language Playlist Problems Failure');
export const initializeNationalLanguagePlaylistProblemsState = createAction(
  '[Playlist] Initialize National Language Playlist Problems State'
);

// findPhysicsPlaylistProblems ------------------------------------------------------------
export const findPhysicsPlaylistProblems = createAction('[Playlist] Find Physics Playlist Problems', props<{ playlistId: string }>());
export const findPhysicsPlaylistProblemsSuccess = createAction(
  '[Playlist] Find Physics Playlist Problems Success',
  props<{ physicsPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findPhysicsPlaylistProblemsFailure = createAction('[Playlist] Find Physics Playlist Problems Failure');
export const initializePhysicsPlaylistProblemsState = createAction('[Playlist] Initialize Physics Playlist Problems State');

// findChemistryPlaylistProblems ------------------------------------------------------------
export const findChemistryPlaylistProblems = createAction('[Playlist] Find Chemistry Playlist Problems', props<{ playlistId: string }>());
export const findChemistryPlaylistProblemsSuccess = createAction(
  '[Playlist] Find Chemistry Playlist Problems Success',
  props<{ chemistryPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findChemistryPlaylistProblemsFailure = createAction('[Playlist] Find Chemistry Playlist Problems Failure');
export const initializeChemistryPlaylistProblemsState = createAction('[Playlist] Initialize Chemistry Playlist Problems State');

// findBiologyPlaylistProblems ------------------------------------------------------------
export const findBiologyPlaylistProblems = createAction('[Playlist] Find Biology Playlist Problems', props<{ playlistId: string }>());
export const findBiologyPlaylistProblemsSuccess = createAction(
  '[Playlist] Find Biology Playlist Problems Success',
  props<{ biologyPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findBiologyPlaylistProblemsFailure = createAction('[Playlist] Find Biology Playlist Problems Failure');
export const initializeBiologyPlaylistProblemsState = createAction('[Playlist] Initialize Biology Playlist Problems State');

// findJapaneseHistoryPlaylistProblems ------------------------------------------------------------
export const findJapaneseHistoryPlaylistProblems = createAction(
  '[Playlist] Find Japanese History Playlist Problems',
  props<{ playlistId: string }>()
);
export const findJapaneseHistoryPlaylistProblemsSuccess = createAction(
  '[Playlist] Find Japanese History Playlist Problems Success',
  props<{ japaneseHistoryPlaylistProblems: HistoryPlaylistProblem[] }>()
);
export const findJapaneseHistoryPlaylistProblemsFailure = createAction('[Playlist] Find Japanese History Playlist Problems Failure');
export const initializeJapaneseHistoryPlaylistProblemsState = createAction(
  '[Playlist] Initialize Japanese History Playlist Problems State'
);

// findWorldHistoryPlaylistProblems ------------------------------------------------------------
export const findWorldHistoryPlaylistProblems = createAction(
  '[Playlist] Find World History Playlist Problems',
  props<{ playlistId: string }>()
);
export const findWorldHistoryPlaylistProblemsSuccess = createAction(
  '[Playlist] Find World History Playlist Problems Success',
  props<{ worldHistoryPlaylistProblems: HistoryPlaylistProblem[] }>()
);
export const findWorldHistoryPlaylistProblemsFailure = createAction('[Playlist] Find World History Playlist Problems Failure');
export const initializeWorldHistoryPlaylistProblemsState = createAction('[Playlist] Initialize World History Playlist Problems State');

// findGeographyPlaylistProblems ------------------------------------------------------------
export const findGeographyPlaylistProblems = createAction('[Playlist] Find Geography Playlist Problems', props<{ playlistId: string }>());
export const findGeographyPlaylistProblemsSuccess = createAction(
  '[Playlist] Find Geography Playlist Problems Success',
  props<{ geographyPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findGeographyPlaylistProblemsFailure = createAction('[Playlist] Find Geography Playlist Problems Failure');
export const initializeGeographyPlaylistProblemsState = createAction('[Playlist] Initialize Geography Playlist Problems State');

// findPoliticalEconomyPlaylistProblems ------------------------------------------------------------
export const findPoliticalEconomyPlaylistProblems = createAction(
  '[Playlist] Find Political Economy Playlist Problems',
  props<{ playlistId: string }>()
);
export const findPoliticalEconomyPlaylistProblemsSuccess = createAction(
  '[Playlist] Find Political Economy Playlist Problems Success',
  props<{ politicalEconomyPlaylistProblems: SciencePlaylistProblem[] }>()
);
export const findPoliticalEconomyPlaylistProblemsFailure = createAction('[Playlist] Find Political Economy Playlist Problems Failure');
export const initializePoliticalEconomyPlaylistProblemsState = createAction(
  '[Playlist] Initialize Political Economy Playlist Problems State'
);

// findPdfUrl ------------------------------------------------------------
export const resolvePdfObjectUrl = createAction('[Playlist] Resolve Pdf Object Url', props<{ pdfPath: string }>());
export const resolvePdfObjectUrlSuccess = createAction(
  '[Playlist] Resolve Pdf Object Url Success',
  props<{ pdfPath: string; objectUrl: string }>()
);
export const resolvePdfObjectUrlFailure = createAction('[Playlist] Resolve Pdf Object Url Failure', props<{ pdfPath: string }>());
export const initializePdfObjectUrl = createAction('[Playlist] Initialize Pdf Object Url', props<{ pdfPath: string }>());
export const initializePdfObjectUrlState = createAction('[Playlist] Initialize Pdf Object Url State');
