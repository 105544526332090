import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';

@Component({
  selector: 'app-button-toggle-saved-problem',
  templateUrl: './button-toggle-saved-problem.component.html',
  styleUrls: ['./button-toggle-saved-problem.component.scss']
})
export class ButtonToggleSavedProblemComponent implements OnChanges, OnInit {
  constructor() {}

  @Input() isAnswerd = false;
  @Input() disabled = false;
  @Input() canSpinner = false;
  @Output() saveAnsweredClick = new EventEmitter();

  showSpinner = false;

  ngOnChanges(changes) {
    this.showSpinner = false;
  }

  ngOnInit() {}

  toggle() {
    if (this.disabled) {
      this.saveAnsweredClick.emit();
      return;
    }

    if (this.canSpinner) {
      this.showSpinner = true;
    }

    this.saveAnsweredClick.emit();
  }

  reset() {
    this.isAnswerd = false;
    this.showSpinner = false;
  }
}
