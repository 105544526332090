import { Component, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { EnglishProblemOutlineData } from '../english-problem-outline/english-problem-outline.component';
import { RoutingPathResolver } from 'src/app/app-routing-path-resolver';
import { ReadableEnglishProblem, ReadableEnglishPlaylistProblem } from 'src/app/models/problem';
import { Log } from 'src/app/utils/log';
import { Observable } from 'rxjs';
import { BookmarkProblem } from 'src/app/models/bookmark';
import { initializePdfObjectUrl, resolvePdfObjectUrl } from '../../../actions/playlist.actions';
import { getSpecificResolvePdfObjectUrlResult } from '../../../selectors/playlist.selectors';
import { filter, take } from 'rxjs/operators';
import { openWindow, redirectPage } from '../../../actions/core.actions';
import { AppEvent } from '../../../models/event-log';
import { reportAppEvent } from '../../../actions/event-log.actions';
import { Store } from '@ngrx/store';
import { RootState } from '../../../reducers';
import { WordFileUtil } from '../../../utils/word-file-util';

interface ProblemData {
  sequentialId: string;
  id: string;
  university: string;
  departments: string;
  year: number;
  level: string;
  book: string;
  page: number;
  problemNumber: string;
  outlineData: EnglishProblemOutlineData;

  comment?: string;
  pdfPath?: string;
  pdfDownloading?: boolean;

  hasExternalData: boolean;
  hasWordData: boolean;
  isBookmarked: boolean;
}

type ProblemId = string;

@Component({
  selector: 'app-english-problems',
  templateUrl: './english-problems.component.html',
  styleUrls: ['./english-problems.component.scss']
})
export class EnglishProblemsComponent implements OnChanges, OnDestroy {
  private LOG_SOURCE = this.constructor.name;

  @Input() readableEnglishProblems: ReadableEnglishProblem[];
  @Input() longSentenceKeywords: string[];
  @Input() readableEnglishPlaylistProblems: ReadableEnglishPlaylistProblem[];
  @Input() problemButtonDisabled = false;
  @Input() wordButtonDisabled = false;
  @Input() hideLastBottomBorder = false;
  @Input() initializedBookmark$: Observable<boolean>;
  @Input() bookmarkProblems: BookmarkProblem[];
  @Input() bookmarkedAllFlag: boolean;
  @Input() canBookmarkSpiner = false;
  @Input() isTrial: boolean;
  @Input() playListId: string;
  @Input() themeId: string;

  @Output() addBookmarkClick = new EventEmitter<ProblemId>();
  @Output() deleteBookmarkClick = new EventEmitter<ProblemId>();

  @Output() problemClick = new EventEmitter<{ problemId: ProblemId; year: number }>();

  columns: string[];
  dataSource: ProblemData[] = [];

  private objectUrls: string[] = [];

  constructor(private store: Store<RootState>) {}

  ngOnChanges() {
    if (!this.readableEnglishProblems && !this.readableEnglishPlaylistProblems) return;
    const dataCount = this.readableEnglishProblems
      ? this.readableEnglishProblems.length
      : this.readableEnglishPlaylistProblems
      ? this.readableEnglishPlaylistProblems.length
      : 'none';
    Log.debug(this.LOG_SOURCE, `input data length: ${dataCount}`);

    this.columns = this.readableEnglishProblems
      ? ['university', 'departments', 'year', 'outline', 'level', 'book', 'page', 'problemNumber', 'wordDownload']
      : ['sequentialId', 'university', 'departments', 'year', 'outline', 'level', 'book', 'page', 'problemNumber', 'wordDownload'];

    if (this.initializedBookmark$ !== undefined) {
      this.columns.splice(0, 0, 'bookmark');
    }

    this.dataSource = this.readableEnglishProblems
      ? this.readableEnglishProblems.map(readableProblem => this.mapData(readableProblem))
      : this.readableEnglishPlaylistProblems
      ? this.readableEnglishPlaylistProblems.map(readablePlaylistProblem => {
          const data = this.mapData(readablePlaylistProblem);
          data.sequentialId = readablePlaylistProblem.sequentialId;
          if (readablePlaylistProblem.comment) {
            data.outlineData.comment = readablePlaylistProblem.comment;
          }
          if (readablePlaylistProblem.pdfPath) {
            data.pdfPath = readablePlaylistProblem.pdfPath;
            data.pdfDownloading = false;
          }
          return data;
        })
      : [];
  }

  ngOnDestroy() {
    this.objectUrls.forEach(url => URL.revokeObjectURL(url));
  }

  openProblemPdf(problem: ProblemData) {
    if (!problem.pdfPath) {
      Log.warn(this.LOG_SOURCE, `pdfPath が設定されていません. problem: `, problem);
      return;
    }
    Log.debug(this.LOG_SOURCE, `problem の pdf をダウンロードして開きます. pdfPath: `, problem.pdfPath);
    problem.pdfDownloading = true;
    const pdfPath = problem.pdfPath;
    this.store.dispatch(resolvePdfObjectUrl({ pdfPath }));
    this.store
      .select(getSpecificResolvePdfObjectUrlResult(pdfPath))
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        problem.pdfDownloading = false;
        if (result.success) {
          this.store.dispatch(openWindow({ url: result.objectUrl }));
          this.objectUrls.push(result.objectUrl);
          this.store.dispatch(initializePdfObjectUrl({ pdfPath }));
        }
      });

    const subjectId = problem.id.slice(6, 8);
    const splitPdfPath = problem.pdfPath.split('/');
    const selectedPlaylistPdf = splitPdfPath[splitPdfPath.length - 1];
    const selectedPlaylistSetName = selectedPlaylistPdf.slice(0, 3);
    const appEvent: AppEvent = {
      type: 'select-playlist-pdf',
      value: JSON.stringify({
        subjectId,
        selectedPlaylistSetName,
        selectedPlaylistPdf
      })
    };
    setTimeout(() => this.store.dispatch(reportAppEvent({ event: appEvent })));
  }

  showPlayListProblemDetail(problem: ProblemData) {
    const url = RoutingPathResolver.resolvePlaylistProblemDetail(this.playListId, this.themeId, problem.id);
    this.store.dispatch(openWindow({ url }));
  }

  openProblemWord(problem: ProblemData) {
    const wordUrl = WordFileUtil.getPath(problem.id, this.isTrial);
    if (wordUrl === '') {
      Log.warn(this.LOG_SOURCE, `wordURL が設定されていません. problem id: `, problem.id);
      return;
    }
    Log.debug(this.LOG_SOURCE, `word をダウンロードして開きます. wordURL: `, wordUrl);

    this.store.dispatch(redirectPage({ url: wordUrl }));

    const subjectId = problem.id.slice(6, 8);
    const selectedProblemWord = `${problem.id}.docx`;
    const appEvent: AppEvent = {
      type: 'select-problem-word',
      value: JSON.stringify({
        subjectId,
        selectedProblemWord
      })
    };
    setTimeout(() => this.store.dispatch(reportAppEvent({ event: appEvent })));
  }

  private mapData(readableProblem: ReadableEnglishProblem): ProblemData {
    const isThinking = readableProblem.thinking !== '';
    const outlineData: EnglishProblemOutlineData = {
      isThinking,
      fields: readableProblem.fields,
      subTypes: readableProblem.subTypes
    };
    if (readableProblem.longSentences && readableProblem.longSentences.length !== 0) {
      outlineData.longSentences = [...readableProblem.longSentences];
    }
    if (this.longSentenceKeywords && this.longSentenceKeywords.length !== 0) {
      outlineData.keywords = [...this.longSentenceKeywords];
    }
    const data: ProblemData = {
      /** PlaylistProblem の場合は map 後に適切な値を設定 */
      sequentialId: '',

      id: readableProblem.id,
      university: readableProblem.university,
      departments: readableProblem.departments,
      year: readableProblem.year,
      level: readableProblem.level,
      book: readableProblem.book,
      page: readableProblem.page,
      problemNumber: readableProblem.problemNumber,
      hasExternalData: readableProblem.hasExternalData,
      hasWordData: readableProblem.hasWordData,
      isBookmarked: this.bookmarkedAllFlag
        ? true
        : this.bookmarkProblems !== undefined
        ? this.bookmarkProblems.find(bookmarkProblem => bookmarkProblem.problemId === readableProblem.id) !== undefined
        : false,
      outlineData
    };
    return data;
  }
}
