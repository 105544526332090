import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-playlist-tag-button',
  templateUrl: './playlist-tag-button.component.html',
  styleUrls: ['./playlist-tag-button.component.scss']
})
export class PlaylistTagButtonComponent implements OnInit {
  constructor() {}
  @Input() tagname: string;

  ngOnInit() {}
}
