export const TRIAL_ENGLISH_WORD_PATH = 'trial-word-files/01/2020/D0010/2000100101/200010010101.docx';
export const TRIAL_MATH_WORD_PATH = 'trial-word-files/02/2020/D0010/2000100201/200010020101.docx';
export const TRIAL_NATIONAL_LANGUAGE_WORD_PATH = 'trial-word-files/03/2020/D0010/2000100301/200010030101.docx';
export const TRIAL_PHYSICS_WORD_PATH = 'trial-word-files/04/2020/D0010/2000100401/200010040101.docx';
export const TRIAL_CHEMISTRY_WORD_PATH = 'trial-word-files/05/2020/D0010/2000100501/200010050101.docx';
export const TRIAL_BIOLOGY_WORD_PATH = 'trial-word-files/06/2020/D0010/2000100601/200010060101.docx';
export const TRIAL_JAPANESE_HISTORY_WORD_PATH = 'trial-word-files/07/2020/D0010/2000100701/200010070101.docx';
export const TRIAL_WORLD_HISTORY_WORD_PATH = 'trial-word-files/08/2020/D0010/2000100801/200010080101.docx';
export const TRIAL_GEOGRAPHY_WORD_PATH = 'trial-word-files/09/2020/D0010/2000100901/200010090101.docx';
export const TRIAL_POLITICAL_ECONOMY_WORD_PATH = 'trial-word-files/10/2020/D9999/2099991001/209999100101.docx';
