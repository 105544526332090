import { Action, createReducer, on } from '@ngrx/store';

import * as CommonIdSearchActions from '../../actions/common-id/common-id-search.actions';
import { UniversitySearchCondition, UniversityCondition, PrefectureCondition } from '../../models/university-search-condition';
import { CommonIdFindUniversityPapersResponse } from '../../models/common-id/common-id-find-university-papers-response';

export const commonIdSearchFeatureKey = 'commonIdSearch';

export interface State {
  searchCondition: UniversitySearchCondition<UniversityCondition | PrefectureCondition>;

  /** 検索条件に合う大学の件数 */
  isUniversityCountSearching: boolean;
  matchedUniversityCount: number | null;

  /** 大学の検索結果 */
  isUniversitySearching: boolean;
  matchedUniversities: CommonIdFindUniversityPapersResponse[] | null;

  /** 検索結果のページ指定用 */
  page: number;
}

export const initialState: State = {
  searchCondition: null,
  isUniversityCountSearching: false,
  matchedUniversityCount: null,
  isUniversitySearching: false,
  matchedUniversities: null,
  page: null
};

const commonIdSearchReducer = createReducer(
  initialState,

  // 検索条件に合う大学の校数を取得する場合 -------------------------------------------------------
  on(CommonIdSearchActions.findUniversityCountByUniversityIds, state => ({ ...state, isUniversityCountSearching: true })),
  on(CommonIdSearchActions.findUniversityCountByUniversityIdsSuccess, (state, { result }) => ({
    ...state,
    matchedUniversityCount: result.count,
    isUniversityCountSearching: false
  })),
  on(CommonIdSearchActions.findUniversityCountByUniversityIdsFailure, state => ({ ...state, isUniversityCountSearching: false })),

  // 検索条件に合う大学の校数を取得する場合（都道府県別） -------------------------------------------------------
  on(CommonIdSearchActions.findUniversityCountByPrefectureIds, state => ({ ...state, isUniversityCountSearching: true })),
  on(CommonIdSearchActions.findUniversityCountByPrefectureIdsSuccess, (state, { result }) => ({
    ...state,
    matchedUniversityCount: result.count,
    isUniversityCountSearching: false
  })),
  on(CommonIdSearchActions.findUniversityCountByPrefectureIdsFailure, state => ({ ...state, isUniversityCountSearching: false })),

  // 検索条件に合う大学の詳細を取得する場合 -------------------------------------------------------
  on(CommonIdSearchActions.findUniversityPapersByUniversityIds, state => ({ ...state, isUniversitySearching: true })),
  on(CommonIdSearchActions.findUniversityPapersByUniversityIdsSuccess, (state, { universities }) => ({
    ...state,
    matchedUniversities: universities,
    isUniversitySearching: false
  })),
  on(CommonIdSearchActions.findUniversityPapersByUniversityIdsFailure, state => ({ ...state, isUniversitySearching: false })),

  // 検索条件に合う大学の詳細を取得する場合（都道府県別） -------------------------------------------------------
  on(CommonIdSearchActions.findUniversityPapersByPrefectureIds, state => ({ ...state, isUniversitySearching: true })),
  on(CommonIdSearchActions.findUniversityPapersByPrefectureIdsSuccess, (state, { universities }) => ({
    ...state,
    matchedUniversities: universities,
    isUniversitySearching: false
  })),
  on(CommonIdSearchActions.findUniversityPapersByPrefectureIdsFailure, state => ({ ...state, isUniversitySearching: false })),

  // 検索結果の校数をリセットする場合 ---------------------------------------------------------------------------
  on(CommonIdSearchActions.initializeUniversityCountState, state => {
    const next: State = { ...state, matchedUniversityCount: null, isUniversityCountSearching: false };
    return next;
  }),

  // 検索結果の大学詳細をリセットする場合 ---------------------------------------------------------------------------
  on(CommonIdSearchActions.initializeUniversityPapersState, state => {
    const next: State = { ...state, matchedUniversities: null, isUniversitySearching: false, page: null };
    return next;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return commonIdSearchReducer(state, action);
}
